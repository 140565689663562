import * as authService from "services/auth/auth";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  actionTypes,
  changePasswordErrorAction,
  changePasswordFulFillAction,
  changePasswordSuccessAction
} from "./actions";

export function* changePasswordWorker(action) {
  try {
    const { oldPassword, newPassword } = action.payload;
    yield call(authService.changePassword, oldPassword, newPassword);
    yield put(changePasswordSuccessAction());
  } catch (error) {
    yield put(changePasswordErrorAction(error));
  } finally {
    yield put(changePasswordFulFillAction());
  }
}

export default function* changePasswordWatcher() {
  yield takeLatest(actionTypes.AUTH_CHANGE_PASSWORD_REQUEST, changePasswordWorker);
}
