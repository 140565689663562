import React, { memo } from "react";

const VirtualMetrologyIcon = ({ className }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M16 20C16 18.8954 15.1046 18 14 18C12.8954 18 12 18.8954 12 20C12 21.1046 12.8954 22 14 22C15.1046 22 16 21.1046 16 20Z"
      fill="#86E8B6"
    />
    <path
      d="M36 28C36 26.8954 35.1046 26 34 26C32.8954 26 32 26.8954 32 28C32 29.1046 32.8954 30 34 30C35.1046 30 36 29.1046 36 28Z"
      fill="#86E8B6"
    />
    <path
      d="M19 37C20.1046 37 21 36.1046 21 35C21 34.6357 20.9026 34.2942 20.7324 34C20.3866 33.4022 19.7403 33 19 33C17.8954 33 17 33.8954 17 35C17 36.1046 17.8954 37 19 37Z"
      fill="#86E8B6"
    />
    <path
      d="M32 11C32 9.89543 31.1046 9 30 9C28.8954 9 28 9.89543 28 11C28 11.5072 28.1888 11.9703 28.5 12.3229C28.8665 12.7381 29.4027 13 30 13C31.1046 13 32 12.1046 32 11Z"
      fill="#86E8B6"
    />
    <path
      d="M33 20C33 21.1046 33.8954 22 35 22C36.1046 22 37 21.1046 37 20C37 18.8954 36.1046 18 35 18C33.8954 18 33 18.8954 33 20ZM33 20H28V43.5999M36 28C36 26.8954 35.1046 26 34 26C32.8954 26 32 26.8954 32 28C32 29.1046 32.8954 30 34 30C35.1046 30 36 29.1046 36 28ZM36 28H43.5999M12 20C12 21.1046 12.8954 22 14 22C15.1046 22 16 21.1046 16 20C16 18.8954 15.1046 18 14 18C12.8954 18 12 18.8954 12 20ZM12 20H4.40007M15 29C15 27.8954 14.1046 27 13 27C11.8954 27 11 27.8954 11 29C11 30.1046 11.8954 31 13 31C14.1046 31 15 30.1046 15 29ZM15 29H20V4.40007M4.40007 20C4.13772 21.2924 4 22.6301 4 24C4 35.0457 12.9543 44 24 44C25.3699 44 26.7076 43.8623 28 43.5999M4.40007 20C5.34733 15.3335 7.91937 11.2569 11.5 8.38657M43.5999 28C43.8623 26.7076 44 25.3699 44 24C44 12.9543 35.0457 4 24 4C22.6301 4 21.2924 4.13772 20 4.40007M43.5999 28C42.6527 32.6665 40.0806 36.7431 36.5 39.6134M20 4.40007C18.4249 4.7198 16.917 5.22465 15.5 5.8909M28 43.5999C29.5751 43.2802 31.083 42.7754 32.5 42.1091M36.5 33V39.6134M36.5 39.6134C35.2763 40.5944 33.9347 41.4345 32.5 42.1091M32.5 34.5V42.1091M11.5 8.38657V14.5M11.5 8.38657C12.7237 7.4056 14.0653 6.56551 15.5 5.8909M15.5 5.8909V13M28.5 12.3229C28.8665 12.7381 29.4027 13 30 13C31.1046 13 32 12.1046 32 11C32 9.89543 31.1046 9 30 9C28.8954 9 28 9.89543 28 11C28 11.5072 28.1888 11.9703 28.5 12.3229ZM28.5 12.3229L24 16.5V32L20.7324 34M20.7324 34C20.3866 33.4022 19.7403 33 19 33C17.8954 33 17 33.8954 17 35C17 36.1046 17.8954 37 19 37C20.1046 37 21 36.1046 21 35C21 34.6357 20.9026 34.2942 20.7324 34Z"
      stroke="black"
    />
  </svg>
);

export default memo(VirtualMetrologyIcon);
