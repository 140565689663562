export const actionTypes = {
  AUTH_SET_STATE: "auth.set.state",
  AUTH_CLEAR_STATE: "auth.clear.state"
};

export function setAuthStateAction(payload) {
  return { type: actionTypes.AUTH_SET_STATE, payload };
}

export function cleanAuthStateAction() {
  return { type: actionTypes.AUTH_CLEAR_STATE };
}
