import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Text } from "components/ui";
import { makeStyles, styled } from "@material-ui/core";
import { CURATED_DATASETS_TEXT, UPLOADED_FILES_TEXT } from "../../data-page/components/DataRowsWrapper";
import CloseIcon16 from "assets/icons/close-icon/CloseIcon16";
import { vantiColors } from "assets/jss/palette";
import DynamicTabs from "components/ui/Tabs";
import GeneralVantiButton, { VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { useDispatch, useSelector } from "react-redux";
import { allDataConnectorsSelector } from "modules/data-connectors/selectors";
import { dataConnectorsStartFetch } from "modules/data-connectors/actions";
import { allDataCurationsSelector } from "modules/data-curations/selectors";
import { fetchCurations } from "modules/data-curations/actions";
import DatasetRubric from "../../data-page/components/dataset-rubric/DatasetRubric";
import { SOURCE_TYPES } from "../../data-page/constants";
import RadioButton24Checked from "assets/icons/radio-button/RadioButton24Checked";
import RadioButton24Unchecked from "assets/icons/radio-button/RadioButton24Unchecked";
import { ChatButtonActionType } from "@vanti-analytics-org/vanti-common";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import useChatMessages from "common/hooks/use-chat-messages";
import { FOLDER_SOURCE_TYPE } from "pages/side-bar/data-page/components/DataRow";

const FINISH_BUTTON_TEXT = "Select";
const WAITING_FOR_CHAT_RESPONSE_TEXT = "Waiting for chat response...";

const DatasetSelectionContentHeader = styled("div")({
  boxSizing: "border-box",
  justifyContent: "space-between",
  display: "flex",
  paddingBottom: 24
});

const DatasetSelectionContentBody = styled("div")({
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  paddingBottom: 16
});

const DatasetSelectionContentFooter = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "flex-end"
});

const DatasetSelectionContentBodyWrapper = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  flexWrap: "wrap",
  gap: 8,
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: "8px"
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: vantiColors.lightBlue7,
    borderRadius: "40px"
  }
});

const DynamicTabsWrapper = styled("div")({
  paddingBottom: 24
});

const DATASET_SELECTION_MODAL_HEADER_TITLE = "Choose a dataset";

const DATASET_SELECTION_MODAL_DEFAULT_TAB_TITLES = {
  CURATED_DATASETS_TAB_TITLE: CURATED_DATASETS_TEXT,
  UPLOADED_FILES_TAB_TITLE: UPLOADED_FILES_TEXT
};

const useStyles = makeStyles(theme => ({
  tabsRoot: {
    minHeight: 1
  },
  tabRoot: {
    minHeight: 1,
    minWidth: 1,
    lineHeight: "normal",
    color: vantiColors.gray33,
    boxSizing: "border-box",
    borderBottom: `2px solid white`,
    "&.Mui-selected": {
      color: "black",
      borderBottom: `2px solid ${theme.palette.vantiColors.darkBlue7}`
    }
  },
  tabsIndicator: {
    display: "none"
  },
  tabsFlexContainer: {
    display: "flex",
    gap: 16
  }
}));

const DatasetSelectionRubricHeaderStyled = styled("div")({
  display: "flex",
  justifyContent: "space-between"
});

const RadioButtonWrapperStyled = styled("div")({
  cursor: "pointer",
  width: "fit-content",
  display: "contents"
});

const ChatPageDatasetSelection = ({
  chatId,
  closeDataSelectionModal,
  useDefaultCustomRubricHeader = true,
  onClickFinish,
  onlyChatSuitableDatasets = true,
  onlyIngestedDatasets = false
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { datasetsContext, chatContext } = useContext(AppContext);
  const { datasets: datasetsObject, selectedDatasetsForCuration } = datasetsContext;
  const { setDisplayOtherDatasets, setChatsWithDisabledDatasetSelection } = chatContext;
  const { sendChatMessage } = useChatMessages();

  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isSelectButtonEnabled, setIsSelectButtonEnabled] = useState(false);

  const dataConnectors = useSelector(allDataConnectorsSelector);
  const dataCurations = useSelector(allDataCurationsSelector);

  const datasets = useMemo(() => {
    return Object.values(datasetsObject) || [];
  }, [datasetsObject]);

  const datasetSelectionTabTitles = useMemo(() => {
    let tabTitles = new Set([]);
    if (datasets.some(ds => ds.sourceType === SOURCE_TYPES.CURATION))
      tabTitles.add(DATASET_SELECTION_MODAL_DEFAULT_TAB_TITLES.CURATED_DATASETS_TAB_TITLE);
    if (datasets?.some(ds => ds.sourceType === SOURCE_TYPES.LOCAL_UPLOAD))
      tabTitles.add(DATASET_SELECTION_MODAL_DEFAULT_TAB_TITLES.UPLOADED_FILES_TAB_TITLE);

    const nonEmptyDataConnectorIds = datasets
      ?.filter(ds => ds.sourceType === SOURCE_TYPES.DATA_CONNECTOR)
      .map(ds => ds.sourceDataConnectorId.toString());
    if (nonEmptyDataConnectorIds.length) {
      for (const dataConnectorId of nonEmptyDataConnectorIds) {
        const connector = dataConnectors.find(dc => dc._id.toString() === dataConnectorId);
        if (connector) {
          tabTitles.add(connector.title);
        }
      }
    }

    return Array.from(tabTitles);
  }, [datasets, dataConnectors]);

  const CustomRubricHeader = ({ datasetId, currentCheckedRubric, onClickRadioButtonCallback }) => {
    return (
      <DatasetSelectionRubricHeaderStyled>
        <RadioButtonWrapperStyled
          onClick={() => {
            if (datasetId !== currentCheckedRubric) {
              onClickRadioButtonCallback(datasetId);
            }
          }}
        >
          {currentCheckedRubric === datasetId ? <RadioButton24Checked /> : <RadioButton24Unchecked />}
        </RadioButtonWrapperStyled>
      </DatasetSelectionRubricHeaderStyled>
    );
  };

  useEffect(async () => {
    if (dataConnectors && dataConnectors.length < 1) {
      dispatch(dataConnectorsStartFetch());
    }

    if (dataCurations && dataCurations.length < 1) {
      dispatch(fetchCurations());
    }
  }, []);

  const onClickRadioButtonCallback = useCallback(datasetId => {
    setSelectedDataset(datasetId);
    setIsSelectButtonEnabled(true);
  }, []);

  useEffect(() => {
    setIsSelectButtonEnabled(selectedDatasetsForCuration?.length);
  }, [selectedDatasetsForCuration]);

  const tabContent = useMemo(() => {
    if (!datasetSelectionTabTitles) {
      return;
    }

    let filteredDatasets;
    switch (datasetSelectionTabTitles[selectedTabIndex]) {
      case DATASET_SELECTION_MODAL_DEFAULT_TAB_TITLES.CURATED_DATASETS_TAB_TITLE:
        filteredDatasets = datasets.filter(ds => ds.sourceType === SOURCE_TYPES.CURATION);
        break;
      case DATASET_SELECTION_MODAL_DEFAULT_TAB_TITLES.UPLOADED_FILES_TAB_TITLE:
        filteredDatasets = datasets.filter(ds => ds.sourceType === SOURCE_TYPES.LOCAL_UPLOAD);
        break;
      default: {
        const dataConnector = dataConnectors.filter(dc => dc.title === datasetSelectionTabTitles[selectedTabIndex])[0];
        filteredDatasets = datasets.filter(
          ds => ds.sourceType === SOURCE_TYPES.DATA_CONNECTOR && ds.sourceDataConnectorId === dataConnector?._id
        );
        break;
      }
    }

    return filteredDatasets.map((dataset, index) => (
      <DatasetRubric
        dataset={dataset}
        key={`dataset-rubric-${index}-${dataset._id}`}
        onDownloadDataset={() => {}}
        onMoveDataset={() => {}}
        onDeleteDataset={() => {}}
        checkSupportedDatasetType={onlyChatSuitableDatasets}
        isFolder={dataset.entryType === FOLDER_SOURCE_TYPE}
        isReadOnly={true}
        hideCheckbox={onlyIngestedDatasets && !dataset.ingestedSchemaId}
        customRubricHeader={
          useDefaultCustomRubricHeader && (
            <CustomRubricHeader
              datasetId={dataset._id}
              currentCheckedRubric={selectedDataset}
              onClickRadioButtonCallback={onClickRadioButtonCallback}
              isReadOnly={true}
            />
          )
        }
      />
    ));
  }, [selectedDataset, selectedTabIndex, datasets, dataCurations, dataConnectors]);

  const handleChange = useCallback((event, newValue) => {
    setSelectedTabIndex(newValue);
  }, []);

  const onClickFinishHandler = useCallback(async () => {
    setIsSelectButtonEnabled(false);

    if (onClickFinish) {
      onClickFinish();
    } else {
      const action = `${ChatButtonActionType.USE_DATASET}/${selectedDataset}`;
      const text = datasets.find(d => d._id === selectedDataset).name || WAITING_FOR_CHAT_RESPONSE_TEXT;

      await sendChatMessage(text, chatId, action);

      if (selectedDataset) setChatsWithDisabledDatasetSelection(prevState => ({ ...prevState, [chatId]: true }));
    }

    closeDataSelectionModal();
  }, [selectedDataset, datasets, chatId, closeDataSelectionModal, setChatsWithDisabledDatasetSelection, onClickFinish]);

  return (
    <>
      <DatasetSelectionContentHeader data-testid={"dataset-selection-content-header"}>
        <Text size={18} weight={500} lineSize={"22px"}>
          {DATASET_SELECTION_MODAL_HEADER_TITLE}
        </Text>
        <CloseIcon16 onClick={() => setDisplayOtherDatasets(false)} style={{ cursor: "pointer" }} />
      </DatasetSelectionContentHeader>

      {datasetSelectionTabTitles && (
        <DynamicTabsWrapper>
          <DynamicTabs
            tabProps={{ classes: { root: classes.tabRoot } }}
            tabsProps={{
              classes: {
                root: classes.tabRoot,
                indicator: classes.tabsIndicator,
                flexContainer: classes.tabsFlexContainer
              }
            }}
            tabs={datasetSelectionTabTitles}
            selectedTabIndex={selectedTabIndex}
            handleChange={handleChange}
            indicatorColor={vantiColors.darkBlue7}
            data-testid={"dataset-selection-content-tabs"}
          />
        </DynamicTabsWrapper>
      )}

      <DatasetSelectionContentBody data-testid={"dataset-selection-content-body"}>
        {datasetSelectionTabTitles?.length && (
          <DatasetSelectionContentBodyWrapper>{tabContent}</DatasetSelectionContentBodyWrapper>
        )}
      </DatasetSelectionContentBody>

      <DatasetSelectionContentFooter>
        <GeneralVantiButton
          variant={VARIANTS.PRIMARY}
          buttonInfo={{
            disabled: !isSelectButtonEnabled,
            onClick: onClickFinishHandler,
            label: FINISH_BUTTON_TEXT
          }}
        />
      </DatasetSelectionContentFooter>
    </>
  );
};

export default memo(ChatPageDatasetSelection);
