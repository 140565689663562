import { dataConnectorsFlagTypes } from "./actionTypes";

const defaultState = {
  dataConnectors: [],
  loading: false,
  error: null,
  success: false
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case dataConnectorsFlagTypes.DATA_CONNECTORS_START_FETCH: {
      return { ...state, loading: true, success: false, error: null };
    }
    case dataConnectorsFlagTypes.DATA_CONNECTORS_FETCH_SUCCESS: {
      const dataConnectors = action.payload;
      return { ...state, loading: false, success: true, error: null, dataConnectors };
    }
    case dataConnectorsFlagTypes.DATA_CONNECTORS_FETCH_ERROR: {
      const error = action.payload;
      return { ...state, loading: false, success: false, error };
    }
    case dataConnectorsFlagTypes.DATA_CONNECTORS_SET_UPLOAD_DATA: {
      return { ...state, loading: true, error: null, success: false };
    }
    case dataConnectorsFlagTypes.DATA_CONNECTORS_UPLOAD_SET_SUCCESS: {
      return { ...state, loading: false, error: null, success: true };
    }
    case dataConnectorsFlagTypes.DATA_CONNECTORS_UPLOAD_SET_ERROR: {
      return { ...state, loading: false, error: action.payload.message, success: false };
    }
    case dataConnectorsFlagTypes.DATA_CONNECTORS_CLEAR_UPLOAD_DATA: {
      return { ...state, loading: false };
    }
    case dataConnectorsFlagTypes.DATA_CONNECTORS_UPLOAD_CLEAR_SUCCESS: {
      return { ...state, success: false };
    }
    case dataConnectorsFlagTypes.DATA_CONNECTORS_UPLOAD_CLEAR_ERROR: {
      return { ...state, error: null };
    }
    case dataConnectorsFlagTypes.DATA_CONNECTORS_ADD_CONNECTOR_TO_STATE: {
      const dataConnector = action.payload;

      const dataConnectors = [...state.dataConnectors];
      dataConnectors.push(dataConnector);

      return { ...state, dataConnectors };
    }
    case dataConnectorsFlagTypes.DATA_CONNECTORS_EDIT_CONNECTOR: {
      const dataConnector = action.payload;

      const dataConnectors = [...state.dataConnectors];
      const dataConnectorToEditIndex = dataConnectors.findIndex(connector => connector._id === dataConnector._id);

      if (dataConnectorToEditIndex > -1) {
        dataConnectors[dataConnectorToEditIndex] = dataConnector;
      }

      return { ...state, dataConnectors };
    }
    case dataConnectorsFlagTypes.DATA_CONNECTORS_REMOVE_CONNECTOR_FROM_STATE: {
      const dataConnectorId = action.payload;

      const dataConnectors = [...state.dataConnectors];
      const dataConnectorToDeleteIndex = dataConnectors.findIndex(connector => connector._id === dataConnectorId);

      if (dataConnectorToDeleteIndex > -1) {
        dataConnectors.splice(dataConnectorToDeleteIndex, 1);
      }

      return { ...state, dataConnectors };
    }

    default: {
      return state;
    }
  }
}
