import React, { memo } from "react";

const TreeViewArrowCollapsed = ({ ...rest }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g cliPath="url(#clip0_2971_5640)">
        <path d="M6.33398 11.0002L9.66732 7.66691L6.33398 4.33358L6.33398 11.0002Z" fill="#091733" />
      </g>
      <defs>
        <clipPath id="clip0_2971_5640">
          <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(TreeViewArrowCollapsed);
