import React, { memo, useCallback, useState } from "react";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import { CommonDataEntitiesTypes } from "@vanti-analytics-org/vanti-common";
import { startCase } from "lodash";
import PaintShopIcon from "assets/icons/goal-types/PaintShopIcon";
import ManualAssemblyIcon from "assets/icons/goal-types/ManualAssemblyIcon";
import VisualInspectionIcon from "assets/icons/goal-types/VisualInspectionIcon";
import CustomGoalIcon from "assets/icons/goal-types/CustomGoalIcon";
import AssemblyOptimization from "assets/icons/goal-types/AssemblyOptimization";
import AssemblyTestingElectronicIcon45 from "assets/icons/goal-types/AssemblyTestingElectronicIcon45";
import { isCommonEntity } from "../DataEntitiesPage";
import TrashIcon24 from "assets/icons/trash-icon/TrashIcon24";
import { ClickableWrapperWithHoveredClipPath } from "./activity-section/ActivityCard";

const commonEntityToIconMap = {
  [CommonDataEntitiesTypes.MACHINE]: <PaintShopIcon />,
  [CommonDataEntitiesTypes.STATION]: <ManualAssemblyIcon />,
  [CommonDataEntitiesTypes.OPERATOR]: <AssemblyTestingElectronicIcon45 />,
  [CommonDataEntitiesTypes.COMPONENT]: <VisualInspectionIcon />,
  [CommonDataEntitiesTypes.UNIT]: <AssemblyOptimization />
};
const DataEntitiesCard = ({ entity, onClickEntityCard, onDeleteEntity }) => {
  const [isHovered, setIsHovered] = useState(false);

  const onClick = useCallback(() => {
    if (entity) onClickEntityCard(entity.name.toLowerCase());
  }, [entity, onClickEntityCard]);

  return (
    <DataEntitiesCardContainer
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {commonEntityToIconMap[entity?.name?.toUpperCase()] || <CustomGoalIcon />}
      <Text weight={600}>{isCommonEntity(entity?.name) ? startCase(entity?.name?.toLowerCase()) : entity?.name}</Text>

      <ClickableWrapperWithHoveredClipPath
        onClick={event => {
          event.stopPropagation();
          onDeleteEntity(event, entity?._id);
        }}
        bgColor={"transparent"}
        borderColor={vantiColors.darkBlue6}
      >
        <TrashIcon24Styled isHovered={isHovered} stroke={vantiColors.darkBlue6} />
      </ClickableWrapperWithHoveredClipPath>
    </DataEntitiesCardContainer>
  );
};

export const TrashIcon24Styled = styled(TrashIcon24)(({ isHovered }) => ({
  opacity: isHovered ? 1 : 0,
  transition: "all ease-in-out 0.2s",
  padding: 4
}));

export const DataEntitiesCardContainer = styled("div")({
  width: 200,
  height: 168,
  backgroundColor: "white",
  padding: "18px 16px",
  gap: 8,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 4,
  cursor: "pointer",
  transition: "all ease-in-out 0.2s",
  boxShadow: "0px 8px 12px 0px rgba(170, 170, 170, 0.20)",
  "&:hover": {
    backgroundColor: vantiColors.gray36
  }
});

export default memo(DataEntitiesCard);
