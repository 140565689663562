import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import { Text } from "components/ui";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import DownloadIcon24 from "assets/icons/download-icon/DownloadIcon24";
import ExportModelIcon24 from "assets/icons/export-model-icon/ExportModelIcon24";
import usePermissions from "common/hooks/use-permissions";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import { usePDF } from "@react-pdf/renderer";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import html2canvas from "html2canvas";
import ReportExecutiveSummarySectionHeadline from "./ReportExecutiveSummarySectionHeadline";
import ReportExecutiveSummarySectionDescription from "./ReportExecutiveSummarySectionDescription";
import ChatMessageReportPDF from "../ChatMessageReportPDF";
import { DAYJS_DATE_FORMAT, getDateFormatDayjs } from "utils/dateTime";
import { constants } from "utils/constants";
import Spacer from "components/ui/Spacer";
import ChatMessageReportCreatedByTitle from "./ChatMessageReportCreatedByTitle";

const DOWNLOAD_PDF_BTN_TEXT = "Download PDF";
const EXECUTIVE_SUMMARY_INFO_TITLE = "Generated by Vanti";
const EXPORT_BTN_TEXT = "Export model";
const LOADING_PDF_TEXT = "Loading PDF...";
export const REPORT_PDF_NAME = "Report.pdf";

const ReportExecutiveSummaryInformation = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  paddingRight: 16
}));

const ReportExecutiveSummarySectionWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
});

const ReportExecutiveSummaryButtons = styled("div")({
  display: "flex",
  flexDirection: "row",
  columnGap: "16px"
});

const ChatMessageReportExecutiveSummarySection = ({ message, onExportModel, staticReportCallbacks }) => {
  const { chatContext, datasetsContext } = useContext(AppContext);
  const { setChatMessageMetadataId } = chatContext;
  const { reportData } = message;

  const isAllowedToExportChatModel = usePermissions([ROLES_ENUM.EXPORT_CHAT_MODEL]);
  const isAllowedToDownloadReportPdf = usePermissions([ROLES_ENUM.DOWNLOAD_CHAT_REPORT_PDF]);
  const isAllowedToViewJsonResponse = usePermissions([ROLES_ENUM.EDIT_DATASET_QUESTIONS]);

  const [tableAsImage, setTableAsImage] = useState(null);
  const [graphAsImage, setGraphAsImage] = useState(null);
  const [isRenderPDFDoc, setIsRenderPDFDoc] = useState(false);

  const dataset = useMemo(() => datasetsContext.datasets[(message?.reportData?.datasetId)], [
    datasetsContext.datasets,
    message?.reportData?.datasetId
  ]);

  const PDFDoc = useMemo(
    () =>
      isRenderPDFDoc && (
        <ChatMessageReportPDF
          question={reportData.question}
          description={reportData.description}
          creationInfo={`${message?.reportData?.userInitiatedReportDisplayName} | ${getDateFormatDayjs(
            message?.createdAt
          )}`}
          tableAsImage={tableAsImage}
          graphAsImage={graphAsImage}
          reportSummary={message?.reportData?.resultSummary}
          dataset={dataset}
        />
      ),
    [
      isRenderPDFDoc,
      dataset,
      reportData.question,
      reportData.description,
      message?.reportData?.userInitiatedReportDisplayName,
      message?.reportData?.resultSummary,
      message?.createdAt,
      tableAsImage,
      graphAsImage
    ]
  );

  const [PDFInstance, updatePDFInstance] = usePDF({ document: PDFDoc });

  const onClickPDFDownload = useCallback(() => {
    setIsRenderPDFDoc(true);
  }, []);

  useEffect(() => {
    if (PDFDoc) updatePDFInstance(PDFDoc);
  }, [isRenderPDFDoc]);

  useEffect(() => {
    if (isRenderPDFDoc && PDFInstance.url && !PDFInstance.loading) {
      const a = document.createElement("a");
      a.download = REPORT_PDF_NAME;
      a.href = PDFInstance.url;
      a.click();
      a.remove();
      setIsRenderPDFDoc(false);
    }
  }, [isRenderPDFDoc, PDFInstance.url, PDFInstance.loading]);

  useEffect(() => {
    const previewTableElement = document.getElementById("report-data-preview-table-wrapper");
    html2canvas(previewTableElement).then(canvas => {
      setTableAsImage(canvas.toDataURL());
    });

    const graphElement = document.getElementById(`chat-message-report-graph-section-wrapper-${message?._id}`);
    html2canvas(graphElement).then(canvas => {
      setGraphAsImage(canvas.toDataURL());
    });
  }, [document.getElementById(`chat-message-report-graph-section-wrapper-${message?._id}`)]);

  return (
    reportData && (
      <ReportExecutiveSummarySectionWrapper data-testid={`report-executive-summary-section-wrapper-${message._id}`}>
        {reportData.question && (
          <ReportExecutiveSummaryInformation
            data-testid={`report-executive-summary-section-information-${message._id}`}
          >
            <Text size={12} weight={600} lineSize={"15px"} color={vantiColors.lightGray16}>
              {EXECUTIVE_SUMMARY_INFO_TITLE}
            </Text>

            <Spacer space={24} />
            <ReportExecutiveSummarySectionHeadline
              message={message}
              onHeadlineUpdated={staticReportCallbacks?.onUpdateField}
              data-testid={`report-executive-summary-section-headline-${message._id}`}
            />
            <Spacer space={2} />

            <ReportExecutiveSummarySectionDescription
              message={message}
              onDescriptionUpdated={staticReportCallbacks?.onUpdateField}
              data-testid={`report-executive-summary-section-description-${message._id}`}
            />

            <ChatMessageReportCreatedByTitle message={message} timeFormat={DAYJS_DATE_FORMAT.DEFAULT} />
          </ReportExecutiveSummaryInformation>
        )}

        {staticReportCallbacks?.generateStaticReportButtonsGroup ? (
          staticReportCallbacks?.generateStaticReportButtonsGroup()
        ) : (
          <ReportExecutiveSummaryButtons>
            {isAllowedToViewJsonResponse && (
              <GeneralVantiButton
                size={SIZES.SMALL}
                variant={VARIANTS.SECONDARY}
                buttonInfo={{
                  onClick: () => setChatMessageMetadataId(message._id)
                }}
              >
                <Text weight={500} lineSize={"17px"} color={vantiColors.darkBlue7}>
                  {constants.chat.CHAT_MESSAGE_METADATA_BUTTON_TEXT}
                </Text>
              </GeneralVantiButton>
            )}

            {isAllowedToExportChatModel && (
              <GeneralVantiButton
                size={SIZES.SMALL}
                variant={VARIANTS.SECONDARY}
                buttonInfo={{
                  onClick: onExportModel
                }}
              >
                <Text weight={500} lineSize={"17px"} color={vantiColors.darkBlue7}>
                  {EXPORT_BTN_TEXT}
                </Text>
                <ExportModelIcon24 stroke={vantiColors.darkBlue7} />
              </GeneralVantiButton>
            )}

            {isAllowedToDownloadReportPdf && (
              <GeneralVantiButton
                size={SIZES.SMALL}
                buttonInfo={{
                  onClick: onClickPDFDownload
                }}
              >
                <Text weight={500} lineSize={"17px"} color={vantiColors.white}>
                  {PDFInstance.loading ? LOADING_PDF_TEXT : DOWNLOAD_PDF_BTN_TEXT}
                </Text>
                <DownloadIcon24 stroke={vantiColors.white} />
              </GeneralVantiButton>
            )}
          </ReportExecutiveSummaryButtons>
        )}
      </ReportExecutiveSummarySectionWrapper>
    )
  );
};

export default memo(ChatMessageReportExecutiveSummarySection);
