import { createContext, useMemo, useState } from "react";

const defaultValue = {
  datasets: {},
  datasetQuestions: {},
  connectors: [],
  uploadedFiles: [],
  curatedDatasets: [],
  selectedDatasetsForCuration: [],
  datasetFileDescriptionSelectedTabIndex: 0,
  openDatasetInfoModalId: null,
  setContext: () => {}
};

export const DatasetContext = createContext({ ...defaultValue });

export const useDatasetContext = () => {
  const [context, setContext] = useState(defaultValue);
  return useMemo(() => ({ ...context, setContext }), [context]);
};
