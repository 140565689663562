import React, { memo } from "react";

const AssemblyOptimization = ({ className }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M15 42H33C33 42 32.5 38 24 38C15.5 38 15 42 15 42Z" fill="#86E8B6" />
    <path
      d="M24 38C32.5 38 33 42 33 42H15C15 42 15.5 38 24 38ZM24 38V32M24 32H6C4.34315 32 3 30.6569 3 29V8C3 6.34315 4.34315 5 6 5H42C43.6569 5 45 6.34315 45 8V29C45 30.6569 43.6569 32 42 32H24ZM7.5 20H12.5L16.5 11L19 25L22 17L25 21.5L27.5 16L30.5 25L34.5 16.5L37 22.5H40.5"
      stroke="black"
    />
  </svg>
);

export default memo(AssemblyOptimization);
