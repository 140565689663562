import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";

import VantiBreadcrumbsComponent from "./component/index";
import { allDataConnectorsSelector } from "modules/data-connectors/selectors";

import { navigationBreadcrumbsTypeSelector } from "modules/navigation/selectors";

import { BREADCRUMB, BREADCRUMBS_TYPES, getBreadcrumbLinks } from "common/constants/NavigationConstants";
import { getStationAndModelIDs } from "utils/router";
import { useHistory, useLocation } from "react-router-dom";
import useStation from "common/hooks/use-station";
import { allDataCurationsSelector } from "modules/data-curations/selectors";

const getParamsByPath = currentPathName => {
  let pathname;
  if (currentPathName.includes("configure")) {
    pathname = "/dashboard/onboarding/configure/stations/:stationId";
  } else if (currentPathName.includes("submit")) {
    pathname = `/dashboard/onboarding/submit/stations/:stationId`;
  } else if (currentPathName.includes("onboarding/stations")) {
    pathname = `/dashboard/onboarding/stations/:stationId`;
  } else {
    pathname = `/dashboard/stations/:stationId`;
  }

  return getStationAndModelIDs(currentPathName, pathname);
};

const findResourceById = (id, resourceArray) => {
  return resourceArray.find(resource => {
    return resource._id === id;
  });
};

const VantiBreadcrumbs = () => {
  const breadcrumbsType = useSelector(navigationBreadcrumbsTypeSelector);
  const dataConnectors = useSelector(allDataConnectorsSelector);
  const dataCurations = useSelector(allDataCurationsSelector);
  const location = useLocation();
  const history = useHistory();
  const currentPathName = history.location.pathname;

  const params = getParamsByPath(currentPathName);
  const station = useStation(params.stationId);

  const baseBreadCrumb = useMemo(() => BREADCRUMB[breadcrumbsType] || BREADCRUMB.DEFAULT, [breadcrumbsType]);

  let foundConnector;
  if (breadcrumbsType === BREADCRUMBS_TYPES.NEW_CONNECTOR) {
    const pathName = location.pathname;
    const id = pathName.split("/")[3];
    const connector = findResourceById(id, dataConnectors);
    foundConnector = connector;
  }

  let foundCuration;
  if (breadcrumbsType === BREADCRUMBS_TYPES.NEW_CURATION) {
    const pathName = location.pathname;
    const id = pathName.split("/")[3];
    const curation = findResourceById(id, dataCurations);
    foundCuration = curation;
  }

  const breadCrumbsLinks = useMemo(
    () =>
      getBreadcrumbLinks(breadcrumbsType, {
        stationName: station?.name,
        stationId: station?.id,
        stationNumOfModels: station?.numOfModels,
        foundConnectorTitle: foundConnector?.title,
        foundConnectorId: foundConnector?._id,
        foundCurationName: foundCuration?.name,
        foundCurationId: foundCuration?._id
      }),
    [
      breadcrumbsType,
      station?.name,
      station?.numOfModels,
      station?.id,
      foundConnector?.title,
      foundConnector?.id,
      foundCuration?.name,
      foundCuration?.id
    ]
  );

  return <VantiBreadcrumbsComponent baseBreadCrumb={baseBreadCrumb} links={breadCrumbsLinks} />;
};

export default memo(VantiBreadcrumbs);
