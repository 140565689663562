import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { FlexItem, Text } from "components/ui";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import styles from "../../../styles";
import { vantiColors } from "../../../../../../assets/jss/palette";
import ChevronLeft from "../../../../../../assets/icons/ChevronLeft";
import ChevronRight from "../../../../../../assets/icons/ChevronRight";
import { ScrollTextStyled } from "../SingleDataCuration.style";

const CurationCSVInputPreview = ({ fileData = {}, id, ...props }) => {
    const classes = styles();
    const firstColumn = useRef(null);
    const lastColumn = useRef(null);
    const [columnIndex, setColumnIndex] = useState(-1);

    const StyledPaperComponent = props => {
        return <Paper {...props} className={classes.tableContainer} />;
    };
    const scrollToEndOrStart = useCallback(
        columnIndex => {
            if (columnIndex === -1) {
                lastColumn.current.scrollIntoView({ block: "nearest" });
                return;
            }
            firstColumn.current.scrollIntoView({ block: "nearest" });
        },
        [columnIndex, lastColumn.current, firstColumn.current]
    );
    const { headers, body } = fileData;

    const bodyContent = useMemo(() => {
        return body.map((row, index) => {
            return (
                <TableRow key={`${row}-${index}`}>
                    {row.map((value, index) => (
                        <TableCell title={value} key={`${value}-${index}`}>
                            {value}
                        </TableCell>
                    ))}
                </TableRow>
            );
        });
    }, [body]);

    return (
        <FlexItem container dense="full" data-testid={`curation-csv-input-preview-${id}`} {...props}>
            <div className={classes.dataTableContainer}>
                <div className={classes.wrapper}>
                    <div className={classes.scrollContainer}>
                        <TableContainer component={StyledPaperComponent}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header, index) => (
                                            <TableCell
                                                id={`table-column-${index}`}
                                                key={header}
                                                ref={index === headers.length - 1 ? lastColumn : index === 0 ? firstColumn : null}
                                            >
                                                <div className={classes.heading}>
                          <span className={classes.headCell}>
                            <Text style={{fontWeight:"bold"}}>{header}</Text>
                          </span>
                                                </div>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>{bodyContent}</TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
            <ScrollTextStyled>
                <Text
                    size={14}
                    data-testid={`scroll-to-${columnIndex === 0 ? "start" : "end"}`}
                    color={vantiColors.darkBlue7}
                    className={classes.scrollButton}
                    onClick={() => {
                        setColumnIndex(columnIndex === 0 ? -1 : 0);
                        scrollToEndOrStart(columnIndex);
                    }}
                >
                    {columnIndex === 0 && (
                        <span className={classes.chevronLeft}>
              <ChevronLeft />
            </span>
                    )}
                    Scroll to {columnIndex === 0 ? "start" : "end"}
                    {columnIndex !== 0 && (
                        <span className={classes.chevronRight}>
              <ChevronRight />
            </span>
                    )}
                </Text>
            </ScrollTextStyled>
        </FlexItem>
    );
};

export default memo(CurationCSVInputPreview);