import { useState, useEffect } from "react";
import { logger } from 'utils/logger'
import { getRootFolder } from "services/api/fileExplorer";

const defaultRootFoldeName = "root";

const useRoot = connectorId => {
  const [rootFolder, setRootFolder] = useState(null);

  useEffect(() => {
    getRootFolder(connectorId)
      .then(res => {
        if (res) {
          setRootFolder({
            id: res,
            name: res,
            path: res,
            isDir: true,
            chain: true,
            exact: true
          });
        } else {
          setRootFolder({
            id: defaultRootFoldeName,
            name: defaultRootFoldeName,
            path: defaultRootFoldeName,
            isDir: true,
            chain: true
          });
        }
      })
      .catch(err => {
        setRootFolder({
          id: defaultRootFoldeName,
          name: defaultRootFoldeName,
          path: defaultRootFoldeName,
          isDir: true,
          chain: true
        });

        logger.error("Error happened when fetching root folder: ", err);
      });
  }, [connectorId]);

  return rootFolder;
};

export default useRoot;
