/* eslint-disable max-lines */
import React, { useEffect, useState, Fragment } from "react";
import * as PropTypes from "prop-types";
import * as emailServices from "services/admin/emails";
import * as utilsServices from "services/api/utils";
import ReactHtmlParser from "react-html-parser";
import Handlebars from "utils/handlebars";
import Card from "components/ui/Card/Card";
import CardBody from "components/ui/Card/CardBody";
import CardHeader from "components/ui/Card/CardHeader";
import FlexContainer from "components/ui/Flex/FlexContainer";
import FlexItem from "components/ui/Flex/FlexItem";
import Text from "components/ui/Typography/Text";
import BrandedTitle from "components/ui/Typography/BrandedTitle";
import { useParams } from "react-router-dom";
import Ajv from "ajv";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/ui/Buttons/Button";
import ReactResizeDetector from "react-resize-detector";
import TextUnderlinedInput from "components/ui/Inputs/TextUnderlinedInput";
import DeleteIcon from "@material-ui/icons/Delete";
import SimpleAlert from "components/ui/Alert/SimpleAlert";
import { useHistory } from "react-router-dom";
import CustomSelect from "components/ui/Select/CustomSelect";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as BackButtonIcon } from "../../../assets/img/back-button.svg";
import { logger } from "utils/logger";

const bodyAjv = new Ajv({ allErrors: true });
const subjectAjv = new Ajv({ allErrors: true });

const styles = theme => ({
  emailEditMainContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflowY: "auto"
  },
  textarea: {
    width: "100%",
    height: "200px"
  },
  htmlContainer: {
    width: "100%"
  },
  dragAndDropArea: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.vantiColors.lightBlue5,
    cursor: "pointer",
    boxSizing: "border-box",
    height: "80px",
    width: "100%",
    border: "1px dashed #00818A",
    padding: "0 30px"
  },
  dragAndDropAreaText: {
    display: "flex",
    alignItems: "center"
  },
  emailListContainer: {
    width: "100%",
    height: "100%",
    flexWrap: "nowrap"
  }
});

const useStyles = makeStyles(styles);

function StateStatus({ state }) {
  return (
    <Fragment>
      {state.success === true && <Text color="green">Success</Text>}
      {state.success === false && <Text color="danger">Error {state.error && state.error.message}</Text>}
    </Fragment>
  );
}

StateStatus.propTypes = {
  state: PropTypes.object
};

function SuccessOrErrors({ errors }) {
  return (
    <Fragment>
      {" "}
      {errors.length > 0 &&
        errors.map((message, i) => (
          <Text color="danger" key={i}>
            - {message}
          </Text>
        ))}
      {errors.length === 0 && <Text color="green">All Good</Text>}
    </Fragment>
  );
}

SuccessOrErrors.propTypes = {
  errors: PropTypes.array
};

export default function EmailsEdit() {
  const classes = useStyles();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { emailId } = useParams();
  const [fetchError, setFetchError] = useState(null);
  const [email, setEmail] = useState([]);
  const [title, setTitle] = useState("");
  const [constants, setConstants] = useState("{}");
  const [action, setAction] = useState("NULL");
  const [body, setBody] = useState("");
  const [bodySchema, setBodySchema] = useState("{}");
  const [subject, setSubject] = useState("");
  const [subjectSchema, setSubjectSchema] = useState("{}");
  const [infoState, setInfoState] = useState({ sending: false, success: null, error: null });
  const [constantsState, setConstantsState] = useState({ sending: false, success: null, error: null });
  const [bodyState, setBodyState] = useState({ sending: false, success: null, error: null });
  const [subjectState, setSubjectState] = useState({ sending: false, success: null, error: null });
  const [deleteState, setDeleteState] = useState({ sending: false, success: null, error: null });
  const [textareaHeight, setTextareaHeight] = useState(200);
  const [emailActions, setEmailActions] = useState([]);
  useEffect(() => {
    utilsServices.getEmailActionsEnums().then(setEmailActions);
  }, []);

  useEffect(() => {
    emailServices
      .getEmail(emailId)
      .then(email => {
        logger.info(email);
        setAction(email.action || "NULL");
        setTitle(email.title);
        setEmail(email);
        setConstants(JSON.stringify(email.constants || {}, null, 2));
        setBody(decodeURIComponent(email.body));
        setBodySchema(JSON.stringify(JSON.parse(email.bodySchema || "{}"), null, 2));
        setSubject(decodeURIComponent(email.subject));
        setSubjectSchema(JSON.stringify(JSON.parse(email.subjectSchema || "{}"), null, 2));
      })
      .catch(setFetchError);
  }, [emailId]);

  const onUpdateInfo = () => {
    setInfoState({ sending: true, success: null, error: null });
    emailServices
      .updateEmail(emailId, {
        title: title,
        action: action === "NULL" ? undefined : action
      })
      .then(() => {
        setInfoState({ sending: false, success: true, error: null });
      })
      .catch(error => {
        setInfoState({ sending: false, success: false, error: error });
      });
  };

  const onUpdateBody = () => {
    setBodyState({ sending: true, success: null, error: null });
    emailServices
      .updateEmail(emailId, {
        body: encodeURIComponent(body),
        bodySchema: JSON.stringify(JSON.parse(bodySchema))
      })
      .then(() => {
        setBodyState({ sending: false, success: true, error: null });
      })
      .catch(error => {
        setBodyState({ sending: false, success: false, error: error });
      });
  };

  const onUpdateSubject = () => {
    setSubjectState({ sending: true, success: null, error: null });
    emailServices
      .updateEmail(emailId, {
        subject: encodeURIComponent(subject),
        subjectSchema: JSON.stringify(JSON.parse(subjectSchema))
      })
      .then(() => {
        setSubjectState({ sending: false, success: true, error: null });
      })
      .catch(error => {
        setSubjectState({ sending: false, success: false, error: error });
      });
  };

  let _bodySchema;
  const _bodySchemaErrors = [];
  try {
    _bodySchema = JSON.parse(bodySchema);
    if (!bodyAjv.validateSchema(_bodySchema)) {
      _bodySchemaErrors.push(...bodyAjv.errorsText(bodyAjv.errors, { separator: ";" }).split(";"));
    }
  } catch (e) {
    _bodySchemaErrors.push(e.message);
  }

  const _bodyErrors = [];
  try {
    Handlebars.compile(body)({});
  } catch (e) {
    _bodyErrors.push(e.message);
  }

  let _subjectSchema;
  const _subjectSchemaErrors = [];
  try {
    _subjectSchema = JSON.parse(subjectSchema);
    if (!subjectAjv.validateSchema(_subjectSchema)) {
      _subjectSchemaErrors.push(...subjectAjv.errorsText(subjectAjv.errors, { separator: ";" }).split(";"));
    }
  } catch (e) {
    _subjectSchemaErrors.push(e.message);
  }

  const _subjectErrors = [];
  try {
    Handlebars.compile(subject)({});
  } catch (e) {
    _subjectErrors.push(e.message);
  }

  let _constantsErrors = [];
  let _constantsBodyErrors = [];
  let _constantsSubjectErrors = [];
  try {
    const _constants = JSON.parse(constants);
    if (_bodySchema) {
      try {
        if (_bodySchema.required) {
          _bodySchema.required = _bodySchema.required.filter(item => item === "constants");
        }
        if (!bodyAjv.validate(_bodySchema, { constants: _constants })) {
          _constantsBodyErrors.push(...bodyAjv.errorsText(bodyAjv.errors, { separator: ";" }).split(";"));
        }
      } catch (error) {
        _constantsBodyErrors.push(error.message);
      }
    }
    if (_subjectSchema) {
      try {
        if (_subjectSchema.required) {
          _subjectSchema.required = _subjectSchema.required.filter(item => item === "constants");
        }
        if (!subjectAjv.validate(_subjectSchema, { constants: _constants })) {
          _constantsSubjectErrors.push(...subjectAjv.errorsText(subjectAjv.errors, { separator: ";" }).split(";"));
        }
      } catch (error) {
        _constantsSubjectErrors.push(error.message);
      }
    }
  } catch (error) {
    _constantsErrors.push(error.message);
  }

  const onUpdateConstants = () => {
    setConstantsState({ sending: true, success: null, error: null });
    emailServices
      .updateEmail(emailId, {
        constants: JSON.parse(constants)
      })
      .then(() => {
        setConstantsState({ sending: false, success: true, error: null });
      })
      .catch(error => {
        setConstantsState({ sending: false, success: false, error: error });
      });
  };

  const onConfirmDelete = () => {
    setDeleteState({ sending: true, success: null, error: null });
    emailServices
      .deleteEmail(emailId)
      .then(() => {
        setDeleteState({ sending: false, success: true, error: null });
        history.push(`/admin/emails`);
        setIsModalOpen(false);
      })
      .catch(error => {
        setDeleteState({ sending: false, success: false, error: error });
      });
  };

  const onAbordDelete = () => {
    setIsModalOpen(false);
  };

  const bodySaveDisables = _bodySchemaErrors.length !== 0 || _bodyErrors.length !== 0 || bodyState.sending;
  const subjectSaveDisables = _subjectSchemaErrors.length !== 0 || _subjectErrors.length !== 0 || subjectState.sending;
  const constantSaveDisables =
    _constantsErrors.length !== 0 ||
    _constantsBodyErrors.length !== 0 ||
    _constantsSubjectErrors.length !== 0 ||
    constantsState.sending;
  return (
    <div className={classes.emailEditMainContainer}>
      <SimpleAlert
        isOpen={isModalOpen}
        onConfirm={onConfirmDelete}
        confirmLabel={"Delete"}
        onCancel={onAbordDelete}
        cancelLabel={"Cancel"}
      >
        <Text color={"dark"} size="22px">
          Clicking on <b>Delete</b> will erase all data related to this email.
        </Text>
        <br />
        <Text color={"dark"} size="22px">
          Are you sure?
        </Text>
        <StateStatus state={deleteState} />
      </SimpleAlert>
      <FlexContainer alignItems={"center"} direction="column" key={email.id} className={classes.emailListContainer}>
        <FlexItem xs={12} sm={12} md={12} container justifyContent="space-between">
          <div style={{ display: "flex" }}>
            <Button transparent={true} style={{ padding: "0 10px 0 0" }} onClick={() => history.push("/admin/emails")}>
              <BackButtonIcon />
            </Button>
            <BrandedTitle text="Edit Email" size="30px" />
          </div>
          <Button transparent={true} onClick={() => setIsModalOpen(true)} style={{ padding: "0" }}>
            <DeleteIcon />
          </Button>
        </FlexItem>
        {fetchError && (
          <FlexContainer justifyContent="center" alignItems="center">
            <FlexItem xs={12} sm={12} md={12} container direction="column">
              <Text color="danger">{fetchError.message}</Text>
            </FlexItem>
          </FlexContainer>
        )}
        <FlexItem xs={12} sm={12} md={12} container>
          <Card>
            <CardHeader bottomBorder>
              <Text color="darkBlue" size="20px" weight="bold">
                Info
              </Text>
            </CardHeader>
            <CardBody>
              <FlexContainer>
                <FlexItem xs={12} sm={12} md={12} container direction="column">
                  <TextUnderlinedInput
                    labelText="ID"
                    id="email-edit-id"
                    formControlProps={{}}
                    labelProps={{ shrink: true }}
                    inputProps={{
                      value: email.id,
                      disabled: true
                    }}
                  />
                  <TextUnderlinedInput
                    labelText="Title"
                    id="email-edit-title"
                    formControlProps={{}}
                    labelProps={{ shrink: true }}
                    inputProps={{
                      value: title,
                      onChange: e => setTitle(e.target.value)
                    }}
                  />
                  <CustomSelect
                    labelText="Action"
                    color="green"
                    id={"user-type-select"}
                    formControlProps={{ style: { margin: "0" } }}
                    labelProps={{}}
                    selectProps={{
                      value: action,
                      displayEmpty: true,
                      onChange: event => {
                        setAction(event.target.value);
                      }
                    }}
                    inputProps={{}}
                  >
                    <MenuItem value={"NULL"}>Null</MenuItem>
                    {emailActions.map(action => (
                      <MenuItem value={action} key={action}>
                        {action}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FlexItem>
                <FlexItem xs={12} sm={12} md={12} container direction="column">
                  <Button color="green" disabled={!title} onClick={onUpdateInfo}>
                    Update
                  </Button>
                  <StateStatus state={infoState} />
                </FlexItem>
              </FlexContainer>
            </CardBody>
          </Card>
        </FlexItem>
        <FlexItem xs={12} sm={12} md={12} container>
          <Card>
            <CardHeader bottomBorder>
              <Text color="darkBlue" size="20px" weight="bold">
                Body
              </Text>
            </CardHeader>
            <CardBody>
              <FlexContainer alignItems={"center"} key={email.id}>
                <FlexItem xs={12} sm={6} md={6} container>
                  <Text color="darkBlue" size="14px" weight="bold">
                    Body Raw:
                  </Text>
                  <textarea
                    style={{ height: textareaHeight }}
                    className={classes.textarea}
                    value={body}
                    onChange={e => {
                      setBody(e.target.value);
                    }}
                  />
                </FlexItem>
                <FlexItem xs={12} sm={6} md={6} container>
                  <Text color="darkBlue" size="14px" weight="bold">
                    Body Html:
                  </Text>
                  <div className={classes.htmlContainer}>
                    <ReactResizeDetector handleHeight onResize={(_, height) => setTextareaHeight(height)} />
                    {ReactHtmlParser(body)}
                  </div>
                </FlexItem>
                <FlexItem xs={12} sm={12} md={12} container direction="column">
                  <Text color="darkBlue" size="14px" weight="bold">
                    Body Issues:
                  </Text>
                  <SuccessOrErrors errors={_bodyErrors} />
                </FlexItem>
                <FlexItem xs={12} sm={12} md={12} container>
                  <Text color="darkBlue" size="14px" weight="bold">
                    JSON Schema:
                  </Text>
                  <textarea
                    className={classes.textarea}
                    value={bodySchema}
                    onChange={e => setBodySchema(e.target.value)}
                  ></textarea>
                </FlexItem>
                <FlexItem xs={12} sm={12} md={12} container direction="column">
                  <Text color="darkBlue" size="14px" weight="bold">
                    JSON Schema Issues:
                  </Text>
                  <SuccessOrErrors errors={_bodySchemaErrors} />
                </FlexItem>
                <FlexItem xs={12} sm={12} md={12} container direction="column">
                  <Button color="green" disabled={bodySaveDisables} onClick={onUpdateBody}>
                    Save
                  </Button>
                  <StateStatus state={bodyState} />
                </FlexItem>
              </FlexContainer>
            </CardBody>
          </Card>
        </FlexItem>
        <FlexItem xs={12} sm={12} md={12} container>
          <Card>
            <CardHeader bottomBorder>
              <Text color="darkBlue" size="20px" weight="bold">
                Subject
              </Text>
            </CardHeader>
            <CardBody>
              <FlexContainer alignItems={"center"} key={email.id}>
                <FlexItem xs={12} sm={12} md={12} container>
                  <Text color="darkBlue" size="14px" weight="bold">
                    Subject Raw:
                  </Text>
                  <textarea
                    className={classes.textarea}
                    value={subject}
                    onChange={e => {
                      setSubject(e.target.value);
                    }}
                  />
                </FlexItem>
                <FlexItem xs={12} sm={12} md={12} container direction="column">
                  <Text color="darkBlue" size="14px" weight="bold">
                    Subject Issues:
                  </Text>
                  <SuccessOrErrors errors={_subjectErrors} />
                </FlexItem>
                <FlexItem xs={12} sm={12} md={12} container>
                  <Text color="darkBlue" size="14px" weight="bold">
                    JSON Schema:
                  </Text>
                  <textarea
                    className={classes.textarea}
                    value={subjectSchema}
                    onChange={e => setSubjectSchema(e.target.value)}
                  ></textarea>
                </FlexItem>
                <FlexItem xs={12} sm={12} md={12} container direction="column">
                  <Text color="darkBlue" size="14px" weight="bold">
                    JSON Schema Issues:
                  </Text>
                  <SuccessOrErrors errors={_subjectSchemaErrors} />
                </FlexItem>
                <FlexItem xs={12} sm={12} md={12} container direction="column">
                  <Button color="green" disabled={subjectSaveDisables} onClick={onUpdateSubject}>
                    Save
                  </Button>
                  <StateStatus state={subjectState} />
                </FlexItem>
              </FlexContainer>
            </CardBody>
          </Card>
        </FlexItem>
        <FlexItem xs={12} sm={12} md={12} container>
          <Card>
            <CardHeader bottomBorder>
              <Text color="darkBlue" size="20px" weight="bold">
                Constants
              </Text>
            </CardHeader>
            <CardBody>
              <FlexContainer alignItems={"center"} key={email.id}>
                <FlexItem xs={12} sm={12} md={12} container>
                  <Text color="darkBlue" size="14px" weight="bold">
                    Constants:
                  </Text>
                  <textarea
                    className={classes.textarea}
                    value={constants}
                    onChange={e => setConstants(e.target.value)}
                  ></textarea>
                </FlexItem>
                <FlexItem xs={12} sm={12} md={12} container direction="column">
                  <Text color="darkBlue" size="14px" weight="bold">
                    Constants Issues:
                  </Text>
                  <SuccessOrErrors errors={_constantsErrors} />
                  <Text color="darkBlue" size="14px" weight="bold">
                    Constants Body Issues:
                  </Text>
                  <SuccessOrErrors errors={_constantsBodyErrors} />
                  <Text color="darkBlue" size="14px" weight="bold">
                    Constants Subject Issues:
                  </Text>
                  <SuccessOrErrors errors={_constantsSubjectErrors} />
                </FlexItem>
                <FlexItem xs={12} sm={12} md={12} container direction="column">
                  <Button color="green" disabled={constantSaveDisables} onClick={onUpdateConstants}>
                    Save
                  </Button>
                  <StateStatus state={constantsState} />
                </FlexItem>
              </FlexContainer>
            </CardBody>
          </Card>
        </FlexItem>
      </FlexContainer>
    </div>
  );
}
