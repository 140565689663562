import React, { memo } from "react";

import CustomDropDown from "components/ui/Inputs/DropDown/CustomDropdown";

import { disabledFieldDefaultValue } from "pages/side-bar/data-connectors/constants";

const DataSourceSelect = ({ field, disabled, onChange, values }) => {
  return (
    <CustomDropDown
      dataTestId={field.title}
      formControlProps={{
        fullWidth: true,
        style: { margin: 0 }
      }}
      labelProps={{ shrink: true }}
      inputProps={{
        disabled: disabled,
        style: { margin: 0, fontStyle: "normal" },
        "data-testid": `new-connector-field-${field.title}-dropdown`,
        value: disabled ? disabledFieldDefaultValue : values[field],
        defaultValue: disabled ? disabledFieldDefaultValue : field.defaultValue,
        onChange: e => {
          onChange(e.target.value, field.key);
        },
        displayEmpty: true,
        renderValue: selected => {
          if (!selected) {
            return <span>{field.title}</span>;
          }
          return selected;
        }
      }}
      options={(field.selectValues || []).map(param => {
        return { id: param, name: param };
      })}
    />
  );
};

export default memo(DataSourceSelect);
