import React, { memo } from "react";
import { FlexItem, Text } from "components/ui";

const NumberedSection = ({ index, title, subtitle, children, ...props }) => {
  return (
    <FlexItem data-testid={`${title}-section`} container {...props}>
      <FlexItem container dense="full" alignItems="center">
        <FlexItem dense="full">
          <Text data-testid={`title-${title}`} size={16} weight="bold">
            {index ? `${index}. ${title}` : title}
          </Text>
        </FlexItem>

        {subtitle && (
          <FlexItem dense="rightTopBottom">
            <Text data-testid={`${subtitle ? subtitle : "subtitle"}`} size={14} weight="bold">
              {subtitle}
            </Text>
          </FlexItem>
        )}
      </FlexItem>

      <FlexItem xs={12} dense="horizontal">
        {children}
      </FlexItem>
    </FlexItem>
  );
};

export default memo(NumberedSection);
