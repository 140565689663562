import React, { forwardRef, memo } from "react";
import classNames from "classnames";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import styles from "./Button.style";

const useStyles = makeStyles(styles);
const colors = [
  "dark",
  "darkBlue",
  "lightBlue",
  "green",
  "turquoise",
  "darkGray",
  "gray",
  "lightGrayBlue",
  "transparent",
  "danger"
];

const RegularButton = (props, ref) => {
  const classes = useStyles();
  const {
    color,
    round,
    children,
    disabled,
    simple,
    size,
    block,
    transparent,
    link,
    className,
    muiClasses,
    ...rest
  } = props;
  const { style = {}, ...otherRest } = rest;
  const preDefinedColor = colors.find(option => option === color);
  const btnClasses = classNames(classes.button, className, {
    [classes.round]: round,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.transparent]: transparent,
    [classes[size]]: size,
    [classes[color]]: preDefinedColor
  });

  const styles = {
    ...(color && !preDefinedColor && { backgroundColor: color }),
    ...style
  };
  return (
    <Button {...otherRest} classes={muiClasses} className={btnClasses} style={styles} ref={ref}>
      {children}
    </Button>
  );
};

RegularButton.propTypes = {
  color: PropTypes.oneOf(colors),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  transparent: PropTypes.bool,
  link: PropTypes.bool,
  className: PropTypes.string,
  muiClasses: PropTypes.object,
  children: PropTypes.node
};

export default memo(forwardRef(RegularButton));
