import http from "../http";
import config from "common/config";

const chatContextApi = `${config.serverUrl}/api/v2/chat-context`;
export const FETCH_CHAT_CONTEXT_API_KEY = "fetch.chat-context";

export async function getChatContext(relatedEntityId, contextType) {
  const res = await http.get(`${chatContextApi}/${relatedEntityId}/${contextType}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function updateChatContext(relatedEntityId, contextType, context) {
  const res = await http.patch(`${chatContextApi}/${relatedEntityId}/${contextType}`, { context });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}
