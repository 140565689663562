import { Snackbar } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { vantiColors } from "assets/jss/vanti-dashboard.js";
import classNames from "classnames";
import React, { memo, useEffect, useState } from "react";
import * as PropTypes from "prop-types";

const useStyles = makeStyles({
  vantiBackground: {
    backgroundColor: vantiColors.green1
  }
});

// TODO: use Ben's vanti button.
function SimpleNotification({ open, anchorOrigin, onClose, message, severity, ...rest }) {
  const classes = useStyles(useStyles);
  const [notificationTop, setNotificationTop] = useState(0);

  const errorMessageStyle = classNames({
    [classes.vantiBackground]: severity === "success"
  });

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert className={errorMessageStyle} elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const TopSnackBar = withStyles({
    anchorOriginTopCenter: {
      top: `${notificationTop}px`,
      justifyContent: "center"
    }
  })(Snackbar);

  useEffect(() => {
    // Append notification to the AppBar
    const extraSpace = 10;
    setNotificationTop(extraSpace + document.getElementsByTagName("header")[0].clientHeight);
  }, []);

  return (
    <TopSnackBar open={open} onClose={onClose} anchorOrigin={anchorOrigin} {...rest}>
      <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </TopSnackBar>
  );
}
export default memo(SimpleNotification);

SimpleNotification.propTypes = {
  open: PropTypes.bool,
  anchorOrigin: PropTypes.object,
  onClose: PropTypes.func,
  message: PropTypes.string,
  severity: PropTypes.oneOf(["error", "warning", "info", "success"])
};
