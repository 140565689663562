import { put, takeLatest, call } from "redux-saga/effects";
import { logger } from 'utils/logger'
import predict, { predictOnFile } from "services/api/predict";
import provideFeedback from "services/api/feedback";

import { ACTION_TYPES } from "../actions/index";

function* predictSaga({ payload: { body, token } }) {
  try {
    const res = yield call(predict, { body, token });

    yield put({ type: ACTION_TYPES.UPDATE_PREDICT, payload: res });
  } catch (error) {
    logger.error("error: ", error);
  }
}

function* provideFeedbackSaga({ payload: { body, token } }) {
  try {
    const res = yield call(provideFeedback, { body, token });

    yield put({ type: ACTION_TYPES.UPDATE_FEEDBACK, payload: res });
  } catch (error) {
    logger.error("error: ", error);
  }
}

function* filePredictSaga({ payload: { body, stationId, token, contentType } }) {
  try {
    const res = yield call(predictOnFile, { body, stationId, contentType, token });

    yield put({ type: ACTION_TYPES.UPDATE_PREDICT, payload: res });
  } catch (error) {
    logger.error("error: ", error);
  }
}

export default function* sandboxWatcher() {
  yield takeLatest(ACTION_TYPES.PREDICT, predictSaga);
  yield takeLatest(ACTION_TYPES.FILE_PREDICT, filePredictSaga);
  yield takeLatest(ACTION_TYPES.PROVIDE_FEEDBACK, provideFeedbackSaga);
}
