import React, { memo } from "react";

const NewDefectDetectionIcon = ({ className }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0667 16.5L33.4001 19.734L30.4001 21.5L35.4001 28.9794L25.7334 23L25.6167 29.25L23.4001 24.5L21.9001 26L21.6778 28L14.6501 28.5795L18.4001 25L12.9001 22.0192L17.1742 21L14.6501 14.25L18.4001 17.5H21.2388L22.4001 19.5L24.0667 16.5Z"
      fill="#86E8B6"
    />
    <path
      d="M13.4001 4L17.1742 10.5M37.4001 6L27.4001 10.5L25.7334 13.5M17.1742 10.5L25.7334 13.5M17.1742 10.5L21.2388 17.5M25.7334 13.5L24.0667 16.5M47.4001 17.5L33.4001 19.734M41.9001 33L35.4001 28.9794M30.4001 39.5L27.8334 34M19.9001 44L21.1223 33M7.40005 35.5L14.6501 28.5795M0.400055 25L12.9001 22.0192M21.2388 17.5L22.4001 19.5L24.0667 16.5M21.2388 17.5H18.4001L14.6501 14.25M3.40005 10.5L10.9001 11L14.6501 14.25M14.6501 14.25L17.1742 21L12.9001 22.0192M12.9001 22.0192L18.4001 25L14.6501 28.5795M14.6501 28.5795L21.6778 28M21.6778 28L21.9001 26L23.4001 24.5L25.6167 29.25M21.6778 28L21.1223 33M21.1223 33L27.8334 34M27.8334 34L25.6167 29.25M25.6167 29.25L25.7334 23L35.4001 28.9794M35.4001 28.9794L30.4001 21.5L33.4001 19.734M24.0667 16.5L33.4001 19.734"
      stroke="black"
    />
  </svg>
);

export default memo(NewDefectDetectionIcon);
