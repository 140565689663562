import React, { useState, useMemo, useCallback, useEffect, useRef, memo } from "react";
import { ChonkyActions, FileHelper } from "chonky";
import CommonFileExplorer from "../common-file-explorer/CommonFileExplorer";
import { FILES_SORT_BY } from "../common-file-explorer/CommonFileExplorer";
import useFiles from "./use-files";
import useRoot from "./use-root";

const StorageFileBrowser = ({ connectorId, onFileSelect, onFileUpload, onFolderSelect }) => {
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [sortBy, setSortBy] = useState({
    key: FILES_SORT_BY.KEYS.NAME,
    order: FILES_SORT_BY.ORDER.ASC
  });

  const fileExplorerRef = useRef();

  useEffect(() => {
    if (fileExplorerRef.current) {
      const y = fileExplorerRef.current.getBoundingClientRect().top + window.scrollY;
      window.scroll({
        top: y,
        behavior: "smooth"
      });
    }
  }, [fileExplorerRef.current]);

  const rootFolder = useRoot(connectorId);

  const onSortClick = key => {
    if (key === sortBy.key) {
      setSortBy({
        key,
        order: sortBy.order === FILES_SORT_BY.ORDER.ASC ? FILES_SORT_BY.ORDER.DESC : FILES_SORT_BY.ORDER.ASC
      });
    } else {
      setSortBy({
        key,
        order: FILES_SORT_BY.ORDER.ASC
      });
    }
  };

  const [folderChain, root, prefix] = useMemo(() => {
    if (currentFolderId) {
      const splittedId = currentFolderId.split("/");

      const calculatedFolderChain = splittedId.reduce(
        (acc, item, index) => {
          if (item) {
            acc.push({
              id: item,
              path: splittedId.slice(0, index + 1).join("/"),
              name: item,
              isDir: true,
              chain: true
            });
          }

          return acc;
        },
        [rootFolder]
      );

      const calculatedRoot = rootFolder.exact ? rootFolder.name : calculatedFolderChain[1]?.name;
      const calculatedPrefix =
        currentFolderId === calculatedRoot ? "" : currentFolderId.replace(`${calculatedRoot}/`, "");

      return [calculatedFolderChain, calculatedRoot, calculatedPrefix];
    }

    return [[rootFolder], undefined, undefined];
  }, [currentFolderId, rootFolder]);

  const [files, error] = useFiles(connectorId, prefix, root, sortBy);

  const handleFileAction = useCallback(
    data => {
      if (data.id === ChonkyActions.OpenFiles.id) {
        const { targetFile, files } = data.payload;
        const fileToOpen = targetFile ?? files[0];

        if (fileToOpen) {
          if (FileHelper.isDirectory(fileToOpen)) {
            let newFolderId = null;

            if (fileToOpen.id !== rootFolder.id) {
              newFolderId = `${fileToOpen.chain ? fileToOpen.path : fileToOpen.id}`;

              if (root && !newFolderId.includes(root)) {
                newFolderId = `${root}/${newFolderId}`;
              }
            }

            setCurrentFolderId(newFolderId);
          } else {
            onFileUpload(`${root || rootFolder.id}/${fileToOpen.id}`);
          }
        }
      } else if (data.id === ChonkyActions.MouseClickFile.id) {
        const { file } = data.payload;

        if (file && !FileHelper.isDirectory(file)) {
          onFileSelect(`${root || rootFolder.id}/${file.id}`);
        } else if (FileHelper.isDirectory(file)) {
          if (onFolderSelect) onFolderSelect(`${root || rootFolder.id}/${file.id}`);
        }
      }
    },
    [setCurrentFolderId, onFileSelect, root, rootFolder]
  );

  return (
    rootFolder && (
      <CommonFileExplorer
        wrapperRef={fileExplorerRef}
        error={error}
        files={files}
        folderChain={folderChain}
        onFileAction={handleFileAction}
        onSortClick={onSortClick}
        sortBy={sortBy}
      />
    )
  );
};

export default memo(StorageFileBrowser);
