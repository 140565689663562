import { useSelector } from "react-redux";
import { rolesSelector } from "modules/user/state/selectors";
import { ROLES_ENUM } from "common/constants/AccountConstants";

const usePermissions = requiredRoles => {
  const roles = useSelector(rolesSelector);

  const restrictedRole = requiredRoles.some(role => {
    if (roles?.includes(role)) {
      return false;
    }

    return true;
  });

  return !restrictedRole;
};

export default usePermissions;
