import { Popover, styled } from "@material-ui/core";
import { ROLE } from "@vanti-analytics-org/vanti-common";
import CheckboxFilled24 from "assets/icons/checkbox/CheckboxFilled24";
import CheckboxUnfilled24 from "assets/icons/checkbox/CheckboxUnfilled24";
import MoreIconHorizontal24 from "assets/icons/more-icon-horizontal/MoreIconHorizontal24";
import { vantiColors } from "assets/jss/palette";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import usePermissions from "common/hooks/use-permissions";
import { Text } from "components/ui";
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PERSONAS_ENUM, ROLES_ENUM } from "common/constants/AccountConstants";
import { useHistory } from "react-router-dom";
import CustomUnsavedChangesDialog from "components/ui/CustomUnsavedChangesDialog/CustomUnsavedChangesDialog";
import { UPLOAD_STATUSES } from "pages/side-bar/data-curation/utils";
import { SOURCE_TYPES } from "../../../data-page/constants";
import { DATASET_STATUSES } from "../../constants";
import { ReactComponent as DeleteIcon } from "assets/icons/delete-icon/delete.svg";
import DatasetFileInfoModal from "pages/side-bar/chat/components/DatasetFileInfoModal";
import useDatasetRequest from "common/hooks/use-dataset-request";
import { userPermissionsSelector } from "modules/user/state/selectors";
import { useSelector } from "react-redux";
import DownloadIcon24 from "assets/icons/download-icon/DownloadIcon24";
import { FlexRow } from "components/ui/Flex/FlexContainer";
import { DarkToolTip } from "components/ui/tooltips/tooltip";

const PopoverMenuItemsText = {
  DOWNLOAD: "Download",
  FILE_INFO: "File info",
  QUESTIONS: "Questions",
  MOVE: "Move to",
  DELETE: "Delete"
};

const RubricHeaderStyled = styled("div")(({ hideCheckbox }) => ({
  display: "flex",
  justifyContent: hideCheckbox ? "flex-end" : "space-between",
  width: "100%"
}));

const CheckboxWrapperStyled = styled("div")({
  cursor: "pointer",
  width: "fit-content",
  display: "contents"
});

const MoreIconHorizontalWrapperStyled = styled("div")({
  cursor: "pointer",
  display: "flex"
});

const PopoverMenuStyled = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.vantiColors.white,
  display: "flex",
  flexDirection: "column"
}));

const PopoverMenuItemStyled = styled(Text)(({ theme, disabled }) => ({
  padding: "10px 31px 10px 16px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.vantiColors.gray18
  },
  pointerEvents: disabled ? "none" : "all"
}));

const RubricHeader = ({
  dataset,
  onMoveDataset,
  onDeleteDataset,
  isReadOnly,
  isFolder,
  hideCheckbox,
  disableMenuActions
}) => {
  const { datasetsContext } = useContext(AppContext);
  const { selectedDatasetsForCuration, openDatasetInfoModalId, setContext } = datasetsContext;
  const history = useHistory();
  const userPermissions = useSelector(userPermissionsSelector);

  const isDatasetErrornous = useMemo(() => dataset.status === DATASET_STATUSES.FAILED, [dataset?.status]);
  const canDownloadDataset = usePermissions([ROLE.DOWNLOAD_DATASET]);
  const canMoveDataset = userPermissions?.persona === PERSONAS_ENUM.SUPER_ADMIN;
  const canDeleteDataset = usePermissions([ROLE.DELETE_DATASET]);
  const canEditDatasetQuestions = usePermissions([ROLES_ENUM.EDIT_DATASET_QUESTIONS]);
  const { getDatasetPresignedUrlReq } = useDatasetRequest();
  const [showFileInfoModal, setShowFileInfoModal] = useState(null);
  const datasetSourceType = useMemo(() => dataset.sourceType || "");

  const [unsavedData, setUnsavedData] = useState(null);
  const [attemptedToCloseModal, setAttemptedToCloseModal] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const isDatasetSelected = useMemo(() => {
    const datasetId = dataset.id || dataset._id;
    return (
      selectedDatasetsForCuration.findIndex(selectedDataset => {
        const selectedDatasetId = selectedDataset.id || selectedDataset._id;
        return selectedDatasetId === datasetId;
      }) > -1
    );
  }, [selectedDatasetsForCuration]);

  useEffect(() => {
    if (openDatasetInfoModalId === dataset.id) {
      setContext(prevState => ({ ...prevState, datasetFileDescriptionSelectedTabIndex: 1 }));
      setShowFileInfoModal(true);
    }
  }, [openDatasetInfoModalId]);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const Checkbox = useMemo(() => !hideCheckbox && (isDatasetSelected ? CheckboxFilled24 : CheckboxUnfilled24), [
    isDatasetSelected,
    hideCheckbox
  ]);

  const onClickCheckbox = useCallback(() => {
    const datasetId = dataset.id || dataset._id;
    const selectedDatasetsForCurationCopy = [...selectedDatasetsForCuration];
    const datasetIndex = selectedDatasetsForCuration.findIndex(selectedDataset => {
      const selectedDatasetId = selectedDataset.id || selectedDataset._id;
      return selectedDatasetId === datasetId;
    });
    if (datasetIndex > -1) {
      selectedDatasetsForCurationCopy.splice(datasetIndex, 1);
      setContext(prevState => ({ ...prevState, selectedDatasetsForCuration: selectedDatasetsForCurationCopy }));
      return;
    }

    selectedDatasetsForCurationCopy.push(dataset);
    setContext(prevState => ({ ...prevState, selectedDatasetsForCuration: selectedDatasetsForCurationCopy }));
  }, [dataset, selectedDatasetsForCuration, setContext]);

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const onClickMove = useCallback(() => {
    // Switch between curation and local sections for dataset
    let sourceType = SOURCE_TYPES.LOCAL_UPLOAD;
    if (dataset.sourceType == SOURCE_TYPES.LOCAL_UPLOAD) {
      sourceType = SOURCE_TYPES.CURATION;
    }
    if (onMoveDataset) {
      onMoveDataset(dataset.id, sourceType);
    }

    handleClose();
  }, [dataset.id]);

  const onClickDelete = useCallback(() => {
    if (onDeleteDataset) {
      onDeleteDataset(dataset.id);
    }

    handleClose();
  }, [dataset.id, onDeleteDataset]);

  const onClickDownload = useCallback(() => {
    getDatasetPresignedUrlReq.mutate(dataset.id);
    handleClose();
  }, [dataset.id]);

  const onCloseHandler = useCallback(() => {
    if (unsavedData) {
      setAttemptedToCloseModal(true);
      return;
    }

    setAttemptedToCloseModal(false);
    setShowFileInfoModal(false);
    setContext(prevState => ({
      ...prevState,
      openDatasetInfoModalId: null,
      datasetFileDescriptionSelectedTabIndex: 0
    }));
  }, [unsavedData]);

  const onConfirmationHandler = useCallback(confirmed => {
    if (!confirmed) {
      setAttemptedToCloseModal(false);
      return;
    }

    setUnsavedData(null);
    onCloseHandler();
  }, []);

  return (
    <RubricHeaderStyled hideCheckbox={hideCheckbox}>
      {!hideCheckbox && (
        <CheckboxWrapperStyled onClick={onClickCheckbox}>
          <Checkbox />
        </CheckboxWrapperStyled>
      )}

      {isFolder ? (
        <FlexRow style={{ justifyContent: "flex-end" }}>
          <DarkToolTip title={"Delete"} placement={"top"}>
            <div>
              <DeleteIcon style={{ cursor: "pointer" }} onClick={onClickDelete} />
            </div>
          </DarkToolTip>
          <DarkToolTip title={"Download"} placement={"top"}>
            <div>
              <DownloadIcon24 style={{ cursor: "pointer" }} onClick={onClickDownload} />
            </div>
          </DarkToolTip>
        </FlexRow>
      ) : (
        <div>
          <MoreIconHorizontalWrapperStyled onClick={handleClick}>
            <MoreIconHorizontal24 />
          </MoreIconHorizontalWrapperStyled>

          <Popover
            open={isMenuOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
          >
            <PopoverMenuStyled>
              {canDownloadDataset && !isDatasetErrornous && (
                <PopoverMenuItemStyled
                  disabled={disableMenuActions}
                  size={14}
                  onClick={!disableMenuActions && onClickDownload}
                >
                  {PopoverMenuItemsText.DOWNLOAD}
                </PopoverMenuItemStyled>
              )}

              {!isDatasetErrornous && (
                <PopoverMenuItemStyled
                  disabled={disableMenuActions}
                  size={14}
                  onClick={() => !disableMenuActions && setShowFileInfoModal(true)}
                >
                  {PopoverMenuItemsText.FILE_INFO}
                </PopoverMenuItemStyled>
              )}

              {/*
                NOT RELEVANT, AS WE USE ACCOUNT-SCOPED QUESTIONS NOW
              
              {canEditDatasetQuestions && !isDatasetErrornous && (
                <PopoverMenuItemStyled
                  disabled={disableMenuActions}
                  size={14}
                  onClick={() => !disableMenuActions && history.push(`/dashboard/data/questions/${dataset.id}`)}
                >
                  {PopoverMenuItemsText.QUESTIONS}
                </PopoverMenuItemStyled>
              )} */}

              {canDeleteDataset && (
                <PopoverMenuItemStyled size={14} color={vantiColors.rose6} onClick={onClickDelete}>
                  {PopoverMenuItemsText.DELETE}
                </PopoverMenuItemStyled>
              )}
            </PopoverMenuStyled>
          </Popover>
        </div>
      )}

      {dataset && showFileInfoModal && (
        <DatasetFileInfoModal
          dataset={dataset}
          setShowFileInfoModal={setShowFileInfoModal}
          setUnsavedData={setUnsavedData}
          onCloseHandler={onCloseHandler}
          isReadOnly={isReadOnly}
        />
      )}
      <CustomUnsavedChangesDialog
        showDialogConditions={[unsavedData, attemptedToCloseModal]}
        onConfirmationHandler={onConfirmationHandler}
      />
    </RubricHeaderStyled>
  );
};

export default memo(RubricHeader);
