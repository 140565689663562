import { createSelector } from "reselect";

const editProduct = state => state.station?.operations?.editProduct;

export const editProductLoadingSelector = productId =>
  createSelector(
    editProduct,
    state => state.loading[productId]
  );

export const editProductErrorSelector = productId =>
  createSelector(
    editProduct,
    state => state.error[productId]
  );

export const editProductSuccessSelector = productId =>
  createSelector(
    editProduct,
    state => state.success[productId]
  );
