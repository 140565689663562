import { actionTypes } from "./actions";
import { defaultSingleResourceOperationState } from "../../../../utils/modules-utils";

const defaultState = defaultSingleResourceOperationState();

export default function changePasswordReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.AUTH_CHANGE_PASSWORD_CLEAR: {
      return { ...defaultState };
    }
    case actionTypes.AUTH_CHANGE_PASSWORD_REQUEST: {
      return { ...defaultState, loading: true };
    }
    case actionTypes.AUTH_CHANGE_PASSWORD_SUCCESS: {
      return { ...state, success: true };
    }
    case actionTypes.AUTH_CHANGE_PASSWORD_ERROR: {
      return { ...state, success: false, error: action.payload.error };
    }
    case actionTypes.AUTH_CHANGE_PASSWORD_FULFILL: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
}