import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const UploadIcon24 = ({ stroke = vantiColors.gray29, ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M17.5 19.5H6.5V4.5H13.8121L17.5 7.72688V19.5Z" stroke={stroke} strokeMiterlimit="10" />
      <path d="M13.5 4.5V8H17" stroke={stroke} />
    </svg>
  );
};

export default memo(UploadIcon24);
