import React, { memo } from "react";
import { Circle, Path, Svg } from "@react-pdf/renderer";

const NotepadIconForPDF = () => {
  return (
    <Svg width="36" height="36" viewBox="0 0 36 36" fill="white" xmlns="http://www.w3.org/2000/svg">
      <Path d="M23.8315 7.6657H12.322V6.61938H8.13672V32.7773H28.0167V6.61938H23.8315V7.6657Z" fill="white" />

      <Path
        d="M12.322 7.6657H23.8315V6.61938V5.04991H20.6925C20.6925 5.04991 20.1693 2.95728 18.0767 2.95728C15.9841 2.95728 15.4609 5.04991 15.4609 5.04991H12.322V6.61938V7.6657Z"
        fill="white"
      />

      <Path
        d="M23.8315 6.61938V7.6657H12.322V6.61938M23.8315 6.61938V5.04991H20.6925C20.6925 5.04991 20.1693 2.95728 18.0767 2.95728C15.9841 2.95728 15.4609 5.04991 15.4609 5.04991H12.322V6.61938M23.8315 6.61938H28.0167V32.7773H8.13672V6.61938H12.322M10.5536 13.9436H25.9241M10.5536 20.2215H25.9241M10.5536 26.4994H25.9241"
        stroke="#091733"
        stroke-width="1.02857"
      />

      <Circle cx="18.0777" cy="5.31135" r="0.784737" fill="green" />

      <Path
        d="M33.9431 28.3714C33.9431 31.9218 31.0649 34.8 27.5145 34.8C23.9641 34.8 21.0859 31.9218 21.0859 28.3714C21.0859 24.821 23.9641 21.9429 27.5145 21.9429C31.0649 21.9429 33.9431 24.821 33.9431 28.3714Z"
        fill="#52DE97"
        stroke="#091733"
        stroke-width="1.02857"
      />

      <Path
        d="M24.4785 28.6794L26.4404 30.6413L31.2212 26.5144"
        stroke="#091733"
        stroke-width="1.02857"
        fill="#52DE97"
      />
    </Svg>
  );
};

export default memo(NotepadIconForPDF);
