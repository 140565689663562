import qs from "querystring";
import {
  signOut,
  getStoredAccessToken,
  performTokensRefresh,
  isAccessTokenCloseToExpire,
  isTokenBeingRefreshed,
  waitForTokenRefreshToFinish
} from "services/auth/auth";
import { logger } from "utils/logger";

export class ServerError extends Error {
  constructor(error) {
    super(error.message);
    this.statusCode = error.statusCode;
    this.error = error.error;
    this.details = error.details;
  }

  isValidationError() {
    return this.message === "Request Validation Error";
  }
}

const request = async ({ method, url, body, headers, useRawBody, noAuth }) => {
  const bodyData = body instanceof FormData || useRawBody ? body : JSON.stringify(body);

  const defaultHeaders = {};

  if (!(body instanceof FormData)) {
    defaultHeaders["Content-Type"] = "application/json";
  }

  const accessToken = getStoredAccessToken();

  if (accessToken && !noAuth) {
    if (isAccessTokenCloseToExpire()) {
      //if token is close to expire, we need to use a mutex-look-a-like:
      //the HTTP requests can be fired multiple at a time, and if each of them will try to refresh the token
      //at some point, we may reach out-of-sync state because of different refresh requests ending at different times
      //therefore, we use a promise-mutex-like hack to make sure if not one request will refresh the tokens
      //but at least that it will be done in a sequential manner
      if (isTokenBeingRefreshed()) {
        await waitForTokenRefreshToFinish();
      } else {
        await performTokensRefresh();
      }
    }

    defaultHeaders["Authorization"] = `Bearer ${getStoredAccessToken()}`;
  }

  const options = {
    body: ["POST", "PUT", "PATCH", "DELETE"].indexOf(method) >= 0 ? bodyData : undefined,
    headers: {
      ...defaultHeaders,
      ...headers
    },
    credentials: "include",
    method,
    mode: "cors"
  };
  const response = await fetch(url, options);
  if (response.status === 401) {
    await signOut();
    logger.info("Signed out after getting 401 from server", response);
  }
  return response;
};

const http = {
  request,
  /**
   * makes post ajax request
   * @param {string} url the url to fetch
   * @param {object} body json to send to the url
   * @param {object} headers json with extra headers
   * @return {Promise<Response>}
   */
  async post(url, body = {}, headers = {}) {
    return request({ method: "POST", url, body, headers });
  },

  /**
   * makes get ajax request
   * @param {string} url the url to fetch
   * @param {string} query the query part of the url
   * @param {object} headers json with extra headers
   * @return {Promise<Response>}
   */
  async get(url, query = {}, headers = {}) {
    const _query = qs.stringify(query);
    let _url = _query ? `${url}?${_query}` : url;
    return request({ method: "GET", url: _url, headers });
  },

  /**
   * makes patch ajax request
   * @param {string} url the url to fetch
   * @param {object} body json to send to the url
   * @param {object} headers json with extra headers
   * @return {Promise<Response>}
   */
  async patch(url, body = {}, headers = {}) {
    return request({ method: "PATCH", url, body, headers });
  },

  /**
   * makes delete ajax request
   * @param {string} url the url to fetch
   * @param {object} body json to send to the url
   * @param {object} headers json with extra headers
   * @return {Promise<Response>}
   */
  async delete(url, body = {}, headers = {}) {
    return request({ method: "DELETE", url, body, headers });
  }
};
export default http;
