import { combineReducers } from "redux";
import { all } from "redux-saga/effects";

import webNotificationStateReducer from "./state/reducer";
import updateWebNotificationWatcher from "./operations/update-web-notification/sagas";
import updateWebNotificationReducer from "./operations/update-web-notification/reducer";
import updateWebNotificationListWatcher from "./operations/update-web-notification-list/sagas";
import updateWebNotificationListReducer from "./operations/update-web-notification-list/reducer";
import fetchWebNotificationsWatcher from "./operations/fetch-web-notifications/sagas";
import fetchWebNotificationsReducer from "./operations/fetch-web-notifications/reducer";
import deleteWebNotificationWatcher from "./operations/delete-web-notification/sagas";
import deleteWebNotificationReducer from "./operations/delete-web-notification/reducer";

export default combineReducers({
  state: webNotificationStateReducer,
  operations: combineReducers({
    fetchWebNotifications: fetchWebNotificationsReducer,
    updateWebNotification: updateWebNotificationReducer,
    updateWebNotificationList: updateWebNotificationListReducer,
    deleteWebNotification: deleteWebNotificationReducer
  })
});

export function* sagas() {
  yield all([
    updateWebNotificationWatcher(),
    updateWebNotificationListWatcher(),
    fetchWebNotificationsWatcher(),
    deleteWebNotificationWatcher()
  ]);
}
