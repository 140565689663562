import http from "../http";
import config from "common/config";

const METRICS_API_URL = `${config.serverUrl}/api/v2/metrics`;

export async function getAllAccountMetrics() {
  const res = await http.get(METRICS_API_URL);

  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}
