import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import { Text } from "components/ui";
import { RubricStyled } from "../common-styled-components";
import CloseIcon16 from "assets/icons/close-icon/CloseIcon16";
import ZipIconBG80 from "assets/icons/zip-icon-bg/ZipIconBG80";
import DynamicLoader from "components/ui/DynamicLoader/DynamicLoader";
import RubricHeader from "./RubricHeader";
import StateWrapper from "components/ui/StateWrapper/StateWrapper";
import { Skeleton } from "@material-ui/lab";
import ErrorIcon40 from "assets/icons/error-icon/ErrorIcon40";
import { DATASET_STATUSES } from "../../constants";
import { DarkToolTip } from "components/ui/tooltips/tooltip";
import { ReactComponent as ConnectorsFolder } from "assets/icons/connector-folder-icon/folder.svg";

import usePermissions from "common/hooks/use-permissions";
import {
  ChatButtonActionType,
  ChatMessageSenderType,
  ChatMessageType,
  DatasetQuestionSource,
  isSupportedDatasetTypeForChat,
  ROLE
} from "@vanti-analytics-org/vanti-common";
import { getDateFormatDayjs } from "utils/dateTime";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import ChatIcon24 from "assets/icons/chat-icon/ChatIcon24";
import { ReactComponent as ReplayIcon } from "assets/icons/replay-icon/replay-icon.svg";
import { useHistory } from "react-router-dom";
import uuid from "uuid";
import { asDisposableChatId } from "utils/chat/chat-utils";
import { getAccountDatasets, getDatasetQuestions, getFolderDatasets } from "services/api/dataset";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { userSelector } from "modules/user/state/selectors";
import { useQuery } from "react-query";

export const FETCH_FOLDER_DATASETS_KEY = "fetch.folder.datasets";

const PROBLEM_UPLOADING_FILE_TEXT_SUFFIX = "There was a problem uploading";
const UNSUPPORTED_FILE_TOOLTIP_TEXT = "To ask questions, make sure to choose a CSV file format";
const CUSTOM_CHAT_LINK_FLOW_SYSTEM_TEXT_CONTENT = "You can ask any question about this dataset";

const TableStyled = styled("table")(({ cellPadding, borderColor, lowOpacity, isHovered }) => ({
  border: `1px solid ${borderColor}`,
  borderRadius: 2,
  height: "100%",
  cursor: isHovered ? "pointer" : "auto",
  opacity: lowOpacity ? 0.2 : 1,

  "& th, td": {
    textAlign: "left",
    padding: cellPadding,
    borderBottom: `1px solid ${borderColor}`,

    "&:not(:last-of-type)": {
      borderRight: `1px solid ${borderColor}`
    }
  },
  "& tr:first-of-type th:first-of-type": {
    borderTopLeftRadius: 4
  },
  "& tr:last-of-type td": {
    borderBottom: "none"
  },
  "& tr:last-of-type td:first-of-type": {
    borderBottomLeftRadius: 4
  },
  "& tr:last-of-type td:last-of-type": {
    borderBottomRightRadius: 4
  }
}));

const TableHeaderStyled = styled("th")(({ backgroundColor, minWidth, maxWidth }) => ({
  backgroundColor,
  textOverflow: "ellipsis",
  overflow: "hidden",
  boxSizing: "border-box",
  whiteSpace: "pre",
  minWidth,
  maxWidth
}));

const TableCellTextStyled = styled(Text)({
  display: "block",
  overflow: "hidden",
  textOverflow: "ellipsis"
});

const TableCellStyled = styled("td")(({ height, minWidth, maxWidth }) => ({
  height,
  minWidth,
  maxWidth,
  boxSizing: "border-box",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "pre"
}));

const DatasetNameStyled = styled(Text)({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden"
});

const DataPreviewWrapperStyled = styled("div")(({ theme }) => ({
  overflow: "hidden",
  boxShadow: `0px 1px 3px 0px ${theme.palette.vantiColors.lightGray18}`,
  borderRadius: 4,
  position: "relative",
  cursor: "default"
}));

const DatasetRubricStyled = styled(RubricStyled)(({ isSupportedDataset, isHovered, isDisabledForSelection }) => {
  const getRubricBackgroundColor = () => {
    if (isDisabledForSelection) {
      return vantiColors.gray38;
    }

    if (isHovered) {
      return vantiColors.gray35;
    }

    return "transparent";
  };

  return {
    display: "flex",
    flexDirection: "column",
    rowGap: 8,
    opacity: isSupportedDataset && !isDisabledForSelection ? 1 : 0.4,
    borderColor: vantiColors.gray36,
    backgroundColor: getRubricBackgroundColor(),
    transition: "background 0.3s ease",
    cursor: isDisabledForSelection ? "not-allowed" : "pointer"
  };
});

const DatasetRubricFooterStyled = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  columnGap: 16
});

const TableMetadataStyled = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 4,
  overflow: "hidden",
  maxWidth: 147
});

const RemoveFailedRubricButtonStyled = styled("div")({
  cursor: "pointer",
  display: "flex",
  justifyContent: "flex-end",
  width: "100%"
});

const CloseButtonStyled = styled(CloseIcon16)({
  cursor: "pointer"
});

const ZipPreviewWrapperStyled = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const DynamicLoaderOverlayWrapperStyled = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 999
});

const DynamicLoaderWrapperStyled = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1
});

const LoadingPreviewSkeletonStyled = styled(Skeleton)({
  flex: 1,
  borderRadius: 2,
  "&.MuiSkeleton-text": {
    transform: "none"
  }
});

const RubricErrorWrapperStyled = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: 1,
  textAlign: "center",
  rowGap: 16
});

const TableHeadStyled = styled("thead")({
  display: "block"
});

const TableBodyStyled = styled("tbody")({
  display: "block",
  overflowY: "auto",
  overflowX: "hidden",
  height: "70vh"
});

const ChatIconContainerStyled = styled("div")(({ disabled }) => ({
  cursor: disabled ? "not-allowed" : "pointer",
  opacity: disabled ? 0.2 : 1,
  display: "flex"
}));

const DataPreviewTable = ({
  preview = { headers: [], body: [] },
  isEnlargedPreview,
  lowOpacity,
  isHovered,
  onClick
}) => {
  const { headers, body } = preview;

  const { cellHeight, cellPadding, cellWidth, headerBgColor, borderColor, fontSize, headerFontWeight } = useMemo(
    () => ({
      cellHeight: isEnlargedPreview ? 41 : 10,
      cellPadding: isEnlargedPreview ? "12px 16px" : "0px 0px 0px 8px",
      cellWidth: isEnlargedPreview ? "102px" : "62.333px",
      headerBgColor: isEnlargedPreview ? vantiColors.lightGray12 : vantiColors.gray35,
      borderColor: isEnlargedPreview ? vantiColors.lightGray13 : vantiColors.gray36,
      fontSize: isEnlargedPreview ? 14 : 10,
      headerFontWeight: isEnlargedPreview ? 500 : 400
    }),
    [isEnlargedPreview, isHovered]
  );

  return (
    <TableStyled
      onClick={onClick}
      cellSpacing={0}
      cellPadding={cellPadding}
      borderColor={borderColor}
      lowOpacity={lowOpacity}
      isHovered={isHovered}
      data-testid={`data-preview-table-${Math.random()}`}
    >
      <TableHeadStyled>
        <tr>
          {headers?.map((header, index) => (
            <TableHeaderStyled
              key={`${header}-${index}`}
              backgroundColor={headerBgColor}
              minWidth={cellWidth}
              maxWidth={cellWidth}
            >
              <TableCellTextStyled
                size={fontSize}
                weight={headerFontWeight}
                color={vantiColors.darkBlue10}
                lineSize={"19px"}
              >
                {header}
              </TableCellTextStyled>
            </TableHeaderStyled>
          ))}
        </tr>
      </TableHeadStyled>

      <TableBodyStyled>
        {body?.map((row, index) => (
          <tr key={index} style={{ background: "white" }}>
            {row.map(cell => (
              <TableCellStyled
                key={`${cell}-${index}-${uuid.v4()}`}
                height={cellHeight}
                minWidth={cellWidth}
                maxWidth={cellWidth}
              >
                <TableCellTextStyled size={fontSize} weight={400} color={vantiColors.lightGray17} lineSize={"19px"}>
                  {cell}
                </TableCellTextStyled>
              </TableCellStyled>
            ))}
          </tr>
        ))}
      </TableBodyStyled>
    </TableStyled>
  );
};

const PreviewSection = ({
  datasetPreview,
  datasetType,
  isHovered,
  onClick,
  enlargePreviewLoadPercentage,
  isFolder,
  folderFilesCount
}) => {
  const isShouldDisplayPreview = useMemo(() => Boolean(datasetPreview) || datasetType === "GENERIC_ZIP", [
    datasetPreview,
    datasetType
  ]);
  return (
    <StateWrapper success={isFolder || isShouldDisplayPreview} LoadingComponent={<LoadingPreviewSkeletonStyled />}>
      {isFolder ? (
        <DataPreviewWrapperStyled style={{ boxShadow: "none", width: "150px", position: "relative" }}>
          <div
            style={{
              fontSize: 12,
              margin: "30px 27px 13px 86px",
              zIndex: 9,
              width: "300px",
              color: "rgb(132, 139, 153)"
            }}
          >
            {" "}
            {0 || `+${folderFilesCount}`}{" "}
          </div>

          <ConnectorsFolder
            style={{
              paddingLeft: "35%",
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "2px",
              right: "-20px",
              opacity: "0.7"
            }}
          ></ConnectorsFolder>
        </DataPreviewWrapperStyled>
      ) : datasetType === "JSON" || datasetType === "XML" ? (
        <DataPreviewWrapperStyled>
          <Text size={10}>{datasetPreview}</Text>
        </DataPreviewWrapperStyled>
      ) : datasetType === "GENERIC_ZIP" ? (
        <ZipPreviewWrapperStyled>
          <ZipIconBG80 />
        </ZipPreviewWrapperStyled>
      ) : (
        <DataPreviewWrapperStyled data-testid={`dataset-rubric-preview-table-wrapper-${Math.random()}`}>
          {Boolean(enlargePreviewLoadPercentage) && (
            <DynamicLoaderOverlayWrapperStyled>
              <DynamicLoader value={enlargePreviewLoadPercentage} />
            </DynamicLoaderOverlayWrapperStyled>
          )}

          <DataPreviewTable
            preview={datasetPreview}
            lowOpacity={enlargePreviewLoadPercentage}
            isHovered={isHovered}
            onClick={onClick}
            data-testid={`dataset-rubric-preview-table-${Math.random()}`}
          />
        </DataPreviewWrapperStyled>
      )}
    </StateWrapper>
  );
};

const ChatIcon24Styled = styled(ChatIcon24)(({ isHovered }) => ({
  opacity: isHovered ? 1 : 0.6,
  transition: "all 0.3s ease"
}));

const DatasetRubric = ({
  dataset,
  onDownloadDataset,
  onMoveDataset,
  onDeleteDataset,
  customRubricHeader = null,
  checkSupportedDatasetType = false,
  isReadOnly = false,
  isFolder,
  hideCheckbox,
  disableMenuActions
}) => {
  const [uploadingLoader, setUploadingLoader] = useState(Math.floor(Math.random() * 30));

  const [isLoadingEnlargedPreview, setIsLoadingEnlargedPreview] = useState(false);
  const [enlargePreviewLoadPercentage, setEnlargePreviewLoadPercentage] = useState(0);

  const [isSupportedDataset, setIsSupportedDataset] = useState(true);
  const [showUnsupportedTooltip, setShowUnsupportedTooltip] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const canDeleteDataset = usePermissions([ROLE.DELETE_DATASET]);
  const { datasetsContext } = useContext(AppContext);
  const { setContext } = datasetsContext;

  useEffect(
    () => checkSupportedDatasetType && setIsSupportedDataset(isSupportedDatasetTypeForChat(dataset?.datasetType)),
    [checkSupportedDatasetType, dataset?.datasetType]
  );

  const fetchDatasets = useQuery([FETCH_FOLDER_DATASETS_KEY, dataset.id], async () => getFolderDatasets(dataset.id), {
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false
  });

  const onMouseOver = useCallback(() => {
    setIsHovered(true);
    if (!isSupportedDataset) {
      setShowUnsupportedTooltip(true);
    }
  }, [isSupportedDataset]);

  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
    setShowUnsupportedTooltip(false);
  }, []);

  const onClickEnlarge = useCallback(() => {
    const datasetId = dataset.id || dataset._id;
    setContext(prevState => ({ ...prevState, openDatasetInfoModalId: datasetId }));
  }, [dataset]);

  useEffect(() => {
    if (isLoadingEnlargedPreview) {
      let interval = setInterval(() => {
        setEnlargePreviewLoadPercentage(prevState => {
          if (prevState + 3 > 100) {
            setEnlargePreviewLoadPercentage(0);
            setIsLoadingEnlargedPreview(false);
            clearInterval(interval);
          }

          return prevState + 3;
        });
      }, 100);
    }
  }, [isLoadingEnlargedPreview]);
  useEffect(() => {
    let interval;

    if (dataset.status === DATASET_STATUSES.UPLOADING) {
      interval = setInterval(() => {
        setUploadingLoader(prevValue => prevValue + 1);
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [dataset.status]);

  return (
    <DarkToolTip placement={"top"} title={UNSUPPORTED_FILE_TOOLTIP_TEXT} open={showUnsupportedTooltip} arrow>
      <DatasetRubricStyled
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        isSupportedDataset={isSupportedDataset}
        isHovered={isHovered}
        isDisabledForSelection={hideCheckbox}
        data-testid={`dataset-rubric-styled-${dataset.id}`}
      >
        {dataset.status === DATASET_STATUSES.FAILED || dataset.status === DATASET_STATUSES.PREVIEW_ERROR ? (
          <RubricErrorWrapperStyled>
            {canDeleteDataset && (
              <RubricHeader
                dataset={dataset}
                onDownloadDataset={onDownloadDataset}
                onMoveDataset={onMoveDataset}
                onDeleteDataset={onDeleteDataset}
              />
            )}

            <ErrorIcon40 />

            <Text color={vantiColors.gray33}>
              {PROBLEM_UPLOADING_FILE_TEXT_SUFFIX} {dataset.name}
            </Text>
          </RubricErrorWrapperStyled>
        ) : (
          <>
            {dataset.status === DATASET_STATUSES.UPLOADING ? (
              <>
                <RubricHeader
                  dataset={dataset}
                  onDownloadDataset={onDownloadDataset}
                  onDeleteDataset={onDeleteDataset}
                  isReadOnly={isReadOnly}
                  isFolder={isFolder}
                  hideCheckbox={hideCheckbox}
                  disableMenuActions={disableMenuActions}
                />

                <DynamicLoaderWrapperStyled>
                  <DynamicLoader value={uploadingLoader} />
                </DynamicLoaderWrapperStyled>
              </>
            ) : (
              <>
                {customRubricHeader || (
                  <RubricHeader
                    dataset={dataset}
                    onDownloadDataset={onDownloadDataset}
                    onMoveDataset={onMoveDataset}
                    onDeleteDataset={onDeleteDataset}
                    isReadOnly={isReadOnly}
                    isFolder={isFolder}
                    hideCheckbox={hideCheckbox}
                    disableMenuActions={disableMenuActions}
                  />
                )}
                <PreviewSection
                  onClick={onClickEnlarge}
                  isHovered={isHovered}
                  datasetPreview={dataset?.preview}
                  datasetType={dataset?.datasetType}
                  enlargePreviewLoadPercentage={enlargePreviewLoadPercentage}
                  isFolder={isFolder}
                  folderFilesCount={dataset?.folderFilesCount}
                />
              </>
            )}

            <DatasetRubricFooterStyled>
              <TableMetadataStyled>
                <DatasetNameStyled size={14} lineSize={"17px"}>
                  {dataset.name}
                </DatasetNameStyled>
                <div>
                  {isFolder && (
                    <ReplayIcon
                      style={{ paddingRight: "10px", cursor: "pointer" }}
                      onClick={() => fetchDatasets.refetch()}
                    />
                  )}
                  <Text size={12} color={vantiColors.gray33} lineSize={1}>
                    {getDateFormatDayjs(
                      isFolder ? dataset.remoteFolderLastCheckedAt || dataset.createdAt : dataset.createdAt
                    )}
                  </Text>
                </div>
              </TableMetadataStyled>
            </DatasetRubricFooterStyled>
          </>
        )}
      </DatasetRubricStyled>
    </DarkToolTip>
  );
};

export default memo(DatasetRubric);
