import React, { memo, useMemo } from "react";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import { getDateFormatDayjs } from "utils/dateTime";

const ChatMessageBubbleTimestamp = ({ timestamp }) => {
  const formattedTimestamp = useMemo(() => {
    return getDateFormatDayjs(timestamp);
  }, [timestamp]);

  return (
    <Text size={12} lineSize={"14.52px"} color={vantiColors.gray39}>
      {formattedTimestamp}
    </Text>
  );
};

export default memo(ChatMessageBubbleTimestamp);
