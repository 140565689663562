import React, { memo } from "react";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import GeneralVantiButton, { SIZES } from "components/ui/Buttons/GeneralVantiButton";
import PlusIcon24 from "assets/icons/plus-icon/PlusIcon24";
import { vantiColors } from "assets/jss/palette";

const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: "19px 16px",
  position: "absolute",
  top: 0,
  width: "100%",
  backgroundColor: vantiColors.gray18,
  boxSizing: "border-box",
  zIndex: 9999
});

const HeaderLeft = styled("div")({
  display: "flex",
  flexDirection: "column"
});

const AddStationButton = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: 8
});

const Input = styled("input")(({ fontSize }) => ({
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  borderBottom: "1px solid gray",
  background: "transparent",
  fontSize
}));

const Header = ({
  title,
  description,
  onClickAddStation,
  setTitle,
  setDescription,
  isEditingTitle,
  isEditingDescription,
  setIsEditingTitle,
  setIsEditingDescription
}) => {
  const onClickTitleText = e => {
    setIsEditingTitle(true);
    e.stopPropagation();
  };

  const onClickDescriptionText = e => {
    setIsEditingDescription(true);
    e.stopPropagation();
  };

  const onTitleChange = e => {
    setTitle(e.target.value);
  };

  const onDescriptionChange = e => {
    setDescription(e.target.value);
  };

  const onClickInput = e => {
    e.stopPropagation();
  };

  const onStopEditingAll = () => {
    setIsEditingTitle(false);
    setIsEditingDescription(false);
  };

  const onStopEditingTitle = () => {
    setIsEditingTitle(false);
  };

  const onStopEditingDescription = () => {
    setIsEditingDescription(false);
  };

  const onInputKeydown = event => {
    if (event.key === "Enter") {
      onStopEditingAll();
    }
  };

  return (
    <Wrapper onClick={onStopEditingAll}>
      <HeaderLeft>
        {isEditingTitle ? (
          <Input
            value={title}
            onChange={onTitleChange}
            fontSize={22}
            onClick={onClickInput}
            onBlur={onStopEditingTitle}
            onKeyDown={onInputKeydown}
            autoFocus
          />
        ) : (
          <Text size={22} weight={600} onClick={onClickTitleText}>
            {title}
          </Text>
        )}

        {isEditingDescription ? (
          <Input
            value={description}
            onChange={onDescriptionChange}
            fontSize={16}
            onClick={onClickInput}
            onBlur={onStopEditingDescription}
            onKeyDown={onInputKeydown}
            autoFocus
          />
        ) : (
          <Text size={16} onClick={onClickDescriptionText}>
            {description}
          </Text>
        )}
      </HeaderLeft>

      <GeneralVantiButton
        size={SIZES.SMALL}
        buttonInfo={{
          disabled: false,
          onClick: onClickAddStation,
          label: (
            <AddStationButton>
              <PlusIcon24 stroke={vantiColors.white} />
              <Text color={vantiColors.white}>Add Station</Text>
            </AddStationButton>
          )
        }}
      />
    </Wrapper>
  );
};

export default memo(Header);
