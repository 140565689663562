import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const ChartIcon24 = ({ width = 24, height = 24, stroke = vantiColors.darkBlue7, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48.00 48.00"
      xmlns="http://www.w3.org/2000/svg"
      fill={stroke}
      transform="rotate(0)matrix(1, 0, 0, 1, 0, 0)"
      {...rest}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <title>chart-threshold</title>{" "}
        <g id="Layer_2" data-name="Layer 2">
          {" "}
          <g id="invisible_box" data-name="invisible box">
            {" "}
            <rect width="48" height="48" fill="none"></rect>{" "}
          </g>{" "}
          <g id="icons_Q2" data-name="icons Q2">
            {" "}
            <g>
              {" "}
              <path d="M17,26h2a2,2,0,0,0,0-4H17a2,2,0,0,0,0,4Z"></path>{" "}
              <path d="M27,26h2a2,2,0,0,0,0-4H27a2,2,0,0,0,0,4Z"></path>{" "}
              <path d="M42,40H8V26H9a2,2,0,0,0,0-4H8V6A2,2,0,0,0,4,6V44H42a2,2,0,0,0,0-4Z"></path>{" "}
              <path d="M37,26h2a2,2,0,0,0,0-4H37a2,2,0,0,0,0,4Z"></path>{" "}
            </g>{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export default memo(ChartIcon24);
