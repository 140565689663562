import React, { memo } from "react";
import { styled } from "@material-ui/core";
import {
  ChatBubble,
  ChatMessageContentContainer,
  ChatMessageContentWrapper
} from "pages/side-bar/chat/components/chat-message/ChatMessageContentCommonStyles";
import { Text } from "components/ui";
import ChatMessageBubbleTimestamp from "./ChatMessageBubbleTimestamp";
import DotsFlashingLoader from "components/ui/DotsFlashingLoader";
import { vantiColors } from "assets/jss/palette";
import { constants } from "utils/constants";
import { capitalize } from "lodash";

const ChatBubbleTextStyle = ({ children, isUserMessage }) => {
  return (
    <Text size={16} lineSize={"19px"} color={isUserMessage ? "white" : "black"}>
      {isUserMessage ? children : capitalize(children)}
    </Text>
  );
};

const DotsFlashingLoaderWrapper = styled("div")({
  marginLeft: 8 //since the before:: dot is left:-8px, we add 8px padding to align everything
});

const ChatMessageLoadingBubble = ({ textContent, isUserMessage }) => {
  return (
    <LoadingBubbleWrapper>
      {textContent && textContent.length && (
        <ChatBubbleTextStyle isUserMessage={isUserMessage}>{textContent}</ChatBubbleTextStyle>
      )}
      <DotsFlashingLoaderWrapper>
        <DotsFlashingLoader />
      </DotsFlashingLoaderWrapper>
    </LoadingBubbleWrapper>
  );
};

const ChatMessageBubbleWithAvatar = ({
  isUserMessage,
  chatMessageAvatar,
  textContent,
  isLoading,
  timestamp,
  onChatMessageMetadataClick
}) => {
  return (
    <ChatMessageContentContainer>
      <ChatMessageContentWrapper isUserMessage={isUserMessage}>
        {chatMessageAvatar}
        <ChatBubble isUserMessage={isUserMessage}>
          {isLoading ? (
            <ChatMessageLoadingBubble textContent={textContent} isUserMessage={isUserMessage} />
          ) : (
            <ChatBubbleTextStyle isUserMessage={isUserMessage}>{textContent}</ChatBubbleTextStyle>
          )}
        </ChatBubble>
      </ChatMessageContentWrapper>
      <ChatMessageBubbleFooter isUserMessage={isUserMessage}>
        <ChatMessageBubbleTimestamp timestamp={timestamp} />
        {onChatMessageMetadataClick && (
          <ChatMessageMetadataTextButton
            size={12}
            lineSize={"12.52px"}
            weight={600}
            color={vantiColors.darkBlue7}
            onClick={() => onChatMessageMetadataClick()}
          >
            {constants.chat.CHAT_MESSAGE_METADATA_BUTTON_TEXT}
          </ChatMessageMetadataTextButton>
        )}
      </ChatMessageBubbleFooter>
    </ChatMessageContentContainer>
  );
};

const LoadingBubbleWrapper = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "baseline",
  boxSizing: "border-box",
  paddingRight: 24,
  gap: 18
});

const ChatMessageBubbleFooter = styled("div")(({ isUserMessage }) => ({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  gap: 8,
  padding: "0px 56px",
  direction: isUserMessage ? "rtl" : "ltr"
}));

const ChatMessageMetadataTextButton = styled(Text)({
  cursor: "pointer",
  padding: 0,
  "&:hover": {
    clipPath: `circle(52%)`,
    background: vantiColors.lightGray8
  }
});

export default memo(ChatMessageBubbleWithAvatar);
