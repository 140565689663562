import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import { Modal, styled } from "@material-ui/core";
import CloseIcon16 from "assets/icons/close-icon/CloseIcon16";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { Text } from "components/ui";
import { buildDatasetForDisplay, CONNECTOR_SOURCES, CONNECTOR_TYPES, ROW_TYPES, SOURCE_TYPES } from "../constants";
import CloudStorageFilesBrowser from "./CloudStorageFilesBrowser";
import DatabaseFilesBrowser from "./DatabaseFilesBrowser";
import DataRow from "./DataRow";
import CustomDialog from "components/ui/Dialog/CustomDialog";
import GeneralVantiButton, { VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { listenToEvent, stopListeningToEvent } from "utils/socket-utils";
import { vantiColors } from "assets/jss/palette";
import RedCircleExclamationMark from "assets/icons/notification-center/RedCircleExclamationMark";
import useDatasetRequest from "common/hooks/use-dataset-request";
import CustomDeleteConfirmationDialog from "./CustomDeleteConfirmationDialog";

const SOCKET_EVENTS = {
  DATASET_CREATE: "dataset/create",
  DATASET_UPDATE: "dataset/update"
};

const ADD_DATA_SUFFIX_TEXT = "- add data";
export const UPLOADED_FILES_TEXT = "Uploaded files";
export const CURATED_DATASETS_TEXT = "Curated datasets";

const DATA_CONNECTORS_TEXT = "Data connectors";

const DELETE_RELATED_CHATS_TEXT = "Deleting this dataset will also delete all chats that are associated with it!";

const DataRowsWrapperStyled = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 8
});

const ModalStyled = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const ModalBodyStyled = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "50%"
});

const FileBrowserWrapperStyled = styled("div")(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.vantiColors.white,
  minHeight: 397,
  padding: 16,
  borderRadius: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "& .chonky-chonkyRoot": {
    border: "none"
  }
}));

const CloseModalButtonWrapperStyled = styled("div")({
  display: "flex"
});

const CloseModalButtonStyled = styled(CloseIcon16)({
  cursor: "pointer"
});

const AddConnectorDatasetModalHeaderStyled = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start"
});

const DatasetFilesBrowser = ({ connector, onClose }) => {
  return (
    <FileBrowserWrapperStyled>
      <AddConnectorDatasetModalHeaderStyled>
        <Text size={18} weight={500}>
          {connector.title} {ADD_DATA_SUFFIX_TEXT}
        </Text>

        <CloseModalButtonWrapperStyled onClick={onClose}>
          <CloseModalButtonStyled />
        </CloseModalButtonWrapperStyled>
      </AddConnectorDatasetModalHeaderStyled>

      {connector?.source?.type === CONNECTOR_TYPES.CLOUD_STORAGE ? (
        <CloudStorageFilesBrowser connectorId={connector.id} onFinish={onClose} />
      ) : (
        <DatabaseFilesBrowser
          tableNames={connector.tableNames}
          createdAt={connector.createdAt}
          connectorId={connector.id}
          onFinish={onClose}
        />
      )}
    </FileBrowserWrapperStyled>
  );
};

const DataRowsWrapper = () => {
  const { datasetsContext } = useContext(AppContext);
  const { connectors, uploadedFiles, curatedDatasets, setContext, datasets } = datasetsContext;
  const { deleteDatasetReq, getDatasetPresignedUrlReq, updateSourceTypeDatasetReq } = useDatasetRequest();

  const [selectedConnectorForDataUpload, setSelectedConnectorForDataUpload] = useState(null);
  const [datasetIdToDelete, setDatasetIdToDelete] = useState(null);

  const onDeleteDataset = useCallback(datasetId => {
    setDatasetIdToDelete(datasetId);
  }, []);

  const onDownloadDataset = useCallback(
    datasetId => {
      getDatasetPresignedUrlReq.mutate(datasetId);
    },
    [getDatasetPresignedUrlReq]
  );

  const onMoveDataset = useCallback((datasetId, sourceType) => {
    updateSourceTypeDatasetReq.mutate({ datasetId, sourceType });
  }, []);

  const onAddDatasetClick = useCallback(connector => {
    setSelectedConnectorForDataUpload(connector);
  }, []);

  useEffect(() => {
    if (deleteDatasetReq.data?.ok) {
      const datasetId = deleteDatasetReq.data?.data;

      const dataset = datasets[datasetId];

      if (!datasetId || !dataset) return;

      const { sourceType } = dataset;

      if (sourceType === SOURCE_TYPES.DATA_CONNECTOR) {
        const sourceDataConnectorId = dataset.sourceDataConnectorId;
        const connectorsNew = [...connectors];
        const relatedConnector = connectorsNew.find(connector => connector.id === sourceDataConnectorId);

        if (!relatedConnector) {
          return;
        }

        relatedConnector.datasets.map((dataset, datasetIndex) => {
          if (dataset.id === datasetId) {
            relatedConnector.datasets.splice(datasetIndex, 1);
            setContext(prevState => ({ ...prevState, connectors: connectorsNew }));
            return;
          }
        });
      } else if (sourceType === SOURCE_TYPES.LOCAL_UPLOAD) {
        const uploadedFilesNew = [...uploadedFiles];
        const foundDatasetIndex = uploadedFilesNew.findIndex(file => file.id === datasetId);
        if (foundDatasetIndex === -1) {
          return;
        }

        uploadedFilesNew.splice(foundDatasetIndex, 1);
        setContext(prevState => ({ ...prevState, uploadedFiles: uploadedFilesNew }));
      } else if (sourceType === SOURCE_TYPES.CURATION) {
        const curatedDatasetsNew = [...curatedDatasets];
        const foundDatasetIndex = curatedDatasetsNew.findIndex(dataset => dataset.id === datasetId);

        if (foundDatasetIndex === -1) {
          return;
        }

        curatedDatasetsNew.splice(foundDatasetIndex, 1);
        setContext(prevState => ({ ...prevState, curatedDatasets: curatedDatasetsNew }));
      }
    }
  }, [deleteDatasetReq.data?.ok, connectors]);

  const onUploadConnectorFile = useCallback(
    dataset => {
      const datasetForDisplay = buildDatasetForDisplay(dataset);

      const datasetsNew = { ...datasets };
      datasetsNew[dataset._id] = dataset;

      const connectorsNew = [...connectors];

      const relatedConnectorIndex = connectorsNew.findIndex(
        connector => connector.id === dataset.connectorId || connector.id === dataset.sourceDataConnectorId
      );

      if (relatedConnectorIndex < 0) return;

      if (dataset.parentFolderId) {
        //do nothing for now, we do not need to add a dataset to folder
        //the amount of files will be updated over another websocket event
        //TODO: add dataset to state as a child of folder if we want to show them
      } else {
        const datasetIndex = connectorsNew[relatedConnectorIndex].datasets.findIndex(ds => ds.id === dataset._id);

        if (datasetIndex < 0) {
          connectorsNew[relatedConnectorIndex].datasets.push(datasetForDisplay);
        } else {
          connectorsNew[relatedConnectorIndex].datasets[datasetIndex] = datasetForDisplay;
        }
      }

      setContext(prevState => ({ ...prevState, connectors: connectorsNew, datasets: datasetsNew }));
    },
    [datasets, connectors, setContext]
  );

  const onUploadCuratedFile = useCallback(
    dataset => {
      const datasetForDisplay = buildDatasetForDisplay(dataset);

      const datasetsCopy = { ...datasets };
      datasetsCopy[dataset._id] = dataset;

      const curatedDatasetsCopy = [...curatedDatasets];
      curatedDatasetsCopy.push(datasetForDisplay);

      setContext(prevState => ({ ...prevState, curatedDatasets: curatedDatasetsCopy }));
    },
    [curatedDatasets, datasets, setContext]
  );

  const onUploadLocalFile = useCallback(
    dataset => {
      const datasetForDisplay = buildDatasetForDisplay(dataset);

      const uploadedFilesNew = [...uploadedFiles];
      const foundFileIndex = uploadedFilesNew.findIndex(file => file.id === dataset._id);

      if (foundFileIndex < 0) {
        uploadedFilesNew.unshift(datasetForDisplay);
      } else {
        uploadedFilesNew[foundFileIndex] = datasetForDisplay;
      }

      setContext(prevState => ({
        ...prevState,
        uploadedFiles: uploadedFilesNew,
        datasets: { ...prevState.datasets, [dataset._id]: dataset }
      }));
    },
    [uploadedFiles, setContext]
  );

  useEffect(() => {
    listenToEvent(SOCKET_EVENTS.DATASET_CREATE, data => {
      const dataset = data.resource.data;
      const sourceType = dataset.sourceType;

      if (sourceType === SOURCE_TYPES.LOCAL_UPLOAD) {
        onUploadLocalFile(dataset);
      } else if (sourceType === SOURCE_TYPES.DATA_CONNECTOR) {
        onUploadConnectorFile(dataset);
      } else {
        onUploadCuratedFile(dataset);
      }
    });

    listenToEvent(SOCKET_EVENTS.DATASET_UPDATE, data => {
      const dataset = data.resource.data;
      const sourceType = dataset.sourceType;

      if (sourceType === SOURCE_TYPES.LOCAL_UPLOAD) {
        onUploadLocalFile(dataset);
      } else {
        onUploadConnectorFile(dataset);
      }
    });

    return () => {
      stopListeningToEvent(SOCKET_EVENTS.DATASET_CREATE);
      stopListeningToEvent(SOCKET_EVENTS.DATASET_UPDATE);
    };
  }, [onUploadConnectorFile, onUploadLocalFile, onUploadCuratedFile]);

  useEffect(() => {
    if (deleteDatasetReq.isSuccess) {
      setDatasetIdToDelete(null);
    }
  }, [deleteDatasetReq.isSuccess]);
  return (
    <DataRowsWrapperStyled>
      {!connectors.length ? (
        <DataRow title={DATA_CONNECTORS_TEXT} source={null} datasets={[]} rowType={ROW_TYPES.NO_CONNECTORS} />
      ) : (
        connectors.map((connector, index) => (
          <DataRow
            key={`${connector.title}-${index}`}
            onAddDatasetClick={() => {
              onAddDatasetClick(connector);
            }}
            onDownloadDataset={onDownloadDataset}
            onMoveDataset={() => {}}
            onDeleteDataset={onDeleteDataset}
            title={connector.title}
            source={connector.source}
            datasets={[...connector.datasets]}
            rowType={ROW_TYPES.CONNECTOR}
            connector={connector}
          />
        ))
      )}

      <DataRow
        onDownloadDataset={onDownloadDataset}
        onMoveDataset={onMoveDataset}
        onDeleteDataset={onDeleteDataset}
        title={UPLOADED_FILES_TEXT}
        source={CONNECTOR_SOURCES.EMPTY}
        datasets={uploadedFiles}
        rowType={ROW_TYPES.UPLOADED_FILES}
      />

      <DataRow
        onDownloadDataset={onDownloadDataset}
        onMoveDataset={onMoveDataset}
        onDeleteDataset={onDeleteDataset}
        title={CURATED_DATASETS_TEXT}
        source={CONNECTOR_SOURCES.EMPTY}
        datasets={curatedDatasets}
        rowType={ROW_TYPES.CURATED_DATASETS}
      />

      <ModalStyled
        open={selectedConnectorForDataUpload}
        onClose={() => {
          setSelectedConnectorForDataUpload(null);
        }}
      >
        <ModalBodyStyled>
          <DatasetFilesBrowser
            onClose={() => {
              setSelectedConnectorForDataUpload(null);
            }}
            connector={selectedConnectorForDataUpload}
          />
        </ModalBodyStyled>
      </ModalStyled>

      <CustomDeleteConfirmationDialog
        isOpenedCondition={datasetIdToDelete || deleteDatasetReq.isLoading}
        entity={{ type: "dataset", name: datasets[datasetIdToDelete]?.name }}
        deletionWarningText={DELETE_RELATED_CHATS_TEXT}
        onCloseCallback={() => setDatasetIdToDelete(null)}
        onClickCancelCallback={() => setDatasetIdToDelete(null)}
        onClickDeleteCallback={() => deleteDatasetReq.mutate(datasetIdToDelete)}
        isDisabled={deleteDatasetReq.isLoading}
      />
    </DataRowsWrapperStyled>
  );
};

export default memo(DataRowsWrapper);
