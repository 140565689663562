import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const SearchIcon = ({ ...rest }) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="7.30023" cy="7.94669" r="6.5" stroke={vantiColors.gray29} />
      <line x1="11.8964" y1="12.5429" x2="17.5533" y2="18.1997" stroke={vantiColors.gray29} />
    </svg>
  );
};

export default memo(SearchIcon);
