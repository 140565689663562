import React, { memo } from "react";
import { Bar } from "react-chartjs-2";
import defaultOptions from "../utils";

const GeneralHorizontalBarChart = ({ height = "auto", data, options = defaultOptions, redraw = false, ...rest }) => {
  return (
    <div style={{ height }}>
      <Bar data={data} options={options} redraw={redraw} {...rest} />
    </div>
  );
};

export default memo(GeneralHorizontalBarChart);
