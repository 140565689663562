import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ClickAwayListener, Grow, Popper, styled } from "@material-ui/core";
import { DashboardItemType } from "@vanti-analytics-org/vanti-common";
import { vantiColors } from "assets/jss/palette";
import { BREADCRUMBS_TYPES } from "common/constants/NavigationConstants";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import useDashboardRequest from "common/hooks/use-dashboard-request";
import useReportRequest from "common/hooks/use-report-request";
import { Text } from "components/ui";
import GeneralModalPreviewComponent from "components/ui/Modal/GeneralModalPreviewComponent";
import dayjs from "dayjs";
import { navigationSetBreadcrumbsType } from "modules/navigation/actions";
import { useDispatch } from "react-redux";
import { DAYJS_DATE_FORMAT, getDateFormatDayjs } from "utils/dateTime";
import { StyledMenuItem, StyledMenuList, StyledPaper } from "../../dashboard/topbar/UserMenu/UserMenu";
import { DATASTE_FILE_INFO_MODAL_HEIGHT, DATASTE_FILE_INFO_MODAL_WIDTH } from "../chat/components/DatasetFileInfoModal";
import CustomDeleteConfirmationDialog from "../data-page/components/CustomDeleteConfirmationDialog";
import DashboardItemReportExpandedView from "../home/body/dashboard-item/DashboardItemReportExpandedView";
import CellText from "./components/cells/CellText";
import GeneratedByVantiCellContent from "./components/cells/GeneratedByVantiCellContent";
import ReportsTable from "./components/ReportsTable";
import ReportsTiles from "./components/ReportsTiles";
import SearchBar from "./components/SearchBar";
import StaticReportUploader from "./components/StaticReportUploader";
import TagsGroup from "./components/TagsGroup";
import TagsListPopup from "./components/TagsListPopup";
import useTagsRequest from "common/hooks/use-tags-request";
import DashboardItemPinUnpinMenu from "../home/body/dashboard-item/DashboardItemPinUnpinMenu";

export const DELETE_REPORT_WARNING_TEXT =
  "Deleting this report will delete it from all associated locations (e.g chat session, dashboard, report page).";

const HEADER_TEXT = "Reports";
const INPUT_PLACEHOLDER = "Report name, Creator, Dataset...";

const TOTAL_REPORTS_TEXT = "Total Reports";

const headers = [
  {
    title: "Report name",
    isSortable: true,
    isFilterable: false,
    showTooltipOnOverflowingText: true,
    isSortedByDefault: false
  },
  {
    title: "Dataset",
    isSortable: true,
    isFilterable: true,
    inputPlaceholder: "Dataset name",
    showTooltipOnOverflowingText: false,
    isSortedByDefault: false
  },
  {
    title: "Created by",
    isSortable: true,
    isFilterable: true,
    inputPlaceholder: "Creator name",
    showTooltipOnOverflowingText: false,
    isSortedByDefault: false,
    capitalize: true
  },
  {
    title: "Creation time",
    isSortable: true,
    isFilterable: false,
    showTooltipOnOverflowingText: false,
    isSortedByDefault: true
  },
  {
    title: "Result",
    isSortable: false,
    isFilterable: false,
    showTooltipOnOverflowingText: true,
    isSortedByDefault: false
  },
  {
    title: "Tag",
    isSortable: false,
    isFilterable: false,
    showTooltipOnOverflowingText: false,
    isSortedByDefault: false
  }
];

const tiles = [
  {
    title: "Tile 1",
    createdBy: "benos",
    creationDate: "July 1, 2023"
  },
  {
    title: "Tile 2",
    createdBy: "benos",
    creationDate: "July 1, 2023"
  },
  {
    title: "Tile 3",
    createdBy: "benos",
    creationDate: "July 1, 2023"
  },
  {
    title: "Tile 4",
    createdBy: "benos",
    creationDate: "July 1, 2023"
  },
  {
    title: "Tile 5",
    createdBy: "benos",
    creationDate: "July 1, 2023"
  },
  {
    title: "Tile 6",
    createdBy: "benos",
    creationDate: "July 1, 2023"
  }
];

const DOWNLOAD_REPORT_MENU_ITEM_TEXT = "Download";
const DELETE_BUTTON_TEXT = "Delete";
const GENERATED_BY_VANTI_STRING_VALUE = "Vanti";

const ReportsPageWrapper = styled("div")(({ usePadding }) => ({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  rowGap: 16,
  padding: usePadding ? "24px 16px" : 0
}));

const HeaderWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 24
});

const TotalReportsText = styled(Text)({
  textAlign: "right",
  paddingRight: 8
});

const ReportsPage = ({
  showPageTitle = true,
  usePadding = true,
  tableBorderColor = null,
  displayRowMenu = true,
  displayStatisReportUploader = true
}) => {
  const { reportsContext, dashboardContext } = useContext(AppContext);
  const { openExpandedDashboardItem, setOpenExpandedDashboardItem, dashboards } = dashboardContext;
  const { getDashboardsRequest, getUserDashboardItemsRequest } = useDashboardRequest();
  const { reports, reportIdToDelete, setReportIdToDelete, selectedTableRows, setSelectedTableRows } = reportsContext;
  const [actionsPopupRef, setActionsPopupRef] = useState(null);
  const [pinUnpinPopupRef, setPinUnpinPopupRef] = useState(null);
  const [customPopupRef, setCustomPopupRef] = useState(null);
  const dispatch = useDispatch();
  const { getAllAccountTags, deleteTag } = useTagsRequest();
  const { tagsContext } = useContext(AppContext);
  const { tags } = tagsContext;

  const onSelectRow = useCallback(row => {
    setSelectedTableRows(prevState => {
      const prevStateCopy = [...prevState];

      const foundRowIndex = prevState.findIndex(innerRow => innerRow.original?._id === row.original?._id);

      if (foundRowIndex >= 0) {
        prevStateCopy.splice(foundRowIndex, 1);
        return prevStateCopy;
      }

      return [...prevStateCopy, row];
    });
  }, []);

  const onDeleteTagClick = useCallback(
    (reportId, tagId) => {
      deleteTag.mutate({ reportId, tagId });
    },
    [deleteTag.mutate]
  );

  const content = useMemo(() => {
    const unsorted = Object.values(reports)?.map(report => ({
      reportName: <CellText>{report?.reportData.name || report?.reportData.question}</CellText>,
      dataset: <CellText>{report?.reportData.datasetId?.name || "Dataset not found"}</CellText>, //populated field
      createdBy: report?.reportData.generatedByVanti ? (
        <GeneratedByVantiCellContent>{GENERATED_BY_VANTI_STRING_VALUE}</GeneratedByVantiCellContent>
      ) : (
        <CellText>{report?.reportData.userInitiatedReportDisplayName}</CellText>
      ),
      creationTime: <CellText>{getDateFormatDayjs(report.createdAt, DAYJS_DATE_FORMAT.DATE_PICKER)}</CellText>,
      result: <CellText>{report?.reportData.resultSummary || "See visual response"}</CellText>,
      tag: (
        <TagsGroup
          tags={[...(report?.reportData?.tags || [])]}
          onDeleteCallback={tagId => onDeleteTagClick(report._id, tagId)}
          isInDeletingProcess={deleteTag.isLoading}
        />
      ),
      _id: report._id
    }));

    return unsorted.sort((a, b) => b.creationTime?.props?.children?.localeCompare(a.creationTime?.props?.children));
  }, [reports, deleteTag.isLoading]);

  const [filteredContent, setFilteredContent] = useState({});
  const [viewMode, setViewMode] = useState("TABLE");

  const { getAllAccountReports, deleteReports } = useReportRequest();

  useEffect(() => {
    dispatch(navigationSetBreadcrumbsType(BREADCRUMBS_TYPES.NONE));
    getAllAccountReports.refetch();
    if (!dashboards?.length) {
      getDashboardsRequest.mutate();
    }
    getUserDashboardItemsRequest.mutate();
  }, []);

  const onFilter = useCallback(
    filterContent => {
      let newItems = [...content];

      if (filterContent) {
        const { filteredValues, key } = filterContent;

        if (filteredValues?.length && key) {
          const filteredArr = filteredValues.map(val => val.title);
          newItems = [...content].filter(item => filteredArr.includes(item[key]?.props?.children));
        }
      }

      setFilteredContent(newItems);
    },
    [content]
  );

  const onSort = useCallback(sortContent => {
    setFilteredContent(filteredContent => {
      const newFilteredContent = [...filteredContent];

      if (sortContent) {
        const { key, direction } = sortContent;

        if (key) {
          return [...newFilteredContent].sort((a, b) => {
            const stringA = a[key]?.props?.children;
            const stringB = b[key]?.props?.children;

            //handle date strings
            if (dayjs(stringA).isValid()) {
              if (direction === "ASC") {
                return dayjs(stringA).isAfter(dayjs(stringB)) ? -1 : 1;
              }
              return dayjs(stringB).isAfter(dayjs(stringA)) ? -1 : 1;
            }

            if (direction === "ASC") {
              return stringB?.localeCompare(stringA);
            }
            return stringA?.localeCompare(stringB);
          });
        }
      }
    });
  }, []);

  const onSearchValueChange = useCallback(
    searchValue => {
      let newFilteredContent = [...content];
      searchValue = searchValue.toLowerCase();
      if (searchValue && searchValue?.trim()?.length) {
        newFilteredContent = newFilteredContent.filter(item => {
          return (
            item?.reportName?.props?.children?.toLowerCase().includes(searchValue) ||
            item?.dataset?.props?.children?.toLowerCase().includes(searchValue) ||
            item?.creationTime?.props?.children?.toLowerCase().includes(searchValue) ||
            item?.result?.props?.children?.toLowerCase().includes(searchValue) ||
            item?.createdBy?.props?.children?.toLowerCase().includes(searchValue)
          );
        });
      }

      setFilteredContent(newFilteredContent);
    },
    [content]
  );

  const onSwitchViewMode = useCallback(mode => setViewMode(mode), []);

  useEffect(() => {
    setFilteredContent([...content]);
  }, [content]);

  const shouldDisplayDeletionDialog = useMemo(() => !!(reports && Object.keys(reports).length && reportIdToDelete), [
    reports,
    reportIdToDelete
  ]);

  const onScrollingTableBody = useCallback(() => {
    if (actionsPopupRef) {
      setActionsPopupRef(null);
    }
  }, [actionsPopupRef]);

  const onClickRow = useCallback(async rowClickedData => {
    const reportId = rowClickedData?.original?._id;
    if (reportId) {
      setOpenExpandedDashboardItem({
        type: DashboardItemType.REPORT,
        metadata: { reportId }
      });
    }
  }, []);

  const clickedReportByTags = useMemo(() => {
    const reportId = customPopupRef?.cellData?.row?.original?._id;
    return reports[reportId];
  }, [reports, customPopupRef?.cellData?.row?.original?._id]);

  useEffect(() => {
    getAllAccountTags.mutate();
  }, []);

  return (
    <>
      <ReportsPageWrapper data-testid={"reports-page-wrapper"} usePadding={usePadding}>
        <HeaderWrapper data-testid={"reports-page-header-wrapper"}>
          {showPageTitle && (
            <Text size={22} weight={600}>
              {HEADER_TEXT}
            </Text>
          )}

          <UserActionsHeaderWrapper>
            <SearchBar placeholder={INPUT_PLACEHOLDER} onChange={onSearchValueChange} width={387} />
            {displayStatisReportUploader && <StaticReportUploader />}
          </UserActionsHeaderWrapper>
          {/* VVVVV BRING THIS SECTION BACK WHEN WE WANT TO SHOW TILES VVVVV */}

          {/* <FiltersWrapper>

          <div>
            <button onClick={() => onSwitchViewMode("TABLE")}>table</button>
            <button onClick={() => onSwitchViewMode("TILES")}>tiles</button>
          </div>
        </FiltersWrapper> */}
        </HeaderWrapper>

        {viewMode === "TABLE" ? (
          <>
            <ReportsTable
              headers={headers}
              filteredContent={filteredContent}
              onFilter={onFilter}
              onSort={onSort}
              onWheel={onScrollingTableBody}
              displayRowMenu={displayRowMenu}
              onClickRow={displayRowMenu && onClickRow}
              tableBorderColor={tableBorderColor}
              onSelectRow={onSelectRow}
              isSelectableRows={true}
              setActionsPopupRef={setActionsPopupRef}
              setPinUnpinPopupRef={setPinUnpinPopupRef}
              setCustomPopupRef={setCustomPopupRef}
              data-testid={"reports-page-table"}
              selectedTableRows={selectedTableRows}
            />

            <TotalReportsText color={vantiColors.gray41}>
              {filteredContent?.length === Object.values(reports || {}).length
                ? `${Object.values(reports || {}).length} ${TOTAL_REPORTS_TEXT}`
                : `Filtered results - ${filteredContent?.length}/${
                    Object.values(reports || {}).length
                  } ${TOTAL_REPORTS_TEXT}`}
            </TotalReportsText>
          </>
        ) : (
          <ReportsTiles tiles={tiles} data-testid={"reports-page-tiles"} />
        )}

        {!!actionsPopupRef && (
          <Popper
            open={!!actionsPopupRef}
            anchorEl={actionsPopupRef.ref}
            style={{ zIndex: 10000 }}
            transition
            popperOptions={{
              placement: "left-start"
            }}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} id="reports-page-row-list-grow">
                <StyledPaper>
                  <ClickAwayListener onClickAway={() => setActionsPopupRef(null)}>
                    <ReportsStyledMenuList role="menu" data-testid="reports-page-row-menu-list">
                      <StyledMenuItem
                        size={14}
                        color={vantiColors.rose6}
                        data-testid="reports-page-row-menu-list-delete"
                        onClick={() => {
                          setReportIdToDelete(actionsPopupRef.rowData?._id);
                        }}
                      >
                        {DELETE_BUTTON_TEXT}
                      </StyledMenuItem>
                    </ReportsStyledMenuList>
                  </ClickAwayListener>
                </StyledPaper>
              </Grow>
            )}
          </Popper>
        )}

        {!!pinUnpinPopupRef && (
          <DashboardItemPinUnpinMenu
            dashboardItem={{
              type: DashboardItemType.REPORT,
              metadata: {
                reportId: pinUnpinPopupRef.rowData?._id
              }
            }}
            anchorEl={pinUnpinPopupRef}
            setAnchorEl={setPinUnpinPopupRef}
            data-testid={"reports-page-pin-unpin-menu"}
          />
        )}
        {!!customPopupRef && (
          <Popper
            open={!!customPopupRef}
            anchorEl={customPopupRef.ref}
            style={{ zIndex: 10000 }}
            transition
            popperOptions={{
              placement: "bottom"
            }}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} id="reports-page-row-list-grow">
                <StyledPaper>
                  <ClickAwayListener onClickAway={() => setCustomPopupRef(null)}>
                    <div>
                      <TagsListPopup tags={tags} reportId={clickedReportByTags?._id} />
                    </div>
                  </ClickAwayListener>
                </StyledPaper>
              </Grow>
            )}
          </Popper>
        )}

        <CustomDeleteConfirmationDialog
          isOpenedCondition={shouldDisplayDeletionDialog}
          entity={{ type: "report", name: reports[reportIdToDelete]?.name }}
          deletionWarningText={DELETE_REPORT_WARNING_TEXT}
          onCloseCallback={() => setReportIdToDelete(null)}
          onClickCancelCallback={() => setReportIdToDelete(null)}
          onClickDeleteCallback={() => deleteReports.mutate({ ids: [reportIdToDelete] })}
          isDisabled={false}
        />
      </ReportsPageWrapper>

      {openExpandedDashboardItem && (
        <GeneralModalPreviewComponent
          height={DATASTE_FILE_INFO_MODAL_HEIGHT}
          width={DATASTE_FILE_INFO_MODAL_WIDTH}
          open={openExpandedDashboardItem}
          onClose={() => setOpenExpandedDashboardItem(null)}
          padding={"16px 4px 16px 16px"}
        >
          <DashboardItemReportExpandedView />
        </GeneralModalPreviewComponent>
      )}
    </>
  );
};

const ReportsStyledMenuList = styled(StyledMenuList)({
  borderRadius: "4px",
  boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.15)"
});

const UserActionsHeaderWrapper = styled("div")({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between"
});

export default memo(ReportsPage);
