import React, { memo } from "react";

const AdaptiveSPCIcon = ({ className }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M7 42C8.65685 42 10 40.6569 10 39C10 37.8896 9.3967 36.9201 8.5 36.4013C8.05874 36.1461 7.54643 36 7 36C5.34315 36 4 37.3431 4 39C4 40.6569 5.34315 42 7 42Z"
      fill="#86E8B6"
    />
    <path
      d="M31 34C32.6569 34 34 32.6569 34 31C34 29.8896 33.3967 28.9201 32.5 28.4013C32.0587 28.1461 31.5464 28 31 28C30.4536 28 29.9413 28.1461 29.5 28.4013C29.3208 28.505 29.1533 28.6267 29 28.7639C28.3863 29.3132 28 30.1115 28 31C28 32.6569 29.3431 34 31 34Z"
      fill="#86E8B6"
    />
    <path
      d="M19 15C17.3431 15 16 16.3431 16 18C16 19.1104 16.6033 20.0799 17.5 20.5987C17.9413 20.8539 18.4536 21 19 21C19.5464 21 20.0587 20.8539 20.5 20.5987C20.6792 20.495 20.8467 20.3733 21 20.2361C21.6137 19.6868 22 18.8885 22 18C22 16.3431 20.6569 15 19 15Z"
      fill="#86E8B6"
    />
    <path
      d="M42 12C43.6569 12 45 10.6569 45 9C45 7.34315 43.6569 6 42 6C40.3431 6 39 7.34315 39 9C39 10.0435 39.5328 10.9625 40.3411 11.5C40.8163 11.8159 41.3866 12 42 12Z"
      fill="#86E8B6"
    />
    <path
      d="M40.3411 11.5C40.8163 11.8159 41.3866 12 42 12C43.6569 12 45 10.6569 45 9C45 7.34315 43.6569 6 42 6C40.3431 6 39 7.34315 39 9C39 10.0435 39.5328 10.9625 40.3411 11.5ZM40.3411 11.5L32.5 28.4013M32.5 28.4013C32.0587 28.1461 31.5464 28 31 28C30.4536 28 29.9413 28.1461 29.5 28.4013M32.5 28.4013C33.3967 28.9201 34 29.8896 34 31C34 32.6569 32.6569 34 31 34C29.3431 34 28 32.6569 28 31C28 30.1115 28.3863 29.3132 29 28.7639C29.1533 28.6267 29.3208 28.505 29.5 28.4013M17.5 20.5987C17.9413 20.8539 18.4536 21 19 21C19.5464 21 20.0587 20.8539 20.5 20.5987M17.5 20.5987C16.6033 20.0799 16 19.1104 16 18C16 16.3431 17.3431 15 19 15C20.6569 15 22 16.3431 22 18C22 18.8885 21.6137 19.6868 21 20.2361C20.8467 20.3733 20.6792 20.495 20.5 20.5987M17.5 20.5987L8.5 36.4013M8.5 36.4013C8.05874 36.1461 7.54643 36 7 36C5.34315 36 4 37.3431 4 39C4 40.6569 5.34315 42 7 42C8.65685 42 10 40.6569 10 39C10 37.8896 9.3967 36.9201 8.5 36.4013ZM20.5 20.5987L29.5 28.4013"
      stroke="black"
    />
  </svg>
);

export default memo(AdaptiveSPCIcon);
