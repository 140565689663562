import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  chip: {
    borderRadius: "3px",
    color: "white",
    fontWeight: "400",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    paddingTop: 2,
    height: "35px"
  },
  errorsContainer: {
    display: "flex",
    alignItems: "flex-start",
    padding: "8px 8px 8px 0px"
  },
  errorText: {
    fontFamily: "Inter",
    marginLeft: "8px",
    verticalAlign: "text-bottom"
  },
  yellowFlag: {
    width: "15px",
    padding: theme.spacing(0, 0.75, 0, 0.75)
  },
  pinkYellowFlag: {
    width: "15px",
    padding: theme.spacing(0, 0.75, 0, 0.75),
    position: "absolute",
    left: "223px"
  }
}));

export default useStyles;
