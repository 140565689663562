import React, { memo } from "react";

const IndustryIcon = ({ width = 24, height = 20, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 14670">
        <path
          id="Rectangle 606"
          d="M0 19H7.66667H19C21.2091 19 23 17.2091 23 15V15C23 12.7909 21.2091 11 19 11H7.66667H0"
          stroke="#091733"
        />
        <rect id="Rectangle 610" x="4" y="5" width="6" height="6" stroke="#091733" />
        <rect id="Rectangle 611" x="14" y="1" width="7" height="7" rx="3.5" stroke="#091733" />
        <rect
          id="Rectangle 614"
          x="5.3125"
          y="15.5566"
          width="0.625"
          height="0.625"
          transform="rotate(-90 5.3125 15.5566)"
          stroke="#091733"
          strokeWidth="0.625"
        />
        <rect
          id="Rectangle 613"
          x="10.5625"
          y="15.5566"
          width="0.625"
          height="0.625"
          transform="rotate(-90 10.5625 15.5566)"
          stroke="#091733"
          strokeWidth="0.625"
        />
        <rect
          id="Rectangle 612"
          x="15.8125"
          y="15.5566"
          width="0.625"
          height="0.625"
          transform="rotate(-90 15.8125 15.5566)"
          stroke="#091733"
          strokeWidth="0.625"
        />
        <rect
          id="Rectangle 613_2"
          x="18.7675"
          y="3.70703"
          width="1.5"
          height="0.5"
          transform="rotate(135 18.7675 3.70703)"
          stroke="#091733"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};

export default memo(IndustryIcon);
