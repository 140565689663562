import React, { memo } from "react";

const DataCurationIcon80 = ({ ...rest }) => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="80" height="80" rx="40" fill="#F9F9F9" />
      <path
        d="M55.0314 21.4863C55.7254 22.6884 55.7254 24.1694 55.0314 25.3715C54.3374 26.5735 53.0548 27.314 51.6667 27.314C50.2787 27.314 48.9961 26.5735 48.3021 25.3715C47.6081 24.1694 47.6081 22.6884 48.3021 21.4863C48.9961 20.2842 50.2787 19.5437 51.6667 19.5437C53.0548 19.5437 54.3374 20.2842 55.0314 21.4863L56.0417 20.903L55.0314 21.4863Z"
        stroke="#848B99"
        strokeWidth="2.33333"
      />
      <path
        d="M32.3748 17.2104C33.2084 18.6543 33.2084 20.4332 32.3748 21.8771C31.5412 23.321 30.0006 24.2105 28.3333 24.2105C26.6661 24.2105 25.1255 23.321 24.2919 21.8771C23.4583 20.4332 23.4583 18.6543 24.2919 17.2104C25.1255 15.7666 26.6661 14.8771 28.3333 14.8771C30.0006 14.8771 31.5412 15.7666 32.3748 17.2104Z"
        stroke="#848B99"
        strokeWidth="2.33333"
      />
      <path
        d="M59.8334 34.7104H20.1667L36.5 51.0438V61.5438L43.5 66.2896V51.0438L59.8334 34.7104Z"
        stroke="#848B99"
        strokeWidth="2.33333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.2185 30.0438C40.1897 30.0438 41.0872 30.5619 41.5728 31.403C42.0584 32.2441 42.0584 33.2804 41.5728 34.1215C41.4461 34.3409 41.2914 34.5384 41.1146 34.7104H43.8797C44.4829 33.2677 44.3875 31.6116 43.5935 30.2364C42.6911 28.6733 41.0233 27.7104 39.2185 27.7104C37.4137 27.7104 35.7459 28.6733 34.8435 30.2364C34.0495 31.6116 33.9541 33.2677 34.5573 34.7104H37.3224C37.1456 34.5384 36.9909 34.3409 36.8642 34.1215C36.3786 33.2804 36.3786 32.2441 36.8642 31.403C37.3498 30.5619 38.2473 30.0438 39.2185 30.0438Z"
        fill="#848B99"
      />
    </svg>
  );
};

export default memo(DataCurationIcon80);
