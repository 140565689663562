import React, { memo, useCallback, useMemo, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import * as PropTypes from "prop-types";
import useStyles from "./styles";
import classNames from "classnames";
import { styled } from "@material-ui/core";
import ArrowDownIcon20 from "assets/icons/arrow-down-icon/ArrowDownIcon20";

const CustomSelectStyled = styled(Select)({
  "&.MuiInput-underline:after": {
    borderBottom: "none"
  }
});

const ArrowIconWrapperStyled = styled("div")(({ isOpen }) => ({
  transform: isOpen && "rotate(180deg)"
}));

const CustomDropDown = ({
  options,
  id,
  formControlProps,
  labelText,
  labelProps,
  inputLabelProps,
  inputProps,
  dataTestId,
  className
}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const menuProps = useMemo(
    () => ({
      ...inputProps?.MenuProps,
      ...labelProps?.MenuProps,
      ...{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }
    }),
    [inputProps?.MenuProps, labelProps?.MenuProps]
  );

  const ArrowIconWrapper = useCallback(
    () => (
      <ArrowIconWrapperStyled isOpen={isOpen}>
        <ArrowDownIcon20 />
      </ArrowIconWrapperStyled>
    ),
    [isOpen]
  );

  return (
    <FormControl {...formControlProps} className={classNames(className, classes.formControl)}>
      {labelText && (
        <InputLabel data-testid={`label-${labelText}`} id={id} {...inputLabelProps} className={classes.labelRoot}>
          {labelText}
        </InputLabel>
      )}

      <CustomSelectStyled
        data-testid={`select-${dataTestId}`}
        id={id}
        {...labelProps}
        {...inputProps}
        className={classes.input}
        MenuProps={menuProps}
        IconComponent={ArrowIconWrapper}
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {options.map(option => (
          <MenuItem data-testid={`option-${option.name}`} key={option.id} value={option.id} onClick={option.onClick}>
            {option.name}
          </MenuItem>
        ))}
      </CustomSelectStyled>
    </FormControl>
  );
};

export default memo(CustomDropDown);

CustomDropDown.propTypes = {
  options: PropTypes.arrayOf(String),
  id: PropTypes.string,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object
};
