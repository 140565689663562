import { logger } from "utils/logger";
import { useMutation } from "react-query";
import { deleteDataset, getDatasetPresignedUrl, updateDatasetSourceType } from "services/api/dataset";
import { useContext } from "react";
import { AppContext } from "./context-hooks/use-app-context";
import { SOURCE_TYPES } from "pages/side-bar/data-page/constants";

const DELETE_DATASET_KEY = "delete.dataset";
const GET_DATASET_PRESIGNED_URL = "get.dataset.presigned.url";
const UPDATE_SOURCE_TYPE_DATASET_KEY = "update.dataset";

const useDatasetRequest = () => {
  const { datasetsContext } = useContext(AppContext);
  const { setContext } = datasetsContext;

  const deleteDatasetReq = useMutation(DELETE_DATASET_KEY, async variables => deleteDataset(variables), {
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false
  });

  const getDatasetPresignedUrlReq = useMutation(
    GET_DATASET_PRESIGNED_URL,
    variables => getDatasetPresignedUrl(variables),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: presignedUrl => {
        if (presignedUrl) window.location.href = presignedUrl;
      },
      onError: error => logger.error("Failed to get dataset by id", error)
    }
  );

  const updateSourceTypeDatasetReq = useMutation(
    UPDATE_SOURCE_TYPE_DATASET_KEY,
    async variables => updateDatasetSourceType(variables.datasetId, variables.sourceType),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: res => {
        if (!res || !res._id) {
          return;
        }
        const newCuratedDatasets = [...datasetsContext.curatedDatasets];
        const newUploadedFiles = [...datasetsContext.uploadedFiles];
        const newDatasets = { ...datasetsContext.datasets };

        let deleteFromArr = newCuratedDatasets;
        let pushToArr = newUploadedFiles;
        let newSourceType = SOURCE_TYPES.LOCAL_UPLOAD;
        if (res.sourceType === SOURCE_TYPES.CURATION) {
          deleteFromArr = newUploadedFiles;
          pushToArr = newCuratedDatasets;
          newSourceType = SOURCE_TYPES.CURATION;
        }

        const foundDatasetIndex = deleteFromArr.findIndex(dataset => dataset.id === res._id);
        if (foundDatasetIndex === -1) {
          return;
        }

        const datasetToPush = deleteFromArr.splice(foundDatasetIndex, 1)[0];
        datasetToPush.sourceType = newSourceType;
        pushToArr.push(datasetToPush);
        newDatasets[datasetToPush.id] = datasetToPush;

        setContext(prevState => ({
          ...prevState,
          datasets: newDatasets,
          uploadedFiles: newUploadedFiles,
          curatedDatasets: newCuratedDatasets
        }));
      }
    }
  );

  return { deleteDatasetReq, getDatasetPresignedUrlReq, updateSourceTypeDatasetReq };
};

export default useDatasetRequest;
