import { useQuery } from "react-query";
import { logger } from "utils/logger";
import { useContext, useState } from "react";
import { AppContext } from "./context-hooks/use-app-context";
import { CHAT_API_QUERY_KEYS } from "pages/side-bar/chat/constants";
import { getChatHistory, getChats } from "services/api/chat";
import { isDisposableChat } from "utils/chat/chat-utils";
import dayjs from "dayjs";

const CHAT_LOST_REQUEST_FAILURE_MSG = "Failed to get all chats";
const CHAT_HISTORY_REQUEST_FAILURE_MSG = "Failed to get chat history";
const useChatRequest = () => {
  const { chatContext } = useContext(AppContext);
  const {
    chatList,
    setChatList,
    setChatSessions,
    selectedChatId,
    setIsChatLoading,
    chatSessions,
    setFirstItemIndex,
    firstItemIndex,
    setLastMessageInCurrentChat
  } = chatContext;

  const [topHistoryBound, setTopHistoryBound] = useState(Date.now());

  const chatListRequest = useQuery(CHAT_API_QUERY_KEYS.GET_CHATS, () => getChats(), {
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,

    onSuccess: data => {
      let disposableChat = Object.values(chatList).find(chat => isDisposableChat(chat._id));
      if (disposableChat) {
        data.push(disposableChat);
      }
      setChatList(prevState =>
        [...prevState, ...data].sort((a, b) => {
          return dayjs(b.lastMessageAt).diff(dayjs(a.lastMessageAt));
        })
      );
    },
    onError: error => {
      logger.error(CHAT_LOST_REQUEST_FAILURE_MSG, error);
      return [];
    }
  });

  const chatHistoryRequest = useQuery(
    CHAT_API_QUERY_KEYS.GET_CHAT_HISTORY,
    () => getChatHistory(selectedChatId, topHistoryBound),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: data => {
        const oldHistory = chatSessions[selectedChatId];

        const newHistory = [];

        if (oldHistory) {
          newHistory.push(...oldHistory);

          for (const newMessage of data) {
            if (!oldHistory.find(oldMessage => oldMessage._id === newMessage._id)) {
              newHistory.push(newMessage);
            }
          }

          if (newHistory.length > oldHistory.length) setFirstItemIndex(firstItemIndex - 10);
        } else {
          newHistory.push(...data);
          setTopHistoryBound(Date.now() + 60_000);
        }

        newHistory.sort((a, b) => {
          return dayjs(a.createdAt).diff(dayjs(b.createdAt));
        });

        setChatSessions(prevState => ({
          ...prevState,
          [selectedChatId]: newHistory
        }));
        setIsChatLoading(false);
        setLastMessageInCurrentChat(newHistory.at(-1));
      },
      onError: error => {
        logger.error(CHAT_HISTORY_REQUEST_FAILURE_MSG, error);
        setIsChatLoading(false);
      } //TODO: handle error
    }
  );

  return {
    chatListRequest,
    chatHistoryRequest,
    setTopHistoryBound
  };
};

export default useChatRequest;
