/* eslint-disable max-lines */
import React, { useMemo } from "react";
import cs from "classnames";

import { TableCell } from "@material-ui/core";

import ArrowDown from "assets/icons/arrow-down/ArrowDown";

import { GOAL_TEXT_MAP, GROUP_BY_VALUES } from "pages/dashboard/station/constants/dashboard";
import { WEB_STATUS_LABELS } from "common/constants/StationConstants";

import useStyles from "./styles";

export default function ExpandCell({ value, groupedBy, isClosed, expandProps }) {
  const classes = useStyles();

  const cellValue = useMemo(() => {
    if (groupedBy === GROUP_BY_VALUES.GOAL.value) {
      return {
        ...GOAL_TEXT_MAP[value],
        backgroundColor: GOAL_TEXT_MAP[value].color
      };
    }

    if (groupedBy === GROUP_BY_VALUES.STATUS.value) {
      return WEB_STATUS_LABELS[value];
    }
  }, [groupedBy, value]);

  if (!cellValue) {
    return null;
  }

  return (
    <TableCell className={classes.tableCell} {...expandProps}>
      <div
        className={classes.groupItem}
        style={{
          borderColor: cellValue.backgroundColor
        }}
      >
        {cellValue.lowercaseText}
        <ArrowDown
          className={cs(classes.arrowIcon, {
            [classes.closedIcon]: isClosed
          })}
        />
      </div>
    </TableCell>
  );
}
