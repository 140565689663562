import React, { memo } from "react";

const ManualAssemblyIcon = ({ className }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M18.098 26.6939C13.7567 30.0826 5.23976 36.9456 8.77045 40.4763C12.3011 44.007 19.1642 35.4901 22.5529 31.1488L20.3254 28.9214L18.098 26.6939Z"
      fill="#86E8B6"
    />
    <path
      d="M19.9074 16.446C19.5609 17.3303 19.0304 18.1591 18.3159 18.8736C15.5412 21.6482 11.0427 21.6482 8.26807 18.8736C6.20795 16.8135 5.67744 13.803 6.67654 11.2534L10.2776 14.8545L12.7896 15.3569L14.7991 13.3473L14.2967 10.8354L10.6957 7.23427C13.2452 6.23517 16.2557 6.76568 18.3159 8.8258C20.376 10.8859 20.9065 13.8964 19.9074 16.446ZM19.9074 16.446L25.3493 21.8879M15.8039 20.3808L20.3254 24.9023M25.3493 21.8879L26.3541 22.8927L27.3589 23.8975M25.3493 21.8879L34.8947 12.3425L34.8947 10.333L39.9186 7.31863L40.9234 8.32341L41.9282 9.32819L38.9138 14.3521L36.9043 14.3521L27.3589 23.8975M20.3254 24.9023L22.335 26.9118L24.3445 28.9214M20.3254 24.9023C20.0219 25.2058 19.1927 25.8394 18.098 26.6939M18.098 26.6939C13.7567 30.0826 5.23976 36.9456 8.77045 40.4763C12.3011 44.007 19.1642 35.4901 22.5529 31.1488M18.098 26.6939L20.3254 28.9214L22.5529 31.1488M28.7817 33.3585C27.7826 35.9081 28.3131 38.9186 30.3732 40.9787C32.4333 43.0388 35.4438 43.5694 37.9934 42.5703L34.3923 38.9692L33.8899 36.4572L35.8995 34.4477L38.4114 34.9501L42.0125 38.5511C43.0116 36.0015 42.4811 32.9911 40.421 30.9309C38.3609 28.8708 35.3504 28.3403 32.8008 29.3394M28.7817 33.3585C29.1282 32.4743 29.6587 31.6454 30.3732 30.9309C31.0877 30.2164 31.9165 29.6859 32.8008 29.3394M28.7817 33.3585L28.866 33.4429M28.7817 33.3585L24.3445 28.9214M32.8008 29.3394L27.3589 23.8975M24.3445 28.9214C24.041 29.2249 23.4074 30.0541 22.5529 31.1488"
      stroke="black"
    />
  </svg>
);

export default memo(ManualAssemblyIcon);
