import { put, select, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./actions";
import * as stationService from "services/api/station";
import { stationQueryActionTypes } from "../../query";
import { actionTypes as accountStateActionTypes } from "modules/account/state/actions";

export function* createAndAssignProductWorker(action) {
  const { stationId, productName } = action.payload;
  try {
    const accountId = yield select(state => state.account.state.id);
    const product = yield stationService.addProductsToStation(accountId, stationId, productName);
    yield put({ type: actionTypes.STATION_CREATE_AND_ASSIGN_PRODUCT_SUCCESS, payload: { stationId } });
    yield put({ type: accountStateActionTypes.ACCOUNT_ADD_PRODUCT, payload: { data: [product] } });

    const stations = yield select(state => state.station.query.stations);
    const updatedStation = yield { ...stations[stationId], products: [...stations[stationId].products, product] };
    yield put({ type: stationQueryActionTypes.STATION_ADD_PRODUCT_SUCCESS, payload: { data: updatedStation } });
  } catch (error) {
    yield put({ type: actionTypes.STATION_CREATE_AND_ASSIGN_PRODUCT_ERROR, payload: { stationId, error } });
  } finally {
    yield put({ type: actionTypes.STATION_CREATE_AND_ASSIGN_PRODUCT_FULFILL, payload: { stationId } });
  }
}

export default function* createAndAssignProductWatcher() {
  yield takeLatest(actionTypes.STATION_CREATE_AND_ASSIGN_PRODUCT_REQUEST, createAndAssignProductWorker);
}
