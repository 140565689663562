import React, { useEffect, useMemo, useState } from "react";
import { useAsync } from "react-use";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Text from "components/ui/Typography/Text";
import TextField from "@material-ui/core/TextField";
import GeneralVantiButton from "components/ui/Buttons/GeneralVantiButton";
import { makeStyles } from "@material-ui/core/styles";
import { FlexContainer, FlexItem } from "components/ui";
import PhoneInput from "react-phone-input-2";
import { dangerColor, darkBlueColor, greenColor, vantiColors } from "assets/jss/palette";
import { ReactComponent as CheckMarkIconGreen } from "../../../../assets/icons/CheckMarkIconGreen.svg";
import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/CloseIconNoBorder.svg";
import { postUserInquiry } from "services/api/user";
import Loader from "components/ui/Loader/index.js";
import SimpleNotification from "components/ui/SimpleNotification/SimpleNotification";
import { PHONE_NUMBER_REGEX } from "pages/auth/create-account/constants";
import { userPhoneNumberSelector } from "modules/user/state/selectors";

const useStyles = makeStyles(theme => ({
  contactSupportModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "503px",
    margin: "auto",
    zIndex: "9999 !important"
  },
  modalInnerSection: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "32px 64px 64px 64px",
    borderRadius: 4,
    position: "relative"
  },
  freeTextField: {
    borderColor: vantiColors.darkBlue1,
    borderRadius: 0,
    fontFamily: "Inter"
  },
  subTextSection: {
    paddingTop: "16px !important"
  },
  closeIcon: {
    position: "absolute",
    right: 20,
    top: 20,
    cursor: "pointer"
  },
  circularLoadingContainer: {
    marginLeft: 10
  }
}));

const isPhoneNumberValid = phoneNumber => {
  return phoneNumber.match(PHONE_NUMBER_REGEX) && phoneNumber.length >= 10;
};

const INQUIRY_SUCCESSFULLY_SENT_MESSAGE = "Inquiry was successfully sent";

const TrialBannerModal = ({ open, setIsModalOpen }) => {
  const classes = useStyles();

  const phoneNumberFromState = useSelector(userPhoneNumberSelector);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [freeTextValue, setFreeTextValue] = useState("");

  const [isUserClickedFinish, setIsUserClickedFinish] = useState(false);
  const [uploadInquiryError, setUploadInquiryError] = useState(null);
  const [isSuccessNotificationOpen, setIsSuccessNotificationOpen] = useState(false);

  const userInquiryUploader = useAsync(async () => {
    if (isUserClickedFinish && freeTextValue.trim().length > 0) {
      try {
        const userInqueryUploader = await postUserInquiry(freeTextValue);
        if (!userInqueryUploader) {
          return false;
        }
        return true;
      } catch (error) {
        setUploadInquiryError(error.message);
        return false;
      }
    }

    return false;
  }, [isUserClickedFinish]);

  const onFinishClick = () => {
    setIsUserClickedFinish(true);
  };

  useEffect(() => {
    if (userInquiryUploader.value === true) {
      setIsSuccessNotificationOpen(true);
      onModalClose();
    }
  }, [userInquiryUploader]);

  const onPhoneNumberChange = value => {
    setPhoneNumber(value);

    if (!isPhoneNumberValid(value)) {
      setPhoneNumberError("Invalid number");
    } else {
      setPhoneNumberError("");
    }
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setPhoneNumber("");
    setFreeTextValue("");
    setPhoneNumberError("");
    setUploadInquiryError(null);
    setIsUserClickedFinish(false);
  };

  const onSuccessNotificationClose = () => {
    setIsSuccessNotificationOpen(false);
  };

  const isButtonDisabled = useMemo(() => {
    if (freeTextValue.trim().length === 0 || userInquiryUploader.loading) {
      return true;
    }

    if (phoneNumberFromState) return false;

    if (phoneNumber) {
      if (phoneNumberError) {
        return true;
      }
      return !isPhoneNumberValid(phoneNumber);
    }
  }, [phoneNumber, phoneNumberError, phoneNumberFromState, freeTextValue, userInquiryUploader.loading]);

  const buttonInfo = {
    disabled: isButtonDisabled,
    onClick: onFinishClick,
    label: (
      <Text color={vantiColors.darkBlue6} size={17} weight="medium">
        ADD TO THE REQUEST 🙏
        {userInquiryUploader.loading && (
          <Text className={classes.circularLoadingContainer}>
            <Loader />
          </Text>
        )}
      </Text>
    )
  };

  return (
    <>
      <SimpleNotification
        open={isSuccessNotificationOpen}
        autoHideDuration={3000}
        message={INQUIRY_SUCCESSFULLY_SENT_MESSAGE}
        severity={"success"}
        onClose={onSuccessNotificationClose}
      />

      <Modal
        className={classes.contactSupportModal}
        open={open}
        onClose={onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <FlexContainer className={classes.modalInnerSection}>
            <CloseIcon className={classes.closeIcon} onClick={onModalClose} />

            <FlexItem container justifyContent="center" dense="full">
              <FlexItem dense="full">
                <CheckMarkIconGreen />
              </FlexItem>
            </FlexItem>

            <FlexItem container dense="horizontal">
              <FlexItem dense="full">
                <Text size={30} weight="semi" color={vantiColors.darkBlue6}>
                  We have{" "}
                  <Text color={vantiColors.green4} size="inherit" weight="semi">
                    successfully{" "}
                  </Text>
                  received your inquiry
                </Text>
              </FlexItem>

              <FlexItem dense="full" className={classes.subTextSection}>
                <Text size={14} color={vantiColors.darkBlue1} weight="semi">
                  Our team will contact you within 1-2 business days
                </Text>
              </FlexItem>
            </FlexItem>

            <FlexItem container dense="horizontal">
              <FlexItem xs={12} dense="horizontal">
                <Text size={16} color={vantiColors.darkBlue1}>
                  Would you like to add anything else to your inquiry?
                </Text>
              </FlexItem>

              <FlexItem xs={12} dense="horizontal">
                <PhoneInput
                  specialLabel="Phone number"
                  country={"us"}
                  value={phoneNumberFromState || phoneNumber}
                  disabled={phoneNumberFromState}
                  onChange={phone => {
                    onPhoneNumberChange(phone);
                  }}
                  inputStyle={{ width: "100%" }}
                />

                {phoneNumberError && (
                  <FlexItem dense="vertical">
                    <Text size={14} color={vantiColors.danger5}>
                      {phoneNumberError}
                    </Text>
                  </FlexItem>
                )}
              </FlexItem>

              <FlexItem xs={12} dense="horizontal">
                <TextField
                  id="feedback-free-text-field"
                  value={freeTextValue}
                  onChange={e => setFreeTextValue(e.target.value)}
                  multiline
                  rows={4}
                  placeholder="Describe your inquiry in a few words"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: classes.freeTextField
                    }
                  }}
                />
              </FlexItem>
            </FlexItem>

            <FlexItem xs={12} dense="horizontal">
              <GeneralVantiButton buttonInfo={buttonInfo} style={{ borderRadius: 3, padding: "25px" }} fullWidth />
            </FlexItem>

            {uploadInquiryError && (
              <FlexItem xs={12}>
                <Text color={vantiColors.danger1}>{uploadInquiryError}</Text>
              </FlexItem>
            )}
          </FlexContainer>
        </Fade>
      </Modal>
    </>
  );
};

export default TrialBannerModal;
