import React from "react";
import classNames from "classnames";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  darkBlueCardHeader,
  darkCardHeader,
  greenCardHeader,
  turquoiseCardHeader,
  vantiColors
} from "assets/jss/vanti-dashboard";

const useStyles = makeStyles({
  cardHeader: {
    padding: "20px",
    minHeight: "2rem",
    marginBottom: "0",
    borderBottom: "none",
    background: "transparent",
    zIndex: "3 !important",
    color: vantiColors.white
  },
  darkBlueCardHeader: {
    color: vantiColors.white,
    ...darkBlueCardHeader
  },
  greenCardHeader: {
    color: vantiColors.white,
    ...greenCardHeader
  },
  turquoiseCardHeader: {
    color: vantiColors.white,
    ...turquoiseCardHeader
  },
  darkCardHeader: {
    color: vantiColors.white,
    ...darkCardHeader
  },
  bottomBorder: {
    borderBottom: `1px solid #EAEAEA`
  }
});

export default function CardHeader(props) {
  const classes = useStyles();
  const { className, children, color, bottomBorder, ...rest } = props;
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[color + "CardHeader"]]: color,
    [classes.bottomBorder]: bottomBorder,
    [className]: className !== undefined
  });
  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
}

CardHeader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["darkBlue", "green", "turquoise", "dark", "transparent"]),
  bottomBorder: PropTypes.bool,
  children: PropTypes.node
};
