import React, { memo } from "react";
import PropTypes from "prop-types";

import Card from "components/ui/Card/Card";
import CardBody from "components/ui/Card/CardBody";
import Text from "components/ui/Typography/Text";
import CardSection from "components/ui/Card/CardSection";
import Hidden from "@material-ui/core/Hidden";

import { ReactComponent as IconWhite } from "assets/img/icons/vanti-icon-white.svg";

import useStyles from "./styles";

const AuthCard = ({ children, withSignUp }) => {
  const classes = useStyles();

  return (
    <Card direction="row" className={classes.card}>
      <Hidden smDown>
        <CardSection className={classes.cardLeftSection}>
          <CardBody className={classes.cardBody}>
            <Text color="white" family="poppins" size="1.3vw" weight="bold" lineSize="1.8em">
              The Only Scalable AI
              <br />
              for Manufacturing
            </Text>
            {withSignUp && (
              <div className={classes.signUp}>
              </div>
            )}
            <IconWhite className={classes.icon} />
          </CardBody>
        </CardSection>
      </Hidden>
      <CardSection className={classes.cardRightSection}>{children}</CardSection>
    </Card>
  );
};

AuthCard.propTypes = {
  children: PropTypes.node
};

export default memo(AuthCard);
