import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const InfoIcon = ({ color = vantiColors.darkBlue7, ...rest }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill={color} {...rest}>
    <g clipPath="url(#clip0_17:291)">
      <path
        d="M7 1C8.18669 1 9.34673 1.35188 10.3334 2.01117C11.3201 2.67046 12.0892 3.60753 12.5433 4.70389C12.9974 5.80024 13.1162 7.00665 12.8847 8.17053C12.6532 9.33442 12.0818 10.4035 11.2426 11.2426C10.4035 12.0818 9.33444 12.6532 8.17055 12.8847C7.00666 13.1162 5.80026 12.9974 4.70391 12.5433C3.60755 12.0891 2.67048 11.3201 2.01119 10.3334C1.3519 9.34674 1 8.18669 1 7C1 5.4087 1.63214 3.88257 2.75736 2.75735C3.88258 1.63214 5.4087 1 7 1ZM7 0C5.61553 0 4.26215 0.410548 3.11101 1.17972C1.95987 1.94889 1.06265 3.04215 0.532839 4.32123C0.00302595 5.60031 -0.135588 7.00776 0.134509 8.36563C0.404605 9.7235 1.07128 10.9708 2.05025 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.39971 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73783 14 8.38447 14 7C14 5.14348 13.2625 3.36302 11.9498 2.05026C10.637 0.737508 8.85652 0 7 0Z"
        fill={color}
      />
      <path d="M7 4V3M7 5V11V5Z" stroke={color} />
    </g>
    <defs>
      <clipPath id="clip0_17:291">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default memo(InfoIcon);
