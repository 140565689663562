import { useSelector } from "react-redux";
import * as queryModule from "modules/station/query";

/**
 * @param {string} stationId
 */
export default function useStation(stationId) {
  return useSelector(queryModule.singleStationSelector(stationId));
}

export function useStations() {
  return useSelector(queryModule.stationsSelector);
}
