import React, { memo } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { FlexItem, Text } from "components/ui/index";
import { styled } from "@material-ui/core/styles";

const RootContainer = styled(FlexItem)({
  flexGrow: 1,
  flexDirection: "column"
});

const StyledTab = styled(Tab)(({ theme, indicatorColor = theme.palette.vantiColors.green1 }) => ({
  textTransform: "none",
  padding: 0,
  "&.Mui-selected": {
    color: "black",
    borderBottom: `2px solid ${indicatorColor}`
  },
  "&:not(.Mui-selected)": {
    color: theme.palette.vantiColors.gray19
  },
  "&:not(.Mui-selected):hover": {
    color: theme.palette.vantiColors.lightGray6
  }
}));

const DynamicTabs = ({ tabs, selectedTabIndex, handleChange, tabProps, tabsProps, indicatorColor }) => {
  return (
    <RootContainer container dense={"full"}>
      <FlexItem dense={"full"}>
        <Tabs value={selectedTabIndex} onChange={handleChange} {...tabsProps}>
          {tabs.map((tab, index) => (
            <StyledTab
              disabled={tab?.disabled}
              disableRipple
              key={`${tab}-${index}-${Math.random()}`}
              indicatorColor={indicatorColor}
              label={
                <Text size={14} weight={400}>
                  {tab?.title || tab}
                </Text>
              }
              {...tabProps}
              data-testid={`${tab}-button`}
            />
          ))}
          disabled
        </Tabs>
      </FlexItem>
    </RootContainer>
  );
};

export default memo(DynamicTabs);
