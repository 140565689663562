import React, { memo } from "react";

import LoadingIcon from "assets/icons/LoadingIcon";

import useStyles from "./styles";

const Loader = ({ ...rest }) => {
  const classes = useStyles();

  return <LoadingIcon className={classes.loader} color="currentColor" {...rest} />;
};

export default memo(Loader);
