import config from "common/config";
import http from "services/http";
import * as uuidv4 from "uuid/v4";

// Server
const authUrl = `${config.serverUrl}/auth/v2`;
export const apiUrl = `${config.serverUrl}/api/v2`;
const accountUrl = `${apiUrl}/accounts`;
const validateInviteUrl = `${accountUrl}/verify-user-invite`;
const confirmInviteUrl = `${accountUrl}/confirm-user-invite`;

let storedAccessToken = null;
let accessTokenExpirationTimestamp = null;

let deviceFingerprint = null;

let tokenRefreshingPromise = null;

export function getStoredAccessToken() {
  return storedAccessToken;
}

export function updateStoredAccessToken(accessToken, expirationTime) {
  storedAccessToken = accessToken;

  accessTokenExpirationTimestamp = Date.now() + expirationTime * 1000;
}

export function computeDeviceFingerprint() {
  deviceFingerprint = window.localStorage.getItem(`vanti_device_fingerprint`);

  if (!deviceFingerprint) {
    deviceFingerprint = uuidv4();

    window.localStorage.setItem(`vanti_device_fingerprint`, deviceFingerprint);
  }
}

/**
 */
export async function ping() {
  const urlParams = new URLSearchParams(window.location.search);
  const account = urlParams.get("a");

  const res = await http.get(`${authUrl}/ping`, { account });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function getAuthData() {
  const response = await http.get(`${authUrl}/inhouse/me`, {}, {});
  const responseBody = await response.json();
  if (response.status !== 200) {
    throw responseBody;
  }
  return responseBody;
}

export async function refreshTokens() {
  const response = await http.request({
    method: "POST",
    url: `${authUrl}/inhouse/refresh-tokens`,
    body: { deviceFingerprint },
    noAuth: true //we don't need to send auth token for refresh request, and if we do, we enter infinite loop of refreshes
  });

  const responseBody = await response.json();

  if (response.status !== 200) {
    throw responseBody;
  }

  return responseBody;
}

export async function performTokensRefresh() {
  let resolveWaitingFunction = null;

  tokenRefreshingPromise = new Promise(resolve => {
    resolveWaitingFunction = resolve;
  });

  try {
    const refreshResponse = await refreshTokens();

    const { accessToken, expirationTime } = refreshResponse.data;
    updateStoredAccessToken(accessToken, expirationTime);
  } catch (error) {
    await signOut();
  }

  resolveWaitingFunction();

  tokenRefreshingPromise = null;
}

export function isTokenBeingRefreshed() {
  return tokenRefreshingPromise !== null;
}

export async function waitForTokenRefreshToFinish() {
  if (tokenRefreshingPromise) {
    await tokenRefreshingPromise;
  }
}

export function isAccessTokenCloseToExpire() {
  const diff = accessTokenExpirationTimestamp - Date.now();
  return diff < 30 * 1000; //30 seconds is the limit
}

export async function signInByEmail(email, password) {
  const response = await http.request({
    method: "POST",
    url: `${authUrl}/inhouse/login`,
    body: {
      email,
      password,
      deviceFingerprint
    },
    noAuth: true
  });
  const responseBody = await response.json();
  if (response.status !== 200) {
    response.responseBody = responseBody;
    if (response.status === 400) {
      throw "Invalid email or password format";
    }
    throw responseBody.message || `Request failed with code ${response.status}`;
  }

  if (responseBody.data) {
    const { accessToken, expirationTime } = responseBody.data;
    updateStoredAccessToken(accessToken, expirationTime);
  } else if (!responseBody.ok) {
    throw responseBody.message; //do not throw raw strings, but older code relies on that so whatever
  }

  return responseBody;
}

export async function signOut() {
  await logout();

  const nonAuthRoutes = ["/auth", "/create-account", "/version"];

  // if either we are on login/sign-up route OR we are on root page - do not perform redirect (it will cause an endless loop)
  // the root page redirect is handled by AuthenticatedRoute in App.js, so ignore it too
  if (!nonAuthRoutes.some(url => window.location.href.includes(url)) && window.location.pathname !== "/") {
    window.location.replace("/auth/sign-in");
  }
}

export async function validateDisableStatus(email) {
  const res = await http.post(`${apiUrl}/validate-disable-user`, { email }, {}, true);
  const result = await res.json();
  return result;
}

/**
 *
 */
export async function resetPassword(email) {
  const res = await http.post(`${authUrl}/inhouse/reset-password`, { email }, {}, true);
  if (res.status === 429) {
    throw new Error("Too many attempts, please try again later");
  }
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 *
 * @param {string} code
 * @param {string} newPassword
 * @returns {}
 */
export async function confirmResetPassword(code, newPassword) {
  const response = await http.post(`${authUrl}/inhouse/reset-password/${code}`, { password: newPassword });
  const responseBody = await response.json();
  if (response.status !== 200) {
    throw responseBody;
  }
  if (!response.ok) {
    throw new Error(response.message);
  }
  return responseBody;
}

export async function changePassword(currentPassword, newPassword) {
  const response = await http.post(`${authUrl}/inhouse/change-password`, { currentPassword, newPassword });
  const responseBody = await response.json();
  if (response.status !== 200) {
    throw responseBody;
  }
  if (!responseBody.ok) {
    throw new Error(response.message);
  }
  return responseBody;
}

export async function logout() {
  const response = await http.request({ method: "POST", url: `${authUrl}/inhouse/logout`, noAuth: true });
  const responseBody = await response.json();
  if (response.status !== 200) {
    throw responseBody;
  }
  return responseBody;
}

/**
 *
 */

// understand how verify email is getting into the picture. and then inset it into the flow.
export async function verifyEmail() {
  const res = await http.post(`${authUrl}/email/verify`, {});
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function validateUserInvite(verifyToken) {
  const res = await http.post(`${validateInviteUrl}`, { verifyToken }, {}, true);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function confirmUserInvite(token, firstName, lastName, password) {
  const res = await http.post(`${confirmInviteUrl}`, {
    token,
    firstName,
    lastName,
    password
  });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @param {Array<{ email: string, persona: string }>} invitedUsers
 */
export async function inviteUsers(invitedUsers) {
  const res = await http.post(`${accountUrl}/invite`, { invitedUsers });
  const result = await res.json();

  if (res.status > 200) {
    throw new Error(result.message.message || result.message);
  }
  return { data: result };
}

/**
 * @param {Array<{email: string, persona: string }>} users
 */
export async function changeUsersPermissions(users) {
  const res = await http.patch(`${accountUrl}/change-permissions`, { users });
  const result = await res.json();

  if (res.status > 200 || !result.ok) {
    throw new Error(result.message.message || result.message);
  }

  return result;
}

export async function getAccountUsers() {
  const res = await http.get(`${accountUrl}/get-users`);
  const result = await res.json();

  if (res.status > 200 || !result.ok) {
    throw new Error(result.message.message || result.message);
  }

  return result;
}
export async function getAccountUserNames() {
  const res = await http.get(`${accountUrl}/get-user-names`);
  const result = await res.json();

  if (res.status > 200 || !result.ok) {
    throw new Error(result.message.message || result.message);
  }

  return result;
}

/**
 * @param {string} userId
 */
export async function removeUserFromAccount(userId) {
  const res = await http.delete(`${accountUrl}/remove-user-from-account/${userId}`);
  const result = await res.json();

  if (res.status > 200 || !result.ok) {
    throw new Error(result.message.message || result.message);
  }

  return result;
}

/**
 * @param {string} userId
 */
export async function resendInvite(userId) {
  const res = await http.get(`${accountUrl}/send-invite/${userId}`);
  const result = await res.json();

  if (res.status > 200 || !result.ok) {
    throw new Error(result.message.message || result.message);
  }

  return result;
}

export async function getRoles() {
  const res = await http.get(`${accountUrl}/get-roles`);
  const result = await res.json();

  if (res.status > 200 || !result.ok) {
    throw new Error(result.message.message || result.message);
  }

  return result;
}

export async function getAccount() {
  const response = await http.get(`${accountUrl}/`);
  const responseData = await response.json();

  if (response.status > 200 || !responseData.ok) {
    throw new Error(responseData.message.message || responseData.message);
  }

  return responseData;
}
