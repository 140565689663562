import { createSelector } from "reselect";

export const dataCurationsSelector = state => state.dataCurations.state;

export const allDataCurationsSelector = createSelector(
  dataCurationsSelector,
  state => state.dataCurations
);

export const fetchingLoadingSelector = createSelector(
  dataCurationsSelector,
  state => state.loading
);

export const fetchingErrorSelector = createSelector(
  dataCurationsSelector,
  state => state.error
);

export const fetchingSuccessSelector = createSelector(
  dataCurationsSelector,
  state => state.success
);
