import { useMutation } from "react-query";
import { getAllAccountMetrics } from "services/api/metrics";

const REQ_KEYS = {
  GET_ALL_ACCOUNT_METRICS_REQ_KEY: "get.all.account.metrics.req.key"
};

const useMetricsRequest = () => {
  const getAllAccountMetricsReq = useMutation(
    REQ_KEYS.GET_ALL_ACCOUNT_METRICS_REQ_KEY,
    params => getAllAccountMetrics(params),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false
    }
  );

  return {
    getAllAccountMetricsReq
  };
};

export default useMetricsRequest;
