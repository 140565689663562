import { defaultSingleResourceOperationState } from "../../../../utils/modules-utils";
import { actionTypes } from "./actions";

const defaultState = defaultSingleResourceOperationState();

export default function signInByEmailReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.USER_SIGN_IN_CLEAR: {
      return { ...defaultState };
    }
    case actionTypes.USER_SIGN_IN_REQUEST: {
      return { ...defaultState, loading: true };
    }
    case actionTypes.USER_SIGN_IN_SUCCESS: {
      return { ...state, success: true };
    }
    case actionTypes.USER_SIGN_IN_ERROR: {
      return { ...state, success: false, error: action.payload.error };
    }
    case actionTypes.USER_SIGN_IN_FULFILL: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
}