import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";

export const DashboardItemContainerStyled = styled("div")(({ isHovered }) => ({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  borderRadius: 6,
  padding: 16,
  backgroundColor: isHovered ? vantiColors.gray36 : "white",
  cursor: isHovered ? "pointer" : "auto",
  boxShadow: "0px 8px 12px 0px rgba(170, 170, 170, 0.20)",
  transition: "all ease-out 0.3s"
}));
