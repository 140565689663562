import config from "common/config";
import http from "services/http";

const dataConnectorsAPI = `${config.serverUrl}/api/v2/dataconnectors`;

export const getAllDataConnectors = async () => {
  const res = await http.get(`${dataConnectorsAPI}/connectors`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const getDataConnector = async id => {
  const res = await http.get(`${dataConnectorsAPI}/connectors/${id}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
};

export const createNewConnector = async fields => {
  const res = await http.post(`${dataConnectorsAPI}/connectors`, fields);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result;
};

export const editConnector = async (connectorId, fields) => {
  const res = await http.patch(`${dataConnectorsAPI}/connectors/${connectorId}`, fields);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result;
};

export const deleteDataConnector = async dataConnectorId => {
  const res = await http.delete(`${dataConnectorsAPI}/connectors/${dataConnectorId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result;
};

export const getAllSupportedDataSources = async () => {
  const res = await http.get(`${dataConnectorsAPI}/connectors/registry`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  return result.data;
};

export const testConnectionConnector = async (sourceType, credentials) => {
  const res = await http.post(`${dataConnectorsAPI}/runner/test`, { sourceType, credentials });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  return result;
};

export const startModelJob = async (modelId, connectorId, userQuery, tableName) => {
  const res = await http.post(`${dataConnectorsAPI}/startModelJob`, { modelId, connectorId, userQuery, tableName });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  if (!result.ok) {
    throw new Error(result.message);
  }

  return result;
};

export const cancelModelJob = async modelId => {
  const res = await http.post(`${dataConnectorsAPI}/cancelModelJob`, { modelId });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  return result;
};

export const removeFileAndUpdateState = async modelId => {
  const res = await http.patch(`${dataConnectorsAPI}/removeFileUpdateState`, { modelId });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  return result;
};

export const getPlainCredential = async (connectorId, credentialKey) => {
  const res = await http.get(`${dataConnectorsAPI}/getPlainCredential/${connectorId}?credentialKey=${credentialKey}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  return result.data;
};

export const reloadDatabaseTables = async connectorId => {
  const res = await http.post(`${dataConnectorsAPI}/runner/reload-database-tables/${connectorId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  if (!result.ok) {
    throw new Error(result.message);
  }

  return result;
};

export const startDatasetJob = async (connectorId, userQuery, tableName) => {
  const res = await http.post(`${dataConnectorsAPI}/startDatasetJob`, {
    connectorId,
    userQuery,
    tableName
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const watchRemoteFolder = async (connectorId, folder) => {
  const res = await http.post(`${dataConnectorsAPI}/watchRemoteFolder`, {
    connectorId,
    folder
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};
