import React, { memo, useMemo } from "react";
import { Bar, Pie, Scatter, Line } from "react-chartjs-2";
import { logger } from "utils/logger";
import Gradient from "javascript-color-gradient";
import { vantiColors } from "assets/jss/palette";

export const GRAPH_TYPES_STR = {
  BAR: "BAR",
  SCATTER: "SCATTER",
  PIE: "PIE",
  LINE: "LINE",
  COMBINED: "COMBINED"
};

export const GRAPH_TYPES = {
  [GRAPH_TYPES_STR.BAR]: Bar,
  [GRAPH_TYPES_STR.COMBINED]: Bar,
  [GRAPH_TYPES_STR.SCATTER]: Scatter,
  [GRAPH_TYPES_STR.PIE]: Pie,
  [GRAPH_TYPES_STR.LINE]: Line
};

const GraphFactory = ({ type, data, options }) => {
  const { chartData, chartLabels } = data;

  const colorGradients = useMemo(() => {
    const g = new Gradient();
    g.setColorGradient("#52DE97", "#00C6C4", "#0098E2", "#0063FD", "#0313A4");
    g.setMidpoint(chartData?.X?.length || 1);
    return g.getColors();
  }, [data]);

  const convertedData = useMemo(() => {
    const defaultChartData = {
      labels: chartData.X,
      datasets: [
        {
          label: chartLabels.y_label,
          data: chartData.Y,
          backgroundColor: vantiColors.darkBlue7
        }
      ]
    };

    switch (type) {
      case GRAPH_TYPES_STR.SCATTER: {
        //Scatter charts require different chart data structure
        if (chartData.X.length !== chartData.Y.length) {
          logger.warn("Chart data structure is invalid. The number X and Y values isn't equal.", {
            xLength: chartData.X.length,
            yLength: chartData.Y.length
          });
          return {
            datasets: []
          };
        }
        const newData = [];
        for (let i = 0; i < chartData.X.length; i++) {
          newData.push({
            x: chartData.X[i] || 0,
            y: chartData.Y[i] || 0
          });
        }
        return {
          datasets: [
            {
              label: chartLabels.y_label,
              data: newData,
              backgroundColor: vantiColors.darkBlue7
            }
          ]
        };
      }

      case GRAPH_TYPES_STR.PIE:
        defaultChartData.datasets[0].backgroundColor = colorGradients;
        return defaultChartData;

      case GRAPH_TYPES_STR.COMBINED:
        defaultChartData.datasets.unshift({
          type: GRAPH_TYPES_STR.LINE.toLowerCase(),
          label: chartLabels.y_label,
          data: chartData.Y,
          backgroundColor: vantiColors.red2,
          borderColor: vantiColors.red2
        });
        return defaultChartData;

      case GRAPH_TYPES_STR.BAR:
      case GRAPH_TYPES_STR.LINE:
      default:
        return defaultChartData;
    }
  }, [data, type]);

  if (GRAPH_TYPES[type]) {
    const GraphComponent = GRAPH_TYPES[type];
    return <GraphComponent data={convertedData} options={options} />;
  }

  return null;
};

export default memo(GraphFactory);
