import React, { forwardRef, memo, useCallback, useState } from "react";
import { styled } from "@material-ui/core";
import { PIN_ICON_ENABLED_BTN_TOOLTIP } from "./DashboardItemPinUnpinMenu";
import { DarkToolTip } from "components/ui/tooltips/tooltip";
import PinIcon24 from "assets/icons/push-pin/PinIcon24";

const ClickablePinIconWithTooltip = ({ onMouseOverCallback, onMouseLeaveCallback, ...props }, ref) => {
  const [isPinIconHovered, setIsPinIconHovered] = useState(false);

  const onMouseOverHandler = useCallback(() => {
    setIsPinIconHovered(true);
    onMouseOverCallback && onMouseOverCallback();
  }, []);

  const onMouseLeaveHandler = useCallback(() => {
    setIsPinIconHovered(false);
    onMouseLeaveCallback && onMouseLeaveCallback();
  }, []);

  return (
    <DarkToolTip arrow open={!!isPinIconHovered} placement={"bottom-end"} title={PIN_ICON_ENABLED_BTN_TOOLTIP}>
      <ClickableWrapper
        ref={ref}
        onMouseEnter={() => onMouseOverHandler()}
        onMouseLeave={() => onMouseLeaveHandler()}
        {...props}
        data-testid={"reports-page-pin-unpin-menu-wrapper"}
      >
        <PinIcon24 />
      </ClickableWrapper>
    </DarkToolTip>
  );
};

const ClickableWrapper = styled("div")(({ isClickable, hidden }) => ({
  cursor: isClickable && "pointer",
  visibility: hidden ? "hidden" : "visible",
  display: "flex"
}));

export default memo(forwardRef(ClickablePinIconWithTooltip));
