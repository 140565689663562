import useQuery from "../../../../common/hooks/use-query";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import * as signInModuleActions from "modules/auth/operations/signInByEmail/actions";
import * as signOutActions from "modules/auth/operations/signOut/actions";
import { useHistory } from "react-router-dom";
import FlexItem from "components/ui/Flex/FlexItem.js";
import FlexContainer from "components/ui/Flex/FlexContainer.js";
import Button from "components/ui/Buttons/Button.js";
import CardBody from "components/ui/Card/CardBody";
import CardHeader from "components/ui/Card/CardHeader";
import Text from "components/ui/Typography/Text";
import TextUnderlinedInput from "components/ui/Inputs/TextUnderlinedInput";
import { ReactComponent as LogoWhiteGreenDark } from "assets/img/vanti-logo/logo-white-green-dark.svg";
import AuthCard from "./auth-card";
import { KEY_CODES } from "common/constants/CommonConstants";
import { vantiColors } from "assets/jss/palette";
import { MarketplaceUserInviteStatus } from "../../../../services/accounts";
import { createUserViaMarketplace } from "../../../../services/api/accounts";
import * as authService from "services/auth/auth";
import SuccessGreenIcon from "assets/icons/SuccessGreenIcon";
import { validateEmail } from "@vanti-analytics-org/vanti-common";
import { toErrEmail } from "utils/email-validator";

const styles = theme => ({
  cardHeader: {
    padding: "0",
    display: "flex",
    justifyContent: "center"
  },
  cardBody: {
    position: "relative",
    padding: "20px 40px"
  },
  AzureSignInButton: {
    display: "flex",
    alignItems: "flex-start"
  },
  azureIcon: {
    margin: "-2px 10px 0 0"
  },
  generalErrorTextContainer: {
    textAlign: "center"
  },
  mainErrorWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  triangleIcon: {
    marginRight: "10px",
    width: "18px"
  },
  errorMainText: {
    fontWeight: 600,
    fontSize: "17px",
    color: theme.palette.primaryColor[2]
  },
  errorExplanation: {
    fontSize: "14px",
    marginTop: "5px",
    color: theme.palette.primaryColor[2]
  },
  mailToLink: {
    "&:active, &:hover, &:visited": {
      color: theme.palette.primaryColor[2],
      textDecoration: "underline"
    },
    color: theme.palette.primaryColor[2],
    textDecoration: "underline"
  },
  successNotification: {
    padding: "0px 0px 0px 5px !important"
  }
});

const useStyles = makeStyles(styles);

const CreateUserEmailViaMarketplace = ({ accountName, encryptedAccountId, token }) => {
  const query = useQuery();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [processingJoinRequest, setProcessingJoinRequest] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const [successNotification, setSuccessNotification] = useState(null);

  const validateEmailAddress = email => {
    setGeneralError(null);

    const validEmailResult = validateEmail(email);
    if (validEmailResult.error) {
      setGeneralError(toErrEmail(validEmailResult.error));
      return false;
    }

    return true;
  };

  const onClickJoin = async () => {
    try {
      const emailTrimed = email.trim();
      const isEmailValid = validateEmailAddress(emailTrimed);
      setProcessingJoinRequest(true);

      if (!isEmailValid) {
        return;
      }

      const result = await createUserViaMarketplace(emailTrimed, encryptedAccountId, token);

      if (!result?.status) {
        setProcessingJoinRequest(false);
        throw "invalid request";
      }

      switch (result?.status) {
        case MarketplaceUserInviteStatus.InvitationSent:
          setSuccessNotification(true);
          break;

        case MarketplaceUserInviteStatus.ExistsOnRequestedAccount:
          setGeneralError("User already exists on this account, go to sign in");
          break;

        case MarketplaceUserInviteStatus.ExistsOnOtherAccount:
          setGeneralError("User exists on other account");
          break;

        default:
          setGeneralError("Invalid request, something went wrong");
          break;
      }

      setProcessingJoinRequest(false);
    } catch (error) {
      setGeneralError("invalid request, something went wrong");
      setProcessingJoinRequest(false);
    }
  };

  const onClickSignIn = () => {
    const { accountName, encryptedAccountId, token, marketplace } = query;

    const accountNameEncoded = `accountName=${encodeURIComponent(accountName)}`;
    const encryptedAccountIdEncoded = `encryptedAccountId=${encodeURIComponent(encryptedAccountId)}`;
    const tokenEncoded = `token=${encodeURIComponent(token)}`;
    const marketplaceEncoded = `marketplace=${encodeURIComponent(marketplace)}`;
    const modeEncoded = `mode=createUserEmailViaMarketplace`;

    const queryParams = [
      modeEncoded,
      accountNameEncoded,
      encryptedAccountIdEncoded,
      tokenEncoded,
      marketplaceEncoded
    ].join("&");

    history.push(`/auth/sign-in?${queryParams}`);
  };

  useEffect(() => {
    localStorage.clear();
    authService.signOut();

    dispatch(signInModuleActions.signInClearAction());
    dispatch(signOutActions.signOutRequestAction());

    return () => {
      dispatch(signInModuleActions.signInClearAction());
      dispatch(signOutActions.signOutClearAction());
    };
  }, []);

  useEffect(() => {
    setGeneralError();
  }, [email]);

  const isJoinDisabled = !email || generalError || processingJoinRequest;

  const onKeyDown = event => {
    if (!isJoinDisabled && KEY_CODES.ENTER === event.keyCode) {
      onClickSignIn();
    }
  };

  return (
    <FlexContainer justifyContent="center" alignItems="center" container>
      <FlexItem dense="horizontal" xs={12} sm={12} md={6} container direction="row">
        <AuthCard withSignUp>
          <CardHeader color="transparent" className={classes.cardHeader}>
            <div>
              <LogoWhiteGreenDark height="50px" />
            </div>
          </CardHeader>
          <CardBody className={classes.cardBody}>
            <Text h={3}>Join account </Text>
            <Text color={vantiColors.green1} h={3}>
              {accountName}{" "}
            </Text>
            <TextUnderlinedInput
              labelText="Email"
              id="email-login"
              labelProps={{ shrink: true }}
              formControlProps={{ fullWidth: true, margin: "dense" }}
              inputProps={{
                value: email,
                onChange: e => setEmail(e.target.value),
                onKeyDown: onKeyDown
              }}
              error={generalError}
            />
            {(generalError || query.error) && (
              <Text color="red" size="10px" family="poppins">
                {generalError ?? query.error}
              </Text>
            )}

            <FlexItem dense="full" justifyContent="center" alignItems="center" container>
              <FlexItem dense="horizontal" xs={6}>
                <Button color="green" block disabled={isJoinDisabled} onClick={onClickJoin} size="small">
                  <Text color="white" size="16px" weight={"bold"} family="poppins">
                    JOIN
                  </Text>
                </Button>
              </FlexItem>
            </FlexItem>

            <Text size="12px" family="poppins">
              Already a user?{" "}
            </Text>
            <Button transparent size="small" onClick={onClickSignIn} style={{ padding: 0 }}>
              <Text color={vantiColors.green1} size="12px" family="poppins">
                Sign In
              </Text>
            </Button>

            {successNotification && (
              <FlexItem container alignItems="center">
                <SuccessGreenIcon />
                <FlexItem className={classes.successNotification}>
                  <Text size="14px" family="poppins">
                    {successNotification} Check your email and complete the registration
                  </Text>
                </FlexItem>
              </FlexItem>
            )}
          </CardBody>
        </AuthCard>
      </FlexItem>
    </FlexContainer>
  );
};

export default CreateUserEmailViaMarketplace;
