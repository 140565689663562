import Evaporate from "evaporate";
import sparkMD5 from "spark-md5";
import sha256 from "js-sha256";
import config from "./config";
import http from "../services/http";
import { logger } from "utils/logger";
import { getStoredAccessToken } from "../services/auth/auth";

export default async function initEvaporate({ name, file, onUpdate = () => {}, bucket }) {
  const awsKeyRawResult = await http.get(`${config.serverUrl}/api/v2/blocks/aws-key`);
  const awsKeyParsedResult = await awsKeyRawResult.json();
  const evaporate = await Evaporate.create({
    signerUrl: `${config.serverUrl}/api/v2/blocks/sign-url`,
    aws_key: awsKeyParsedResult.accessKeyId,
    bucket: bucket,
    xhrWithCredentials: true,
    cloudfront: false,
    awsRegion: config.evaporate.awsRegion,
    computeContentMd5: true,
    maxConcurrentParts: 2,
    partSize: 512 * 1024 * 1024 * 2,
    cryptoMd5Method: d => btoa(sparkMD5.ArrayBuffer.hash(d, true)),
    cryptoHexEncodedHash256: sha256,
    signHeaders: {
      Authorization: "Bearer " + getStoredAccessToken()
    }
  });
  const addConfig = {
    name: name,
    file: file,
    progress: progressValue => {
      logger.debug("progressValue: ", progressValue);
      logger.debug("progress percent: ", progressValue * 100);
      logger.debug("progress: ", Math.round(progressValue * 100));
      if (onUpdate) onUpdate(Math.round(progressValue * 100));
    },
    complete: () => logger.info("Complete!")
  };
  if (awsKeyParsedResult.sessionToken) {
    const awsHeaders = { "x-amz-security-token": awsKeyParsedResult.sessionToken };
    addConfig.xAmzHeadersCommon = addConfig.xAmzHeadersAtInitiate = awsHeaders;
  }
  try {
    const awsObjectKey = await evaporate.add(addConfig, { bucket });
    logger.info("File successfully uploaded to:", awsObjectKey);
  } catch (error) {
    logger.error("File did not upload successfully:", error);
    throw error;
  }
}
