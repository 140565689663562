import socketIO from "socket.io-client";
import uuid from "uuid";
import config from "../../common/config";
import { getStoredAccessToken } from "../auth/auth";

let lastPongTime = Date.now();
let reconnectInterval = null;

const createSocket = () =>
  socketIO(config.serverUrl, {
    transports: ["websocket"],
    forceNew: true
  });

let io = createSocket();

const rooms = new Map();

const checkForConnection = () => {
  if (Date.now() - lastPongTime > 35 * 1000 || io.disconnected) {
    console.log(`Seems we lost socket connection with the server, reconnecting...`);
    io.disconnect();
    io = createSocket();
  }
};

// Reconnecting to rooms when connected
io.on("connect", () => {
  if (reconnectInterval) clearInterval(reconnectInterval);
  reconnectInterval = setInterval(checkForConnection, 30 * 1000);

  rooms.forEach(handler => {
    handler();
  });
});

io.on("pong", () => {
  lastPongTime = Date.now();
});

const socket = {
  io,

  /**
   * this function used to flag rooms that we are in
   * @param {string} identifier - unique identifier for a room
   * @param {Function} joinHandler - a function to run in order to rejoin the room on disconnection
   */
  async joinRoom(identifier, joinHandler) {
    rooms.set(identifier, joinHandler);

    await joinHandler();
  },

  /**
   * @param {string} identifier - unique identifier for a room
   * @param {Function} reJoinHandler - a function to run in order to rejoin the room on disconnection
   */
  async leaveRoom(identifier, leaveHandler) {
    rooms.delete(identifier);
    await leaveHandler();
  },

  /**
   * @param {string} event
   * @param  {...any} args
   * @returns {SocketIOClient.Socket}
   */
  async emit(event, ...args) {
    return io.emit(event, await this.__createSession(), ...args);
  },

  /**
   * @param {string} event
   * @param {Function} fn
   * @returns {Emitter}
   */
  on(event, fn) {
    return io.on(event, fn);
  },

  /**
   * @param {string} event
   * @param {Function} fn
   * @returns {Emitter}
   */
  off(event, fn) {
    return io.off(event, fn);
  },

  /**
   * @param {Function} fn
   * @returns {Emitter}
   */
  error(fn) {
    return io.on("socketerror", fn);
  },

  async __createSession() {
    return {
      requestId: uuid.v4(),
      token: getStoredAccessToken()
    };
  }
};
export default socket;
