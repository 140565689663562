import React, { memo, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAsyncFn } from "react-use";
import isEmpty from "lodash/isEmpty";

import { FlexItem } from "components/ui";
import ConnectorName from "../components/ConnectorName";
import DataSourcesWrapper from "../components/data-sources/data-source-wrapper/EditDataSourceWrapper";
import DataSources from "../components/DataSources";
import LoadingOverlay from "components/ui/loading-overlay";
import Header from "../components/Header";

import { DataConnectorsContext } from "../../../DataConnectorsPage";

import { dataConnectorsEditConnector } from "modules/data-connectors/actions";

import { editConnector } from "services/api/dataConnectors";

import { CONNECTOR_PAGE_MODES, DEFAULT_CARD_WIDTH, DEFAULT_SPACING } from "../../../constants";
import useRequestWatcher from "../request-watcher";

const EditSingleDataConnector = ({ existingConnector }) => {
  const params = useParams();
  const history = useHistory();

  const { connectorId } = params;
  const { dataSources } = useContext(DataConnectorsContext);

  const [connectorName, setConnectorName] = useState(existingConnector.title);
  const [selectedDataSource, setSelectedDataSource] = useState(dataSources[existingConnector.sourceType]);
  const [dataSourceFieldsValues, setDataSourceFieldsValues] = useState({});
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [editConnectorResponse, onEditConnector] = useAsyncFn(
    async reset => {
      if (reset) {
        return null;
      }
      return await editConnector(connectorId, {
        title: connectorName,
        credentials: {
          ...dataSourceFieldsValues
        }
      });
    },
    [connectorName, dataSourceFieldsValues, connectorId]
  );

  useRequestWatcher(editConnectorResponse, dataConnectorsEditConnector);

  useEffect(async () => {
    setFieldsChanged(true);
    await onEditConnector(true);
  }, [connectorName, selectedDataSource, dataSourceFieldsValues]);

  const onClick = async () => {
    return await onEditConnector();
  };

  return (
    <>
      <FlexItem container dense="full" style={{ width: 946, marginBottom: 128 }}>
        <FlexItem data-testid="header-section" container dense="full">
          <Header onCloseClicked={history.goBack.bind(null)} title={existingConnector.title} />
        </FlexItem>

        <FlexItem data-testid="connector-name-section" container dense="horizontal">
          <FlexItem style={{ width: DEFAULT_CARD_WIDTH + DEFAULT_SPACING }} dense="full">
            <ConnectorName
              connectorName={connectorName}
              pageMode={CONNECTOR_PAGE_MODES.EDIT_CONNECTOR}
              onConnectorNameChange={setConnectorName}
            />
          </FlexItem>
        </FlexItem>

        <FlexItem container dense="horizontal" style={{ transition: "opacity 0.5s", opacity: 1 }}>
          <DataSources
            dataSources={[selectedDataSource]}
            selectedDataSource={selectedDataSource}
            onSelectDataSource={setSelectedDataSource}
            shouldKeepSelectionAfterUnmount
          />
        </FlexItem>

        <DataSourcesWrapper
          onClick={!isEmpty(connectorName.trim()) && onClick}
          actionResponse={editConnectorResponse}
          setDataSourceFieldsValues={setDataSourceFieldsValues}
          selectedDataSource={selectedDataSource}
          fieldsChanged={fieldsChanged}
        />
      </FlexItem>
      {editConnectorResponse.loading && <LoadingOverlay />}
    </>
  );
};

export default memo(EditSingleDataConnector);
