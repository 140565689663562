import React from "react";

import CardHeader from "components/ui/Card/CardHeader";

import TextLogo from "assets/img/vanti-logo/text-logo.svg";

import useStyles from "./styles";

const ConfirmAccountHeader = () => {
  const classes = useStyles();

  return (
    <CardHeader color="transparent" className={classes.cardHeader} data-testid="verify-email-vanti-logo-section">
      <div className={classes.logoContainer} data-testid="verify-email-vanti-logo">
        <img src={TextLogo} alt="Text" />
      </div>
    </CardHeader>
  );
};

export default ConfirmAccountHeader;
