export const vantiColors = {
  warning: "#ff9800",

  danger1: "#ef5350",
  danger2: "#f55a4e",
  danger3: "#CF4747",
  danger4: "#e53935",
  danger5: "#c93160",

  success1: "#66bb6a",
  success2: "#5cb860",
  success3: "#4caf50",
  success4: "#43a047",

  info1: "#00d3ee",
  info2: "#26c6da",
  info3: "#00acc1",

  rose1: "#FFDEE9",
  rose2: "#F991A0",
  rose3: "#ec407a",
  rose4: "#FE3D79",
  rose5: "#eb3573",
  rose6: "#FF3C78",
  rose7: "#e91e63",
  rose8: "#d81b60",
  rose9: "#C93160",

  gray1: "#999",
  gray2: "#777",
  gray3: "#3C4858",
  gray4: "#AAAAAA",
  gray5: "#D2D2D2",
  gray6: "#DDD",
  gray7: "#b4b4b4",
  gray8: "#555555",
  gray9: "#333",
  gray10: "#a9afbb",
  gray11: "#eee",
  gray12: "#e7e7e7",
  gray13: "#FCFCFC",
  gray14: "#DADADA",
  gray15: "#535353",
  gray16: "#E5F2F3",
  gray17: "#212935",
  gray18: "#F9F9F9",
  gray19: "#ADB3C0",
  gray20: "#c6d4ed",
  gray21: "#FFFFFF1A",
  gray22: "#F3F3FA",
  gray23: "#808080",
  gray24: "#0000008a",
  gray25: "#D9D9D9",
  gray26: "#03030333",
  gray27: "#00000033",
  gray28: "#F4F4F5",
  gray29: "#091733",
  gray30: "#353535",
  gray31: "#56565633",
  gray32: "#FFFFFF99",
  gray33: "#848B99",
  gray34: "#09173380",
  gray35: "#F3F3F5",
  gray36: "#E6E8EB",
  gray37: "#0000001f",
  gray38: "#B5B9C2",
  gray39: "#535D70",
  gray40: "#3A455C",
  gray41: "#6B7485",
  gray42: "#222E47",

  black: "#000",
  black1: "#1C1B1F",
  white: "#FFF",

  lightGreen1: "#EEFCF5",
  lightGreen2: "#CBF5E0",
  lightGreen3: "#BAF2D5",
  lightGreen4: "#86E8B6",
  lightGreen5: "#60EFA6",
  lightGreen6: "#52de9733",

  green6: "#A8EECB",
  green2: "#8AFFC8",
  green4: "#31CB7D",
  green1: "#52DE97",
  green7: "#52DE97CC",
  green3: "#00AB69",
  green8: "#0A4427",
  green5: "#A8EECB1A",
  green9: "#263238",
  green10: "#52de9790",

  lightBlue1: "#C6D4EE",
  lightBlue2: "#F9FFFF",
  lightBlue3: "#95A3BC",
  lightBlue4: "#EAF0FF",
  lightBlue5: "#F7F9FE",
  lightBlue6: "#CDDAFB",
  lightBlue7: "#E7E7EF",
  lightBlue8: "#D3D4D7",
  lightBlue9: "#c4c4c4",
  lightBlue10: "#F2F3FA",
  lightBlue11: "#CDD0ED",
  lightBlue12: "#6871C8",
  lightBlue13: "#8189D1",
  lightBlue14: "#9AA1DB",
  lightBlue15: "#E8F7FF",
  lightBlue16: "#4F5ABF",

  blue1: "#475A7D",
  blue2: "#7587AC",
  blue3: "#1A3151",
  blue4: "#13115B",
  blue5: "#DBDDF7",
  blue6: "#311F87",
  blue7: "#5492FF",
  blue8: "#0054E7",
  blue9: "#1B63E3",
  blue10: "#F0F0F0",
  blue11: "#0098E2",

  darkBlue1: "#394253",
  darkBlue2: "#646D7F",
  darkBlue3: "#121B2A",
  darkBlue4: "#616875",
  darkBlue5: "#3C1EFF",
  darkBlue6: "#091733",
  darkBlue7: "#0313A4",
  darkBlue8: "#0063DF",
  darkBlue9: "#020D73",
  darkBlue10: "#0a1733",

  lightGray1: "#DDDEDF",
  lightGray2: "#BCBFC2",
  lightGray3: "#D8D8D8",
  lightGray4: "#E9EAEB",
  lightGray5: "#EDEDFC",
  lightGray6: "#768299",
  lightGray7: "#29313F",
  lightGray8: "#E9F0FD",
  lightGray9: "#F5F5F580",
  lightGray10: "#0000001a",
  lightGray11: "#F8F8F8",
  lightGray12: "#E6E7F6",
  lightGray13: "#CED1D6",
  lightGray14: "#ffffff80",
  lightGray15: "#00000026",
  lightGray16: "#9DA2AD",
  lightGray17: "#6a7485",
  lightGray18: "#d2d2d2d1",

  lightGrayBlue1: "#A7B5D1",
  lightGrayBlue2: "#D9E7FF",
  lightGrayBlue3: "#7885A0",

  turquoise1: "#00818A",
  turquoise2: "#4EB1BA",
  turquoise3: "#00545D",
  turquoise4: "#4CA7AD",
  turquoise5: "#52DE97",
  turquoise6: "#00C6C4",

  purple1: "#B1A5FF",
  purple2: "#7B67FF",
  purple3: "#EDEDFC",
  purple4: "#B2A7FF",
  purple5: "#5940FF",
  purple6: "#D8D2FF",
  purple7: "#CCC4FF",
  purple8: "#C3BAFF",
  purple9: "#BBB1FF",
  purple10: "#7b67ff4d",

  yellow1: "#FFF7CC",
  yellow2: "#B77F00",
  yellow3: "#FFBF00",
  yellow4: "#FFF7CCE6",

  red1: "#FFE3D4",
  red2: "#FF5C00",
  red3: "#FF8038",
  red4: "#FDE3D4E6",
  red5: "#FF0000",
  red6: "#FFDEE966",
  red7: "#FF0000",
  red8: "#FF4F4F",
  red9: "#FFEFF0"
};

export const primaryColor = [vantiColors.turquoise1, vantiColors.green1, vantiColors.darkBlue1];

export const imageGalleryColors = [
  "#52DE97",
  "#7B67FF",
  "#5492FF",
  "#FFBF00",
  "#FF3C78",
  "#BACFF6",
  "#936300",
  "#13115B",
  "#BCBFC2",
  "#00818A",
  "#FFBAD0",
  "#852140",
  "#616875",
  "#C61E1E",
  "#B2A7FF"
];

export const avatarsColorPalette = [
  "#A8E2EF",
  "#A8C9EF",
  "#A8AFEF",
  "#CBA8EF",
  "#EFA8EC",
  "#EFA8A8",
  "#EFC1A8",
  "#EFDFA8",
  "#DDEFA8",
  "#A8EFCB"
];

export const APP_MAIN_BACKGROUND_COLOR = "#F9F9F9";
