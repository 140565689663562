import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const DownloadIcon24 = ({ stroke = vantiColors.gray29, ...rest }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      {...rest}
    >
      <path
        d="M12.3286 14.9896L12.3286 2.33218M12.3286 14.9896L16.3252 10.993M12.3286 14.9896L8.33212 10.993M6.00001 21L18.6574 20.9861"
        stroke={stroke}
        strokeWidth="1.1"
      />
    </svg>
  );
};

export default memo(DownloadIcon24);
