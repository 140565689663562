import React, { memo } from "react";
import { FlexItem, Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import styles from "./styles";

const Header = ({ title, error, onCloseClicked }) => {
  const classes = styles();
  return (
    <FlexItem
      container
      justifyContent="space-between"
      alignItems="center"
      dense="horizontal"
      data-testid="data-connectors-header-section"
    >
      <Text
        data-testid="header-title"
        size={14}
        color={vantiColors.darkBlue7}
        className={classes.headerText}
        onClick={onCloseClicked}
      >
        {title}
      </Text>

      <FlexItem dense="full">
        {error && (
          <FlexItem dense="full">
            <Text data-testid="header-error" color={vantiColors.rose6}>
              {error}
            </Text>
          </FlexItem>
        )}
      </FlexItem>
    </FlexItem>
  );
};

export default memo(Header);
