import http from "../http";
import config from "common/config";

const chatsApi = `${config.serverUrl}/api/v2/chats`;

export async function getChats() {
  const res = await http.get(`${chatsApi}/`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function startChat(question, datasetId = null, action = null) {
  const res = await http.post(`${chatsApi}/`, {
    question,
    datasetId,
    clickedButtonAction: action
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function getChatHistory(chatId, until = undefined) {
  const res = await http.get(`${chatsApi}/${chatId}/messages`, {
    until
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function sendTextMessageToChat(chatId, message, clickedButtonAction = null) {
  const res = await http.post(`${chatsApi}/${chatId}/messages`, { message, clickedButtonAction });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function removeReportFromChat(chatId, reportId) {
  const res = await http.delete(`${chatsApi}/${chatId}/reports/${reportId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function updateReportInChat(chatId, reportId, reportData) {
  const res = await http.patch(`${chatsApi}/${chatId}/reports/${reportId}`, reportData);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function updateChat(chatId, chatData) {
  const res = await http.patch(`${chatsApi}/${chatId}`, chatData);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function deleteChat(chatId) {
  const res = await http.delete(`${chatsApi}/${chatId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function updateMessageReportChartType(chatId, messageId, newReportChartType) {
  const res = await http.patch(`${chatsApi}/${chatId}/${messageId}`, {
    newReportChartType
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function updateMessageReportExecSummary(chatId, messageId, newExecSummary) {
  const res = await http.patch(`${chatsApi}/${chatId}/exec-summary/${messageId}`, {
    newExecSummary
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function sendChatlessQuestion(datasetId, message) {
  const res = await http.post(`${chatsApi}/${datasetId}/chatless-message`, { message });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function getChatMessage(messageId) {
  const res = await http.get(`${chatsApi}/messages/${messageId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function saveStaticReport(staticReportMessage) {
  const res = await http.post(`${chatsApi}/messages/save-static-report`, { staticReportMessage });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function getMessageDatabasePreview(chatId, chatMessageId, desiredPageNumber, itemsPerPage) {
  const response = await http.get(`${chatsApi}/${chatId}/${chatMessageId}/database-preview`, {
    desiredPageNumber,
    itemsPerPage
  });
  const res = await response.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(res.message);
  }
  if (!res.ok) {
    throw new Error(res.message);
  }

  return res.data;
}
