import { call, put, takeEvery } from "redux-saga/effects";
import * as fullstory from "common/fullstory/fullstory";
import { actionTypes } from "./actions";
import * as authService from "services/auth/auth";
import { setAuthStateAction } from "modules/auth/state/actions";
import { fetchUserRequestAction } from "modules/user/operations/fetchUser/actions";
import { fetchAccountRequestAction } from "modules/account/operations/fetchAccount/actions";
import { fetchWebNotificationRequestAction } from "modules/web-notifications/operations/fetch-web-notifications/actions";
import { actionTypes as userStateActionTypes } from "../../../user/state/actions";

export function* onAuthChangedWorker() {
  try {
    if (authService.getStoredAccessToken()) {
      const authResponse = yield call(authService.getAuthData);
      const authData = authResponse.data;
      yield put(setAuthStateAction({ ...authData }));
      yield call(() => fullstory.setIdentity(authData.uid, authData.displayName, authData.email));
      yield put({ type: actionTypes.FETCH_AUTH_DATA_SUCCESS });
      yield put(fetchUserRequestAction());
      yield put(fetchAccountRequestAction());
      yield put(fetchWebNotificationRequestAction());
    } else {
      // 'USER_SET_ROLES' is affecting the loading of all the intial application
      // We want to make sure, we show Vanti's pages only after all the roles has been loaded
      yield put({ type: userStateActionTypes.USER_SET_ROLES, payload: [] });
    }
  } catch (e) {
    // 'USER_SET_ROLES' is affecting the loading of all the intial application
    // We want to make sure, we show Vanti's pages only after all the roles has been loaded
    yield put({ type: userStateActionTypes.USER_SET_ROLES, payload: [] });
  } finally {
    yield put({ type: actionTypes.FETCH_AUTH_DATA_FULFILL });
  }
}

export default function* onAuthChangedWatcher() {
  yield takeEvery(actionTypes.FETCH_AUTH_DATA_REQUEST, onAuthChangedWorker);
}
