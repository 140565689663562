import { actionTypes } from "./actions";
import { defaultMultipleResourceOperationState } from "utils/modules-utils";

const defaultState = defaultMultipleResourceOperationState();

export default function deleteStationReducer(state = defaultState, action) {
  const stationId = action?.payload?.stationId;
  switch (action.type) {
    case actionTypes.STATION_DELETE_REQUEST: {
      state.loading[stationId] = true;
      state.success[stationId] = false;
      return { ...state };
    }
    case actionTypes.STATION_DELETE_SUCCESS: {
      state.success[stationId] = true;
      return { ...state };
    }
    case actionTypes.STATION_DELETE_ERROR: {
      state.error[stationId] = action.payload.error;
      state.success[stationId] = false;
      return { ...state };
    }
    case actionTypes.STATION_DELETE_FULFILL: {
      delete state.loading[stationId];
      delete state.success[stationId];
      return { ...state };
    }
    default:
      return state;
  }
}
