import React, { memo } from "react";
import GeneralModalPreviewComponent from "components/ui/Modal/GeneralModalPreviewComponent";
import DatasetFileInfo from "pages/side-bar/data-page/components/DatasetFileInfo";

export const DATASTE_FILE_INFO_MODAL_WIDTH = "84%";
export const DATASTE_FILE_INFO_MODAL_HEIGHT = "84%";

const DatasetFileInfoModal = ({ dataset, setUnsavedData, onCloseHandler, isReadOnly }) => {
  return (
    <GeneralModalPreviewComponent
      height={DATASTE_FILE_INFO_MODAL_HEIGHT}
      width={DATASTE_FILE_INFO_MODAL_WIDTH}
      onClose={onCloseHandler}
    >
      <DatasetFileInfo
        dataset={dataset}
        setUnsavedData={setUnsavedData}
        onCloseHandler={onCloseHandler}
        isReadOnly={isReadOnly}
      />
    </GeneralModalPreviewComponent>
  );
};

export default memo(DatasetFileInfoModal);
