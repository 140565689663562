import React, { memo, useContext } from "react";
import { CircularProgress, styled } from "@material-ui/core";
import { FlexItem, Text } from "components/ui";
import CustomTable from "components/ui/CsvTable/CustomTable";
import DataConnectorsTableBody from "./DataConnectorsTableBody";
import { DataConnectorsContext } from "pages/side-bar/data-connectors/DataConnectorsPage";
import { DATA_CONNECTORS_TABLE_HEADERS } from "pages/side-bar/data-connectors/constants";
import { APP_MAIN_BACKGROUND_COLOR, vantiColors } from "assets/jss/palette";
import DataConnectorsIcon80 from "assets/icons/data-connectors/DataConnectorsIcon80";
import GeneralVantiButton, { VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { useHistory } from "react-router-dom";
import { CONNECTORS_PLACEHOLDER_TEXT } from "pages/side-bar/data-connectors/constants";

// Note - the "className" properties in these file's components is not being sent from any father of this component, but it's just
// being used in the props for the sake of wrapping it in "styled" component which is a must
// https://stackoverflow.com/questions/52542817/styled-components-not-applying-style-to-custom-functional-react-component

const PlaceholderAreaInner = styled(FlexItem)({
  rowGap: 16
});

const AddConnectorButtonStyled = styled(GeneralVantiButton)({
  width: 215
});

const PlaceholderArea = ({ className }) => {
  const history = useHistory();

  return (
    <FlexItem container className={className}>
      <PlaceholderAreaInner dense="full" container direction="column" alignItems="center">
        <DataConnectorsIcon80 />

        <Text color={vantiColors.gray33} size={16} weight={400}>
          {CONNECTORS_PLACEHOLDER_TEXT}
        </Text>

        <AddConnectorButtonStyled
          variant={VARIANTS.TERTIARY}
          buttonInfo={{
            label: "Add data connector",
            onClick: () => {
              history.push("/dashboard/data-connectors/create");
            }
          }}
        ></AddConnectorButtonStyled>
      </PlaceholderAreaInner>
    </FlexItem>
  );
};

const PlaceholderAreaStyled = styled(PlaceholderArea)({
  height: "100%",
  backgroundColor: "white",
  borderTop: `2px solid ${APP_MAIN_BACKGROUND_COLOR}`,
  padding: "166px 497px 304.5px 497px !important"
});

const DataConnectorsTable = ({ className }) => {
  const { dataConnectors, loading } = useContext(DataConnectorsContext);

  return (
    <FlexItem dense="full" container justifyContent="center" className={className}>
      {loading && (
        <FlexItem dense="full">
          <CircularProgress />
        </FlexItem>
      )}

      <CustomTable
        headers={DATA_CONNECTORS_TABLE_HEADERS}
        dataTestId="data-connectors-table-header"
        tableContainerProps={{ "data-testid": "data-connectors-table-section" }}
        tableHeadProps={{ "data-testid": "data-connectors-table-header-section" }}
      >
        {dataConnectors?.length > 0 && (
          <DataConnectorsTableBody rows={dataConnectors} data-testid={"data-connectors-table-body-section"} />
        )}
      </CustomTable>

      {dataConnectors?.length === 0 && <PlaceholderAreaStyled />}
    </FlexItem>
  );
};

export default memo(DataConnectorsTable);
