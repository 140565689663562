import React, { memo } from "react";
import { TextField } from "@material-ui/core";
import { ReactComponent as SearchIconNoFill } from "assets/icons/SearchIconNoFill.svg";

const ItemAddOrSearchSection = ({ itemSearchValue, setItemSearchValue, setNewItemValue, placeholder }) => {
  const onItemValueChange = itemValue => {
    setItemSearchValue(itemValue);
    setNewItemValue(itemValue);
  };

  return (
    <TextField
      id={`search-station-item`}
      variant="standard"
      fullWidth
      InputLabelProps={{
        shrink: true,
        style: { fontFamily: "Inter", fontSize: 12 }
      }}
      FormHelperTextProps={{ style: { fontFamily: "Inter" } }}
      inputProps={{
        style: {
          fontFamily: "Inter",
          fontSize: 12,
          paddingLeft: 11
        },
        value: itemSearchValue,
        onChange: event => {
          onItemValueChange(event.target.value);
        },
        placeholder: placeholder,
        maxLength: 50
      }}
      InputProps={{
        startAdornment: <SearchIconNoFill />,
        disableUnderline: true
      }}
    />
  );
};

export default memo(ItemAddOrSearchSection);
