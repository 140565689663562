import { put, select, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./actions";
import { actionTypes as accountStateActionTypes } from "modules/account/state/actions";
import * as stationService from "services/api/station";
import { stationQueryActionTypes } from "../../query";

export function* editProductWorker(action) {
  const { product: editedProduct } = action.payload;
  try {
    const accountId = yield select(state => state.account.state.id);
    const editProductResponse = yield stationService.editStationProducts(accountId, editedProduct);
    yield put({ type: actionTypes.STATION_EDIT_PRODUCT_SUCCESS, payload: { product: editedProduct } });

    const accountProducts = yield select(state => state.account.state.products);
    const editedProductIndex = accountProducts.findIndex(product => product._id === editedProduct._id);
    if (editedProductIndex >= 0) {
      accountProducts[editedProductIndex] = editProductResponse;
      yield put({ type: accountStateActionTypes.ACCOUNT_SET_PRODUCTS, payload: [...accountProducts] });
    }

    const stations = yield select(state => state.station.query.stations);
    Object.values(stations).forEach(station => {
      const editedProductIndex = station.products.findIndex(product => product._id === editedProduct._id);
      if (editedProductIndex >= 0) {
        station.products[editedProductIndex] = editProductResponse;
        station.products = [...station.products];
      }
    });

    yield put({ type: stationQueryActionTypes.STATION_SET_STATIONS, payload: { ...stations } });
  } catch (error) {
    yield put({ type: actionTypes.STATION_EDIT_PRODUCT_ERROR, payload: { product: editedProduct, error } });
  } finally {
    yield put({ type: actionTypes.STATION_EDIT_PRODUCT_FULFILL, payload: { product: editedProduct } });
  }
}

export default function* editProductWatcher() {
  yield takeLatest(actionTypes.STATION_EDIT_PRODUCT_REQUEST, editProductWorker);
}
