import { Box } from "@material-ui/core";
import Text from "components/ui/Typography/Text";
import React, { memo } from "react";

import { ReactComponent as CheckIcon } from "assets/icons/check_green.svg";

import styles from "./styles";

const TrackerInfoBlock = props => {
  const { text, time, step, lastStep } = props;

  const classes = styles();

  return (
    <Box display="flex" height="100%" flexDirection="column" alignItems="center" justifyContent="center">
      {lastStep.step !== step && (
        <>
          <Text data-testid="tracker-step-num" body={3}>
            {step}
          </Text>
          <Text data-testid="tracker-step-title" style={{ textAlign: "center" }} h={3}>
            {text}
          </Text>
          <Box data-testid="tracker-step-time" className={classes.time}>
            {time}&nbsp;
            <Box component="span" fontSize="12px" fontWeight="400">
              Min
            </Box>
          </Box>
        </>
      )}
      {lastStep.step === step && (
        <Box mt="10px" display="flex" alignItems="center" flexDirection="column">
          <Text data-testid="tracker-step-title" style={{ textAlign: "center" }} h={2}>
            {text}
          </Text>
          <Box mt="10px">
            <CheckIcon />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default memo(TrackerInfoBlock);
