import { actionTypes } from "./actions";

const defaultState = {
  users: [],
  products: [],
  lines: []
};

export default function accountStateReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.ACCOUNT_SET_STATE: {
      const {
        name,
        description,
        trialDaysTotal,
        license,
        deployedModelsCount,
        startTrialDate,
        createdAt,
        updatedAt,
        maxAllowedDeployedModelsCount,
        id,
        licenseTimeLeft,
        products,
        lines,
        industries
      } = action.payload;
      return {
        ...defaultState,
        name,
        description,
        trialDaysTotal,
        license,
        deployedModelsCount,
        startTrialDate,
        createdAt,
        updatedAt,
        maxAllowedDeployedModelsCount,
        id,
        licenseTimeLeft,
        products,
        lines,
        industries
      };
    }
    case actionTypes.ACCOUNT_SET_USERS: {
      return { ...state, users: action.payload };
    }
    case actionTypes.ACCOUNT_CLEAR_STATE: {
      return { ...defaultState };
    }
    case actionTypes.ACCOUNT_ADD_PRODUCT: {
      return { ...state, products: state.products.concat(action.payload.data) };
    }
    case actionTypes.ACCOUNT_ADD_LINE: {
      const newLines = state.lines.find(line => line._id === action.payload.data._id)
        ? state.lines
        : state.lines.concat([action.payload.data]);
      return { ...state, lines: newLines };
    }
    case actionTypes.ACCOUNT_REMOVE_LINE: {
      const lineId = action.payload;
      return { ...state, lines: state.lines.filter(line => line._id !== lineId) };
    }
    case actionTypes.ACCOUNT_SET_PRODUCTS: {
      return { ...state, products: action.payload };
    }
    case actionTypes.ACCOUNT_SET_LINES: {
      return { ...state, lines: action.payload };
    }
    case actionTypes.ACCOUNT_REMOVE_PRODUCT: {
      const productId = action.payload;
      return { ...state, products: state.products.filter(product => product._id !== productId) };
    }
    default:
      return state;
  }
}
