import React, { memo, useEffect, useMemo, useState } from "react";
import { FlexItem } from "components/ui";
import AddNewItemSection from "../common/AddNewItemSection";
import ItemAddOrSearchSection from "../common/ItemSearchSection";
import { TAGS } from "../constants";
import Line from "./Line";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "components/ui/Progress/CircularProgress";
import { vantiColors } from "assets/jss/palette";
import styles from "../common/commonStyles";
import { createLineAction, stationLineAssignAction, stationLineEditAction } from "modules/station/query";
import { accountIdSelector } from "modules/account/state/selectors";
import { Box, IconButton } from "@material-ui/core";
import { ReactComponent as CheckIcon } from "assets/icons/check_16.svg";
import * as queryModule from "modules/station/query";

const Lines = ({ stationLinesMap, lines, stationId, onPopoverClose }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const accountId = useSelector(accountIdSelector);
  const isLoading = useSelector(state => state.station.query.lineLoading);
  const accountApps = useSelector(queryModule.stationsSelector);

  const [linesForDisplay, setLinesForDisplay] = useState(lines);
  const [lineSearchValue, setLineSearchValue] = useState("");
  const [isAddingNewLine, setIsAddingNewLine] = useState("");
  const [newLineValue, setNewLineValue] = useState("");
  const [editedLineValue, setEditedLineValue] = useState("");
  const [lineBeingEditedId, setLineBeingEditedId] = useState(null);

  useEffect(() => {
    setLinesForDisplay(lines);
  }, [lines]);

  useEffect(() => {
    if (lineBeingEditedId) {
      setEditedLineValue(linesForDisplay.find(line => line._id === lineBeingEditedId).name);
    }
  }, [lineBeingEditedId]);

  useEffect(() => {
    if (!lineSearchValue.trim()) {
      setLinesForDisplay(lines);
    } else {
      const filteredLines = lines.filter(line => (line.name || "").includes(lineSearchValue));
      setLinesForDisplay(filteredLines);
    }
  }, [lineSearchValue]);

  const onAddLineCheckboxClick = () => {
    setIsAddingNewLine(prevState => !prevState);
  };

  const onFinishAddingNewLine = () => {
    executeAddingItemAction();
    setIsAddingNewLine(false);
    setNewLineValue("");
    setLineSearchValue("");
    setLineBeingEditedId(null);
  };

  const onFinishEditingLine = () => {
    const editedLine = linesForDisplay.find(line => line._id === lineBeingEditedId);
    if (!editedLineValue.trim()) {
      setIsAddingNewLine(false);
      setLineBeingEditedId(null);
      return;
    }

    const newLineData = getNewLineData(editedLine);

    const stationIdList = accountApps.filter(app => editedLine._id === app.lineList[0]).map(app => app.id);

    dispatch(stationLineEditAction({ accountId, newValue: newLineData, stationIdList }));

    setNewLineValue("");
    setLineSearchValue("");
    setIsAddingNewLine(false);
    setLineBeingEditedId(null);
  };

  const onCheckboxClick = (newValue, lineId) => {
    const editedLine = linesForDisplay.find(line => line._id === lineId);
    dispatch(stationLineAssignAction({ isChecked: newValue, accountId, stationId, line: editedLine }));
  };

  const getNewLineData = line => {
    return {
      ...line,
      name: editedLineValue
    };
  };

  const executeAddingItemAction = () => {
    dispatch(createLineAction({ accountId, newLineValue, stationIdList: [stationId] }));
  };

  const onLineClick = lineId => {
    setLineBeingEditedId(lineId);
  };

  const onAddLineSectionNameClick = () => {
    setIsAddingNewLine(true);
  };

  const shouldDisplayDescriptionText = useMemo(() => !linesForDisplay.length && lineSearchValue.trim(), [
    linesForDisplay.length,
    lineSearchValue
  ]);

  return (
    <FlexItem container dense="full" justifyContent="flex-start" alignItems="center" data-testid="line-chip-popover">
      <FlexItem xs={12} className={classes.searchContainer}>
        <ItemAddOrSearchSection
          itemSearchValue={lineSearchValue}
          setItemSearchValue={setLineSearchValue}
          placeholder={"Search or create line"}
          itemsFoundLength={linesForDisplay.length}
          setNewItemValue={setNewLineValue}
        />
      </FlexItem>

      {!lines.length && !shouldDisplayDescriptionText && (
        <AddNewItemSection
          onAddNewItemCheckboxClick={onAddLineCheckboxClick}
          newItemValue={newLineValue}
          setNewItemValue={setNewLineValue}
          isAddingNewItem={isAddingNewLine}
          color={TAGS.LINE.themeColor}
          onFinish={onFinishAddingNewLine}
          onTitleClick={onAddLineSectionNameClick}
          text="Add a new line"
        />
      )}

      {lineSearchValue.trim() && (
        <Box className={classes.searchWrapper}>
          <Box fontSize="10px" fontWeight="400" color={vantiColors.darkBlue1}>
            Select or create line
          </Box>
          <Box className={classes.create} onClick={onFinishAddingNewLine}>
            <Box className={classes.createLine}>
              <Box>create</Box>
              <Box>
                <Box
                  className={classes.highlight}
                  component="span"
                  style={{ backgroundColor: TAGS.LINE.highlightColor }}
                >
                  {lineSearchValue.trim()}
                </Box>
              </Box>
              <IconButton className={classes.button}>
                <CheckIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}

      <FlexItem container dense="full">
        {linesForDisplay.map((line, index) => (
          <FlexItem key={line._id} xs={12} dense="full" data-testid={`line-inside-popover-index-${index}`}>
            <Line
              isSelected={!!stationLinesMap[line._id]}
              line={line}
              isBeingEdited={line._id === lineBeingEditedId}
              editedLineValue={editedLineValue}
              setEditedLineValue={setEditedLineValue}
              onFinishEditing={onFinishEditingLine}
              onNameClick={e => {
                e.stopPropagation();
                onLineClick(line._id);
              }}
              onCheckboxClick={newValue => {
                onCheckboxClick(newValue, line._id);
              }}
              onPopoverClose={onPopoverClose}
            />
          </FlexItem>
        ))}
      </FlexItem>

      {isLoading && (
        <FlexItem
          container
          dense="full"
          alignItems="center"
          justifyContent="center"
          className={classes.circularProgressSection}
        >
          <FlexItem>
            <CircularProgress color="lightGray" />
          </FlexItem>
        </FlexItem>
      )}
    </FlexItem>
  );
};

export default memo(Lines);
