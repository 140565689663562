export const actionTypes = {
  STATION_EDIT_PRODUCT_REQUEST: "station.editProduct.request",
  STATION_EDIT_PRODUCT_SUCCESS: "station.editProduct.success",
  STATION_EDIT_PRODUCT_ERROR: "station.editProduct.error",
  STATION_EDIT_PRODUCT_FULFILL: "station.editProduct.fulfill"
};

export function editProductRequestAction(editedProduct) {
  return { type: actionTypes.STATION_EDIT_PRODUCT_REQUEST, payload: { product: editedProduct } };
}
