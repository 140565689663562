import http from "../http";
import config from "common/config";

const REPORTS_API_URL = `${config.serverUrl}/api/v2/reports`;

export async function getAllAccountReports(enrich = true) {
  const res = await http.get(`${REPORTS_API_URL}/`, { enrich });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function deleteReports(ids) {
  const res = await http.delete(`${REPORTS_API_URL}/`, { ids });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function toggleReportTag(reportId, tagId) {
  const res = await http.patch(`${REPORTS_API_URL}/toggle-tag/${reportId}/${tagId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}
