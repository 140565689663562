import { call, put, select, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./actions";
import { setUserStateAction } from "../../state/actions";
import * as userService from "services/api/user";
import * as notificationSocket from "services/sockets/notification-socket";
import { fetchAccountRequestAction } from "modules/account/operations/fetchAccount/actions";
import * as authService from "services/auth/auth";
import { fetchWebNotificationRequestAction } from "modules/web-notifications/operations/fetch-web-notifications/actions";

export function* userUpdateWorker(action) {
  try {
    const { firstName, lastName, userAccountId } = action.payload;
    const currentAccountId = yield select(state => state.account.state.id);
    const user = yield call(userService.updateUser, firstName, lastName, userAccountId);
    if (userAccountId && currentAccountId !== userAccountId) {
      yield call(authService.performTokensRefresh);
      notificationSocket.leaveAccountEvents(currentAccountId);
      notificationSocket.joinAccountEvents(userAccountId);
      yield put(fetchAccountRequestAction());
    }
    yield put({ type: actionTypes.USER_UPDATE_SUCCESS });
    yield put(setUserStateAction(user));
    yield put(fetchWebNotificationRequestAction());
  } catch (error) {
    yield put({ type: actionTypes.USER_UPDATE_ERROR, payload: { error } });
  } finally {
    yield put({ type: actionTypes.USER_UPDATE_FULFILL });
  }
}

export default function* userUpdateWatcher() {
  yield takeLatest(actionTypes.USER_UPDATE_REQUEST, userUpdateWorker);
}
