import ACCOUNT_INFO_CARD from "../cards/AccountInfoCard.js";
import ADDITIONAL_INFO_CARD from "../cards/AdditionalInfoCard.js";
import PERSONAL_INFO_CARD from "../cards/PersonalInfoCard.js";
import { industries, industriesForMobile } from "../constants";

import { isUserAgentMobile } from "utils/app-utils.js";

import "react-phone-input-2/lib/material.css";

const CarouselCards = ({
  email,
  emailError,
  onEmailChange,
  accountName,
  accountNameError,
  onAccountNameChange,
  phoneNumber,
  phoneNumberError,
  onPhoneNumberChange,
  selectedIndustries,
  onSelectedIndustriesChange,
  otherIndustry,
  onOtherIndustryChange,
  isShowOtherIndustryField,
  selectedRole,
  onSelectedRoleChange,
  onIsShowOtherIndustryField,
  onClickNext
}) => {
  const isUserBrowsingFromMobile = isUserAgentMobile();
  const industriesType = isUserBrowsingFromMobile ? industriesForMobile : industries;

  return [
    ACCOUNT_INFO_CARD(accountName, accountNameError, onAccountNameChange, onClickNext, isUserBrowsingFromMobile),

    PERSONAL_INFO_CARD(
      email,
      phoneNumber,
      emailError,
      phoneNumberError,
      onEmailChange,
      onPhoneNumberChange,
      onClickNext,
      isUserBrowsingFromMobile
    ),

    ADDITIONAL_INFO_CARD(
      industriesType,
      selectedRole,
      otherIndustry,
      onSelectedIndustriesChange,
      isShowOtherIndustryField,
      selectedIndustries,
      onOtherIndustryChange,
      onSelectedRoleChange,
      onIsShowOtherIndustryField,
      isUserBrowsingFromMobile
    )
  ];
};

export default CarouselCards;
