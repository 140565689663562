import React, { useEffect } from "react";
import Card from "components/ui/Card/Card";
import CardBody from "components/ui/Card/CardBody";
import FlexContainer from "components/ui/Flex/FlexContainer";
import FlexItem from "components/ui/Flex/FlexItem";
import Text from "components/ui/Typography/Text";
import BrandedTitle from "components/ui/Typography/BrandedTitle";
import Button from "components/ui/Buttons/Button";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { navigationSetBreadcrumbsType } from "modules/navigation/actions";
import { BREADCRUMBS_TYPES } from "common/constants/NavigationConstants";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  adminPage: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    flexWrap: "nowrap",
    alignItems: "center"
  },
  cardContainer: {
    height: "122px",
    minHeight: "122px",
    maxHeight: "122px"
  },
  card: {
    height: "100%"
  }
}));

export default function AdminPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(navigationSetBreadcrumbsType(BREADCRUMBS_TYPES.ADMIN));
  }, []);

  return (
    <div className={classes.adminPage}>
      <FlexItem xs={12} sm={10} md={8} container className={classes.cardContainer}>
        <BrandedTitle text="Admin" size="30px" />
      </FlexItem>
      <FlexItem xs={12} sm={10} md={8} container className={classes.cardContainer}>
        <Card className={classes.card}>
          <CardBody>
            <FlexContainer justifyContent="space-between" alignItems="center">
              <Text color="darkBlue" size="18px" weight="bold">
                Sandbox
              </Text>

              <Button color="green" onClick={() => history.push(`/admin/sandbox`)}>
                Edit
              </Button>
            </FlexContainer>
          </CardBody>
        </Card>
      </FlexItem>
      <FlexItem xs={12} sm={10} md={8} container className={classes.cardContainer}>
        <Card className={classes.card}>
          <CardBody>
            <FlexContainer justifyContent="space-between" alignItems="center">
              <Text color="darkBlue" size="18px" weight="bold">
                Email
              </Text>

              <Button color="green" onClick={() => history.push(`/admin/emails`)}>
                Edit
              </Button>
            </FlexContainer>
          </CardBody>
        </Card>
      </FlexItem>
      <FlexItem xs={12} sm={10} md={8} container className={classes.cardContainer}>
        <Card className={classes.card}>
          <CardBody>
            <FlexContainer justifyContent="space-between" alignItems="center">
              <Text color="darkBlue" size="18px" weight="bold">
                Model Master
              </Text>

              <Button color="green" onClick={() => history.push(`/admin/explorer`)}>
                Edit
              </Button>
            </FlexContainer>
          </CardBody>
        </Card>
      </FlexItem>
    </div>
  );
}
