import { vantiColors } from "assets/jss/palette";
import { Text } from "components/ui";
import React, { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import { DataEntitiesCardContainer, TrashIcon24Styled } from "./DataEntitiesCard";
import { ClickableWrapperWithHoveredClipPath } from "./activity-section/ActivityCard";
import { DarkToolTip } from "components/ui/tooltips/tooltip";
import EntitiesAndFlowsIcon from "assets/icons/data-entities-and-flows/EntitiesAndFlowsIcon";

const FlowCard = ({ flow, index, onClickDelete, children }) => {
  const history = useHistory();

  const [isHovered, setIsHovered] = useState(false);

  const onClickFlowCard = () => {
    if (flow) {
      history.push(`/dashboard/flows/${flow._id}`);
      return;
    }

    history.push("/dashboard/flows/new");
  };

  return (
    <DataEntitiesCardContainer
      onClick={onClickFlowCard}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-testid={`flow-card-${flow?._id}`}
    >
      {children || (
        <>
          <EntitiesAndFlowsIcon width={48} height={48} />

          <DarkToolTip title={flow.title} placement={"top"} arrow>
            <div>
              <Text lineClamp={1} weight={600}>
                {flow.title || `Unnamed Flow`}
              </Text>
            </div>
          </DarkToolTip>

          <ClickableWrapperWithHoveredClipPath
            onClick={e => {
              e.stopPropagation();
              onClickDelete(flow._id);
            }}
            bgColor={"transparent"}
            borderColor={vantiColors.darkBlue6}
          >
            <TrashIcon24Styled isHovered={isHovered} stroke={vantiColors.darkBlue6} />
          </ClickableWrapperWithHoveredClipPath>
        </>
      )}
    </DataEntitiesCardContainer>
  );
};

export default memo(FlowCard);
