export const actionTypes = {
  UPDATE_WEB_NOTIFICATION_REQUEST: "web_notification.update.request",
  UPDATE_WEB_NOTIFICATION_SUCCESS: "web_notification.update.success",
  UPDATE_WEB_NOTIFICATION_ERROR: "web_notification.update.error",
  UPDATE_WEB_NOTIFICATION_FULFILL: "web_notification.update.fulfill"
};

export function updateWebNotificationRequestAction(id, updatedNotification, serverEmit = true, updateStore = true) {
  return {
    type: actionTypes.UPDATE_WEB_NOTIFICATION_REQUEST,
    payload: {
      id,
      updatedNotification,
      serverEmit,
      updateStore
    }
  };
}
