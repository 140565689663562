import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import GeneralVantiButton, { VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { styled } from "@material-ui/core";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import capitalize from "lodash/capitalize";
import HomePageHeaderInput from "./HomePageHeaderInput";
import useDashboardRequest from "common/hooks/use-dashboard-request";
import Spacer from "components/ui/Spacer";
import HomePageDashboardInvite from "./HomePageDashboardInvite";
import { GeneralAddButton } from "components/ui/Buttons/AddItemButton";

const DASHBOARD_NAME_PLACEHOLDER = "Add a name for your board";
const DASHBOARD_DESC_PLACEHOLDER = "Description...";
export const ADD_ITEM_BTN_TEXT = "Add item";

const HomePageHeader = ({ onAddItemCallback }) => {
  const { dashboardContext } = useContext(AppContext);
  const { currentDashboard } = dashboardContext;
  const { updateDashboardRequest } = useDashboardRequest();
  const [nameValue, setNameValue] = useState(null);
  const [descValue, setDescValue] = useState(null);

  useEffect(() => {
    if (currentDashboard) {
      setNameValue(capitalize(currentDashboard?.name));
      setDescValue(capitalize(currentDashboard?.description));
    }
  }, [currentDashboard]);

  const updateCallback = useCallback(
    updateFields => {
      if (currentDashboard?._id) {
        updateDashboardRequest.mutate({ dashboardId: currentDashboard._id, updateFields });
      }
    },
    [currentDashboard?._id]
  );

  const isEmptyState = useMemo(() => !currentDashboard?.items.length, [currentDashboard]);

  return (
    <HomePageHeaderContainer>
      <DashboardHeader>
        <HomePageHeaderInput
          initValue={nameValue}
          placeholder={DASHBOARD_NAME_PLACEHOLDER}
          updateCallback={newValue => updateCallback({ name: newValue })}
        />
        <HomePageHeaderInput
          initValue={descValue}
          placeholder={DASHBOARD_DESC_PLACEHOLDER}
          updateCallback={newValue => updateCallback({ description: newValue })}
          inputStyle={{ fontSize: 16 }}
        />
        <Spacer space={16} />
        <HomePageDashboardInvite />
      </DashboardHeader>

      {!isEmptyState && (
        <ButtonGroupStyled>
          <GeneralVantiButton
            variant={VARIANTS.SECONDARY}
            buttonInfo={{ onClick: onAddItemCallback, label: <GeneralAddButton textLabel={ADD_ITEM_BTN_TEXT} /> }}
          />
        </ButtonGroupStyled>
      )}
    </HomePageHeaderContainer>
  );
};

const HomePageHeaderContainer = styled("div")({
  width: "100%",
  height: 102,
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between"
});

const DashboardHeader = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
});

const ButtonGroupStyled = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  gap: 8
});

export default memo(HomePageHeader);
