import { styled } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { Skeleton } from "@material-ui/lab";

const ModalPreviewContainer = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const ModalPreviewContentWrapper = styled("div")(({ padding }) => ({
  width: "100%",
  height: "100%",
  backgroundColor: "white",
  padding,
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  borderRadius: "4px"
}));

const ModalStyled = styled(Modal)(({ width, height }) => ({
  width,
  height,
  boxSizing: "border-box",
  margin: "auto"
}));

const SkeletonStyled = styled(Skeleton)(({ height }) => ({
  borderRadius: 4,
  opacity: 0.3,
  height
}));

const LoadingWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 16,
  height: "100%"
});

const DefaultComponent = () => (
  <LoadingWrapper>
    <SkeletonStyled variant="rect" animation="wave" height={"25%"} />
    <SkeletonStyled variant="rect" animation="wave" height={"75%"} />
  </LoadingWrapper>
);

const GeneralModalPreviewComponent = ({ children, width = 892, height = 537, padding = 16, ...modalProps }) => {
  const [contentToDisplay, setContentToDisplay] = useState(<DefaultComponent />);

  useEffect(() => {
    setImmediate(() => {
      setContentToDisplay(children);
    });
  }, [children]);

  return (
    <ModalStyled width={width} height={height} open={true} {...modalProps}>
      <ModalPreviewContainer data-testid={"modal-preview-container"}>
        <ModalPreviewContentWrapper padding={padding} data-testid={"modal-preview-content-wrapper"}>
          {contentToDisplay}
        </ModalPreviewContentWrapper>
      </ModalPreviewContainer>
    </ModalStyled>
  );
};

export default memo(GeneralModalPreviewComponent);
