import React, { memo } from "react";
import VantiLogo16Icon from "assets/img/vanti-logo/VantiLogo16Icon";
import { Text } from "components/ui";
import { styled } from "@material-ui/core";

const GeneratedByVantiCellContent = ({ children }) => {
  return (
    <GeneratedByVantiCellContentWrapper>
      <VantiLogo16Icon />
      <Text>{children}</Text>
    </GeneratedByVantiCellContentWrapper>
  );
};

const GeneratedByVantiCellContentWrapper = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  gap: 4
});

export default memo(GeneratedByVantiCellContent);
