import { call, put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./actions";
import { actionTypes as accountStateActionTypes } from "../../state/actions";
import * as authService from "services/auth/auth";
import { getDaysDiff } from "utils/dateTime";

export function* fetchAccountWorker() {
  try {
    const { data: account } = yield call(authService.getAccount);
    yield put({ type: actionTypes.FETCH_ACCOUNT_SUCCESS });
    const userUsedTrialDays = getDaysDiff(new Date(account.startTrialDate), new Date());
    account.licenseTimeLeft = account.trialDaysTotal - userUsedTrialDays;
    yield put({ type: accountStateActionTypes.ACCOUNT_SET_STATE, payload: account });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_ACCOUNT_ERROR, payload: error });
  } finally {
    yield put({ type: actionTypes.FETCH_ACCOUNT_FULFILL });
  }
}

export default function* fetchAccountWatcher() {
  yield takeLatest(actionTypes.FETCH_ACCOUNT_REQUEST, fetchAccountWorker);
}
