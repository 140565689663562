import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  cardItem: {
    height: "90p",
    width: "346px",
    padding: theme.spacing(2, 2),
    gap: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transition: "0.4s all ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.vantiColors.gray18
    }
  },
  topCardPart: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    height: "36px",
    width: "314px"
  },
  bottomCardPart: {
    width: "314px",
    height: "30px",
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "row",
    alignItems: "self-end",
    justifyContent: "space-between"
  },
  content: {
    left: "24px",
    width: "266px",
    height: "36px",
    size: `${theme.spacing(1.75)}px`,
    weight: 500,
    verticalAlign: "top"
  }
}));
