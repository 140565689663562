import React, { memo, useEffect } from "react";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import { ReportDownloadText } from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportDataPreviewSection/ChatMessageReportDataPreviewTableEnlarged";
import { styled } from "@material-ui/core";
import useDatasetRequest from "../../../../../../../common/hooks/use-dataset-request";

const DOWNLOAD_TEXT = "Download";
const DOWNLOAD_FULL_DATASET_TEXT_SUFFIX = "to view the full dataset";

const ReportDataPreviewTitle = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 4
});

const ChatMessageDataPreviewTitle = ({ message, dataset }) => {
  const { getDatasetPresignedUrlReq } = useDatasetRequest();

  return (
    <ReportDataPreviewTitle>
      <Text size={16} weight={600} lineSize={"20px"}>
        {`Data preview - ${dataset?.name}`}
      </Text>
      <Text lineSize={"17px"} color={vantiColors.gray41}>
        <ReportDownloadText
          onClick={() => {
            getDatasetPresignedUrlReq.mutate(message.reportData.datasetId);
          }}
        >
          {DOWNLOAD_TEXT}{" "}
        </ReportDownloadText>
        {DOWNLOAD_FULL_DATASET_TEXT_SUFFIX}
      </Text>
    </ReportDataPreviewTitle>
  );
};

export default memo(ChatMessageDataPreviewTitle);
