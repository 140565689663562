import { useDispatch } from "react-redux";
import { useEffect } from "react";
import * as directoryModule from "modules/notification/directory";

/**
 * @param {string} accountId
 */

export function useNotifications(accountId) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (accountId) {
      dispatch(directoryModule.notificationListenStartAction(accountId));
    }
  }, [accountId, dispatch]);
}
