import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  ModalFooter: {
    padding: "10px 15px 10px",
    borderRadius: "0",
    backgroundColor: "transparent",
    border: "0"
  }
}));

export default function ModalFooter(props) {
  const classes = useStyles();
  const { className, children, ...rest } = props;
  const modalFooterClasses = classNames({
    [classes.ModalFooter]: true,
    [className]: className !== undefined
  });
  return (
    <div className={modalFooterClasses} {...rest}>
      {children}
    </div>
  );
}

ModalFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
