import React, { memo } from "react";

const EditIcon = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 1L1 14V17.5H4.5L17.5 4.5L14 1Z" stroke="#394253" />
    </svg>
  );
};

export default memo(EditIcon);
