import React, { memo, useEffect } from "react";
import { makeStyles, styled } from "@material-ui/core";
import PlaceholderIcon24 from "assets/icons/placeholder-icon/PlaceholderIcon24";
import { vantiColors } from "assets/jss/palette";

const RedirectionPlaceholder = ({ text, timeout = null, onTimeoutFinishedCallback }) => {
  const classes = useStyles();

  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        onTimeoutFinishedCallback();
      }, timeout);
    }
  }, [timeout]);

  return (
    <RedirectTemplateStyled>
      <PlaceholderIcon24 />

      <LoadingTextWrapperStyled>
        {text}
        <div className={classes.dotFlashing} />
      </LoadingTextWrapperStyled>
    </RedirectTemplateStyled>
  );
};

const RedirectTemplateStyled = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: 24,
  boxSizing: "border-box",
  backgroundColor: vantiColors.white,
  zIndex: 0
});

const LoadingTextWrapperStyled = styled("div")({
  display: "flex",
  alignItems: "baseline",
  columnGap: 8
});

const useStyles = makeStyles(() => ({
  "@keyframes k-dot-flashing": {
    "0%": {
      backgroundColor: vantiColors.darkBlue6
    },
    "50%, 100%": {
      backgroundColor: "rgba(9, 23, 51, 0.2)"
    }
  },
  dotFlashing: {
    left: "4px", //since the before:: dot is left:-8px, we add 8px padding to align everything
    position: "relative",
    width: "2px",
    height: "2px",
    borderRadius: "5px",
    backgroundColor: vantiColors.darkBlue6,
    color: vantiColors.darkBlue6,
    animation: "$k-dot-flashing 1s infinite linear alternate",
    animationDelay: "0.5s",

    "&:before, &:after": {
      content: '""',
      display: "inline-block",
      position: "absolute",
      top: "0"
    },
    "&:before": {
      left: "-4px",
      width: "2px",
      height: "2px",
      borderRadius: "5px",
      backgroundColor: vantiColors.darkBlue6,
      color: vantiColors.darkBlue6,
      animation: "$k-dot-flashing 1s infinite alternate",
      animationDelay: "0s"
    },
    "&:after": {
      left: "4px",
      width: "2px",
      height: "2px",
      borderRadius: "5px",
      backgroundColor: vantiColors.darkBlue6,
      color: vantiColors.darkBlue6,
      animation: "$k-dot-flashing 1s infinite alternate",
      animationDelay: "1s"
    }
  }
}));

export default memo(RedirectionPlaceholder);
