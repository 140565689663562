import React, { memo } from "react";

const ReportIcon24 = ({ ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M2.5 21.5V2.5H21.5V21.5H2.5Z" stroke="#091733" />
      <path d="M5 6H18M5 10.2667H16.44M5 14H14.36" stroke="black" />
    </svg>
  );
};

export default memo(ReportIcon24);
