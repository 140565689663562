/* eslint-disable max-lines */
import { actionTypes, fetchingFlagsTypes } from "./actionTypes";
import uniqBy from "lodash/uniqBy";

import {
  CODE_SNIPPET,
  DEFAULT_AUGMENTATION_VALUE,
  DEFAULT_FAIL_RATE,
  DEFAULT_NUM_OF_CLUSTERS,
  DEFAULT_SENSITIVITY
} from "pages/dashboard/onboarding/common/configuration/utils/constants";
import { ONBOARDING_TEXT_TRAINING_STATUS } from "components/ui/Tracker/TrainingStepOverview/TrainingStepOverview";

const defaultState = {
  station: {
    stationId: null,
    stationName: "",
    stationDescription: "",
    products: [],
    lines: [],
    labels: [],
    type: null
  },
  model: {
    modelId: null,
    modelName: "",
    modelDescription: "",
    pipelineVersion: null,
    configuration: {
      autoMode: true,
      smartCrop: true,
      falsePositive: 0,
      falseNegative: 0,
      falsePositiveMaxRate: 0,
      falseNegativeMaxRate: 0,
      dataRefinement: [],
      numOfClusters: DEFAULT_NUM_OF_CLUSTERS,
      averageFailRate: DEFAULT_FAIL_RATE,
      sensitivity: DEFAULT_SENSITIVITY,
      augmentationValue: DEFAULT_AUGMENTATION_VALUE,
      falsePositiveRate: 0,
      falseNegativeRate: 0,
      isTimeSplitSelected: false,
      codeContent: CODE_SNIPPET
    },
    type: null,
    file: {
      path: null,
      kpiIndexes: [],
      kpiLabels: [],
      imagesPreview: [],
      timeSeriesIndexes: [],
      columnIdentifierIndexes: [],
      seriesIdIndexes: [],
      type: null,
      data: {
        headerColumns: [],
        bodyColumns: []
      }
    },
    dataset: {},
    error: null
  },
  navigation: {
    stepper: {
      currentStep: 0
    },
    back: {
      callback: null
    },
    next: {
      callback: null
    }
  },
  isSubmitPageLoaded: false,
  onboardingTextTrainingStatus: ONBOARDING_TEXT_TRAINING_STATUS.DRAFT,
  tooltips: {},
  warnings: []
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    /* Common */
    case actionTypes.ONBOARDING_SUBMIT_PAGE_LOADED_STATUS: {
      return { ...state, isSubmitPageLoaded: action.payload };
    }
    case actionTypes.ONBOARDING_SUBMIT_PAGE_TRAINING_STATUS: {
      return { ...state, onboardingTextTrainingStatus: action.payload };
    }
    case actionTypes.ONBOARDING_CLEAR: {
      return defaultState;
    }
    case actionTypes.ONBOARDING_SET_WARNINGS: {
      return { ...state, warnings: action.payload };
    }
    case actionTypes.ONBOARDING_ADD_WARNING: {
      return {
        ...state,
        warnings: uniqBy([...state.warnings, action.payload], warning => warning.id === action.payload.id)
      };
    }
    case actionTypes.ONBOARDING_REMOVE_WARNING: {
      return { ...state, warnings: state.warnings.filter(warning => warning.id !== action.payload.id) };
    }
    /* Common */

    /* Station */
    case actionTypes.ONBOARDING_SET_STATION_NAME: {
      return { ...state, station: { ...state.station, stationName: action.payload } };
    }
    case actionTypes.ONBOARDING_SET_STATION_ERROR: {
      return { ...state, station: { ...state.station, error: action.payload } };
    }
    case actionTypes.ONBOARDING_SET_STATION_DESCRIPTION: {
      return { ...state, station: { ...state.station, stationDescription: action.payload.stationDescription } };
    }
    case actionTypes.ONBOARDING_SET_STATION: {
      return {
        ...state,
        station: { ...state.station, ...action.payload }
      };
    }
    case actionTypes.ONBOARDING_SET_STATION_ID: {
      return {
        ...state,
        station: { ...state.station, stationId: action.payload }
      };
    }
    case actionTypes.ONBOARDING_SET_STATION_PRODUCT: {
      return {
        ...state,
        station: { ...state.station, products: [...state.station.products, action.payload] }
      };
    }
    case actionTypes.ONBOARDING_UNSET_STATION_PRODUCT: {
      return {
        ...state,
        station: {
          ...state.station,
          products: state.station.products.filter(product => product._id !== action.payload._id)
        }
      };
    }
    case actionTypes.ONBOARDING_DELETE_STATION_PRODUCT: {
      return {
        ...state,
        station: {
          ...state.station,
          products: state.station.products.filter(product => product._id !== action.payload)
        }
      };
    }
    case actionTypes.ONBOARDING_EDIT_STATION_PRODUCT_SUCCESS: {
      return {
        ...state,
        station: { ...state.station, products: action.payload }
      };
    }
    case actionTypes.ONBOARDING_EDIT_STATION_LINE_SUCCESS: {
      return {
        ...state,
        station: { ...state.station, lines: action.payload }
      };
    }
    case actionTypes.ONBOARDING_SET_STATION_LINES: {
      return {
        ...state,
        station: { ...state.station, lines: [action.payload] }
      };
    }
    case actionTypes.ONBOARDING_UNSET_STATION_LINE: {
      return {
        ...state,
        station: {
          ...state.station,
          lines: []
        }
      };
    }
    case actionTypes.ONBOARDING_DELETE_STATION_LINES: {
      return {
        ...state,
        station: {
          ...state.station,
          lines: state.station.lines.filter(line => line._id !== action.payload)
        }
      };
    }
    /* Station */

    /* Model */
    case actionTypes.ONBOARDING_SET_MODEL_NAME: {
      return { ...state, model: { ...state.model, modelName: action.payload.modelName } };
    }
    case actionTypes.ONBOARDING_SET_MODEL_ID: {
      return { ...state, model: { ...state.model, modelId: action.payload } };
    }
    case actionTypes.ONBOARDING_SET_MODEL_ERROR: {
      return { ...state, model: { ...state.model, error: action.payload } };
    }
    case actionTypes.ONBOARDING_CLEAR_MODEL_ERROR: {
      return { ...state, model: { ...state.model, error: null } };
    }
    case actionTypes.ONBOARDING_SET_KPI_INDEXES: {
      return { ...state, model: { ...state.model, file: { ...state.model.file, kpiIndexes: action.payload } } };
    }
    case actionTypes.ONBOARDING_SET_TIME_SERIES_INDEXES: {
      return { ...state, model: { ...state.model, file: { ...state.model.file, timeSeriesIndexes: action.payload } } };
    }
    case actionTypes.ONBOARDING_SET_COLUMN_IDENTIFIER_INDEXES: {
      return {
        ...state,
        model: { ...state.model, file: { ...state.model.file, columnIdentifierIndexes: action.payload } }
      };
    }
    case actionTypes.ONBOARDING_SET_SERIES_ID_INDEXES: {
      return {
        ...state,
        model: { ...state.model, file: { ...state.model.file, seriesIdIndexes: action.payload } }
      };
    }
    case actionTypes.ONBOARDING_CLEAR_FILE: {
      return { ...state, model: { ...state.model, file: { ...defaultState.model.file } } };
    }
    case actionTypes.ONBOARDING_SET_KPI_LABELS: {
      return {
        ...state,
        model: {
          ...state.model,
          file: { ...state.model.file, kpiLabels: action.payload }
        }
      };
    }
    case actionTypes.ONBOARDING_SET_FILE: {
      return {
        ...state,
        model: {
          ...state.model,
          file: {
            ...state.model.file,
            ...action.payload.file
          }
        }
      };
    }
    case actionTypes.ONBOARDING_SET_MODEL_TYPE: {
      return {
        ...state,
        model: {
          ...state.model,
          configuration: {
            ...state.model.configuration,
            type: action.payload
          }
        }
      };
    }

    case actionTypes.ONBOARDING_SET_PIPELINE_VERSION: {
      return {
        ...state,
        model: {
          ...state.model,
          pipelineVersion: action.payload
        }
      };
    }
    /* Model */

    /* Configuration */
    case actionTypes.ONBOARDING_CONFIGURATION_SET_CLUSTERS_NUMBER: {
      return {
        ...state,
        model: {
          ...state.model,
          configuration: {
            ...state.model.configuration,
            numOfClusters: action.payload
          }
        }
      };
    }
    case actionTypes.ONBOARDING_CONFIGURATION_SET_AUTO_MODE: {
      return {
        ...state,
        model: {
          ...state.model,
          configuration: {
            ...state.model.configuration,
            autoMode: action.payload
          }
        }
      };
    }
    case actionTypes.ONBOARDING_CONFIGURATION_SET_SMART_CROP: {
      return {
        ...state,
        model: {
          ...state.model,
          configuration: {
            ...state.model.configuration,
            smartCrop: action.payload
          }
        }
      };
    }
    case actionTypes.ONBOARDING_CONFIGURATION_SET_AVERAGE_FAIL_RATE: {
      return {
        ...state,
        model: {
          ...state.model,
          configuration: {
            ...state.model.configuration,
            averageFailRate: action.payload
          }
        }
      };
    }
    case actionTypes.ONBOARDING_CONFIGURATION_SET_DATA_REFINEMENT: {
      return {
        ...state,
        model: {
          ...state.model,
          configuration: {
            ...state.model.configuration,
            dataRefinement: action.payload
          }
        }
      };
    }
    case actionTypes.ONBOARDING_CONFIGURATION_SET_MODEL_AUGMENTATION: {
      return {
        ...state,
        model: {
          ...state.model,
          configuration: {
            ...state.model.configuration,
            augmentationValue: action.payload
          }
        }
      };
    }

    case actionTypes.ONBOARDING_CONFIGURATION_SET_FALSE_NEGATIVE_MAXIMUM_RATE: {
      return {
        ...state,
        model: {
          ...state.model,
          configuration: {
            ...state.model.configuration,
            falseNegativeMaxRate: action.payload
          }
        }
      };
    }
    case actionTypes.ONBOARDING_CONFIGURATION_SET_FALSE_POSITIVE_MAXIMUM_RATE: {
      return {
        ...state,
        model: {
          ...state.model,
          configuration: {
            ...state.model.configuration,
            falsePositiveMaxRate: action.payload
          }
        }
      };
    }

    case actionTypes.ONBOARDING_CONFIGURATION_SET_SENSITIVITY: {
      return {
        ...state,
        model: {
          ...state.model,
          configuration: {
            ...state.model.configuration,
            sensitivity: action.payload
          }
        }
      };
    }
    case actionTypes.ONBOARDING_SET_EXISTING_CONFIG: {
      return {
        ...state,
        model: {
          ...state.model,
          ...action.payload
        }
      };
    }
    case actionTypes.ONBOARDING_CONFIGURATION_RESET: {
      return {
        ...state,
        model: {
          ...state.model,
          configuration: {
            ...defaultState.model.configuration
          }
        }
      };
    }
    case actionTypes.ONBOARDING_CONFIGURATION_SET_IS_TIME_SPLIT_SELECTED: {
      return {
        ...state,
        model: {
          ...state.model,
          configuration: {
            ...state.model.configuration,
            isTimeSplitSelected: action.payload
          }
        }
      };
    }
    /* Configuration */

    /* Navigation */
    case actionTypes.ONBOARDING_NAVIGATION_SET_CURRENT_STEP: {
      return {
        ...state,
        navigation: {
          ...state.navigation,
          stepper: {
            ...state?.navigation?.stepper,
            currentStep: action.payload
          }
        }
      };
    }

    case actionTypes.ONBOARDING_NAVIGATION_SET_NEXT_CALLBACK: {
      return {
        ...state,
        navigation: {
          ...state.navigation,
          next: {
            callback: action.payload
          }
        }
      };
    }

    /* Navigation */

    /* Fetch */
    case fetchingFlagsTypes.ONBOARDING_STATION_QUERY_SUCCESS: {
      return {
        ...state,
        station: { ...state.station, stationId: action.payload.stationId },
        error: null
      };
    }
    case fetchingFlagsTypes.ONBOARDING_MODEL_QUERY_SUCCESS: {
      return {
        ...state,
        model: { ...state.model, modelId: action.payload.modelId },
        error: null
      };
    }
    case fetchingFlagsTypes.ONBOARDING_QUERY_ERROR: {
      return { ...state, error: action.payload.error };
    }
    /* Fetch */

    default: {
      return state;
    }
  }
}
