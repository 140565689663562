import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import CalendarIcon24 from "assets/icons/calendar/CalendarIcon24";
import EditIcon24 from "assets/icons/edit-icon/EditIcon24";
import { DAYJS_DATE_FORMAT, getDateFormatDayjs } from "utils/dateTime";
import { useMutation } from "react-query";
import { DATASET_API_QUERY_KEYS, updateDatasetDateRange } from "services/api/dataset";
import { logger } from "utils/logger";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { DarkToolTip } from "components/ui/tooltips/tooltip";

const DATA_DATE_RANGE_TEXT = "Data date range";
const START_END_DATE_TEXT = "Start date - End date";
const NO_DATA_RANGE_TEXT = "No data range has been set";

const DatasetDateRange = ({ datasetId, tooltipText = "", readOnly = false }) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const { datasetsContext } = useContext(AppContext);
  const { datasets, setContext } = datasetsContext;
  const [isHovered, setIsHovered] = useState(null);
  const [showDateRange, setShowDateRange] = useState(false);

  const ref = useRef(null);

  const initialValue = useMemo(() => (startTime && endTime ? [startTime, endTime] : null), [startTime, endTime]);

  const changeDataRange = useMemo(() => showDateRange && !readOnly, [showDateRange, readOnly]);

  //this is a custom onBlur implementation since the react-calendar doesn't expose onBlur
  const handleOutsideClick = useCallback(
    e => {
      if (showCalendar && ref.current && !ref.current.contains(e.target)) {
        setShowCalendar(false);
      }
    },
    [showCalendar, ref.current]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  useEffect(() => {
    setStartTime(datasets[datasetId]?.dateRange?.start || null);
    setEndTime(datasets[datasetId]?.dateRange?.end || null);
  }, [datasets]);

  const commonUserActionStyle = useMemo(
    () => ({
      cursor: readOnly ? "not-allowed" : "pointer"
    }),
    [readOnly]
  );

  const DateRangeText = ({ color, children }) => {
    return (
      <TextStyled
        onClick={() => {
          setShowCalendar(prev => !prev);
          setShowDateRange(prev => !prev);
        }}
        commonUserActionStyle={commonUserActionStyle}
        color={color}
        borderBottom={"1px solid red"}
      >
        {children}
      </TextStyled>
    );
  };

  const updateDatasetDateRangeRequest = useMutation(
    [DATASET_API_QUERY_KEYS.UPDATE_DATASET_DATE_RANGE],
    async ({ start, end }) => {
      return updateDatasetDateRange(datasetId, start, end);
    },
    {
      manual: false,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: (data, { datasetId }) => {
        setContext(prevState => {
          const datasetsCopy = { ...prevState.datasets };
          datasetsCopy[datasetId] = { ...data };
          return { ...prevState, datasets: datasetsCopy };
        });
      },
      onError: (error, { datasetId, start, end }) =>
        logger.error("Failed to update dataset date range", { error, datasetId, start, end })
    }
  );

  const onChange = useCallback(values => {
    const start = values[0];
    const end = values[1];
    updateDatasetDateRangeRequest.mutate({ datasetId, start, end });
    setStartTime(start);
    setEndTime(end);
    setShowCalendar(false);
    setShowDateRange(true);
  }, []);

  const startEndDateText = useMemo(
    () =>
      startTime && endTime
        ? [
            getDateFormatDayjs(startTime, DAYJS_DATE_FORMAT.DATE_PICKER),
            "-",
            getDateFormatDayjs(endTime, DAYJS_DATE_FORMAT.DATE_PICKER)
          ].join(" ")
        : START_END_DATE_TEXT,
    [startTime, endTime]
  );

  const showTooltip = useMemo(() => isHovered && readOnly, [isHovered, readOnly]);

  return (
    <DarkToolTip arrow open={!!showTooltip} placement={"right-end"} title={tooltipText}>
      <DataRangeContainer
        readOnly={readOnly}
        showCalendar={showCalendar}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CalendarIcon24Styled
          commonUserActionStyle={commonUserActionStyle}
          onClick={() => {
            setShowCalendar(true);
            setShowDateRange(true);
          }}
          stroke={readOnly ? vantiColors.lightGray16 : vantiColors.gray29}
        />
        <DateRangeText color={readOnly ? vantiColors.lightGray16 : vantiColors.gray40}>
          {changeDataRange ? DATA_DATE_RANGE_TEXT : NO_DATA_RANGE_TEXT}
        </DateRangeText>
        {changeDataRange && <DateRangeText color={vantiColors.lightGray16}>{startEndDateText}</DateRangeText>}

        <CalendarContainer>
          {!readOnly && (
            <CalendarWrapper ref={ref}>
              {showCalendar && <Calendar value={initialValue} onChange={onChange} selectRange />}
            </CalendarWrapper>
          )}
          <EditIcon24Styled commonUserActionStyle={commonUserActionStyle} />
        </CalendarContainer>
      </DataRangeContainer>
    </DarkToolTip>
  );
};

const CalendarIcon24Styled = styled(CalendarIcon24)(({ commonUserActionStyle }) => ({
  ...commonUserActionStyle
}));

const EditIcon24Styled = styled(EditIcon24)(({ commonUserActionStyle }) => ({
  ...commonUserActionStyle
}));

const DataRangeContainer = styled("div")(({ readOnly, showCalendar }) => ({
  width: "fit-content",
  marginTop: readOnly ? 0 : 16,
  boxSizing: "border-box",
  borderBottom: readOnly || !showCalendar ? "none" : `1px solid ${vantiColors.gray40}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 16
}));

const CalendarContainer = styled("div")({
  position: "relative",
  display: "flex"
});

const CalendarWrapper = styled("div")({
  boxSizing: "border-box",
  position: "absolute",
  zIndex: 9999,
  top: 0,
  left: 0
});

const TextStyled = styled(Text)(({ commonUserActionStyle }) => ({
  ...commonUserActionStyle
}));

export default memo(DatasetDateRange);
