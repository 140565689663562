import React, { memo, useCallback, useRef, useState } from "react";
import { styled } from "@material-ui/core";
import MoreIconVertical24 from "assets/more-icon-vertical/MoreIconVertical24";
import Text from "../Typography/Text";
import { DarkToolTipArrowEnd, DarkToolTipArrowStart, DarkToolTipFit } from "../tooltips/tooltip";
import CheckboxUnfilled24 from "assets/icons/checkbox/CheckboxUnfilled24";
import CheckboxFilled24 from "assets/icons/checkbox/CheckboxFilled24";
import ClickablePinIconWithTooltip from "pages/side-bar/home/body/dashboard-item/ClickablePinIconWithTooltip";

const TableBodyCell = styled("td")(({ width, cellHasCheckbox }) => ({
  width,
  padding: cellHasCheckbox ? "8px 4px !important" : "inherit",
  maxWidth: width,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
}));

const TableBodyCellWithCheckbox = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: 4
});

const TableBodyCellWithIcons = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
});

const InnerCellIconsWrapper = styled("div")({
  display: "flex"
});

const ClickableWrapper = styled("div")(({ isClickable, hidden }) => ({
  cursor: isClickable && "pointer",
  visibility: hidden ? "hidden" : "visible",
  display: "flex"
}));

const OverflowText = styled(Text)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
});

const CheckboxFilled24Styled = styled(CheckboxFilled24)({
  minWidth: 24
});

const CheckboxUnfilled24Styled = styled(CheckboxUnfilled24)({
  minWidth: 24
});

const getText = (isShowTooltip, text, isShowArrow, arrowPosition, capitalize) =>
  isShowTooltip ? (
    arrowPosition === "left" ? (
      <DarkToolTipArrowStart placement="top-start" maxWidth="fit-content" title={text} arrow={isShowArrow}>
        <OverflowText capitalize={capitalize}>{text}</OverflowText>
      </DarkToolTipArrowStart>
    ) : arrowPosition === "right" ? (
      <DarkToolTipArrowEnd placement="top-end" maxWidth="fit-content" title={text} arrow={isShowArrow}>
        <OverflowText capitalize={capitalize}>{text}</OverflowText>
      </DarkToolTipArrowEnd>
    ) : (
      <DarkToolTipFit placement="top" maxWidth="fit-content" title={text} arrow={isShowArrow}>
        <OverflowText capitalize={capitalize}>{text}</OverflowText>
      </DarkToolTipFit>
    )
  ) : (
    <OverflowText capitalize={capitalize}>{text}</OverflowText>
  );

const BodyCell = ({
  cell,
  cellHasCheckbox,
  renderedCellText,
  isRowHovered,
  isLastCell,
  onSelectRow,
  isShowTooltip,
  setActionsPopupRef,
  setPinUnpinPopupRef,
  setCustomPopupRef,
  isRowSelected,
  displayRowMenu
}) => {
  const moreIconRef = useRef(null);
  const pinUnpinRef = useRef(null);
  const [isClickedPin, setIsClickedPin] = useState(false);
  const tagsRef = useRef(null);

  const handleChange = useCallback(() => {
    return onSelectRow(cell?.row);
  }, [onSelectRow, cell?.row]);

  return (
    <TableBodyCell
      key={cell.id}
      width={cell.column.getSize()}
      cellHasCheckbox={cellHasCheckbox}
      onClick={() => setActionsPopupRef(null)}
    >
      {cellHasCheckbox ? (
        <TableBodyCellWithCheckbox>
          <ClickableWrapper
            isClickable={isRowHovered}
            hidden={!isRowHovered && !isRowSelected}
            data-testid={"reports-page-checkbox-icon-wrapper"}
          >
            {isRowSelected ? (
              <CheckboxFilled24Styled
                onClick={e => {
                  handleChange();
                  e.stopPropagation();
                }}
              />
            ) : (
              <CheckboxUnfilled24Styled
                onClick={e => {
                  handleChange();
                  e.stopPropagation();
                }}
              />
            )}
          </ClickableWrapper>

          {getText(isShowTooltip, renderedCellText, true, "left", cell.column?.columnDef?.header?.capitalize)}
        </TableBodyCellWithCheckbox>
      ) : isLastCell ? (
        <TableBodyCellWithIcons>
          <span
            ref={tagsRef}
            onClick={e => {
              setCustomPopupRef({ ref: tagsRef.current, cellData: cell });
              e.stopPropagation();
            }}
          >
            {getText(isShowTooltip, renderedCellText, true, "right", cell.column?.columnDef?.header?.capitalize)}
          </span>

          {!!displayRowMenu && (
            <InnerCellIconsWrapper>
              <ClickablePinIconWithTooltip
                ref={pinUnpinRef}
                isClickable={isRowHovered}
                hidden={!isRowHovered}
                isClicked={isClickedPin}
                onClick={e => {
                  setIsClickedPin(true);
                  setPinUnpinPopupRef({ ref: pinUnpinRef.current, rowData: cell?.row?.original });
                  e.stopPropagation();
                }}
                data-testid={"reports-page-pin-unpin-menu-wrapper"}
              />

              <ClickableWrapper
                ref={moreIconRef}
                isClickable={isRowHovered}
                hidden={!isRowHovered}
                onClick={e => {
                  setActionsPopupRef({ ref: moreIconRef.current, rowData: cell?.row?.original });
                  e.stopPropagation();
                }}
                data-testid={"reports-page-more-icon-wrapper"}
              >
                <MoreIconVertical24 data-testid={"reports-page-more-icon"} />
              </ClickableWrapper>
            </InnerCellIconsWrapper>
          )}
        </TableBodyCellWithIcons>
      ) : (
        getText(isShowTooltip, renderedCellText, true, "left", cell.column?.columnDef?.header?.capitalize)
      )}
    </TableBodyCell>
  );
};

export default memo(BodyCell);
