import React, { memo, useCallback, useContext } from "react";
import { styled } from "@material-ui/core";
import ChatMessageContent from "pages/side-bar/chat/components/chat-message/ChatMessageContent";
import { ChatMessageSenderType } from "@vanti-analytics-org/vanti-common";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import usePermissions from "common/hooks/use-permissions";
import { ROLES_ENUM } from "common/constants/AccountConstants";

const SingleChatMessageContainer = styled("div")(({ isUserMessage, firstItemInList }) => ({
  display: "flex",
  gap: 8,
  flexDirection: "column",
  justifyContent: isUserMessage ? "flex-end" : "flex-start",
  alignItems: isUserMessage ? "self-end" : "self-start",
  paddingTop: firstItemInList ? 16 : 0
}));

const ChatMessage = ({ message, firstItemInList }) => {
  const isUserMessage = message.senderType === ChatMessageSenderType.USER;
  const { chatContext } = useContext(AppContext);
  const { setChatMessageMetadataId } = chatContext;

  const isPermittedToViewMessageMetadata = usePermissions([ROLES_ENUM.EDIT_DATASET_QUESTIONS]);

  const onChatMessageMetadataClick = useCallback(() => setChatMessageMetadataId(message._id), [message._id]);

  return (
    <SingleChatMessageContainer
      isUserMessage={isUserMessage}
      firstItemInList={firstItemInList}
      id={`chat-message-${message._id}`}
      data-testid={`single-chat-msg-container-${message._id}`}
    >
      <ChatMessageContent
        message={message}
        isUserMessage={isUserMessage}
        data-testid={`single-chat-msg-content-${message._id}`}
        onChatMessageMetadataClick={isPermittedToViewMessageMetadata && onChatMessageMetadataClick}
      />
    </SingleChatMessageContainer>
  );
};

export default memo(ChatMessage);
