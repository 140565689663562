import React, { memo } from "react";
import { DAYJS_DATE_FORMAT, getDateFormatDayjs } from "utils/dateTime";
import VantiLogo16Icon from "assets/img/vanti-logo/VantiLogo16Icon";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import { styled } from "@material-ui/core";

const ChatMessageReportCreatedByTitle = ({
  message,
  size,
  color = vantiColors.gray39,
  lineSize = "16.94px",
  timeFormat = DAYJS_DATE_FORMAT.DATE_PICKER
}) => {
  const reportCreationDate = getDateFormatDayjs(message.createdAt, timeFormat);
  const creationInfoString = `Created by: ${
    message.reportData?.generatedByVanti ? "Vanti" : message.reportData?.userInitiatedReportDisplayName
  } | ${reportCreationDate}`;

  return (
    <CreatedByWrapper data-testid={"report-created-by-wrapper"}>
      {message.reportData?.generatedByVanti && <VantiLogo16Icon />}
      <Text size={size} color={color} lineSize={lineSize} capitalize={true} data-testid={"report-created-by-text"}>
        {creationInfoString}
      </Text>
    </CreatedByWrapper>
  );
};

const CreatedByWrapper = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  gap: 4
});

export default memo(ChatMessageReportCreatedByTitle);
