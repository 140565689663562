import { vantiColors } from "assets/jss/palette";
import React, { memo } from "react";

const CheckMarkIcon = ({
  width = 12,
  height = 9,
  viewBox = "0 0 12 9",
  fill = "none",
  color = vantiColors.green1,
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.55747 3.49828L2.05575 2.94081L0.940806 3.94425L1.44253 4.50172L2.55747 3.49828ZM4.7 7L4.14253 7.50172L4.6586 8.07514L5.21724 7.5431L4.7 7ZM1.44253 4.50172L4.14253 7.50172L5.25747 6.49828L2.55747 3.49828L1.44253 4.50172ZM5.21724 7.5431L11.5172 1.5431L10.4828 0.456897L4.18276 6.4569L5.21724 7.5431Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(CheckMarkIcon);
