import React, { memo, useEffect, useMemo, useState } from "react";
import { FlexItem } from "components/ui";
import AddNewItemSection from "../common/AddNewItemSection";
import ItemAddOrSearchSection from "../common/ItemSearchSection";
import { TAGS } from "../constants";
import Product from "./Product";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "components/ui/Progress/CircularProgress";
import { vantiColors } from "assets/jss/palette";
import styles from "../common/commonStyles";
import { accountProductsSelector } from "modules/account/state/selectors";
import { Box, IconButton } from "@material-ui/core";
import { ReactComponent as CheckIcon } from "assets/icons/check_16.svg";
import { createAndAssignProductRequestAction } from "modules/station/operations/createAndAssignProduct/actions";
import { loadingSelector as createAndAssignProductLoadingSelector } from "modules/station/operations/createAndAssignProduct/selectors";
import { loadingSelector as assignProductLoadingSelector } from "modules/station/operations/assignProduct/selectors";
import { assignProductRequestAction } from "modules/station/operations/assignProduct/actions";
import { deleteProductRequestAction } from "modules/station/operations/deleteProduct/actions";
import { deleteProductLoadingSelector } from "modules/station/operations/deleteProduct/selectors";
import { editProductRequestAction } from "modules/station/operations/editProduct/actions";
import { editProductLoadingSelector } from "modules/station/operations/editProduct/selectors";

const Products = ({ stationProductsMap, products, stationId }) => {
  const classes = styles();
  const dispatch = useDispatch();

  const accountProducts = useSelector(accountProductsSelector);
  const createAndAssignProductIsLoading = useSelector(createAndAssignProductLoadingSelector(stationId));
  const assignProductLoadingIsLoading = useSelector(assignProductLoadingSelector(stationId));

  const [productsForDisplay, setProductsForDisplay] = useState(products);
  const [productSearchValue, setProductSearchValue] = useState("");
  const [isAddingNewProduct, setIsAddingNewProduct] = useState("");
  const [newProductValue, setNewProductValue] = useState("");
  const [editedProductValue, setEditedProductValue] = useState("");
  const [productBeingEditedId, setProductBeingEditedId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const deleteProductIsLoading = useSelector(deleteProductLoadingSelector(selectedProductId));
  const editProductIsLoading = useSelector(editProductLoadingSelector(selectedProductId));

  useEffect(() => {
    setIsLoading(
      createAndAssignProductIsLoading || assignProductLoadingIsLoading || deleteProductIsLoading || editProductIsLoading
    );
  }, [createAndAssignProductIsLoading, assignProductLoadingIsLoading, deleteProductIsLoading, editProductIsLoading]);

  useEffect(() => {
    setProductsForDisplay(
      products.filter(product => accountProducts.find(accountProduct => accountProduct._id === product._id))
    );
  }, [products, accountProducts]);

  useEffect(() => {
    if (productBeingEditedId) {
      setEditedProductValue(productsForDisplay.find(product => product._id === productBeingEditedId).name);
      setSelectedProductId(productBeingEditedId);
    }
  }, [productBeingEditedId]);

  useEffect(() => {
    if (!productSearchValue.trim()) {
      setProductsForDisplay(products);
    } else {
      const filteredProducts = products.filter(product => (product.name || "").includes(productSearchValue));
      setProductsForDisplay(filteredProducts);
    }
  }, [productSearchValue]);

  const onAddProductCheckboxClick = () => {
    setIsAddingNewProduct(prevState => !prevState);
  };

  const onFinishAddingNewProduct = () => {
    executeAddingItemAction();
    setIsAddingNewProduct(false);
    setNewProductValue("");
    setProductSearchValue("");
    setProductBeingEditedId(null);
  };

  const onFinishEditingProduct = () => {
    const editedProduct = productsForDisplay.find(product => product._id === productBeingEditedId);

    if (!editedProductValue.trim()) {
      setIsAddingNewProduct(false);
      setProductBeingEditedId(null);
      return;
    }

    const newProductData = getNewProductData(editedProduct);
    dispatch(editProductRequestAction(newProductData));
    setNewProductValue("");
    setProductSearchValue("");
    setIsAddingNewProduct(false);
    setProductBeingEditedId(null);
  };

  const onCheckboxClick = (isChecked, productId) => {
    const selectedProduct = productsForDisplay.find(product => product._id === productId);
    dispatch(assignProductRequestAction({ isChecked, stationId, product: selectedProduct }));
  };

  const getNewProductData = product => {
    return {
      ...product,
      name: editedProductValue
    };
  };

  const executeAddingItemAction = () => {
    dispatch(createAndAssignProductRequestAction({ stationId, productName: newProductValue }));
  };

  const onDeleteHandler = productId => {
    setSelectedProductId(productId);
    dispatch(deleteProductRequestAction(productId));
  };

  const onProductClick = productId => {
    setProductBeingEditedId(productId);
  };

  const onAddProductSectionNameClick = () => {
    setIsAddingNewProduct(true);
  };

  const shouldDisplayDescriptionText = useMemo(() => !productsForDisplay.length && productSearchValue.trim(), [
    productsForDisplay.length,
    productSearchValue
  ]);

  return (
    <FlexItem
      container
      dense="full"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.itemsContainer}
      data-testid="product-chip-popover"
    >
      <FlexItem xs={12} className={classes.searchContainer}>
        <ItemAddOrSearchSection
          itemSearchValue={productSearchValue}
          setItemSearchValue={setProductSearchValue}
          placeholder={"Search or create product"}
          itemsFoundLength={productsForDisplay.length}
          setNewItemValue={setNewProductValue}
        />
      </FlexItem>

      {!products.length && !shouldDisplayDescriptionText && (
        <AddNewItemSection
          onAddNewItemCheckboxClick={onAddProductCheckboxClick}
          newItemValue={newProductValue}
          setNewItemValue={setNewProductValue}
          isAddingNewItem={isAddingNewProduct}
          color={TAGS.PRODUCT.themeColor}
          onFinish={onFinishAddingNewProduct}
          onTitleClick={onAddProductSectionNameClick}
          text="Add a new product"
        />
      )}

      {productSearchValue.trim() && (
        <Box className={classes.searchWrapper}>
          <Box fontSize="10px" fontWeight="400" color={vantiColors.darkBlue1}>
            Select or create product
          </Box>
          <Box className={classes.create} onClick={onFinishAddingNewProduct}>
            <Box className={classes.createLine}>
              <Box>create</Box>
              <Box>
                <Box
                  className={classes.highlight}
                  component="span"
                  style={{ backgroundColor: TAGS.PRODUCT.highlightColor }}
                >
                  {productSearchValue.trim()}
                </Box>
              </Box>
              <IconButton className={classes.button}>
                <CheckIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}

      <FlexItem container dense="full">
        {productsForDisplay.map((product, index) => (
          <FlexItem key={product._id} xs={12} dense="full" data-testid={`product-inside-popover-index-${index}`}>
            <Product
              isSelected={!!stationProductsMap[product._id]}
              product={product}
              isBeingEdited={product._id === productBeingEditedId}
              editedProductValue={editedProductValue}
              setEditedProductValue={setEditedProductValue}
              onFinishEditing={onFinishEditingProduct}
              onNameClick={e => {
                e.stopPropagation();
                onProductClick(product._id);
              }}
              onCheckboxClick={newValue => {
                onCheckboxClick(newValue, product._id);
              }}
              onDelete={() => onDeleteHandler(product._id)}
            />
          </FlexItem>
        ))}
      </FlexItem>

      {isLoading && (
        <FlexItem
          container
          dense="full"
          alignItems="center"
          justifyContent="center"
          className={classes.circularProgressSection}
        >
          <FlexItem>
            <CircularProgress color="lightGray" />
          </FlexItem>
        </FlexItem>
      )}
    </FlexItem>
  );
};

export default memo(Products);
