import React, { memo } from "react";

const OpenInFullIcon16 = ({ ...rest }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g filter="url(#filter0_d_1047_65572)">
      <rect x="2" width="24" height="24" rx="1" fill="white" shapeRendering="crispEdges" />
      <mask
        id="mask0_1047_65572"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="4"
        width="16"
        height="16"
      >
        <rect x="6" y="4" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1047_65572)">
        <path
          d="M8 17.9999V13.0675H8.95702V16.3662L18.3663 6.95702H15.0675V6H20V10.9325H19.043V7.63373L9.63373 17.0429H12.9325V17.9999H8Z"
          fill="#091733"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_1047_65572"
        x="0"
        y="0"
        width="28"
        height="28"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1047_65572" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1047_65572" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default memo(OpenInFullIcon16);
