import React, { memo } from "react";

import { Skeleton } from "@material-ui/lab";

import { vantiColors } from "assets/jss/palette";

import styles from "./styles";

const DataSourceCard = ({ sourceTitle, iconUrl, isSelected, ...props }) => {
  const classes = styles();
  return (
    <div
      className={classes.cardButtonContainer}
      style={{
        backgroundColor: isSelected ? vantiColors.lightGray12 : vantiColors.lightBlue10,
        border: isSelected ? `1px solid #B3B8E4` : `1px solid ${vantiColors.lightBlue10}`,
        transition: "ease-in 0.2s"
      }}
    >
      <img
        data-testid="connector-img"
        src={iconUrl}
        style={{ width: "180px", objectFit: sourceTitle == "MariaDB" ? "none" : "contain" }}
        alt={<Skeleton variant="rectangular" width={290} height={180} animation="wave" />}
        {...props}
      />
    </div>
  );
};

export default memo(DataSourceCard);
