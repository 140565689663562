import React, { useEffect, useState, memo } from "react";
import * as d3 from "d3";
import { vantiColors } from "assets/jss/palette";
import styles from "./styles";

const Tracker = props => {
  const {
    size = 400,
    margin = { left: 0, top: 0, right: 0, bottom: 0 },
    arcWidth = 20,
    padAngle = 0.1,
    currentStep,
    data = [],
    infoBlock,
    currentStepIndex
  } = props;

  const backgroundRadius = size / 2;

  const [svg, setSvg] = useState();

  const classes = styles();

  useEffect(() => {
    const svg = createSvg();
    drawCircleBackground(svg);
    drawArcs(svg);
    setSvg(svg);
  }, []);

  useEffect(() => {
    if (currentStep && svg) {
      svg.selectAll(".vui_donutArcSlices").style("fill", payload => {
        return payload.data.name === currentStep?.step || (currentStepIndex > -1 && payload.index < currentStepIndex)
          ? vantiColors.green1
          : vantiColors.lightGray4;
      });
    }
  }, [currentStep, currentStepIndex, svg]);

  const arc = d3
    .arc()
    .innerRadius((size * 0.75) / 2)
    .outerRadius((size * 0.75) / 2 + arcWidth);

  const pie = d3
    .pie()
    .value(data => data.value)
    .padAngle(padAngle)
    .sort(null);

  const createSvg = () =>
    d3
      .select("#vui_chart")
      .append("svg")
      .attr("width", size + margin.left + margin.right)
      .attr("height", size + margin.top + margin.bottom)
      .append("g")
      .attr("class", "wrapper")
      .attr("id", "vui_step-svg")
      .attr("transform", `translate(${size / 2 + margin.left}, ${size / 2 + margin.top})`);

  const drawCircleBackground = svg =>
    svg
      .append("circle")
      .attr("cx", "0")
      .attr("cy", "0")
      .attr("r", backgroundRadius)
      .style("fill", vantiColors.white);

  const drawArcs = svg => {
    svg
      .selectAll(".vui_donutArcSlices")
      .data(pie(data))
      .enter()
      .append("path")
      .attr("class", "vui_donutArcSlices")
      .attr("id", (_, i) => `vui_donutArc${i}`)
      .attr("d", arc)
      .style("fill", payload => {
        return payload.data.name === currentStep?.step ? vantiColors.green4 : vantiColors.lightGray4;
      });
  };

  return (
    <div className={classes.root} style={{ width: `${size}px`, height: `${size}px` }} id="vui_chart">
      <div style={{ width: `${size / 1.8}px`, height: `${size / 1.8}px` }} className={classes.content}>
        {infoBlock}
      </div>
    </div>
  );
};

export default memo(Tracker);
