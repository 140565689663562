import { makeStyles } from "@material-ui/core";
import { DEFAULT_SPACING } from "../../../constants";

const useStyles = makeStyles(theme => ({
  connectButton: {
    alignSelf: "flex-end"
  },
  innerContainer: {
    width: "1070px",
    [theme.breakpoints.up("1366")]: {
      width: "1070px"
    },
    [theme.breakpoints.up("1600")]: {
      width: "1275px"
    }
  },
  tableRow: {
    height: theme.spacing(10.5),
    "&:hover": {
      backgroundColor: theme.palette.vantiColors.gray18
    },
    cursor: "pointer"
  },
  tableRowForViewer: {
    height: "84px",
    "&:hover": {
      backgroundColor: theme.palette.vantiColors.gray18
    }
  },
  fab: {
    backgroundColor: `${theme.palette.vantiColors.lightGray4} !important`
  },
  radioButton: {
    position: "absolute",
    top: 0,
    left: 0
  },
  cardButtonContainer: {
    display: "flex",
    width: "280px",
    height: "200px",
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.vantiColors.lightBlue10,
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: `${theme.palette.vantiColors.lightGray12} !important`
    }
  },
  codeEditor: {
    padding: "0 !important"
  },
  formControl: {
    margin: "0px !important",
    padding: "0px !important"
  },
  iconButton: {
    height: "100% !important",
    transition: "0.3s all ease-in-out",
    marginRight: theme.spacing(1),
    "& > button": {
      width: theme.spacing(3),
      padding: 0
    }
  },
  disabledConnectorNameIcon: {
    cursor: "not-allowed !important",
    opacity: 0.3
  },
  dataSourcesFieldsContainer: {
    "& > div": {
      display: "flex",
      alignItems: "flex-start",

      "&:nth-child(odd)": {
        paddingRight: `${DEFAULT_SPACING}px`
      }
    }
  },
  togglePasswordEyeIcon: {
    padding: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.vantiColors.gray20
    }
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(-7.5)
  },
  headerText: {
    cursor: "pointer",
    fontWeight: "400"
  },
  headerCloseButton: {
    position: "absolute"
  }
}));

export default useStyles;
