import React, { useCallback, useMemo, useState } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import cs from "classnames";
import _ from "lodash";
import uuid from "uuid";

import Text from "components/ui/Typography/Text";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import SecondaryVantiButton from "components/ui/Buttons/secondary/SecondaryVantiButton";
import PrimaryButton from "components/ui/Buttons/primary";
import DoughnutGraph from "components/ui/DonutGraph/DonutGraph";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SquareBulletItem from "components/SquareBulletItem";

import { SERVER_STATUS_LABELS } from "common/constants/StationConstants";
import { db } from "models/db";

import CustomHeader from "./header";
import CustomBody from "./body";

import ArrowUpIcon from "assets/icons/ArrowUpIcon";
import { ReactComponent as VantiIconGreenBeating } from "assets/img/icons/vanti-icon-green-beating-heart.svg";

import useStyles from "./styles";
import Button from "components/ui/Sandbox/tabs/button";
const XLSX = require("xlsx");

const EnhancedTable = () => {
  const ORDER_BY_VALUES = {
    ASCENDING: "asc",
    DESCENDING: "desc"
  };
  const headCells = [
    { id: "title", label: "Model Name" },
    { id: "modelId", label: "Model ID" },
    { id: "modelState", label: "Model Status" },
    { id: "modelType", label: "Model Type" },
    { id: "accuracy", label: "Model Accuracy" },
    { id: "precision", label: "Precision" },
    { id: "recall", label: "Recall" },
    { id: "silhouette", label: "Silhouette Score" },
    { id: "rmse", label: "RMSE Score" },
    { id: "mae", label: "MAE Score" },
    { id: "apiStatus", label: "API Status" },
    { id: "createdAt", label: "Created At" },
    { id: "deployedAt", label: "Deployed At" },
    { id: "creatorName", label: "Creator Name" },
    { id: "creatorId", label: "Creator ID" },
    { id: "creatorEmail", label: "Creator Email" },
    { id: "creatorLicense", label: "Creator License" },
    { id: "stationName", label: "Station Name" },
    { id: "stationId", label: "Station ID" },
    { id: "stationType", label: "Station Type" },
    { id: "stationState", label: "Station State" },
    { id: "accountName", label: "Account Name" },
    { id: "accountId", label: "Account ID" },
    { id: "datasetLink", label: "Dataset Link" },
    { id: "reportLink", label: "Report link" }
  ];
  const headCellsMap = _.chain(headCells)
    .keyBy("id")
    .mapValues("label")
    .value();

  const classes = useStyles();
  const [order, setOrder] = useState(ORDER_BY_VALUES.ASCENDING);
  const [orderBy, setOrderBy] = useState("title");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isFiltersSectionExpanded, setFiltersSectionExpanded] = useState(true);
  const [filters, setFilters] = useState({});

  // TODO: This is workaround to clear selected autocomplete value on reset, must be changed
  const [inputKey, setInputKey] = useState(uuid());

  const normalizedRows = useLiveQuery(() => {
    return (async () => {
      const rowPromises = [];
      await db.transaction("r", db.blocks, db.stations, db.users, db.accounts, async () => {
        await db.blocks.each(block => {
          rowPromises.push(
            (async () => {
              const station = block.stationId && (await db.stations.get(block.stationId));
              const creator = block.creatorId && (await db.users.get(block.creatorId));
              const account = creator?.account && (await db.accounts.get(creator.account));
              return {
                id: uuid(),
                title: block.title,
                createdAt: block.createdAt,
                deployedAt: block.deployStartedAt,
                creatorName: creator?.fullName,
                creatorId: block.creatorId,
                creatorEmail: creator?.email,
                creatorLicense: creator?.license,
                modelState: block.state,
                modelType: block.useCase,
                accuracy: block.report.accuracy,
                precision: block.report.precision,
                recall: block.report.recall,
                silhouette: block.report.silhouette,
                rmse: block.report.rmse,
                mae: block.report.mae,
                apiStatus: station?.apiStatus,
                modelId: block.id,
                stationName: station?.name,
                stationId: block.stationId,
                stationType: station?.type,
                stationState: station?.state,
                accountName: account?.name,
                accountId: account?.id,
                datasetLink: block.datasetLink,
                reportLink: station && `/dashboard/stations/${station?.id}`
              };
            })()
          );
        });
      });
      return await Promise.all(rowPromises);
    })();
  }, []);

  const optionsMap = useLiveQuery(() => {
    return (async () => {
      return {
        title: await db.blocks.orderBy("title").uniqueKeys(),
        modelId: await db.blocks.toCollection().uniqueKeys(),
        modelState: await db.blocks.orderBy("state").uniqueKeys(),
        modelType: await db.blocks.orderBy("useCase").uniqueKeys(),
        apiStatus: await db.stations.orderBy("apiStatus").uniqueKeys(),
        creatorName: await db.users.orderBy("fullName").uniqueKeys(),
        creatorId: await db.users.toCollection().uniqueKeys(),
        creatorEmail: await db.users.orderBy("email").uniqueKeys(),
        creatorLicense: await db.users.orderBy("license").uniqueKeys(),
        stationName: await db.stations.orderBy("name").uniqueKeys(),
        stationId: await db.stations.toCollection().uniqueKeys(),
        stationType: await db.stations.orderBy("type").uniqueKeys(),
        stationState: await db.stations.orderBy("state").uniqueKeys(),
        accountName: await db.accounts.orderBy("name").uniqueKeys(),
        accountId: await db.accounts.toCollection().uniqueKeys()
      };
    })();
  });

  const rows = useMemo(() => {
    if (!normalizedRows) {
      return null;
    }
    const filteredRows = normalizedRows.filter(row => {
      for (const key in filters) {
        if (row[key] !== filters[key]) {
          return false;
        }
      }
      return true;
    });
    return _.orderBy(filteredRows, [orderBy], [order]);
  }, [filters, order, orderBy, normalizedRows]);

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === ORDER_BY_VALUES.ASCENDING;
    setOrder(isAsc ? ORDER_BY_VALUES.DESCENDING : ORDER_BY_VALUES.ASCENDING);
    setOrderBy(property);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onFilterClick = e => {
    e.preventDefault();

    const newFilters = headCells.reduce((acc, { id }) => {
      const { value } = e.target.elements[id] || {};
      if (value) {
        acc[id] = value;
      }
      return acc;
    }, {});

    setFilters(newFilters);
  };

  const onResetClick = () => {
    setInputKey(uuid());
    setFilters({});
  };

  const onExportTableClick = () => {
    const blocksForExcelSheet = rows.map(row => {
      delete row.id;
      return _.mapKeys(row, (_value, key) => headCellsMap[key]);
    });
    const modelMasterWorkSheet = XLSX.utils.json_to_sheet(blocksForExcelSheet);
    modelMasterWorkSheet["!margins"] = { left: 0 };
    const modelMasterWorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(modelMasterWorkBook, modelMasterWorkSheet, "Model Master Data");
    XLSX.writeFile(modelMasterWorkBook, "Model Master.xlsx");
  };

  const stateCounters = _.countBy(rows, "modelState");

  const doughnutGraphData = useCallback(() => {
    if (!rows) {
      return null;
    }
    return {
      labels: ["Active", "Ready", "In Analysis", "Error", "Draft", "Failed Deployment", "In Deployment", "Deployed"],
      datasets: [
        {
          data: [
            stateCounters["ACTIVE"],
            stateCounters["READY"],
            stateCounters["IN_ANALYSIS"],
            stateCounters["FAILED_ANALYSIS"],
            stateCounters["DRAFT"],
            stateCounters["FAILED_DEPLOYMENT"],
            stateCounters["IN_DEPLOYMENT"],
            stateCounters["DEPLOYED"]
          ],
          backgroundColor: [
            SERVER_STATUS_LABELS["ACTIVE"].backgroundColor,
            SERVER_STATUS_LABELS["READY"].backgroundColor,
            SERVER_STATUS_LABELS["IN_ANALYSIS"].backgroundColor,
            SERVER_STATUS_LABELS["FAILED_ANALYSIS"].backgroundColor,
            SERVER_STATUS_LABELS["DRAFT"].backgroundColor,
            SERVER_STATUS_LABELS["FAILED_DEPLOYMENT"].backgroundColor,
            SERVER_STATUS_LABELS["IN_DEPLOYMENT"].backgroundColor,
            SERVER_STATUS_LABELS["DEPLOYED"].backgroundColor
          ],
          hoverOffset: 2
        }
      ]
    };
  }, [stateCounters]);

  if (!rows || !optionsMap) {
    return (
      <div className={classes.loadingScreen}>
        <VantiIconGreenBeating />
      </div>
    );
  }

  const onArrowClick = () => {
    setFiltersSectionExpanded(prevValue => !prevValue);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={cs(classes.root, { [classes.loading]: _.isEmpty(rows) })}>
      <Paper className={cs(classes.filterWrapper, { [classes.expanded]: isFiltersSectionExpanded })}>
        <h3>Filters</h3>
        <div>
          <form onReset={onResetClick} onSubmit={onFilterClick} className={classes.filters}>
            <section>
              {headCells.map((field, index) => {
                return (
                  field.id !== "datasetLink" &&
                  field.id !== "deployedAt" &&
                  field.id !== "createdAt" &&
                  field.id !== "reportLink" &&
                  field.id !== "accuracy" &&
                  field.id !== "precision" &&
                  field.id !== "recall" &&
                  field.id !== "silhouette" &&
                  field.id !== "rmse" &&
                  field.id !== "mae" && (
                    <label key={index}>
                      <Autocomplete
                        key={`${inputKey}-${index}`}
                        name={field.id}
                        id={field.id}
                        options={optionsMap[field.id]
                          .filter(option => option && option !== "" && option !== "undefined")
                          .map(option => {
                            return { title: option.toString() };
                          })}
                        getOptionLabel={option => option.title}
                        style={{ width: "100%" }}
                        renderInput={params => (
                          <TextField {...params} autoComplete="off" label={field.label} variant="outlined" />
                        )}
                      />
                    </label>
                  )
                );
              })}
            </section>
            <div>
              <PrimaryButton type="submit">Filter</PrimaryButton>
              <SecondaryVantiButton type="reset">Reset</SecondaryVantiButton>
              <Button type="button" onClick={onExportTableClick}>
                Export to Excel
              </Button>
            </div>
          </form>
          {!_.isEmpty(rows) && (
            <>
              <div className={classes.doughnutGraphContainer}>
                <div className={classes.doughnutGraph}>
                  <DoughnutGraph data={doughnutGraphData} options={{}} />
                  <Text weight="bold" size="30px" color="#394253" className={classes.deployedStationsText}>
                    {rows.length}
                  </Text>
                </div>
              </div>
              <div className={classes.bulletsContainer}>
                <div>
                  <SquareBulletItem
                    label="Active"
                    text={stateCounters["ACTIVE"] ?? 0}
                    color={SERVER_STATUS_LABELS["ACTIVE"].backgroundColor}
                  />
                  <SquareBulletItem
                    label="Ready"
                    text={stateCounters["READY"] ?? 0}
                    color={SERVER_STATUS_LABELS["READY"].backgroundColor}
                  />
                  <SquareBulletItem
                    label="In Analysis"
                    text={stateCounters["IN_ANALYSIS"] ?? 0}
                    color={SERVER_STATUS_LABELS["IN_ANALYSIS"].backgroundColor}
                  />
                  <SquareBulletItem
                    label="Error"
                    text={stateCounters["FAILED_ANALYSIS"] ?? 0}
                    color={SERVER_STATUS_LABELS["FAILED_ANALYSIS"].backgroundColor}
                  />
                  <SquareBulletItem
                    label="Draft"
                    text={stateCounters["DRAFT"] ?? 0}
                    color={SERVER_STATUS_LABELS["DRAFT"].backgroundColor}
                  />
                  <SquareBulletItem
                    label="Failed Deployment"
                    text={stateCounters["FAILED_DEPLOYMENT"] ?? 0}
                    color={SERVER_STATUS_LABELS["FAILED_DEPLOYMENT"].backgroundColor}
                  />
                  <SquareBulletItem
                    label="In Deployment"
                    text={stateCounters["IN_DEPLOYMENT"] ?? 0}
                    color={SERVER_STATUS_LABELS["IN_DEPLOYMENT"].backgroundColor}
                  />
                  <SquareBulletItem
                    label="Deployed"
                    text={stateCounters["DEPLOYED"] ?? 0}
                    color={SERVER_STATUS_LABELS["DEPLOYED"].backgroundColor}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <ArrowUpIcon
          className={cs(classes.arrowIcon, { [classes.collapsedIcon]: !isFiltersSectionExpanded })}
          onClick={onArrowClick}
        />
      </Paper>

      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
            <CustomHeader
              headCells={headCells}
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <CustomBody rows={rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} emptyRows={emptyRows} />
          </Table>
        </TableContainer>
        <TablePagination
          style={{ height: "250px" }}
          rowsPerPageOptions={[5, 10, 25, 30, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {_.isEmpty(rows) && (
        <div className={classes.loadingScreen}>
          <VantiIconGreenBeating />
        </div>
      )}
    </div>
  );
};

export default EnhancedTable;
