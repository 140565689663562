import XLSX from "xlsx";
import errors from "./errors";
import { logger } from 'utils/logger'

const A_CHAR_CODE = "A".charCodeAt(0);
const ENGLISH_LETTERS_COUNT = 26;

/**
 * Converts excel cell name to row and col
 * @param {string} cell
 * @returns {{col: number, row: number}}
 */
const cellNameToPosition = cell => {
  const cellArray = Array.from(cell);
  const rowStartIndex = cellArray.findIndex(v => !isNaN(v));
  const row = Number(cell.substring(rowStartIndex));
  let col = 0;
  cellArray
    .slice(0, rowStartIndex)
    .reverse()
    .forEach((char, index) => {
      const v = char.charCodeAt(0) - A_CHAR_CODE + 1;
      col += v * ENGLISH_LETTERS_COUNT ** index;
    });
  return { row: row - 1, col: col - 1 };
};

/**
 * @param  stream
 * @param {number} maxLines
 * @param {DataReaderCallback} callback
 */
export function read(stream, maxLines, callback) {
  const chunks = [];
  stream
    .on("data", chunk => chunks.push(chunk))
    .on("error", error => {
      logger.error(error);
      callback(new Error(errors.FAILED_PARSING), null);
    })
    .on("end", () => {
      try {
        const raw = Buffer.concat(chunks);
        const workbook = XLSX.read(raw, { type: "buffer", sheetRows: maxLines + 1 });
        const results = workbook.SheetNames.map(sheetName => {
          // Parse sheet dimensions
          const sheet = workbook.Sheets[sheetName];
          const sheetDim = sheet["!ref"].split(":");
          const lastCellPos = cellNameToPosition(sheetDim[1]);
          const rows = lastCellPos.row + 1;
          const cols = lastCellPos.col + 1;
          // Initializing arrays
          const headers = Array(cols).fill("");
          const data = [...Array(rows - 1)].map(() => Array(cols).fill(""));
          // Fill data
          // eslint-disable-next-line no-unused-vars
          for (const [name, cell] of Object.entries(sheet)) {
            if (name.startsWith("!")) continue;
            const position = cellNameToPosition(name);
            if (position.row === 0) {
              headers[position.col] = cell.v;
            } else {
              data[position.row - 1][position.col] = cell.v;
            }
          }
          return { name: sheetName, headers, data };
        });
        callback(null, results);
      } catch (error) {
        logger.error(error);
        callback(new Error(errors.FAILED_PARSING), null);
      }
    });
}
