/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FlexItem from "../../../components/ui/Flex/FlexItem.js";
import FlexContainer from "../../../components/ui/Flex/FlexContainer.js";
import Button from "../../../components/ui/Buttons/Button.js";
import { Redirect, useHistory } from "react-router-dom";
import Card from "../../../components/ui/Card/Card";
import CardBody from "../../../components/ui/Card/CardBody";
import CardFooter from "../../../components/ui/Card/CardFooter";
import CardHeader from "../../../components/ui/Card/CardHeader";
import { ReactComponent as LogoWhiteGreenDark } from "assets/img/vanti-logo/logo-white-green-dark.svg";
import TextUnderlinedInput from "../../../components/ui/Inputs/TextUnderlinedInput";
import Text from "../../../components/ui/Typography/Text";
import BrandedTitle from "../../../components/ui/Typography/BrandedTitle";
import * as authService from "../../../services/auth/auth";
import * as PropTypes from "prop-types";
import { getShowPasswordProps, validatePassword } from "../Utils";
import { vantiColors } from "assets/jss/palette";
import GreenV from "assets/icons/GreenV.svg";

const styles = {
  cardHeader: {
    padding: "0",
    display: "flex",
    justifyContent: "center"
  },
  cardBody: {
    position: "relative",
    padding: "30px"
  },
  cardFooter: {
    padding: "30px",
    flexDirection: "column"
  }
};

function getError(error) {
  if (!error) {
    return null;
  }
  switch (error?.code) {
    case "auth/expired-action-code":
      return "Token expired, please try to reset password again";
    case "auth/invalid-action-code":
      return "Token invalid, please try to reset password again";
    case "auth/user-disabled":
      return "User is disabled";
    case "auth/user-not-found":
      return "User not found";
    default:
      return "Unexpected error occurred";
  }
}

const useStyles = makeStyles(styles);

export default function ResetPasswordAction({ resetToken }) {
  const classes = useStyles();
  const history = useHistory();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [error, setError] = useState(null);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordStatus, setPasswordStatus] = useState({ message: "", type: "neutral" });
  const [passwordConfirmStatus, setPasswordConfirmStatus] = useState({ message: "", type: "neutral" });

  const [isChangePasswordDisabled, setIsChangePasswordDisabled] = useState(true);

  useEffect(() => {
    setError(null);
    if (passwordStatus.type === "success" && passwordConfirmStatus.type === "success") {
      setIsChangePasswordDisabled(false);
    } else {
      setIsChangePasswordDisabled(true);
    }
  }, [password, confirmPassword]);

  const onClickChangePassword = async () => {
    if (password === confirmPassword) {
      try {
        await authService.confirmResetPassword(resetToken, password);
        setIsPasswordChanged(true);
      } catch (err) {
        setError(getError(error));
      }
    }
  };

  const onClickDone = () => history.push("/auth/sign-in");

  const onPasswordChange = passwordValue => {
    setPassword(passwordValue);
    const passwordValidation = validatePassword(passwordValue);
    setPasswordStatus(passwordValidation);
    if (confirmPassword !== passwordValue) {
      setPasswordConfirmStatus({ message: "Passwords do not match", type: "error" });
    } else {
      setPasswordConfirmStatus({ message: "", type: "success" });
    }
  };

  const onConfirmPasswordChange = passwordValue => {
    setConfirmPassword(passwordValue);
    if (passwordStatus.type === "success") {
      setPasswordStatus({ message: "", type: "success" });
    }
    if (passwordValue === "") {
      setPasswordConfirmStatus({ message: "* Mandatory field", type: "error" });
    }
    if (password !== passwordValue) {
      setPasswordConfirmStatus({ message: "Passwords do not match", type: "error" });
    } else if (passwordValue !== "") {
      setPasswordConfirmStatus({
        message: (
          <div style={{ color: vantiColors.success1 }}>
            <img src={GreenV} alt="Check" /> Awesome, passwords match
          </div>
        ),
        type: "success"
      });
    }
  };

  if (!resetToken) {
    return <Redirect to={"/dashboard/auth"} />;
  }

  return (
    <FlexContainer justifyContent={"center"}>
      <FlexItem xs={12} sm={6} md={4} container direction={"row"}>
        <Card>
          <CardHeader color="transparent" className={classes.cardHeader} data-testid="vanti-logo-section">
            <div>
              <LogoWhiteGreenDark height={"50px"} data-testid="vanti-logo" />
            </div>
          </CardHeader>
          <CardBody className={classes.cardBody}>
            <BrandedTitle text={"Change Password"} size="28px" />
            {!isPasswordChanged && (
              <Fragment>
                <TextUnderlinedInput
                  labelText="New Password"
                  id="new-password-reset-password"
                  dataTestId="new-password-reset-password"
                  labelProps={{ shrink: true }}
                  formControlProps={{ fullWidth: true, margin: "dense" }}
                  inputProps={{
                    value: password,
                    ...getShowPasswordProps(showPassword, setShowPassword),
                    onChange: e => {
                      onPasswordChange(e.target.value);
                    }
                  }}
                  error={passwordStatus.type === "error"}
                  success={passwordStatus.type === "success"}
                  neutral={passwordStatus.type === "neutral"}
                  helperText={passwordStatus.message}
                />
                <TextUnderlinedInput
                  labelText="Confirm Password"
                  id="confim-password-reset-password"
                  dataTestId="confim-password-reset-password"
                  labelProps={{ shrink: true }}
                  formControlProps={{ fullWidth: true, margin: "dense" }}
                  inputProps={{
                    value: confirmPassword,
                    ...getShowPasswordProps(showConfirmPassword, setShowConfirmPassword),
                    onChange: e => {
                      onConfirmPasswordChange(e.target.value);
                    }
                  }}
                  error={passwordConfirmStatus.type === "error"}
                  success={passwordConfirmStatus.type === "success"}
                  neutral={passwordConfirmStatus.type === "neutral"}
                  helperText={passwordConfirmStatus.message}
                />
              </Fragment>
            )}
            {isPasswordChanged && (
              <Text color="darkBlue" family="poppins" size="16px">
                Your password was changed successfully.
              </Text>
            )}
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            {!isPasswordChanged && (
              <Button
                color="green"
                block
                disabled={isChangePasswordDisabled}
                onClick={onClickChangePassword}
                size="small"
                data-testid="change-password-btn"
              >
                <Text color="white" size="16px" weight={"bold"} family="poppins">
                  Change Password
                </Text>
              </Button>
            )}
            {isPasswordChanged && (
              <Button
                color="green"
                block
                disabled={isChangePasswordDisabled}
                onClick={onClickDone}
                size="small"
                data-testid="done-btn"
              >
                <Text color="white" size="16px" weight={"bold"} family="poppins">
                  Done
                </Text>
              </Button>
            )}
            {error && (
              <Text color="red" size="16px" weight={"bold"} family="poppins">
                {error}
              </Text>
            )}
          </CardFooter>
        </Card>
      </FlexItem>
    </FlexContainer>
  );
}

ResetPasswordAction.propTypes = {
  oobCode: PropTypes.string
};
