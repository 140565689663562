import { vantiColors } from "assets/jss/palette";
import React, { memo } from "react";

const ErrorIcon40 = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.75" cy="12" r="11.375" stroke={vantiColors.rose6} strokeWidth="1.25" />
      <path d="M13.375 13.6865H12.125L12.125 6.18652H13.375V13.6865Z" fill={vantiColors.rose6} />
      <path
        d="M13.3539 17.5775C13.1964 17.735 12.9951 17.8138 12.7502 17.8138C12.5052 17.8138 12.3039 17.735 12.1464 17.5775C11.9889 17.42 11.9102 17.2188 11.9102 16.9738C11.9102 16.7288 11.9889 16.5275 12.1464 16.37C12.3039 16.2125 12.5052 16.1338 12.7502 16.1338C12.9951 16.1338 13.1964 16.2125 13.3539 16.37C13.5114 16.5275 13.5901 16.7288 13.5901 16.9738C13.5901 17.2188 13.5114 17.42 13.3539 17.5775Z"
        fill={vantiColors.rose6}
      />
    </svg>
  );
};

export default memo(ErrorIcon40);
