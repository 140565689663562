import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { logger } from "utils/logger";
import CardHeader from "./card-header/CardHeader";
import CardNextButton from "./card-next-button/CardNextButton";
import CardSubHeader from "./card-sub-header/CardSubHeader";
import CarouselCards from "./carousel-cards/CarouselCards";
import CreationCompletedPage from "./creation-completed-page/CreationCompletedPage";
import FlexContainer from "components/ui/Flex/FlexContainer";
import FlexItem from "components/ui/Flex/FlexItem";
import Text from "components/ui/Typography/Text";
import VantiFullPageCard from "../../vanti-full-page-card/index";
import { Carousel } from "react-responsive-carousel";

import {
  errorSelector as createAccountErrorSelector,
  loadingSelector as createAccountIsLoadingSelector,
  successSelector as createAccountIsSuccessSelector
} from "modules/account/operations/createAccount/selectors";

import http from "services/http";
import { apiUrl } from "services/auth/auth";

import { INDUSTRIES, maxInputFieldLength, PHONE_NUMBER_REGEX } from "./constants";
import { alertBeforeRefresh, scrollToBottom, scrollToTop } from "utils/app-utils";

import * as fullStory from "common/fullstory/fullstory";
import FULLSTORY_EVENTS from "common/fullstory/fullStoryEvents";
import useQuery from "common/hooks/use-query";

import { vantiColors } from "assets/jss/palette";

import styles from "./styles";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { clearAccountStateAction } from "modules/account/state/actions";
import { createAccountRequestAction } from "../../../modules/account/operations/createAccount/actions";
import { validateEmail } from "@vanti-analytics-org/vanti-common";
import { toErrEmail } from "utils/email-validator";

const CreateAccount = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const classes = styles();

  // TODO: https://vanti-analytics.atlassian.net/browse/DEV-3389
  const [accountName, setAccountName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [accountNameError, setAccountNameError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("us");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [otherIndustry, setOtherIndustry] = useState("");
  const [isShowOtherIndustryField, setIsShowOtherIndustryField] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");

  const [carouselIndex, setCarouselIndex] = useState(0);

  const isCreationRequestLoading = useSelector(createAccountIsLoadingSelector);
  const isCreationRequestSucceeded = useSelector(createAccountIsSuccessSelector);
  const creationProcessError = useSelector(createAccountErrorSelector);

  const debouncedChangeHandler = useCallback(
    debounce(
      email =>
        http.post(`${apiUrl}/validate-existing-domain`, { email }, {}, true).then(resp => {
          resp.json().then(result => {
            if (result.code === "error") {
              setEmailError(
                "This email is already connected to another domain, please contact the admin to get invitation link"
              );
            }
          });
        }),
      500
    ),
    []
  );

  useEffect(() => {
    window.addEventListener("beforeunload", alertBeforeRefresh);
    window.addEventListener("beforeunload", dispatch(clearAccountStateAction()));

    return () => {
      window.removeEventListener("beforeunload", alertBeforeRefresh);
      window.removeEventListener("beforeunload", dispatch(clearAccountStateAction()));
    };
  }, []);

  useEffect(() => {
    if (creationProcessError) {
      scrollToBottom("smooth");
    }
  }, [creationProcessError]);

  useEffect(() => {
    if (isCreationRequestSucceeded) {
      window.removeEventListener("beforeunload", alertBeforeRefresh);
    }
  }, [isCreationRequestSucceeded]);

  const onEmailChange = async value => {
    setEmail(value);

    if (value.trim() === "") {
      setEmailError("Required field");
      return;
    }

    const validEmailResult = validateEmail(value);
    if (validEmailResult.error) {
      setEmailError(toErrEmail(validEmailResult.error));
      return;
    }

    setEmailError("");
    debouncedChangeHandler(validEmailResult.email);
  };

  const onAccountNameChange = value => {
    setAccountName(value);
    if (value.trim() === "") {
      setAccountNameError("Required field");
    } else {
      setAccountNameError("");
    }
  };

  const onPhoneNumberChange = value => {
    setPhoneNumber(value);

    if (!value.match(PHONE_NUMBER_REGEX) || value.length < 10) {
      setPhoneNumberError("Phone number is not valid");
    } else {
      setPhoneNumberError("");
    }
  };

  const onSelectedIndustriesChange = value => {
    scrollToBottom("smooth");
    const newState = [...selectedIndustries];

    if (newState.includes(value)) {
      const indexOfValue = newState.indexOf(value);
      newState.splice(indexOfValue, 1);
      setSelectedIndustries(newState);
      return;
    }

    if (value === INDUSTRIES.OTHER.value) {
      if (otherIndustry.trim() !== "") {
        newState.push(value);
        setSelectedIndustries(newState);
      }
      return;
    }

    newState.push(value);
    setSelectedIndustries(newState);
  };

  const onOtherIndustryChange = value => {
    setOtherIndustry(value);
  };

  const onSelectedRoleChange = value => {
    setSelectedRole(value);
  };

  useEffect(() => {
    if (!isShowOtherIndustryField) {
      const newState = [...selectedIndustries];
      const indexOfValue = newState.indexOf(otherIndustry);

      if (indexOfValue > -1) {
        newState.splice(indexOfValue, 1);
      }
    }
  }, [isShowOtherIndustryField]);

  useEffect(() => {
    if (isCreationRequestSucceeded) {
      let newSelectedIndustries = [...selectedIndustries];

      if (isShowOtherIndustryField && otherIndustry.trim() !== "") {
        newSelectedIndustries.push(otherIndustry);
      } else {
        newSelectedIndustries = selectedIndustries;
      }

      try {
        fullStory.setCustomEvent(FULLSTORY_EVENTS.NEW_ACCOUNT_CREATED_SUCCESSFULLY, {
          email,
          accountName,
          phoneNumber,
          industries: newSelectedIndustries,
          role: selectedRole
        });
      } catch (error) {
        logger.error(`Error creating a custom Fullstory event: ${error}`);
      }
    }
  }, [isCreationRequestSucceeded]);

  const onClickBack = () => {
    if (!isFirstCardDisplaying) {
      setCarouselIndex(prevState => prevState - 1);
    }
  };

  const getAccountIndustries = () => {
    let newSelectedIndustries = [...selectedIndustries];
    if (isShowOtherIndustryField && otherIndustry.trim() !== "") {
      newSelectedIndustries.push(otherIndustry);
    } else {
      newSelectedIndustries = selectedIndustries;
    }
    return newSelectedIndustries;
  };

  const onClickFinish = () => {
    const newSelectedIndustries = getAccountIndustries();

    dispatch(
      createAccountRequestAction(
        email,
        accountName,
        phoneNumber,
        newSelectedIndustries,
        selectedRole,
        query["marketplace"],
        query["token"]
      )
    );
  };

  const onClickNext = () => {
    scrollToTop("smooth");

    if (!isLastCardDisplaying && !isNextDisabled) {
      setCarouselIndex(prevState => prevState + 1);
    }
  };

  const onIsShowOtherIndustryField = newState => {
    scrollToBottom("smooth");
    setIsShowOtherIndustryField(newState);
  };

  const cards = useMemo(() => {
    return CarouselCards({
      email,
      emailError,
      onEmailChange,
      accountName,
      accountNameError,
      onAccountNameChange,
      phoneNumber,
      phoneNumberError,
      onPhoneNumberChange,
      selectedIndustries,
      onSelectedIndustriesChange,
      otherIndustry,
      onOtherIndustryChange,
      isShowOtherIndustryField,
      setIsShowOtherIndustryField,
      selectedRole,
      onSelectedRoleChange,
      maxInputFieldLength,
      onIsShowOtherIndustryField,
      onClickNext
    });
  }, [
    email,
    accountName,
    phoneNumber,
    selectedIndustries,
    otherIndustry,
    selectedRole,
    isShowOtherIndustryField,
    onClickNext
  ]);

  const isNextDisabled = useMemo(() => {
    return cards[carouselIndex].isNextDisabled || isCreationRequestLoading;
  }, [cards, carouselIndex, isCreationRequestLoading]);

  const isLastCardDisplaying = !(carouselIndex < cards.length - 1);
  const isFirstCardDisplaying = !(carouselIndex > 0);

  const buttonTextDisplay = isLastCardDisplaying ? "DONE" : "NEXT";

  return (
    <FlexContainer data-testid="create-account-page">
      <VantiFullPageCard>
        {isCreationRequestSucceeded ? (
          <CreationCompletedPage accountName={accountName} emailAddress={email} />
        ) : (
          <FlexContainer>
            <CardHeader
              totalCardsCount={cards.length}
              carouselIndex={carouselIndex}
              onClickAction={onClickBack}
              description={cards[carouselIndex].description}
              isFirstCardDisplaying={isFirstCardDisplaying}
            />

            <CardSubHeader index={carouselIndex} />

            <FlexItem container justifyContent="center" dense="full">
              <FlexItem xs={12} dense="full" data-testid="create-account-cards-carousel-wrapper">
                <Carousel
                  showStatus={false}
                  showArrows={false}
                  showIndicators={false}
                  selectedItem={carouselIndex}
                  swipeable={false}
                  showThumbs={false}
                >
                  {cards.map((card, index) => (
                    <FlexItem
                      data-testid={`create-account-card-item-${index}`}
                      key={index}
                      container
                      justifyContent="center"
                      dense="full"
                    >
                      <FlexItem
                        container
                        justifyContent="center"
                        dense="full"
                        className={classes.cardText}
                        data-testid="create-account-title-section"
                      >
                        {card.text}
                      </FlexItem>

                      <FlexItem container justifyContent="center" dense="full" className={classes.cardElementText}>
                        {card.element}
                      </FlexItem>

                      <FlexItem container dense="full" className={classes.buttonContainer}>
                        <CardNextButton
                          isNextDisabled={isNextDisabled}
                          isLastCardDisplaying={isLastCardDisplaying}
                          onClickFinish={onClickFinish}
                          onClickNext={onClickNext}
                          buttonTextDisplay={buttonTextDisplay}
                          isCreationRequestLoading={isCreationRequestLoading}
                          index={carouselIndex}
                        />
                      </FlexItem>
                    </FlexItem>
                  ))}
                </Carousel>
              </FlexItem>
            </FlexItem>

            {creationProcessError && (
              <FlexItem data-testid="create-account-error-section" container justifyContent="center">
                <FlexItem>
                  <Text color={vantiColors.danger1} size={14}>
                    {creationProcessError}
                  </Text>
                </FlexItem>
              </FlexItem>
            )}
          </FlexContainer>
        )}
      </VantiFullPageCard>
    </FlexContainer>
  );
};

export default CreateAccount;
