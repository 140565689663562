import config from "common/config";
import http from "../http";
import { logger } from "utils/logger";

// Server
const host = config.serverUrl;
const baseURL = `${host}/admin/v2/accounts`;

// API

/**
 * @returns {Array<accounts>}
 */
export async function getAllAccounts() {
  const res = await http.get(`${baseURL}/`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function createAccount(
  email,
  accountName,
  phoneNumber,
  selectedIndustries,
  selectedRole,
  marketplace,
  token
) {
  const res = await http.post(
    `${host}/account/v2/`,
    {
      email,
      accountName,
      phoneNumber,
      selectedIndustries,
      selectedRole,
      marketplace,
      token
    },
    {},
    true
  );
  const result = await res.json();

  if (res.status > 200 || !result.ok) {
    throw new Error(result.message?.message ?? result.message);
  }

  return result;
}

export async function getSiteViewData(timeFrame) {
  const res = await http.get(`${host}/api/v2/accounts/site-view`, { timeFrame });
  const result = await res.json();

  if (res.status > 200 || !result.ok) {
    throw new Error(result.message);
  }

  return result;
}

export async function createUserViaMarketplace(email, encryptedAccountId, token) {
  try {
    const res = await http.post(
      `${host}/account/v2/create-user-via-marketplace`,
      {
        email,
        encryptedAccountId,
        token
      },
      {},
      true
    );

    const result = await res.json();

    if (res.status > 200 || !result.ok) {
      throw new Error(result.message);
    }

    return result;
  } catch (error) {
    logger.error(error);
  }
}
