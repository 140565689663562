const defaultFont = {
  fontFamily: '"Inter", sans-serif',
  fontWeight: "400",
  lineHeight: "1.5em"
};

const extraLightFontWeight = "200";
const lightFontWeight = "300";
const regularFontWeight = "400";
const mediumFontWeight = "500";
const regularXFontWeight = "600";
const boldFontWeight = "700";

export {
  defaultFont,
  extraLightFontWeight,
  lightFontWeight,
  regularFontWeight,
  mediumFontWeight,
  boldFontWeight,
  regularXFontWeight
};
