export const actionTypes = {
  AUTH_CHANGE_PASSWORD_CLEAR: "auth.changePassword.clear",
  AUTH_CHANGE_PASSWORD_REQUEST: "auth.changePassword.request",
  AUTH_CHANGE_PASSWORD_SUCCESS: "auth.changePassword.success",
  AUTH_CHANGE_PASSWORD_ERROR: "auth.changePassword.error",
  AUTH_CHANGE_PASSWORD_FULFILL: "auth.changePassword.fulfill"
};

export function changePasswordClearAction() {
  return { type: actionTypes.AUTH_CHANGE_PASSWORD_CLEAR, payload: {} };
}

export function changePasswordSuccessAction() {
  return { type: actionTypes.AUTH_CHANGE_PASSWORD_SUCCESS, payload: {} };
}

export function changePasswordErrorAction(error) {
  return { type: actionTypes.AUTH_CHANGE_PASSWORD_ERROR, payload: { error } };
}

export function changePasswordFulFillAction() {
  return { type: actionTypes.AUTH_CHANGE_PASSWORD_FULFILL, payload: {} };
}

export function changePasswordRequestAction(oldPassword, newPassword) {
  return { type: actionTypes.AUTH_CHANGE_PASSWORD_REQUEST, payload: { oldPassword, newPassword } };
}
