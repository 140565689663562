
import React, { memo } from "react";

const EditIcon24 = ({...rest }) => {
return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M10.9739 3.4739L13.2555 5.75549L5.58103 13.43L3.50686 13.43L3.29944 11.1484L10.9739 3.4739Z" stroke="#535D70"/>
    </svg>
  );
};

export default memo(EditIcon24);
