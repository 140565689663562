import React, { memo, useCallback, useContext, useEffect } from "react";
import { Snackbar, styled } from "@material-ui/core";
import HomePageTabs from "./HomePageTabs";
import { vantiColors } from "assets/jss/palette";
import Spacer from "components/ui/Spacer";
import HomePageHeader from "./header/HomePageHeader";
import HomePageBody from "./body/HomePageBody";
import useDashboardRequest from "common/hooks/use-dashboard-request";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { navigationSetBreadcrumbsType } from "modules/navigation/actions";
import { BREADCRUMBS_TYPES } from "common/constants/NavigationConstants";
import { useDispatch } from "react-redux";
import { Alert } from "@material-ui/lab";
import HomePageAddItemModal from "./body/add-item/HomePageAddItemModal";

const toDashboardItemModelFormat = item => ({
  _id: item.i,
  screenData: { x: item.x, y: item.y, w: item.w, h: item.h }
});

const HomePage = () => {
  const dispatch = useDispatch();
  const { dashboardContext } = useContext(AppContext);
  const {
    currentDashboard,
    dashboardSnackbar,
    setDashboardSnackbar,
    openAddItemModal,
    setOpenAddItemModal
  } = dashboardContext;
  const { updateDashboardItemsRequest, addDashboardRequest } = useDashboardRequest();

  useEffect(() => {
    dispatch(navigationSetBreadcrumbsType(BREADCRUMBS_TYPES.EMPTY));
  }, []);

  const onLayoutChange = useCallback((layout, layouts) => {}, []);

  const onDragStop = useCallback(
    (layout, event, domElement) => {
      if (currentDashboard) {
        updateDashboardItemsRequest.mutate({
          dashboardId: currentDashboard._id,
          items: layout.map(item => toDashboardItemModelFormat(item))
        });
      }
    },
    [currentDashboard, updateDashboardItemsRequest]
  );

  const addNewDashboard = useCallback(() => {
    addDashboardRequest.mutate();
  }, []);

  useEffect(() => {
    return () => {
      setOpenAddItemModal(false);
    };
  }, []);

  return (
    <HomePageContainer data-testid={"home-page-container"}>
      <HomePageTabsWrapper data-testid={"home-page-tabs-wrapper"}>
        <HomePageTabs addNewDashboardCallback={addNewDashboard} data-testid={"home-page-tabs"} />
      </HomePageTabsWrapper>
      <Spacer space={22} data-testid={"home-page-tabs-spacer"} />
      <HomePageHeader onAddItemCallback={() => setOpenAddItemModal(true)} />
      <Spacer space={32} data-testid={"home-page-header-spacer"} />
      <HomePageBody
        onLayoutChange={onLayoutChange}
        onDragStop={onDragStop}
        onAddItemCallback={() => setOpenAddItemModal(true)}
      />
      <Snackbar open={!!dashboardSnackbar} autoHideDuration={2000} onClose={() => setDashboardSnackbar(null)}>
        <Alert onClose={() => setDashboardSnackbar(null)} severity="success" sx={{ width: "100%" }}>
          {dashboardSnackbar}
        </Alert>
      </Snackbar>
      <HomePageAddItemModal openAddItemModal={openAddItemModal} setOpenAddItemModal={setOpenAddItemModal} />
    </HomePageContainer>
  );
};

const HomePageTabsWrapper = styled("div")({
  width: "100%",
  height: 33,
  boxSizing: "border-box",
  borderBottom: `1px solid ${vantiColors.gray36}`,
  display: "flex",
  flexDirection: "column"
});

const HomePageContainer = styled("div")({
  width: "100%",
  height: "100%",
  padding: 16,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column"
});

export default memo(HomePage);
