import React, { memo } from "react";
import EllipsisText from "components/ui/EllipsisText/EllipsisText";
import BodyCell from "../../table-body-rows/body-cell/BodyCell";
import Text from "components/ui/Typography/Text";
import { Box } from "@material-ui/core";
import { getFormattedDateForDisplay } from "utils/utils";
import styles from "./styles";
import { vantiColors } from "assets/jss/palette";

const StationCreatedByCell = ({ station }) => {
  const classes = styles();

  return (
    <BodyCell data-testid={`${station?.name}-created-by`} align="left">
      <Box display="flex" position="relative">
        <div className={classes.createdWrapper}>
          <div>
            <EllipsisText
              tooltipText={station.creatorDisplayName}
              color={vantiColors.gray39}
              size={16}
              weight={500}
              data-testid="creator-name"
            >
              {station.creatorDisplayName}
            </EllipsisText>
          </div>

          {!station.isDummy && (
            <Text className={classes.creatorDisplayName} body={3} data-testid="creation-time">
              <EllipsisText color={vantiColors.gray33} size={12} weight={400}>
                {getFormattedDateForDisplay(station.createdAt)}
              </EllipsisText>
            </Text>
          )}
        </div>
      </Box>
    </BodyCell>
  );
};

export default memo(StationCreatedByCell);
