import { Grid, styled } from "@material-ui/core";
import { memo } from "react";

export const WARNING_BANNER_HEIGHT = 32;

const StyledVantiStickyWarningBanner = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  width: "100%",
  height: theme.spacing(WARNING_BANNER_HEIGHT / 8),
  color: "white",
  backgroundColor: theme.palette.vantiColors.red8,
  justifyContent: "space-between"
}));

export default memo(StyledVantiStickyWarningBanner);
