import React, { memo } from "react";
import cs from "classnames";

import { TextField } from "@material-ui/core";

import { disabledFieldDefaultValue } from "pages/side-bar/data-connectors/constants";

import useStyles from "./styles";

const DataSourceTextField = ({ field, disabled, values, onChange }) => {
  const classes = useStyles();

  return (
    <TextField
      variant="outlined"
      rows={6}
      fullWidth
      multiline
      dataTestId={field.title}
      id={field.title}
      shouldIncludeSpacing={false}
      color="gray"
      formControlProps={{
        fullWidth: true,
        className: cs(classes.formControl, classes.textFieldFormControl)
      }}
      inputProps={{
        disabled: disabled,
        type: "text",
        className: classes.textField,
        placeholder: field.defaultValue,
        value: disabled ? disabledFieldDefaultValue : values[field],
        defaultValue: disabled ? disabledFieldDefaultValue : field.defaultValue,
        onChange: event => {
          onChange(event.target.value, field.key);
        }
      }}
    />
  );
};

export default memo(DataSourceTextField);
