export const actionTypes = {
  FETCH_USER_ROLES_REQUEST: "user.fetch.request",
  FETCH_USER_ROLES_SUCCESS: "user.fetch.success",
  FETCH_USER_ROLES_ERROR: "user.fetch.error",
  FETCH_USER_ROLES_FULFILL: "user.fetch.fulfill"
};

export function fetchUserRolesRequestAction() {
  return { type: actionTypes.FETCH_USER_ROLES_REQUEST };
}
