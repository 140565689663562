import config from "common/config";
import http from "services/http";
import { logger } from "utils/logger";

const dataCurationsAPI = `${config.serverUrl}/api/v2/datacurations`;

export const createNewCuration = async curation => {
  const res = await http.post(`${dataCurationsAPI}/curations`, curation);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result;
};

export const duplicateCuration = async curationId => {
  const res = await http.post(`${dataCurationsAPI}/curations/${curationId}/duplicate`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result;
};

export const getAllCurations = async () => {
  const res = await http.get(`${dataCurationsAPI}/curations`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const deleteCuration = async curationId => {
  const res = await http.delete(`${dataCurationsAPI}/curations/${curationId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result;
};

export const updateCuration = async (id, data) => {
  const res = await http.patch(`${dataCurationsAPI}/curations/${id}`, data);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const getExistingCuration = async curationId => {
  const res = await http.get(`${dataCurationsAPI}/curations/${curationId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
};

export const getPresignedUrlForCuration = async (curationId, filename, action) => {
  const res = await http.get(
    `${dataCurationsAPI}/s3-presigned-url/${curationId}?filename=${filename}&action=${action}`
  );
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
};

export const runDataCuration = async (curationId, datasetIds) => {
  logger.info("[runDataCuration] starting data curation run request");

  const res = await http.post(`${dataCurationsAPI}/run`, {
    curationId,
    datasetIds
  });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
};

export const getJobResultS3Url = async jobId => {
  const res = await http.get(`${dataCurationsAPI}/jobs/${jobId}/s3-result-url`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
};

export const mergeFiles = async (curationId, datasetsFilesLocations, code, filesNames) => {
  const res = await http.post(`${dataCurationsAPI}/merge`, {
    curationId,
    datasetsFilesLocations,
    code,
    filesNames
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
};
