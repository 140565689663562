import React, { memo, useRef } from "react";
import { ChonkyActions, FullFileBrowser } from "chonky";
import useHeaderTitles from "./use-titles";
import { ReactComponent as ErrorTriangleIcon } from "assets/icons/errorTriangle.svg";
import { Text } from "components/ui";
import { Grid } from "@material-ui/core";
import useStyles from "./styles";

const fileActions = [
  {
    ...ChonkyActions.EnableListView,
    button: null
  }
];

export const FILES_SORT_BY = {
  KEYS: {
    NAME: "name",
    SIZE: "size",
    DATE: "date"
  },
  ORDER: {
    ASC: "asc",
    DESC: "desc"
  }
};

const FileBrowser = ({ files, error, onFileAction, onSortClick, sortBy, folderChain, wrapperRef }) => {
  const classes = useStyles();

  const fileExplorerRef = useRef();

  useHeaderTitles(fileExplorerRef.current, onSortClick, sortBy);

  return (
    <>
      {files && (
        <Grid innerRef={wrapperRef} className={classes.fileBrowserWrapper}>
          <FullFileBrowser
            ref={fileExplorerRef}
            files={files}
            folderChain={folderChain}
            onFileAction={onFileAction}
            fileActions={fileActions}
            defaultFileViewActionId={ChonkyActions.EnableListView.id}
            disableDefaultFileActions
          />
        </Grid>
      )}

      {error && (
        <div className={classes.filesError}>
          <ErrorTriangleIcon />
          <Text body={2}>{error?.message || error}</Text>
        </div>
      )}
    </>
  );
};

export default memo(FileBrowser);
