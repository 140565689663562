export const actionTypes = {
  DELETE_WEB_NOTIFICATION_REQUEST: "web_notifications.delete.request",
  DELETE_WEB_NOTIFICATION_SUCCESS: "web_notifications.delete.success",
  DELETE_WEB_NOTIFICATION_ERROR: "web_notifications.delete.error",
  DELETE_WEB_NOTIFICATION_FULFILL: "web_notifications.delete.fulfill"
};

export function deleteWebNotificationRequestAction(id) {
  return { type: actionTypes.DELETE_WEB_NOTIFICATION_REQUEST, payload: { id } };
}
