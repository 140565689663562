import React, { memo, useCallback, useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, styled } from "@material-ui/core";
import ArrowDown from "assets/icons/arrow-down/ArrowDown";
import { Text } from "components/ui";
import DotsFlashingLoader from "components/ui/DotsFlashingLoader";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { vantiColors } from "assets/jss/palette";
import DatasetQuestionsList from "./DatasetQuestionsList";
import { DatasetQuestionCategory, DatasetQuestionSource } from "@vanti-analytics-org/vanti-common";
import useDatasetQuestionRequest from "common/hooks/use-dataset-question-request";
import { useMutation } from "react-query";
import { CHAT_API_QUERY_KEYS } from "../../chat/constants";
import { sendChatlessQuestion } from "services/api/chat";
import { logger } from "utils/logger";

const DATASET_QUESTION_TITLE_PREFIX_TEXT = "Generated by";

const GENERATING_SOURCE_SUFFIX = {
  [DatasetQuestionSource.Manual]: "Vanti admins",
  [DatasetQuestionSource.VantiAI]: "chat GPT"
};

const DatasetQuestionsSectionAccordion = styled(Accordion)(({ theme, accordionRootStyle }) => ({
  backgroundColor: theme.palette.vantiColors.white,
  borderRadius: 4,
  padding: "14px 16px",
  boxSizing: "border-box",
  "& .MuiAccordionSummary-content": {
    margin: 0
  },
  "&.MuiAccordion-root": {
    ...accordionRootStyle
  },
  "& .MuiAccordionSummary-root": {
    padding: 0
  },
  "& .MuiAccordionDetails-root": {
    padding: 0
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "auto",
    marginBottom: 24
  }
}));

const AccordionDetailsStyled = styled(AccordionDetails)({
  columnGap: 12,
  rowGap: 12,
  flexWrap: "wrap"
});

const NewQuestionInputStyled = styled("input")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  fontSize: 16,
  fontWeight: 400,
  border: `1px solid ${vantiColors.gray36}`,
  borderRadius: "5px",
  color: vantiColors.gray29,
  "&::placeholder": {
    color: vantiColors.lightGray16
  },
  padding: "12px 16px"
});

const NewQuestionInputWrapper = styled("div")({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  height: 43,
  gap: 16
});

const AccordionSummaryIconWrapper = styled("div")({
  marginLeft: 8
});

const DotsFlashingWrapper = styled("div")({
  margin: "0px 12px"
});

export const defaultAnswerObj = question => ({
  question: question || "This is your question",
  report_headline: "This is your report headline",
  textual_response: "This is your answer",
  chart_type: "bar",
  chart_labels: {
    x_label: "some x label",
    y_label: "some y label"
  },
  chart_data: { X: ["1", "2"], Y: ["a", "b"] }
});

const DatasetQuestionsSection = ({
  source,
  destination,
  datasetId,
  icon,
  CustomListHeaderRow,
  accordionRootStyle,
  isQuestionInputEditable = true,
  displayNewQuestionInput = true
}) => {
  const [isExpanded, setIsExpanded] = useState(null);
  const [newQuestionValue, setNewQuestionValue] = useState("");
  const [waitingForAgentResponse, setWaitingForAgentResponse] = useState(false);

  const title = useMemo(() => {
    return source === DatasetQuestionSource.Playground
      ? "Playground"
      : [DATASET_QUESTION_TITLE_PREFIX_TEXT, GENERATING_SOURCE_SUFFIX[source]].join(" ");
  }, [source]);

  const handleExpand = useCallback((event, isExpanded) => {
    if (isExpanded) {
      setIsExpanded(true);
    } else {
      // This timeout is for the animation of collapsing the row to be finished
      setTimeout(() => {
        setIsExpanded(false);
      }, 0);
    }
  }, []);

  const buttonText = useMemo(() => (source !== DatasetQuestionSource.Playground ? "Add" : "Ask"), [source]);

  const { saveOrUpdateQuestionsRequest } = useDatasetQuestionRequest();
  const saveOrUpdateQuestions = useCallback(
    async data => {
      saveOrUpdateQuestionsRequest.mutate({ datasetId, questions: [data] });
      setNewQuestionValue("");
    },
    [newQuestionValue]
  );

  const sendChatlessQuestionRequest = useMutation(
    [CHAT_API_QUERY_KEYS.SEND_CHATLESS_QUESTION],
    async () => sendChatlessQuestion(datasetId, newQuestionValue),
    {
      manual: false,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: data => {
        saveOrUpdateQuestions({
          question: newQuestionValue,
          source: DatasetQuestionSource.Playground,
          dest: DatasetQuestionSource.VantiAI,
          category: DatasetQuestionCategory.Custom,
          answer: data
        });
        setWaitingForAgentResponse(false);
      },
      onError: (error, { datasetId }) => logger.error("Failed to send chatless question", { error, datasetId })
    }
  );

  const handleAddOrAskClick = useCallback(() => {
    if (source === DatasetQuestionSource.Playground) {
      setWaitingForAgentResponse(true);
      sendChatlessQuestionRequest.mutate();
    } else {
      saveOrUpdateQuestions({
        question: newQuestionValue,
        source: DatasetQuestionSource.Manual,
        destination: DatasetQuestionSource.Manual,
        category: DatasetQuestionCategory.Custom,
        answer: defaultAnswerObj(newQuestionValue)
      });
    }
  }, [source, saveOrUpdateQuestions]);

  return (
    <DatasetQuestionsSectionAccordion onChange={handleExpand} accordionRootStyle={accordionRootStyle}>
      <AccordionSummary expandIcon={<ArrowDown />}>
        <Text size={18} weight={500} lineSize={"21.78px"}>
          {title}
        </Text>
        {icon && <AccordionSummaryIconWrapper>{icon}</AccordionSummaryIconWrapper>}
      </AccordionSummary>

      {isExpanded && (
        <AccordionDetailsStyled>
          {displayNewQuestionInput && (
            <NewQuestionInputWrapper>
              <NewQuestionInputStyled
                placeholder={"New question"}
                type={"text"}
                onChange={e => setNewQuestionValue(e.target.value)}
                onKeyDown={e => {
                  if (e.key === "Enter") handleAddOrAskClick();
                }}
                value={newQuestionValue}
                disabled={waitingForAgentResponse}
                data-testid={`new-questions-input-source-${source}`}
              />
              <GeneralVantiButton
                size={SIZES.BIG}
                variant={VARIANTS.SECONDARY}
                buttonInfo={{
                  label: waitingForAgentResponse ? (
                    <DotsFlashingWrapper>
                      <DotsFlashingLoader />
                    </DotsFlashingWrapper>
                  ) : (
                    buttonText
                  ),
                  onClick: handleAddOrAskClick,
                  disabled: !newQuestionValue.length || waitingForAgentResponse
                }}
              />
            </NewQuestionInputWrapper>
          )}
          {CustomListHeaderRow}
          <DatasetQuestionsList
            datasetId={datasetId}
            source={source}
            destination={destination}
            isQuestionInputEditable={isQuestionInputEditable}
          />
        </AccordionDetailsStyled>
      )}
    </DatasetQuestionsSectionAccordion>
  );
};

export default memo(DatasetQuestionsSection);
