import { actionTypes } from "./actions";
import { defaultSingleResourceOperationState } from "utils/modules-utils";

const defaultState = defaultSingleResourceOperationState();

export default function resendInviteReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.ACCOUNT_RESEND_INVITE_REQUEST: {
      return { ...defaultState, loading: true };
    }
    case actionTypes.ACCOUNT_RESEND_INVITE_SUCCESS: {
      return { ...state, success: true, email: action.payload };
    }
    case actionTypes.ACCOUNT_RESEND_INVITE_ERROR: {
      return { ...state, success: false, error: action.payload };
    }
    case actionTypes.ACCOUNT_RESEND_INVITE_FULFILL: {
      return { ...state, loading: false, email: null };
    }
    default:
      return state;
  }
}
