import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@material-ui/core";
import DynamicGraph, { GRAPH_TYPES_STR } from "pages/side-bar/chat/components/DynamicGraph";
import { Text } from "components/ui";
import CustomDropdown from "components/ui/Inputs/DropDown/CustomDropdown";
import { updateMessageReportChartType } from "services/api/chat";
import { logger } from "utils/logger";
import EmptyReportDataIcon from "assets/icons/chat/EmptyReportDataIcon";
import { vantiColors } from "assets/jss/palette";
import { isSufficientGraphDataForDisplay } from "./ChatMessageReport";

const ChatMessageReportGraphSectionContainerStyled = styled("div")(({ isDashboardPreview }) => ({
  width: "100%",
  height: isDashboardPreview ? "400px" : "unset",

  display: "flex",
  flexDirection: "column",
  gap: 16
}));

const DynamicGraphContainerStyled = styled("div")({
  width: "100%",
  height: "100%",
  backgroundColor: "white"
});

const DynamicGraphWrapperStyled = styled("div")(({ isDashboardPreview, dynamicWidthInPercentage = 60 }) => ({
  width: isDashboardPreview ? "95%" : `${dynamicWidthInPercentage}%`,
  height: isDashboardPreview ? "95%" : "unset",
  paddingLeft: 16,
  paddingBottom: isDashboardPreview ? "unset" : 16,
  paddingRight: isDashboardPreview ? 16 : "unset",
  overflow: "hidden"
}));

const HeaderWrapperStyled = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const CustomDropdownStyled = styled(CustomDropdown)({
  width: "fit-content",
  marginTop: 0,
  minWidth: 150
});

export const GRAPH_SECTION_TITLE_TEXT = "Data visualization";

const makeEllipsis = (maxLength, text) => {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

// Chart.register(zoomPlugin); //enable this later

const ChatMessageReportGraphSection = ({ message, displayHeaderSection = true, isDashboardPreview, isCompactView }) => {
  const { reportData } = message;
  const [chartType, setChartType] = useState("");

  const MAX_CHARS_IN_LABEL = isCompactView ? 4 : 8;

  const onChangeChartType = useCallback(
    newType => {
      updateMessageReportChartType(message?.chatId, message?._id, newType).catch(error => {
        logger.error(error);
      });
    },
    [message?.chatId, message?._id]
  );

  useEffect(() => {
    if (reportData?.chartType) {
      setChartType(reportData?.chartType?.toUpperCase());
    }
  }, [reportData?.chartType]);

  const dynamicWidthInPercentage = useMemo(() => {
    //PIE charts are scaling their height according to the width (since it is a circle)
    //so we want to decrease the width a little bit to avoid a huge chart
    return chartType.toUpperCase() === GRAPH_TYPES_STR.PIE ? 45 : 60;
  }, [chartType]);

  const defaultTicks = useMemo(
    () => ({
      //display label text as ellipsis
      callback: function(value) {
        const label = this.getLabelForValue(value).toString();
        return makeEllipsis(MAX_CHARS_IN_LABEL, label);
      }
    }),
    []
  );

  const getDisplayOptions = useCallback(() => {
    const graphType = chartType.toUpperCase();

    switch (graphType) {
      case GRAPH_TYPES_STR.SCATTER:
      case GRAPH_TYPES_STR.LINE:
        return {
          x: false,
          y: true
        };
      case GRAPH_TYPES_STR.BAR:
      case GRAPH_TYPES_STR.PIE:
      default:
        return {
          x: false,
          y: false
        };
    }
  }, [chartType]);

  const dashboardPreviewLayout = {
    padding: {
      top: -40,
      bottom: -5
    }
  };

  const defaultOptions = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: !isDashboardPreview,
      layout: isDashboardPreview ? { ...dashboardPreviewLayout } : {},
      plugins: {
        title: {
          display: true,
          text: "" //Graph title - blank
        },
        legend: {
          display: true,
          align: "end",
          labels: {
            boxWidth: 16,
            boxHeight: 16
          }
        },
        zoom: {
          zoom: {
            wheel: {
              enabled: true
            },
            mode: "xy",
            speed: 1
          },
          limits: {
            y: { min: 0, max: 1 },
            x: { min: 0, max: 1 }
          },
          pan: {
            enabled: true,
            mode: "xy",
            speed: 1
          }
        }
      },
      scales: {
        x: {
          ticks: defaultTicks,
          title: {
            display: true,
            text: reportData?.chartLabels?.x_label
          },
          grid: {
            display: getDisplayOptions().x
          }
        },
        y: {
          beginAtZero: false,
          ticks: defaultTicks,
          title: {
            display: !isCompactView,
            text: isCompactView ? makeEllipsis(20, reportData?.chartLabels?.y_label) : reportData?.chartLabels?.y_label
          },
          grid: {
            display: getDisplayOptions().y
          }
        }
      }
    }),
    [defaultTicks, reportData?.chartLabels, getDisplayOptions, isDashboardPreview, isCompactView]
  );

  const chartOptions = useMemo(() => {
    const graphType = chartType.toUpperCase();
    const customPieOptions = { ...defaultOptions };

    switch (graphType) {
      case GRAPH_TYPES_STR.SCATTER:
      case GRAPH_TYPES_STR.LINE:
      case GRAPH_TYPES_STR.BAR:
        return defaultOptions;
      case GRAPH_TYPES_STR.PIE: {
        delete customPieOptions.scales;
        return customPieOptions;
      }
    }
  }, [chartType, defaultOptions]);

  const shouldDisplayChartSelectionDropDown = useMemo(
    () => [GRAPH_TYPES_STR.BAR, GRAPH_TYPES_STR.LINE, GRAPH_TYPES_STR.COMBINED].includes(chartType.toUpperCase()),
    [chartType]
  );

  const isSufficientGraphData = useMemo(() => {
    return isSufficientGraphDataForDisplay(reportData);
  }, [{ ...reportData }]);

  return (
    <ChatMessageReportGraphSectionContainerStyled isDashboardPreview={isDashboardPreview}>
      {displayHeaderSection && (
        <HeaderWrapperStyled>
          <Text weight={600} size={16} lineSize={"20px"}>
            {GRAPH_SECTION_TITLE_TEXT}
          </Text>

          {shouldDisplayChartSelectionDropDown && (
            <CustomDropdownStyled
              id="graph-selection-dropdown"
              labelText={null}
              options={[
                {
                  name: "Bar chart",
                  id: GRAPH_TYPES_STR.BAR,
                  onClick: () => {
                    setChartType(GRAPH_TYPES_STR.BAR);
                    onChangeChartType(GRAPH_TYPES_STR.BAR.toLowerCase());
                  }
                },
                {
                  name: "Line chart",
                  id: GRAPH_TYPES_STR.LINE,
                  onClick: () => {
                    setChartType(GRAPH_TYPES_STR.LINE);
                    onChangeChartType(GRAPH_TYPES_STR.LINE.toLowerCase());
                  }
                },
                {
                  name: "Combined chart",
                  id: GRAPH_TYPES_STR.COMBINED,
                  onClick: () => {
                    setChartType(GRAPH_TYPES_STR.COMBINED);
                    onChangeChartType(GRAPH_TYPES_STR.COMBINED.toLowerCase());
                  }
                }
              ]}
              formControlProps={{ fullWidth: true }}
              labelProps={{ shrink: true }}
              inputProps={{ value: chartType }}
            />
          )}
        </HeaderWrapperStyled>
      )}

      {isSufficientGraphData ? (
        <DynamicGraphContainerStyled>
          <DynamicGraphWrapperStyled
            id={`chat-message-report-graph-section-wrapper-${message?._id}`}
            data-testid={`chat-message-report-graph-section-wrapper-${message?._id}`}
            dynamicWidthInPercentage={dynamicWidthInPercentage}
            isDashboardPreview={isDashboardPreview}
          >
            <DynamicGraph
              data-testid={`dynamic-graph-${reportData.datasetId}`}
              type={chartType.toUpperCase()}
              data={{ chartData: reportData.chartData, chartLabels: reportData.chartLabels }}
              options={chartOptions}
            />
          </DynamicGraphWrapperStyled>
        </DynamicGraphContainerStyled>
      ) : (
        <EmptyReportDataIconWrapper>
          <EmptyReportDataIcon width={160} height={160} />
        </EmptyReportDataIconWrapper>
      )}
    </ChatMessageReportGraphSectionContainerStyled>
  );
};

const EmptyReportDataIconWrapper = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white"
});

export default memo(ChatMessageReportGraphSection);
