import React, { memo, useEffect, useState } from "react";
import { Document, Page, View, Text, StyleSheet, Link, Image } from "@react-pdf/renderer";
import VantiFullLogoForPDF from "assets/icons/pdf/vanti-logos/VantiFullLogoForPDF";
import { vantiColors } from "assets/jss/palette";
import NotepadIconForPDF from "assets/icons/pdf/notepad-icon/NotepadIconForPDF";
import { getDatasetPresignedUrl } from "services/api/dataset";
import { logger } from "utils/logger";

const GENERATED_BY_VANTI_TEXT = "Generated by Vanti";
const DOWNLOAD_TEXT = "Download";
const DOWNLOAD_FULL_DATASET_TEXT_SUFFIX = "to view the full dataset";
const DATA_PREVIEW_PREFIX = "Data preview - ";
const FINAL_NUMBER_OF_RECORDS_TEXT = "Final number of records";
const COLUMNS_IN_CURATED_TABLE_TEXT = "Columns in curated table";
const DATA_VISUALIZATION_TEXT = "Data visualization";
const SUMMARY_TITLE_TEXT = "Summary";

const styles = StyleSheet.create({
  page: {
    padding: 24,
    rowGap: 24
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerLogoWrapper: {
    width: 89
  },
  headerText: {
    color: vantiColors.gray33,
    fontSize: 10
  },
  descriptionHeader: {
    fontSize: 12,
    fontWeight: "semi-bold",
    color: vantiColors.darkBlue6
  },
  descriptionText: {
    fontSize: 10,
    fontWeight: 400,
    color: vantiColors.gray39
  },
  descriptionSection: {
    backgroundColor: vantiColors.gray18,
    borderRadius: 4,
    padding: 12,
    flexDirection: "row",
    columnGap: 16
  },
  descriptionCreationText: {
    color: "#8B919D",
    fontSize: 10
  },
  descriptionTextSection: {
    rowGap: 16,
    maxWidth: "80%"
  },
  descriptionTopTextsSection: {
    rowGap: 4
  },
  dataPreviewTitleText: {
    fontSize: 12,
    fontWeight: "bold"
  },
  dataPreviewDownloadText: {
    fontSize: 10,
    fontWeight: 400,
    color: vantiColors.gray39
  },
  dataPreviewSectionInner: {
    rowGap: 4
  },
  dataPreviewSection: {
    rowGap: 16
  },
  dataPreviewFooter: {
    flexDirection: "row",
    columnGap: 12,
    alignItems: "center"
  },
  dataPreviewFooterText: {
    fontSize: 10,
    color: "#8B919D"
  },
  verticalBorder: {
    height: "100%",
    width: 1,
    backgroundColor: vantiColors.lightGray13
  },
  horizontalBorder: {
    backgroundColor: vantiColors.lightGray13,
    width: "100%",
    height: 1
  },
  dataVisualizationSection: {
    rowGap: 16,
    height: 265
  },
  graphWrapper: {
    padding: 16,
    backgroundColor: vantiColors.gray18,
    borderRadius: 4
  },
  generalTitleText: {
    fontSize: 12,
    fontWeight: "bold"
  },
  summarySection: {
    rowGap: 4
  },
  summaryText: {
    fontSize: 10,
    color: vantiColors.gray39
  }
});

const ChatMessageReportPDF = ({
  question,
  description,
  creationInfo,
  tableAsImage,
  graphAsImage,
  reportSummary,
  dataset = {}
}) => {
  const [datasetDownloadUrl, setDatasetDownloadUrl] = useState("");

  useEffect(async () => {
    try {
      const datasetDownloadUrl = await getDatasetPresignedUrl(dataset._id);
      setDatasetDownloadUrl(datasetDownloadUrl);
    } catch (error) {
      logger.error("Error trying to download dataset - ", error);
    }
  }, [dataset?._id]);

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerLogoWrapper}>
            <VantiFullLogoForPDF />
          </View>

          <Text style={styles.headerText}>{GENERATED_BY_VANTI_TEXT}</Text>
        </View>

        <View style={styles.descriptionSection}>
          <NotepadIconForPDF />

          <View style={styles.descriptionTextSection}>
            <View style={styles.descriptionTopTextsSection}>
              <Text style={styles.descriptionHeader}>{question}</Text>
              <Text style={styles.descriptionText}>{description}</Text>
            </View>

            <Text style={styles.descriptionCreationText}>{creationInfo}</Text>
          </View>
        </View>

        <View style={styles.dataPreviewSection}>
          <View style={styles.dataPreviewSectionInner}>
            <Text style={styles.dataPreviewTitleText}>
              {DATA_PREVIEW_PREFIX} {dataset.name}
            </Text>

            <Text style={styles.dataPreviewDownloadText}>
              <Link src={datasetDownloadUrl}>{DOWNLOAD_TEXT}</Link> {DOWNLOAD_FULL_DATASET_TEXT_SUFFIX}
            </Text>
          </View>

          {tableAsImage && <Image src={tableAsImage} />}

          <View style={styles.dataPreviewFooter}>
            <Text style={styles.dataPreviewFooterText}>
              {FINAL_NUMBER_OF_RECORDS_TEXT} {dataset.rowsCount}
            </Text>

            <View style={styles.verticalBorder} />

            <Text style={styles.dataPreviewFooterText}>
              {COLUMNS_IN_CURATED_TABLE_TEXT} {dataset.columnsCount}
            </Text>
          </View>
        </View>

        <View style={styles.horizontalBorder} />

        {graphAsImage && (
          <View style={styles.dataVisualizationSection}>
            <Text style={styles.generalTitleText}>{DATA_VISUALIZATION_TEXT}</Text>
            <View style={styles.graphWrapper}>
              <Image src={graphAsImage} />
            </View>
          </View>
        )}

        {graphAsImage && <View style={styles.horizontalBorder} />}

        {reportSummary && (
          <View style={styles.summarySection}>
            <Text style={styles.generalTitleText}>{SUMMARY_TITLE_TEXT}</Text>
            <Text style={styles.summaryText}>{reportSummary}</Text>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default memo(ChatMessageReportPDF);
