import React, { useEffect, useState } from "react";
import * as emailServices from "services/admin/emails";
import ReactHtmlParser from "react-html-parser";
import Handlebars from "utils/handlebars";
import Card from "components/ui/Card/Card";
import CardBody from "components/ui/Card/CardBody";
import CardHeader from "components/ui/Card/CardHeader";
import FlexContainer from "components/ui/Flex/FlexContainer";
import FlexItem from "components/ui/Flex/FlexItem";
import Text from "components/ui/Typography/Text";
import BrandedTitle from "components/ui/Typography/BrandedTitle";
import { useParams } from "react-router-dom";
import Ajv from "ajv";
import { makeStyles } from "@material-ui/core/styles";
import TextUnderlinedInput from "components/ui/Inputs/TextUnderlinedInput";
import Button from "components/ui/Buttons/Button";
import { ReactComponent as BackButtonIcon } from "../../../assets/img/back-button.svg";
import { useHistory } from "react-router-dom";

const bodyAjv = new Ajv({ allErrors: true });
const subjectAjv = new Ajv({ allErrors: true });

const styles = () => ({
  textarea: {
    width: "100%",
    height: "200px"
  },
  htmlContainer: {
    width: "100%"
  }
});

const useStyles = makeStyles(styles);

export default function EmailsSend() {
  const classes = useStyles();
  const history = useHistory();
  const { emailId } = useParams();
  const [fetchError, setFetchError] = useState(null);
  const [email, setEmail] = useState([]);
  const [constants, setConstants] = useState({});
  const [body, setBody] = useState("");
  const [bodySchema, setBodySchema] = useState({});
  const [bodyData, setBodyData] = useState("{}");
  const [subject, setSubject] = useState("");
  const [subjectSchema, setSubjectSchema] = useState({});
  const [subjectData, setSubjectData] = useState("{}");
  const [to, setTo] = useState("");
  const [bcc, setBcc] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [sendError, setSendError] = useState(null);

  const _to = to
    .split(";")
    .map(v => v.trim())
    .filter(Boolean);
  const _bcc = bcc
    .split(";")
    .map(v => v.trim())
    .filter(Boolean);

  useEffect(() => {
    emailServices
      .getEmail(emailId)
      .then(email => {
        setEmail(email);
        setConstants(email.constants || {});
        setBody(decodeURIComponent(email.body));
        setBodySchema(JSON.parse(email.bodySchema));
        setBodyData("{}");
        setSubject(decodeURIComponent(email.subject));
        setSubjectSchema(JSON.parse(email.subjectSchema));
        setSubjectData("{}");
      })
      .catch(setFetchError);
  }, [emailId]);

  const onSend = () => {
    setIsSending(true);
    setIsSuccess(null);
    emailServices
      .sendEmail(emailId, {
        to: _to,
        bcc: _bcc,
        body: JSON.parse(bodyData),
        subject: JSON.parse(subjectData)
      })
      .then(() => {
        setIsSuccess(true);
      })
      .catch(error => {
        setIsSuccess(false);
        setSendError(error);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  let _body = body;
  let _bodyErrors = [];
  try {
    const _bodyData = { ...JSON.parse(bodyData), constants };
    if (!bodyAjv.validate(bodySchema, _bodyData)) {
      _bodyErrors.push(...bodyAjv.errorsText(bodyAjv.errors, { separator: ";" }).split(";"));
    }
    _body = Handlebars.compile(body)(_bodyData);
  } catch (e) {
    _bodyErrors.push(e.message);
  }

  let _subject = subject;
  let _subjcetErrors = [];
  try {
    const _subjectData = { ...JSON.parse(subjectData), constants };
    if (!subjectAjv.validate(subjectSchema, _subjectData)) {
      _subjcetErrors.push(...subjectAjv.errorsText(subjectAjv.errors, { separator: ";" }).split(";"));
    }
    _subject = Handlebars.compile(subject)(_subjectData);
  } catch (e) {
    _subjcetErrors.push(e.message);
  }

  const sendDisabled =
    _bodyErrors.length !== 0 || _subjcetErrors.length !== 0 || (_to.length === 0 && _bcc.length === 0) || isSending;
  return (
    <FlexContainer alignItems={"center"} direction="column" key={email.id}>
      <FlexItem xs={12} sm={12} md={12} container direction="column" justifyContent="flex-start">
        <div style={{ display: "flex" }}>
          <Button transparent={true} style={{ padding: "0 10px 0 0" }} onClick={() => history.push("/admin/emails")}>
            <BackButtonIcon />
          </Button>
          <BrandedTitle text="Send Email" size="30px" />
        </div>
        <Text color="darkBlue" size="16px">
          {email.title} | {email.id}
        </Text>
      </FlexItem>
      {fetchError && (
        <FlexContainer justifyContent="center" alignItems="center">
          <FlexItem xs={12} sm={12} md={12} container direction="column">
            <Text color="danger">{fetchError.message}</Text>
          </FlexItem>
        </FlexContainer>
      )}
      <FlexItem xs={12} sm={12} md={12} container>
        <Card>
          <CardHeader bottomBorder>
            <Text color="darkBlue" size="20px" weight="bold">
              Body
            </Text>
          </CardHeader>
          <CardBody>
            <FlexContainer alignItems={"center"} key={email.id}>
              <FlexItem xs={12} sm={6} md={6} container>
                <Text color="darkBlue" size="14px" weight="bold">
                  Data:
                </Text>
                <textarea
                  className={classes.textarea}
                  value={bodyData}
                  onChange={e => {
                    setBodyData(e.target.value);
                  }}
                />
              </FlexItem>
              <FlexItem xs={12} sm={6} md={6} container>
                <Text color="darkBlue" size="14px" weight="bold">
                  Scheme:
                </Text>
                <textarea className={classes.textarea} value={JSON.stringify(bodySchema, null, 2)}></textarea>
              </FlexItem>
              <FlexItem xs={12} sm={12} md={12} container direction="column">
                <Text color="darkBlue" size="14px" weight="bold">
                  Issues:
                </Text>
                {_bodyErrors.length > 0 &&
                  _bodyErrors.map((message, i) => (
                    <Text color="danger" key={i}>
                      - {message}
                    </Text>
                  ))}
                {_bodyErrors.length === 0 && <Text color="green">All Good</Text>}
              </FlexItem>
              <FlexItem xs={12} sm={12} md={12} container>
                <Text color="darkBlue" size="14px" weight="bold">
                  Preview:
                </Text>
                <div className={classes.htmlContainer}>{ReactHtmlParser(_body)}</div>
              </FlexItem>
            </FlexContainer>
          </CardBody>
        </Card>
      </FlexItem>
      <FlexItem xs={12} sm={12} md={12} container>
        <Card>
          <CardHeader bottomBorder>
            <Text color="darkBlue" size="20px" weight="bold">
              Subject
            </Text>
          </CardHeader>
          <CardBody>
            <FlexContainer alignItems={"center"} key={email.id}>
              <FlexItem xs={12} sm={6} md={6} container>
                <Text color="darkBlue" size="14px" weight="bold">
                  Data:
                </Text>
                <textarea
                  className={classes.textarea}
                  value={subjectData}
                  onChange={e => {
                    setSubjectData(e.target.value);
                  }}
                />
              </FlexItem>
              <FlexItem xs={12} sm={6} md={6} container>
                <Text color="darkBlue" size="14px" weight="bold">
                  Scheme:
                </Text>
                <textarea className={classes.textarea} value={JSON.stringify(subjectSchema, null, 2)}></textarea>
              </FlexItem>
              <FlexItem xs={12} sm={12} md={12} container direction="column">
                <Text color="darkBlue" size="14px" weight="bold">
                  Issues:
                </Text>
                {_subjcetErrors.length > 0 &&
                  _subjcetErrors.map((message, i) => (
                    <Text color="danger" key={i}>
                      - {message}
                    </Text>
                  ))}
                {_subjcetErrors.length === 0 && <Text color="green">All Good</Text>}
              </FlexItem>
              <FlexItem xs={12} sm={12} md={12} container direction="column">
                <Text color="darkBlue" size="14px" weight="bold">
                  Preview:
                </Text>
                <div className={classes.htmlContainer}>{_subject}</div>
              </FlexItem>
            </FlexContainer>
          </CardBody>
        </Card>
      </FlexItem>
      <FlexItem xs={12} sm={12} md={12} container>
        <Card>
          <CardHeader bottomBorder>
            <Text color="darkBlue" size="20px" weight="bold">
              Send
            </Text>
          </CardHeader>
          <CardBody>
            <FlexContainer>
              <FlexItem xs={12} sm={12} md={12} container direction="column">
                <TextUnderlinedInput
                  labelText="To:"
                  id="send-email-to"
                  formControlProps={{ fullWidth: true }}
                  labelProps={{ shrink: true }}
                  inputProps={{
                    value: to,
                    onChange: e => setTo(e.target.value)
                  }}
                />
                <TextUnderlinedInput
                  labelText="Bcc:"
                  id="email-send-bcc"
                  formControlProps={{ fullWidth: true }}
                  labelProps={{ shrink: true }}
                  inputProps={{
                    value: bcc,
                    onChange: e => setBcc(e.target.value)
                  }}
                />
              </FlexItem>
              <FlexItem xs={12} sm={12} md={12} container direction="column">
                <Button color="green" disabled={sendDisabled} onClick={onSend}>
                  Send
                </Button>
                {isSuccess === true && <Text color="green">Success</Text>}
                {isSuccess === false && <Text color="danger">Error {sendError && sendError.message}</Text>}
              </FlexItem>
            </FlexContainer>
          </CardBody>
        </Card>
      </FlexItem>
    </FlexContainer>
  );
}
