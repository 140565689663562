import React, { memo, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { styled } from "@material-ui/core";
import { setChonkyDefaults } from "chonky";
import { useDispatch, useSelector } from "react-redux";
import Admin from "pages/Admin";
import Auth from "pages/Auth";
import AuthenticatedRoute from "components/Router/AuthenticatedRoute";
import CreateAccount from "pages/auth/create-account/CreateAccount";
import Dashboard from "pages/Dashboard";
import IFramePage from "pages/IFramePage";
import HeadersProvider from "components/hoc/headers-provider";
import Version from "pages/Version";
import { loadingSelector } from "modules/auth/operations/fetchAuthData/selectors";
import { emailVerifiedSelector, uidSelector } from "modules/auth/state/selectors";
import * as fullStory from "common/fullstory/fullstory";
import { PERSONAS_ENUM, ROLES_ENUM } from "common/constants/AccountConstants";
import usePermissions from "common/hooks/use-permissions";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import { ReactComponent as VantiIconGreenBeating } from "assets/img/icons/vanti-icon-green-beating-heart.svg";
import { fetchAuthDataAction } from "modules/auth/operations/fetchAuthData/actions";
import { userEmailSelector, userPermissionsSelector, userRolesLoadingSelector } from "modules/user/state/selectors";
import { BREADCRUMBS_TYPES } from "common/constants/NavigationConstants";
import config from "common/config";
import * as authService from "services/auth/auth";
import CombinedContextProviders from "common/hooks/context-hooks/CombinedContextProviders";

export const LoadingContainer = styled("div")({
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

export const MainContainer = styled("div")(() => ({
  height: "100vh",
  width: "100%",
  display: "flex",
  flexDirection: "column"
}));

function App() {
  const dispatch = useDispatch();
  const uid = useSelector(uidSelector);
  const emailVerified = useSelector(emailVerifiedSelector);
  const userPermissions = useSelector(userPermissionsSelector);
  const loading = useSelector(loadingSelector);
  const userRolesLoading = useSelector(userRolesLoadingSelector);
  const userEmail = useSelector(userEmailSelector);

  const isStreamlitAvailable = usePermissions([ROLES_ENUM.STREAMLIT_APPS]);
  const isStreamlitChatCsvAvailable = usePermissions([ROLES_ENUM.STREAMLIT_CHAT_CSV]);
  const isRathAvailable = usePermissions([ROLES_ENUM.ENABLE_RATH]);

  useEffect(async () => {
    authService.computeDeviceFingerprint();
    await authService.performTokensRefresh();

    dispatch(fetchAuthDataAction());

    setChonkyDefaults({ iconComponent: ChonkyIconFA });

    fullStory.init(userEmail);
  }, []);

  return loading || userRolesLoading ? (
    <LoadingContainer>
      <VantiIconGreenBeating />
    </LoadingContainer>
  ) : (
    <MainContainer>
      <CombinedContextProviders>
        <HeadersProvider />
        <Switch>
          <Route path="/version" component={Version} />
          <Route path="/auth" component={Auth} />
          <Route path="/create-account" component={CreateAccount} />
          <AuthenticatedRoute
            path="/dashboard"
            redirectCondition={!(uid && emailVerified)}
            redirectTo={"/auth/sign-in"}
            component={Dashboard}
          />
          <AuthenticatedRoute
            path="/admin"
            redirectCondition={!(userPermissions?.persona === PERSONAS_ENUM.SUPER_ADMIN)}
            redirectTo={"/auth/sign-in"}
            component={Admin}
          />
          <AuthenticatedRoute
            path="/demos"
            redirectCondition={!isStreamlitAvailable}
            redirectTo={"/auth/sign-in"}
            component={IFramePage}
            data={{ title: BREADCRUMBS_TYPES.DEMOS, url: config.streamlit.url }}
          />
          <AuthenticatedRoute
            path="/chat-csv"
            redirectCondition={!isStreamlitChatCsvAvailable}
            redirectTo={"/auth/sign-in"}
            component={IFramePage}
            data={{ title: BREADCRUMBS_TYPES.CHAT_CSV, url: config.streamlit.chatCsvUrl }}
          />
          <AuthenticatedRoute
            path="/dataset-analyzer"
            redirectCondition={!isRathAvailable}
            redirectTo={"/auth/sign-in"}
            component={IFramePage}
            data={{ title: BREADCRUMBS_TYPES.DATASET_ANALYZER, url: config.rath.url }}
          />
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </CombinedContextProviders>
    </MainContainer>
  );
}

export default memo(App);
