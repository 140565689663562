import React from "react";
import AzureIcon34 from "assets/icons/data-connectors/connectors-icons/AzureIcon34";
import { BASE_PAGES_LINKS } from "common/constants/NavigationConstants";
import S3Icon34 from "assets/icons/data-connectors/connectors-icons/S3Icon34";
import PostgresIcon34 from "assets/icons/data-connectors/connectors-icons/PostgresIcon34";
import BigQueryIcon34 from "assets/icons/data-connectors/connectors-icons/BigQueryIcon34";
import MariaDBIcon34 from "assets/icons/data-connectors/connectors-icons/MariaDBIcon34";
import GCPIcon34 from "assets/icons/data-connectors/connectors-icons/GCPIcon34";
import DatabricksIcon34 from "assets/icons/data-connectors/connectors-icons/DatabricksIcon34";
import MySQLIcon34 from "assets/icons/data-connectors/connectors-icons/MySQLIcon34";
import SQLServerIcon34 from "assets/icons/data-connectors/connectors-icons/SQLServerIcon34";
import MinioIcon34 from "assets/icons/data-connectors/connectors-icons/MinioIcon34";

export const CONNECTOR_TYPES = {
  DATABASE: "DATABASE",
  CLOUD_STORAGE: "CLOUD_STORAGE"
};

// SHOULD COME FROM VANTI-COMMON
export const CONNECTOR_SOURCES = {
  postgres: { name: "postgres", type: CONNECTOR_TYPES.DATABASE },
  s3: { name: "s3", type: CONNECTOR_TYPES.CLOUD_STORAGE },
  mysql: { name: "mysql", type: CONNECTOR_TYPES.DATABASE },
  mariadb: { name: "mariadb", type: CONNECTOR_TYPES.DATABASE },
  mssql: { name: "mssql", type: CONNECTOR_TYPES.DATABASE },
  azure_blob: { name: "azure_blob", type: CONNECTOR_TYPES.CLOUD_STORAGE },
  googlecloudstorage: { name: "gcp", type: CONNECTOR_TYPES.CLOUD_STORAGE },
  minio: { name: "minio", type: CONNECTOR_TYPES.CLOUD_STORAGE },
  bigquery: { name: "big_query", type: CONNECTOR_TYPES.CLOUD_STORAGE },
  databricks: { name: "data_bricks", type: CONNECTOR_TYPES.CLOUD_STORAGE },
  EMPTY: { name: null, type: null }
};

export const CONNECTORS_ICONS = {
  [CONNECTOR_SOURCES.azure_blob.name]: <AzureIcon34 />,
  [CONNECTOR_SOURCES.s3.name]: <S3Icon34 />,
  [CONNECTOR_SOURCES.postgres.name]: <PostgresIcon34 />,
  [CONNECTOR_SOURCES.bigquery.name]: <BigQueryIcon34 />,
  [CONNECTOR_SOURCES.mariadb.name]: <MariaDBIcon34 />,
  [CONNECTOR_SOURCES.googlecloudstorage.name]: <GCPIcon34 />,
  [CONNECTOR_SOURCES.databricks.name]: <DatabricksIcon34 />,
  [CONNECTOR_SOURCES.mysql.name]: <MySQLIcon34 />,
  [CONNECTOR_SOURCES.mssql.name]: <SQLServerIcon34 />,
  [CONNECTOR_SOURCES.minio.name]: <MinioIcon34 />
};

export const NEW_CONNECTOR_PAGE_LINK = `${BASE_PAGES_LINKS.DATA_CONNECTORS}/create`;

export const SOURCE_TYPES = {
  DATA_CONNECTOR: "DATA_CONNECTOR",
  LOCAL_UPLOAD: "LOCAL_UPLOAD",
  CURATION: "CURATION"
};

export const ROW_TYPES = {
  CONNECTOR: "CONNECTOR",
  UPLOADED_FILES: "UPLOADED_FILES",
  CURATED_DATASETS: "CURATED_DATASETS",
  NO_CONNECTORS: "NO_CONNECTORS"
};

export const buildConnectorForDisplay = (datasets, relatedConnector) => {
  return {
    id: relatedConnector._id,
    title: relatedConnector.title,
    source: CONNECTOR_SOURCES[relatedConnector.sourceType],
    tableNames: relatedConnector.tableNames,
    createdAt: relatedConnector.createdAt,
    datasets: datasets.map(dataset => buildDatasetForDisplay(dataset))
  };
};

export const buildDatasetForDisplay = dataset => {
  return {
    id: dataset._id,
    name: dataset.name || dataset.tableName || dataset.userQuery,
    createdAt: dataset.createdAt || dataset.startedAt,
    creatorId: dataset.creatorId || dataset.creatorId,
    status: dataset.uploadStatus || dataset.status,
    preview: dataset.preview,
    enlargedPreview: dataset.enlargedPreview,
    mediumPreview: dataset.mediumPreview,
    datasetType: dataset.datasetType,
    originalFilesize: dataset.originalFilesize,
    rowsCount: dataset.rowsCount,
    columnsCount: dataset.columnsCount,
    sourceType: dataset.sourceType,
    sourceDataConnectorId: dataset.sourceDataConnectorId,
    dateRange: dataset.dateRange,
    updatedAt: dataset.updatedAt,
    remoteFolderLastCheckedAt: dataset.remoteFolderLastCheckedAt,
    folderFilesCount: dataset.folderFilesCount,
    entryType: dataset.entryType
  };
};

export const ADD_BUTTON_TEXT = "Add";
export const WATCH_BUTTON_TEXT = "Watch";

export const ALLOWED_FILE_TYPES = ["csv", "json", "xml", "zip"];

export const DATASET_STATUSES = {
  UPLOADING: "UPLOADING",
  UPLOADED: "UPLOADED",
  FAILED: "FAILED",
  PREVIEW_ERROR: "PREVIEW_ERROR"
};
