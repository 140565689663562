import React, { memo, useContext, useEffect, useState } from "react";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import AutoAwesomeIcon24 from "assets/icons/auto-awesome/AutoAwesomeIcon";
import IndustryIcon from "assets/icons/industry-icon/IndustryIcon";
import QuestionsArrowIcon from "assets/icons/questions-arrow/QuestionsArrowIcon";
import { getPredefinedQuestionsByAccount } from "services/api/dataset";
import usePermissions from "common/hooks/use-permissions";
import { ChatButtonActionType, ROLE } from "@vanti-analytics-org/vanti-common";
import { AppContext } from "common/hooks/context-hooks/use-app-context";

const QuestionMainContainer = styled("div")(({ busy }) => ({
  display: "flex",
  width: "100%",
  gap: 12,
  padding: "16px",
  justifyContent: "center",
  opacity: busy ? 0.5 : 1
}));

const QuestionColumnCategoryContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 12,
  alignItems: "center"
}));

const CategoryTitleContainer = styled("div")(() => ({
  display: "flex",
  height: "30px",
  alignItems: "center"
}));

const Question = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  width: "340px",
  height: "120px",
  borderRadius: "4px",
  background: vantiColors.gray35,
  padding: "16px",
  alignItems: "flex-start",
  justifyContent: "space-between",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.vantiColors.lightGray4
  }
}));

const questionCategoryToTitleMap = {
  CUSTOM: "Custom suggestions",
  INDUSTRY: "Industry suggestions"
};

const ChatMessageQuestions = ({ startNewChatFunc }) => {
  const [questionsMap, setQuestionsMap] = useState({});
  const { chatContext } = useContext(AppContext);
  const { setChatInputEnabled } = chatContext;

  const canUseSuggestions = usePermissions([ROLE.USE_CHAT_SUGGESTIONS]);

  const [isBusy, setIsBusy] = useState(false);

  useEffect(async () => {
    setQuestionsMap({});
    const questionsPerCategoy = 3;
    const questions = await getPredefinedQuestionsByAccount();

    const categoryQuestionMap = {};
    for (const question of questions) {
      const key = question.category;
      if (!(key in categoryQuestionMap)) {
        categoryQuestionMap[key] = [];
      }

      // Making sure we are limiting the questions per category
      if (categoryQuestionMap[key].length < questionsPerCategoy) {
        categoryQuestionMap[key].push(question);
      }
    }
    setQuestionsMap(categoryQuestionMap);
    setChatInputEnabled(true);
  }, []);

  const getQuestion = questionObj => {
    return (
      <Question
        onClick={async () => {
          setIsBusy(true);
          if (canUseSuggestions)
            await startNewChatFunc(
              questionObj.question,
              questionObj.datasetId,
              `${ChatButtonActionType.USE_PREDEFINED_QUESTION}/${questionObj.datasetId}`
            );
        }}
      >
        <Text
          weight={"400"}
          size={16}
          style={{
            display: "-webkit-box",
            width: "100%",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
          title={questionObj.question}
        >
          {questionObj.question}
        </Text>
        {questionObj.datasetName && (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            <Text
              weight={"400"}
              size={14}
              color={vantiColors.gray39}
              title={questionObj.datasetName}
              style={{
                width: "50%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            >
              {`Dataset: ${questionObj.datasetName}`}
            </Text>
            <QuestionsArrowIcon />
          </div>
        )}
      </Question>
    );
  };

  const getCategoryQuestions = (categoryName, questionsArr) => {
    return questionsArr ? (
      <QuestionColumnCategoryContainer>
        <CategoryTitleContainer>
          <Text weight={"500"} style={{ marginRight: "8px" }}>
            {questionCategoryToTitleMap[categoryName]}
          </Text>
          {categoryName === "CUSTOM" ? <AutoAwesomeIcon24 /> : <IndustryIcon />}
        </CategoryTitleContainer>
        {questionsArr.map(obj => getQuestion(obj))}
      </QuestionColumnCategoryContainer>
    ) : null;
  };

  return (
    <QuestionMainContainer busy={isBusy}>
      {Object.keys(questionsMap).length === 0 ? null : (
        <>{Object.keys(questionCategoryToTitleMap).map(key => getCategoryQuestions(key, questionsMap[key]))}</>
      )}
    </QuestionMainContainer>
  );
};

export default memo(ChatMessageQuestions);
