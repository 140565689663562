import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)"
    },
    to: {
      transform: "rotate(360deg)"
    }
  },
  loader: {
    animationName: "$spin",
    animationDuration: "0.3s",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
    width: "16px",
    height: "16px"
  }
});
