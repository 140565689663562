export const actionTypes = {
  FETCH_WEB_NOTIFICATIONS_REQUEST: "web_notifications.fetch.request",
  FETCH_WEB_NOTIFICATIONS_SUCCESS: "web_notifications.fetch.success",
  FETCH_WEB_NOTIFICATIONS_ERROR: "web_notifications.fetch.error",
  FETCH_WEB_NOTIFICATIONS_FULFILL: "web_notifications.fetch.fulfill"
};

export function fetchWebNotificationRequestAction(applicationId) {
  return { type: actionTypes.FETCH_WEB_NOTIFICATIONS_REQUEST, payload: { applicationId } };
}
