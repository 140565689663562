export const actionTypes = {
  FETCH_AUTH_DATA_REQUEST: "fetch.auth.data.request",
  FETCH_AUTH_DATA_SUCCESS: "fetch.auth.data.success",
  FETCH_AUTH_DATA_FULFILL: "fetch.auth.data.fulfill",
  FETCH_AUTH_DATA_ERROR: "fetch.auth.data.error"
};

export function fetchAuthDataAction() {
  return { type: actionTypes.FETCH_AUTH_DATA_REQUEST };
}
