import React, { memo } from "react";
// import WidgetsOutlined from "@material-ui/icons/WidgetsOutlined";
// import Icon from "@mui/material/Icon";

const QuestionsArrow = ({ width = 23, height = 23, ...props }) => {
  // return <WidgetsOutlined style={{ width, height, color }} />;
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 70460">
        <path
          id="Vector 9"
          d="M20.8777 12.2664L1.99962 12.2664M20.8777 12.2664L15.5485 6.93721M20.8777 12.2664L15.5485 17.5956"
          stroke="#091733"
          strokeWidth="1.1"
        />
      </g>
    </svg>
  );
};

export default memo(QuestionsArrow);
