import { Box } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import classNames from "classnames";
import Text from "components/ui/Typography/Text";
import React from "react";
import styles from "./styles";

const StepData = props => {
  const { item, itemKey, isActive, lastStep } = props;

  const classes = styles();

  return (
    <Box
      data-testid={`training-${itemKey}-section`}
      className={classNames(classes.root, { [classes.active]: isActive })}
    >
      <Box className={classes.wrapper} alignItems={item.step !== lastStep.step ? "flex-end" : "center"}>
        <Box display="flex" flexDirection="column">
          {item.step !== lastStep.step && (
            <Text data-testid={`training-${itemKey}`} body={3}>
              {itemKey}
            </Text>
          )}
          <Text data-testid={`training-step-${itemKey}-title`} h={4}>
            {item.title}
          </Text>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          {item.step !== lastStep.step && (
            <Box
              data-testid={`training-${itemKey}-min-time`}
              className={classNames(classes.time, classes.label, {
                [classes.labelActive]: isActive
              })}
            >
              {item.time}&nbsp;
              <Box component="span" fontSize="12px" fontWeight="400">
                Min
              </Box>
            </Box>
          )}
          {item.step === lastStep.step && (
            <Box
              fontSize="12px"
              color={vantiColors.lightGray6}
              mr="15px"
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Box data-testid={`training-step-${itemKey}-text`}>Total Train</Box>
              <Box data-testid={`training-${itemKey}-min-time`} display="flex" alignItems="flex-start">
                <Box fontSize="14px" fontWeight="500">
                  {item.time}&nbsp;
                </Box>
                <Box component="span" fontSize="12px" fontWeight="400">
                  Min
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default StepData;
