import React, { memo, useContext, useRef, useState } from "react";
import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import SearchIcon24 from "assets/icons/search-icon/SearchIcon24";
import CloseIcon24 from "assets/icons/close-icon/CloseIcon24";
import { TreeViewContext } from "common/hooks/context-hooks/use-treeview-context";

export const TreeSearchBarContainer = styled("div")({
  border: `1px solid ${vantiColors.gray36}`,
  borderRadius: "5px",
  display: "flex",
  paddingLeft: 16,
  paddingRight: 4,
  marginBottom: 8,
  marginTop: 18,
  width: "100%",
  height: 40,
  boxSizing: "border-box"
});

export const TreeSearchBarContentWrapper = styled("div")(({}) => ({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}));

export const SearchIconAndInputWrapper = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

export const InputStyled = styled("input")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  border: "none",
  color: vantiColors.gray29,
  "&::placeholder": {
    color: vantiColors.lightGray16
  }
});

export const CloseIcon24Placeholder = styled("div")({
  width: 24
});

const MAX_TREE_SEARCH_TEXT_LENGTH = 64;
const TREE_SEARCH_PLACEHOLDER_TEXT = "Search";

const CustomTreeSearchBar = () => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { treeViewSearchValue, setTreeViewSearchValue } = useContext(TreeViewContext);

  const inputRef = useRef(null);

  return (
    <TreeSearchBarContainer data-testid={"tree-search-bar-container"}>
      <TreeSearchBarContentWrapper data-testid={"tree-search-bar-content-wrapper"}>
        <SearchIconAndInputWrapper data-testid={"search-icon-and-input-wrapper"}>
          <SearchIcon24
            stroke={treeViewSearchValue.length || isInputFocused ? vantiColors.gray29 : vantiColors.lightGray16}
            data-testid={"tree-search-bar-icon"}
          />
          <InputStyled
            ref={inputRef}
            placeholder={TREE_SEARCH_PLACEHOLDER_TEXT}
            type={"text"}
            maxLength={MAX_TREE_SEARCH_TEXT_LENGTH}
            value={treeViewSearchValue}
            onChange={e => setTreeViewSearchValue(e.target.value)}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            data-testid={"tree-search-bar-input"}
          />
        </SearchIconAndInputWrapper>
        {treeViewSearchValue.length ? (
          <CloseIcon24
            stroke={vantiColors.gray29}
            onClick={() => {
              setTreeViewSearchValue("");
              setIsInputFocused(true);
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}
            data-testid={"tree-search-bar-close-icon"}
          />
        ) : (
          <CloseIcon24Placeholder />
        )}
      </TreeSearchBarContentWrapper>
    </TreeSearchBarContainer>
  );
};

export default memo(CustomTreeSearchBar);
