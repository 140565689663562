import { createContext, useMemo, useState } from "react";

const defaultValue = {
  tags: [],
  setTags: () => {}
};

export const TagsContext = createContext({ ...defaultValue });

export const useTagsContext = () => {
  const [tags, setTags] = useState(defaultValue.tags);

  return useMemo(
    () => ({
      tags,
      setTags
    }),
    [tags]
  );
};
