import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  tabRoot: {
    minHeight: theme.spacing(5),
    minWidth: 1,
    paddingTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    "&.Mui-selected": {
      color: "black",
      borderBottom: `2px solid ${theme.palette.vantiColors.darkBlue7}`
    }
  },
  tabsIndicator: {
    display: "none"
  },
  tabsFlexContainer: {
    marginBottom: theme.spacing(1.25),
    display: "flex",
    paddingLeft: theme.spacing(2)
  }
}));

export default useStyles;
