import React, { memo } from "react";
import AlertIcon16 from "assets/icons/alert-icon/AlertIcon16";
import { vantiColors } from "assets/jss/palette";
import GeneralVantiButton, { VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import CustomDialog from "components/ui/Dialog/CustomDialog";

const CHAT_DELETE_DIALOG_TEXT = "Are you sure you want to delete this chat?";
const CANCEL_TEXT = "Cancel";
const DELETE_TEXT = "Delete";

const ChatDeleteDialog = ({ deletionTargetType, onCancel, onConfirm }) => {
  return (
    <CustomDialog
      isOpened={!!deletionTargetType}
      title={"Confirm deletion"}
      body={CHAT_DELETE_DIALOG_TEXT}
      icon={<AlertIcon16 stroke={vantiColors.rose6} fill={vantiColors.rose6} />}
      onClose={onCancel}
    >
      <GeneralVantiButton
        buttonInfo={{
          onClick: onCancel,
          label: CANCEL_TEXT
        }}
        variant={VARIANTS.SECONDARY}
        data-testid={"confirm-deletion-chat-cancel-btn"}
      />

      <GeneralVantiButton
        buttonInfo={{
          onClick: onConfirm,
          label: DELETE_TEXT
        }}
        data-testid={"confirm-deletion-chat-delete-btn"}
      />
    </CustomDialog>
  );
};

export default memo(ChatDeleteDialog);
