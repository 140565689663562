import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";

const GeneralTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.vantiColors.darkBlue1,
    boxShadow: theme.shadows[2],
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 400,
    font: "poppins"
  },
  arrow: {
    color: theme.palette.vantiColors.green1
  }
}))(Tooltip);

const PlainTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.vantiColors.gray15,
    boxShadow: "0px 2px 6px rgba(80, 103, 147, 0.15)",
    fontSize: 14,
    maxWidth: 350
  },
  arrow: {
    color: "white",
    fontSize: "26px"
  }
}))(Tooltip);

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#394253",
    fontSize: "12px",
    fontWeight: 400,
    width: "fit-content",
    padding: "10px",
    fontFamily: "Inter"
  }
}))(Tooltip);

const DarkToolTip = withStyles(theme => ({
  tooltip: {
    background: vantiColors.gray40,
    color: "white",
    padding: "10px",
    fontSize: 12,
    maxWidth: 350,
    fontFamily: "Inter"
  },
  arrow: {
    color: vantiColors.gray40
  }
}))(Tooltip);

const DarkToolTipFit = withStyles(theme => ({
  tooltip: {
    background: theme.palette.primaryColor[2],
    color: "white",
    padding: "10px",
    fontSize: 12,
    maxWidth: "fit-content",
    fontFamily: "Inter"
  },
  arrow: {
    color: theme.palette.primaryColor[2]
  }
}))(Tooltip);

const DarkToolTipArrowStart = withStyles(theme => ({
  arrow: {
    color: theme.palette.primaryColor[2],
    left: "0px !important"
  }
}))(DarkToolTipFit);

const DarkToolTipArrowEnd = withStyles(theme => ({
  arrow: {
    color: theme.palette.primaryColor[2],
    right: "0px !important",
    left: "auto !important"
  }
}))(DarkToolTipFit);

const DarkToolTipLarge = withStyles(theme => ({
  tooltip: {
    background: "linear-gradient(270deg, #212935 0%, #394253 100%);",
    color: theme.palette.vantiColors.turquoise1,
    boxShadow: theme.shadows[2],
    fontSize: 12,
    maxWidth: 750
  }
}))(Tooltip);

const LightArrowTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.vantiColors.darkBlue1,
    fontSize: "14px",
    fontWeight: 400,
    width: "fit-content",
    padding: "10px",
    fontFamily: '"Inter", sans-serif',
    boxShadow: "0px 2px 6px rgba(80, 103, 147, 0.15)"
  },
  arrow: {
    color: theme.palette.common.white,
    left: "20% !important"
  }
}))(Tooltip);

export {
  PlainTooltip,
  LightTooltip,
  DarkToolTip,
  DarkToolTipLarge,
  DarkToolTipFit,
  DarkToolTipArrowStart,
  DarkToolTipArrowEnd,
  GeneralTooltip,
  LightArrowTooltip
};
