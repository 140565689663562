import { BREADCRUMBS_TYPES } from "common/constants/NavigationConstants";
import { addCurationToState, editCuration } from "modules/data-curations/actions";
import { navigationSetBreadcrumbsType } from "modules/navigation/actions";
import { useCallback, useContext, useEffect, useMemo, useReducer, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getPresignedUrlForCuration } from "services/api/dataCuration";
import { listenToEvent, stopListeningToEvent } from "utils/socket-utils";
import { logger } from "utils/logger";

import uuid from "uuid";
import {
  CURATION_RUN_JOB_FAILED_TEXT,
  FAILED_API_REQ_STATUS,
  PYTHON_CODE_PLACEHOLDERS,
  SUCCESSFUL_API_REQ_STATUS,
  SUPPORTED_FILE_EXTENSIONS
} from "../../../utils";
import {
  newCurationSetCurationCode,
  newCurationSetMergeCode,
  newCurationSetName,
  singleCurationSetActiveFileTab,
  singleCurationSetActiveSectionIndex,
  singleCurationSetCuration,
  singleCurationSetCurationJob,
  singleCurationSetCurationRunError,
  singleCurationSetDownloadUrl,
  singleCurationSetExistingCurationError,
  singleCurationSetIsCurationLoading,
  singleCurationSetIsMergeLoading,
  singleCurationSetIsSafeToLeave,
  singleCurationSetMergeCode,
  singleCurationSetMergeError,
  singleCurationSetMergeJob,
  singleCurationSetNewCurationError,
  singleCurationSetPresignedUrls,
  singleDataCurationChangedFileAction,
  singleDataCurationRemoveFile,
  singleDataCurationSetFiles
} from "./actions";
import {
  NEW_CURATION_DATA_INITIAL_STORE,
  NEW_CURATION_DATA_REDUCER,
  USE_SINGLE_DATA_CURATION_INITIAL_STATE,
  USE_SINGLE_DATA_CURATION_REDUCER
} from "./reducers";
import { AppContext } from "../../../../../../common/hooks/context-hooks/use-app-context";

const useSingleDataCuration = ({
  createNewCurationReq,
  existingCurationReq,
  runDataCurationReq,
  mergeFilesReq,
  updateCurationReq
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { curationId } = useParams();
  const { datasetsContext } = useContext(AppContext);
  const { setContext, selectedDatasetsForCuration } = datasetsContext;
  const downloadSectionRef = useRef(null);
  const [curationData, setCurationData] = useReducer(NEW_CURATION_DATA_REDUCER, NEW_CURATION_DATA_INITIAL_STORE());
  const [singleDataCuration, setSingleDataCuration] = useReducer(
    USE_SINGLE_DATA_CURATION_REDUCER,
    USE_SINGLE_DATA_CURATION_INITIAL_STATE()
  );

  const generateOriginalFilesPreviewObject = useMemo(() => {
    const originalFilesPreviewObject = {};
    singleDataCuration.files.forEach(file => {
      if (!file.isJustForPreview && file?.preview?.body && file?.preview?.headers) {
        if (file.type === SUPPORTED_FILE_EXTENSIONS.CSV) {
          const filePreviewData = file?.preview?.body || [];
          filePreviewData.unshift(file?.preview?.headers);
          originalFilesPreviewObject[file.name] = JSON.stringify(filePreviewData);
        } else {
          originalFilesPreviewObject[file.name] = file.preview;
        }
      }
    });
    return originalFilesPreviewObject;
  }, [singleDataCuration.files.length]);

  const generateOriginalFileNamesForDB = useMemo(
    () =>
      singleDataCuration.files.map(file => {
        if (file.originalName) {
          return file.originalName;
        }
        const currentTimestamp = new Date().getTime();

        return `${file.name}-${currentTimestamp}`;
      }),
    [singleDataCuration.files.length]
  );

  const uploadDataTabs = useMemo(
    () =>
    {
      return singleDataCuration.files.map(file => {
        return {
          id: `FILE_${file?.id}`,
          name: file?.name
        };
      })}
      ,
    [singleDataCuration.files.length]
  );

  const onCurationNameSubmit = useCallback(newName => {
    setCurationData(newCurationSetName(newName));
    setSingleDataCuration(singleCurationSetIsSafeToLeave(false));
  }, []);

  const onBeforeFileChange = useCallback(
    (actionType, payload) => {
      setSingleDataCuration(
        singleDataCurationChangedFileAction({
          action: actionType,
          payload: payload
        })
      );
    },
    [existingCurationReq.data?._id, singleDataCuration.curation]
  );

  const onCurationFileAdd = async newFile => {
    const existingFile = singleDataCuration.files.find(
        file => `FILE_${file?.id}` === `FILE_${newFile?.id}`
    );
    if (!existingFile){
      setSingleDataCuration(singleDataCurationSetFiles(newFile));
      setSingleDataCuration(singleCurationSetIsSafeToLeave(false));
    }
  };

  const onCurationFileRemove = useCallback(
    fileId => {
      const updatedContext = [...selectedDatasetsForCuration];
      const datasetIndex = updatedContext.findIndex(dataset => dataset.id === fileId);
      if (datasetIndex > -1) {
        updatedContext.splice(datasetIndex, 1);
        setSingleDataCuration(singleDataCurationRemoveFile(fileId));

        setContext(prevValue => ({ ...prevValue, selectedDatasetsForCuration: updatedContext }));
      }
    },
    [selectedDatasetsForCuration, singleDataCurationRemoveFile, setContext]
  );

  const onCurationCodeUpdate = useCallback(newCode => {
    setCurationData(newCurationSetCurationCode(newCode));
    setSingleDataCuration(singleCurationSetIsSafeToLeave(false));
  }, []);

  const onMergeCodeUpdate = useCallback(newCode => {
    setSingleDataCuration(singleCurationSetMergeCode(newCode));
    setCurationData(newCurationSetMergeCode(newCode));
    setSingleDataCuration(singleCurationSetIsSafeToLeave(false));
  }, []);

  const onSectionFocus = useCallback(index => {
    setSingleDataCuration(singleCurationSetActiveSectionIndex(index));
  }, []);

  const onCurationNameBlur = useCallback(newName => {
    onCurationNameSubmit(newName);
  }, []);

  const createOrUpdateCuration = useCallback(async () => {
    let curation;

    if (!singleDataCuration.curation && !existingCurationReq.data?._id) {
      const { data: createdCuration } = await createNewCurationReq.refetch();
      setSingleDataCuration(singleCurationSetCuration(createdCuration.data));
      dispatch(addCurationToState(createdCuration.data));
      curation = createdCuration.data;
    } else {
      const { data: updatedCuration } = await updateCurationReq.refetch();
      setSingleDataCuration(singleCurationSetCuration(updatedCuration));
      curation = updatedCuration;
    }
    return curation;
  }, [singleDataCuration.curation, createNewCurationReq, updateCurationReq, existingCurationReq.data?._id]);

  const onClickRunCurate = useCallback(async () => {
    try {
      logger.info("sending curation request");
      setSingleDataCuration(singleCurationSetNewCurationError(null));
      setSingleDataCuration(singleCurationSetCurationRunError(null));

      setSingleDataCuration(singleCurationSetIsCurationLoading(true));
      setSingleDataCuration(singleCurationSetDownloadUrl(null));

      if (singleDataCuration.curation === null) {
        await createOrUpdateCuration();
      }

      await runDataCurationReq.refetch();
    } catch (error) {
      setSingleDataCuration(singleCurationSetNewCurationError(error.message || error));
    }
  }, [{ ...curationData }, singleDataCuration.curation, createOrUpdateCuration, runDataCurationReq]);

  const onMergeRunSocketNotification = useCallback(mergeJobData => {
    setSingleDataCuration(singleCurationSetIsMergeLoading(false));
    setSingleDataCuration(singleCurationSetMergeJob(mergeJobData));
    if (mergeJobData.curation) {
      dispatch(editCuration(mergeJobData.curation));
    }
  }, []);

  const onCurationRunSocketNotification = useCallback(curationJobData => {
    setSingleDataCuration(singleCurationSetIsCurationLoading(false));
    setSingleDataCuration(singleCurationSetCurationJob(curationJobData));
    if (curationJobData.curation) {
      dispatch(editCuration(curationJobData.curation));
    }
  }, []);

  const onChangeActiveFileTab = useCallback(payload => {
    setSingleDataCuration(singleCurationSetActiveFileTab(payload));
  }, []);

  const onClickDownloadCuratedFile = useCallback(async () => {
    const curationIdForPresign = singleDataCuration.curation?._id || curationId;
    const presignedCuratedFileDownloadResponse = await getPresignedUrlForCuration(
      curationIdForPresign,
      "curated.csv",
      "GET"
    );
    window.location.href = presignedCuratedFileDownloadResponse.url;
  }, [curationId, singleDataCuration.curation?._id]);

  const onClickDownloadMergedFile = useCallback(async () => {
    const curationIdForPresign = singleDataCuration.curation?._id || curationId;
    const presignedMergedFileDownloadResponse = await getPresignedUrlForCuration(
      curationIdForPresign,
      "merged.csv",
      "GET"
    );
    window.location.href = presignedMergedFileDownloadResponse.url;
  }, [curationId, singleDataCuration.curation?._id]);

  const uploadedFileFormat = useMemo(() => {
    const currentFileViewed = singleDataCuration.files.find(
      file => `FILE_${file?.id}` === singleDataCuration.activeFileTab
    );
    if (!currentFileViewed) return;

    if (currentFileViewed?.fileEntity) {
      return currentFileViewed?.fileEntity.name
        .split(".")
        .pop()
        .toLowerCase();
    } else {
      return null;
    }
  }, [...singleDataCuration.files, singleDataCuration.activeFileTab]);

  const onCrossButtonClick = useCallback(() => {
    history.push("/dashboard/data-curation");
  }, [history]);

  const isMergeJobFinishedSuccessfully = useMemo(
    () => singleDataCuration.mergeJob?.status === SUCCESSFUL_API_REQ_STATUS,
    [singleDataCuration.mergeJob?.status]
  );

  const isCurationJobFinishedSuccessfully = useMemo(
    () => singleDataCuration.curationJob?.status === SUCCESSFUL_API_REQ_STATUS,
    [singleDataCuration.curationJob?.status]
  );

  const curationSectionTitle = useMemo(
    () =>
      singleDataCuration.mergeJob?.status === SUCCESSFUL_API_REQ_STATUS || existingCurationReq.data?.status?.MERGED
        ? "Curation - your curation will be based on the output merge file"
        : "Curation",
    [singleDataCuration.mergeJob?.status, existingCurationReq.data?.status?.MERGED]
  );

  useEffect(() => {
    let existingCurationReqError = null;
    let createNewCurationReqError = null;

    if (existingCurationReq.isError) {
      existingCurationReqError = existingCurationReq.error.message;
    }

    setSingleDataCuration(singleCurationSetExistingCurationError(existingCurationReqError));

    if (createNewCurationReq.isError) {
      createNewCurationReqError = createNewCurationReq.error.message;
    }

    setSingleDataCuration(singleCurationSetNewCurationError(createNewCurationReqError));
  }, [
    existingCurationReq.isError,
    existingCurationReq.error,
    createNewCurationReq.isError,
    createNewCurationReq.error
  ]);

  useEffect(() => {
    if (downloadSectionRef.current) {
      downloadSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [downloadSectionRef.current]);

  useEffect(() => {
    dispatch(navigationSetBreadcrumbsType(BREADCRUMBS_TYPES.NEW_CURATION));

    return () => {
      dispatch(navigationSetBreadcrumbsType(BREADCRUMBS_TYPES.DATA_CURATION));
    };
  }, []);

  useEffect(() => {
    const existingReqData = existingCurationReq.data;

    if (existingReqData) {
      onCurationNameSubmit(existingReqData.name);

      if (existingReqData.status?.MERGED && !existingReqData.status?.CURATED) {
        setCurationData(newCurationSetCurationCode(PYTHON_CODE_PLACEHOLDERS.csv));
      } else {
        onCurationCodeUpdate(existingReqData.code);
      }

      onMergeCodeUpdate(existingReqData.mergeCode);
      existingReqData.fileNames.map(fileName => {
        const fileNameWithoutTimestamp = fileName.slice(0, fileName.lastIndexOf("-"));
        const fileType = fileNameWithoutTimestamp.substring(fileName.lastIndexOf(".") + 1).toUpperCase();

        onCurationFileAdd({
          id: uuid(),
          isJustForPreview: true,
          originalName: fileName,
          name: fileNameWithoutTimestamp,
          fileEntity: {
            name: fileNameWithoutTimestamp
          },
          type: fileType,
          data: existingReqData.originalFilesPreview[fileNameWithoutTimestamp]
        });
      });
      setSingleDataCuration(singleCurationSetCuration(existingReqData));
    }
  }, [existingCurationReq.data]);

  useEffect(() => {
    if (singleDataCuration.mergeJob?.status === SUCCESSFUL_API_REQ_STATUS) {
      setCurationData(newCurationSetCurationCode(PYTHON_CODE_PLACEHOLDERS.csv));
    }
  }, [singleDataCuration.mergeJob?.status, existingCurationReq.data]);

  useEffect(() => {
    const mergeEventName = `update-merge-job/${singleDataCuration.curation?._id}`;
    const curationEventName = `update-curation-job/${singleDataCuration.curation?._id}`;

    if (singleDataCuration.curation?._id) {
      listenToEvent(mergeEventName, payload => {
        onMergeRunSocketNotification(payload.resource.data);
      });

      listenToEvent(curationEventName, payload => {
        onCurationRunSocketNotification(payload.resource.data);
      });
    }

    return () => {
      stopListeningToEvent(mergeEventName);
      stopListeningToEvent(curationEventName);
    };
  }, [singleDataCuration.curation?._id]);

  useEffect(() => {
    const lastAddedFile = singleDataCuration.files[singleDataCuration.files.length - 1];
    if (lastAddedFile) {
      const lastAddedTab = uploadDataTabs.find(tab => tab.id === `FILE_${lastAddedFile?.id}`);
      setSingleDataCuration(singleCurationSetActiveFileTab(lastAddedTab.id));
    }
  }, [singleDataCuration.files, uploadDataTabs, singleCurationSetActiveFileTab]);

  useEffect(() => {
    if (singleDataCuration.mergeJob?.error || singleDataCuration.mergeJob?.status === FAILED_API_REQ_STATUS) {
      const errorMessage = singleDataCuration.mergeJob.error;
      setSingleDataCuration(singleCurationSetMergeError(errorMessage));
    }
  }, [singleDataCuration.mergeJob?.status, singleDataCuration.mergeJob?.error]);

  useEffect(() => {
    if (singleDataCuration.curationJob?.error || singleDataCuration.curationJob?.status === FAILED_API_REQ_STATUS) {
      const errorMessage = singleDataCuration.curationJob.error;
      setSingleDataCuration(singleCurationSetCurationRunError(errorMessage || CURATION_RUN_JOB_FAILED_TEXT));
    }
  }, [singleDataCuration.curationJob?.status, singleDataCuration.curationJob?.error]);

  return {
    curationData,
    setCurationData,
    singleDataCuration,
    setSingleDataCuration,
    downloadSectionRef,
    generateOriginalFilesPreviewObject,
    generateOriginalFileNamesForDB,
    uploadDataTabs,
    onCurationNameSubmit,
    onBeforeFileChange,
    onCurationFileAdd,
    onCurationFileRemove,
    onCurationCodeUpdate,
    onMergeCodeUpdate,
    onSectionFocus,
    onCurationNameBlur,
    onClickRunCurate,
    onChangeActiveFileTab,
    onClickDownloadCuratedFile,
    onClickDownloadMergedFile,
    uploadedFileFormat,
    onCrossButtonClick,
    isMergeJobFinishedSuccessfully,
    isCurationJobFinishedSuccessfully,
    curationSectionTitle
  };
};

export default useSingleDataCuration;
