export const actionTypes = {
  UPDATE_WEB_NOTIFICATION_LIST_REQUEST: "web_notification_list.update.request",
  UPDATE_WEB_NOTIFICATION_LIST_SUCCESS: "web_notification.update.success",
  UPDATE_WEB_NOTIFICATION_LIST_ERROR: "web_notification_list.update.error",
  UPDATE_WEB_NOTIFICATION_LIST_FULFILL: "web_notification_list.update.fulfill"
};

export function updateWebNotificationListRequestAction(updatedNotificationList, serverEmit = true, updateStore = true) {
  return {
    type: actionTypes.UPDATE_WEB_NOTIFICATION_LIST_REQUEST,
    payload: { updatedNotificationList, serverEmit, updateStore }
  };
}
