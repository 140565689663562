import React, { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import CheckboxInput from "components/ui/Inputs/CheckboxInput";
import Text from "components/ui/Typography/Text";
import UnderlinedInput from "components/ui/Inputs/Underlined";
import UnderlinedPasswordInput from "components/ui/Inputs/Underlined/password";

import GreenV from "assets/icons/GreenV.svg";

import useStyles from "./styles";
import { greenColor, darkBlueColor, vantiColors } from "assets/jss/vanti-dashboard";

// TODO: Create an library of controlled by react-hook-form components for easier reuse
const ProfileForm = ({ showInfo, isDisable }) => {
  const classes = useStyles();

  const { register, formState } = useFormContext();

  const [isPasswordFieldFocused, setIsPasswordFieldFocused] = useState(false);
  const [isPasswordVerificationFieldFocused, setIsPasswordVerificationFieldFocused] = useState(false);

  const passwordHelpLabel = useMemo(() => {
    if (!!formState.dirtyFields["password"] && !formState.errors["password"]?.message) {
      return {
        message: isPasswordFieldFocused && (
          <div style={{ position: "absolute" }}>
            <img src={GreenV} alt="Check" />{" "}
            <Text color={vantiColors.green4} size={12}>
              Excellent, password strength is strong
            </Text>
          </div>
        ),
        type: "success"
      };
    }
    return { message: formState.errors["password"]?.message, type: "error" };
  }, [formState, isPasswordFieldFocused]);

  const passwordVerificationHelpLabel = useMemo(() => {
    if (
      !!formState.dirtyFields["password"] &&
      !!formState.dirtyFields["passwordVerification"] &&
      !formState.errors["passwordVerification"]?.message
    ) {
      return {
        message: isPasswordVerificationFieldFocused && (
          <div style={{ position: "absolute" }}>
            <img src={GreenV} alt="Check" />
            <Text color={vantiColors.green4} size={12}>
              Awesome, passwords match
            </Text>
          </div>
        )
      };
    } else {
      return formState.errors["passwordVerification"]?.message;
    }
  }, [formState, isPasswordFieldFocused, isPasswordVerificationFieldFocused]);

  return (
    <>
      {showInfo && (
        <div className={classes.info} data-testid="one-hour-limit-text">
          <Text color={vantiColors.darkBlue1} body={2} weight="bold">
            There is a one-hour limit on the sign-up session
          </Text>
        </div>
      )}
      <section className={classes.inputsWrapper} data-testid="verify-email-form-section">
        <UnderlinedInput
          label={
            <Text weight="regular" body={1} data-testid="verify-email-form-first-name-label">
              First name
            </Text>
          }
          helpLabel={formState.errors["firstName"]}
          labelProps={{ shrink: true }}
          inputProps={{
            type: "text",
            disabled: isDisable,
            "data-testid": "verify-email-form-first-name-input",
            ...register("firstName")
          }}
        />
        <UnderlinedInput
          label="Last name"
          labelProps={{ shrink: true, "data-testid": "verify-email-form-last-name-label" }}
          helpLabel={formState.errors["lastName"]}
          inputProps={{
            disabled: isDisable,
            type: "text",
            "data-testid": "verify-email-form-last-name-input",
            ...register("lastName")
          }}
        />
        <UnderlinedPasswordInput
          label="Password"
          labelProps={{ shrink: true, "data-testid": "verify-email-form-password-label" }}
          helpLabel={{
            ...formState.errors["password"],
            message: passwordHelpLabel?.message,
            color: passwordHelpLabel?.color
          }}
          inputProps={{
            type: "password",
            autoComplete: "new-password",
            disabled: isDisable,
            "data-testid": "verify-email-form-password-input",
            ...register("password"),
            onFocus: () => {
              setIsPasswordFieldFocused(true);
            },
            onBlur: () => {
              setIsPasswordFieldFocused(false);
            }
          }}
        />

        <UnderlinedPasswordInput
          label="Password verification"
          helpLabel={{
            ...formState.errors["passwordVerification"],
            message: passwordVerificationHelpLabel?.message,
            color: passwordVerificationHelpLabel?.color
          }}
          labelProps={{ shrink: true, "data-testid": "verify-email-form-password-verification-label" }}
          inputProps={{
            type: "password",
            disabled: isDisable,
            "data-testid": "verify-email-form-password-verification-input",
            ...register("passwordVerification"),
            onFocus: () => {
              setIsPasswordVerificationFieldFocused(true);
            },
            onBlur: () => {
              setIsPasswordVerificationFieldFocused(false);
            }
          }}
        />
      </section>
      <section className={classes.checkBoxContainer} data-testid="verify-email-terms-and-privacy-section">
        <div>
          <CheckboxInput
            id="policies"
            color={vantiColors.green4}
            formControlProps={{
              className: classes.checkBox
            }}
            checkboxProps={{
              disabled: isDisable,
              "data-testid": "verify-email-terms-and-privacy-check-box",
              ...register("policies")
            }}
          />
          <Text color={vantiColors.darkBlue1} data-testid="verify-email-terms-and-privacy-text">
            I read and agree with the&nbsp;
            <a
              href="https://www.vanti.ai/terms-and-conditions/"
              target="noopener"
              data-testid="verify-email-terms-and-privacy-terms-link"
            >
              Terms of Use
            </a>
            &nbsp;and with the&nbsp;
            <a
              href="https://www.vanti.ai/privacy-policy/"
              target="noopener"
              data-testid="verify-email-terms-and-privacy-privacy-link"
            >
              Privacy Policy
            </a>
            .
          </Text>
        </div>
      </section>
    </>
  );
};

export default ProfileForm;
