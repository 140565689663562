import React, { memo, useCallback, useRef, useState } from "react";
import { styled } from "@material-ui/core";
import CaretDownIcon from "assets/icons/caret-down-icon/CaretDownIcon";
import CaretUpIcon from "assets/icons/caret-up-icon/CaretUpIcon";
import { vantiColors } from "assets/jss/palette";
import { Text } from "components/ui";
import Popup from "./Popup";
import { TextWithPopupWrapper } from "../common";

const HeaderStyled = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 8,
  zIndex: 999999
});

const StationNamesDropdown = styled("div")({
  backgroundColor: vantiColors.white,
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.15)"
});

const StationNameItem = styled(Text)({
  width: 142,
  height: 56,
  display: "flex",
  alignItems: "center",
  padding: "0px 12px",
  cursor: "pointer",
  boxSizing: "border-box",
  "&:hover": {
    backgroundColor: vantiColors.gray18
  }
});

const Input = styled("input")({
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  borderBottom: "1px solid gray",
  background: "transparent"
});

const mockStationSubEntities = ["Station 1", "Station 2", "Station 3"];

const Header = ({
  stationData = {
    name: "",
    description: "",
    childEntities: []
  },
  onNameChange,
  onDescriptionChange
}) => {
  const headerRef = useRef(null);
  const descriptionInputRef = useRef(null);

  const [isStationNamePopupOpen, setIsStationNamePopupOpen] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);

  const onClickName = useCallback(
    name => {
      onNameChange(name);
      setIsStationNamePopupOpen(false);
    },
    [onNameChange]
  );

  const onClickStationName = () => {
    setIsStationNamePopupOpen(true);
  };

  const onClosePopup = () => {
    setIsStationNamePopupOpen(false);
  };

  const onClickDescriptionText = useCallback(() => {
    setIsEditingDescription(true);
  }, []);

  const onFinishEditingDescription = useCallback(() => {
    const newDescription = descriptionInputRef.current.value;
    onDescriptionChange(newDescription);
    setIsEditingDescription(false);
  }, []);

  const onInputKeydown = e => {
    if (e.key === "Enter") {
      onFinishEditingDescription();
    }
  };

  return (
    <HeaderStyled>
      {isStationNamePopupOpen && (
        <Popup onClose={onClosePopup} ref={headerRef}>
          <StationNamesDropdown>
            {mockStationSubEntities.map(stationSubEntity => (
              <StationNameItem
                key={stationSubEntity}
                onClick={() => {
                  onClickName(stationSubEntity);
                }}
              >
                {stationSubEntity}
              </StationNameItem>
            ))}
          </StationNamesDropdown>
        </Popup>
      )}

      <TextWithPopupWrapper size={18} weight={600} ref={headerRef} onClick={onClickStationName}>
        {stationData.name} {isStationNamePopupOpen ? <CaretUpIcon /> : <CaretDownIcon />}
      </TextWithPopupWrapper>

      {isEditingDescription ? (
        <Input
          autoFocus
          defaultValue={stationData.description}
          onBlur={onFinishEditingDescription}
          ref={descriptionInputRef}
          onKeydown={onInputKeydown}
        />
      ) : (
        <Text color={vantiColors.gray39} onClick={onClickDescriptionText}>
          {stationData.description}
        </Text>
      )}
    </HeaderStyled>
  );
};

export default memo(Header);
