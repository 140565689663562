import React, { cloneElement, memo, useEffect, useRef, useState } from "react";
import { vantiColors } from "assets/jss/palette";
import { FlexItem, Text } from "components/ui";
import { IconButton, styled } from "@material-ui/core";
import { DarkToolTip } from "components/ui/tooltips/tooltip";

const CustomTreeItemLabelWrapper = styled(FlexItem)({
  flexWrap: "nowrap",
  maxWidth: "220px",
  justifyContent: "space-between",
  "& > div": {
    display: "flex",
    flexWrap: "nowrap",
    "&:first-child": {
      marginRight: "10px",
      overflow: "hidden"
    }
  }
});

const EndIconWrapper = styled(IconButton)({
  "&.MuiIconButton-root": {
    padding: 0,
    marginRight: "4px",
    "&:hover": {
      backgroundColor: vantiColors.gray36
    }
  },
  transition: "opacity 0.2s",
  pointerEvents: "none",
  opacity: 0
});

const ItemText = styled(Text)(({ isItemInEditMode, isEllipsisText, shouldHighlight }) => {
  const defaultProps = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    alignSelf: "center"
  };
  const gradColor = shouldHighlight ? vantiColors.darkBlue7 : vantiColors.gray29;
  const ellipsisTextProps = {
    backgroundImage: `linear-gradient(90deg, ${gradColor}, ${gradColor}, ${gradColor}, ${gradColor}, #ffffff00)`,
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent"
  };

  return isEllipsisText && !isItemInEditMode ? { ...defaultProps, ...ellipsisTextProps } : defaultProps;
});

const CustomTreeItemLabel = ({ node, shouldHighlight }) => {
  let textElementRef = useRef();
  const [showTooltip, setShowTooltip] = useState(false);
  const [isEllipsisText, setIsEllipsisText] = useState(false);
  const itemSize = node.fixedSize ?? node.children?.length ?? null;
  const [isHover, setIsHover] = useState(false);
  const [isItemInEditMode, setIsItemInEditMode] = useState(false);

  useEffect(() => {
    if (textElementRef?.current) {
      setIsEllipsisText(textElementRef.current.offsetWidth < textElementRef.current.scrollWidth);
    }
    //node.name is a dependency to force re-evaluating ellipsis when only name changes
  }, [node.name, textElementRef.current]);

  useEffect(() => {
    setIsItemInEditMode(typeof node.name !== "string");
  }, [node.name]);

  return (
    <DarkToolTip
      placement={"right"}
      title={node.name}
      open={showTooltip && !node.disableTooltip}
      onOpen={() => {
        if (isEllipsisText) {
          setShowTooltip(true);
        }
      }}
      onClose={() => {
        setShowTooltip(false);
      }}
      arrow
    >
      <CustomTreeItemLabelWrapper
        container
        dense={"full"}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        data-testid={`custom-tree-item-label-wrapper-${node.name}`}
      >
        <div>
          <ItemText
            ref={textElementRef}
            shouldHighlight={shouldHighlight}
            color={shouldHighlight ? vantiColors.darkBlue7 : vantiColors.gray29}
            lineSize={"17px"}
            isEllipsisText={isEllipsisText}
            isItemInEditMode={isItemInEditMode}
            data-testid={`custom-tree-item-label-item-text-${node.name}`}
          >
            {node.name}
          </ItemText>
          {itemSize !== null && !node.hideChildrenCount && <Text color={vantiColors.gray33}> ({itemSize})</Text>}
        </div>
        {isHover && (
          <div>
            {node.endIcons &&
              node.endIcons?.map((nodeIcon, i) => (
                <EndIconWrapper
                  key={`end-icon-${i}`}
                  className={"endIcon"}
                  onClick={event => {
                    event.stopPropagation();
                    nodeIcon.onClick();
                  }}
                >
                  {cloneElement(nodeIcon.icon, {
                    stroke: shouldHighlight ? vantiColors.darkBlue7 : vantiColors.gray29
                  })}
                </EndIconWrapper>
              ))}
          </div>
        )}
      </CustomTreeItemLabelWrapper>
    </DarkToolTip>
  );
};
export default memo(CustomTreeItemLabel);
