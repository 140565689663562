import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const PlusIcon16 = ({ stroke = vantiColors.darkBlue7, color = "none", ...rest }) => (
  <svg {...rest} width="16" height="17" viewBox="0 0 16 17" fill={color} xmlns="http://www.w3.org/2000/svg">
    <rect x="3.25" y="8" width="9.5" height="1" fill={stroke} />
    <rect x="8.5" y="3.75" width="9.5" height="1" transform="rotate(90 8.5 3.75)" fill={stroke} />
  </svg>
);

export default memo(PlusIcon16);
