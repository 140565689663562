import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

const useStyles = makeStyles(theme => ({
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderBottomColor: theme.palette.vantiColors.gray5 + " !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderBottomColor: theme.palette.vantiColors.gray15
    }
  },
  greenUnderline: {
    "&:hover:not($disabled):before,&:before": {
      borderBottomColor: `${theme.palette.vantiColors.green1} !important`,
      borderWidth: "1px !important"
    },
    "&:after": {
      borderBottomColor: theme.palette.vantiColors.green1
    }
  },
  underlineError: {
    "&:after": {
      borderColor: theme.palette.vantiColors.danger1
    }
  },
  underlineSuccess: {
    "&:after": {
      borderColor: theme.palette.vantiColors.success1
    }
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  labelRoot: {
    ...theme.fonts.defaultFont,
    color: `${theme.palette.vantiColors.gray4} !important`,
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    letterSpacing: "unset"
  },
  labelRootError: {
    color: theme.palette.vantiColors.danger1
  },
  labelRootSuccess: {
    color: theme.palette.vantiColors.success1
  },
  feedback: {
    position: "absolute",
    top: "18px",
    right: "0",
    zIndex: "2",
    display: "block",
    width: "24px",
    height: "24px",
    textAlign: "center",
    pointerEvents: "none"
  },
  formControl: {
    paddingBottom: theme.spacing(1.25),
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
    borderBottomColor: `${theme.palette.vantiColors.gray5} !important`
  },
  marginTop: {
    marginTop: theme.spacing(2)
  }
}));

export default function CustomSelect(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    labelProps,
    id,
    selectProps,
    inputProps,
    children,
    error,
    success,
    color,
    selectIcon
  } = props;

  const { classes: selectClasses = {}, ...restSelectProps } = selectProps || {};
  const { classes: inputClasses = {}, ...restInputProps } = inputProps || {};
  const { MenuProps } = restSelectProps || {};

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });

  const formControlClasses = classNames({
    [classes.formControl]: true,
    [formControlProps.className]: true
  });

  const underlineClasses = classNames({
    [classes.underline]: true,
    [classes[color + "Underline"]]: color
  });

  const marginTop = classNames({
    [classes.marginTop]: labelText !== undefined
  });

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        IconComponent={selectIcon}
        classes={{
          disabled: classes.disabled,
          select: underlineClasses,
          ...selectClasses
        }}
        id={id}
        input={
          <Input
            classes={{
              root: marginTop,
              underline: underlineClasses,
              ...inputClasses
            }}
            {...restInputProps}
          />
        }
        {...restSelectProps}
        MenuProps={{ MenuListProps: { "data-testid": "dropdown-section" }, ...MenuProps }}
      >
        {children}
      </Select>
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomSelect.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  selectProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.oneOf(["green"])
};
