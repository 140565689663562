import React, { memo, useRef } from "react";
import { Button, styled } from "@material-ui/core";
import RotatingLoadingIcon from "assets/icons/RotatingLoadingIcon";

const SecondaryVantiButtonDefinition = styled(Button, {
  shouldForwardProp: true,
  name: "SecondaryVantiButton",
  slot: "Root"
})(({ theme }) => ({
  backgroundColor: "transparent",
  height: 34,
  border: `1px solid ${theme.palette.vantiColors.darkBlue6}`,
  color: theme.palette.vantiColors.darkBlue6,
  borderRadius: 4,
  padding: "8px 56.5px",
  fontWeight: 500,
  lineHeight: "18px",
  fontSize: 14,
  margin: 0,
  "&:hover": {
    backgroundColor: theme.palette.vantiColors.lightGray4
  },
  "&.Mui-disabled": {
    color: theme.palette.vantiColors.gray19,
    borderColor: theme.palette.vantiColors.gray19,
    cursor: "not-allowed"
  }
}));

const SecondaryVantiButton = ({ children, loading, ...props }) => {
  const buttonRef = useRef(null);

  return (
    <SecondaryVantiButtonDefinition startIcon={loading && <RotatingLoadingIcon />} ref={buttonRef} {...props}>
      {children}
    </SecondaryVantiButtonDefinition>
  );
};

export default memo(SecondaryVantiButton);
