import { createContext, useMemo, useState } from "react";

const defaultValue = {
  dashboards: {},
  dashboardItems: {},
  selectedDashboardTab: 0,
  dashboardIdToTabIndex: [],
  currentDashboard: null,
  dashboardSnackbar: null,
  selectedItemBrowserTab: 0,
  isItemDraggingInProgress: null,
  openAddItemModal: null,
  openExpandedDashboardItem: null,
  setDashboards: () => {},
  setDashboardItems: () => {},
  setSelectedDashboardTab: () => {},
  setDashboardIdToTabIndex: () => {},
  setCurrentDashboard: () => {},
  setDashboardSnackbar: () => {},
  setSelectedItemBrowserTab: () => {},
  setIsItemDraggingInProgress: () => {},
  setOpenAddItemModal: () => {},
  setOpenExpandedDashboardItem: () => {}
};

export const DashboardContext = createContext({ ...defaultValue });

export const useDashboardContext = () => {
  const [dashboards, setDashboards] = useState(defaultValue.dashboards);
  const [dashboardItems, setDashboardItems] = useState(defaultValue.dashboardItems);
  const [selectedDashboardTab, setSelectedDashboardTab] = useState(defaultValue.selectedDashboardTab);
  const [selectedItemBrowserTab, setSelectedItemBrowserTab] = useState(defaultValue.selectedItemBrowserTab);
  const [dashboardIdToTabIndex, setDashboardIdToTabIndex] = useState(defaultValue.dashboardIdToTabIndex);
  const [currentDashboard, setCurrentDashboard] = useState(defaultValue.currentDashboard);
  const [dashboardSnackbar, setDashboardSnackbar] = useState(defaultValue.dashboardSnackbar);
  const [isItemDraggingInProgress, setIsItemDraggingInProgress] = useState(defaultValue.isItemDraggingInProgress);
  const [openAddItemModal, setOpenAddItemModal] = useState(defaultValue.openAddItemModal);
  const [openExpandedDashboardItem, setOpenExpandedDashboardItem] = useState(defaultValue.openExpandedDashboardItem);

  return useMemo(
    () => ({
      dashboards,
      setDashboards,
      dashboardItems,
      setDashboardItems,
      selectedDashboardTab,
      setSelectedDashboardTab,
      dashboardIdToTabIndex,
      setDashboardIdToTabIndex,
      currentDashboard,
      setCurrentDashboard,
      dashboardSnackbar,
      setDashboardSnackbar,
      selectedItemBrowserTab,
      setSelectedItemBrowserTab,
      isItemDraggingInProgress,
      setIsItemDraggingInProgress,
      openAddItemModal,
      setOpenAddItemModal,
      openExpandedDashboardItem,
      setOpenExpandedDashboardItem
    }),
    [
      dashboards,
      dashboardItems,
      dashboardIdToTabIndex,
      currentDashboard,
      selectedDashboardTab,
      dashboardSnackbar,
      selectedItemBrowserTab,
      isItemDraggingInProgress,
      openAddItemModal,
      openExpandedDashboardItem
    ]
  );
};
