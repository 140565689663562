import React from "react";
import { TextField } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import { FlexContainer, FlexItem, Text } from "components/ui";

import { KEY_CODES, maxInputFieldLength } from "../constants";

import styles from "./styles";

const ACCOUNT_INFO_CARD = (
  accountName,
  accountNameError,
  onAccountNameChange,
  onClickNext,
  isUserBrowsingFromMobile
) => {
  const classes = styles();

  const headerTextSize = isUserBrowsingFromMobile ? 40 : 30;

  return {
    text: (
      <FlexContainer data-testid="step1-title-container" item xs={10} md={4} dense="full">
        <Text color={vantiColors.darkBlue1} size={headerTextSize} weight="bold">
          Let&apos;s create a
        </Text>

        <Text size={headerTextSize} weight="bold" color={vantiColors.green1}>
          <Text color={vantiColors.darkBlue1} size={headerTextSize} weight="bold">
            &nbsp;new{" "}
          </Text>
          account.
        </Text>
      </FlexContainer>
    ),
    description: "Account details",
    element: (
      <FlexContainer data-testid="step1-subtitle-container" item xs={10} md={4} justifyContent="center">
        <FlexItem container dense="full" justifyContent="flex-start" className={classes.subTextContainer}>
          <FlexItem container dense="full">
            <Text data-testid="step1-subtitle-text" color={vantiColors.darkBlue1} weight="bold" size={16}>
              Ready to supercharge your industrial AI models?
            </Text>
          </FlexItem>

          <FlexItem dense="full">
            <Text data-testid="step1-description-text" color={vantiColors.darkBlue1} size={14}>
              Create your account and try it out yourself.
            </Text>
          </FlexItem>
        </FlexItem>

        <FlexItem xs={12} dense="full">
          <TextField
            label="Organization name"
            id="organization-name"
            variant="standard"
            InputLabelProps={{
              shrink: true,
              style: {
                fontFamily: "Inter",
                fontWeight: "500",
                color: vantiColors.green4
              }
            }}
            fullWidth
            autoFocus
            inputProps={{
              style: {
                fontFamily: "Inter",
                paddingLeft: "16px"
              },
              "data-testid": "create-account-organization-input",
              value: accountName,
              onChange: e => onAccountNameChange(e.target.value),
              onKeyDown: e => {
                if (e.keyCode === KEY_CODES.TAB) e.preventDefault();
                if (e.keyCode === KEY_CODES.ENTER) onClickNext();
              },
              maxLength: maxInputFieldLength,
              placeholder: "e.g. companyname"
            }}
            error={!!accountNameError}
            helperText={accountNameError}
          />
        </FlexItem>
      </FlexContainer>
    ),
    isNextDisabled: accountName.trim() === ""
  };
};

export default ACCOUNT_INFO_CARD;
