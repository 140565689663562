import React, { memo, useContext, useMemo } from "react";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { styled } from "@material-ui/core";
import DatasetQuestionsRow from "./DatasetQuestionsRow";

const QuestionsListContainer = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column"
});

const DatasetQuestionsList = ({ datasetId, source, isQuestionInputEditable }) => {
  const { datasetsContext } = useContext(AppContext);
  const { datasetQuestions } = datasetsContext;

  const datasetQuestionsList = useMemo(
    () => Object.values(datasetQuestions[datasetId] || []).filter(question => question.source === source),
    [datasetQuestions, datasetId, source]
  );

  return (
    !!datasetQuestionsList.length && (
      <QuestionsListContainer>
        {datasetQuestionsList
          .sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
          .map(q => {
            return (
              <DatasetQuestionsRow
                key={q._id.toString()}
                question={q}
                isQuestionInputEditable={isQuestionInputEditable}
              />
            );
          })}
      </QuestionsListContainer>
    )
  );
};

export default memo(DatasetQuestionsList);
