import { FlexContainer, FlexItem, Text } from "components/ui";
import React, { memo } from "react";
import { EMPTY_CHIP_TEXT, TAGS_ENUM } from "../constants";

const ChipLabel = ({ label, stationItems, type = TAGS_ENUM.PRODUCT }) => {
  const itemType = type === TAGS_ENUM.PRODUCT ? "products" : "lines";

  if (stationItems.length > 1) {
    return (
      <Text size={14}>
        {stationItems.length} {itemType}
      </Text>
    );
  }

  if (stationItems.length === 1) {
    return <Text size={14}>{stationItems[0]?.name}</Text>;
  }

  if (label) {
    return (
      <FlexContainer style={{ flexWrap: "nowrap" }} item dense="full" justifyContent="space-around" alignItems="center">
        <FlexItem dense="full">
          <Text size={14}>+</Text>
        </FlexItem>

        <FlexItem dense="full">
          <Text size={14}>{label}</Text>
        </FlexItem>
      </FlexContainer>
    );
  }

  return <Text size={14}>{EMPTY_CHIP_TEXT}</Text>;
};

export default memo(ChipLabel);
