import React, { memo } from "react";

const AutoAwesomeIcon = ({ width = 28, height = 29, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="auto_awesome">
        <path
          id="auto_awesome_2"
          d="M20.0003 10.0576L18.9907 7.7916L16.7246 6.782L18.9907 5.77237L20.0003 3.50635L21.0099 5.77237L23.2759 6.782L21.0099 7.7916L20.0003 10.0576ZM20.0003 25.4935L18.9907 23.2275L16.7246 22.2179L18.9907 21.2083L20.0003 18.9422L21.0099 21.2083L23.2759 22.2179L21.0099 23.2275L20.0003 25.4935ZM9.23102 21.0064L7.21178 16.5192L2.72461 14.4999L7.21178 12.4807L9.23102 7.99349L11.2503 12.4807L15.7375 14.4999L11.2503 16.5192L9.23102 21.0064ZM9.23102 18.1749L10.3977 15.6666L12.906 14.4999L10.3977 13.3333L9.23102 10.8249L8.06436 13.3333L5.55602 14.4999L8.06436 15.6666L9.23102 18.1749Z"
          fill="#091733"
        />
      </g>
    </svg>
  );
};

export default memo(AutoAwesomeIcon);
