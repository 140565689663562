import { NOTIFICATION_TOPICS, NOTIFICATION_TYPES } from "common/constants/NotificationConstants";
import { createSelector } from "reselect";

const webNotificationStateSelector = state => state.webNotifications.state;

// TODO: Probably best approach will be to put `hideUntil` to query of request, must be done
export const latestValidWebNotificationWarningSelector = createSelector(
  webNotificationStateSelector,
  state =>
    state.notificationsList.reduce((selectedItem, item) => {
      if (item.type === NOTIFICATION_TYPES.WARNING) {
        if (!item.hideUntil || new Date(item.hideUntil).getTime() < Date.now()) {
          if (!selectedItem || new Date(selectedItem.createdAt).getTime() < new Date(item.createdAt).getTime()) {
            selectedItem = item;
          }
        }
      }

      return selectedItem;
    }, null)
);

//get all active notifications which are relevant to the notification center
export const webNotificationCenterSelector = createSelector(
  webNotificationStateSelector,
  state => state.notificationsList.filter(item => item.topic !== NOTIFICATION_TOPICS.TRAINING)
);

export const firstWebNotificationSelector = createSelector(
  webNotificationStateSelector,
  state => state.notificationsList[0]
);

export const firstWarningWebNotificationSelector = createSelector(
  webNotificationStateSelector,
  state => state.notificationsList.find(item => item.type === NOTIFICATION_TYPES.WARNING)
);

export const warningWebNotificationSelector = createSelector(
  webNotificationStateSelector,
  state => state.notificationsList.filter(item => item.type === NOTIFICATION_TYPES.WARNING)
);

export const activeWarningWebNotificationSelector = createSelector(
  webNotificationStateSelector,
  state => state.notificationsList.filter(item => item.active && item.type === NOTIFICATION_TYPES.WARNING)
);

export const webNotificationsSelector = createSelector(
  webNotificationStateSelector,
  state => state.notificationsList
);

export const webNotificationSelector = id =>
  createSelector(
    webNotificationStateSelector,
    state => state.notificationsList.find(item => item.id === id)
  );

export const webNotificationsByBlockIdSelector = blockId =>
  createSelector(
    webNotificationStateSelector,
    state => state.notificationsList.filter(item => item.data.blockId === blockId)
  );

export const webFirstNotificationByBlockIdSelector = blockId =>
  createSelector(
    webNotificationStateSelector,
    state => state.notificationsList.find(item => item.data.blockId === blockId)
  );

export const webNotificationsByApplicationIdSelector = applicationId =>
  createSelector(
    webNotificationStateSelector,
    state => state.notificationsList.filter(item => item.data.stationId === applicationId)
  );

export const webFirstNotificationByStationIdSelector = stationId =>
  createSelector(
    webNotificationStateSelector,
    state =>
      state.notificationsList.find(
        item =>
          item.data.stationId === stationId && (!item.hideUntil || new Date(item.hideUntil).getTime() < Date.now())
      )
  );
