import { createContext, useMemo, useState } from "react";

const defaultValue = {
  isNotificationCenterOpen: false,
  selectedNotificationCenterSecondaryTabIndex: 0,
  isWarningBannerDisplayed: false,
  applicationFeedFocusNotificationId: null,
  setIsNotificationCenterOpen: () => {},
  setSelectedNotificationCenterSecondaryTabIndex: () => {},
  setIsWarningBannerDisplayed: () => {},
  setApplicationFeedFocusNotificationId: () => {}
};

export const AppNotificationsContext = createContext({ ...defaultValue });

export const useAppNotificationsContext = () => {
  const [isNotificationCenterOpen, setIsNotificationCenterOpen] = useState(defaultValue.isNotificationCenterOpen);
  const [selectedNotificationCenterSecondaryTabIndex, setSelectedNotificationCenterSecondaryTabIndex] = useState(
    defaultValue.selectedNotificationCenterSecondaryTabIndex
  );
  const [isWarningBannerDisplayed, setIsWarningBannerDisplayed] = useState(defaultValue.isWarningBannerDisplayed);
  const [applicationFeedFocusNotificationId, setApplicationFeedFocusNotificationId] = useState(
    defaultValue.applicationFeedFocusNotificationId
  );

  return useMemo(
    () => ({
      setIsNotificationCenterOpen,
      isNotificationCenterOpen,

      setSelectedNotificationCenterSecondaryTabIndex,
      selectedNotificationCenterSecondaryTabIndex,

      setIsWarningBannerDisplayed,
      isWarningBannerDisplayed,

      setApplicationFeedFocusNotificationId,
      applicationFeedFocusNotificationId
    }),
    [
      isNotificationCenterOpen,
      selectedNotificationCenterSecondaryTabIndex,
      isWarningBannerDisplayed,
      applicationFeedFocusNotificationId
    ]
  );
};
