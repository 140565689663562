import React, { memo } from "react";

const DataConnectorsIcon80 = ({ ...rest }) => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="80" height="80" rx="40" fill="#F9F9F9" />
      <path d="M17.8334 62.1666V17.8333H62.1667V62.1666H17.8334Z" stroke="#848B99" strokeWidth="2.33333" />
      <path d="M18.2225 32.2227H61.7781" stroke="#848B99" strokeWidth="2.33333" />
      <path d="M27.554 24.4446H35.3318" stroke="#848B99" strokeWidth="2.33333" />
      <path d="M18.2225 47.7773H61.7781" stroke="#848B99" strokeWidth="2.33333" />
      <path d="M27.554 40H35.3318" stroke="#848B99" strokeWidth="2.33333" />
      <path d="M27.554 55.556H35.3318" stroke="#848B99" strokeWidth="2.33333" />
    </svg>
  );
};

export default memo(DataConnectorsIcon80);
