import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@material-ui/core";
import CloseIcon16 from "assets/icons/close-icon/CloseIcon16";
import { vantiColors } from "assets/jss/palette";
import DownloadIcon24 from "assets/icons/download-icon/DownloadIcon24";
import ReportExecutiveSummarySectionHeadline from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportExecutiveSummarySection/ReportExecutiveSummarySectionHeadline";
import ReportExecutiveSummarySectionDescription from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportExecutiveSummarySection/ReportExecutiveSummarySectionDescription";
import ChatMessageReportResultSummarySection from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportResultSummarySection";
import Spacer from "components/ui/Spacer";
import usePermissions from "common/hooks/use-permissions";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import ChatMessageReportPDF from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportPDF";
import { usePDF } from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import { REPORT_PDF_NAME } from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportExecutiveSummarySection/ChatMessageReportExecutiveSummarySection";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import ChatMessageReportCreatedByTitle from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportExecutiveSummarySection/ChatMessageReportCreatedByTitle";
import { getDateFormatDayjs } from "utils/dateTime";
import ChatMessageReportGraphSection, {
  GRAPH_SECTION_TITLE_TEXT
} from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportGraphSection";
import Divider from "@material-ui/core/Divider";
import ChatMessageReportDataPreviewSection from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportDataPreviewSection/ChatMessageReportDataPreviewSection";
import { VantiScrollBarProps } from "components/ui/VantiScrollBarProps";
import { Text } from "components/ui";
import { isSufficientGraphDataForDisplay } from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReport";
import TrashIcon24 from "assets/icons/trash-icon/TrashIcon24";
import { DarkToolTip } from "components/ui/tooltips/tooltip";
import useReportRequest from "common/hooks/use-report-request";
import CustomDeleteConfirmationDialog from "pages/side-bar/data-page/components/CustomDeleteConfirmationDialog";
import { DELETE_REPORT_WARNING_TEXT } from "../../../reports/ReportsPage";
import DashboardItemPinUnpinMenu from "./DashboardItemPinUnpinMenu";
import { DashboardItemType } from "@vanti-analytics-org/vanti-common";
import { getChatMessage } from "services/api/chat";
import DashboardItemReportSkeleton from "./DashboardItemReportSkeleton";
import PinIcon24 from "assets/icons/push-pin/PinIcon24";
import ClickablePinIconWithTooltip from "./ClickablePinIconWithTooltip";

const DELETE_REPORT_BTN_TOOLTIP = "Delete report";
const DOWNLOAD_REPORT_BTN_TOOLTIP = "Download report as PDF";

const DashboardItemReportExpandedView = ({ isDeleteEnabled = true }) => {
  const isAllowedToDownloadReportPdf = usePermissions([ROLES_ENUM.DOWNLOAD_CHAT_REPORT_PDF]);
  const [tableAsImage, setTableAsImage] = useState(null);
  const [graphAsImage, setGraphAsImage] = useState(null);
  const [isRenderPDFDoc, setIsRenderPDFDoc] = useState(false);
  const { datasetsContext, dashboardContext, reportsContext } = useContext(AppContext);
  const { reports, setReportIdToDelete, reportIdToDelete } = reportsContext;
  const { datasets } = datasetsContext;
  const { openExpandedDashboardItem, setOpenExpandedDashboardItem } = dashboardContext;
  const [reportMessage, setReportMessage] = useState(null);
  const { deleteReports } = useReportRequest();
  const [pinUnpinPopupRef, setPinUnpinPopupRef] = useState(null);
  const pinUnpinRef = useRef(null);

  useEffect(async () => {
    //New dashboard widgets schema provide all data right away
    if (openExpandedDashboardItem?.metadata.reportData) {
      setReportMessage({
        _id: null,
        reportData: openExpandedDashboardItem?.metadata.reportData,
        dashboardItemId: openExpandedDashboardItem?.itemId
      });
      return;
    }

    if (openExpandedDashboardItem?.metadata?.reportId) {
      setReportMessage(await getChatMessage(openExpandedDashboardItem?.metadata?.reportId));
    }
  }, [openExpandedDashboardItem]);

  const dataset = useMemo(() => {
    if (reportMessage?.reportData?.datasetId) {
      return datasets[(reportMessage?.reportData?.datasetId)];
    }
  }, [datasets, reportMessage?.reportData?.datasetId]);

  const PDFDoc = useMemo(
    () =>
      isRenderPDFDoc && (
        <ChatMessageReportPDF
          question={reportMessage?.reportData.question}
          description={reportMessage?.reportData.description}
          creationInfo={`${reportMessage?.reportData?.userInitiatedReportDisplayName} | ${getDateFormatDayjs(
            reportMessage?.createdAt
          )}`}
          tableAsImage={tableAsImage}
          graphAsImage={graphAsImage}
          reportSummary={reportMessage?.reportData?.resultSummary}
          dataset={dataset}
        />
      ),
    [
      isRenderPDFDoc,
      dataset,
      reportMessage?.reportData.question,
      reportMessage?.reportData.description,
      reportMessage?.reportData?.userInitiatedReportDisplayName,
      reportMessage?.reportData?.resultSummary,
      reportMessage?.createdAt,
      tableAsImage,
      graphAsImage
    ]
  );

  const [PDFInstance, updatePDFInstance] = usePDF({ document: PDFDoc });

  const onClickPDFDownload = useCallback(() => {
    setIsRenderPDFDoc(true);
  }, []);

  useEffect(() => {
    if (PDFDoc) updatePDFInstance(PDFDoc);
  }, [isRenderPDFDoc]);

  useEffect(() => {
    if (isRenderPDFDoc && PDFInstance.url && !PDFInstance.loading) {
      const a = document.createElement("a");
      a.download = REPORT_PDF_NAME;
      a.href = PDFInstance.url;
      a.click();
      a.remove();
      setIsRenderPDFDoc(false);
    }
  }, [isRenderPDFDoc, PDFInstance.url, PDFInstance.loading]);

  useEffect(() => {
    const previewTableElement = document.getElementById("report-data-preview-table-wrapper");
    html2canvas(previewTableElement).then(canvas => {
      setTableAsImage(canvas.toDataURL());
    });

    const graphElement = document.getElementById(`chat-message-report-graph-section-wrapper-${reportMessage?._id}`);
    html2canvas(graphElement).then(canvas => {
      setGraphAsImage(canvas.toDataURL());
    });
  }, [document.getElementById(`chat-message-report-graph-section-wrapper-${reportMessage?._id}`)]);

  const isSufficientGraphData = useMemo(() => {
    return isSufficientGraphDataForDisplay(reportMessage?.reportData);
  }, [{ ...reportMessage?.reportData }]);

  const shouldDisplayDeletionDialog = useMemo(() => !!(reports && Object.keys(reports).length && reportIdToDelete), [
    reports,
    reportIdToDelete
  ]);

  const dashboardItem = useMemo(() => {
    if (openExpandedDashboardItem) {
      return {
        type: DashboardItemType.REPORT,
        metadata: openExpandedDashboardItem?.metadata
      };
    }
  }, [openExpandedDashboardItem]);

  return reportMessage ? (
    <DashboardItemReportExpandedViewContainer>
      <ExpandedViewHeaderWrapper data-testid={"dashboard-item-expanded-view-header-wrapper"}>
        <CloseIcon16Styled onClick={() => setOpenExpandedDashboardItem(null)} />
        <ExpandedViewHeader data-testid={"dashboard-item-expanded-view-header"}>
          <ReportTitleAndButtons data-testid={"dashboard-item-expanded-view-report-title-and-btn-group"}>
            <ExecutiveSummaryContainer>
              <div data-testid={"dashboard-item-expanded-view-executive-summary-headline-and-description"}>
                <ReportExecutiveSummarySectionHeadline
                  message={reportMessage}
                  size={18}
                  weight={500}
                  lineSize={"21.78px"}
                  data-testid={"dashboard-item-expanded-view-executive-summary-headline"}
                />
                <Spacer space={10} />
                <ReportExecutiveSummarySectionDescription
                  message={reportMessage}
                  data-testid={"dashboard-item-expanded-view-executive-summary-description"}
                />
              </div>
              <ChatMessageReportCreatedByTitle message={reportMessage} />
            </ExecutiveSummaryContainer>
            <ButtonGroup data-testid={"dashboard-item-expanded-view-executive-summary-btn-group"}>
              <ClickablePinIconWithTooltip
                ref={pinUnpinRef}
                onClick={e => {
                  setPinUnpinPopupRef({ ref: pinUnpinRef.current });
                  e.stopPropagation();
                }}
              />
              {!!pinUnpinPopupRef && (
                <DashboardItemPinUnpinMenu
                  dashboardItem={dashboardItem}
                  anchorEl={pinUnpinPopupRef}
                  setAnchorEl={setPinUnpinPopupRef}
                />
              )}
              {isAllowedToDownloadReportPdf && (
                <DarkToolTip arrow placement={"bottom-end"} title={DOWNLOAD_REPORT_BTN_TOOLTIP}>
                  <div>
                    <DownloadIcon24 stroke={vantiColors.darkBlue7} onClick={onClickPDFDownload} />
                  </div>
                </DarkToolTip>
              )}
              {isDeleteEnabled && (
                <DarkToolTip arrow placement={"bottom-end"} title={DELETE_REPORT_BTN_TOOLTIP}>
                  <div>
                    <TrashIcon24
                      stroke={vantiColors.darkBlue7}
                      onClick={() => setReportIdToDelete(reportMessage?._id)}
                    />
                  </div>
                </DarkToolTip>
              )}
            </ButtonGroup>
          </ReportTitleAndButtons>
        </ExpandedViewHeader>
      </ExpandedViewHeaderWrapper>

      <Divider />
      <ChatMessageReportDataPreviewSection
        message={reportMessage}
        displayTitle={true}
        data-testid={`dashboard-item-expanded-view-report-data-preview-section-${reportMessage?._id}`}
      />
      {reportMessage?.reportData?.base64Img ? (
        <img src={`data:image/jpeg;base64,${reportMessage?.reportData?.base64Img}`} />
      ) : (
        isSufficientGraphData && (
          <>
            <Divider />
            <DashboardItemReportExpandedViewGraphSectionWrapper>
              <Text weight={600} size={16} lineSize={"20px"}>
                {GRAPH_SECTION_TITLE_TEXT}
              </Text>
              {reportMessage?.reportData?.base64Img ? (
                <img src={`data:image/jpeg;base64,${reportMessage?.reportData?.base64Img}`} />
              ) : (
                <ChatMessageReportGraphSection
                  message={reportMessage}
                  displayHeaderSection={false}
                  data-testid={`dashboard-item-report-graph-section-${reportMessage?._id}`}
                  isDashboardPreview={true}
                />
              )}
            </DashboardItemReportExpandedViewGraphSectionWrapper>
          </>
        )
      )}

      {reportMessage?.reportData?.resultSummary?.length > 0 && (
        <>
          <Divider />
          <ChatMessageReportResultSummarySection resultSummary={reportMessage?.reportData.resultSummary} />
        </>
      )}
      <CustomDeleteConfirmationDialog
        isOpenedCondition={shouldDisplayDeletionDialog}
        entity={{ type: "report", name: reports[reportIdToDelete]?.name }}
        deletionWarningText={DELETE_REPORT_WARNING_TEXT}
        onCloseCallback={() => setReportIdToDelete(null)}
        onClickCancelCallback={() => setReportIdToDelete(null)}
        onClickDeleteCallback={() => deleteReports.mutate({ ids: [reportIdToDelete] })}
        isDisabled={false}
      />
    </DashboardItemReportExpandedViewContainer>
  ) : (
    <DashboardItemReportSkeleton />
  );
};

const DashboardItemReportExpandedViewContainer = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: 32,
  overflowY: "auto",
  paddingRight: 4, //padding from scrollbar
  ...VantiScrollBarProps
});

const ExpandedViewHeader = styled("div")({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "transparent"
});

const CloseIcon16Styled = styled(CloseIcon16)({
  alignSelf: "flex-end",
  cursor: "pointer"
});

const ReportTitleAndButtons = styled("div")({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between"
});

const ButtonGroup = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  gap: 8,
  height: "fit-content"
});

const ExecutiveSummaryContainer = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
});

const ExpandedViewHeaderWrapper = styled("div")({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: 12
});

const DashboardItemReportExpandedViewGraphSectionWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxHeight: "100%",
  height: "100%",
  gap: 32
});

const ClickableWrapper = styled("div")({
  cursor: "pointer"
});

export default memo(DashboardItemReportExpandedView);
