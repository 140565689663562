import React, { memo, useCallback, useEffect, useState } from "react";
import { FlexItem } from "components/ui";
import DataSourceCard from "./DataSourceCard";
import NumberedSection from "pages/side-bar/data-connectors/pages/single-data-connector/components/NumberedSection";
import usePermissions from "common/hooks/use-permissions";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import { styled } from "@material-ui/core";
import { NEW_CONNECTOR_SECTIONS } from "pages/side-bar/data-connectors/constants";

const CardsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  gap: "16px",
  [theme.breakpoints.up("1000")]: {
    width: "900px"
  }
}));

const DataSources = ({ dataSources, selectedDataSource, onSelectDataSource, shouldKeepSelectionAfterUnmount }) => {
  const shouldReplaceMinioWithDatabricksConnector = usePermissions([ROLES_ENUM.ViewDatabricksConnector]);
  const [sourceIndexToHide, setSourceIndexToHide] = useState(null);

  useEffect(() => {
    if (!shouldKeepSelectionAfterUnmount) {
      return () => {
        onSelectDataSource(null);
      };
    }
  }, [shouldKeepSelectionAfterUnmount]);

  const onChangeDataSource = useCallback(
    dataSource => {
      onSelectDataSource(dataSource);
    },
    [selectedDataSource, dataSources]
  );

  useEffect(() => {
    let index = null;

    if (shouldReplaceMinioWithDatabricksConnector) {
      const minioConnectorIndex = dataSources.findIndex(source => source.title === "Minio");

      if (minioConnectorIndex > -1) {
        index = minioConnectorIndex;
      }
    } else {
      const databricksConnectorIndex = dataSources.findIndex(source => source.title === "Data bricks");
      if (databricksConnectorIndex > -1) {
        index = databricksConnectorIndex;
      }
    }

    setSourceIndexToHide(index);
  }, [shouldReplaceMinioWithDatabricksConnector, dataSources]);

  return (
    <NumberedSection
      title={NEW_CONNECTOR_SECTIONS.dataSources.title}
      style={{ backgroundColor: "white", borderRadius: "4px", padding: "16px" }}
    >
      <FlexItem container dense="full">
        <FlexItem data-testid="connectors-sources-section" container dense="full">
          <CardsContainer row>
            {dataSources.map(
              (source, index) =>
                index !== sourceIndexToHide && (
                  <DataSourceCard
                    key={source.title}
                    sourceTitle={source?.title}
                    iconUrl={source?.iconUrl}
                    isSelected={source?.title === selectedDataSource?.title}
                    onClick={event => {
                      onChangeDataSource(source);
                    }}
                  />
                )
            )}
          </CardsContainer>
        </FlexItem>
      </FlexItem>
    </NumberedSection>
  );
};

export default memo(DataSources);
