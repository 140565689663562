import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { styled, Tab, Tabs } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import { DashboardItemType } from "@vanti-analytics-org/vanti-common";
import capitalize from "lodash/capitalize";
import { Text } from "components/ui";
import Divider from "@material-ui/core/Divider";
import ReportsPage from "../../../reports/ReportsPage";
import GeneralVantiButton, { VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import useDashboardRequest from "common/hooks/use-dashboard-request";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { ADD_ITEM_BTN_TEXT } from "../../header/HomePageHeader";
import SearchBar from "../../../reports/components/SearchBar";
import MetricItemsPage from "./MetricItemsPage";
import Spacer from "components/ui/Spacer";
import { FlexColumn } from "components/ui/Flex/FlexContainer";

const ADD_REPORTS_BUTTON_TEXT = "Add reports";

const HomePageItemBrowser = () => {
  const { dashboardContext, reportsContext } = useContext(AppContext);
  const { currentDashboard, selectedItemBrowserTab, setSelectedItemBrowserTab, dashboardItems } = dashboardContext;
  const { selectedTableRows, setSelectedTableRows } = reportsContext;
  const { addDashboardItemsRequest } = useDashboardRequest();
  const [addItemsRequestSent, setAddItemsRequestSent] = useState(null);

  useEffect(() => {
    setSelectedTableRows([]);
  }, []);

  const tabs = useMemo(() => {
    return Object.values(DashboardItemType)
      .filter(type => type !== DashboardItemType.WIDGET) //filter out yet unsupported "WIDGET" section
      .map((type, i) => (
        <ItemTabStyled
          label={`${capitalize(type)}s`}
          key={`item-browser-tabs-${type}`}
          disableRipple={true}
          isChosen={selectedItemBrowserTab === i}
        />
      ));
  }, [selectedItemBrowserTab]);

  const handleChange = useCallback((event, newValue) => {
    setSelectedTableRows([]);
    setSelectedItemBrowserTab(newValue);
  }, []);

  const onAddReports = useCallback(() => {
    if (currentDashboard) {
      setAddItemsRequestSent(true);
      const asDashboardItems = selectedTableRows.map(row => ({
        dashboardId: currentDashboard?._id,
        type: DashboardItemType.REPORT,
        metadata: {
          reportId: row?.original?._id
        }
      }));
      addDashboardItemsRequest.mutate({ dashboardId: currentDashboard._id, items: asDashboardItems });
    }
  }, [currentDashboard, selectedTableRows]);

  const content = useMemo(() => {
    switch (
      Object.values(DashboardItemType).filter(type => type !== DashboardItemType.WIDGET)[selectedItemBrowserTab]
    ) {
      case DashboardItemType.WIDGET:
        return null;
      case DashboardItemType.REPORT:
        return (
          <ItemsTabContentContainer>
            <ReportsTabContentReportsPageWrapper>
              <ReportsPage
                showPageTitle={false}
                usePadding={false}
                tableBorderColor={vantiColors.gray35}
                displayRowMenu={false}
                displayStatisReportUploader={false}
              />
            </ReportsTabContentReportsPageWrapper>

            <GeneralVantiButton
              variant={VARIANTS.TERTIARY}
              style={{ alignSelf: "flex-end" }}
              onClickShowLoader={{ eventFinished: false }}
              onClickDisable={{ eventFinished: false }}
              buttonInfo={{
                disabled: !selectedTableRows.length || addItemsRequestSent,
                label: ADD_REPORTS_BUTTON_TEXT,
                onClick: onAddReports
              }}
            />
          </ItemsTabContentContainer>
        );
      case DashboardItemType.METRIC:
        return (
          <ItemsTabContentContainer>
            <FlexColumn style={{ justifyContent: "flex-start" }}>
              <SearchBar
                placeholder={"Metric name, Description..."}
                onChange={() => {}}
                width={387}
                data-testid={"item-browser-metric-tab-search-bar"}
              />
              <Spacer space={16} />
              <MetricItemsPage />
            </FlexColumn>
          </ItemsTabContentContainer>
        );
    }
  }, [selectedItemBrowserTab, selectedTableRows, currentDashboard]);

  return (
    <HomePageItemBrowserContainer>
      <HomePageItemBrowserNavigationContainer>
        <HomePageItemBrowserTabsAndTitleWrapper>
          <Text size={18} weight={500} lineSize={"21.78px"}>
            {ADD_ITEM_BTN_TEXT}
          </Text>
          <HomePageItemBrowserTabsStyled
            value={selectedItemBrowserTab}
            onChange={handleChange}
            orientation={"vertical"}
            aria-label="item browser tabs"
          >
            {tabs}
          </HomePageItemBrowserTabsStyled>
        </HomePageItemBrowserTabsAndTitleWrapper>
      </HomePageItemBrowserNavigationContainer>
      <Divider orientation={"vertical"} />
      {content}
    </HomePageItemBrowserContainer>
  );
};

const HomePageItemBrowserContainer = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex"
});
const HomePageItemBrowserNavigationContainer = styled("div")({
  width: 140,
  height: "100%",
  boxSizing: "border-box",
  display: "flex"
});

const HomePageItemBrowserTabsAndTitleWrapper = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: 24,
  paddingRight: 16
});

const HomePageItemBrowserTabsStyled = styled(Tabs)({
  "& .MuiTab-wrapper": {
    color: vantiColors.darkBlue6,
    fontSize: 14,
    padding: "7.5px 6px",
    fontWeight: 400,
    lineHeight: "16.94px",
    alignItems: "baseline",
    fontFamily: "Inter"
  },
  "& .MuiTabs-indicator": {
    display: "none"
  },
  "& .MuiTabs-fixed": {
    width: 124
  },
  "& .MuiTab-root": {
    height: "100%",
    minWidth: 0,
    minHeight: 0,
    padding: 0
  }
});

const ItemTabStyled = styled(Tab)(({ isChosen }) => ({
  "& .MuiTab-wrapper": {
    backgroundColor: isChosen ? vantiColors.lightBlue10 : "transparent",
    color: isChosen ? vantiColors.darkBlue7 : vantiColors.gray40,
    padding: "8px 12px"
  },
  "&.MuiTab-textColorInherit": {
    opacity: 1
  }
}));

const ItemsTabContentContainer = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  boxSizing: "border-box",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingLeft: 24
});

const ReportsTabContentReportsPageWrapper = styled("div")({
  width: "100%",
  height: "90%",
  boxSizing: "border-box"
});

export default memo(HomePageItemBrowser);
