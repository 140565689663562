import React, { memo, useEffect, useRef, useState } from "react";
import { Text } from "components/ui";
import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import CloseIcon16 from "assets/icons/close-icon/CloseIcon16";

const colors = [vantiColors.red9, vantiColors.lightGreen1, vantiColors.lightBlue15];

const TagStyled = styled("div")(({ order, width, isPartOfGroup, isDisabled }) => ({
  width: isPartOfGroup ? width : "fit-content",
  maxWidth: "100%",
  overflow: !isPartOfGroup && "hidden",
  textOverflow: !isPartOfGroup && "ellipsis",
  whiteSpace: !isPartOfGroup && "nowrap",
  borderRadius: 20,
  padding: 8,
  zIndex: isPartOfGroup && 3 - order,
  marginLeft: isPartOfGroup && order > 0 && -(width / 2),
  backgroundColor: colors[order] || vantiColors.gray18,
  boxSizing: "border-box",
  pointerEvents: isDisabled ? "none" : "auto",
  opacity: isDisabled ? 0.5 : 1,
  position: "relative"
}));

const DeleteIconWrapper = styled("div")({
  position: "absolute",
  top: 0,
  right: 0
});

const getTrimmedText = text => {
  if (text.length > 9) return text.slice(0, 9) + "...";
  return text;
};

const Tag = ({ text, order, isPartOfGroup = false, isDisabled, onDeleteCallback }) => {
  const ref = useRef(null);
  const [refState, setRefState] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setRefState(ref.current);
    }
  }, [ref.current]);

  return (
    <TagStyled
      order={order}
      ref={ref}
      width={refState?.offsetWidth}
      isPartOfGroup={isPartOfGroup}
      isDisabled={isDisabled}
      onMouseEnter={() => onDeleteCallback && setIsHovered(true)}
      onMouseLeave={() => onDeleteCallback && setIsHovered(false)}
    >
      {onDeleteCallback && isHovered && (
        <DeleteIconWrapper
          onClick={e => {
            onDeleteCallback();
            e.stopPropagation();
          }}
        >
          <CloseIcon16 />
        </DeleteIconWrapper>
      )}
      <Text size={12}>{isPartOfGroup ? getTrimmedText(text) : text}</Text>
    </TagStyled>
  );
};

export default memo(Tag);
