import React, { memo } from "react";

const EmptyReportDataIcon = ({ width = 160, height = 160, ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.5" width="160" height="160" rx="80" fill="#F2F3FA" />
      <rect x="41.5" y="36.5" width="74" height="91" fill="#CDD0ED" />
      <rect width="74" height="91" transform="translate(45.5 32.5)" fill="white" />
      <rect x="64" y="44.5" width="37" height="13" fill="#E6E7F6" />
      <rect x="67" y="47" width="31" height="8" fill="#9AA1DB" />
      <rect x="71.5" y="68.5" width="22" height="3" fill="#CDD0ED" />
      <rect x="63" y="78.5" width="39" height="3" fill="#CDD0ED" />
      <rect x="63.5" y="88.5" width="38" height="3" fill="#CDD0ED" />
      <rect x="64.5" y="98.5" width="36" height="3" fill="#CDD0ED" />
      <rect x="68.5" y="108.5" width="28" height="3" fill="#CDD0ED" />
    </svg>
  );
};

export default memo(EmptyReportDataIcon);
