import React from "react";
import classNames from "classnames";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { blackBoxShadow, vantiColors, hexToRgb } from "assets/jss/vanti-dashboard";

const useStyles = makeStyles({
  card: {
    ...blackBoxShadow,
    color: "rgba(" + hexToRgb(vantiColors.black) + ", 0.87)",
    background: vantiColors.white,
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem",
    // boxShadow: "0 1px 4px 0 rgba(0,0,0,0.5), 2px 5px 10px 0 rgba(0,0,0,0.26)"
    boxShadow: "0 2px 6px 0 #879BC3",
    transition: "0.3s all ease-in-out"
  },
  columnDirection: {
    flexDirection: "column"
  },
  rowDirection: {
    flexDirection: "row"
  },
  disabled: {
    cursor: "not-allowed",
    opacity: 0.5,
    "& > *": {
      cursor: "not-allowed",
      pointerEvents: "none"
    }
  }
});

export default function Card({ className, children, direction, disabled, onClick, ...rest }) {
  const classes = useStyles();

  const cardClasses = classNames(classes.card, classes[direction + "Direction"], {
    [classes.disabled]: disabled,
    [className]: className !== undefined
  });

  const onCardClick = (...params) => {
    if (!disabled) {
      onClick && onClick(...params);
    }
  };

  return (
    <div className={cardClasses} onClick={onCardClick} {...rest}>
      {children}
    </div>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  direction: PropTypes.oneOf(["row", "column"])
};
