export const useDashboardUtils = () => {
  const convertMetricToDashboardItem = (metric, dashboardId) => {
    const metaDataAndRenderData = {
      reportHeadline: metric.reportHeadline,
      metrics: [{ valueStr: !isNaN(metric.textualResponse) && metric.textualResponse }],
      threshold: !isNaN(metric.textualResponse) && metric.textualResponse
    };

    return {
      type: "METRIC",
      dashboardId,
      screenData: {
        w: 1,
        h: 1,
        x: 0,
        y: 0
      },
      metadata: metaDataAndRenderData,
      renderData: metaDataAndRenderData
    };
  };

  return {
    convertMetricToDashboardItem
  };
};
