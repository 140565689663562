import React, { memo, useState } from "react";
import PlusIconBG40 from "assets/icons/plus-icon/PlusIconBG40";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import { DataEntitiesCardContainer } from "./DataEntitiesCard";
import CustomGoalIcon from "assets/icons/goal-types/CustomGoalIcon";
import { styled, TextField } from "@material-ui/core";
import useDataEntityRequest from "common/hooks/use-data-entity-request";
import PlusIconAnimatedOnHover from "../PlusIconAnimatedOnHover";

const AddDataEntityCard = ({ dataEntitiesNames }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(null);
  const [error, setError] = useState(null);
  const [helperText, setHelperText] = useState(null);
  const [newEntityName, setNewEntityName] = useState("");
  const { addDataEntityRequest } = useDataEntityRequest();

  const onAddEntity = () => {
    addDataEntityRequest.mutate({ name: newEntityName });
    setIsHovered(false);
    setIsInEditMode(false);
  };

  const onChange = event => {
    const name = event.target.value;
    setNewEntityName(name);
    if (name) {
      if (name.length > name.trim().length) {
        setError(true);
        setHelperText("Entity name can not include spaces");
      } else if (dataEntitiesNames.some(e => e.toLowerCase() === name.toLowerCase())) {
        setError(true);
        setHelperText("Entity name must be unique");
      } else {
        setError(false);
        setHelperText(null);
      }
    }
  };

  return (
    <DataEntitiesCardContainerStyled
      onClick={() => setIsInEditMode(true)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isInEditMode={isInEditMode}
      isHovered={isHovered}
      onBlur={onAddEntity}
    >
      {isInEditMode ? (
        <>
          <CustomGoalIcon />
          <TextFieldStyled
            variant={"standard"}
            type={"text"}
            placeholder={"Entity_Name"}
            error={error}
            value={newEntityName}
            helperText={helperText}
            onChange={onChange}
            onKeyDown={e => {
              if (e.key === "Enter") {
                onAddEntity();
                e.preventDefault();
              }
            }}
          />
        </>
      ) : (
        <>
          <PlusIconAnimatedOnHover isHovered={isHovered} data-testid={"add-new-flow-plus-icon"} />
          <Text color={isHovered ? vantiColors.darkBlue7 : vantiColors.darkBlue6}>{"Add New Entity"}</Text>
          <Text style={{ opacity: 0 }}>{"invisible text"}</Text>
        </>
      )}
    </DataEntitiesCardContainerStyled>
  );
};

export const DataEntitiesCardContainerStyled = styled(DataEntitiesCardContainer)(({ isInEditMode, isHovered }) => ({
  justifyContent: isInEditMode ? "flex-start" : "space-between",
  "&:hover": {
    backgroundColor: "white"
  },
  border: `1px solid ${isInEditMode || !isHovered ? "white" : vantiColors.darkBlue7}`,
  transition: "all ease-in-out 0.3s"
}));

export const TextFieldStyled = styled(TextField)({
  "& .MuiInput-underline": {
    "&:after": {
      border: `1px solid ${vantiColors.gray36}`
    }
  },
  "& .MuiInputBase-input": {
    textAlign: "center"
  }
});

export default memo(AddDataEntityCard);
