import React, { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import SemanticInfoSection, { SemanticInfoTextArea } from "pages/dashboard/settings/SemanticInfoSection";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { vantiColors } from "assets/jss/palette";
import UploadCloudIcon from "assets/icons/upload-icon/UploadCloudIcon";
import { DarkToolTip } from "components/ui/tooltips/tooltip";
import { ChatMessageReportDataPreviewTable } from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportDataPreviewSection/ChatMessageReportDataPreviewTable";
import { VantiScrollBarProps } from "components/ui/VantiScrollBarProps";
import DataEntityAssociations from "./DataEntityAssociations";
import { FlexColumn, FlexRow } from "components/ui/Flex/FlexContainer";
import useDataEntityRequest from "common/hooks/use-data-entity-request";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import CopyToClipboardIcon24 from "assets/icons/copy-to-clipboard/CopyToClipboardIcon24";
import Spacer from "components/ui/Spacer";
import ActivitySection from "./activity-section/ActivitySection";
import { runDataEntity } from "services/api/data-entity";
import usePermissions from "common/hooks/use-permissions";
import { ROLE } from "@vanti-analytics-org/vanti-common";
import { ROLES_ENUM } from "common/constants/AccountConstants";

const ASSOCIATED_DATA_TITLE_TXT = "Associated Tables";
const CURATION_PROCESS_INFO_TITLE_TXT = "Curation Process Information";
const CURATION_SCRIPT_TITLE_TXT = "Curation Script";
const CURATION_TABLES_TITLE_TXT = "Curated Tables";
const ACTIVITY_INFO_TITLE_TXT = "Activity";
const ADD_DATA_BTN_LABEL_TEXT = "Add data";
const MAX_CURATION_CONTEXT_CHARS = 4096;
const UPLOAD_SCRIPT_TOOLTIP_TXT = "Upload curation script";
const CURATION_SCRIPT_RUN_BTN_LABEL_TXT = "Run Only";
const SAVE_DATA_ENTITY_BTN_LABEL_TXT = "Run & Save";

const commonBodyChildStyle = {
  backgroundColor: "white",
  padding: 16
};

export const CommonTitleText = ({ children }) => (
  <Text size={18} weight={600} lineSize={"normal"}>
    {children}
  </Text>
);

const SingleDataEntityBody = ({ entity, setDisplayOtherDatasets, setDisplayDataDeletionDialogId }) => {
  const [response, setResponse] = useState(true);
  const { updateDataEntityRequest } = useDataEntityRequest();
  const { datasetsContext } = useContext(AppContext);
  const { getTable } = useDataEntityRequest();
  const { datasets } = datasetsContext;

  const inputFile = useRef(null);

  const [pageNumber, setPageNumber] = useState(0);
  const [newCurationCode, setNewCurationCode] = useState(entity.curationCode ? atob(entity.curationCode) : "");
  const [isReachedMaximumRows, setIsReachedMaximumRows] = useState(false);
  const [lastCurationScriptRunError, setLastCurationScriptRunError] = useState(entity?.lastRunError);

  const [tablePreview, setTablePreview] = useState(null);

  const canSeeDebugInformation = usePermissions([ROLES_ENUM.DEBUG_DATA_ENTITIES]);

  const ingestedSchemaId = useMemo(() => {
    return entity.curatedTableId;
  }, [entity]);

  useEffect(() => {
    if (entity && entity.previewRows && entity.previewRows.length) {
      setTablePreview({
        headers: entity.previewRows[0],
        body: entity.previewRows.slice(1)
      });
    }
  }, []);

  const onUploadedCurationScript = event => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      setLastCurationScriptRunError(null);
      const fileReader = new FileReader();
      fileReader.onloadend = reader => {
        setNewCurationCode(reader.currentTarget.result);
      };
      fileReader.readAsText(file);
    }
    event.target.value = ""; //reset file
  };

  const runEntity = async dry => {
    if (!dry) {
      updateDataEntityRequest.mutate({
        dataEntityId: entity._id,
        updateFields: {
          curationCode: btoa(newCurationCode)
        }
      });
    }

    await runDataEntity(entity._id, dry, btoa(newCurationCode));
  };

  useEffect(() => {
    if (entity?.lastRunAt) {
      //reset the button loader
      setResponse(true);
    }

    if (entity && entity.lastRunSuccessfull) {
      setTablePreview({
        headers: entity.previewRows[0],
        body: entity.previewRows.slice(1)
      });
    } else if (entity && !entity.lastRunSuccessfull) {
      setLastCurationScriptRunError(entity?.lastRunError);
    }
  }, [entity?.lastRunAt]);

  useEffect(() => {
    if (ingestedSchemaId) {
      getTable.mutate({ tableId: ingestedSchemaId, itemsPerPage: 10, desiredPageNumber: pageNumber });
    }
  }, [ingestedSchemaId, pageNumber]);

  useEffect(() => {
    if (getTable.data) {
      if (getTable.data?.rows?.length && getTable.data?.fields && !isReachedMaximumRows && !getTable.isLoading) {
        setTablePreview(prevValue => {
          const tableHeaders = [...(prevValue?.headers || [])];
          const tableBody = [...(prevValue?.body || [])];

          for (const row of getTable.data.rows) {
            const fixedRow = [];
            for (const header of tableHeaders) {
              // TODO: Using headers from mongodb to make sure we are rendering the row in the correct order
              // TODO: Using toLowerCase, because in mongo we save the original column names, and cockroach is saving it as lower case
              fixedRow.push(row[header.toLowerCase()] || "");
            }
            tableBody.push(fixedRow);
          }

          return { headers: tableHeaders, body: tableBody };
        });

        setIsReachedMaximumRows(false);
      } else {
        setIsReachedMaximumRows(true);
      }
    }
  }, [getTable.data, getTable.isLoading, isReachedMaximumRows]);

  const isIngestionInfoUpToDate = useMemo(() => {
    if (!entity.lastIngestionAt) return false;
    return new Date(entity.lastRunAt) <= new Date(entity.lastIngestionAt);
  }, [entity?.lastRunAt, entity?.lastIngestionAt]);

  const ingestionDebugStatus = useMemo(() => {
    if (!canSeeDebugInformation) return null;

    if (!entity.lastIngestionAt) return "Ingestion in progress";

    if (!isIngestionInfoUpToDate) return "Ingestion outdated";

    return entity.lastIngestionError ? "Last ingestion failed" : "Ingestion complete and up-to-date";
  }, [entity?.lastIngestionAt, canSeeDebugInformation]);

  return (
    <SingleDataEntityBodyContainer>
      <FlexRow
        style={{ justifyContent: "flex-start", gap: 16, minHeight: "85%" }}
        data-testid={`curation-process-info-associated-data-and-script-code`}
      >
        <FlexColumn style={{ flex: 2, gap: 16, justifyContent: "flex-start" }}>
          <CurationProcessInfoAndDataContainer>
            <FlexColumn style={{ ...commonBodyChildStyle, flex: 1 }} data-testid={`curation-process-info-container`}>
              <CommonTitleText>{CURATION_PROCESS_INFO_TITLE_TXT}</CommonTitleText>
              <Spacer space={16} />
              <FlexRow style={{ height: "100%", gap: 8 }}>
                <FlexColumn>
                  <SemanticInfoSection
                    maxInputChars={MAX_CURATION_CONTEXT_CHARS}
                    isAllowedToEdit={true}
                    onSaveRequiresConfirmation={false}
                    containerGap={0}
                    getDataCallback={() => entity?.curationProcessInfo || ""}
                    onSave={text =>
                      updateDataEntityRequest.mutate({
                        dataEntityId: entity._id,
                        updateFields: {
                          curationProcessInfo: text
                        }
                      })
                    }
                  />
                </FlexColumn>
              </FlexRow>
            </FlexColumn>

            <AssociatedDataContainer data-testid={"associated-data-container"}>
              <FlexRow style={{ height: "unset" }}>
                <FlexColumn style={{ justifyContent: "flex-start" }}>
                  <Text weight={600} size={18}>
                    {ASSOCIATED_DATA_TITLE_TXT}
                  </Text>
                </FlexColumn>
                <GeneralVantiButton
                  buttonInfo={{
                    onClick: () => setDisplayOtherDatasets(true),
                    label: ADD_DATA_BTN_LABEL_TEXT
                  }}
                  variant={VARIANTS.SECONDARY}
                  size={SIZES.SMALL}
                />
              </FlexRow>
              <Spacer space={8} />
              <DataEntityAssociations entity={entity} setDisplayDataDeletionDialogId={setDisplayDataDeletionDialogId} />
            </AssociatedDataContainer>
          </CurationProcessInfoAndDataContainer>
          <CurationCodeContainer>
            <CurationCodeHeaderWrapper>
              <FlexColumn>
                <CommonTitleText>{CURATION_SCRIPT_TITLE_TXT}</CommonTitleText>
              </FlexColumn>
              <DarkToolTip title={"Copy to clipboard"}>
                <GeneralVantiButton
                  style={{ padding: "8px 10px" }}
                  buttonInfo={{
                    onClick: async () =>
                      navigator.clipboard && (await navigator.clipboard.writeText(atob(newCurationCode))),
                    label: <CopyToClipboardIcon24 />
                  }}
                  variant={VARIANTS.TERTIARY}
                  size={SIZES.SMALL}
                />
              </DarkToolTip>
              <Spacer space={8} horizontal={false} />
              <DarkToolTip title={UPLOAD_SCRIPT_TOOLTIP_TXT}>
                <GeneralVantiButton
                  style={{ padding: "8px 10px" }}
                  buttonInfo={{
                    onClick: () => {
                      inputFile.current.click();
                    },
                    label: <UploadCloudIcon width={24} height={24} />
                  }}
                  variant={VARIANTS.SECONDARY}
                  size={SIZES.SMALL}
                />
              </DarkToolTip>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={onUploadedCurationScript}
              />
            </CurationCodeHeaderWrapper>
            <CurationCodeAndButtonWrapper>
              <SemanticInfoTextAreaStyled wrap={"hard"} value={newCurationCode} disabled={true} />
              <RunCurationButtonWrapper>
                <GeneralVantiButton
                  onClickShowLoader={{ eventFinished: response }}
                  onClickDisable={{ eventFinished: response }}
                  buttonInfo={{
                    onClick: () => {
                      setResponse(false);
                      runEntity(true);
                    },
                    label: CURATION_SCRIPT_RUN_BTN_LABEL_TXT,
                    disabled: !response || !newCurationCode
                  }}
                  variant={VARIANTS.SECONDARY}
                  size={SIZES.SMALL}
                  style={{ width: 80 }}
                />
                <GeneralVantiButton
                  onClickShowLoader={{ eventFinished: response }}
                  onClickDisable={{ eventFinished: response }}
                  buttonInfo={{
                    onClick: () => {
                      setResponse(false);
                      runEntity(false);
                    },
                    label: SAVE_DATA_ENTITY_BTN_LABEL_TXT,
                    disabled: !entity.lastRunSuccessfull || !response
                  }}
                  variant={VARIANTS.SECONDARY}
                  size={SIZES.SMALL}
                  style={{ width: 100 }}
                />
              </RunCurationButtonWrapper>
              {lastCurationScriptRunError && (
                <Text size={16} color={vantiColors.danger1}>
                  Script execution failed: {entity.lastRunError}
                </Text>
              )}
              {isIngestionInfoUpToDate && entity?.lastIngestionError && (
                <Text size={16} color={vantiColors.danger1}>
                  Entity saving failed: {entity.lastIngestionError}
                </Text>
              )}
            </CurationCodeAndButtonWrapper>
          </CurationCodeContainer>
        </FlexColumn>

        <ActivitySectionContainer data-testid={`data-entity-activity-section`}>
          <CommonTitleText>{ACTIVITY_INFO_TITLE_TXT}</CommonTitleText>
          <Spacer space={16} />
          <ActivitySection entity={entity} />
        </ActivitySectionContainer>
      </FlexRow>

      {tablePreview && (
        <>
          <FlexColumn style={{ ...commonBodyChildStyle }}>
            <CommonTitleText>
              {CURATION_TABLES_TITLE_TXT}
              {ingestionDebugStatus && " (" + ingestionDebugStatus + ")"}
            </CommonTitleText>
            <ChatMessageReportDataPreviewTable
              preview={tablePreview}
              isEnlargedPreview={false}
              isReachedMaximumRows={isReachedMaximumRows}
              isLoadingMoreItems={getTable.isLoading}
              setPageNumber={setPageNumber}
              showLoadMore={true}
            />
            <Spacer space={16} />
          </FlexColumn>
        </>
      )}
    </SingleDataEntityBodyContainer>
  );
};

const CurationCodeHeaderWrapper = styled("div")({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: 8
});

const CurationCodeAndButtonWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  gap: 4
});

const RunCurationButtonWrapper = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  padding: "9px 8px",
  marginTop: 4,
  alignSelf: "flex-end",
  boxSizing: "border-box",
  columnGap: 8
});
const CurationProcessInfoAndDataContainer = styled("div")({
  width: "100%",
  minHeight: "50%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  gap: 16
});

const SingleDataEntityBodyContainer = styled("div")({
  width: "100%",
  height: "100%",
  overflowY: "auto",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: 16,
  paddingRight: 8,
  ...VantiScrollBarProps
});

export const SemanticInfoTextAreaStyled = styled(SemanticInfoTextArea)({
  border: `1px solid ${vantiColors.gray36}`,
  borderRadius: "5px",
  boxSizing: "border-box",
  "&:disabled": {
    backgroundColor: vantiColors.gray18
  }
});

const CurationCodeContainer = styled(FlexColumn)({
  minHeight: "40%",
  ...commonBodyChildStyle
});

const AssociatedDataContainer = styled(FlexColumn)({
  width: "60%",
  flex: 1,
  justifyContent: "flex-start",
  ...commonBodyChildStyle
});

const ActivitySectionContainer = styled(FlexColumn)({
  flex: 1,
  ...commonBodyChildStyle
});

export default memo(SingleDataEntityBody);
