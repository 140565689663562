export const actionTypes = {
  STATION_ASSIGN_PRODUCT_REQUEST: "station.assignProduct.request",
  STATION_ASSIGN_PRODUCT_SUCCESS: "station.assignProduct.success",
  STATION_ASSIGN_PRODUCT_ERROR: "station.assignProduct.error",
  STATION_ASSIGN_PRODUCT_FULFILL: "station.assignProduct.fulfill"
};

export function assignProductRequestAction({ stationId, product, isChecked }) {
  return { type: actionTypes.STATION_ASSIGN_PRODUCT_REQUEST, payload: { stationId, product, isChecked } };
}
