import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { styled } from "@material-ui/core";
import ChatInput from "./ChatInput";
import ChatMessage from "pages/side-bar/chat/components/chat-message/ChatMessage";
import ChatMessageQuestions from "./chat-message/ChatMessageQuestions";
import usePermissions from "common/hooks/use-permissions";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import ChatMessageMetadataModal from "./ChatMessageMetadataModal";
import Loader from "components/ui/Loader";

const ChatWindowStyled = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 16
});

const StyledList = styled(Virtuoso)(props => ({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: props.chatLoading ? "none" : "block"
}));

const StyledPredefinedQuestions = styled("div")(() => ({
  display: "flex",
  width: "100%",
  height: "100%",
  alignItems: "center",
  backgroundColor: "white"
}));

const OVERSCAN_AMOUNT = 10;
const INCREASED_VIEW_PORT_CONFIG = {
  top: 800,
  bottom: 800
};

const LoaderContainer = styled("div")(() => ({
  display: "flex",
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center"
}));

const ChatWindow = ({
  onSend,
  messages,
  scrollToMessageId,
  startNewChatFunc,
  resetTargetMessageIdForScrolling,
  onTopReached,
  chatId
}) => {
  const virtuosoListRef = useRef(null);

  const isPermittedToSendMessage = usePermissions([ROLES_ENUM.SEND_CHAT_MESSAGE]);
  const isPermittedToUseSuggestion = usePermissions([ROLES_ENUM.USE_CHAT_SUGGESTIONS]);
  const isPermittedToViewMessageMetadata = usePermissions([ROLES_ENUM.EDIT_DATASET_QUESTIONS]);

  const { chatContext } = useContext(AppContext);
  const { chatMessageMetadataId, isChatLoading, firstItemIndex } = chatContext;

  const [shouldFollowOutput, setShouldFollowOutput] = useState(true);

  const onListStartReached = atTop => {
    if (atTop) {
      if (onTopReached) {
        onTopReached();
        setShouldFollowOutput(false);
      }
    }
  };

  useEffect(() => {
    setShouldFollowOutput(true);
  }, [chatId]);

  useEffect(() => {
    if (scrollToMessageId && virtuosoListRef.current) {
      let scrollMessageIndex = messages.findIndex(message => message._id === scrollToMessageId);
      if (!scrollMessageIndex || scrollMessageIndex === -1) {
        scrollMessageIndex = messages.length - 1;
      }
      virtuosoListRef.current.scrollToIndex({
        index: scrollMessageIndex,
        align: "start",
        behavior: messages.length < 12 ? "smooth" : "auto"
      });
    }
  }, [messages, scrollToMessageId, virtuosoListRef.current]);

  return (
    <ChatWindowStyled>
      {!messages || !messages.length ? (
        isPermittedToUseSuggestion &&
        !isChatLoading && (
          <StyledPredefinedQuestions>
            <ChatMessageQuestions startNewChatFunc={startNewChatFunc} />
          </StyledPredefinedQuestions>
        )
      ) : (
        <StyledList
          followOutput={isAtBottom => {
            return shouldFollowOutput || isAtBottom ? "smooth" : false;
          }}
          chatLoading={isChatLoading}
          ref={virtuosoListRef}
          data={messages}
          overscan={OVERSCAN_AMOUNT}
          firstItemIndex={firstItemIndex}
          atTopStateChange={onListStartReached}
          increaseViewportBy={INCREASED_VIEW_PORT_CONFIG}
          itemContent={(index, item) => <ChatMessage message={item} firstItemInList={firstItemIndex - index === 0} />}
          onWheel={() => resetTargetMessageIdForScrolling()}
        />
      )}

      {isChatLoading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}

      {isPermittedToSendMessage && <ChatInput onSend={onSend} />}
      {isPermittedToViewMessageMetadata && chatMessageMetadataId && <ChatMessageMetadataModal />}
    </ChatWindowStyled>
  );
};

export default memo(ChatWindow);
