import { call, put, takeLatest } from "redux-saga/effects";
import { getAllDataConnectors, startModelJob } from "services/api/dataConnectors";
import { dataConnectorsFlagTypes } from "./actionTypes";

function* fetchDataConnectorsSaga() {
  try {
    const allDataConnectors = yield call(getAllDataConnectors);
    yield put({ type: dataConnectorsFlagTypes.DATA_CONNECTORS_FETCH_SUCCESS, payload: allDataConnectors });
  } catch (error) {
    yield put({ type: dataConnectorsFlagTypes.DATA_CONNECTORS_FETCH_ERROR, payload: error });
  }
}

export function* fetchDataConnectorsWatcher() {
  yield takeLatest(dataConnectorsFlagTypes.DATA_CONNECTORS_START_FETCH, fetchDataConnectorsSaga);
}

function* connectToConnectorSaga(action) {
  try {
    const { modelId, connectorId, userQuery, tableName } = action.payload;
    const dataConnectorConnectRes = yield call(startModelJob, modelId, connectorId, userQuery, tableName);
    if (dataConnectorConnectRes.ok) {
      yield put({
        type: dataConnectorsFlagTypes.DATA_CONNECTORS_UPLOAD_SET_SUCCESS
      });
    }
  } catch (error) {
    yield put({ type: dataConnectorsFlagTypes.DATA_CONNECTORS_UPLOAD_SET_ERROR, payload: error });
  } finally {
    yield put({ type: dataConnectorsFlagTypes.DATA_CONNECTORS_CLEAR_UPLOAD_DATA });
  }
}

export function* connectToConnectorWatcher() {
  yield takeLatest(dataConnectorsFlagTypes.DATA_CONNECTORS_SET_UPLOAD_DATA, connectToConnectorSaga);
}
