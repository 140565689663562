export const NEW_CURATION_ACTION_TYPES = {
  SET_NAME: "set.name",
  SET_CURATION_CODE: "set.curation.code",
  SET_MERGE_CODE: "set.merge.code"
};

export const USE_SINGLE_DATA_CURATION_ACTION_TYPES = {
  SET_CURATION: "set.curation",
  SET_CURATION_JOB: "set.curation.job",
  SET_DOWNLOAD_URL: "set.download.url",
  SET_ACTIVE_SECTION_INDEX: "set.active.section.index",
  SET_EXISTING_CURATION_ERROR: "set.existing.curation.error",
  SET_NEW_CURATION_ERROR: "set.new.curation.error",
  SET_NEXT_LOCATION_PROPS: "set.next.location.props",
  SET_IS_SAFE_TO_LEAVE: "set.is.safe.to.leave",
  SET_ACTIVE_FILE_TAB: "set.active.file.tab",
  SET_PRESIGNED_URLS: "set.presigned.urls",
  SET_IS_MERGE_LOADING: "set.is.merge.loading",
  SET_MERGE_ERROR: "set.merge.error",
  SET_MERGE_CODE: "set.merge.code",
  SET_MERGE_JOB: "set.merge.job",
  SET_IS_CURATION_LOADING: "set.is.curation.loading",
  SET_CURATION_RUN_ERROR: "set.curation.run.error",
  SET_FILES: "set.files",
  REMOVE_FILE: "remove.file",
  SET_CHANGED_FILE_ACTION: "set.changed.file.action",
  SHOULD_NOTIFY_ABOUT_ORIGINAL_FILES_CHANGE: "set.should.notify.about.original.files.change"
};
