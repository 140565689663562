import { Chip } from "@material-ui/core";
import { CSVParserErrors } from "@vanti-analytics-org/vanti-common";
import ErrorIcon from "assets/icons/error/ErrorIcon";
import {ReactComponent as DeleteIcon} from "assets/icons/data-curation/DeleteIcon.svg";
import classNames from "classnames";
import { FlexItem } from "components/ui";
import DropFileSection from "components/ui/FileUploader/dropzone-views/DropFileSection";
import FileUploadErrorSection from "components/ui/FileUploader/dropzone-views/FileUploadErrorSection";
import CircularProgress from "components/ui/Progress/CircularProgress";
import { isEmpty } from "lodash";
import { FILE_ERRORS, SUPPORTED_FILE_EXTENSIONS, checkIfFileIsValid } from "pages/side-bar/data-curation/utils";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FILE_UPLOAD_STATUSES, readFile } from "utils/user-data-reader";
import { getFileExtension } from "utils/user-data-reader/index";
import { getExtractedFilesFromZip } from "utils/user-data-reader/zip";
import uuid from "uuid";
import styles from "../../../../styles";

const CurationFileUpload = ({ onCurationFileAdd, onCurationFileRemove, files, titleProps }) => {
  const classes = styles();
  const [filesErrors, setFilesErrors] = useState({});
  const [filesChipsForDisplay, setFilesChipsForDisplay] = useState([]);
  const [hoveredChip, setHoveredChip] = useState(null);

  useEffect(() => {
    if (files) {
      const allFilesChips = [];
      files.forEach(file => {
        allFilesChips.push({
          id: file.id,
          name: file.name,
          status: FILE_UPLOAD_STATUSES.SUCCESS
        });
      });
      setFilesChipsForDisplay(allFilesChips);
    }
  }, [files]);

  const onRemoveFile = useCallback(
    chipId => {
      onCurationFileRemove(chipId);
    },
    [onCurationFileRemove]
  );

  const onFileAdd = async newFile => {
    await onCurationFileAdd(newFile);
  };

  // DO NOT delete, will be used after integration.
  const getDeleteIcon = useCallback(
    fileChip => {
      return hoveredChip === fileChip.id ? null : (
        <FlexItem dense="full" className={classes.chipDeleteIconContainer}>
          {fileChip.status === FILE_UPLOAD_STATUSES.UPLOADING ? (
            <CircularProgress thickness={4} size={14} className={classes.circularProgress} />
          ) : fileChip.status === FILE_UPLOAD_STATUSES.SUCCESS ? null : (
            <ErrorIcon />
          )}
        </FlexItem>
      );
    },
    [hoveredChip]
  );

  const isFileNameExist = useCallback(
    file => {
      const allFiles = [...files];

      const existingFileWithSamePath = allFiles.findIndex(existingFile => existingFile.path === file.path);

      if (existingFileWithSamePath > -1) {
        onFileError(FILE_ERRORS.FILE_NAME_ALREADY_EXIST(file.path), file.path, file.id);
        return true;
      }

      return false;
    },
    [files]
  );

  const parseFile = async ({ file, failureCallback, successCallback }) => {
    const error = checkIfFileIsValid(file);

    if (error) {
      failureCallback(error);
      return;
    } else {
      switch (getFileExtension(file)) {
        case SUPPORTED_FILE_EXTENSIONS.CSV:
          parseCsv({ file, failureCallback, successCallback });
          break;

        case SUPPORTED_FILE_EXTENSIONS.XML:
          parseXml({ file, failureCallback, successCallback });
          break;

        case SUPPORTED_FILE_EXTENSIONS.JSON:
          parseJson({ file, failureCallback, successCallback });
          break;

        case SUPPORTED_FILE_EXTENSIONS.ZIP:
          parseZip({ file, failureCallback, successCallback });
          break;

        default:
          break;
      }
    }
  };

  const parseZip = async ({ file, failureCallback }) => {
    try {
      let result = await getExtractedFilesFromZip(file, [
        SUPPORTED_FILE_EXTENSIONS.CSV,
        SUPPORTED_FILE_EXTENSIONS.JSON,
        SUPPORTED_FILE_EXTENSIONS.XML
      ]);

      if (!result) {
        failureCallback(FILE_ERRORS.ZIP_IS_EMPTY);
        return;
      }

      const { files, directories } = result;

      if (directories.length > 1) {
        const zipDirectoryName = file.name.replace(".zip", "/");

        directories.map(directory => {
          if (directory.name !== zipDirectoryName) {
            const directoryName = directory.name.split(zipDirectoryName)[1].split("/")[0];
            onFileError(FILE_ERRORS.ZIP_CONTAINING_DIRECTORIES(directoryName), directoryName, file.id);
          }
        });
      }

      if (!files || !files.length) {
        failureCallback(FILE_ERRORS.ZIP_IS_EMPTY);
        return;
      }

      for await (const file of files) {
        file.path = file.name.split("/")[1];
        const fileId = uuid();
        file.id = fileId;
        if (!isFileNameExist(file)) {
          assignChipToNewFile(file);
          await parseFile({
            file,
            failureCallback: errorMessage => {
              onFileError(errorMessage, file.path, file.id);
            }
          });
        }
      }
    } catch (error) {
      failureCallback(error);
    }
  };

  const parseXml = async ({ file, failureCallback, successCallback }) => {
    return new Promise((resolve, reject) => {
      try {
        readFile(file, null, async (error, results) => {
          if (error) {
            failureCallback({
              error: error,
              tip: "Please make sure your file is not corrupted",
              modal: true
            });
            return;
          }

          if (isEmpty(results.data)) {
            failureCallback(FILE_ERRORS.FILE_IS_EMPTY);
            return;
          }
          onFileAdd({
            ...file,
            fileEntity: file,
            type: SUPPORTED_FILE_EXTENSIONS.XML,
            data: results.data
          });

          if (successCallback) {
            successCallback();
          }
          resolve(file);
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const parseJson = async ({ file, failureCallback, successCallback }) => {
    return new Promise((resolve, reject) => {
      try {
        readFile(file, null, async (error, results) => {
          if (error) {
            failureCallback({
              error: error,
              tip: "Please make sure your file is not corrupted",
              modal: true
            });
            return;
          }

          if (isEmpty(results.data)) {
            failureCallback(FILE_ERRORS.FILE_IS_EMPTY);
            return;
          }

          onFileAdd({
            ...file,
            fileEntity: file,
            type: SUPPORTED_FILE_EXTENSIONS.JSON,
            data: results.data
          });

          if (successCallback) {
            successCallback();
          }

          resolve(file);
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const parseCsv = async ({ file, failureCallback, successCallback }) => {
    return new Promise((resolve, reject) => {
      try {
        readFile(file, null, async (error, results) => {
          if (error) {
            switch (error) {
              case CSVParserErrors.MISSING_HEADER:
                failureCallback(FILE_ERRORS.HEADER_IS_MISSING);
                break;
              case CSVParserErrors.DUPLICATED_COLUMN:
                failureCallback(FILE_ERRORS.DUPLICATED_HEADER_COLUMN);
                break;
              default:
                failureCallback({
                  error: error,
                  tip: "Please make sure your file is not corrupted",
                  modal: true
                });
                break;
            }

            return;
          }

          if (isEmpty(results.data)) {
            failureCallback(FILE_ERRORS.FILE_IS_EMPTY);
            return;
          }

          onFileAdd({
            ...file,
            fileEntity: file,
            type: SUPPORTED_FILE_EXTENSIONS.CSV,
            data: {
              headerColumns: results.headers,
              bodyColumns: results.data,
              uniqueDataset: results.uniqueDataset
            }
          });

          if (successCallback) {
            successCallback();
          }
          resolve(file);
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const assignChipToNewFile = file => {
    const allFilesChips = [...filesChipsForDisplay];
    allFilesChips.push({
      id: file.id,
      name: file.name,
      status: FILE_UPLOAD_STATUSES.UPLOADING
    });
    setFilesChipsForDisplay(allFilesChips);
  };

  const onFileError = (error, filePath, fileId) => {
    const allFilesErrors = filesErrors;
    allFilesErrors[filePath] = error;
    setFilesErrors(allFilesErrors);
    onRemoveFile(fileId);
  };

  const onDrop = async acceptedFiles => {
    const droppedFiles = acceptedFiles;
    setFilesErrors({});

    if (droppedFiles.length === 0) {
      onFileError(FILE_ERRORS.NOT_SUPPORTED_FILE());
      return;
    }

    for await (const file of droppedFiles) {
      const fileExtension = getFileExtension(file);
      const fileId = uuid();
      file.id = fileId;

      if (fileExtension === SUPPORTED_FILE_EXTENSIONS.ZIP) {
        await parseFile({
          file,
          failureCallback: errorMessage => {
            onFileError(errorMessage, file.path, file.id);
          }
        });
      } else if (!isFileNameExist(file)) {
        assignChipToNewFile(file);
        await parseFile({
          file,
          failureCallback: errorMessage => {
            onFileError(errorMessage, file.path, file.id);
          }
        });
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    getFilesFromEvent: async event => {
      const files = [];
      const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;

      if (event.dataTransfer) {
        const droppedItem = event.dataTransfer.items[0];

        if (!droppedItem.type) {
          return [];
        }
      }

      for (const file of fileList) {
        Object.defineProperty(file, "path", {
          value: file.path || file.webkitRelativePath || file.name,
          writable: false,
          configurable: false,
          enumerable: true
        });

        files.push(file);
      }

      return files;
    }
  });

  const DropzoneElement = (
    <div
      data-testid="curation-file-upload-section"
      className={classNames(classes.dragAndDropArea, {
        [classes.whiteBackground]: isDragActive
      })}
      {...getRootProps({ isDragActive })}
    >
      <input {...getInputProps()} data-testid="curation-files-upload-input" />
      <DropFileSection />
    </div>
  );

  const fileErrorElement =
    Object.values(filesErrors).length > 0 &&
    Object.values(filesErrors).map(errorObject => (
      <FileUploadErrorSection key={errorObject.error} tip={errorObject.tip} className={classes.errorContainer}>
        {errorObject.error}
      </FileUploadErrorSection>
    ));

  return (
      <FlexItem container dense="full" data-testid={`curation-file-upload-section`}>
        {filesChipsForDisplay.map(fileChip => {
          return (
              <Chip
                  key={fileChip.name}
                  data-testid={`chip-curation-file-name-${fileChip.name}`}
                  className={classes.chip}
                  label={fileChip.name}
                  onDelete={() => {
                    onRemoveFile(fileChip.id);
                    setFilesErrors({});
                  }}
              />
          );
        })}
        {fileErrorElement}
      </FlexItem>
  );
};

export default memo(CurationFileUpload);
