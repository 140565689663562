import http from "../http";
import config from "common/config";

const FLOW_API_URL = `${config.serverUrl}/api/v2/flow`;

export async function createFlow({ nodes, edges, title, description }) {
  const res = await http.post(FLOW_API_URL, {
    nodes,
    edges,
    title,
    description
  });

  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function updateFlow({ flowId, nodes, edges, flowTitle: title, flowDescription: description }) {
  const url = `${FLOW_API_URL}/${flowId}`;

  const res = await http.patch(url, {
    nodes,
    edges,
    title,
    description
  });

  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function getAllAccountFlows() {
  const res = await http.get(FLOW_API_URL);

  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function deleteFlow({ flowId }) {
  const res = await http.delete(`${FLOW_API_URL}/${flowId}`);

  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function createAlert({ userPrompt, flowId, edgeId }) {
  const res = await http.post(`${FLOW_API_URL}/run-metric-calculation`, {
    userPrompt,
    flowId,
    edgeId
  });

  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}
