import React, { memo } from "react";

const ProductionEfficiencyIcon = ({ className }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M34.5 4.5L16 24L23 26.5L15 44L33.5 24L26.5 22.5L34.5 4.5Z" fill="#86E8B6" />
    <path
      d="M35.7373 7.80456C40.7442 11.4395 44 17.3395 44 24C44 35.0457 35.0457 44 24 44C22.2735 44 20.5981 43.7812 19 43.3699M30.5 5.07996C28.4621 4.37999 26.2754 4 24 4C12.9543 4 4 12.9543 4 24C4 31.1239 7.72461 37.3779 13.3327 40.9208M34.5 4.5L16 24L23 26.5L15 44L33.5 24L26.5 22.5L34.5 4.5Z"
      stroke="black"
    />
  </svg>
);

export default memo(ProductionEfficiencyIcon);
