import React, { memo } from "react";

const RadioButton24Checked = ({ ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <mask
      id="mask0_2619_1183"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2619_1183)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.4931 20.2915C9.58872 20.7638 10.7588 21 12.0034 21C13.2479 21 14.4174 20.7636 15.512 20.2908C16.6066 19.8181 17.5588 19.1765 18.3685 18.366C19.1782 17.5556 19.8192 16.6025 20.2915 15.5069C20.7638 14.4113 21 13.2412 21 11.9967C21 10.7521 20.7636 9.58255 20.2908 8.48795C19.8181 7.39337 19.1765 6.44122 18.366 5.63152C17.5556 4.82182 16.6025 4.18081 15.5069 3.70848C14.4113 3.23616 13.2412 3 11.9966 3C10.7521 3 9.58255 3.23638 8.48795 3.70915C7.39337 4.18192 6.44123 4.82353 5.63153 5.63398C4.82183 6.44444 4.18081 7.39748 3.70848 8.4931C3.23616 9.58872 3 10.7588 3 12.0033C3 13.2479 3.23638 14.4174 3.70915 15.512C4.18192 16.6066 4.82352 17.5588 5.63397 18.3685C6.44444 19.1782 7.39748 19.8192 8.4931 20.2915ZM17.675 17.675C16.125 19.225 14.2333 20 12 20C9.76667 20 7.875 19.225 6.325 17.675C4.775 16.125 4 14.2333 4 12C4 9.76667 4.775 7.875 6.325 6.325C7.875 4.775 9.76667 4 12 4C14.2333 4 16.125 4.775 17.675 6.325C19.225 7.875 20 9.76667 20 12C20 14.2333 19.225 16.125 17.675 17.675Z"
        fill="#0313A4"
      />
      <path
        d="M12 16C13.1141 16 14.0593 15.6119 14.8356 14.8356C15.6119 14.0593 16 13.1141 16 12C16 10.8859 15.6119 9.94071 14.8356 9.16442C14.0593 8.38814 13.1141 8 12 8C10.8859 8 9.94071 8.38814 9.16442 9.16442C8.38814 9.94071 8 10.8859 8 12C8 13.1141 8.38814 14.0593 9.16442 14.8356C9.94071 15.6119 10.8859 16 12 16Z"
        fill="#0313A4"
      />
    </g>
  </svg>
);

export default memo(RadioButton24Checked);
