import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAsyncFn } from "react-use";
import DataSourceFieldsSection from "../DataSourceFieldsSection";
import CreateConnectButtonSection from "../../connect-button-section/CreateConnectButtonSection";
import { FlexItem, Text } from "components/ui";
import { testConnectionConnector } from "services/api/dataConnectors";
import { vantiColors } from "assets/jss/palette";
import usePermissions from "common/hooks/use-permissions";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import { useDispatch } from "react-redux";
import { dataConnectorsAddConnectorToState } from "modules/data-connectors/actions";
import { TEST_CONNECTION_FAILED_TEXT } from "pages/side-bar/data-connectors/constants";
import { styled } from "@material-ui/core";

export const ConnectErrorContainer = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "auto",
  padding: "16px",
  marginBottom: "10px",
  borderRadius: "2px",
  border: `1px solid var(--red, ${theme.palette.vantiColors.rose6})`,
  background: "#fff5f8"
}));

const mockConnectorTableNames = ["ML_23s7", "PL_63e8", "SN_893b", "LT_87x0", "XLR_8", "GR_19AL97"];

const DataSourcesWrapper = ({
  onClick,
  setDataSourceFieldsValues,
  setFieldsChanged,
  dataSourceFieldsValues,
  connectorName,
  selectedDataSource,
  fieldsChanged,
  actionResponse,
  sourceTypeKey,
  setConnectorTablesCatalogue
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const testConnectionButtonRef = useRef();

  const [mockTestConnection, setMockTestConnection] = useState({});
  const [connectTestErrorMsg, setConnectTestErrorMsg] = useState(null);

  const shouldReplaceMinioWithDatabricksConnector = usePermissions([ROLES_ENUM.ViewDatabricksConnector]);
  const isSelectedDatabricks = useMemo(
    () => shouldReplaceMinioWithDatabricksConnector && selectedDataSource?.title === "Data bricks",
    [selectedDataSource, shouldReplaceMinioWithDatabricksConnector]
  );

  useEffect(() => {
    if (testConnectionButtonRef.current) {
      testConnectionButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [testConnectionButtonRef.current]);

  const [testConnection, onTestConnection] = useAsyncFn(async () => {
    const response = await testConnectionConnector(sourceTypeKey, dataSourceFieldsValues);

    setFieldsChanged(false);
    setConnectorTablesCatalogue(response.data);
    return response;
  }, [dataSourceFieldsValues]);

  useEffect(() => {
    if (testConnection.value?.ok == false && testConnection.loading == false) {
      setConnectTestErrorMsg(testConnection.value?.message || TEST_CONNECTION_FAILED_TEXT);
    }
    if (testConnection.loading == true) {
      setConnectTestErrorMsg(null);
    }
    if (testConnection.value?.ok === true) {
      doneAndExit();
    }
  }, [testConnection]);

  useEffect(() => {
    setConnectTestErrorMsg(null);
  }, [selectedDataSource]);

  useEffect(() => {
    if (mockTestConnection.loading) {
      setTimeout(() => {
        setMockTestConnection(prevState => ({
          ...prevState,
          loading: false
        }));
      }, 3000);
    }
  }, [mockTestConnection]);

  const doneAndExit = async () => {
    if (isSelectedDatabricks) {
      const obj = {
        title: connectorName,
        sourceType: sourceTypeKey,
        credentials: {
          ...dataSourceFieldsValues
        },
        tableNames: mockConnectorTableNames
      };
      dispatch(dataConnectorsAddConnectorToState(obj));
      history.push("/dashboard/data");
    } else {
      const result = await onClick();
      if (!(result instanceof Error)) {
        history.push("/dashboard/data");
      }
    }
  };

  return (
    <FlexItem container dense="full">
      <FlexItem direction="column" container style={{ backgroundColor: "white", borderRadius: "4px" }}>
        {connectTestErrorMsg && (
          <ConnectErrorContainer>
            <Text data-testid="connection-ok-text" color={vantiColors.rose6}>
              {connectTestErrorMsg}
            </Text>
          </ConnectErrorContainer>
        )}
        <FlexItem container dense="full">
          <DataSourceFieldsSection
            selectedDataSource={selectedDataSource}
            onChangeDataSourceFieldsValues={setDataSourceFieldsValues}
          />
          <CreateConnectButtonSection
            dataTestId="data-connector-connect-btn-section"
            dataSourceFieldsValues={dataSourceFieldsValues}
            actionResponse={actionResponse}
            fieldsChanged={fieldsChanged}
            onTestConnection={
              isSelectedDatabricks
                ? () => {
                    setMockTestConnection({
                      loading: true,
                      value: {
                        ok: true
                      }
                    });
                  }
                : onTestConnection
            }
            selectedDataSource={selectedDataSource}
            testConnection={isSelectedDatabricks ? mockTestConnection : testConnection}
          />
        </FlexItem>
      </FlexItem>
    </FlexItem>
  );
};

export default memo(DataSourcesWrapper);
