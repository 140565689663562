import React, { memo, useContext, useMemo } from "react";
import { vantiColors } from "assets/jss/palette";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { Text } from "components/ui";
import { DarkToolTip } from "components/ui/tooltips/tooltip";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { styled } from "@material-ui/core";

const MISSING_DESCRIPTION_TEXT = "No description";
const DATASET_DESCRIPTION_TOOLTIP_TEXT = "Dataset description:";
const NON_DATASET_TITLE_TEXT = "Other...";

const ChatMessageInlineButtonDatasetSelection = ({
  title,
  onClickInlineButton,
  isButtonDisabled,
  isButtonSelected
}) => {
  const { datasetsContext } = useContext(AppContext);
  const { datasets } = datasetsContext;
  const isDatasetButton = title !== NON_DATASET_TITLE_TEXT;

  const fileDescription = useMemo(() => {
    const datasetValueList = Object.values(datasets);
    const dataset = datasetValueList.find(ds => ds.name === title);
    return dataset?.fileDescription || MISSING_DESCRIPTION_TEXT;
  }, [datasets]);

  const tooltipContent = useMemo(() => {
    return (
      <DatasetSelectionButtonTooltip>
        <Text weight={600} color={"white"}>
          {title}
        </Text>
        <br />
        <Text weight={400} color={"white"}>
          <span style={{ color: vantiColors.lightGray13 }}>{DATASET_DESCRIPTION_TOOLTIP_TEXT} &nbsp;</span>
          {fileDescription}
        </Text>
      </DatasetSelectionButtonTooltip>
    );
  }, [title, fileDescription]);

  const InlineButton = useMemo(
    () => (
      <GeneralVantiButton
        size={SIZES.CUSTOM}
        variant={VARIANTS.SELECT}
        buttonInfo={{
          label: (
            <Text
              size={16}
              lineSize={"normal"}
              color={
                isButtonSelected
                  ? vantiColors.darkBlue7
                  : isButtonDisabled
                  ? vantiColors.lightBlue13
                  : vantiColors.darkBlue7
              }
            >
              {title}
            </Text>
          ),
          onClick: onClickInlineButton
        }}
        style={{
          pointerEvents: isButtonDisabled ? "none" : "auto",
          padding: "8px 16px",
          borderColor: isButtonSelected ? vantiColors.darkBlue7 : isButtonDisabled ? vantiColors.lightBlue14 : "auto",
          backgroundColor: isButtonSelected ? vantiColors.lightGray12 : "auto"
        }}
      />
    ),
    [title, onClickInlineButton, isButtonDisabled, isButtonSelected]
  );

  return isDatasetButton ? (
    <DarkToolTip
      placement={"bottom-start"}
      title={tooltipContent}
      enterDelay={500}
      enterNextDelay={500}
      leaveDelay={500}
      arrow
    >
      {InlineButton}
    </DarkToolTip>
  ) : (
    InlineButton
  );
};

const DatasetSelectionButtonTooltip = styled("div")({
  width: "auto",
  height: "auto%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column"
});

export default memo(ChatMessageInlineButtonDatasetSelection);
