import { makeStyles } from "@material-ui/core";

const styles = makeStyles(({ palette, spacing }) => ({
  root: {
    fontFamily: "Inter"
  },
  wrapper: {
    padding: spacing(1.5),
    display: "flex",
    flex: "1",
    justifyContent: "space-between"
  },
  active: {
    backgroundColor: palette.vantiColors.white
  },
  time: {
    fontSize: "16px",
    fontWeight: 500,
    color: palette.vantiColors.lightGray6,
    marginBottom: spacing(1)
  },
  label: {
    backgroundColor: palette.vantiColors.lightGray4,
    borderRadius: "4px",
    padding: spacing(0.5, 2)
  },
  labelActive: {
    color: palette.vantiColors.green4,
    backgroundColor: palette.vantiColors.lightGreen3
  }
}));

export default styles;
