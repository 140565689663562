import React from "react";
import { Grid } from "@material-ui/core";

import CardBody from "components/ui/Card/CardBody";
import Info from "./info";
import Form from "./form";

import useStyles from "./styles";

const ConfirmAccountBody = ({ isDisable, email, account, showInfo }) => {
  const classes = useStyles();

  return (
    <CardBody className={classes.cardBody}>
      <Grid container className={classes.cardBodyContainer}>
        <Info email={email} account={account} />
        <Form isDisable={isDisable} showInfo={showInfo} />
      </Grid>
    </CardBody>
  );
};

export default ConfirmAccountBody;
