import React, {useEffect} from "react";
import { Helmet } from "react-helmet-async";

const webWidgetStyle =
    ` 
       
    `

const HeadersProvider = () => {
    useEffect(() => {
        window.zESettings = {
            webWidget: {
                launcher: {
                    label: {
                        '*': ' '
                    },
                },
                offset: { horizontal: '-13px' },
            }
        }
        }, []);
  return (
    <Helmet>
        <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=4851fbe8-0d94-49a8-b7c2-92cfa0550b32"> </script>
        <script type="text/javascript">
            {webWidgetStyle}
        </script>
          </Helmet>
  );
};

export default HeadersProvider;