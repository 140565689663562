import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { makeStyles, styled } from "@material-ui/core";
import usePermissions from "common/hooks/use-permissions";
import { ChatContextType, isSupportedDatasetTypeForChat, ROLE } from "@vanti-analytics-org/vanti-common";
import CloseIcon16 from "assets/icons/close-icon/CloseIcon16";
import { vantiColors } from "assets/jss/palette";
import { Text } from "components/ui";
import DownloadIcon24 from "assets/icons/download-icon/DownloadIcon24";
import { getDateFormatDayjs } from "utils/dateTime";
import capitalize from "lodash/capitalize";
import { constants } from "utils/constants";
import { ChatMessageReportDataPreviewTable } from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportDataPreviewSection/ChatMessageReportDataPreviewTable";
import DynamicTabs from "components/ui/Tabs";
import SemanticInfoSection from "pages/dashboard/settings/SemanticInfoSection";
import UploadIcon24 from "assets/icons/upload-icon/UploadIcon24";
import { getChatContext, updateChatContext } from "services/api/chat-context";
import { getDatasetFileDescription, updateDatasetFileDescription } from "services/api/dataset";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { VantiScrollBarProps } from "components/ui/VantiScrollBarProps";
import DatasetDateRange from "./DatasetDateRange";
import useDatasetRequest from "common/hooks/use-dataset-request";

const FILE_DESCRIPTION_MAX_CHARS = 300;
const NOT_EDITABLE_FROM_THIS_PAGE_TOOLTIP_TEXT = "Editing this information is available through the Data assets page";
const NO_EDIT_PERMISSIONS_TOOLTIP_TEXT = "You are not allowed to edit this information";

const DatasetFileInfoContainer = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column"
});

const CloseIcon16Styled = styled(CloseIcon16)({
  alignSelf: "flex-end",
  cursor: "pointer",
  marginBottom: 12
});

const DatasetFileInfoHeader = styled("div")({
  width: "100%",
  padding: 20,
  boxSizing: "border-box",
  backgroundColor: vantiColors.lightBlue10,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  marginBottom: 20
});

const DatasetFileInfoTitleSection = styled("div")({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  gap: 12,
  marginBottom: 18
});

const DatasetFileInfoMetadataSection = styled("div")({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column"
});

const DownloadIcon24Styled = styled(DownloadIcon24)({
  cursor: "pointer"
});

const MetadataText = styled("span")({
  fontWeight: 600
});

const DynamicTabsWrapper = styled("div")({
  marginBottom: 24
});

const useStyles = makeStyles(theme => ({
  tabsRoot: {
    minHeight: 1
  },
  tabRoot: {
    minHeight: 1,
    minWidth: 1,
    lineHeight: "normal",
    color: vantiColors.gray33,
    boxSizing: "border-box",
    borderBottom: `2px solid white`,
    "&.Mui-selected": {
      color: "black",
      borderBottom: `2px solid ${theme.palette.vantiColors.darkBlue7}`
    }
  },
  tabsIndicator: {
    display: "none"
  },
  tabsFlexContainer: {
    display: "flex",
    gap: 16
  }
}));

const PreviewDataTableWrapper = styled("div")(({ enableScroll }) => ({
  overflow: enableScroll ? "auto" : "hidden",
  flex: 1,
  boxSizing: "border-box",
  ...VantiScrollBarProps
}));

const DatasetSemanticInfoBody = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflowY: "auto",
  ...VantiScrollBarProps
});

const NonTabularDataPreviewContainer = styled("div")({
  width: "100%",
  overflow: "auto",
  flex: 1,
  boxSizing: "border-box",
  border: `1px solid ${vantiColors.gray36}`,
  borderRadius: "5px",
  padding: 16,
  ...VantiScrollBarProps
});

const DATASET_FILE_INFO_TITLE_PREFIX = "File name:";
const DATASET_FILE_INFO_METADATA_UPLOAD_INFO_PREFIX = "Uploaded by:";
const DATASET_TAB_FILE_INFO = "File info";
const DATASET_TAB_PREVIEW_DATA = "Preview data";

const DATASET_FILE_INFO_MODAL_TABS = {
  DATASET_TAB_FILE_INFO: DATASET_TAB_FILE_INFO,
  DATASET_TAB_PREVIEW_DATA: DATASET_TAB_PREVIEW_DATA
};

const DatasetFileInfo = ({ dataset, setUnsavedData, onCloseHandler, isReadOnly }) => {
  const classes = useStyles();
  const { usersContext, datasetsContext } = useContext(AppContext);
  const { accountUserNames } = usersContext;
  const { datasetFileDescriptionSelectedTabIndex } = datasetsContext;
  const [selectedTabIndex, setSelectedTabIndex] = useState(datasetFileDescriptionSelectedTabIndex || 0);
  const isAllowedToEditChatContext = usePermissions([ROLE.EDIT_CHAT_CONTEXT]);
  const isAllowedToEditFileDescription = usePermissions([ROLE.EDIT_DATASET_FILE_DESCRIPTION]);
  const isAllowedToDownloadDataset = usePermissions([ROLE.DOWNLOAD_DATASET]);

  const [creatorName, setCreatorName] = useState("");
  const { getDatasetPresignedUrlReq } = useDatasetRequest();
  const handleChange = useCallback((event, newValue) => {
    setSelectedTabIndex(newValue);
  }, []);

  const tooltipText = useMemo(() => {
    return !isAllowedToEditChatContext || !isAllowedToEditFileDescription
      ? NO_EDIT_PERMISSIONS_TOOLTIP_TEXT
      : NOT_EDITABLE_FROM_THIS_PAGE_TOOLTIP_TEXT;
  }, [isAllowedToEditFileDescription, isAllowedToEditChatContext, isReadOnly]);

  useEffect(async () => {
    if (dataset?.creatorId) {
      const user = accountUserNames.find(user => user.id === dataset.creatorId);
      if (user) {
        setCreatorName(user.fullDisplayName);
      }
    }
  }, [dataset.creatorId, accountUserNames]);

  const title = useMemo(() => {
    return dataset?.name ? [DATASET_FILE_INFO_TITLE_PREFIX, dataset.name].join(" ") : "";
  }, [dataset?.name]);

  const uploadedBy = useMemo(() => {
    const text = `${DATASET_FILE_INFO_METADATA_UPLOAD_INFO_PREFIX} ${creatorName}, ${getDateFormatDayjs(
      dataset?.createdAt
    )}`;
    return (
      <Text size={16} color={vantiColors.gray40}>
        {text}
      </Text>
    );
  }, [dataset, creatorName]);

  const textDivider = useMemo(() => <span style={{ color: vantiColors.lightGray13 }}>{" | "}</span>, []);
  const tabTitleValue = useMemo(
    () => DATASET_FILE_INFO_MODAL_TABS[Object.keys(DATASET_FILE_INFO_MODAL_TABS)[selectedTabIndex]],
    [selectedTabIndex]
  );
  const metadata = useMemo(() => {
    const datasetFileSizeText = dataset.originalFilesize ? `(${dataset.originalFilesize})` : "";
    //TODO: DONT SHOW RECORDS COUNT IF NONE EXIST (NON CSV)
    return (
      <div>
        <Text size={16} color={vantiColors.gray40} lineSize={"19.36px"}>
          {constants.datasets.NUM_OF_RECORDS_TEXT + " "}
          <MetadataText>{dataset.rowsCount}</MetadataText>
          {textDivider}
          {constants.datasets.NUM_OF_COLUMNS_TEXT + " "}
          <MetadataText>{dataset.columnsCount}</MetadataText>
          {textDivider}
          {constants.datasets.FILE_TYPE_TEXT}
          <MetadataText>{`${dataset.datasetType} ${datasetFileSizeText}`}</MetadataText>
        </Text>
      </div>
    );
  }, [dataset]);

  const tabContent = useMemo(() => {
    switch (tabTitleValue) {
      case DATASET_FILE_INFO_MODAL_TABS.DATASET_TAB_FILE_INFO:
        return (
          <DatasetSemanticInfoBody data-testid={"dataset-semantic-info-body"}>
            <SemanticInfoSection
              Icon={UploadIcon24}
              data-testid={`dataset-semantic-info-section-file-description`}
              onSave={async context => await updateDatasetFileDescription(dataset?.id?.toString(), context)}
              onSaveRequiresConfirmation={false}
              getDataCallback={getDatasetFileDescription}
              maxInputChars={FILE_DESCRIPTION_MAX_CHARS}
              relatedEntityId={dataset?.id?.toString()}
              isAllowedToEdit={isAllowedToEditFileDescription && !isReadOnly}
              tooltipText={tooltipText}
              title={"File description"}
              setUnsavedData={setUnsavedData}
              stretch={false}
              resize={"vertical"}
            />
            {isSupportedDatasetTypeForChat(dataset?.datasetType) && (
              <SemanticInfoSection
                data-testid={`dataset-semantic-info-section-dataset`}
                onSave={async context =>
                  await updateChatContext(dataset?.id?.toString(), ChatContextType.DATASET, context)
                }
                getDataCallback={getChatContext}
                contextType={ChatContextType.DATASET}
                maxInputChars={constants.datasets.SEMANTIC_INFO_MAX_CHARS}
                relatedEntityId={dataset?.id?.toString()}
                isAllowedToEdit={isAllowedToEditChatContext && !isReadOnly}
                tooltipText={tooltipText}
                title={[capitalize(ChatContextType.DATASET), constants.datasets.SEMANTIC_INFO_TEXT].join(" ")}
                setUnsavedData={setUnsavedData}
                resize={"vertical"}
              />
            )}
          </DatasetSemanticInfoBody>
        );

      case DATASET_FILE_INFO_MODAL_TABS.DATASET_TAB_PREVIEW_DATA:
        if (dataset.datasetType === "CSV") {
          return (
            <ChatMessageReportDataPreviewTable
              isEnlargedPreview={true}
              lowOpacity={false}
              preview={dataset?.enlargedPreview}
            />
          );
        }
        return <NonTabularDataPreviewContainer>{dataset.enlargedPreview}</NonTabularDataPreviewContainer>;

      default:
        return null;
    }
  }, [selectedTabIndex, dataset?.enlargedPreview]);

  return (
    <DatasetFileInfoContainer data-testid={"dataset-file-info-container"}>
      <CloseIcon16Styled onClick={onCloseHandler} data-testid={"dataset-file-info-close-icon"} />

      <DatasetFileInfoHeader data-testid={"dataset-file-info-header"}>
        <DatasetFileInfoTitleSection data-testid={"dataset-file-info-section"}>
          <Text color={vantiColors.gray29} lineSize={"21.78px"} size={18} weight={500}>
            {title}
          </Text>
          {isAllowedToDownloadDataset && (
            <DownloadIcon24Styled
              onClick={() => getDatasetPresignedUrlReq.mutate(dataset.id)}
              stroke={vantiColors.darkBlue7}
            />
          )}
        </DatasetFileInfoTitleSection>

        <DatasetFileInfoMetadataSection data-testid={"dataset-file-metadata-section"}>
          {uploadedBy}
          {metadata}
          <DatasetDateRange
            datasetId={dataset.id}
            readOnly={!isAllowedToEditChatContext || isReadOnly}
            tooltipText={tooltipText}
          />
        </DatasetFileInfoMetadataSection>
      </DatasetFileInfoHeader>

      <DynamicTabsWrapper>
        <DynamicTabs
          tabProps={{ classes: { root: classes.tabRoot } }}
          tabsProps={{
            classes: {
              root: classes.tabRoot,
              indicator: classes.tabsIndicator,
              flexContainer: classes.tabsFlexContainer
            }
          }}
          tabs={Object.values(DATASET_FILE_INFO_MODAL_TABS)}
          selectedTabIndex={selectedTabIndex}
          handleChange={handleChange}
          indicatorColor={vantiColors.darkBlue7}
          data-testid={"dataset-file-metadata-section-content-tabs"}
        />
      </DynamicTabsWrapper>

      <PreviewDataTableWrapper enableScroll={tabTitleValue === DATASET_FILE_INFO_MODAL_TABS.DATASET_TAB_PREVIEW_DATA}>
        {tabContent}
      </PreviewDataTableWrapper>
    </DatasetFileInfoContainer>
  );
};

export default memo(DatasetFileInfo);
