import { makeStyles } from "@material-ui/core/styles";
import bg from "assets/img/phone_page_bg.svg";

export default makeStyles(theme => ({
  root: {
    position: "fixed",
    height: "100vh",
    width: "100%",
    background: `url(${bg}) no-repeat center center fixed`,
    backgroundSize: "cover",
    color: theme.palette.primary[2],
    fontWeight: 800,
    fontSize: "21px",
    fontFamily: "Inter"
  },
  wrapper: {
    padding: "0 60px",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    transform: "translateY(-12%)"
  },
  lottieWrapper: {
    marginTop: "-85px",
    width: "400px",
    alignSelf: "center"
  },
  mainText: {
    marginTop: "-50px",
    fontSize: "34px",
    lineHeight: "120%"
  },
  secondaryText: {
    padding: "25px 0"
  },
  logo: {
    marginLeft: "-20px"
  }
}));
