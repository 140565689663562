import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const CloseIcon16 = ({ color = "none", stroke = vantiColors.black1, ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer" }}
    {...props}
  >
    <path
      d="M4.26676 12.4359L3.56421 11.7333L7.29754 8L3.56421 4.26667L4.26676 3.56412L8.00009 7.29745L11.7334 3.56412L12.436 4.26667L8.70264 8L12.436 11.7333L11.7334 12.4359L8.00009 8.70255L4.26676 12.4359Z"
      fill={stroke}
    />
  </svg>
);

export default memo(CloseIcon16);
