import React, { memo } from "react";
import { styled } from "@material-ui/core";
import DataConnectorsIcon80 from "assets/icons/data-connectors/DataConnectorsIcon80";
import { Text } from "components/ui";
import GeneralVantiButton, { VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { vantiColors } from "assets/jss/palette";
import { useHistory } from "react-router-dom";

const ChatWindowEmptyStateWrapper = styled("div")({
  boxSizing: "border-box",
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
});

const ChatWindowEmptyStateContent = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
});

const AddDatasetTextStyled = styled(Text)({
  paddingBottom: 24
});

const DataConnectorsIcon80Styled = styled(DataConnectorsIcon80)({ paddingBottom: 16 });

const ADD_DATA_BUTTON_TEXT = "Add data";
const ADD_DATASETS_TEXT = "To start generating insights, add your first dataset";

const ChatWindowEmptyState = () => {
  const history = useHistory();

  return (
    <ChatWindowEmptyStateWrapper>
      <ChatWindowEmptyStateContent data-testid={"chat-window-empty-state-content"}>
        <DataConnectorsIcon80Styled />
        <AddDatasetTextStyled lineSize={"17px"} weight={400} color={vantiColors.gray33}>
          {ADD_DATASETS_TEXT}
        </AddDatasetTextStyled>
        <GeneralVantiButton
          variant={VARIANTS.TERTIARY}
          buttonInfo={{
            onClick: () => history.push("/dashboard/data"),
            label: ADD_DATA_BUTTON_TEXT
          }}
          style={{ width: 215 }}
        />
      </ChatWindowEmptyStateContent>
    </ChatWindowEmptyStateWrapper>
  );
};

export default memo(ChatWindowEmptyState);
