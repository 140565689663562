import { useMutation } from "react-query";
import { logger } from "utils/logger";
import { useContext } from "react";
import { AppContext } from "./context-hooks/use-app-context";

import {
  acceptDashboardInvitation,
  addDashboard,
  addDashboardItems,
  DASHBOARD_API_QUERY_KEYS,
  deleteDashboardItem,
  deleteDashboards,
  getDashboard,
  getDashboardItems,
  getDashboards,
  getUserDashboardItems,
  pinUnpinUserDashboardItems,
  shareDashboard,
  updateDashboard,
  updateDashboardItems
} from "services/api/dashboard";
import { useHistory } from "react-router-dom";

const SNACKBAR_DELETED_ITEM_TEXT = "Successfully deleted item from dashboard!";
const SNACKBAR_DELETED_DASHBOARD_TEXT = "Successfully deleted dashboard!";

const useDashboardRequest = () => {
  const { dashboardContext } = useContext(AppContext);
  const {
    dashboards,
    setDashboards,
    dashboardItems,
    setDashboardItems,
    setDashboardSnackbar,
    setIsItemDraggingInProgress,
    selectedDashboardTab,
    setSelectedDashboardTab,
    setOpenAddItemModal
  } = dashboardContext;
  const history = useHistory();

  const ctxDeleteDashboardItems = items => {
    const itemsIds = items.map(i => i._id);
    setDashboardItems(prevState => {
      const newDashboardItems = { ...prevState };
      for (const dashboardId of Object.keys(newDashboardItems)) {
        newDashboardItems[dashboardId] = newDashboardItems[dashboardId].filter(item => !itemsIds.includes(item._id));
      }
      return newDashboardItems;
    });

    setDashboards(prevState => {
      const newDashboards = { ...prevState };
      for (const dashboardId of Object.keys(dashboards)) {
        newDashboards[dashboardId].items = newDashboards[dashboardId].items.filter(
          itemId => !itemsIds.includes(itemId)
        );
      }
      return newDashboards;
    });
  };

  const addDashboardRequest = useMutation(
    [DASHBOARD_API_QUERY_KEYS.CREATE_DASHBOARD],
    async dashboard => addDashboard(dashboard),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: data => {
        if (!data) {
          return null;
        }

        setDashboards(prevState => ({ ...prevState, [data._id]: { ...data } }));
      },
      onError: error => logger.error("Failed to add a new dashboard", error)
    }
  );

  const addDashboardItemsRequest = useMutation(
    [DASHBOARD_API_QUERY_KEYS.ADD_DASHBOARD_ITEMS],
    async ({ dashboardId, items }) => addDashboardItems(dashboardId, items),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: (data, { dashboardId }) => {
        if (!data) {
          return null;
        }

        const dashboard = dashboards[dashboardId];
        if (!dashboard) {
          return null;
        }

        setDashboards(prevState => ({
          ...prevState,
          [dashboardId]: { ...dashboard, items: [...dashboard.items, ...data.map(item => item._id)] }
        }));

        const currDashboardItems = dashboardItems[dashboardId] || [];
        setDashboardItems(prevState => ({
          ...prevState,
          [dashboardId]: [...currDashboardItems, ...data]
        }));
        setOpenAddItemModal(false);
      },
      onError: error => logger.error("Failed to add a new dashboard", error)
    }
  );

  const getDashboardRequest = useMutation(
    [DASHBOARD_API_QUERY_KEYS.GET_DASHBOARD],
    async ({ dashboardId }) => getDashboard(dashboardId),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: (data, { dashboardId }) => {
        if (!data) {
          return null;
        }

        if (!dashboards[dashboardId]) {
          setDashboards(prevState => ({ ...prevState, [dashboardId]: data }));
        }
        return data;
      },
      onError: (error, { dashboardId }) => logger.error("Failed to get dashboard", { error, dashboardId })
    }
  );

  const getDashboardsRequest = useMutation([DASHBOARD_API_QUERY_KEYS.GET_DASHBOARDS], async () => getDashboards(), {
    skip: Object.keys(dashboards).length,
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    onSuccess: data => {
      if (!data) {
        return [];
      }

      const dataAsObject = {};
      for (const dashboard of data) {
        dataAsObject[dashboard._id] = dashboard;
      }
      setDashboards(prevState => ({ ...prevState, ...dataAsObject }));

      return data;
    },
    onError: error => logger.error("Failed to get all user dashboards", { error })
  });

  const updateDashboardRequest = useMutation(
    [DASHBOARD_API_QUERY_KEYS.UPDATE_DASHBOARD],
    async ({ dashboardId, updateFields }) => updateDashboard(dashboardId, updateFields),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: (data, { dashboardId }) => {
        if (!data) {
          return null;
        }

        setDashboards(prevState => ({
          ...prevState,
          [dashboardId]: data
        }));
      },
      onError: (error, { dashboardId }) => logger.error("Failed to update dashboard", { error, dashboardId })
    }
  );

  const deleteDashboardsRequest = useMutation(
    [DASHBOARD_API_QUERY_KEYS.DELETE_DASHBOARDS],
    async ({ dashboardIds }) => deleteDashboards(dashboardIds),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: data => {
        if (!data) {
          return null;
        }

        const lastBoardDeleted = Object.keys(dashboards).length === 1;

        setDashboards(prevState => {
          const dashboardsCopy = { ...prevState };
          for (const deletedDashboard of data) {
            delete dashboardsCopy[deletedDashboard._id];
          }
          return dashboardsCopy;
        });

        setDashboardItems(prevState => {
          const dashboardsItemsCopy = { ...prevState };
          for (const deletedDashboard of data) {
            delete dashboardsItemsCopy[deletedDashboard._id];
          }
          return dashboardsItemsCopy;
        });

        setSelectedDashboardTab(0);
        setDashboardSnackbar(SNACKBAR_DELETED_DASHBOARD_TEXT);

        //in case the last board was just deleted, we refetch dashboards to generate default dashboard from the server
        if (lastBoardDeleted) {
          getDashboardsRequest.mutate({});
        }
      },
      onError: (error, { dashboardIds }) => logger.error("Failed to delete dashboards", { error, dashboardIds })
    }
  );

  const deleteDashboardItemRequest = useMutation(
    [DASHBOARD_API_QUERY_KEYS.DELETE_DASHBOARDS],
    async dashboardItemId => deleteDashboardItem(dashboardItemId),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: data => {
        if (!data) {
          return null;
        }

        setDashboardSnackbar(SNACKBAR_DELETED_ITEM_TEXT);
        setDashboardItems(prevState => ({
          ...prevState,
          [data.dashboardId]: prevState[data.dashboardId]?.filter(i => i._id !== data._id)
        }));

        setDashboards(prevState => {
          const dashboard = prevState[data.dashboardId];
          return {
            ...prevState,
            [dashboard._id]: { ...dashboard, items: dashboard.items.filter(itemId => itemId !== data._id) }
          };
        });
      },
      onError: (error, { dashboardItemId }) =>
        logger.error("Failed to delete item from dashboard", { error, dashboardItemId })
    }
  );

  const getDashboardItemsRequest = useMutation(
    [DASHBOARD_API_QUERY_KEYS.GET_DASHBOARD_ITEMS],
    async ({ dashboardId }) => getDashboardItems(dashboardId),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: (data, { dashboardId }) => {
        if (!data) {
          return null;
        }

        const currDashboardItems = dashboardItems[dashboardId] || [];
        setDashboardItems(prevState => ({ ...prevState, [dashboardId]: [...currDashboardItems, ...data] }));
      },
      onError: (error, { dashboardId }) => logger.error("Failed to get dashboard items", { error, dashboardId })
    }
  );

  const updateDashboardItemsRequest = useMutation(
    [DASHBOARD_API_QUERY_KEYS.UPDATE_DASHBOARD_ITEMS],
    async ({ dashboardId, items }) => updateDashboardItems(dashboardId, items),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: (data, { dashboardId }) => {
        if (!data) {
          return null;
        }

        setDashboardItems(prevState => ({ ...prevState, [dashboardId]: [...data] }));
        setIsItemDraggingInProgress(false);
      },
      onError: (error, { dashboardId }) =>
        logger.error("Failed to update dashboard items dashboards", { error, dashboardId })
    }
  );

  const shareDashboardRequest = useMutation(
    [DASHBOARD_API_QUERY_KEYS.SHARE_DASHBOARD],
    async ({ dashboardId, userId }) => shareDashboard(dashboardId, userId),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: updatedDashboard => {
        if (!updatedDashboard) {
          return null;
        }
        setDashboards(prevState => ({ ...prevState, [updatedDashboard._id]: updatedDashboard }));
      },
      onError: (error, { dashboardId, userId }) =>
        logger.error("Failed to share dashboard", { error, dashboardId, userId })
    }
  );

  const acceptDashboardInvitationRequest = useMutation(
    [DASHBOARD_API_QUERY_KEYS.SHARE_DASHBOARD],
    async notificationId => acceptDashboardInvitation(notificationId),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: updatedDashboard => {
        if (!updatedDashboard) {
          return null;
        }
        setDashboards(prevState => ({ ...prevState, [updatedDashboard._id]: updatedDashboard }));
        history.push("/dashboard/home");
        setSelectedDashboardTab(selectedDashboardTab + 2);
      },
      onError: (error, { notificationId }) =>
        logger.error("Failed to accept dashboard invitation", { error, notificationId })
    }
  );

  const getUserDashboardItemsRequest = useMutation(
    [DASHBOARD_API_QUERY_KEYS.GET_USER_DASHBOARD_ITEMS],
    async () => getUserDashboardItems(),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: data => {
        if (!data) {
          return null;
        }

        setDashboardItems(prevState => {
          const newDashboardItems = { ...prevState };
          for (const item of data) {
            if (newDashboardItems[item.dashboardId]?.find(i => i._id === item._id)) {
              continue;
            }
            newDashboardItems[item.dashboardId] = [...(newDashboardItems[item.dashboardId] ?? []), { ...item }];
          }
          return { ...newDashboardItems };
        });
      },
      onError: error => logger.error("Failed to get user dashboard items", error)
    }
  );

  const pinUnpinUserDashboardItemsRequest = useMutation(
    [DASHBOARD_API_QUERY_KEYS.PIN_UNPIN_USER_DASHBOARD_ITEMS],
    async ({ type, metadata, pinnedDashboardIds }) => pinUnpinUserDashboardItems(type, metadata, pinnedDashboardIds),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: ({ deletedItems, newItems }) => {
        if (!deletedItems || !newItems) {
          return null;
        }

        setDashboards(prevState => {
          const newDashboards = { ...prevState };
          //delete items from dashboard.items
          for (const deletedItem of deletedItems) {
            if (!newDashboards[deletedItem.dashboardId]) continue;

            newDashboards[deletedItem.dashboardId].items = newDashboards[deletedItem.dashboardId].items.filter(
              id => id !== deletedItem._id
            );
          }
          //add new items to dashboard.items
          for (const newItem of newItems) {
            if (
              !newDashboards[newItem.dashboardId] ||
              newDashboards[newItem.dashboardId].items.find(id => id === newItem._id)
            ) {
              continue;
            }
            newDashboards[newItem.dashboardId].items.push(newItem._id);
          }
          return { ...newDashboards };
        });

        setDashboardItems(prevState => {
          const newDashboardItems = { ...prevState };
          //delete items from dashboardItems
          for (const deletedItem of deletedItems) {
            if (!newDashboardItems[deletedItem.dashboardId]) continue;

            newDashboardItems[deletedItem.dashboardId] = newDashboardItems[deletedItem.dashboardId].filter(
              item => item._id !== deletedItem._id
            );
          }
          //add items to dashboardItems
          for (const newItem of newItems) {
            if (
              !newDashboardItems[newItem.dashboardId] ||
              newDashboardItems[newItem.dashboardId].find(item => item._id === newItem._id)
            ) {
              continue;
            }
            newDashboardItems[newItem.dashboardId].push(newItem);
          }
          return { ...newDashboardItems };
        });
      },
      onError: error => logger.error("Failed to get user dashboard items", error)
    }
  );

  return {
    addDashboardRequest,
    addDashboardItemsRequest,
    getDashboardRequest,
    getDashboardsRequest,
    updateDashboardRequest,
    deleteDashboardsRequest,
    deleteDashboardItemRequest,
    getDashboardItemsRequest,
    updateDashboardItemsRequest,
    getUserDashboardItemsRequest,
    pinUnpinUserDashboardItemsRequest,
    shareDashboardRequest,
    acceptDashboardInvitationRequest,
    ctxDeleteDashboardItems
  };
};

export default useDashboardRequest;
