import React, { forwardRef, memo } from "react";
import classNames from "classnames";
import * as PropTypes from "prop-types";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";

import { makeStyles, styled } from "@material-ui/core/styles";
import { vantiColors } from "assets/jss/palette";

const StyledFormControl = styled(FormControl)(({ theme, ...props }) => {
  const styles = {
    position: "relative",
    verticalAlign: "unset"
  };

  if (props.shouldIncludeBottomPadding) {
    styles.paddingBottom = theme.spacing(1.25);
  }

  return styles;
});

const StyledInputLabel = styled(InputLabel)(({ theme, ...props }) => {
  const styles = {
    ...theme.fonts.defaultFont,
    color: `${theme.palette.vantiColors.darkBlue1} !important`,
    fontSize: "18px",
    lineHeight: "25px",
    letterSpacing: "unset",
    top: "-5px"
  };

  if (props.error) {
    styles.color = theme.palette.vantiColors.danger1;
  } else if (props.success) {
    styles.color = theme.palette.vantiColors.success1;
  }

  return styles;
});

const useStyles = makeStyles(theme => ({
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  underline: {
    "&:before": {
      borderColor: `${vantiColors.gray19} !important`,
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: `${vantiColors.darkBlue1} !important`,
      borderWidth: "1px !important"
    }
  },
  underlineError: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: `${theme.palette.vantiColors.rose6} !important`,
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: theme.palette.vantiColors.danger1
    }
  },
  underlineSuccess: {
    "&:after": {
      borderColor: theme.palette.vantiColors.success1
    }
  },
  greenUnderline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: `${theme.palette.vantiColors.gray29} !important`,
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: theme.palette.vantiColors.gray29
    }
  },
  darkGreenUnderline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: `${theme.palette.vantiColors.gray29} !important`,
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: theme.palette.vantiColors.gray29
    }
  },
  dangerHelperText: {
    ...theme.fonts.defaultFont,
    marginTop: theme.spacing(1.5),
    fontSize: "10px",
    color: theme.palette.vantiColors.rose6
  },
  neutralHelperText: {
    ...theme.fonts.defaultFont,
    marginTop: theme.spacing(1.5),
    fontSize: "10px",
    color: theme.palette.vantiColors.darkBlue1
  },
  successHelperText: {
    marginTop: theme.spacing(1.5),
    fontSize: "10px",
    color: theme.palette.vantiColors.success1
  },
  labelRoot: {
    ...theme.fonts.defaultFont,
    color: `${theme.palette.vantiColors.darkBlue1} !important`,
    fontSize: "18px",
    lineHeight: "25px",
    letterSpacing: "unset",
    top: "-5px"
  },
  labelRootError: {
    color: theme.palette.vantiColors.danger1
  },
  labelRootSuccess: {
    color: theme.palette.vantiColors.success1
  },
  feedback: {
    position: "absolute",
    top: "18px",
    right: "0",
    zIndex: "2",
    display: "block",
    width: "24px",
    height: "24px",
    textAlign: "center",
    pointerEvents: "none"
  },
  marginTop: {
    marginTop: theme.spacing(1.8)
  },
  input: {
    ...theme.fonts.defaultFont
  },
  inputWithSpacing: {
    paddingTop: theme.spacing(1.25)
  },
  formControl: {
    position: "relative",
    verticalAlign: "unset"
  },
  helperTextLeft: {
    textAlign: "left"
  },
  formControlBottomPadding: {
    paddingBottom: theme.spacing(1.25)
  }
}));

const TextUnderlinedInput = (props, ref) => {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    neutral,
    color,
    helperText,
    FormHelperTextProps,
    leftSideHelperText,
    shouldIncludeSpacing = true,
    shouldIncludeBottomPadding = true,
    dataTestId,
    autoFocus = false
  } = props;

  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

  return (
    <StyledFormControl
      shouldIncludeBottomPadding={shouldIncludeBottomPadding}
      {...formControlProps}
      className={formControlProps?.className}
    >
      {labelText !== undefined && labelText !== "" ? (
        <StyledInputLabel data-testid={`label-${id}`} htmlFor={id} error={error} success={success} {...labelProps}>
          {labelText}
        </StyledInputLabel>
      ) : null}
      <Input
        data-testid={`input-${id}`}
        classes={{
          root: classNames({
            [classes.marginTop]: shouldIncludeSpacing && labelText === undefined
          }),
          input: classNames({
            [classes.input]: true,
            [classes.inputWithSpacing]: shouldIncludeSpacing
          }),
          disabled: classes.disabled,
          underline: classNames(classes.underline, {
            [classes.underlineError]: error,
            [classes.underlineSuccess]: success && !error,
            [classes[color + "Underline"]]: color
          })
        }}
        autoFocus={autoFocus}
        inputProps={{ "data-testid": `${dataTestId}` }}
        inputRef={ref}
        {...inputProps}
        id={id}
      />
      {helperText && (
        <FormHelperText
          id={helperTextId}
          data-testid={helperTextId}
          className={classNames({
            [classes.helperTextLeft]: leftSideHelperText,
            [classes.dangerHelperText]: error,
            [classes.successHelperText]: success,
            [classes.neutralHelperText]: neutral
          })}
          {...FormHelperTextProps}
        >
          {helperText}
        </FormHelperText>
      )}
    </StyledFormControl>
  );
};

export default memo(forwardRef(TextUnderlinedInput));

TextUnderlinedInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  color: PropTypes.string,
  helperText: PropTypes.string,
  FormHelperTextProps: PropTypes.object
};
