import React, { memo, useCallback, useRef, useState } from "react";
import { DarkToolTip } from "components/ui/tooltips/tooltip";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import AddImageIcon from "assets/icons/AddImageIcon";
import { vantiColors } from "assets/jss/palette";
import usePermissions from "common/hooks/use-permissions";
import { ROLE } from "@vanti-analytics-org/vanti-common";
import { logger } from "utils/logger";
import GeneralModalPreviewComponent from "components/ui/Modal/GeneralModalPreviewComponent";
import {
  DATASTE_FILE_INFO_MODAL_HEIGHT,
  DATASTE_FILE_INFO_MODAL_WIDTH
} from "pages/side-bar/chat/components/DatasetFileInfoModal";
import ChatMessageReport from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReport";
import useChatMessages from "common/hooks/use-chat-messages";

export const STATIC_REPORT_CHAT_ID = "STATIC_REPORT_CHAT_ID";

const initialReportMessage = {
  reportData: {
    question: "Question/headline goes here",
    description: "Description goes here",
    datasetId: "DATASET_ID",
    generatedByVanti: true,
    base64Img: "",
    resultSummary: "Result summary goes here"
  }
};
const StaticReportUploader = () => {
  const inputFile = useRef(null);
  const isAllowedToAddStaticReports = usePermissions([ROLE.EDIT_DATASET_QUESTIONS]);
  const [staticReportPreview, setStaticReportPreview] = useState(null);
  const [staticReportMessage, setStaticReportMessage] = useState(initialReportMessage);
  const { saveStaticReportRequest } = useChatMessages();

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      logger.error("Failed to upload and convert file to b64", error);
    };
  };
  const onStaticReportUploaded = event => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      getBase64(file, result => {
        setStaticReportMessage(prevState => ({
          ...prevState,
          reportData: {
            ...prevState.reportData,
            base64Img: result.substring(result.indexOf(",") + 1)
          }
        }));
        setStaticReportPreview(true);
      });
    }
    event.target.value = ""; //reset file
  };

  const onUpdateField = useCallback(field => {
    const key = Object.keys(field).pop();
    const value = Object.values(field).pop();
    if (!key || !value) {
      logger.error("Static report - key or value is invalid", field);
      return;
    }
    setStaticReportMessage(prevState => ({
      ...prevState,
      reportData: {
        ...prevState.reportData,
        [key]: value
      }
    }));
  }, []);

  const onSummaryUpdated = useCallback(
    summary => {
      onUpdateField({ resultSummary: summary.description });
    },
    [onUpdateField]
  );

  const generateStaticReportButtonsGroup = useCallback(() => {
    return (
      <GeneralVantiButton
        variant={VARIANTS.SECONDARY}
        size={SIZES.SMALL}
        buttonInfo={{
          onClick: () => {
            saveStaticReportRequest.mutate(staticReportMessage);
            setStaticReportPreview(false);
          },
          label: "Save"
        }}
      />
    );
  }, [staticReportMessage]);

  const staticReportCallbacks = {
    onSummaryUpdated,
    generateStaticReportButtonsGroup,
    onUpdateField
  };

  return (
    <>
      <DarkToolTip title={"Upload static report as an image"}>
        {isAllowedToAddStaticReports && (
          <GeneralVantiButton
            variant={VARIANTS.SECONDARY}
            style={{ height: 40, width: 15 }}
            buttonInfo={{
              label: <AddImageIcon width={24} height={24} stroke={vantiColors.darkBlue7} />,
              onClick: () => {
                inputFile.current.click();
              }
            }}
          />
        )}
      </DarkToolTip>
      <input type="file" id="file" ref={inputFile} style={{ display: "none" }} onChange={onStaticReportUploaded} />
      {staticReportPreview && (
        <GeneralModalPreviewComponent
          height={DATASTE_FILE_INFO_MODAL_HEIGHT}
          width={DATASTE_FILE_INFO_MODAL_WIDTH}
          open={staticReportPreview}
          onClose={() => setStaticReportPreview(null)}
          padding={"16px 4px 16px 16px"}
        >
          <ChatMessageReport message={staticReportMessage} staticReportCallbacks={staticReportCallbacks} />
        </GeneralModalPreviewComponent>
      )}
    </>
  );
};

export default memo(StaticReportUploader);
