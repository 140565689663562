import React, { memo, useEffect } from "react";
import { Button } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import { Text } from "components/ui";
import CustomDialog from "components/ui/Dialog/CustomDialog";
import LoadingOverlay from "components/ui/loading-overlay";
import { removeCuration } from "modules/data-curations/actions";
import { useDispatch } from "react-redux";
import { deleteCuration } from "services/api/dataCuration";
import { API_QUERIES, DELETE_DATA_CURATION_DIALOG } from "../../../utils";
import { useQuery } from "react-query";

const DeleteCurationDialog = ({ setIsShowDeleteDialog, currentCuration }) => {
  const dispatch = useDispatch();

  const deleteCurationReq = useQuery(API_QUERIES.keys.deleteCuration, () => deleteCuration(currentCuration._id), {
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false
  });

  useEffect(() => {
    if (deleteCurationReq.data?.ok) {
      dispatch(removeCuration(currentCuration._id));
      setIsShowDeleteDialog(false);
    }
  }, [deleteCurationReq]);

  return (
    <CustomDialog
      isOpened={true}
      title={DELETE_DATA_CURATION_DIALOG.title}
      body={
        deleteCurationReq.isError ? deleteCurationReq.error : DELETE_DATA_CURATION_DIALOG.body(currentCuration.name)
      }
    >
      <Button
        onClick={() => {
          setIsShowDeleteDialog(false);
        }}
      >
        <Text color={vantiColors.gray23}>Cancel</Text>
      </Button>
      <Button
        onClick={() => {
          deleteCurationReq.refetch(currentCuration._id);
        }}
      >
        <Text color={vantiColors.red5}>Delete</Text>
      </Button>

      {deleteCurationReq.isLoading && <LoadingOverlay size={"70%"} />}
    </CustomDialog>
  );
};

export default memo(DeleteCurationDialog);
