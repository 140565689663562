import * as FullStory from "@fullstory/browser";
import config from "../config";

export function init(email) {
  if (!email?.includes("@vanti.ai") && config.env !== "staging") {
    FullStory.init({
      orgId: config.fullStory.orgId
    });
  }
}

export function setIdentity(userId, userName, userEmail) {
  if (!userEmail?.includes("@vanti.ai") && config.env !== "staging") {
    FullStory.identify(userId, {
      email: userEmail,
      displayName: userName || ""
    });
  }
}

export function setCustomEvent(eventName, eventProps) {
  if (FullStory.isInitialized()) {
    FullStory.event(eventName, eventProps);
  }
}
