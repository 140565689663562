import { createSelector } from "reselect";

const deleteProduct = state => state.station?.operations?.deleteProduct;

export const deleteProductLoadingSelector = productId =>
  createSelector(
    deleteProduct,
    state => state.loading[productId]
  );

export const deleteProductErrorSelector = productId =>
  createSelector(
    deleteProduct,
    state => state.error[productId]
  );
export const deleteProductSuccessSelector = productId =>
  createSelector(
    deleteProduct,
    state => state.success[productId]
  );
