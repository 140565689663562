import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import userUpdateWatcher from "./operations/userUpdate/saga";
import userUpdateReducer from "./operations/userUpdate/reducer";
import fetchUserWatcher from "./operations/fetchUser/saga";
import fetchUserReducer from "./operations/fetchUser/reducer";
import fetchUserRolesWatcher from "./operations/fetchUserRoles/saga";
import fetchUserRolesReducer from "./operations/fetchUserRoles/reducer";
import userStateReducer from "./state/reducer";

export default combineReducers({
  state: userStateReducer,
  operations: combineReducers({
    fetchUser: fetchUserReducer,
    fetchUserRoles: fetchUserRolesReducer,
    userUpdate: userUpdateReducer
  })
});

export function* sagas() {
  yield all([fetchUserWatcher(), fetchUserRolesWatcher(), userUpdateWatcher()]);
}
