import React, { memo } from "react";
import { vantiColors } from "../jss/palette";

const DividerIcon = ({ width = 1, height = 20, stroke = vantiColors.gray36 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 1 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.5" y1="20" x2="0.499999" y2="2.18557e-08" stroke={stroke} />
    </svg>
  );
};

export default memo(DividerIcon);
