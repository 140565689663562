import React, { useEffect, useState } from "react";
import * as adminSandboxServices from "services/admin/sandbox";
import Card from "components/ui/Card/Card";
import CardBody from "components/ui/Card/CardBody";
import FlexContainer from "components/ui/Flex/FlexContainer";
import FlexItem from "components/ui/Flex/FlexItem";
import Text from "components/ui/Typography/Text";
import BrandedTitle from "components/ui/Typography/BrandedTitle";
import Button from "components/ui/Buttons/Button";
import { useHistory } from "react-router-dom";
import { ReactComponent as BackButtonIcon } from "../../../assets/img/back-button.svg";

export default function SandboxList() {
  const [blocks, setBlocks] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    adminSandboxServices
      .querySandboxes()
      .then(setBlocks)
      .catch(setFetchError);
  }, []);

  return (
    <>
      <FlexContainer alignItems={"center"} direction="column">
        <FlexItem xs={12} sm={10} md={8} container alignItems="center" justifyContent="flex-start">
          <div style={{ display: "flex" }}>
            <Button transparent={true} style={{ padding: "0 10px 0 0" }} onClick={() => history.push("/admin")}>
              <BackButtonIcon />
            </Button>
            <BrandedTitle text="Sandbox" size="30px" />
          </div>
        </FlexItem>
        {fetchError && (
          <FlexContainer justifyContent="center" alignItems="center">
            <FlexItem xs={12} sm={8} md={8} container direction="column">
              <Text color="danger">{fetchError.message}</Text>
            </FlexItem>
          </FlexContainer>
        )}
        {blocks.map(block => (
          <FlexItem xs={12} sm={10} md={8} container key={block.id}>
            <Card>
              <CardBody>
                <FlexContainer justifyContent="center" alignItems="center" key={block.id}>
                  <FlexItem xs={12} sm={8} md={8} container direction="column">
                    <Text color="darkBlue" size="16px" weight="bold">
                      {block.title}
                    </Text>

                    <Text color="darkBlue" size="14px">
                      Data Source:{" "}
                      <Text color="danger" size="14px">
                        {block.metadata && block.metadata.dataSource}
                      </Text>
                    </Text>
                  </FlexItem>
                  <FlexItem xs={12} sm={8} md={4} container justifyContent="flex-end" alignItems="center">
                    <Button color="lightGrayBlue" onClick={() => history.push(`/admin/sandbox/${block.id}/edit`)}>
                      Edit
                    </Button>
                  </FlexItem>
                </FlexContainer>
              </CardBody>
            </Card>
          </FlexItem>
        ))}
      </FlexContainer>
    </>
  );
}
