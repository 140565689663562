/* eslint-disable no-case-declarations */
import { getMergeCodePlaceholder } from "pages/side-bar/data-curation/utils";
import { NEW_CURATION_ACTION_TYPES, USE_SINGLE_DATA_CURATION_ACTION_TYPES } from "./actionTypes";
import { curationMock } from "../../../../../../tests/mock-files/curation.mock";

export const NEW_CURATION_DATA_INITIAL_STORE = () => ({
  name: "",
  code: getMergeCodePlaceholder(),
  mergeCode: getMergeCodePlaceholder()
});

export const NEW_CURATION_DATA_REDUCER = (state, action) => {
  switch (action.type) {
    case NEW_CURATION_ACTION_TYPES.SET_NAME:
      return { ...state, name: action.payload };

    case NEW_CURATION_ACTION_TYPES.SET_CURATION_CODE:
      return { ...state, code: action.payload };

    case NEW_CURATION_ACTION_TYPES.SET_MERGE_CODE:
      return { ...state, mergeCode: action.payload };

    default:
      return state;
  }
};

export const USE_SINGLE_DATA_CURATION_INITIAL_STATE = () => ({
  curation: null,
  curationJob: null,
  downloadUrl: null,
  activeSectionIndex: 1,
  existingCurationError: null,
  newCurationError: null,
  fileUploadProgressPercentage: null,
  nextLocationProps: {},
  isSafeToLeave: false,
  activeFileTab: null,
  presignedUrls: {},
  isMergeLoading: false,
  isCurationLoading: false,
  mergeError: null,
  mergeCode: "",
  mergeJob: null,
  curationRunError: null,
  files: [],
  fileChangedAction: {
    action: null,
    payload: null
  },
  shouldNotifyAboutOriginalFilesChange: true
});

export const USE_SINGLE_DATA_CURATION_REDUCER = (state, action) => {
  switch (action.type) {
    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_CURATION:
      return { ...state, curation: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_CURATION_JOB:
      return { ...state, curationJob: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_DOWNLOAD_URL:
      return { ...state, downloadUrl: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_ACTIVE_SECTION_INDEX:
      return { ...state, activeSectionIndex: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_EXISTING_CURATION_ERROR:
      return { ...state, existingCurationError: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_NEW_CURATION_ERROR:
      return { ...state, newCurationError: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_NEXT_LOCATION_PROPS:
      return { ...state, nextLocationProps: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_IS_SAFE_TO_LEAVE:
      return { ...state, isSafeToLeave: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_ACTIVE_FILE_TAB:
      return { ...state, activeFileTab: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_PRESIGNED_URLS:
      return { ...state, presignedUrls: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_IS_MERGE_LOADING:
      return { ...state, isMergeLoading: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_MERGE_ERROR:
      return { ...state, mergeError: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_MERGE_CODE:
      return { ...state, mergeCode: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_MERGE_JOB:
      return { ...state, mergeJob: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_IS_CURATION_LOADING:
      return { ...state, isCurationLoading: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_CURATION_RUN_ERROR:
      return { ...state, curationRunError: action.payload };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_FILES:
      const files = [...state.files];
      files.push(action.payload);

      return { ...state, files: [...files] };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.REMOVE_FILE:
      const fileId = action.payload;
      const allFiles = [...state.files];
      const fileToRemoveIndex = allFiles.findIndex(file => file.id === fileId);

      if (fileToRemoveIndex > -1) {
        allFiles.splice(fileToRemoveIndex, 1);
      }
      return { ...state, files: [...allFiles] };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_CHANGED_FILE_ACTION:
      const data = action.payload;
      return { ...state, fileChangedAction: { action: data.action, payload: data.payload } };

    case USE_SINGLE_DATA_CURATION_ACTION_TYPES.SHOULD_NOTIFY_ABOUT_ORIGINAL_FILES_CHANGE:
      return { ...state, shouldNotifyAboutOriginalFilesChange: action.payload };

    default:
      return state;
  }
};
