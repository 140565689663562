import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important"
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: `${theme.palette.vantiColors.darkBlue1} !important`,
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: theme.palette.vantiColors.darkBlue1,
      borderWidth: "1px !important"
    }
  },
  helpLabel: {
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(2),
    color: theme.palette.vantiColors.rose6
  },
  label: {
    ...theme.fonts.defaultFont,
    labelHeight: "20px",
    width: "fit-content",
    position: "relative",
    whiteSpace: "nowrap",
    color: `${theme.palette.vantiColors.darkBlue1} !important`,
    fontSize: "16px"
  },
  marginTop: {
    marginTop: theme.spacing(1)
  },
  input: {
    ...theme.fonts.defaultFont,
    minHeight: "24px",
    padding: theme.spacing(1, 2),
    fontSize: "17px",
    fontWeight: 400,
    color: "#BCBFC2",
    "&::before": {
      display: "none"
    },
    "&:focus": {
      color: "#394253"
    }
  },
  formControl: {
    position: "relative"
  },
  inputWrapper: {
    backgroundColor: theme.palette.vantiColors.lightBlue5,
    borderRadius: "4px",
    "&::before": {
      display: "none"
    },
    marginTop: `${theme.spacing(0.25)}px !important`
  },
  required: {
    color: theme.palette.vantiColors.rose6
  }
}));
