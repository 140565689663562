import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FlexItem } from "components/ui";
import { styled, Switch } from "@material-ui/core";
import ChatWindow from "./components/ChatWindow";
import { TreeViewContext } from "common/hooks/context-hooks/use-treeview-context";
import { CHAT_LOADING_MESSAGE_ID } from "./constants";
import { startChat, deleteChat, updateChat } from "services/api/chat";
import { ChatMessageSenderType, ChatMessageType, ChatState } from "@vanti-analytics-org/vanti-common";
import dayjs from "dayjs";
import GeneralVantiButton from "components/ui/Buttons/GeneralVantiButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { logger } from "utils/logger";
import { useNotifications } from "common/hooks/use-notification";
import { accountIdSelector } from "modules/account/state/selectors";
import { useDispatch, useSelector } from "react-redux";
import { listenToEvent, stopListeningToEvent } from "utils/socket-utils";
import ReportIcon16 from "assets/icons/chat/ReportIcon16.js";
import TrashIcon from "assets/icons/trash-icon/TrashIcon16";
import PencilIcon16In24 from "assets/icons/pencil-icon/PencilIcon16In24";
import TextUnderlinedInput from "components/ui/Inputs/TextUnderlinedInput";
import ChatDeleteDialog from "./components/ChatDeleteDialog";
import { updateReportInChat } from "services/api/chat";
import { navigationSetBreadcrumbsType } from "modules/navigation/actions";
import { BREADCRUMBS_TYPES } from "common/constants/NavigationConstants";
import GeneralModalPreviewComponent from "components/ui/Modal/GeneralModalPreviewComponent";
import ChatPageDatasetSelectionModal from "./components/ChatPageDatasetSelectionModal";
import usePermissions from "common/hooks/use-permissions";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import { useHistory, useLocation } from "react-router-dom";
import ChatWindowEmptyState from "./components/ChatWindowEmptyState";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { DarkToolTip } from "components/ui/tooltips/tooltip";
import capitalize from "lodash/capitalize";
import { asDisposableChatId, isDisposableChat } from "utils/chat/chat-utils";
import useChatRequest from "common/hooks/use-chat-request";
import useChatMessages from "common/hooks/use-chat-messages";
import RedirectionPlaceholder from "./components/RedirectionPlaceholder";
import useAccountUserNamesRequest from "common/hooks/use-account-user-names-request";
import { useParams } from "react-router-dom";

const SOCKET_EVENTS = {
  CHAT_UPDATE: "chat/update",
  CHAT_MESSAGE_CREATE: "chat-message/create",
  CHAT_MESSAGE_UPDATE: "chat-message/update",
  CHAT_MESSAGE_DELETE: "chat-message/delete"
};

const CHAT_DATE_FOLDERS_TITLES = {
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  LAST_7_DAYS: "Last 7 days",
  LAST_30_DAYS: "Last 30 days"
};

const FullFlexStyled = styled(FlexItem)({
  flex: 1
});

const ChatPageWindowContainer = styled("div")({
  backgroundColor: "white",
  flex: 1
});

const CHAT_LOADING_MESSAGE_PROCESSING_TEXT_CONTENT = "Processing dataset";
const CHAT_LOADING_MESSAGE_DEFAULT_TEXT_CONTENT = "";
const NEW_CHAT_BUTTON_TEXT = "New chat";
const NEW_CHAT_BUTTON_DISABLED_TOOLTIP_TEXT = "To start a new chat, add your first dataset";
const CHAT_LINK_FLOW_REDIRECTION_TEXT = "You are being redirected to the chat";
const CHAT_LINK_FLOW_REDIRECTION_TIMEOUT = 3000;

const ChatPage = () => {
  useAccountUserNamesRequest();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const prevLocation = useLocation().pathname;
  const urlParams = useParams();
  const { setIsTreeViewOpen, setTreeViewContent, treeViewSearchValue } = useContext(TreeViewContext);
  const { sendChatMessage, addMessageToChatsHistory } = useChatMessages();
  const { datasetsContext, chatContext } = useContext(AppContext);
  const { datasets } = datasetsContext;
  const {
    chatSessions,
    setChatSessions,
    setChatInputEnabled,
    displayOtherDatasets,
    setDisplayOtherDatasets,
    lastSystemMessageWithInlineButtonsRef,
    chatList,
    setChatList,
    setChatsWithDisabledDatasetSelection,
    selectedChatId,
    setSelectedChatId,
    lastMessageInCurrentChat,
    setLastMessageInCurrentChat,
    setIsChatLoading,
    firstItemIndex
  } = chatContext;

  const { chatListRequest, chatHistoryRequest, setTopHistoryBound } = useChatRequest();
  const [isAddNewChatButtonHovered, setIsAddNewChatButtonHovered] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [currentChat, setCurrentChat] = useState(null);
  const isPermittedToStartChat = usePermissions([ROLES_ENUM.START_NEW_CHAT]);
  const isPermittedToEditReports = usePermissions([ROLES_ENUM.EDIT_CHAT_REPORTS]);
  const isPermittedToDeleteChat = usePermissions([ROLES_ENUM.DELETE_CHAT]);
  const [performedExitCleanup, setPerformedExitCleanup] = useState(null);

  const [startingNewChat, setStartingNewChat] = useState(false);

  const isInEmptyState = useMemo(() => !Object.keys(datasets).length, [datasets]);

  const NewChatButton = useMemo(() => {
    return (
      <DarkToolTip
        title={NEW_CHAT_BUTTON_DISABLED_TOOLTIP_TEXT}
        data-testid={"new-chat-empty-state-button-tooltip"}
        placement="top"
        open={isInEmptyState && isAddNewChatButtonHovered}
        arrow
      >
        <GeneralVantiButton
          size="small"
          variant="primary"
          disableHoverListener
          onMouseEnter={() => setIsAddNewChatButtonHovered(true)}
          onMouseLeave={() => setIsAddNewChatButtonHovered(false)}
        />
      </DarkToolTip>
    );
  }, [isInEmptyState, isAddNewChatButtonHovered]);

  useEffect(() => {
    if (urlParams.chatId) {
      setSelectedChatId(urlParams.chatId);
    }
    chatListRequest.refetch();
  }, []);

  const deleteDisposableChats = useCallback(() => {
    setChatList(prev =>
      prev.filter(chat => {
        return !isDisposableChat(chat._id);
      })
    );
    setChatSessions(prev => {
      for (const chatSessionKey of Object.keys(prev)) {
        if (isDisposableChat(chatSessionKey)) {
          delete prev[chatSessionKey];
        }
      }
      return prev;
    });
  }, []);

  useEffect(() => {
    if (selectedChatId && !isDisposableChat(selectedChatId)) {
      deleteDisposableChats();
    }
  }, [selectedChatId]);

  useEffect(() => {
    const unlisten = history.listen(location => {
      if (
        !performedExitCleanup &&
        location.pathname !== prevLocation &&
        !isDisposableChat(location.pathname.split("/").at(-1))
      ) {
        setPerformedExitCleanup(true);
        deleteDisposableChats();
      }
    });

    return () => {
      unlisten();
      setSelectedChatId(null);
    };
  }, [performedExitCleanup, deleteDisposableChats]);

  useEffect(() => {
    if (!chatListRequest.isFetched) return;

    let currentChat = chatList.find(chat => chat?._id === selectedChatId);

    if (currentChat) {
      setCurrentChat(currentChat);
      setSelectedChatId(currentChat._id);
      history.push(`/dashboard/chat/${currentChat._id}`);
    } else {
      setCurrentChat(null);
      history.push(`/dashboard/chat`);
    }
  }, [selectedChatId, chatList]);

  const [targetMessageIdForScrolling, setTargetMessageIdForScrolling] = useState(null);

  const [editingChat, setEditingChat] = useState(null);
  const [editingReport, setEditingReport] = useState(null);
  const [deletingChat, setDeletingChat] = useState(null);

  const chatEditInputRef = useRef(null);

  //We invoke this hook to make sure web sockets (which is implemented ugly now)
  //Is authorized and joined the correct room for account chat messages
  const accountId = useSelector(accountIdSelector);
  useNotifications(accountId);

  const onUpdateMessage = useCallback(
    message => {
      if (!currentChat) {
        return;
      }
      const chatSessionsContextCopy = { ...chatSessions };

      const currentChatMessages = chatSessionsContextCopy[currentChat._id];
      if (!currentChatMessages) return;

      const foundMessageIndex = currentChatMessages.findIndex(chatMessage => chatMessage._id === message._id);
      if (foundMessageIndex === -1) return;

      currentChatMessages[foundMessageIndex] = message;

      setChatSessions(prevState => ({
        ...prevState,
        [currentChat._id]: currentChatMessages
      }));
    },
    [chatSessions, currentChat]
  );

  const selectedChatHistory = useMemo(() => {
    if (!selectedChatId || !chatSessions[selectedChatId]) return [];
    return chatSessions[selectedChatId];
  }, [chatSessions, selectedChatId]);

  const chatLoadingMessage = useMemo(() => {
    const textContent =
      currentChat?.state === ChatState.INIT || currentChat?.state === ChatState.IDLE
        ? CHAT_LOADING_MESSAGE_PROCESSING_TEXT_CONTENT
        : CHAT_LOADING_MESSAGE_DEFAULT_TEXT_CONTENT;

    return {
      textContent,
      _id: CHAT_LOADING_MESSAGE_ID,
      chatId: selectedChatId,
      type: ChatMessageType.UNIQUE_LOADING,
      senderType: ChatMessageSenderType.SYSTEM
    };
  }, [selectedChatId, currentChat?.state]);

  useEffect(() => {
    setLastMessageInCurrentChat(
      selectedChatHistory?.length ? selectedChatHistory[selectedChatHistory.length - 1] : null
    );
  }, [selectedChatHistory]);

  useEffect(() => {
    setChatInputEnabled(lastMessageInCurrentChat?.type !== ChatMessageType.UNIQUE_LOADING);
  }, [lastMessageInCurrentChat]);

  const startNewChat = async (text, datasetId = null, action = null) => {
    if (startingNewChat) return;

    setStartingNewChat(true);

    try {
      const result = await startChat(text, datasetId, action);

      chatListRequest.refetch();
      const { chat } = result;

      //initial and next chat messages are received from Websocket

      setSelectedChatId(chat._id);
    } catch (error) {
      logger.error("Failed to start a new chat.", error); //TODO: handle error
    }

    setStartingNewChat(false);
  };

  const disableDatasetSelectionForChat = useCallback(() => {
    setChatsWithDisabledDatasetSelection(prevState => ({ ...prevState, [currentChat._id]: true }));
  }, [currentChat?._id]);

  useEffect(() => {
    if (currentChat?.state === ChatState.DATASET_SELECTION || !selectedChatId) return;

    if (lastMessageInCurrentChat?.senderType === ChatMessageSenderType.USER) {
      addMessageToChatsHistory(chatLoadingMessage);
    }
  }, [
    selectedChatId,
    currentChat?.state,
    lastMessageInCurrentChat?.senderType,
    chatLoadingMessage,
    addMessageToChatsHistory
  ]);

  useEffect(() => {
    if (currentChat?.state && currentChat?.state !== ChatState.DATASET_SELECTION) disableDatasetSelectionForChat();
  }, [currentChat?.state, disableDatasetSelectionForChat]);

  const deleteMessageFromChatsHistory = useCallback(
    (deletedMsg, shouldFadeOutLastMessage = false) => {
      if (shouldFadeOutLastMessage && lastSystemMessageWithInlineButtonsRef.current) {
        lastSystemMessageWithInlineButtonsRef.current.setIsFadingOut(true);
      }

      const oldChatHistory = chatSessions[(deletedMsg?.chatId)] || [];

      setChatSessions(prevState => ({
        ...prevState,
        [deletedMsg.chatId]: oldChatHistory.filter(msg => msg._id !== deletedMsg._id)
      }));
    },
    [lastSystemMessageWithInlineButtonsRef.current, chatSessions]
  );

  const onSendChatMessage = useCallback(
    async text => {
      if (!selectedChatId) {
        await startNewChat(text);
      } else {
        await sendChatMessage(text);
      }
    },
    [selectedChatId, startNewChat, sendChatMessage]
  );

  const onChatEdited = useCallback(
    chat => {
      const allChatsExceptEdited = [...chatList.filter(chatItem => chatItem._id !== chat._id)];

      const newChatList = [...allChatsExceptEdited, chat];

      newChatList.sort((a, b) => {
        return dayjs(b.lastMessageAt).diff(dayjs(a.lastMessageAt));
      });

      setChatList(newChatList);
    },
    [chatList]
  );

  useEffect(() => {
    listenToEvent(SOCKET_EVENTS.CHAT_UPDATE, event => {
      onChatEdited(event.resource.data);
    });
    listenToEvent(SOCKET_EVENTS.CHAT_MESSAGE_CREATE, event => {
      addMessageToChatsHistory(event.resource.data);
    });
    listenToEvent(SOCKET_EVENTS.CHAT_MESSAGE_UPDATE, event => {
      onUpdateMessage(event.resource.data);
    });
    listenToEvent(SOCKET_EVENTS.CHAT_MESSAGE_DELETE, event => {
      deleteMessageFromChatsHistory(event.resource.data);
    });

    return () => {
      stopListeningToEvent(SOCKET_EVENTS.CHAT_UPDATE);
      stopListeningToEvent(SOCKET_EVENTS.CHAT_MESSAGE_CREATE);
      stopListeningToEvent(SOCKET_EVENTS.CHAT_MESSAGE_UPDATE);
      stopListeningToEvent(SOCKET_EVENTS.CHAT_MESSAGE_DELETE);
    };
  }, [onChatEdited, deleteMessageFromChatsHistory, addMessageToChatsHistory]);

  useEffect(() => {
    if (currentChat && !isDisposableChat(currentChat._id)) {
      chatHistoryRequest.refetch();
    }
  }, [currentChat]);

  useEffect(() => {
    if (editingChat || editingReport) {
      setTimeout(() => {
        if (chatEditInputRef.current) chatEditInputRef.current.focus();
      }, 350);
    }
  }, [editingChat, editingReport, chatEditInputRef.current]);

  const loadOlderMessagesAfterScroll = async () => {
    if (currentChat && !isDisposableChat(currentChat._id)) {
      setTopHistoryBound(new Date(selectedChatHistory[0].createdAt).valueOf());
      chatHistoryRequest.refetch();
    }
  };

  const ChatEditTreeViewItem = (
    <TextUnderlinedInput
      ref={chatEditInputRef}
      shouldIncludeSpacing={false}
      FormHelperTextProps={{ style: { fontSize: "12px", padding: 0 } }}
      formControlProps={{ style: { paddingTop: "0", paddingBottom: "0", fontSize: "14px" } }}
      inputProps={{
        value: editingReport?.title || editingChat?.title,
        onChange: e => {
          if (editingChat) {
            setEditingChat({
              ...editingChat,
              title: e.target.value
            });
          } else if (editingReport) {
            setEditingReport({
              ...editingReport,
              title: e.target.value
            });
          }
        },
        onKeyPress: e => {
          if (e.key === "Enter") {
            if (editingChat) {
              updateEditedChat();
            } else if (editingReport) {
              updateEditedReport();
            }
          }
        },
        onBlur: e => {
          if (editingChat) {
            updateEditedChat();
          } else if (editingReport) {
            updateEditedReport();
          }
        },
        style: { marginTop: 0, paddingTop: "4px", paddingBottom: "2px", fontSize: "14px" }
      }}
    />
  );

  const updateEditedChat = useCallback(async () => {
    const updatedChat = await updateChat(editingChat._id, editingChat);

    onChatEdited(updatedChat);
    setEditingChat(null);
  }, [editingChat, chatList]);

  const updateEditedReport = useCallback(async () => {
    const updatedChat = await updateReportInChat(editingReport.chatId, editingReport._id, editingReport);

    onChatEdited(updatedChat);
    setEditingReport(null);
  }, [editingReport, chatList]);

  const confirmChatDeletion = useCallback(async () => {
    setChatList(chatList.filter(chatItem => chatItem._id !== deletingChat._id));
    await deleteChat(deletingChat._id);

    if (selectedChatId === deletingChat._id) {
      setSelectedChatId(null);
      setTargetMessageIdForScrolling(null);
    }

    setDeletingChat(null);
  }, [deletingChat, chatList]);

  const deletionDialogTargetType = useMemo(() => {
    return deletingChat ? "chat" : null;
  }, [deletingChat]);

  const confirmDeletion = () => {
    if (deletingChat) {
      confirmChatDeletion();
    }
  };

  //TODO: how we can split this large UseEffect?
  useEffect(() => {
    //Helper function to make a tree item out of chat instance
    const buildTreeNodeFromChat = (chat, index) => {
      const isThisChatBeingEdited = editingChat?._id === chat._id;

      return {
        id: "chat-" + chat._id,
        name: isThisChatBeingEdited ? ChatEditTreeViewItem : capitalize(chat.title) || `Chat ${index + 1}`,
        hideChildrenCount: true,
        disableTooltip: isThisChatBeingEdited,
        shouldHighlight: chat._id === selectedChatId,
        onLabelClick: () => {
          setSelectedReportId(null);
          if (selectedChatId != chat._id) setIsChatLoading(true);
          setTopHistoryBound(Date.now());
          setSelectedChatId(chat._id);
          setTargetMessageIdForScrolling(chat.lastMessageId);
        },
        children: isThisChatBeingEdited
          ? []
          : chat.reports.map(chatReport => ({
              id: "chat-" + chat._id + "-report-" + chatReport._id,
              name: editingReport?._id === chatReport._id ? ChatEditTreeViewItem : capitalize(chatReport.title),
              hideChildrenCount: true,
              icon: <ReportIcon16 />,
              visualNestingLevel: 1,
              shouldHighlight: chatReport._id === selectedReportId,
              disableTooltip: editingReport?._id === chatReport._id,
              onLabelClick: () => {
                if (isThisChatBeingEdited) return;
                setSelectedReportId(chatReport._id);
                setSelectedChatId(chat._id);
                setTargetMessageIdForScrolling(chatReport.chatMessageId);
              },
              endIcons:
                editingReport?._id === chatReport._id || !isPermittedToEditReports
                  ? []
                  : [
                      {
                        icon: <PencilIcon16In24 />,
                        onClick: () => {
                          setEditingReport({
                            ...chatReport,
                            chatId: chat._id
                          });
                        }
                      }
                    ]
            })),
        endIcons:
          isThisChatBeingEdited || !isPermittedToDeleteChat
            ? []
            : [
                {
                  icon: <PencilIcon16In24 />,
                  onClick: () => {
                    setEditingChat(chat);
                  }
                },
                {
                  icon: <TrashIcon />,
                  onClick: async () => {
                    setDeletingChat(chat);
                  }
                }
              ]
      };
    };

    //Compute the today date only once before the loop
    const today = dayjs();

    const chatsGroupedByDate = chatList.reduce((acc, chat) => {
      let sortingKey = null;

      const timePivot = chat.lastMessageAt;

      const daysDifference = today.diff(timePivot, "day", true);

      if (daysDifference <= 1) {
        sortingKey = CHAT_DATE_FOLDERS_TITLES.TODAY;
      } else if (daysDifference <= 2) {
        sortingKey = CHAT_DATE_FOLDERS_TITLES.YESTERDAY;
      } else if (daysDifference <= 7) {
        sortingKey = CHAT_DATE_FOLDERS_TITLES.LAST_7_DAYS;
      } else if (daysDifference <= 30) {
        sortingKey = CHAT_DATE_FOLDERS_TITLES.LAST_30_DAYS;
      } else {
        if (dayjs(timePivot).isSame(today, "year")) {
          sortingKey = dayjs(timePivot).format("MMMM");
        } else {
          sortingKey = dayjs(timePivot).format("YYYY");
        }
      }

      //Our accumulator is an object with values as objects with chats and some metadata
      if (!acc[sortingKey]) {
        acc[sortingKey] = {
          key: sortingKey, //repeat the key here so we can only use the values of the object (see below)
          priority: daysDifference, //by using days difference as priority we can sort the keys by date in the UI
          chats: [chat]
        };
      } else {
        acc[sortingKey].chats.push(chat);
      }

      return acc;
    }, {});

    const treeNodes = [];

    //Take only the values from the object and sort them by priority
    const orderedChatGroups = Object.values(chatsGroupedByDate).sort((a, b) => a.priority - b.priority);

    for (const item of orderedChatGroups) {
      const chats = item.chats;
      const chatsCopy = [...item.chats];
      if (!chats) continue;

      const searchValue = treeViewSearchValue.toLowerCase();
      for (let i = 0; i < chatsCopy.length; i++) {
        if (chatsCopy[i].reports?.length) {
          const matchedReports = chatsCopy[i].reports.filter(({ title }) => title?.toLowerCase().includes(searchValue));
          chatsCopy[i] = { ...chatsCopy[i], reports: [...matchedReports] };
        }
      }

      const filteredChats = [
        ...chatsCopy.filter(({ title, reports }) => title?.toLowerCase().includes(searchValue) || reports?.length)
      ];
      if (!filteredChats.length) continue;

      treeNodes.push({
        type: "section",
        name: item.key
      });

      treeNodes.push(...filteredChats.map(buildTreeNodeFromChat));
    }

    setTreeViewContent({
      title: null,
      tree: treeNodes,
      options: {
        useTitlePadding: false
      },
      footer: {
        items: isPermittedToStartChat
          ? [
              {
                id: "create-new-chat",
                component: NewChatButton,
                componentProps: {
                  buttonInfo: {
                    label: NEW_CHAT_BUTTON_TEXT,
                    disabled: isInEmptyState,
                    onClick: () => {
                      setChatInputEnabled(true);
                      setSelectedChatId(null);
                      setTargetMessageIdForScrolling(null);
                    }
                  }
                }
              }
            ]
          : []
      }
    });
  }, [
    chatList,
    selectedChatId,
    selectedReportId,
    editingChat,
    editingReport,
    isInEmptyState,
    NewChatButton,
    treeViewSearchValue
  ]);

  useEffect(() => {
    dispatch(navigationSetBreadcrumbsType(BREADCRUMBS_TYPES.NONE));
    setIsTreeViewOpen(true);

    return () => {
      setIsTreeViewOpen(false);
    };
  }, []);

  const resetTargetMessageIdForScrolling = () => {
    if (targetMessageIdForScrolling) {
      setTargetMessageIdForScrolling(null);
    }
  };

  return (
    <>
      <FullFlexStyled padding="16px" container data-testid={"chat-page-container"}>
        <ChatPageWindowContainer data-testid={"chat-page-window-container"}>
          {!isInEmptyState ? (
            <ChatWindow
              chatId={selectedChatId}
              scrollToMessageId={targetMessageIdForScrolling}
              resetTargetMessageIdForScrolling={resetTargetMessageIdForScrolling}
              onSend={onSendChatMessage}
              messages={selectedChatHistory}
              datatestid={"chatwindow"}
              startNewChatFunc={startNewChat}
              datasetId={location.state?.datasetId}
              onTopReached={loadOlderMessagesAfterScroll}
              firstItemIndex={firstItemIndex}
            />
          ) : (
            <ChatWindowEmptyState />
          )}

          {displayOtherDatasets && (
            <GeneralModalPreviewComponent
              onClose={(event, reason) => {
                setDisplayOtherDatasets(false);
              }}
            >
              <ChatPageDatasetSelectionModal
                chatId={selectedChatId}
                closeDataSelectionModal={() => setDisplayOtherDatasets(false)}
              />
            </GeneralModalPreviewComponent>
          )}
        </ChatPageWindowContainer>

        <ChatDeleteDialog
          deletionTargetType={deletionDialogTargetType}
          onCancel={() => {
            setDeletingChat(null);
          }}
          onConfirm={confirmDeletion}
        />
      </FullFlexStyled>
    </>
  );
};

export default memo(ChatPage);
