import { createContext, useMemo, useState } from "react";

export const defaultValue = {
  dataEntities: {},
  setDataEntities: () => {}
};

export const DataEntitiesContext = createContext({ ...defaultValue });

export const useDataEntitiesContext = () => {
  const [dataEntities, setDataEntities] = useState(defaultValue.dataEntities);

  return useMemo(
    () => ({
      dataEntities,
      setDataEntities
    }),
    [dataEntities]
  );
};
