import { JsonDatasetMetadataParser } from "@vanti-analytics-org/vanti-common";
import { logger } from 'utils/logger'

export const readJson = async (file, callback) => {
  try {
    const result = await JsonDatasetMetadataParser.parse(file);
    callback(null, {
      data: result.preview
    });
  } catch (err) {
    logger.error(err.message);
    callback(err.message);
  }
};
