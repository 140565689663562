import React, { createContext, memo, useEffect, useMemo } from "react";
import DataCurationsList from "./pages/data-curations-list/DataCurationsList";
import { Route, Switch } from "react-router-dom";
import SingleDataCuration from "./pages/single-data-curation/SingleDataCuration";
import { useDispatch, useSelector } from "react-redux";
import { navigationSetBreadcrumbsType } from "modules/navigation/actions";
import { BREADCRUMBS_TYPES } from "common/constants/NavigationConstants";
import { fetchCurations } from "modules/data-curations/actions";
import { allDataCurationsSelector } from "modules/data-curations/selectors";
import { fetchingLoadingSelector } from "modules/data-curations/selectors";
import { styled } from "@material-ui/core";

export const DataCurationContext = createContext();

export const DataCurationRouterWrapper = styled("div")(({ theme }) => ({
  justifyContent: "center",
  overflowY: "auto",
  paddingTop:"6px",
  height: "100%",
  boxSizing:"border-box",
  width:"100%",
  paddingRight:"38px",
  paddingLeft: "38px",
}));

const DataCurationPage = () => {
  const dispatch = useDispatch();

  const dataCurations = useSelector(allDataCurationsSelector);
  const fetchingLoading = useSelector(fetchingLoadingSelector);

  const contextObject = useMemo(() => {
    return {
      dataCurations,
      fetchingLoading
    };
  }, [dataCurations, fetchingLoading]);

  useEffect(() => {
    dispatch(fetchCurations());
    dispatch(navigationSetBreadcrumbsType(BREADCRUMBS_TYPES.DATA_CURATION));
  }, []);

  return (
    <DataCurationRouterWrapper container data-testid={`data-curation-router-wrapper`}>
      <DataCurationContext.Provider value={contextObject}>
        <Switch>
          <Route path="/dashboard/data-curation/:curationId" component={SingleDataCuration} />
          <Route path="/" component={DataCurationsList} />
        </Switch>
      </DataCurationContext.Provider>
    </DataCurationRouterWrapper>
  );
};

export default memo(DataCurationPage);
