import React, { memo, useMemo, useRef, useState } from "react";
import { Chip, Popover } from "@material-ui/core";
import { FlexContainer, FlexItem } from "components/ui";
import { TAGS, TAGS_ENUM } from "../constants";
import { vantiColors } from "assets/jss/palette";
import Products from "./Products";
import { useSelector } from "react-redux";
import { onboardingStationProductsSelector } from "modules/onboarding/selectors";
import { accountProductsSelector } from "modules/account/state/selectors";
import { stationProductsSelector } from "modules/station/query";
import ChipLabel from "../common/ChipLabel";
import classNames from "classnames";
import styles from "../common/commonStyles";

const ProductChip = ({ title, stationId, chipProps, ...props }) => {
  const classes = styles({
    backgroundColor: TAGS.PRODUCT.backgroundColor,
    textColor: TAGS.PRODUCT.textColor,
    hoverBackgroundColor: TAGS.PRODUCT.hoverBackgroundColor
  });

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const stationProducts = useSelector(
    stationId ? stationProductsSelector(stationId) : onboardingStationProductsSelector
  );

  const accountProducts = useSelector(accountProductsSelector);
  const chipElement = useRef(null);

  const stationProductsMap = useMemo(() => {
    return stationProducts.reduce((res, item) => {
      res[item._id] = item;
      return res;
    }, {});
  }, [stationProducts, accountProducts]);

  const productOptions = useMemo(
    () => [...stationProducts].concat(accountProducts.filter(product => !stationProductsMap[product._id])),
    [stationProducts, stationProductsMap]
  );

  const shouldDisplayDefaultChip = useMemo(() => !stationProducts.length && !title, [stationProducts.length, title]);

  const onChipClick = e => {
    e.stopPropagation();
    setIsPopoverOpen(true);
  };

  const onPopoverClose = e => {
    setIsPopoverOpen(false);
    e.stopPropagation();
  };

  const chipStyle = classNames({
    [classes.chip]: !shouldDisplayDefaultChip,
    [classes.defaultChip]: shouldDisplayDefaultChip
  });

  return (
    <FlexContainer {...props}>
      <FlexItem dense="leftTopBottom">
        <Chip
          label={<ChipLabel label={title} stationItems={stationProducts} type={TAGS_ENUM.PRODUCT} />}
          ref={chipElement}
          onClick={onChipClick}
          className={chipStyle}
          data-testid="product-chip"
        />
      </FlexItem>

      <FlexItem dense="full">
        <Popover
          {...chipProps}
          onClick={e => e.stopPropagation()}
          open={isPopoverOpen}
          anchorEl={chipElement.current}
          onClose={onPopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          PaperProps={{
            style: {
              backgroundColor: "white",
              borderRadius: 0,
              minWidth: 194,
              maxWidth: 250,
              boxShadow: "0px 8px 20px rgba(135, 155, 195, 0.2)",
              border: `1px solid ${vantiColors.gray18}`,
              height: 210
            }
          }}
        >
          <Products stationProductsMap={stationProductsMap} products={productOptions} stationId={stationId} />
        </Popover>
      </FlexItem>
    </FlexContainer>
  );
};

export default memo(ProductChip);
