import { styled } from "@material-ui/core";
import { FlexItem } from "components/ui";

export const RootLayout = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%"
}));

export const MainLayout = styled("div")(({ theme, heightOffset }) => ({
  display: "flex",
  width: "100%",
  height: `calc(100% - ${heightOffset}px)`
}));

export const ApplicationContent = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column"
}));
