import React, { memo } from "react";
import { styled } from "@material-ui/core";
import HomePageEmptyStateIcon from "assets/icons/HomePageEmptyStateIcon";
import { vantiColors } from "assets/jss/palette";
import GeneralVantiButton, { VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { Text } from "components/ui";

const HOME_PAGE_EMPTY_STATE_USER_MESSAGE_TEXT = "Add an item or publish reports for better data control and viewing";
const HOME_PAGE_EMPTY_STATE_BTN_TEXT = "Add items";

const HomePageEmptyState = ({ onAddItemCallback }) => {
  return (
    <HomePageEmptyStateContainer>
      <HomePageEmptyStateWrapper>
        <HomePageEmptyStateIconStyled />
        <TextStyled size={16} lineSize={"19.36px"} color={vantiColors.gray33}>
          {HOME_PAGE_EMPTY_STATE_USER_MESSAGE_TEXT}
        </TextStyled>
        <GeneralVantiButton
          variant={VARIANTS.TERTIARY}
          disableOnClick={true}
          showLoaderOnClick={true}
          buttonInfo={{
            onClick: onAddItemCallback,
            label: HOME_PAGE_EMPTY_STATE_BTN_TEXT
          }}
        />
      </HomePageEmptyStateWrapper>
    </HomePageEmptyStateContainer>
  );
};

const HomePageEmptyStateContainer = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const HomePageEmptyStateWrapper = styled("div")({
  width: "fit-content",
  height: "fit-content",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  position: "absolute",
  top: "35%"
});

const HomePageEmptyStateIconStyled = styled(HomePageEmptyStateIcon)({
  paddingBottom: 43
});

const TextStyled = styled(Text)({
  paddingBottom: 24
});

export default memo(HomePageEmptyState);
