import React, { memo } from "react";
import { FlexItem, Text } from "components/ui";

const getRenderedSubtitle = (subtitle, subtitleTextProps) => {
  return typeof subtitle === "string" ? (
    <FlexItem xs={12} dense="horizontal">
      <Text data-testid={`${subtitle ? subtitle : "subtitle"}`} {...subtitleTextProps}>
        {subtitle}
      </Text>
    </FlexItem>
  ) : typeof subtitle === "object" ? (
    subtitle
  ) : null;
};

const NumberedSection = ({ index, title, subtitle, children, ...props }) => {
  const { titleContainerProps, titleTextProps, subtitleTextProps, containerProps, childrenContentProps } = props;

  return (
    <FlexItem data-testid={`${title}-section`} container {...containerProps}>
      <FlexItem container dense="full" alignItems="center" {...titleContainerProps}>
        <FlexItem dense="full">
          <Text data-testid={`title-${title}`} {...titleTextProps}>
            {index ? `${index}. ${title}` : title}
          </Text>
        </FlexItem>

        {getRenderedSubtitle(subtitle, subtitleTextProps)}
      </FlexItem>

      <FlexItem xs={12} {...childrenContentProps}>
        {children}
      </FlexItem>
    </FlexItem>
  );
};

export default memo(NumberedSection);
