import React from "react";
import { vantiColors } from "assets/jss/palette";
import GreenV from "assets/icons/GreenV.svg";
import { checkPasswordRequirements } from "utils/password-check";
import { IconButton, InputAdornment } from "@material-ui/core";
import EyeOpen from "assets/icons/EyeOpen";
import EyeClosed from "assets/icons/EyeClosed";

export function validatePassword(password) {
  const requirements = checkPasswordRequirements(password);

  if (requirements.hasPassedAllChecks) {
    return {
      message: (
        <div style={{ color: vantiColors.success1 }}>
          <img src={GreenV} alt="Check" /> Excellent, password strength is strong
        </div>
      ),
      type: "success"
    };
  }

  const results = (
    <div>
      <div
        data-testid="8-characters-minimum"
        style={{ color: requirements.isValidLength ? vantiColors.success1 : vantiColors.rose6 }}
      >
        {requirements.isValidLength && <img src={GreenV} alt="Check" />} 8 characters minimum
      </div>
      <div
        data-testid="one-uppercase-character"
        style={{ color: requirements.isUppercase ? vantiColors.success1 : vantiColors.rose6 }}
      >
        {requirements.isUppercase && <img src={GreenV} alt="Check" />} One uppercase character
      </div>
      <div
        data-testid="one-lowercase-character"
        style={{ color: requirements.isLowercase ? vantiColors.success1 : vantiColors.rose6 }}
      >
        {requirements.isLowercase && <img src={GreenV} alt="Check" />} One lowercase character
      </div>
      <div data-testid="one-number" style={{ color: requirements.isNumber ? vantiColors.success1 : vantiColors.rose6 }}>
        {requirements.isNumber && <img src={GreenV} alt="Check" />} One number
      </div>
      <div
        data-testid={`one special character`}
        style={{ color: requirements.isSymbol ? vantiColors.success1 : vantiColors.rose6 }}
      >
        {requirements.isSymbol && <img src={GreenV} alt="Check" />}
        {` One special character`}
      </div>
      <div
        data-testid={`hard-to-guess`}
        style={{ color: requirements.isHardToGuess ? vantiColors.success1 : vantiColors.rose6 }}
      >
        {requirements.isHardToGuess && <img src={GreenV} alt="Check" />}
        {` Password is not an easy-to-guess sequence (e.g. "Asdfg0987!")`}
      </div>
    </div>
  );
  return { message: results, type: "error" };
}

export function validateStringDoesNotContainSpecialCharacters(string) {
  const specialCharactersRegex = /^([a-zA-Z0-9 _-]+)$/;
  return specialCharactersRegex.test(string);
}

export const getShowPasswordProps = (show, setShow) => {
  return {
    type: show ? "text" : "password",
    endAdornment: (
      <InputAdornment position="end">
        <IconButton data-testId="eye-icon" aria-label="toggle password visibility" onClick={() => setShow(!show)}>
          {show ? <EyeOpen /> : <EyeClosed />}
        </IconButton>
      </InputAdornment>
    )
  };
};
