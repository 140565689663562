import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  tabRoot: {
    width: "fit-content",
    minWidth: "fit-content !important",
    marginRight: theme.spacing(2),
    justifyContent: "space-between",
    "&.Mui-selected": {
      color: "black",
      borderBottom: `none !important`
    }
  },
  tabsIndicator: {
    display: "none"
  },
  tabsFlexContainer: {
    height: "50px",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2)
  }
}));

export default useStyles;
