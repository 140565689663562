import React, { memo, useCallback, useContext, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { AppContext } from "common/hooks/context-hooks/use-app-context";

const StaticReportDatasetSelection = ({ onDatasetSelected }) => {
  const { datasetsContext } = useContext(AppContext);
  const { datasets } = datasetsContext;
  const [datasetName, setDatasetName] = useState("Choose dataset");

  const handleChange = useCallback(
    event => {
      setDatasetName(event.target.value);
      onDatasetSelected && onDatasetSelected({ datasetId: event.target.value });
    },
    [onDatasetSelected]
  );

  return (
    datasets && (
      <FormControl variant="filled" style={{ width: 200 }}>
        <InputLabel id="static-report-dataset-selection">{datasetName}</InputLabel>
        <Select
          autoWidth={true}
          labelId="static-report-dataset-selection"
          id="static-report-dataset-selection-filled"
          value={datasetName}
          onChange={handleChange}
        >
          {Object.values(datasets).map(d => (
            <MenuItem value={d._id || d.id} key={`static-report-dataset-selection-menu-item-${d._id || d.id}`}>
              {d.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );
};

export default memo(StaticReportDatasetSelection);
