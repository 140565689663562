import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1, 0, 0, 0),
    position: "relative",
    verticalAlign: "unset",
    "& > div": {
      marginTop: 0
    }
  },
  input: {
    ...theme.fonts.defaultFont,
    display: "flex",
    flexWrap: "wrap",
    minHeight: "50px",
    height: "fit-content",
    minWidth: "597px",
    backgroundColor: theme.palette.vantiColors.lightBlue5,
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    padding: theme.spacing(1, 2.5),
    rowGap: theme.spacing(1),
    "&::before": {
      borderBottom: `none`
    },
    "&::after": {
      display: "none"
    },
    "&:focus": {
      backgroundColor: "transparent"
    },
    "& > input": {
      width: "inherit",
      minWidth: "1px"
    },
    "& > div": {
      display: "flex",
      height: "100%",
      "& > svg": {
        top: "auto",
        right: theme.spacing(2)
      }
    }
  },
  chipsWrapper: {
    display: "flex",
    marginTop: theme.spacing(2),
    width: "100%",
    height: "fit-content",
    flexWrap: "wrap",
    gap: theme.spacing(2)
  }
}));
