import React, { memo, useState } from "react";

import TextUnderlinedInput from "components/ui/Inputs/TextUnderlinedInput";
import { IconButton } from "@material-ui/core";

import { disabledFieldDefaultValue } from "pages/side-bar/data-connectors/constants";

import EyeClosed from "assets/icons/EyeClosed";
import EyeOpen from "assets/icons/EyeOpen";

import useStyles from "./styles";

const DataSourceInput = ({ field, disabled, values, onChange }) => {
  const classes = useStyles();

  const [isPasswordVisible, setIsPasswordVisible] = useState(!field.usePasswordInput);

  const onIconClick = () => {
    setIsPasswordVisible(prevState => !prevState);
  };

  return (
    <TextUnderlinedInput
      dataTestId={field.title}
      id={field.title}
      shouldIncludeSpacing={false}
      color="gray"
      formControlProps={{
        fullWidth: true,
        className: classes.inputFormControl
      }}
      inputProps={{
        disabled: disabled,
        type: isPasswordVisible ? "text" : "password",
        placeholder: field.defaultValue,
        value: disabled ? disabledFieldDefaultValue : values[field],
        defaultValue: disabled ? disabledFieldDefaultValue : field.defaultValue,
        onChange: event => {
          onChange(event.target.value, field.key);
        },
        endAdornment: field.usePasswordInput && (
          <IconButton
            data-testid={"toggle-password-visibility"}
            disabled={disabled}
            classes={{
              root: classes.togglePasswordEyeIcon
            }}
            aria-label="toggle password visibility"
            onClick={onIconClick}
          >
            {isPasswordVisible ? <EyeOpen /> : <EyeClosed />}
          </IconButton>
        )
      }}
    />
  );
};

export default memo(DataSourceInput);
