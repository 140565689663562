import { useContext } from "react";
import { useMutation } from "react-query";
import { createAlert, createFlow, deleteFlow, getAllAccountFlows, updateFlow } from "services/api/flow";
import { AppContext } from "./context-hooks/use-app-context";

const REQ_KEYS = {
  CREATE_FLOW: "create.flow.req.key",
  GET_FLOW: "get.flow.req.key",
  UPDATE_FLOW: "update.flow.req.key",
  DELETE_FLOW: "delete.flow.req.key",
  RUN_METRIC_CALCULATION: "run.metric.calculation.req"
};

const useFlowRequest = () => {
  const { flowContext } = useContext(AppContext);
  const { setFlows } = flowContext;

  const createFlowReq = useMutation(REQ_KEYS.CREATE_FLOW, params => createFlow(params), {
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false
  });

  const updateFlowReq = useMutation(REQ_KEYS.UPDATE_FLOW, params => updateFlow(params), {
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false
  });

  const getAccountFlowsReq = useMutation(REQ_KEYS.GET_FLOW, params => getAllAccountFlows(params), {
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    onSuccess: data => {
      if (data) {
        setFlows(data);
      }
    }
  });

  const createAlertReq = useMutation(REQ_KEYS.RUN_METRIC_CALCULATION, params => createAlert(params), {
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    onSuccess: data => {
      if (data) {
        setFlows(data);
      }
    }
  });

  const deleteFlowReq = useMutation(REQ_KEYS.DELETE_FLOW, params => deleteFlow(params), {
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    onSuccess: (_, params = {}) => {
      const { flowId } = params;

      setFlows(flows => {
        const flowsCopy = { ...flows };

        delete flowsCopy[flowId];

        return flowsCopy;
      });
    }
  });

  return {
    createFlowReq,
    updateFlowReq,
    getAccountFlowsReq,
    deleteFlowReq,
    createAlertReq
  };
};

export default useFlowRequest;
