import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import SignIn from "pages/auth/SignIn";
import ForgotPassword from "pages/auth/ForgotPassword";
import ConfirmAccount from "pages/auth/ConfirmAccount";

import Action from "pages/auth/Action";

import { transition, container } from "assets/jss/vanti-dashboard.js";
import background from "assets/img/background-auth.jpg";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    backgroundImage: `url(${background})`
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `100%`
    },
    overflow: "auto",
    position: "relative",
    margin: "0 auto",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: theme.spacing(8.75),
    margin: "0 auto",
    padding: theme.spacing(3.75, 2),
    minHeight: "calc(100vh - 200px)"
  },
  container
}));

export default function Auth() {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.mainPanel}>
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              <Route path="/auth/sign-in" component={SignIn} />
              <Route path="/auth/forgot-password" component={ForgotPassword} />
              <Route path="/auth/confirm-account" component={ConfirmAccount} />
              <Route path="/auth/action" component={Action} />
              <Redirect to="/auth/sign-in" />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
