export const SENSITIVITY_TYPES = {
  LOW_SENSITIVITY: "low",
  HIGH_SENSITIVITY: "high"
};

export const CODE_SNIPPET = `def my_added_feature(filename, df):
  new_feature = <insert code>
  new_feature_name = <insert code>
  return new_feature, new_feature_name`;

export const DEFAULT_NUM_OF_CLUSTERS = 2;
export const DEFAULT_FAIL_RATE = 10;
export const DEFAULT_SENSITIVITY = SENSITIVITY_TYPES.LOW_SENSITIVITY;
export const DEFAULT_AUGMENTATION_VALUE = 50;

export const MIN_NUM_CLUSTERS = 2;
export const MAX_NUM_CLUSTERS = 10;
export const TABULAR_MAX_NUM_CLUSTERS = 10;
export const TABULAR_MIN_NUM_CLUSTERS = 2;

export const MIN_FAIL_RATE = 0;
export const MAX_FAIL_RATE = 50;
