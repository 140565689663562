import { Button, styled } from "@material-ui/core";
import { FlexItem, Text } from "../../../../../components/ui";
import CurationNameInput from "./components/curation-name/CurationNameInput";
import CodePreview from "./components/code-preview/CodePreview";
import GeneralVantiButton from "../../../../../components/ui/Buttons/GeneralVantiButton";
import { ReactComponent as ArrowLeft } from "assets/icons/data-curation/ArrowLeft.svg"
import { vantiColors } from "assets/jss/palette";
import {ReactComponent as PlusButton} from "assets/icons/data-curation/PlusButton.svg";

export const CurationFileUploadDescStyled = styled(Text)({
  fontSize:16,
  paddingLeft:6,
  paddingTop:3
});

export const CurationNoFilesContainerStyled = styled("div")({
  display:"flex",
  flexDirection:"column"
})

export const AddDatasetLinkStyled = styled(Button)({
  all: "unset",
  color:vantiColors.darkBlue7,
  fontSize:"16px",
  padding:"5px 1px 0px 0px",
  width:"fit-content",
  "&:hover":{
    backgroundColor:"transparent",
  }
})

export const CurationFileUploadBHeaderStyled = styled(Text)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: 0,
  paddingBottom:15,
  width: "100%",
});

export const DataSectionStyled = styled(Text)({
  fontWeight: 600,
  fontSize: 18,
  padding: "0px 0px 2px 5px !important",
  display: "flex",
  alignItems: "center",
  color: vantiColors.darkBlue6
});


export const CurationNameContainerStyled = styled(FlexItem)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  background: vantiColors.white,
  borderRadius: 4,
  marginBottom: 18,
  boxSizing: "border-box",
  padding: "16px 0px 0px 16px"
});

export const CurationPagePrimaryTitleStyled = styled(Text)({
  fontWeight: 600,
  fontSize: 22,
  padding: "0px 0px 16px 0px !important",
  display: "flex",
  alignItems: "center",
  color: vantiColors.darkBlue6
});

export const CurationPageDescStyled = styled(Text)({
  width: "100%",
  height: 38,
  fontWeight: 400,
  fontSize: 16,
  whiteSpace: "pre-line",
  display: "flex",
  alignItems: "center"
});

export const CurationPageSecondaryTitleStyled = styled(Text)({
  width: 128,
  fontWeight: 600,
  fontSize: 18,
  alignItems: "center",
  color: vantiColors.darkBlue6
});

export const CurationFileUploadContainerStyled = styled("div")({
  display: "flex",
  flexDirection: "row",
  background: vantiColors.white,
  marginBottom: 18,
  padding: 8,
  justifyContent:"space-between"
});

export const CurationFileUploadHeaderTextStyled = styled(Text)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: 0,

});

export const PlusButtonStyled = styled(PlusButton)({
  width: 24,
  alignSelf: "center"
});

export const PlusButtonLabelStyled = styled("div")({
  display:"flex",
  justifyContent:"space-between"
});

export const CurationAddDatasetButtonTextStyled = styled(Text)({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 16,
  display: 'flex',
  alignItems: 'center',
  color: vantiColors.gray41,
});

export const CurationAddDatasetButtonStyled = styled(GeneralVantiButton)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignSelf: 'auto',
  padding: '8px 24px',
  width: 181,
  height: 40,
  background: vantiColors.white,
  borderRadius: 4,
});

export const YourDataWrapperStyled = styled("div")({
  display: "flex",
  flexDirection: "row"
})

export const FilesPreviewContainerStyled = styled("div")({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 18,
  padding: 8,
  width:"100%"
});

export const FileUploadContainerStyled = styled("div")({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 24px',
  width: 181,
  height: 40,
  background: vantiColors.white,
  border: '1px solid #B5B9C2',
  borderRadius: 4,
});


export const CurationPageHeaderStyled = styled(FlexItem)({
  padding: "16px 0px 16px 0px !important"
});

export const CurationNameInputStyled = styled(CurationNameInput)({
  width: 218,
  fontWeight: "bold"
});

export const CurationCodeContainerStyled = styled(FlexItem)({
  display: "flex",
  flexDirection: "column",
  padding: 16,
  width: "100%",
  height: "fit-content",
  background: vantiColors.white,
  borderRadius: 4
});

export const CurationCodeInnerContainerStyled = styled(FlexItem)({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  width: "100%",
  padding: "0px !important"
});

export const CodeWrapperStyled = styled(CodePreview)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: 0,
  height: 287,
  background: vantiColors.gray18
});

export const CurationCodeSectionTitleStyled = styled(Text)({
  width: 96,
  height: 19,
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 16,
  display: "flex",
  alignItems: "center",
  color: vantiColors.darkBlue6,
  flex: "none",
  order: 0,
  marginBottom: 17
});

export const CurationOutputTextStyled = styled(Text)({
  width: 250,
  height: 19,
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 16,
  display: "flex",
  alignItems: "center",
  color: vantiColors.darkBlue6,
  flex: "none",
  order: 0,
  marginBottom: 17
});

export const CurationCodeRunButtonStyled = styled(GeneralVantiButton)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "flex-end",
  padding: "8px 24px",
  width: 160,
  height: 40,
  background: vantiColors.lightGray12,
  borderRadius: 4,
  flex: "none",
  marginTop: "16px"
});

export const CurationPreviewContainerStyled = styled(FlexItem)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px !important",
  width: "100%",
  height: 318,
  marginTop: "33px"
});

export const CurationPreviewTextStyled = styled(Text)({
  width: 149,
  height: 22,
  fontWeight: 600,
  fontSize: 18,
  display: "flex",
  alignItems: "center",
  marginBottom: "10px"
});

export const CurationSaveButtonStyled = styled(GeneralVantiButton)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "8px 24px",
  width: 160,
  height: 40,
  borderRadius: 4,
  alignSelf: "flex-end",
  marginTop: "auto"
});

export const CurationInputPreviewTitleStyled = styled(Text)({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 18,
  display: "flex",
  alignItems: "center",
  color: vantiColors.darkBlue6,
  flex: "none",
  padding:"20px 0px 20px 8px"
});

export const CurationSectionTitleStyled = styled(Text)({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 18,
  display: "flex",
  alignItems: "center",
  color: vantiColors.darkBlue6,
  flex: "none",
  order: 0,
  alignSelf: "stretch",
  // flexGrow: 0,
  marginBottom: "7px"
});

export const ScrollTextStyled = styled(FlexItem)({
  gap: "theme.spacing(2)",
  marginTop: "theme.spacing(2)",
  justifyContent: "end",
  cursor: "pointer",
  display: "flex",
  alignSelf: "flex-end",
  marginLeft: "auto"
});

export const BackButtonStyled = styled(Button)({
  textAlign: "center",
  justifyContent: "start",
  padding: "0px 0px 2px 1px",
  "&:hover": {
    backgroundColor: "transparent"
  },
});

export const ArrowLeftStyled = styled(ArrowLeft)({
  width: 20,
  marginLeft:"-6px",
  marginRight:"7"
});


export const ExecErrorContainer = styled("div")({
  display: "flex",
  minHeight: "auto",
  padding: "16px",
  marginBottom: "10px",
  borderRadius: "2px",
  border: `1px solid var(--red, ${vantiColors.rose6})`,
  background: "#fff5f8"
});
