import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/ui/Buttons/Button";
import Modal from "components/ui/Modal/Modal";
import ModalBody from "components/ui/Modal/ModalBody";
import ModalFooter from "components/ui/Modal/ModalFooter";
import * as PropTypes from "prop-types";

const styles = () => ({
  modal: {
    minHeight: "10vh"
  },
  modalBody: {
    marginTop: "10px"
  },
  modalFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  }
});

const useStyles = makeStyles(styles);

export default function SimpleAlert(props) {
  const { isOpen, confirmLabel, onConfirm, cancelLabel, onCancel, children } = props;
  const classes = useStyles();
  return (
    <Modal open={isOpen} paperProps={{ className: classes.modal }}>
      <ModalBody className={classes.modalBody}>{children}</ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <Button transparent={true} onClick={onCancel}>
          {cancelLabel}
        </Button>
        <Button color="danger" onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

SimpleAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};
