import React, { memo } from "react";

import { Text } from "components/ui/index";

import { ReactComponent as UploadIcon } from "assets/img/upload-file.svg";
import { ReactComponent as DisabledUploadIcon } from "assets/img/upload-file-disabled.svg";

import { vantiColors } from "assets/jss/palette";

import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";

const DropFileSection = ({ disabled }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <div className={classes.dropAreaTextContainer}>
        <div className={classes.iconWrapper}>{disabled ? <DisabledUploadIcon /> : <UploadIcon />}</div>
        &nbsp;
        <Text
          data-testid="file-drop-text"
          color={disabled ? vantiColors.lightGray6 : vantiColors.blue4}
          family="poppins"
          underline
          size="14px"
        >
          Click here to upload from your computer or drag and drop your database file(s) {disabled}
        </Text>
      </div>
    </Grid>
  );
};

export default memo(DropFileSection);
