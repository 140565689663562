import { actionTypes } from "./actions";
import { defaultSingleResourceOperationState } from "utils/modules-utils";

const defaultState = {
  ...defaultSingleResourceOperationState(),
  loading: true
};

export default function authStateReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.FETCH_AUTH_DATA_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case actionTypes.FETCH_AUTH_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true
      };
    }
    case actionTypes.FETCH_AUTH_DATA_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        success: false,
        error
      };
    }
    case actionTypes.FETCH_AUTH_DATA_FULFILL: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
}
