import { makeStyles } from "@material-ui/core";

const styles = makeStyles(({ palette, spacing }) => ({
  time: {
    fontSize: "16px",
    fontWeight: 500,
    color: palette.vantiColors.green4,
    backgroundColor: palette.vantiColors.lightGreen3,
    borderRadius: "4px",
    padding: spacing(0.5, 4),
    marginTop: spacing(2)
  }
}));

export default styles;
