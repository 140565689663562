import React, { forwardRef, memo, useContext, useEffect, useState } from "react";
import { styled } from "@material-ui/core";
import { ChatMessageMultipartMessageWrapper } from "pages/side-bar/chat/components/chat-message/ChatMessageContentCommonStyles";
import ChatMessageBubbleWithAvatar from "pages/side-bar/chat/components/chat-message/ChatMessageBubbleWithAvatar";
import ChatMessageInlineButtonsFactory from "./ChatMessageInlineButtons/ChatMessageInlineButtonsFactory";
import { AppContext } from "common/hooks/context-hooks/use-app-context";

const InlineButtonsContainer = styled("div")(() => ({
  width: "100%",
  paddingLeft: "56px",
  paddingRight: "16px",
  boxSizing: "border-box"
}));

const ChatMessageBubbleWithAvatarAndInlineButtons = ({
  message,
  isUserMessage,
  chatMessageAvatar,
  onChatMessageMetadataClick
}) => {
  const { chatContext } = useContext(AppContext);
  const { lastSystemMessageWithInlineButtonsRef } = chatContext;
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    if (lastSystemMessageWithInlineButtonsRef.current) {
      lastSystemMessageWithInlineButtonsRef.current.setIsFadingOut = setIsFadingOut;
    }
  }, [lastSystemMessageWithInlineButtonsRef.current, setIsFadingOut]);

  return (
    <ChatMessageMultipartMessageWrapper
      data-testid={`chat-msg-multipart-message-wrapper-${message._id}`}
      isFadingOut={isFadingOut}
      ref={lastSystemMessageWithInlineButtonsRef}
    >
      {message.textContent && (
        <ChatMessageBubbleWithAvatar
          isUserMessage={isUserMessage}
          chatMessageAvatar={chatMessageAvatar}
          textContent={message.textContent}
          onChatMessageMetadataClick={onChatMessageMetadataClick}
        />
      )}
      <InlineButtonsContainer data-testid={"inline-buttons-container"}>
        <ChatMessageInlineButtonsFactory message={message} />
      </InlineButtonsContainer>
    </ChatMessageMultipartMessageWrapper>
  );
};
export default memo(forwardRef(ChatMessageBubbleWithAvatarAndInlineButtons));
