import React, { memo, useContext, useMemo, useState } from "react";
import { styled } from "@material-ui/core";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import ChatMessageDataPreviewTitle from "./ChatMessageDataPreviewTitle";
import ChatMessageReportDataPreviewTable from "./ChatMessageReportDataPreviewTable";
import ChatMessageReportDataPreviewTableSummary from "./ChatMessageReportDataPreviewTableSummary";
import { Skeleton } from "@material-ui/lab";
import DatasetFileInfoModal from "pages/side-bar/chat/components/DatasetFileInfoModal";
import ChatMessageDatabaseReportPreviewModal from "../ChatMessageDatabaseReportPreviewModal";

const ReportDataPreviewContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 16
});

const ChatMessageReportDataPreviewSection = ({ message, displayTitle = true }) => {
  const { datasetsContext } = useContext(AppContext);
  const [isEnlargedPreview, setIsEnlargedPreview] = useState(false);
  const { setContext, openDatasetInfoModalId } = datasetsContext;

  const [databasePreviewReportModalOpen, setDatabasePreviewReportModalOpen] = useState(false);

  const dataset = useMemo(() => {
    const value = Object.values(datasetsContext.datasets).find(
      dataset => dataset._id === message?.reportData?.datasetId
    );

    //since this code is also called from datasets that were created from buildDatasetForDisplay()
    //we need to make sure we have "id" as well
    if (value) value.id = value._id;
    return value;
  }, [datasetsContext.datasets, message?.reportData?.datasetId]);

  const canEnlargePreview = useMemo(() => {
    return !!dataset || message.reportData?.dbQuery;
  }, [dataset, message]);

  const previewRowsContents = useMemo(() => {
    if (message?.reportData?.previewRows && message?.reportData?.previewRows.length > 0) {
      return {
        headers: message?.reportData?.previewRows[0],
        body: message?.reportData?.previewRows.slice(1)
      };
    } else {
      return dataset?.mediumPreview;
    }
  }, [dataset, message, message?.reportData]);

  return dataset || previewRowsContents ? (
    <ReportDataPreviewContainer>
      {displayTitle && dataset && <ChatMessageDataPreviewTitle message={message} dataset={dataset} />}
      <ChatMessageReportDataPreviewTable
        canEnlargePreview={canEnlargePreview}
        isEnlargedPreview={isEnlargedPreview}
        onClickEnlargePreviewCallback={() => {
          if (message.reportData?.dbQuery) {
            setDatabasePreviewReportModalOpen(true);
            return;
          }
          if (!dataset) return;

          setContext(prevState => ({
            ...prevState,
            openDatasetInfoModalId: dataset.id,
            datasetFileDescriptionSelectedTabIndex: 1
          }));
          setIsEnlargedPreview(true);
        }}
        lowOpacity={false}
        fullBorder={false}
        preview={previewRowsContents}
      />
      {dataset && (
        <ChatMessageReportDataPreviewTableSummary
          numOfRecords={dataset.rowsCount}
          numOfColumns={dataset.columnsCount}
          dateRange={message.dateRange}
          datasetId={dataset._id}
        />
      )}
      {dataset && openDatasetInfoModalId === dataset.id && (
        <DatasetFileInfoModal
          dataset={dataset}
          isReadOnly={true}
          onCloseHandler={() => {
            setIsEnlargedPreview(false);
            setContext(prevState => ({
              ...prevState,
              openDatasetInfoModalId: null,
              datasetFileDescriptionSelectedTabIndex: 0
            }));
          }}
        />
      )}
      {databasePreviewReportModalOpen && (
        <ChatMessageDatabaseReportPreviewModal
          chatMessage={message}
          onCloseHandler={() => {
            setDatabasePreviewReportModalOpen(false);
          }}
        />
      )}
    </ReportDataPreviewContainer>
  ) : (
    <Skeleton animation="wave" variant="rect" height={342} />
  );
};

export default memo(ChatMessageReportDataPreviewSection);
