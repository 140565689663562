import React, { memo } from "react";

const DefectsInspectionIcon = ({ className }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_637_9726)">
      <path
        d="M29.5091 21.1129V17.7258L26.6 14.9032H9.14545V34.0968H29.5091V24.5L26.6 23.371L24.2727 25.0645L20.7818 24.5L19.0364 27.3226M8.56364 10.9516H5.07273V14.3387M8.56364 10.9516V7M8.56364 10.9516H12.0545M12.0545 10.9516V8.69355M12.0545 10.9516H15.5455M15.5455 10.9516V7M15.5455 10.9516H19.0364M19.0364 10.9516V8.69355M19.0364 10.9516H22.5273M22.5273 10.9516V7M22.5273 10.9516H26.0182M26.0182 10.9516H27.7636L33 16.0323V38.0484H22.5273M26.0182 10.9516V8.69355M5.07273 14.3387H1M5.07273 14.3387V17.7258M5.07273 17.7258H2.74545M5.07273 17.7258V21.1129M5.07273 21.1129H1M5.07273 21.1129V24.5M5.07273 24.5H2.74545M5.07273 24.5V27.8871M5.07273 27.8871H1M5.07273 27.8871V31.2742M5.07273 31.2742H2.74545M5.07273 31.2742V34.6613M5.07273 34.6613V38.0484H8.56364M5.07273 34.6613H1M8.56364 38.0484V42M8.56364 38.0484H12.0545M12.0545 38.0484V40.3065M12.0545 38.0484H15.5455M15.5455 38.0484V42M15.5455 38.0484H19.0364M19.0364 38.0484V40.3065M19.0364 38.0484H22.5273M22.5273 38.0484V42"
        stroke="black"
      />
      <path
        d="M20.5271 19.5271C24.9604 15.0938 32.1481 15.0938 36.5813 19.5271C40.6748 23.6205 40.9885 30.0624 37.5226 34.5158L45.1371 42.0086L43.0086 44.1371L35.5158 36.5226C31.0624 39.9885 24.6206 39.6748 20.5271 35.5813C16.0939 31.1481 16.0939 23.9603 20.5271 19.5271Z"
        fill="white"
      />
      <path
        d="M35.5158 36.5226C31.0624 39.9885 24.6206 39.6748 20.5271 35.5813C16.0939 31.1481 16.0939 23.9603 20.5271 19.5271C24.9604 15.0938 32.1481 15.0938 36.5813 19.5271C40.6748 23.6205 40.9885 30.0624 37.5226 34.5158M35.5158 36.5226C35.8855 36.2349 36.2415 35.9211 36.5813 35.5813C36.9211 35.2415 37.2349 34.8855 37.5226 34.5158M35.5158 36.5226L43.0086 44.1371L45.1371 42.0086L37.5226 34.5158"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4053 35.8146V35.8934H21.4927C21.4635 35.8673 21.4343 35.841 21.4053 35.8146ZM33.8442 35.8934H23.6494C23.1866 35.6032 22.7431 35.2699 22.3248 34.8934H34.5423V27.5429L31.6967 26.1897L28.8379 28.4767L28.5881 28.6766L28.302 28.5335L24.7482 26.7566L21.2079 27.7098L19.6222 28.1367L20.7009 26.8985L24.0283 23.0792L24.279 22.7915L24.6226 22.9574L28.0058 24.5901L30.8663 22.4777L31.089 22.3133L31.3465 22.4147L35.2255 23.9424L35.5423 24.0672V24.4077V27.227V34.5385C35.5417 34.5391 35.5411 34.5398 35.5404 34.5404C35.2524 34.8284 34.9509 35.0941 34.638 35.3376C34.3809 35.5378 34.1159 35.723 33.8442 35.8934ZM35.5394 19.2106L35.5423 19.0071L35.271 19.0032C35.3613 19.0708 35.4507 19.1399 35.5394 19.2106ZM34.5271 20.0706C34.8708 20.3301 35.201 20.6157 35.515 20.9274L35.4997 22.0071L34.4998 21.9929L34.5271 20.0706ZM34.5423 24.7481V26.4356L31.8435 25.1522L31.5609 25.0178L31.3164 25.2133L28.4631 27.496L25.0253 25.7772L24.8553 25.6921L24.6717 25.7416L22.5336 26.3172L24.5316 24.0238L27.8428 25.6218L28.1145 25.7529L28.3571 25.5737L31.2376 23.4466L34.5423 24.7481Z"
        fill="black"
      />
      <path
        d="M31.1633 22.8799L35.0423 24.4076V27.2269L31.6288 25.6037L28.5256 28.0862L24.8017 26.2243L21.0779 27.2269L24.4053 23.4076L28.0601 25.1715L31.1633 22.8799Z"
        fill="#86E8B6"
        stroke="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_637_9726">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default memo(DefectsInspectionIcon);
