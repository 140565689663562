import config from "common/config";
import http from "../http";
import { FILE_TYPES, getFileType } from "utils/user-data-reader";

// Server
const utilsUrl = `${config.serverUrl}/api/v2/utils`;

// API

/**
 * @returns {() => void}
 */
export async function getEmailActionsEnums() {
  const res = await http.get(`${utilsUrl}/enum/email-actions`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @returns {() => void}
 */
export async function getBlockDataSourceEnums() {
  const res = await http.get(`${utilsUrl}/enum/block-data-source`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * Log something
 * @param {('info'|'warn'|'error')} level
 * @param {string} message
 * @param {Error?} error
 */
export async function log(level, message, { blockId }, error) {
  let _error;
  if (error) {
    _error = {
      name: error && error.name,
      message: error && error.message,
      stack: error && error.stack
    };
  }
  let _params;
  if (blockId) {
    _params = {
      ...(blockId && { blockId })
    };
  }
  const res = await http.post(`${utilsUrl}/log`, {
    level,
    message,
    ...(_params && { params: _params }),
    ...(_error && { error: _error })
  });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export function getFileExtensionType(file) {
  switch (getFileType(file.name, file.type)) {
    case FILE_TYPES.ZIP:
      return "zip";
    case FILE_TYPES.XLSX:
      return "xlsx";
    default:
      return "csv";
  }
}

export function getFileExtensionByType(fileType) {
  switch (fileType) {
    case FILE_TYPES.ZIP:
      return "zip";
    case FILE_TYPES.XLSX:
      return "xlsx";
    case FILE_TYPES.CSV:
      return "csv";
    default:
      return fileType.toLowerCase();
  }
}
