import { takeLatest, call, put } from "redux-saga/effects";

import * as webNotificationService from "services/api/webNotifications";

import { actionTypes } from "./actions";
import { actionTypes as stateActionTypes } from "../../state/actions";

export function* updateNotificationListWorker({ payload }) {
  try {
    for (const notification of payload.updatedNotificationList) {
      const result = yield call(
        webNotificationService.updateWebNotification,
        notification.id,
        notification.updatedNotificationFields,
        payload.serverEmit
      );
      if (payload.updateStore) {
        yield put({ type: stateActionTypes.UPDATE_WEB_NOTIFICATION, payload: result });
      }
    }
    yield put({ type: actionTypes.UPDATE_WEB_NOTIFICATION_LIST_SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_WEB_NOTIFICATION_LIST_ERROR, payload: { error } });
  } finally {
    yield put({ type: actionTypes.UPDATE_WEB_NOTIFICATION_LIST_FULFILL, payload: {} });
  }
}

export default function* webNotificationsWatcher() {
  yield takeLatest(actionTypes.UPDATE_WEB_NOTIFICATION_LIST_REQUEST, updateNotificationListWorker);
}
