import { makeStyles } from "@material-ui/core";

const styles = makeStyles(({ palette, spacing }) => ({
  accordion: {
    boxShadow: "none",
    border: `1px solid ${palette.vantiColors.lightGray1}`,
    width: "600px",
    padding: spacing(0.5, 1),
    backgroundColor: palette.APP_MAIN_BACKGROUND_COLOR
  },
  wrapper: {
    width: "100%",
    "& > *:not(:last-child)": {
      borderBottom: `1px solid ${palette.vantiColors.lightGray4}`
    }
  }
}));

export default styles;
