import React, { memo, useMemo } from "react";
import { styled } from "@material-ui/core";
import Tag from "./Tag";
import { Text } from "components/ui";

const Wrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: 8
});

const TagsWrapper = styled("div")({
  display: "flex"
});

const TagsGroup = ({ tags = [], onDeleteCallback, isInDeletingProcess }) => {
  const tagsToDisplay = useMemo(() => tags.slice(0, 3), [tags.length]);
  const tagsDiff = useMemo(() => tags.length - tagsToDisplay.length, [(tags, tagsToDisplay)]);

  return (
    <Wrapper>
      <TagsWrapper>
        {tagsToDisplay?.length > 0 ? (
          tagsToDisplay.map((tag, index) => (
            <Tag
              key={tag}
              text={tag.value}
              order={index}
              isPartOfGroup
              onDeleteCallback={() => onDeleteCallback(tag._id)}
              isDisabled={isInDeletingProcess}
            />
          ))
        ) : (
          <Tag text={"+ Add"} />
        )}
      </TagsWrapper>

      {tagsDiff > 0 && <Text>+{tagsDiff}</Text>}
    </Wrapper>
  );
};

export default memo(TagsGroup);
