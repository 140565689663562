import { navigationActionTypes } from "./actionTypes";

/* Breadcrumbs */

export function navigationSetBreadcrumbsType(type) {
  return { type: navigationActionTypes.SET_BREADCRUMBS_TYPE, payload: type };
}

export function navigationSetPreviousLocation(location) {
  return { type: navigationActionTypes.SET_PREVIOUS_LOCATION, payload: location };
}

/* Breadcrumbs */
