import React, { memo, useCallback, useState } from "react";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import usePermissions from "common/hooks/use-permissions";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import PencilIcon16In24 from "assets/icons/pencil-icon/PencilIcon16In24";
import { vantiColors } from "assets/jss/palette";
import { updateMessageReportExecSummary } from "services/api/chat";

const ReportDescriptionContainer = styled(Text)(() => ({
  maxWidth: "95%",
  boxSizing: "border-box",
  display: "flex"
}));

const ReportDescription = styled(Text)(() => ({
  maxWidth: "100%",
  paddingBottom: "14px",
  boxSizing: "border-box",
  position: "relative",
  minHeight: 35 //to avoid height changes when switching between Text and TextArea
}));

const PencilIcon24Styled = styled(PencilIcon16In24)(() => ({
  cursor: "pointer",
  position: "absolute",
  right: "-36px", //12px padding + 24px icon size
  top: "-3px", //textarea top offset
  boxSizing: "border-box",
  backgroundColor: vantiColors.gray36,
  borderRadius: "50%",
  padding: 0
}));

const TextAreaStyled = styled("textarea")(({ isEditingHeadline }) => ({
  height: "fit-content",
  width: "100%",
  fontSize: 14,
  padding: 0,
  margin: 0,
  fontFamily: "Inter",
  fontWeight: 400,
  lineHeight: "17px",
  color: vantiColors.gray39,
  backgroundColor: "transparent",
  boxSizing: "border-box",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  borderBottom: isEditingHeadline ? "1px solid black" : "none"
}));

const MAX_EXEC_SUMMARY_DESCRIPTION_TEXT_LENGTH = 1024;

const ReportExecutiveSummarySectionDescription = ({ message, onDescriptionUpdated }) => {
  const description = message.reportData?.description || "No description";
  const messageId = message._id || "";
  const chatId = message.chatId || "";
  const isAllowedToEditChatReports = usePermissions([ROLES_ENUM.EDIT_CHAT_REPORTS]);
  const [isHovered, setIsHovered] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editedDescription, setEditedDescription] = useState(description);
  const [displayedDescription, setDisplayedDescription] = useState(description);

  const updateDescription = useCallback(
    async newDescription => {
      if (newDescription && newDescription.length) {
        setDisplayedDescription(newDescription);
        setIsEditingDescription(false);
        if (onDescriptionUpdated) {
          onDescriptionUpdated({ description: newDescription });
        } else {
          await updateMessageReportExecSummary(chatId, messageId, { description: newDescription });
        }
      }
    },
    [chatId, messageId]
  );

  return (
    isAllowedToEditChatReports &&
    description && (
      <ReportDescriptionContainer
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        {isEditingDescription ? (
          <TextAreaStyled
            onKeyDown={e => {
              if (e.key === "Tab") {
                updateDescription(editedDescription);
              }
              if (e.key === "Escape") {
                setEditedDescription(displayedDescription);
                setDisplayedDescription(displayedDescription);
                setIsEditingDescription(false);
              }
            }}
            wrap={"hard"}
            autoComplete={"on"}
            maxLength={MAX_EXEC_SUMMARY_DESCRIPTION_TEXT_LENGTH}
            autoFocus
            value={editedDescription}
            isEditingHeadline={isEditingDescription}
            onChange={e => setEditedDescription(e.target.value)}
            onBlur={e => {
              updateDescription(editedDescription);
            }}
          />
        ) : (
          <ReportDescription lineSize={"17px"} color={vantiColors.gray39} onClick={() => setIsEditingDescription(true)}>
            {displayedDescription}
            {isHovered && <PencilIcon24Styled onClick={() => setIsEditingDescription(true)} />}
          </ReportDescription>
        )}
      </ReportDescriptionContainer>
    )
  );
};

export default memo(ReportExecutiveSummarySectionDescription);
