import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { getCoreRowModel, flexRender, useReactTable } from "@tanstack/react-table";
import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import Text from "../Typography/Text";
import SearchPopup from "pages/side-bar/reports/components/SearchPopup";
import ArrowIcon22 from "assets/icons/arrow-icon/ArrowIcon22";
import BodyCell from "./BodyCell";
import DotIcon from "assets/icons/onboarding/dataset-attributes/DotIcon";
import ResizeIcon16 from "assets/icons/resize-icon/ResizeIcon16";
import BulkActionsPopup from "pages/side-bar/reports/components/BulkActionsPopup";
import { AppContext } from "common/hooks/context-hooks/use-app-context";

const TableStyled = styled("table")({
  borderCollapse: "collapse",
  backgroundColor: vantiColors.white,
  width: "100%"
});

const TableRow = styled("tr")(({ isHeader, isHovered }) => ({
  backgroundColor: isHeader ? vantiColors.lightBlue10 : isHovered ? vantiColors.gray35 : "inherit",
  position: isHeader && "sticky",
  top: isHeader && 0,
  zIndex: 100,
  width: "100%",
  height: 46.5,
  "& > td": {
    borderTop: `1px solid ${vantiColors.gray18}`,
    cursor: "pointer"
  },
  "& > th, td": {
    padding: 12,
    textAlign: "left",
    "&:not(:last-child)": {
      borderRight: `1px solid ${vantiColors.gray18}`
    }
  }
}));

const TableHeadCell = styled("th")(({ width, extraPadding }) => ({
  position: "relative",
  width,
  paddingLeft: extraPadding ? "32px !important" : "inherit"
}));

const TableHeadCellText = styled(Text)({
  display: "flex",
  alignItems: "center",
  columnGap: 8
});

const ClickableWrapper = styled("div")(({ isClickable, hidden }) => ({
  cursor: isClickable && "pointer",
  visibility: hidden ? "hidden" : "visible",
  display: "flex"
}));

const ResizeHandleStyled = styled("div")({
  position: "absolute",
  right: 0,
  bottom: 0,
  cursor: "col-resize",
  userSelect: "none",
  touchAction: "none",
  zIndex: 999,
  display: "flex",
  justifyContent: "center",
  width: 32,
  height: "100%",
  alignItems: "flex-end",
  paddingBottom: 8
});

const ResizeHandle = ({ onMouseDown, onTouchStart }) => {
  return (
    <ResizeHandleStyled onMouseDown={onMouseDown} onTouchStart={onTouchStart}>
      <ResizeIcon16 />
    </ResizeHandleStyled>
  );
};

const InnerSortSection = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: 8
});

const getAccessorKey = value => {
  if (!value) return "undefined";

  let newValue = "";

  const wordsSplit = value.split(" ");

  wordsSplit.forEach((word, index) => {
    if (index === 0) {
      newValue += word.toLowerCase();
    } else {
      newValue += word.charAt(0).toUpperCase() + word.toLowerCase().slice(1);
    }
  });

  return newValue;
};

const Table = ({
  headers = [],
  bodyData = [],
  onFilter = () => {},
  onSort = () => {},
  isSelectableRows,
  onSelectRow,
  setActionsPopupRef,
  setPinUnpinPopupRef,
  setCustomPopupRef,
  onWheel,
  onClickRow,
  selectedTableRows,
  displayRowMenu
}) => {
  const [originalBodyData, setOriginalBodyData] = useState(bodyData);
  const [clickedHeaderRef, setClickedHeaderRef] = useState(null);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [currentSearchInputPlaceholder, setCurrentSearchInputPlaceholder] = useState("");
  const [sortDirections, setSortDirections] = useState({});
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [hoveredHeaderIndex, setHoveredHeaderIndex] = useState(null);

  useEffect(() => {
    if (Object.keys(originalBodyData).length) return;

    if (bodyData.length) {
      setOriginalBodyData(bodyData);
    }
  }, [originalBodyData, bodyData]);

  const headersRef = useRef([]);

  const columns = useMemo(
    () =>
      headers?.map(header => ({
        header,
        accessorKey: getAccessorKey(header.title)
      })),
    [headers]
  );

  const searchPopupItems = useMemo(() => {
    if (!clickedHeaderRef?.textContent) return [];

    const titles = {};
    const items = [];

    originalBodyData.forEach((item, index) => {
      const accessorKey = getAccessorKey(clickedHeaderRef?.textContent);
      const title = item[accessorKey]?.props?.children;

      if (!titles[title]) {
        items.push({
          title,
          id: index,
          key: accessorKey
        });

        titles[title] = true;
      }
    });

    return items;
  }, [clickedHeaderRef, originalBodyData]);

  const table = useReactTable({
    data: bodyData,
    columns,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel()
  });

  // This useMemo is super important for performance, please be careful when touching it
  const TableBody = useMemo(() => {
    return (
      <tbody
        onMouseLeave={() => {
          setHoveredRowIndex(null);
        }}
        onWheel={onWheel}
      >
        {table.getRowModel().rows.map((row, rowIndex) => {
          const cells = row.getVisibleCells();

          return (
            <TableRow
              key={row.id}
              onMouseEnter={() => {
                setHoveredRowIndex(rowIndex);
              }}
              isHovered={hoveredRowIndex === rowIndex}
              onClick={() => (onClickRow && onClickRow(row)) || (onSelectRow && onSelectRow(row))}
            >
              {cells.map((cell, cellIndex) => {
                const isRowHovered = hoveredRowIndex === rowIndex;
                const isLastCell = cellIndex === cells.length - 1;
                const isRowSelected =
                  selectedTableRows.findIndex(innerRow => innerRow?.original?._id === row?.original?._id) >= 0;
                const cellHasCheckbox = cellIndex === 0;

                return (
                  <BodyCell
                    key={`${rowIndex}-${cellIndex}`}
                    cell={cell}
                    cellHasCheckbox={cellHasCheckbox}
                    renderedCellText={cell.renderValue()}
                    isRowHovered={isRowHovered}
                    isLastCell={isLastCell}
                    onSelectRow={row => {
                      onSelectRow(row);
                    }}
                    setActionsPopupRef={setActionsPopupRef}
                    setPinUnpinPopupRef={setPinUnpinPopupRef}
                    setCustomPopupRef={setCustomPopupRef}
                    isShowTooltip={cell.column.columnDef.header.showTooltipOnOverflowingText}
                    isRowSelected={isRowSelected}
                    displayRowMenu={displayRowMenu}
                  />
                );
              })}
            </TableRow>
          );
        })}
      </tbody>
    );
  }, [table, bodyData, isSelectableRows, hoveredRowIndex, selectedTableRows]);

  const onClickHeaderText = useCallback(
    (headerIndex, inputPlaceholder) => {
      setClickedHeaderRef(headersRef.current[headerIndex]);
      setCurrentSearchInputPlaceholder(inputPlaceholder);
    },
    [headersRef]
  );

  const onClickSortIcon = useCallback(
    title => {
      setSortDirections(sortDirections => {
        const accessorKey = getAccessorKey(title);

        const newDirection =
          sortDirections[accessorKey] === "ASC" || sortDirections[accessorKey] === "NONE" ? "DESC" : "ASC";

        onSort({
          key: accessorKey,
          direction: newDirection
        });

        const newDirections = { ...sortDirections };

        Object.keys(newDirections).map(key => {
          newDirections[key] = "NONE";
        });

        return { ...newDirections, [accessorKey]: newDirection };
      });
    },
    [onSort]
  );

  const onClickClosePopup = useCallback(() => {
    setClickedHeaderRef(null);
  }, []);

  const onSelectCallback = useCallback(selectedItems => {
    setSelectedFilterItems(selectedItems);
  }, []);

  useEffect(() => {
    onFilter({
      key: getAccessorKey(clickedHeaderRef?.textContent),
      filteredValues: selectedFilterItems
    });
  }, [onFilter, selectedFilterItems]);

  useEffect(() => {
    const sortDirections = {};

    headers.forEach(header => {
      const accessorKey = getAccessorKey(header.title);
      sortDirections[accessorKey] = header.isSortedByDefault ? "DESC" : "NONE";
    });

    setSortDirections(sortDirections);
  }, [headers]);

  return (
    <div>
      <TableStyled>
        {table.getHeaderGroups().map(headerGroup => (
          <TableRow key={headerGroup.id} isHeader={true}>
            {headerGroup.headers.map((header, headerIndex) => {
              const headerObject = header.column.columnDef.header;

              return (
                <TableHeadCell
                  key={headerObject.id}
                  colSpan={header.colSpan}
                  ref={el => (headersRef.current[headerIndex] = el)}
                  width={header.getSize()}
                  extraPadding={isSelectableRows && headerIndex === 0}
                  onMouseEnter={() => setHoveredHeaderIndex(headerIndex)}
                  onMouseLeave={() => setHoveredHeaderIndex(null)}
                >
                  <TableHeadCellText weight={500}>
                    <ClickableWrapper
                      isClickable={headerObject?.isFilterable}
                      onClick={() =>
                        headerObject?.isFilterable && onClickHeaderText(headerIndex, headerObject?.inputPlaceholder)
                      }
                    >
                      {flexRender(headerObject?.title, header.getContext())}
                    </ClickableWrapper>

                    <ClickableWrapper
                      isClickable={headerObject?.isSortable}
                      onClick={() => headerObject?.isSortable && onClickSortIcon(headerObject?.title)}
                    >
                      <InnerSortSection>
                        {sortDirections[getAccessorKey(headerObject.title)] !== "NONE" && (
                          <DotIcon color={vantiColors.darkBlue7} width={6} height={6} />
                        )}

                        {hoveredHeaderIndex === headerIndex && headerObject.isSortable && (
                          <ArrowIcon22
                            direction={sortDirections[getAccessorKey(headerObject.title)] === "ASC" ? "up" : "down"}
                          />
                        )}
                      </InnerSortSection>
                    </ClickableWrapper>
                  </TableHeadCellText>

                  {headerIndex < headers.length - 1 &&
                    header.column.getCanResize() &&
                    hoveredHeaderIndex === headerIndex && (
                      <ResizeHandle onMouseDown={header.getResizeHandler()} onTouchStart={header.getResizeHandler()} />
                    )}
                </TableHeadCell>
              );
            })}
          </TableRow>
        ))}

        {TableBody}
      </TableStyled>

      {clickedHeaderRef && (
        <SearchPopup
          ref={clickedHeaderRef}
          inputPlaceholder={currentSearchInputPlaceholder}
          items={searchPopupItems}
          onClose={onClickClosePopup}
          onSelectCallback={onSelectCallback}
          selectedFilterItems={selectedFilterItems}
          title={`Search ${clickedHeaderRef?.textContent?.toLowerCase()}`}
        />
      )}

      {selectedTableRows.length > 1 && displayRowMenu && <BulkActionsPopup selectedTableRows={selectedTableRows} />}
    </div>
  );
};

export default memo(Table);
