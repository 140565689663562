import React, { memo } from "react";

const NewProcessOptimizationIcon = ({ className }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M21.2857 31.2C21.2857 33.939 22.3108 36.4399 24 38.3437C25.6893 36.4399 26.7143 33.939 26.7143 31.2C26.7143 29.8671 26.4716 28.5907 26.0277 27.412C25.3708 27.5354 24.693 27.6 24 27.6C23.3071 27.6 22.6292 27.5354 21.9723 27.412C21.5285 28.5907 21.2857 29.8671 21.2857 31.2Z"
      fill="#86E8B6"
    />
    <path
      d="M21.9723 27.412C22.4406 26.1684 23.1328 25.0337 24 24.0563C22.0106 21.8141 19.0999 20.4 15.8572 20.4C15.1642 20.4 14.4864 20.4646 13.8295 20.588C15.1424 24.0746 18.2153 26.706 21.9723 27.412Z"
      fill="#86E8B6"
    />
    <path
      d="M32.1429 20.4C28.9002 20.4 25.9895 21.8141 24 24.0563C24.8673 25.0337 25.5594 26.1684 26.0277 27.412C29.7847 26.706 32.8576 24.0746 34.1706 20.588C33.5137 20.4646 32.8359 20.4 32.1429 20.4Z"
      fill="#86E8B6"
    />
    <path
      d="M21.9723 27.412C22.4406 26.1684 23.1328 25.0337 24 24.0563M21.9723 27.412C18.2153 26.706 15.1424 24.0746 13.8295 20.588M21.9723 27.412C22.6292 27.5354 23.3071 27.6 24 27.6C24.693 27.6 25.3708 27.5354 26.0277 27.412M21.9723 27.412C21.5285 28.5907 21.2857 29.8671 21.2857 31.2C21.2857 33.939 22.3108 36.4399 24 38.3437M24 24.0563C22.0106 21.8141 19.0999 20.4 15.8572 20.4C15.1642 20.4 14.4864 20.4646 13.8295 20.588M24 24.0563C25.9894 21.8141 28.9002 20.4 32.1429 20.4C32.8359 20.4 33.5137 20.4646 34.1706 20.588M24 24.0563C24.8673 25.0337 25.5594 26.1684 26.0277 27.412M13.8295 20.588C8.80232 21.5327 5.00003 25.9247 5.00003 31.2C5.00003 37.1647 9.86094 42 15.8572 42C19.0999 42 22.0106 40.5859 24 38.3437M13.8295 20.588C13.3856 19.4093 13.1429 18.1329 13.1429 16.8C13.1429 10.8353 18.0038 6 24 6C29.9963 6 34.8572 10.8353 34.8572 16.8C34.8572 18.1329 34.6145 19.4093 34.1706 20.588M34.1706 20.588C32.8576 24.0746 29.7847 26.706 26.0277 27.412M34.1706 20.588C39.1977 21.5327 43 25.9247 43 31.2C43 37.1647 38.1391 42 32.1429 42C28.9002 42 25.9894 40.5859 24 38.3437M26.0277 27.412C26.4716 28.5907 26.7143 29.8671 26.7143 31.2C26.7143 33.939 25.6893 36.4399 24 38.3437"
      stroke="black"
    />
  </svg>
);

export default memo(NewProcessOptimizationIcon);
