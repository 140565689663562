import React, { memo } from "react";
import { Text } from "components/ui";

import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import ChatIcon80 from "assets/icons/chat-icon/ChatIcon80";

const TreeViewEmptyStateContainer = styled("div")({
  height: "100%",
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  paddingTop: 80,
  gap: 24,
  alignItems: "center"
});

const CHAT_HISTORY_EMPTY_STATE_TEXT = "Your chat history will appear here";

const TreeViewEmptyState = () => {
  return (
    <TreeViewEmptyStateContainer data-testid={"tree-view-empty-state-container"}>
      <ChatIcon80 />
      <Text color={vantiColors.gray33} lineSize={"17px"} style={{ textAlign: "center" }}>
        {CHAT_HISTORY_EMPTY_STATE_TEXT}
      </Text>
    </TreeViewEmptyStateContainer>
  );
};

export default memo(TreeViewEmptyState);
