import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

//TrashIcon is 16px inside a 24px box
const TrashIcon16 = ({ stroke = vantiColors.gray29, fill = "none", ...rest }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer" }}
    {...rest}
  >
    <path d="M6 8.33588H18" stroke={stroke} />
    <path d="M7.84497 8.12978L8.62497 18.2366H15.375L16.1325 8.33588" stroke={stroke} />
    <path d="M9.32996 8.12977V6H14.7075V8.12977" stroke={stroke} />
    <path d="M10.875 10.9924V15.5725" stroke={stroke} />
    <path d="M13.125 10.9924V15.5725" stroke={stroke} />
  </svg>
);

export default memo(TrashIcon16);
