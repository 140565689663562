import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { styled, Tab, Tabs } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import DividerIcon from "assets/icons/DividerIcon";
import PlusIcon16 from "assets/icons/plus-icon/PlusIcon16";
import useDashboardRequest from "common/hooks/use-dashboard-request";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { Text } from "components/ui";
import { MAX_DASHBOARDS_PER_USER } from "@vanti-analytics-org/vanti-common";
import { DarkToolTip } from "components/ui/tooltips/tooltip";
import CloseIcon16 from "assets/icons/close-icon/CloseIcon16";
import CustomDeleteConfirmationDialog from "../data-page/components/CustomDeleteConfirmationDialog";

export const DEFAULT_NEW_BOARD_NAME_TITLE_TEXT = "New board";
export const MAX_DASHBOARDS_PER_USER_TOOLTIP_TEXT = `You have reached your dashboards limit (${MAX_DASHBOARDS_PER_USER}).
 If you would like to add new dashboard you must delete at least 1 dashboard.`;
const DELETE_DASHBOARD_WARNING_TEXT =
  "Deleting this dashboard will delete all of its widgets, reports and metrics for all users who have access to it!";

const HomePageTabs = ({ addNewDashboardCallback }) => {
  const { dashboardContext } = useContext(AppContext);
  const {
    dashboards,
    selectedDashboardTab,
    dashboardIdToTabIndex,
    setSelectedDashboardTab,
    setDashboardIdToTabIndex,
    setCurrentDashboard
  } = dashboardContext;
  const { getDashboardsRequest } = useDashboardRequest();
  const [isAddDashboardTabHovered, setIsAddDashboardTabHovered] = useState(false);
  const [hoveredDashboardId, setHoveredDashboardId] = useState(false);
  const [displayDashboardDeletionDialogId, setDisplayDashboardDeletionDialogId] = useState(null);
  const { deleteDashboardsRequest } = useDashboardRequest();

  useEffect(() => {
    getDashboardsRequest.mutate();
  }, []);

  const isAllowedToAddDashboard = Object.keys(dashboards).length < MAX_DASHBOARDS_PER_USER;

  const handleChange = useCallback(
    (event, newValue) => {
      if (!isAllowedToAddDashboard && Object.keys(dashboardIdToTabIndex).length === newValue) {
        return;
      }
      setSelectedDashboardTab(newValue);
      setCurrentDashboard(dashboards[(dashboardIdToTabIndex[newValue]?.dashboardId)]);
    },
    [dashboards, dashboardIdToTabIndex]
  );

  const tabs = useMemo(() => {
    let dashboardsListWithDividerTabs = [];
    let localDashboardIdToTabIndexList = [];
    let i = 0;
    for (const dashboard of Object.values(dashboards)) {
      dashboardsListWithDividerTabs.push(
        <Tab
          onMouseEnter={() => setHoveredDashboardId(dashboard._id)}
          onMouseLeave={() => setHoveredDashboardId(false)}
          label={
            <TabLabelWrapper>
              <DashboardTabText capitalize={true} lineSize={"normal"} isHovered={hoveredDashboardId === dashboard._id}>
                {dashboard.name || DEFAULT_NEW_BOARD_NAME_TITLE_TEXT}
              </DashboardTabText>
              {hoveredDashboardId === dashboard._id && (
                <CloseIcon16Styled onClick={() => setDisplayDashboardDeletionDialogId(hoveredDashboardId)} />
              )}
            </TabLabelWrapper>
          }
          disableRipple={true}
          key={`home-page-tab-${dashboard.name}-${i}`}
        />
      );
      dashboardsListWithDividerTabs.push(
        <TabStyled icon={<DividerIcon />} disabled key={`home-page-tab-${dashboard.name}-${i + 1}`} />
      );

      localDashboardIdToTabIndexList.push({ tabIndex: i++, dashboardId: dashboard._id });
      localDashboardIdToTabIndexList.push({ tabIndex: i++, dashboardId: null });
    }
    setDashboardIdToTabIndex(localDashboardIdToTabIndexList);
    setCurrentDashboard(dashboards[(localDashboardIdToTabIndexList[selectedDashboardTab || 0]?.dashboardId)]);

    return dashboardsListWithDividerTabs;
  }, [dashboards, selectedDashboardTab, hoveredDashboardId]);

  const addDashboardTab = useMemo(() => {
    return (
      <DarkToolTip
        open={isAddDashboardTabHovered && !isAllowedToAddDashboard}
        title={MAX_DASHBOARDS_PER_USER_TOOLTIP_TEXT}
      >
        <AddTabStyled
          onClick={isAllowedToAddDashboard ? addNewDashboardCallback : () => {}}
          icon={<PlusIcon16 stroke={vantiColors.gray40} />}
          disableRipple={true}
          onMouseEnter={() => setIsAddDashboardTabHovered(true)}
          onMouseLeave={() => setIsAddDashboardTabHovered(false)}
          isAllowedToAddDashboard={isAllowedToAddDashboard}
        />
      </DarkToolTip>
    );
  }, [dashboards, addNewDashboardCallback, isAddDashboardTabHovered]);

  return (
    <>
      <HomePageTabsStyled value={selectedDashboardTab} onChange={handleChange} aria-label="home page tabs">
        {tabs}
        {addDashboardTab}
      </HomePageTabsStyled>
      <CustomDeleteConfirmationDialog
        isOpenedCondition={displayDashboardDeletionDialogId}
        entity={{ type: "dashboard", name: dashboards[displayDashboardDeletionDialogId]?.name || "" }}
        deletionWarningText={DELETE_DASHBOARD_WARNING_TEXT}
        onCloseCallback={() => setDisplayDashboardDeletionDialogId(null)}
        onClickCancelCallback={() => setDisplayDashboardDeletionDialogId(null)}
        onClickDeleteCallback={() => {
          deleteDashboardsRequest.mutate({ dashboardIds: [displayDashboardDeletionDialogId] });
          setDisplayDashboardDeletionDialogId(null);
        }}
        isDisabled={false}
      />
    </>
  );
};

const HomePageTabsStyled = styled(Tabs)({
  "& .MuiTab-wrapper": {
    color: vantiColors.darkBlue6,
    fontSize: 14,
    padding: "7.5px 6px",
    fontWeight: 400,
    lineHeight: "16.94px",
    fontFamily: "Inter"
  },
  "& .MuiTabs-indicator": {
    backgroundColor: vantiColors.darkBlue7,
    bottom: "unset",
    height: 1
  },
  "& .MuiTab-root": {
    height: "100%",
    minWidth: 0,
    minHeight: 0,
    padding: 0
  }
});

const TabStyled = styled(Tab)({
  "& .MuiTab-wrapper": {
    paddingBottom: 0,
    paddingTop: 0
  },
  "&.Mui-disabled": {
    opacity: 1
  },
  alignSelf: "center"
});

const AddTabStyled = styled(Tab)(({ isAllowedToAddDashboard = { isAllowedToAddDashboard } }) => ({
  "&:hover": {
    backgroundColor: vantiColors.lightGray13,
    clipPath: "circle(40%)",
    cursor: isAllowedToAddDashboard ? "pointer" : "help"
  }
}));

const DashboardTabText = styled(Text)(({ isHovered }) => {
  const defaultProps = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    alignSelf: "center",
    minWidth: 20,
    maxWidth: 200,
    transition: "all ease-in-out 0.2s"
  };
  const gradColor = vantiColors.gray29;
  const textFadeoutProps = {
    backgroundImage: `linear-gradient(90deg, ${gradColor}, #ffffff00)`,
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent"
  };

  return isHovered ? { ...defaultProps, ...textFadeoutProps } : defaultProps;
});

const TabLabelWrapper = styled("div")({
  maxWidth: "100%",
  display: "flex",
  alignItems: "flex-end",
  position: "relative"
});

export const CloseIcon16Styled = styled(CloseIcon16)({
  position: "absolute",
  right: "-4px",
  cursor: "pointer",
  backgroundColor: "transparent",
  clipPath: "circle(50%)",
  transition: "all ease-in-out 0.3s",
  "&:hover": {
    backgroundColor: vantiColors.lightGray13
  }
});

export default memo(HomePageTabs);
