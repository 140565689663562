import React, { useEffect, useState } from "react";
import * as adminSandboxService from "services/admin/sandbox";
import * as utilsServices from "services/api/utils";
import Card from "components/ui/Card/Card";
import CardBody from "components/ui/Card/CardBody";
import CardHeader from "components/ui/Card/CardHeader";
import FlexContainer from "components/ui/Flex/FlexContainer";
import FlexItem from "components/ui/Flex/FlexItem";
import Text from "components/ui/Typography/Text";
import BrandedTitle from "components/ui/Typography/BrandedTitle";
import { useParams } from "react-router-dom";
import Button from "components/ui/Buttons/Button";
import TextUnderlinedInput from "components/ui/Inputs/TextUnderlinedInput";
import { useHistory } from "react-router-dom";
import CustomSelect from "components/ui/Select/CustomSelect";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as BackButtonIcon } from "../../../assets/img/back-button.svg";
import useBlock from "common/hooks/use-block";

export default function SandboxEdit() {
  const { blockId } = useParams();
  const history = useHistory();
  const block = useBlock(null, blockId);
  const [fetchError, setFetchError] = useState(null);
  const [dataSource, setDataSource] = useState("");
  const [infoState, setInfoState] = useState({ sending: false, success: null, error: null });
  const [blockDataSource, setBlockDataSource] = useState([]);
  const { title: blockTitle, metadata: blockMetadata } = block || {};
  const { dataSource: blockMetadataDataSource } = blockMetadata || {};

  useEffect(() => {
    utilsServices
      .getBlockDataSourceEnums()
      .then(setBlockDataSource)
      .catch(setFetchError);
  }, []);

  useEffect(() => {
    setDataSource(blockMetadataDataSource);
  }, [blockMetadataDataSource]);

  const onUpdateInfo = () => {
    setInfoState({ sending: true, success: null, error: null });
    adminSandboxService
      .updateSandboxMetadata(blockId, { dataSource })
      .then(() => {
        setInfoState({ sending: false, success: true, error: null });
      })
      .catch(error => {
        setInfoState({ sending: false, success: false, error: error });
      });
  };

  return (
    <>
      <FlexContainer alignItems={"center"} direction="column">
        <FlexItem xs={12} sm={12} md={12} container justifyContent="flex-start">
          <Button transparent={true} style={{ padding: "0 10px 0 0" }} onClick={() => history.push("/admin/sandbox")}>
            <BackButtonIcon />
          </Button>
          <BrandedTitle text="Edit Sandbox Block" size="30px" />
        </FlexItem>
        {fetchError && (
          <FlexContainer justifyContent="center" alignItems="center">
            <FlexItem xs={12} sm={12} md={12} container direction="column">
              <Text color="danger">{fetchError.message}</Text>
            </FlexItem>
          </FlexContainer>
        )}
        <FlexItem xs={12} sm={12} md={12} container>
          <Card>
            <CardHeader bottomBorder>
              <Text color="darkBlue" size="20px" weight="bold">
                Info
              </Text>
            </CardHeader>
            <CardBody>
              <FlexContainer>
                <FlexItem xs={12} sm={12} md={12} container direction="column">
                  <TextUnderlinedInput
                    labelText="ID"
                    id="email-edit-id"
                    formControlProps={{}}
                    labelProps={{ shrink: true }}
                    inputProps={{ value: blockId, disabled: true }}
                  />
                  <TextUnderlinedInput
                    labelText="Title"
                    id="email-edit-title"
                    formControlProps={{}}
                    labelProps={{ shrink: true }}
                    inputProps={{ value: blockTitle, disabled: true }}
                  />
                  <CustomSelect
                    labelText="Action"
                    color="green"
                    id={"user-type-select"}
                    formControlProps={{ style: { margin: "0" } }}
                    labelProps={{}}
                    selectProps={{
                      value: dataSource,
                      displayEmpty: true,
                      onChange: event => {
                        setDataSource(event.target.value);
                      }
                    }}
                    inputProps={{}}
                  >
                    {blockDataSource.map(action => (
                      <MenuItem value={action} key={action}>
                        {action}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FlexItem>
                <FlexItem xs={12} sm={12} md={12} container direction="column">
                  <Button color="green" disabled={!dataSource} onClick={onUpdateInfo}>
                    Update
                  </Button>
                  {infoState.success === true && <Text color="green">Success</Text>}
                  {infoState.success === false && (
                    <Text color="danger">Error {infoState.error && infoState.error.message}</Text>
                  )}
                </FlexItem>
              </FlexContainer>
            </CardBody>
          </Card>
        </FlexItem>
      </FlexContainer>
    </>
  );
}
