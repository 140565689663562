import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    padding: 0,
    borderBottom: "none"
  },
  labelContainer: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center"
  },
  label: {
    color: theme.palette.vantiColors.darkBlue6,
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: "0.02em !important",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  hint: {
    color: theme.palette.vantiColors.darkBlue1,
    fontSize: "12px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    display: "block",
    textOverflow: "ellipsis"
  },
  chevronRotateToUp: {
    transform: "rotate(180deg)"
  },
  chevronHide: {
    display: "none"
  }
}));
