import React, { useEffect, useState } from "react";
import { styled } from "@material-ui/core";
import * as emailServices from "services/admin/emails";
import Card from "components/ui/Card/Card";
import CardBody from "components/ui/Card/CardBody";
import FlexContainer from "components/ui/Flex/FlexContainer";
import FlexItem from "components/ui/Flex/FlexItem";
import Text from "components/ui/Typography/Text";
import BrandedTitle from "components/ui/Typography/BrandedTitle";
import Button from "components/ui/Buttons/Button";
import { useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "components/ui/Modal";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import TextUnderlinedInput from "components/ui/Inputs/TextUnderlinedInput";
import { ReactComponent as BackButtonIcon } from "../../../assets/img/back-button.svg";

const AdminEmailListContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  overflowY: "auto"
}));

export default function EmailsList() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [fetchError, setFetchError] = useState(null);
  const [emails, setEmails] = useState([]);
  const [createState, setCreateState] = useState({ sending: false, success: null, error: null });
  const history = useHistory();

  useEffect(() => {
    emailServices
      .queryEmail()
      .then(setEmails)
      .catch(setFetchError);
  }, []);

  const onCreate = () => {
    setCreateState({ sending: true, success: null, error: null });
    emailServices
      .createEmail(title)
      .then(() => {
        setCreateState({ sending: false, success: true, error: null });
        emailServices.queryEmail().then(setEmails);
        setIsModalOpen(false);
      })
      .catch(error => {
        setCreateState({ sending: false, success: false, error: error });
      });
  };

  return (
    <AdminEmailListContainer>
      <Modal open={isModalOpen} paperProps={{}}>
        <ModalHeader>
          <FlexContainer justifyContent={"center"}>
            <FlexItem xs={12} sm={12} md={12} container alignItems="center" justifyContent="space-between">
              <Text color="darkBlue" size="18px" weight="bold">
                New Email
              </Text>
              <Button transparent={true} onClick={() => setIsModalOpen(false)} style={{ padding: "0" }}>
                <CloseIcon />
              </Button>
            </FlexItem>
          </FlexContainer>
        </ModalHeader>
        <ModalBody>
          <FlexContainer justifyContent="center" direction="column">
            <FlexItem xs={12} sm={12} md={12}>
              <TextUnderlinedInput
                labelText="Title"
                id="email-create"
                formControlProps={{ fullWidth: true }}
                labelProps={{ shrink: true }}
                inputProps={{
                  value: title,
                  onChange: e => setTitle(e.target.value)
                }}
              />
            </FlexItem>
          </FlexContainer>
        </ModalBody>
        <ModalFooter>
          <FlexContainer>
            <FlexItem xs={12} sm={4} md={4} container direction="column">
              <Button onClick={onCreate} disabled={!title} color={"green"} weight={"bold"}>
                Create
              </Button>
              {createState.success === false && (
                <Text color="danger">Error {createState.error && createState.error.message}</Text>
              )}
            </FlexItem>
          </FlexContainer>
        </ModalFooter>
      </Modal>
      <FlexContainer alignItems={"center"} direction="column">
        <FlexItem xs={12} sm={10} md={8} container alignItems="center" justifyContent="space-between">
          <div style={{ display: "flex" }}>
            <Button transparent={true} style={{ padding: "0 10px 0 0" }} onClick={() => history.push("/admin")}>
              <BackButtonIcon />
            </Button>
            <BrandedTitle text="Emails" size="30px" />
          </div>
          <Button transparent={true} onClick={() => setIsModalOpen(true)} style={{ padding: "0" }}>
            <AddIcon />
          </Button>
        </FlexItem>
        {fetchError && (
          <FlexContainer justifyContent="center" alignItems="center">
            <FlexItem xs={12} sm={8} md={8} container direction="column">
              <Text color="danger">{fetchError.message}</Text>
            </FlexItem>
          </FlexContainer>
        )}
        {emails.map(email => (
          <FlexItem xs={12} sm={10} md={8} container key={email.id}>
            <Card>
              <CardBody>
                <FlexContainer justifyContent="center" alignItems="center" key={email.id}>
                  <FlexItem xs={12} sm={8} md={8} container direction="column">
                    <Text color="darkBlue" size="16px" weight="bold">
                      {email.title}
                    </Text>
                    {email.action && (
                      <Text color="darkBlue" size="14px">
                        Action:{" "}
                        <Text color="danger" size="14px">
                          {email.action}
                        </Text>
                      </Text>
                    )}
                  </FlexItem>
                  <FlexItem xs={12} sm={8} md={4} container justifyContent="flex-end" alignItems="center">
                    <Button color="lightGrayBlue" onClick={() => history.push(`/admin/emails/${email.id}/edit`)}>
                      Edit
                    </Button>
                    <Button color="green" onClick={() => history.push(`/admin/emails/${email.id}/send`)}>
                      Send
                    </Button>
                  </FlexItem>
                </FlexContainer>
              </CardBody>
            </Card>
          </FlexItem>
        ))}
      </FlexContainer>
    </AdminEmailListContainer>
  );
}
