import React, { memo, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import CardFooter from "components/ui/Card/CardFooter";
import FlexContainer from "components/ui/Flex/FlexContainer.js";
import FlexItem from "components/ui/Flex/FlexItem.js";
import Text from "components/ui/Typography/Text";
import Loader from "components/ui/Loader";
import GeneralVantiButton from "components/ui/Buttons/GeneralVantiButton";

import { roseColor, blueColor, darkBlueColor, vantiColors } from "assets/jss/vanti-dashboard";

import SuccessIcon from "assets/icons/SuccessIcon";

import useStyles from "./styles";

export const ConfirmAccountFooter = ({ error }) => {
  const classes = useStyles();

  const {
    formState: { isSubmitSuccessful, isSubmitting, isValid }
  } = useFormContext();

  const buttonInfo = useMemo(() => {
    return {
      disabled: !isValid || isSubmitting || (isSubmitSuccessful && error)
    };
  }, [isValid, isSubmitting, isSubmitSuccessful, error]);

  return (
    <CardFooter className={classes.cardFooter}>
      <>
        <FlexContainer justifyContent="center">
          <FlexItem xs={6} className={classes.buttonContainer}>
            {!isSubmitSuccessful && (
              <GeneralVantiButton
                buttonInfo={buttonInfo}
                type="submit"
                className={classes.getStartedButton}
                data-testid={"verify-email-get-started-btn"}
              >
                <Text
                  style={{ letterSpacing: "0.6px !important" }}
                  color={buttonInfo.disabled ? vantiColors.blue2 : vantiColors.darkBlue1}
                >
                  {isSubmitting && <Loader />}
                  {!isSubmitting && isSubmitSuccessful && <SuccessIcon />}
                  {"GET STARTED"}
                </Text>
              </GeneralVantiButton>
            )}

            {error && (
              <Text
                className={classes.error}
                color={vantiColors.rose6}
                size="12px"
                weight="regular"
                data-testid="verify-email-error-text"
              >
                {error}
              </Text>
            )}
          </FlexItem>
        </FlexContainer>
      </>
    </CardFooter>
  );
};

export default memo(ConfirmAccountFooter);
