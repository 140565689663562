import makeStyles from "@material-ui/core/styles/makeStyles";
import { vantiColors } from "assets/jss/palette";

export default makeStyles(({ spacing }) => ({
  popoverContent: {
    boxShadow: "0px 8px 20px rgba(135, 155, 195, 0.2)",
    border: `1px solid ${vantiColors.gray18}`
  },
  itemsContainer: {
    position: "relative"
  },
  searchContainer: {
    borderBottom: `1px solid ${vantiColors.lightGray3}`,
    backgroundColor: vantiColors.gray18,
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: 999
  },
  chip: props => {
    return {
      backgroundColor: props.backgroundColor,
      color: props.textColor,
      "&:hover": {
        backgroundColor: props.hoverBackgroundColor
      }
    };
  },
  defaultChip: {
    color: vantiColors.darkBlue4,
    backgroundColor: vantiColors.gray18,
    "&:hover": {
      backgroundColor: vantiColors.gray21
    }
  },
  chipItem: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: vantiColors.gray18
    },
    height: "50px",
    padding: spacing(0, 1)
  },
  checkboxRoot: {
    padding: "0px"
  },
  checkboxFormControl: {
    margin: "0px",
    padding: "0px"
  },
  circularProgressSection: {
    position: "absolute",
    top: "105px",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    backgroundColor: vantiColors.lightGray9,
    zIndex: 999
  },
  textFieldUnderline: {
    "&::after": {
      border: `1px solid ${vantiColors.lightGray1}`
    }
  },
  item: {
    flex: "1",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    height: "100%",
    "&:hover": {
      backgroundColor: vantiColors.gray18
    },
    "&:active": {
      backgroundColor: vantiColors.lightGray4
    },
    "&:hover $button": {
      opacity: "1"
    }
  },
  textWrapper: {
    height: "100%",
    flex: "1",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  highlight: {
    padding: spacing(0, 0.375),
    borderRadius: "1px",
    fontWeight: 500,
    color: vantiColors.darkBlue1,
    overflowX: "none"
  },
  searchWrapper: {
    margin: spacing(0, 1.5, 2, 1.5),
    boxSizing: "border-box",
    width: "100%",
    overflow: "hidden"
  },
  create: {
    fontSize: "12px",
    fontFamily: "Inter",
    color: vantiColors.darkBlue4,
    backgroundColor: vantiColors.lightGray1,
    borderRadius: "2px",
    boxSizing: "border-box",
    overflowX: "auto",
    fontWeight: 400,
    cursor: "pointer",
    padding: spacing(1)
  },
  createLine: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    boxSizing: "border-box",
    alignItems: "center",
    gridGap: spacing(1)
  },
  button: {
    padding: spacing(0.5),
    zIndex: 2,
    whiteSpace: "unset",
    transition: "opacity .25s ease-in-out",
    backgroundColor: "transparent !important"
  },
  buttonHidden: {
    opacity: "0"
  }
}));
