import { useContext } from "react";
import { useMutation } from "react-query";
import * as dataEntitiesApi from "services/api/data-entity";
import { DATA_ENTITIES_API_KEYS } from "services/api/data-entity";
import { AppContext } from "./context-hooks/use-app-context";

const useDataEntityRequest = () => {
  const { dataEntitiesContext } = useContext(AppContext);
  const { dataEntities, setDataEntities } = dataEntitiesContext;

  const getDataEntitiesRequest = useMutation(
    [DATA_ENTITIES_API_KEYS.GET_DATA_ENTITIES_REQUEST_KEY],
    () => dataEntitiesApi.getDataEntities(),
    {
      skip: Object.values(dataEntities).length,
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: entities => {
        if (!entities || !entities.length) {
          return;
        }

        const mappedEntities = {};

        entities.forEach(entity => {
          mappedEntities[entity._id] = entity;
        });

        setDataEntities(mappedEntities);
      }
    }
  );

  const getTable = useMutation(
    DATA_ENTITIES_API_KEYS.GET_DATA_ENTITY_TABLE_REQUEST_KEY,
    ({ tableId, itemsPerPage, desiredPageNumber }) =>
      dataEntitiesApi.getTable(tableId, itemsPerPage, desiredPageNumber),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false
    }
  );

  const updateDataEntityRequest = useMutation(
    [DATA_ENTITIES_API_KEYS.UPDATE_DATA_ENTITY_REQUEST_KEY],
    ({ dataEntityId, updateFields }) => dataEntitiesApi.updateDataEntity(dataEntityId, updateFields),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: entity => {
        if (!entity) {
          return;
        }

        const result = dataEntities[entity._id];
        if (result) {
          const entitiesCopy = { ...dataEntities };
          entitiesCopy[entity._id] = { ...result };
          setDataEntities(entitiesCopy);
        }
      }
    }
  );

  const addActivityRequest = useMutation(
    [DATA_ENTITIES_API_KEYS.ADD_ACTIVITY_REQUEST],
    ({ dataEntityId, text }) => dataEntitiesApi.addActivity(dataEntityId, text),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: activity => {
        if (!activity) {
          return;
        }

        if (dataEntities[activity.dataEntityId]) {
          const entitiesCopy = { ...dataEntities };
          entitiesCopy[activity.dataEntityId].activity.push(activity);
          setDataEntities(entitiesCopy);
        }
      }
    }
  );

  const updateActivityRequest = useMutation(
    [DATA_ENTITIES_API_KEYS.UPDATE_ACTIVITY_REQUEST],
    ({ dataEntityId, activityId, text }) => dataEntitiesApi.updateActivity(dataEntityId, activityId, text),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: activity => {
        if (!activity) {
          return;
        }

        if (dataEntities[activity.dataEntityId]) {
          const entitiesCopy = { ...dataEntities };
          const index = entitiesCopy[activity.dataEntityId].activity.findIndex(a => a._id === activity._id);
          if (index !== -1) {
            entitiesCopy[activity.dataEntityId].activity[index] = { ...activity };
          }
          setDataEntities(entitiesCopy);
        }
      }
    }
  );

  const deleteActivityRequest = useMutation(
    [DATA_ENTITIES_API_KEYS.DELETE_ACTIVITY_REQUEST],
    ({ dataEntityId, activityId }) => dataEntitiesApi.deleteActivity(dataEntityId, activityId),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: activity => {
        if (!activity) {
          return;
        }

        if (dataEntities[activity.dataEntityId]) {
          const entitiesCopy = { ...dataEntities };
          const index = entitiesCopy[activity.dataEntityId].activity.findIndex(a => a._id === activity._id);
          if (index !== -1) {
            entitiesCopy[activity.dataEntityId].activity.splice(index, 1);
          }
          setDataEntities(entitiesCopy);
        }
      }
    }
  );

  const bindDatasetsToDataEntityRequest = useMutation(
    [DATA_ENTITIES_API_KEYS.BIND_DATASETS_TO_DATA_ENTITY_REQUEST],
    ({ dataEntityId, entries }) => dataEntitiesApi.bindDataEntityDataset(dataEntityId, entries),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: entity => {
        if (!entity) {
          return;
        }

        const result = dataEntities[entity._id];
        if (result) {
          const entitiesCopy = { ...dataEntities };
          entitiesCopy[entity._id].datasets = [...entity.datasets];
          setDataEntities(entitiesCopy);
        }
      }
    }
  );

  const unbindDatasetsToDataEntityRequest = useMutation(
    [DATA_ENTITIES_API_KEYS.UNBIND_DATASETS_TO_DATA_ENTITY_REQUEST],
    ({ dataEntityId, datasetId }) => dataEntitiesApi.unbindDataEntityDataset(dataEntityId, datasetId),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: entity => {
        if (!entity) {
          return;
        }

        const result = dataEntities[entity._id];
        if (result) {
          const entitiesCopy = { ...dataEntities };
          entitiesCopy[entity._id].datasets = [...entity.datasets];
          setDataEntities(entitiesCopy);
        }
      }
    }
  );

  const addDataEntityRequest = useMutation(
    [DATA_ENTITIES_API_KEYS.ADD_DATA_ENTITY_REQUEST_KEY],
    ({ name }) => dataEntitiesApi.addDataEntity(name),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: entity => {
        if (!entity) {
          return;
        }

        const entitiesCopy = { ...dataEntities };
        entitiesCopy[entity._id] = { ...entity };
        setDataEntities(entitiesCopy);
      }
    }
  );

  const deleteDataEntityRequest = useMutation(
    [DATA_ENTITIES_API_KEYS.DELETE_DATA_ENTITY_REQUEST_KEY],
    ({ dataEntityId }) => dataEntitiesApi.deleteDataEntity(dataEntityId),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: entity => {
        if (!entity) {
          return;
        }

        const entitiesCopy = { ...dataEntities };
        if (entitiesCopy[entity._id]) {
          delete entitiesCopy[entity._id];
        }
        setDataEntities(entitiesCopy);
      }
    }
  );

  const addAlertRequest = useMutation(
    [DATA_ENTITIES_API_KEYS.ADD_ALERT_REQUEST_KEY],
    ({ userPrompt }) => dataEntitiesApi.addAlert(userPrompt),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: alert => {
        if (!alert) {
          return;
        }
      },
      onError: error => error
    }
  );

  return {
    getDataEntitiesRequest,
    updateDataEntityRequest,
    bindDatasetsToDataEntityRequest,
    unbindDatasetsToDataEntityRequest,
    addActivityRequest,
    updateActivityRequest,
    deleteActivityRequest,
    addDataEntityRequest,
    deleteDataEntityRequest,
    addAlertRequest,
    getTable
  };
};

export default useDataEntityRequest;
