import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const TrashIcon24 = ({ color = "none", stroke = vantiColors.gray39, ...rest }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer" }}
    {...rest}
  >
    <path d="M4 7.04498H20" stroke={stroke} />
    <path d="M6.45996 6.77496L7.49996 20.015H16.5L17.51 7.04496" stroke={stroke} />
    <path d="M8.43994 6.77499V3.98499H15.6099V6.77499" stroke={stroke} />
    <path d="M10.5 10.525V16.525" stroke={stroke} />
    <path d="M13.5 10.525V16.525" stroke={stroke} />
  </svg>
);

export default memo(TrashIcon24);
