import React, { memo } from "react";

const ZipIconBG80 = () => {
  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.166992" width="80" height="80" rx="40" fill="#F9F9F9" />
      <path
        d="M55.6683 61.0573H24.665V18.9428H45.2845L55.6683 28.0287V61.0573Z"
        stroke="#848B99"
        strokeWidth="2.33"
        strokeMiterlimit="10"
      />
      <path d="M23.5 52.7778H56.8333V42.7778H23.5V52.7778Z" fill="#848B99" />
      <path d="M44.333 19.167V28.8892H55.0552" stroke="#848B99" strokeWidth="2.33" />
      <path
        d="M33.4795 50.8077V49.9732L36.424 45.9367H33.4825V44.7471H38.2765V45.5816L35.332 49.618H38.2735V50.8077H33.4795Z"
        fill="white"
      />
      <path d="M40.9289 44.7471V50.8077H39.4641V44.7471H40.9289Z" fill="white" />
      <path
        d="M42.1402 50.8077V44.7471H44.6437C45.0975 44.7471 45.4891 44.8358 45.8186 45.0134C46.15 45.189 46.4055 45.4346 46.585 45.7503C46.7645 46.064 46.8543 46.4289 46.8543 46.8452C46.8543 47.2634 46.7626 47.6294 46.5791 47.9431C46.3976 48.2548 46.1382 48.4965 45.8008 48.6681C45.4635 48.8398 45.063 48.9256 44.5993 48.9256H43.0546V47.7715H44.3271C44.548 47.7715 44.7325 47.733 44.8805 47.656C45.0304 47.5791 45.1439 47.4716 45.2208 47.3335C45.2977 47.1934 45.3362 47.0306 45.3362 46.8452C45.3362 46.6578 45.2977 46.496 45.2208 46.3599C45.1439 46.2218 45.0304 46.1152 44.8805 46.0403C44.7305 45.9653 44.5461 45.9278 44.3271 45.9278H43.605V50.8077H42.1402Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(ZipIconBG80);
