import React from "react";

const WhiteExclamationMark = ({ width = "19", height = "19", viewBow = "0 0 19 19", ...rest }) => (
  <svg {...rest} width={width} height={height} viewBox={viewBow} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99976 14.7258C10.2331 14.7258 10.4248 14.6508 10.5748 14.5008C10.7248 14.3508 10.7998 14.1591 10.7998 13.9258C10.7998 13.6925 10.7248 13.5008 10.5748 13.3508C10.4248 13.2008 10.2331 13.1258 9.99976 13.1258C9.76642 13.1258 9.57476 13.2008 9.42476 13.3508C9.27476 13.5008 9.19976 13.6925 9.19976 13.9258C9.19976 14.1591 9.27476 14.3508 9.42476 14.5008C9.57476 14.6508 9.76642 14.7258 9.99976 14.7258ZM9.24976 11.0758H10.7498V5.07579H9.24976V11.0758ZM9.99976 19.5008C8.68309 19.5008 7.44576 19.2508 6.28776 18.7508C5.12909 18.2508 4.12476 17.5758 3.27476 16.7258C2.42476 15.8758 1.74976 14.8715 1.24976 13.7128C0.749756 12.5548 0.499756 11.3175 0.499756 10.0008C0.499756 8.68413 0.749756 7.44646 1.24976 6.28779C1.74976 5.12979 2.42476 4.12579 3.27476 3.27579C4.12476 2.42579 5.12909 1.75079 6.28776 1.25079C7.44576 0.750793 8.68309 0.500793 9.99976 0.500793C11.3164 0.500793 12.5541 0.750793 13.7128 1.25079C14.8708 1.75079 15.8748 2.42579 16.7248 3.27579C17.5748 4.12579 18.2498 5.12979 18.7498 6.28779C19.2498 7.44646 19.4998 8.68413 19.4998 10.0008C19.4998 11.3175 19.2498 12.5548 18.7498 13.7128C18.2498 14.8715 17.5748 15.8758 16.7248 16.7258C15.8748 17.5758 14.8708 18.2508 13.7128 18.7508C12.5541 19.2508 11.3164 19.5008 9.99976 19.5008ZM9.99976 18.0008C12.2331 18.0008 14.1248 17.2258 15.6748 15.6758C17.2248 14.1258 17.9998 12.2341 17.9998 10.0008C17.9998 7.76746 17.2248 5.87579 15.6748 4.32579C14.1248 2.77579 12.2331 2.00079 9.99976 2.00079C7.76642 2.00079 5.87476 2.77579 4.32476 4.32579C2.77476 5.87579 1.99976 7.76746 1.99976 10.0008C1.99976 12.2341 2.77476 14.1258 4.32476 15.6758C5.87476 17.2258 7.76642 18.0008 9.99976 18.0008Z"
      fill="white"
    />
  </svg>
);

export default WhiteExclamationMark;
