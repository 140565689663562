import { createSelector } from "reselect";

const createAndAssignProduct = state => state.station?.operations?.createAndAssignProduct;

export const loadingSelector = stationId =>
  createSelector(
    createAndAssignProduct,
    state => state.loading[stationId]
  );

export const errorSelector = stationId =>
  createSelector(
    createAndAssignProduct,
    state => state.error[stationId]
  );
export const successSelector = stationId =>
  createSelector(
    createAndAssignProduct,
    state => state.success[stationId]
  );
