import React from "react";
import Lottie from "react-lottie-player";

import { ReactComponent as VantiFullLogo } from "assets/icons/VantiFullLogo.svg";
import MonitorAnimation from "assets/animations/Monitor.json";
import useStyles from "./MobileRestrict.styles";

const MobileRestrict = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.lottieWrapper}>
          <Lottie loop animationData={MonitorAnimation} play />
        </div>
        <div className={classes.mainText}>Mobile version is not available yet</div>
        <div className={classes.secondaryText}>To enjoy vanti, please use a desktop device</div>
        <div className={classes.logo}>
          <VantiFullLogo />
        </div>
      </div>
    </div>
  );
};

export default MobileRestrict;
