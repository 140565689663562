import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { alertBeforeRefresh } from "utils/app-utils";

const useRequestWatcher = (response, onSuccessAction) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    window.addEventListener("beforeunload", alertBeforeRefresh);

    return () => {
      history.block(() => {
        if (!response?.value?.ok) {
          return true;
        }
      });

      window.removeEventListener("beforeunload", alertBeforeRefresh);
    };
  }, [response]);

  useEffect(() => {
    if (response?.value?.ok) {
      dispatch(onSuccessAction(response.value.data));
    }
  }, [response]);
};

export default useRequestWatcher;
