import React, { forwardRef, memo } from "react";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import { styled } from "@material-ui/core";

const styles = {
  flex: {
    width: "100%"
  }
};

const useStyles = makeStyles(styles);

const FlexContainer = (props, ref) => {
  const classes = useStyles();
  const { children, className, ...rest } = props;

  const flexClasses = classNames({
    [classes.flex]: true,
    [className]: className !== undefined
  });
  return (
    <Grid container {...rest} className={flexClasses} ref={ref}>
      {children}
    </Grid>
  );
};

FlexContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export const FlexRow = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between"
});

export const FlexColumn = styled(FlexRow)({
  flexDirection: "column"
});

export default memo(forwardRef(FlexContainer));
