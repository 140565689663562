import { createContext, useMemo, useRef, useState } from "react";

const defaultValue = {
  chatSessions: {},
  lastSystemMessageWithInlineButtonsRef: {},
  chatInputEnabled: true,
  displayOtherDatasets: false,
  chatList: [],
  chatsWithDisabledDatasetSelection: {},
  selectedChatId: null,
  sendChatMessageFunc: null,
  lastMessageInCurrentChat: null,
  chatMessageMetadataId: null,
  isChatLoading: false,
  firstItemIndex: 1000,
  setChatSessions: () => {},
  setChatInputEnabled: () => {},
  setDisplayOtherDatasets: () => {},
  setChatList: () => {},
  setChatsWithDisabledDatasetSelection: () => {},
  setSelectedChatId: () => {},
  setSendChatMessageFunc: () => {},
  setLastMessageInCurrentChat: () => {},
  setChatMessageMetadataId: () => {},
  setIsChatLoading: () => {},
  setFirstItemIndex: () => {}
};

export const ChatContext = createContext({ ...defaultValue });

export const useChatContext = () => {
  const [chatSessionsContext, setChatSessionsContext] = useState(defaultValue.chatSessions);
  const [chatInputEnabledContext, setChatInputEnabledContext] = useState(defaultValue.chatInputEnabled);
  const lastSystemMessageWithInlineButtonsRefContext = useRef(defaultValue.lastSystemMessageWithInlineButtonsRef);
  const [displayOtherDatasetsContext, setDisplayOtherDatasetsContext] = useState(defaultValue.displayOtherDatasets);
  const [chatListContext, setChatListContext] = useState(defaultValue.chatList);
  const [chatsWithDisabledDatasetSelection, setChatsWithDisabledDatasetSelection] = useState(
    defaultValue.chatsWithDisabledDatasetSelection
  );
  const [selectedChatId, setSelectedChatId] = useState(defaultValue.selectedChatId);
  const [sendChatMessageFunc, setSendChatMessageFunc] = useState(defaultValue.sendChatMessageFunc);
  const [lastMessageInCurrentChat, setLastMessageInCurrentChat] = useState(defaultValue.lastMessageInCurrentChat);
  const [chatMessageMetadataId, setChatMessageMetadataId] = useState(defaultValue.chatMessageMetadataId);
  const [isChatLoading, setIsChatLoading] = useState(defaultValue.isChatLoading);
  const [firstItemIndex, setFirstItemIndex] = useState(defaultValue.firstItemIndex);

  return useMemo(
    () => ({
      chatSessions: { ...chatSessionsContext },
      setChatSessions: setChatSessionsContext,
      lastSystemMessageWithInlineButtonsRef: lastSystemMessageWithInlineButtonsRefContext,
      chatInputEnabled: chatInputEnabledContext,
      setChatInputEnabled: setChatInputEnabledContext,
      displayOtherDatasets: displayOtherDatasetsContext,
      setDisplayOtherDatasets: setDisplayOtherDatasetsContext,
      chatList: chatListContext,
      setChatList: setChatListContext,
      chatsWithDisabledDatasetSelection,
      setChatsWithDisabledDatasetSelection,
      selectedChatId,
      setSelectedChatId,
      sendChatMessageFunc,
      setSendChatMessageFunc,
      lastMessageInCurrentChat,
      setLastMessageInCurrentChat,
      chatMessageMetadataId,
      setChatMessageMetadataId,
      isChatLoading,
      setIsChatLoading,
      firstItemIndex,
      setFirstItemIndex
    }),
    [
      chatSessionsContext,
      lastSystemMessageWithInlineButtonsRefContext,
      chatInputEnabledContext,
      displayOtherDatasetsContext,
      chatListContext,
      chatsWithDisabledDatasetSelection,
      selectedChatId,
      sendChatMessageFunc,
      lastMessageInCurrentChat,
      chatMessageMetadataId,
      isChatLoading,
      firstItemIndex
    ]
  );
};
