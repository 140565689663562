import React from "react";
import { Switch, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import EmailsAdmin from "./admin/EmailsAdmin";
import SandboxAdmin from "./admin/SandboxAdmin";
import Explorer from "./admin/Explorer";
import AdminPage from "./admin/AdminPage";

const useStyles = makeStyles(() => ({
  adminContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%"
  }
}));

export default function Admin() {
  const classes = useStyles();
  return (
    <div className={classes.adminContainer}>
      <Switch>
        <Route exact path="/admin" component={AdminPage} />
        <Route exact path="/admin/explorer" component={Explorer} />
        <Route path="/admin/emails" component={EmailsAdmin} />
        <Route path="/admin/sandbox" component={SandboxAdmin} />
      </Switch>
    </div>
  );
}
