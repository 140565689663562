import http from "../http";
import config from "common/config";

const dataEntitiesApi = `${config.serverUrl}/api/v2/data-entities`;
export const DATA_ENTITIES_API_KEYS = {
  GET_DATA_ENTITIES_REQUEST_KEY: "GET_DATA_ENTITIES_REQUEST_KEY",
  GET_DATA_ENTITY_TABLE_REQUEST_KEY: "GET_DATA_ENTITY_TABLE_REQUEST_KEY",
  UPDATE_DATA_ENTITY_REQUEST_KEY: "UPDATE_DATA_ENTITY_REQUEST_KEY",
  BIND_DATASETS_TO_DATA_ENTITY_REQUEST: "BIND_DATASETS_TO_DATA_ENTITY_REQUEST",
  UNBIND_DATASETS_TO_DATA_ENTITY_REQUEST: "UNBIND_DATASETS_TO_DATA_ENTITY_REQUEST",
  ADD_ACTIVITY_REQUEST: "ADD_ACTIVITY_REQUEST",
  UPDATE_ACTIVITY_REQUEST: "UPDATE_ACTIVITY_REQUEST",
  DELETE_ACTIVITY_REQUEST: "DELETE_ACTIVITY_REQUEST",
  ADD_DATA_ENTITY_REQUEST_KEY: "ADD_DATA_ENTITY_REQUEST_KEY",
  DELETE_DATA_ENTITY_REQUEST_KEY: "DELETE_DATA_ENTITY_REQUEST_KEY",
  ADD_ALERT_REQUEST_KEY: "ADD_ALERT_REQUEST_KEY"
};

export async function getDataEntities() {
  const res = await http.get(`${dataEntitiesApi}/`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function updateDataEntity(id, updateFields) {
  const res = await http.patch(`${dataEntitiesApi}/${id}`, updateFields);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function addActivity(id, text) {
  const res = await http.patch(`${dataEntitiesApi}/add-activity/${id}`, { text });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function updateActivity(dataEntityId, activityId, text) {
  const res = await http.patch(`${dataEntitiesApi}/update-activity/${dataEntityId}`, { activityId, text });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function deleteActivity(dataEntityId, activityId) {
  const res = await http.delete(`${dataEntitiesApi}/delete-activity/${dataEntityId}`, { activityId });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function bindDataEntityDataset(id, entries) {
  const res = await http.patch(`${dataEntitiesApi}/bind-dataset/${id}`, entries);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function unbindDataEntityDataset(id, datasetId) {
  const res = await http.patch(`${dataEntitiesApi}/unbind-dataset/${id}`, { datasetId });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function addDataEntity(name, description) {
  const res = await http.post(`${dataEntitiesApi}/`, { name, description });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function deleteDataEntity(dataEntityId) {
  const res = await http.delete(`${dataEntitiesApi}/${dataEntityId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function getTable(tableId, itemsPerPage, desiredPageNumber) {
  const res = await http.get(
    `${dataEntitiesApi}/table/${tableId}?itemsPerPage=${itemsPerPage}&desiredPageNumber=${desiredPageNumber}`
  );
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function runDataEntity(dataEntityId, isDryRun, curationCode) {
  const res = await http.post(`${dataEntitiesApi}/run`, {
    dataEntityId,
    isDryRun,
    curationCode
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function addAlert(userPrompt) {
  const res = await http.post(`${dataEntitiesApi}/add-alert`, {
    userPrompt
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}
