import config from "common/config";
import initEvaporate from "common/evaporate";
import { BATCH_TYPES } from "common/constants/CommonConstants";
import * as blockService from "../api/block.js";
import http from "../http";
import { logger } from "utils/logger";

export async function uploadBatchFile(batchType, blockId, userId, file, onProgress = null) {
  const bucket =
    batchType === BATCH_TYPES.PREDICT ? config.evaporate.batchPredictionsBucket : config.evaporate.batchFeedbacksBucket;
  const location = blockService.getBlockFileLocation(userId, blockId);
  const filePath = `${location}${file.newName || file.name}`;
  return await uploadFile(blockId, file, filePath, location, false, onProgress, bucket);
}

export async function uploadBlockFile(blockId, userId, file, isDataSetFile, onProgress) {
  const targetFileEntity = file.originalFile || file.fileEntity || file;
  const location = blockService.getBlockFileLocation(userId, blockId);
  const filePath = `${location}${targetFileEntity.path ?? targetFileEntity.name}`;
  return await uploadFile(blockId, file, filePath, location, isDataSetFile, onProgress, config.evaporate.bucket);
}

async function uploadFile(blockId, file, filePath, location, isDataSetFile, onProgress, bucket) {
  const isMinio = config.storage.type.toLowerCase() === "minio";

  //The passed 'file' argument is usually not a dead-simple js File object, but a File object with some additional properties
  //It may happen that the actual file that is needed for training is stored in the 'originalFile' property of the passed 'file' object
  //So we have to check it here and use the 'originalFile' property if it exists
  const targetDataFileObject = file.originalFile || file.fileEntity || file;

  logger.info(`Uploading file`, {
    bucket,
    filePath,
    isDataSetFile,
    storageType: isMinio ? "minio" : "s3"
  });

  if (isMinio) {
    const res = await http.get(`${config.serverUrl}/api/v2/blocks/presigned-upload-url`, {
      bucket: bucket,
      name: filePath
    });
    const result = await res.json();

    if (res.status > 200 || !result.ok) {
      throw new Error(result.message);
    }
    const reader = new FileReader(); //CAN WE SET THIS READER AS THE BODY OF THE FETCH PUT? like streams

    await new Promise(resolve => {
      //wait for the file to be loaded to the browser
      reader.addEventListener("load", async event => {
        const xhr = new XMLHttpRequest();

        xhr.upload.addEventListener("progress", event => {
          if (event.lengthComputable) {
            onProgress && onProgress(Math.round((event.loaded / event.total) * 100));
          }
        });

        //wait for the file to be loaded to storage
        xhr.addEventListener("loadend", async () => {
          onProgress && onProgress(100);
          if (isDataSetFile) {
            await blockService.setFile(blockId, location, file);
          }
          resolve(xhr.readyState === 4 && xhr.status === 200);

          return true;
        });

        xhr.open("PUT", result.uploadUrl, true);
        xhr.setRequestHeader("Content-Type", targetDataFileObject ? targetDataFileObject.type : file.type);
        xhr.send(event.target.result);
      });

      reader.readAsArrayBuffer(targetDataFileObject ?? file);
    });
  } else {
    await initEvaporate({
      name: filePath,
      file: targetDataFileObject,
      onUpdate: onProgress,
      bucket
    });
    if (isDataSetFile) {
      await blockService.setFile(blockId, location, file);
    }
    return true;
  }
}

export async function uploadFileDirectlyToUrl(s3Url, file) {
  const formData = new FormData();

  formData.file = file;

  return await http.request({
    method: "PUT",
    url: s3Url,
    useRawBody: true,
    body: formData.file,
    noAuth: true,
    headers: {
      "Content-Type": file.type
    }
  });
}
