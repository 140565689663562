import React, { memo } from "react";

import BodyCell from "../../table-body-rows/body-cell/BodyCell";
import ProductChip from "pages/dashboard/station/sections/products-and-lines/products/ProductChip";

const StationProductsCell = ({ stationName, stationId }) => {
  return (
    <BodyCell data-testid={`${stationName}-products`} align="left">
      <ProductChip
        chipProps={{
          onClick: e => {
            e.stopPropagation();
          }
        }}
        stationId={stationId}
      />
    </BodyCell>
  );
};

export default memo(StationProductsCell);
