import React, { memo, useEffect, useMemo, useState } from "react";
import classNames from "classnames";

import TextUnderlinedInput from "components/ui/Inputs/TextUnderlinedInput";
import { InputAdornment } from "@material-ui/core";
import NumberedSection from "./NumberedSection";
import { CONNECTOR_PAGE_MODES, NEW_CONNECTOR_SECTIONS } from "pages/side-bar/data-connectors/constants";

import useStyles from "./styles";

const ConnectorName = ({ onConnectorNameChange, pageMode, connectorName }) => {
  const classes = useStyles();
  const [name, setName] = useState(connectorName);
  const [error, setError] = useState(null);

  const isLocalNameEmpty = useMemo(() => !name || name?.trim() === "", [name]);

  const onChange = event => {
    const newValue = event.target.value;

    setName(newValue);

    if (pageMode === CONNECTOR_PAGE_MODES.EDIT_CONNECTOR) {
      onConnectorNameChange(newValue);
    }

    if (newValue?.trim() === "") {
      setError("Please provide the name of the connector");
    } else {
      setError(null);
    }
  };

  useEffect(() => {
    setName(connectorName);
  }, [connectorName]);

  return (
    <NumberedSection title={NEW_CONNECTOR_SECTIONS.dataConnectorName.title} dense="full">
      <TextUnderlinedInput
        id="connector-name"
        dataTestId="connector-name"
        shouldIncludeSpacing={false}
        error={!!error}
        helperText={error}
        color="green"
        formControlProps={{
          fullWidth: true,
          className: classes.formControl
        }}
        inputProps={{
          type: "text",
          value: name,
          placeholder: "Connector name",
          onChange,
          onKeyDown: event => {
            if (event.keyCode === 13 && !isLocalNameEmpty) {
              onConnectorNameChange && onConnectorNameChange(name);
            }
          },
          onBlur: () => {
            onConnectorNameChange && onConnectorNameChange(name);
          },
          endAdornment: (
            <InputAdornment
              position="end"
              className={classNames(classes.iconButton, {
                [classes.disabledConnectorNameIcon]: isLocalNameEmpty
              })}
            ></InputAdornment>
          )
        }}
      />
    </NumberedSection>
  );
};

export default memo(ConnectorName);
