import React, { memo } from "react";
import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import CloseIcon16 from "assets/icons/close-icon/CloseIcon16";
import ChatMessageReportDataPreviewTable from "./ChatMessageReportDataPreviewTable";
import ChatMessageDataPreviewTitle from "./ChatMessageDataPreviewTitle";
import GeneralModalPreviewComponent from "components/ui/Modal/GeneralModalPreviewComponent";

export const ReportDownloadText = styled("a")({
  color: vantiColors.darkBlue7,
  cursor: "pointer",
  "&:hover": {
    color: vantiColors.darkBlue7,
    textDecoration: "underline"
  }
});

const ChatMessageDataPreviewEnlargedHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: 24
});

const ChatMessageDataPreviewEnlargedBody = styled("div")({
  overflow: "auto"
});

const ChatMessageReportDataPreviewTableEnlarged = ({ isEnlargedPreview, message, dataset, setIsEnlargedPreview }) => {
  return (
    isEnlargedPreview && (
      <GeneralModalPreviewComponent
        onClose={() => {
          setIsEnlargedPreview(false);
        }}
      >
        <ChatMessageDataPreviewEnlargedHeader>
          <ChatMessageDataPreviewTitle message={message} dataset={dataset} />
          <CloseIcon16 onClick={() => setIsEnlargedPreview(false)} style={{ cursor: "pointer" }} />
        </ChatMessageDataPreviewEnlargedHeader>

        <ChatMessageDataPreviewEnlargedBody>
          {dataset?.enlargedPreview && (
            <ChatMessageReportDataPreviewTable
              isEnlargedPreview={isEnlargedPreview}
              lowOpacity={false}
              preview={dataset?.enlargedPreview}
            />
          )}
        </ChatMessageDataPreviewEnlargedBody>
      </GeneralModalPreviewComponent>
    )
  );
};

export default memo(ChatMessageReportDataPreviewTableEnlarged);
