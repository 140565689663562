import React, { forwardRef, memo } from "react";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";

const styles = {
  flex: {
    padding: "15px 15px !important"
  },
  fullDense: {
    padding: "0px !important"
  },
  verticalDense: {
    padding: "0px 15px !important"
  },
  horizontalDense: {
    padding: "15px 0px !important"
  },
  leftTopBottomDense: {
    padding: "0 15px 0 0 !important"
  },
  rightTopBottomDense: {
    padding: "0 0 0 15px !important"
  },
  topDense: {
    padding: "0 0 15px 0 !important"
  },
  bottomDense: {
    padding: "15px 0 0 0 !important"
  },
  customPadding: {
    padding: props => `${props.padding} !important`
  }
};

const useStyles = makeStyles(styles);

const FlexItem = (props, ref) => {
  const { children, className, dense, padding, ...rest } = props;
  const classes = useStyles({ padding });

  const flexClasses = classNames({
    [classes.flex]: true,
    [classes[`${dense}Dense`]]: dense,
    [classes.customPadding]: padding,
    [className]: className !== undefined
  });

  return (
    <Grid item {...rest} className={flexClasses} ref={ref}>
      {children}
    </Grid>
  );
};

FlexItem.propTypes = {
  children: PropTypes.node,
  dense: PropTypes.oneOf(["vertical", "horizontal", "top", "bottom", "leftTopBottom", "rightTopBottom", "full"]),
  className: PropTypes.string
};

export default memo(forwardRef(FlexItem));
