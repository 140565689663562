import { ACTION_TYPES } from "../actions";

const defaultState = {
  feedback: {
    result: ""
  },
  predict: {
    result: ""
  },
  isModalOpen: false
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.OPEN_MODAL: {
      return { ...state, isModalOpen: true };
    }
    case ACTION_TYPES.CLOSE_MODAL: {
      return { ...state, isModalOpen: false, predict: { result: "" } };
    }
    case ACTION_TYPES.UPDATE_PREDICT: {
      return { ...state, predict: { ...state.predict, result: action.payload } };
    }
    case ACTION_TYPES.UPDATE_FEEDBACK: {
      return { ...state, feedback: { ...state.feedback, result: action.payload } };
    }
    case ACTION_TYPES.RESET: {
      return state;
    }
    default: {
      return state;
    }
  }
}
