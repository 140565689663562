import React, { memo, useCallback, useEffect, useState } from "react";
import { styled } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import GeneralVantiButton from "components/ui/Buttons/GeneralVantiButton";
import { Text } from "components/ui";

import CloseIcon16 from "assets/icons/close-icon/CloseIcon16";

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    boxSizing: "border-box",
    margin: 0,
    width: "500px",
    height: "fit-content",
    padding: "16px"
  },

  "& .MuiDialogTitle-root": {
    fontSize: "18px",
    padding: "0 0 25px 0"
  }
});

const StyledDialogTitle = styled(DialogTitle)({
  paddingBottom: "24px",
  fontSize: "18px",
  lineHeight: "22px"
});

const DialogTitleContent = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center"
});

const DialogBody = styled("div")({
  display: "flex",
  flexDirection: "column",
  paddingBottom: "16px",
  gap: "12px"
});

const DialogButtonsWrapper = styled("div")({
  display: "flex",
  marginTop: "auto",
  justifyContent: "flex-end",
  alignItems: "center"
});

const ExportChatMessageReportModal = ({ exportedModelName, onClose, isOpen }) => {
  return (
    <StyledDialog
      TransitionProps={{
        onExited: onClose
      }}
      open={isOpen}
    >
      <StyledDialogTitle>
        <DialogTitleContent>
          "{exportedModelName}" - export
          <CloseIcon16 style={{marginBottom: "auto"}} onClick={onClose} />
        </DialogTitleContent>
      </StyledDialogTitle>
      <DialogBody>
        <Text size={14} lineSize="normal">
          This answer is based on an AI model. Export the model and integrate it into the desired deployment
          environment.
        </Text>
      </DialogBody>
      <DialogButtonsWrapper>
        <GeneralVantiButton
          size={"small"}
          buttonInfo={{
            label: "Export",
            onClick: onClose
          }}
        />
      </DialogButtonsWrapper>
    </StyledDialog>
  );
};

export default memo(ExportChatMessageReportModal);
