import React, { memo } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled } from "@material-ui/core";
import Text from "../Typography/Text";
import CloseIcon16 from "assets/icons/close-icon/CloseIcon16";

const DialogStyled = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "fit-content"
  }
});

const InnerDialogStyled = styled("div")({
  minWidth: 600,
  padding: 16
});

const DialogTitleWrapperStyled = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 0px 16px 0px"
});

const DialogTitleStyled = styled(DialogTitle)({
  padding: 0
});

const TitleWrapperStyled = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: 8
});

const CloseModalButtonWrapperStyled = styled("div")({
  cursor: "pointer",
  display: "flex"
});

const CloseButtonStyled = styled(CloseIcon16)({
  cursor: "pointer"
});

const DialogContentStyled = styled(DialogContent)({
  paddingLeft: 0,
  paddingRight: 0
});

const DialogActionsStyled = styled(DialogActions)({
  paddingLeft: 0,
  paddingRight: 0
});

const CustomDialog = ({ isOpened, title, body, children: DialogActionsBody, icon, onClose, ...props }) => {
  return (
    <DialogStyled open={isOpened} onClose={onClose} {...props}>
      <InnerDialogStyled>
        <DialogTitleWrapperStyled>
          <DialogTitleStyled>
            <TitleWrapperStyled>
              {icon}

              <Text data-testid="dialog-title" size={18} weight={500}>
                {title}
              </Text>
            </TitleWrapperStyled>
          </DialogTitleStyled>

          <CloseModalButtonWrapperStyled onClick={onClose}>
            <CloseButtonStyled />
          </CloseModalButtonWrapperStyled>
        </DialogTitleWrapperStyled>

        <DialogContentStyled>
          <DialogContentText>
            <Box sx={{ display: "flex", alignItems: "center" }}>{body}</Box>
          </DialogContentText>
        </DialogContentStyled>

        <DialogActionsStyled>{DialogActionsBody}</DialogActionsStyled>
      </InnerDialogStyled>
    </DialogStyled>
  );
};

export default memo(CustomDialog);
