import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const ArrowDown = ({
  width = "12",
  height = "8",
  viewBox = "0 0 12 8",
  strokeColor = vantiColors.darkBlue1,
  strokeWidth = 2,
  fill = "none",
  ...props
}) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11 1L6 6L1 1" stroke={strokeColor} strokeWidth={strokeWidth} />
    </svg>
  );
};

export default memo(ArrowDown);
