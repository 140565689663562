export const actionTypes = {
  STATION_CREATE_AND_ASSIGN_PRODUCT_REQUEST: "station.createAndAssignProduct.request",
  STATION_CREATE_AND_ASSIGN_PRODUCT_SUCCESS: "station.createAndAssignProduct.success",
  STATION_CREATE_AND_ASSIGN_PRODUCT_ERROR: "station.createAndAssignProduct.error",
  STATION_CREATE_AND_ASSIGN_PRODUCT_FULFILL: "station.createAndAssignProduct.fulfill"
};

export function createAndAssignProductRequestAction({ stationId, productName }) {
  return { type: actionTypes.STATION_CREATE_AND_ASSIGN_PRODUCT_REQUEST, payload: { stationId, productName } };
}
