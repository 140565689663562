import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import cs from "classnames";

import Grid from "@material-ui/core/Grid";
import NumberedSection from "pages/side-bar/data-connectors/pages/single-data-connector/components/NumberedSection";
import DataSourceInput from "./fields/DataSourceInput";
import DataSourceMultiInput from "./fields/DataSourceMultiInput";
import DataSourceSelect from "./fields/DataSourceSelect";
import DataSourceTextField from "./fields/DataSourceTextField";

import { getPlainCredential } from "services/api/dataConnectors";

import useStyles from "./styles";

const FIELD_TYPES = {
  INPUT: "input",
  SELECT: "select",
  INPUT_MULTIPLE: "input-multiple",
  TEXTFIELD: "text-input"
};

const getSectionTitle = (title, field) => {
  let newTitle = title;

  if (field.type === FIELD_TYPES.INPUT_MULTIPLE) {
    newTitle += " (add one or more)";
  }

  if (field.required) {
    newTitle += " *";
  }

  return newTitle;
};

const DataSourceFieldsSection = ({ selectedDataSource, onChangeDataSourceFieldsValues, isEditMode }) => {
  const classes = useStyles();

  const { connectorId } = useParams();

  const [fieldsValues, setFieldsValues] = useState(null);

  const selectedDataSourceFields = useMemo(() => selectedDataSource?.fields || [], [selectedDataSource]);

  const onFieldValueChange = useCallback(
    (newValue, fieldKey) => {
      setFieldsValues(state => {
        const newState = { ...state, [fieldKey]: newValue };
        onChangeDataSourceFieldsValues(newState);
        return newState;
      });
    },
    [setFieldsValues]
  );

  const renderField = useCallback(
    field => {
      switch (field.type) {
        case FIELD_TYPES.SELECT:
          return (
            <DataSourceSelect disabled={isEditMode} field={field} onChange={onFieldValueChange} values={fieldsValues} />
          );

        case FIELD_TYPES.INPUT_MULTIPLE:
          return <DataSourceMultiInput field={field} values={fieldsValues} onChange={onFieldValueChange} />;

        case FIELD_TYPES.TEXTFIELD:
          return (
            <DataSourceTextField
              disabled={isEditMode}
              field={field}
              onChange={onFieldValueChange}
              values={fieldsValues}
            />
          );

        default:
          return (
            <DataSourceInput disabled={isEditMode} field={field} values={fieldsValues} onChange={onFieldValueChange} />
          );
      }
    },
    [isEditMode, fieldsValues, onFieldValueChange]
  );

  useEffect(() => {
    if (selectedDataSourceFields.length) {
      const newValuesObject = {};

      Promise.all(
        selectedDataSourceFields.map(async field => {
          if (field.shared && isEditMode) {
            newValuesObject[field.key] = await getPlainCredential(connectorId, field.key);
          }
        })
      ).then(() => {
        setFieldsValues(newValuesObject);
        onChangeDataSourceFieldsValues(newValuesObject);
      });
    }
  }, [selectedDataSourceFields, isEditMode]);

  return (
    fieldsValues && (
      <Grid
        container
        data-testid="data-source-fields-section"
        justifyContent="space-between"
        className={classes.dataSourcesFieldsContainer}
        style={{ width: "500px" }}
      >
        {selectedDataSourceFields.map((field, index) => (
          <Grid
            className={cs({ [classes.fullWidth]: field.type === FIELD_TYPES.TEXTFIELD })}
            style={{
              width: field.type !== FIELD_TYPES.TEXTFIELD ? "218px" : "100%",
              minWidth: field.type !== FIELD_TYPES.TEXTFIELD ? "218px" : "100%",
              maxWidth: field.type !== FIELD_TYPES.TEXTFIELD ? "218px" : "100%"
            }}
            key={field.key}
            container
            xs={field.type === FIELD_TYPES.TEXTFIELD ? 12 : 6}
          >
            <NumberedSection title={field.title} dense="horizontal">
              {renderField(field)}
            </NumberedSection>
          </Grid>
        ))}
      </Grid>
    )
  );
};

export default memo(DataSourceFieldsSection);
