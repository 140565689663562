import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2, 9, 2, 2.25),
    position: "relative",
    borderBottom: `2px solid ${theme.palette.vantiColors.gray18}`,
    backgroundColor: theme.palette.vantiColors.white,
    "&:hover": {
      backgroundColor: theme.palette.vantiColors.lightBlue10
    },
    "&:active": {
      backgroundColor: theme.palette.vantiColors.lightBlue10
    },
    "&:hover $trashTooltip": {
      opacity: "1"
    }
  },
  trashTooltip: {
    position: "absolute",
    top: "auto",
    right: "1%",
    zIndex: 2,
    opacity: 0,
    transition: "0.2s all ease-in-out",
    "& button": {
      padding: 0,
      backgroundColor: "transparent !important"
    }
  },
  emptyView: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    minHeight: "300px",
    background: "white",
    alignItems: "center",
    justifyContent: "center"
  },
  emptyIconWrapper: {
    width: "46px",
    height: "46px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    background: theme.palette.vantiColors.gray18,
    marginBottom: theme.spacing(1)
  }
}));
