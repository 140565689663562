import React, { memo } from "react";
import { FlexItem } from "components/ui";
import LeftBorderDynamicWrapper from "components/ui/LeftBorderDynamicWrapper/LeftBorderDynamicWrapper";
import NumberedSection from "../NumberedSection";
import styles from "../../../../styles";
import CurationCSVOutputPreview from "./CurationCSVOutputPreview";

const CurationCSVOutputPreviewWrapper = ({ titleProps, curatedPreview }) => {
  const classes = styles();

  return (
    <FlexItem dense="full" xs={12}>
      <LeftBorderDynamicWrapper active={true}>
        <NumberedSection
          index={titleProps.index}
          title={titleProps.title}
          subtitle={titleProps.subtitle}
          containerProps={{
            xs: 12,
            dense: "full",
            className: classes.numberedSection
          }}
          titleTextProps={{ size: 16, weight: 700 }}
          titleContainerProps={{ className: classes.marginBottom }}
          childrenContentProps={{ dense: "full" }}
        >
          <CurationCSVOutputPreview preview={curatedPreview} />
        </NumberedSection>
      </LeftBorderDynamicWrapper>
    </FlexItem>
  );
};

export default memo(CurationCSVOutputPreviewWrapper);
