import { styled } from "@material-ui/core";

export const RubricStyled = styled("div")(({ theme }) => ({
  width: 205,
  height: 171,
  border: `1px solid ${theme.palette.vantiColors.gray36}`,
  borderRadius: 4,
  padding: 8,
  boxSizing: "border-box"
}));

export const AddConnectorDatasetModalButtonStyled = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  paddingTop: 16,
  flex: 1
});
