import React, { memo } from "react";

const OptimizeRecipeIcon = ({ className }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M11.02 30.7265C11.3969 37.5683 17.0641 43 24 43C30.4996 43 35.8851 38.2302 36.8471 32C33.6396 32.31 31.2822 31.6591 29.1397 30.8135C28.8199 31.2309 28.3163 31.5 27.7499 31.5C26.7834 31.5 25.9999 30.7165 25.9999 29.75C25.9999 29.6648 26.006 29.5811 26.0177 29.4991C22.4139 28.0322 18.5064 27.1244 11.02 30.7265Z"
      fill="#86E8B6"
    />
    <path
      d="M20 7H19.5C18.6716 7 18 6.32843 18 5.5C18 4.67157 18.6716 4 19.5 4H24H28.5C29.3284 4 30 4.67157 30 5.5C30 6.32843 29.3284 7 28.5 7H28M20 7C20.455 7.92077 20.5674 11.0458 19.3388 16.5314C19.1316 17.4567 18.464 18.196 17.6375 18.6607C13.6761 20.8882 11 25.1316 11 30C11 30.2438 11.0067 30.486 11.02 30.7265M20 7H24H28M28 7C27.545 7.92077 27.4326 11.0458 28.6612 16.5314C28.8685 17.4567 29.5359 18.196 30.3625 18.6607C34.3239 20.8882 37 25.1316 37 30C37 30.6801 36.9478 31.3481 36.8471 32M11.02 30.7265C11.3969 37.5683 17.0641 43 24 43C30.4996 43 35.8851 38.2302 36.8471 32M11.02 30.7265C18.5064 27.1244 22.4139 28.0322 26.0177 29.4991M36.8471 32C33.6396 32.31 31.2822 31.6591 29.1397 30.8135M29.1397 30.8135C29.3656 30.5188 29.4999 30.1501 29.4999 29.75C29.4999 28.7835 28.7164 28 27.7499 28C26.8686 28 26.1394 28.6515 26.0177 29.4991M29.1397 30.8135C28.8199 31.2309 28.3163 31.5 27.7499 31.5C26.7834 31.5 25.9999 30.7165 25.9999 29.75C25.9999 29.6648 26.006 29.5811 26.0177 29.4991M21.4999 23.75C21.4999 24.7165 20.7164 25.5 19.7499 25.5C18.7834 25.5 17.9999 24.7165 17.9999 23.75C17.9999 22.7835 18.7834 22 19.7499 22C20.7164 22 21.4999 22.7835 21.4999 23.75ZM25.4999 16.75C25.4999 17.7165 24.7164 18.5 23.7499 18.5C22.7834 18.5 21.9999 17.7165 21.9999 16.75C21.9999 15.7835 22.7834 15 23.7499 15C24.7164 15 25.4999 15.7835 25.4999 16.75Z"
      stroke="black"
    />
  </svg>
);

export default memo(OptimizeRecipeIcon);
