import { call, put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./actions";
import { actionTypes as userStateActionTypes } from "../../state/actions";
import * as authService from "services/auth/auth";

export function* fetchUserRolesWorker() {
  try {
    const { data: userRoles } = yield call(authService.getRoles);
    yield put({ type: actionTypes.FETCH_USER_ROLES_SUCCESS });
    yield put({ type: userStateActionTypes.USER_SET_ROLES, payload: userRoles });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_USER_ROLES_ERROR, payload: error });
    yield put({ type: userStateActionTypes.USER_SET_ROLES, payload: [] });
  } finally {
    yield put({ type: actionTypes.FETCH_USER_ROLES_FULFILL });
  }
}

export default function* fetchUserRolesWatcher() {
  yield takeLatest(actionTypes.FETCH_USER_ROLES_REQUEST, fetchUserRolesWorker);
}
