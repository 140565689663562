import React, { memo, useMemo } from "react";
import { vantiColors } from "assets/jss/palette";
import { Handle, Position } from "reactflow";
import { Text } from "components/ui";
import { EntityRow, palette } from "./common";
import { styled } from "@material-ui/core";

const Wrapper = styled("div")({
  width: 241,
  minHeight: 192,
  border: `1px solid ${vantiColors.gray36}`,
  padding: 16,
  boxSizing: "border-box",
  cursor: "default",
  backgroundColor: vantiColors.white
});

const ContentWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 16
});

const Header = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 4
});

const ChildrenWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 12
});

const handleStyles = { background: vantiColors.white, border: `1px solid ${vantiColors.gray23}` };

function CustomNode({ data }) {
  const { name, description, childEntities, onClick } = useMemo(() => data || {}, [data]);

  return (
    <Wrapper onClick={onClick}>
      <ContentWrapper>
        <Header>
          <Text color={vantiColors.darkBlue7} weight={500} size={16} lineSize={1}>
            {name}
          </Text>
          <Text color={vantiColors.lightBlue16} lineSize={1}>
            {description}
          </Text>
        </Header>

        <ChildrenWrapper>
          {childEntities?.map((childEntity, index) => (
            <EntityRow
              key={childEntity.name}
              childEntity={childEntity}
              color={palette[index]}
              onClickOpenPopup={() => {}}
            />
          ))}
        </ChildrenWrapper>
      </ContentWrapper>

      <Handle type="target" position={Position.Left} isConnectable={true} style={handleStyles} />
      <Handle type="source" position={Position.Right} isConnectable={true} style={handleStyles} />
    </Wrapper>
  );
}

export default memo(CustomNode);
