export const DELETE_DATA_CONNECTOR_DIALOG = {
  title: "Confirm deletion",
  body: connectorName => `Are you sure you want to delete connector ${connectorName || "-"}?`
};

export const getFormattedDateForDisplay = date => {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return dd + "." + mm + "." + yyyy;
};

export const getFormattedTimeForDisplay = date => {
  return date.toLocaleTimeString("en-us", {
    hour: "2-digit",
    minute: "2-digit"
  });
};
