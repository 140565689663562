import { vantiColors } from "assets/jss/palette";
import React, { memo } from "react";

const ResizeIcon16 = ({ ...rest }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M3.4408 12.2593L4.3836 13.2021L12.8689 4.71681L11.9261 3.774L3.4408 12.2593ZM9.09765 13.2021L12.8689 9.43085L11.9261 8.48804L8.15484 12.2593L9.09765 13.2021Z"
        fill={vantiColors.gray33}
      />
    </svg>
  );
};

export default memo(ResizeIcon16);
