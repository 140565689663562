import { useEffect, useState } from "react";

import useStyles from "../storage-file-explorer/styles";

import { FILES_SORT_BY } from "./CommonFileExplorer";

const useHeaderTitles = (ref, onSortBy, sortBy) => {
  const classes = useStyles();

  const [headerTitles, setHeaderTitles] = useState(null);

  useEffect(() => {
    if (headerTitles && sortBy) {
      headerTitles[sortBy.key].className =
        sortBy.order === FILES_SORT_BY.ORDER.ASC ? classes.arrowUp : classes.arrowDown;
    }

    return () => {
      if (headerTitles && sortBy) {
        headerTitles[sortBy.key].className = "";
      }
    };
  }, [sortBy, headerTitles]);

  useEffect(() => {
    if (ref) {
      const toolbarWrapper = document.getElementsByClassName("chonky-toolbarWrapper")[0];

      const headerWrapperEl = document.createElement("div");
      headerWrapperEl.className = classes.headersWrapper;

      toolbarWrapper.appendChild(headerWrapperEl);

      const headerTitleEl = document.createElement("span");
      headerTitleEl.textContent = "Title";

      const headerModDateEl = document.createElement("span");
      headerModDateEl.textContent = "Update Date";

      const headerSizeEl = document.createElement("span");
      headerSizeEl.textContent = "Size";

      headerWrapperEl.appendChild(headerTitleEl);
      headerWrapperEl.appendChild(headerModDateEl);
      headerWrapperEl.appendChild(headerSizeEl);

      setHeaderTitles({
        [FILES_SORT_BY.KEYS.NAME]: headerTitleEl,
        [FILES_SORT_BY.KEYS.SIZE]: headerSizeEl,
        [FILES_SORT_BY.KEYS.DATE]: headerModDateEl
      });
    }
  }, [ref]);

  useEffect(() => {
    if (headerTitles && onSortBy) {
      headerTitles[FILES_SORT_BY.KEYS.NAME].onclick = () => onSortBy(FILES_SORT_BY.KEYS.NAME);
      headerTitles[FILES_SORT_BY.KEYS.DATE].onclick = () => onSortBy(FILES_SORT_BY.KEYS.DATE);
      headerTitles[FILES_SORT_BY.KEYS.SIZE].onclick = () => onSortBy(FILES_SORT_BY.KEYS.SIZE);
    }
  }, [headerTitles, onSortBy]);
};

export default useHeaderTitles;
