import React, { memo } from "react";

const RadioButton24Unchecked = ({ ...rest }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <mask
      id="mask0_2619_1203"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="24"
    >
      <rect x="0.166992" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2619_1203)">
      <path
        d="M12.1703 21C10.9258 21 9.75571 20.7638 8.66009 20.2915C7.56448 19.8192 6.61143 19.1782 5.80097 18.3685C4.99052 17.5588 4.34891 16.6066 3.87614 15.512C3.40338 14.4174 3.16699 13.2479 3.16699 12.0033C3.16699 10.7588 3.40315 9.58872 3.87547 8.4931C4.3478 7.39748 4.98882 6.44444 5.79852 5.63398C6.60822 4.82353 7.56036 4.18192 8.65494 3.70915C9.74954 3.23638 10.9191 3 12.1636 3C13.4082 3 14.5783 3.23616 15.6739 3.70848C16.7695 4.18081 17.7226 4.82182 18.533 5.63152C19.3435 6.44122 19.9851 7.39337 20.4578 8.48795C20.9306 9.58255 21.167 10.7521 21.167 11.9967C21.167 13.2412 20.9308 14.4113 20.4585 15.5069C19.9862 16.6025 19.3452 17.5556 18.5355 18.366C17.7258 19.1765 16.7736 19.8181 15.679 20.2908C14.5844 20.7636 13.4149 21 12.1703 21ZM12.167 20C14.4003 20 16.292 19.225 17.842 17.675C19.392 16.125 20.167 14.2333 20.167 12C20.167 9.76667 19.392 7.875 17.842 6.325C16.292 4.775 14.4003 4 12.167 4C9.93366 4 8.04199 4.775 6.49199 6.325C4.94199 7.875 4.16699 9.76667 4.16699 12C4.16699 14.2333 4.94199 16.125 6.49199 17.675C8.04199 19.225 9.93366 20 12.167 20Z"
        fill="#B5B9C2"
      />
    </g>
  </svg>
);

export default memo(RadioButton24Unchecked);
