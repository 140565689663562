import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  root: {
    width: "100%",
    "& h3": {
      marginTop: "0"
    }
  },
  loading: {
    overflow: "hidden"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  filterWrapper: {
    position: "relative",
    overflow: "hidden",
    margin: "12px 0",
    display: "flex",
    flexDirection: "column",
    padding: "12px",
    maxHeight: "43px",
    transition: "max-height 0.3s ease-out",
    "& > div": {
      transition: "all ease-in-out 0.3s",
      opacity: 0,
      display: "flex"
    }
  },
  expanded: {
    maxHeight: "3000px",
    transition: "max-height 0.3s ease-in",
    "& > div": {
      transition: "all ease-in-out 0.3s",
      opacity: 1
    }
  },
  filters: {
    display: "flex",
    flexDirection: "column",
    "& > section": {
      display: "grid",
      gridTemplate: "auto auto auto / 400px 400px 400px",
      columnGap: "12px",
      rowGap: "4px",
      "& > label": {
        display: "flex",
        padding: "4px 0",
        "& > span": {
          minWidth: "100px",
          display: "flex",
          alignItems: "center"
        },
        "& > input": {
          marginLeft: "30px"
        }
      }
    },
    "& > div": {
      display: "flex",
      marginTop: "20px",
      "& > button:first-of-type": {
        margin: "0",
        marginRight: "8px",
        width: "307px"
      }
    }
  },
  doughnutGraphContainer: {
    width: "17%",
    height: "17%",
    marginLeft: "50px"
  },
  doughnutGraph: {
    position: "relative"
  },
  deployedStationsText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  arrowIcon: {
    transition: "all ease-in-out 0.3s",
    position: "absolute",
    right: "16px",
    width: "16px",
    height: "16px",
    top: "12px"
  },
  collapsedIcon: {
    transition: "all ease-in-out 0.3s",
    transform: "rotateX(180deg)"
  },
  bulletsContainer: {
    marginLeft: "40px"
  },
  loadingScreen: {
    width: "100%",
    height: "100%",
    position: "absolute",
    background: "white",
    top: 0,
    zIndex: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.6
  }
}));
