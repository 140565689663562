import { createSelector } from "reselect";

const authSelector = state => state.auth?.state;

export const uidSelector = createSelector(
  authSelector,
  auth => auth.uid
);
export const emailSelector = createSelector(
  authSelector,
  auth => auth.email
);
export const emailVerifiedSelector = createSelector(
  authSelector,
  auth => auth.emailVerified
);