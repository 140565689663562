import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const BellNotificationIconWithBadge = ({
  width = "24",
  height = "24",
  viewBox = "0 0 24 24",
  fill = "none",
  bellStroke = vantiColors.gray18,
  ...rest
}) => {
  return (
    <svg {...rest} width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.0402 20.236C15.6498 17.7429 17.5267 12.5071 17.343 9.44672C17.2126 7.2739 15.5701 6.16751 14.5589 5.68893C14.1011 5.47224 13.7728 5.38426 13.7728 5.38426M17.0402 20.236L12.2106 18.9418M17.0402 20.236L18.0061 20.4948M13.7728 5.38426L14.3295 3.30667M13.7728 5.38426C13.7728 5.38426 13.4444 5.29627 12.9396 5.25502C11.8246 5.16393 9.849 5.30084 8.64964 7.11735C6.96039 9.67584 5.96787 15.1486 3.51723 16.6125M12.2106 18.9418L10.2787 18.4242L8.34688 17.9066M12.2106 18.9418C11.8863 20.152 11.1196 21.0816 9.67071 20.6934C8.22182 20.3051 8.02262 19.1167 8.34688 17.9066M3.51723 16.6125L8.34688 17.9066M3.51723 16.6125L2.55131 16.3537"
        stroke="#091733"
      />
      <circle cx="17" cy="12" r="3.5" fill="#FF3C78" stroke={bellStroke} />
    </svg>
  );
};

export default memo(BellNotificationIconWithBadge);
