const DATA_CONNECTORS_TABLE_HEADERS_VALUES = {
  NAME: "Name",
  DATA_SOURCE: "Data source",
  LAST_CONNECTION: "Last connection",
  SPACE_CELL: "",
  EDIT_ROW_ICON: ""
};

export const DATA_CONNECTORS_TABLE_HEADERS = Object.values(DATA_CONNECTORS_TABLE_HEADERS_VALUES);

export const DEFAULT_SPEED_DIAL_DIRECTION = "left";

export const NEW_CONNECTOR_FINISH_BUTTON_TEXT = "DONE";

export const CONNECT_BUTTON_TEXT = "Connect";
export const UPDATE_CONNECTOR_BUTTON_TEXT = "Save";

export const NEW_CONNECTOR_SECTIONS = {
  dataConnectorName: {
    index: 1,
    title: "Connector name"
  },
  dataSources: {
    index: 2,
    title: "Connect source",
    supportedDataFiles: ["CSV", "ZIP"]
  }
};

export const CONNECTOR_PAGE_MODES = {
  NEW_CONNECTOR: "NEW_CONNECTOR",
  EDIT_CONNECTOR: "EDIT_CONNECTOR"
};

export const QUERY_NEEDED_TO_COMPLETE_CREATION_TEXT = "To complete the integration, a query needs to be created";
export const TEST_CONNECTION_FAILED_TEXT = "The connection test has failed. Please try again";

export const DEFAULT_CARD_WIDTH = 294;
export const DEFAULT_SPACING = 32;

export const disabledFieldDefaultValue = "*******************";

export const ConnectorTypes = {
  DATABASE: "DATABASE",
  CLOUD_STORAGE: "CLOUD_STORAGE"
};

export const CONNECTORS_TEXT = "Connectors";
export const NEW_CONNECTOR_TEXT = "New data connector";

export const DATA_CONNECTORS_URL_PREFIX = "/dashboard/data-connectors";

export const CONNECTORS_PLACEHOLDER_TEXT = "Your data connectors will appear here";
