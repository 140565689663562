export const actionTypes = {
  USER_SET_STATE_IS_READ_NOTIFICATION_CENTER_REQUEST: "user.set.state.is_read_notification_center",
  USER_SET_STATE: "user.set.state",
  USER_SET_ROLES: "user.set.roles",
  USER_CLEAR_STATE: "user.clear.state"
};

export function setUserStateAction(user) {
  return { type: actionTypes.USER_SET_STATE, payload: user };
}

export function setUserIsReadNotificationCenterAction(isReadNotificationCenter) {
  return { type: actionTypes.USER_SET_STATE_IS_READ_NOTIFICATION_CENTER_REQUEST, payload: isReadNotificationCenter };
}

export function cleanUserStateAction() {
  return { type: actionTypes.USER_CLEAR_STATE };
}
