import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: theme.spacing(2, 2, 2, 0),
    border: "none"
  },
  groupItem: {
    width: "160px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    lineHeight: "24px",
    fontWeight: 600,
    cursor: "pointer",
    border: "1px solid green",
    borderRadius: "5px",
    padding: theme.spacing(2)
  },
  arrowIcon: {
    marginLeft: theme.spacing(1.5)
  }
}));

export default useStyles;
