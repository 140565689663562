export const actionTypes = {
  STATION_DELETE_PRODUCT_REQUEST: "station.deleteProduct.request",
  STATION_DELETE_PRODUCT_SUCCESS: "station.deleteProduct.success",
  STATION_DELETE_PRODUCT_ERROR: "station.deleteProduct.error",
  STATION_DELETE_PRODUCT_FULFILL: "station.deleteProduct.fulfill"
};

export function deleteProductRequestAction(productId) {
  return { type: actionTypes.STATION_DELETE_PRODUCT_REQUEST, payload: { productId } };
}
