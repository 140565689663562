import React, { memo, useMemo } from "react";
import { vantiColors } from "assets/jss/palette";
import { styled } from "@material-ui/core";

const STROKE_FILL_LENGTH = 112;

const LoaderWrapperStyled = styled("div")({
  width: 56,
  height: 56,
  transform: "rotate(-90deg)"
});

const DynamicLoader = ({ value, color = vantiColors.darkBlue7 }) => {
  const actualValue = useMemo(() => STROKE_FILL_LENGTH - (STROKE_FILL_LENGTH / 100) * value, [value]);

  return (
    <LoaderWrapperStyled>
      <svg viewBox="22 22 44 44">
        <circle
          cx="44"
          cy="44"
          r="17.834"
          fill="none"
          strokeWidth="7"
          stroke={vantiColors.gray35}
          strokeDasharray={STROKE_FILL_LENGTH}
          strokeDashoffset={0}
        />

        <circle
          cx="44"
          cy="44"
          r="17.834"
          fill="none"
          strokeWidth="7"
          stroke={color}
          strokeDasharray={STROKE_FILL_LENGTH}
          strokeDashoffset={actualValue}
        />
      </svg>
    </LoaderWrapperStyled>
  );
};

export default memo(DynamicLoader);
