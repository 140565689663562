import { styled } from "@material-ui/core";
import ChatMessageInlineButton from "../buttons/ChatMessageInlineButton";
import React, { memo, useCallback, useContext, useState } from "react";
import { AppContext } from "common/hooks/context-hooks/use-app-context";

const InlineButtonsDatasetChoiceWrapper = styled("div")(() => ({
  width: "100%",
  boxSizing: "border-box",
  gap: 8,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start"
}));

const InlineButtonsDatasetChoiceContainer = ({ message }) => {
  const { chatContext } = useContext(AppContext);
  const { chatsWithDisabledDatasetSelection, setChatsWithDisabledDatasetSelection } = chatContext;

  const [selectedButtonId, setSelectedButtonId] = useState(false);

  const onDatasetSelection = useCallback(
    buttonId => {
      setSelectedButtonId(buttonId);
      setChatsWithDisabledDatasetSelection(prevState => ({ ...prevState, [message.chatId]: true }));
    },
    [message.chatId]
  );

  return (
    <InlineButtonsDatasetChoiceWrapper>
      {message?.inlineButtons?.map(({ action, title }, index) => {
        const buttonId = action?.split("/")[1];

        return (
          <ChatMessageInlineButton
            key={`${buttonId}-${Math.random()}`}
            chatId={message.chatId}
            title={title}
            buttonAction={action}
            index={index}
            isButtonDisabled={chatsWithDisabledDatasetSelection[message.chatId]}
            isButtonSelected={buttonId === selectedButtonId}
            onClickCallback={() => {
              onDatasetSelection(buttonId);
            }}
          />
        );
      })}
    </InlineButtonsDatasetChoiceWrapper>
  );
};

export default memo(InlineButtonsDatasetChoiceContainer);
