import { createContext } from "react";
import { useDatasetContext } from "./use-datasets-context";
import { useAppNotificationsContext } from "./use-notifications-context";
import { useTreeViewContext } from "./use-treeview-context";
import { useChatContext } from "./use-chat-context";
import { useUsersContext } from "./use-users-context";
import { useDashboardContext } from "./use-dashboard-context";
import { useReportsContext } from "./use-reports-context";
import { useTagsContext } from "./use-tags-context";
import { useDataEntitiesContext } from "./use-data-entities-context";
import { useFlowContext } from "./use-flow-context";

export const AppContext = createContext({
  notifications: {},
  treeView: {},
  datasetsContext: {},
  chatContext: {},
  usersContext: {},
  dashboardContext: {},
  reportsContext: {},
  dataEntitiesContext: {},
  tagsContext: {},
  flowContext: {}
});

export const useAppContext = () => ({
  notifications: useAppNotificationsContext(),
  treeView: useTreeViewContext(),
  datasetsContext: useDatasetContext(),
  chatContext: useChatContext(),
  usersContext: useUsersContext(),
  dashboardContext: useDashboardContext(),
  reportsContext: useReportsContext(),
  dataEntitiesContext: useDataEntitiesContext(),
  tagsContext: useTagsContext(),
  flowContext: useFlowContext()
});
