import fileReaderStream from "filereader-stream";
import http from "http";
import { countCsvRows, readCsv } from "./csv";
import errors from "./errors";
import { read as readXlsx } from "./xlsx";

import { readJson } from "./json";
import { readXml } from "./xml";
import { read as readZip } from "./zip";

const CSV_MIME_TYPES = [
  "text/plain",
  "application/vnd.ms-excel",
  "text/x-csv",
  "application/csv",
  "application/x-csv",
  "text/csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "text/tab-separated-values"
];

const ZIP_MIME_TYPES = ["application/zip"];
const SQL_MIME_TYPES = ["application/sql"];
const PDF_MIME_TYPES = ["application/pdf"];
const XLSX_MIME_TYPES = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
const JSON_MIME_TYPES = ["application/json"];
const XML_MIME_TYPES = ["application/xhtml+xml"];

export const FILE_TYPES = {
  CSV: "CSV",
  XLSX: "XLSX",
  SQL: "SQL",
  PDF: "PDF",
  ZIP: "ZIP",
  JPG: "JPG",
  JSON: "JSON",
  XML: "XML"
};

export const FILE_UPLOAD_STATUSES = {
  UPLOADING: "UPLOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

/**
 * @param {string} name - file name
 * @param {string} mimeType - file mime types
 * @returns {FILE_TYPES|null}
 */
export function getFileTypeByFile(file) {
  return getFileType(file.name, file.mimeType);
}

export function getFileType(name, mimeType) {
  if (ZIP_MIME_TYPES.indexOf(mimeType) >= 0 || name.toLowerCase().endsWith(".zip")) {
    return FILE_TYPES.ZIP;
  }

  if (CSV_MIME_TYPES.indexOf(mimeType) >= 0 || name.toLowerCase().endsWith(".csv")) {
    return FILE_TYPES.CSV;
  }

  if (XLSX_MIME_TYPES.indexOf(mimeType) >= 0 || name.toLowerCase().endsWith(".xlsx")) {
    return FILE_TYPES.XLSX;
  }

  if (
    SQL_MIME_TYPES.indexOf(mimeType) >= 0 ||
    name.toLowerCase().endsWith(".sql") ||
    name.toLowerCase().endsWith(".pdf") ||
    name.toLowerCase().endsWith(".zip")
  ) {
    return FILE_TYPES.SQL;
  }

  if (PDF_MIME_TYPES.indexOf(mimeType) >= 0 || name.toLowerCase().endsWith(".pdf")) {
    return FILE_TYPES.PDF;
  }

  if (JSON_MIME_TYPES.indexOf(mimeType) >= 0 || name.toLowerCase().endsWith(".json")) {
    return FILE_TYPES.JSON;
  }

  if (
    XML_MIME_TYPES.indexOf(mimeType) >= 0 ||
    name.toLowerCase().endsWith(".xml") ||
    name.toLowerCase().endsWith(".xhml")
  ) {
    return FILE_TYPES.XML;
  }

  return null;
}

/**
 * @typedef {Object} TableData
 * @property {string|null} name
 * @property {Array} headers
 * @property {Array<Array>} data
 */

/**
 * @callback DataReaderCallback
 * @param {Error|null} error
 * @param {TableData[]} results
 */

/**
 * @param file
 * @param {number} maxLines
 * @param {DataReaderCallback} callback
 */
export async function readFile(file, maxLines, callback, updateProgress) {
  const name = file.name;
  const mimeType = file.mimeType;

  switch (getFileType(name, mimeType)) {
    case FILE_TYPES.CSV: {
      await readCsv(file, callback, updateProgress);
      return;
    }
    case FILE_TYPES.XLSX: {
      await readXlsx(fileReaderStream(file), maxLines, callback);
      return;
    }
    case FILE_TYPES.ZIP: {
      await readZip(file, callback, updateProgress);
      return;
    }
    case FILE_TYPES.JSON: {
      readJson(file, callback, updateProgress);
      return;
    }
    case FILE_TYPES.XML: {
      await readXml(file, callback, updateProgress);
      return;
    }
    default: {
      await callback(new Error(errors.NOT_SUPPORTED), null);
      return;
    }
  }
}

/**
 * @param {{url: string, name: string}}} blockFile
 * @param {number} maxLines
 * @param {DataReaderCallback} callback
 */
export function readUrl(blockFile, maxLines, callback, updateProgress) {
  http.get(blockFile.url, response => {
    const name = blockFile.name;
    const mimeType = response.headers["content-type"];
    switch (getFileType(name, mimeType)) {
      case FILE_TYPES.CSV: {
        readCsv(response, callback, updateProgress);
        return;
      }
      case FILE_TYPES.XLSX: {
        readXlsx(response, maxLines, callback);
        return;
      }
      case FILE_TYPES.ZIP: {
        readZip(response, callback, updateProgress);
        return;
      }
      default: {
        callback(new Error(errors.NOT_SUPPORTED), null);
        return;
      }
    }
  });
}

export function countFileRows(file, callback) {
  const name = file.name;
  const mimeType = file.mimeType;
  switch (getFileType(name, mimeType)) {
    case FILE_TYPES.CSV: {
      countCsvRows(file, callback);
      return;
    }

    default: {
      callback(new Error(errors.NOT_SUPPORTED), null);
      return;
    }
  }
}

export const getFileExtension = file => {
  return file.path.substring(file.path.lastIndexOf(".") + 1).toUpperCase();
};
