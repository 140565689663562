const DATA_CURATION_TABLE_HEADERS_VALUES = {
  CURATION_NAME: "Curation name",
  SOURCE_FORMAT: "Source format",
  LAST_EXECUTION_DATE: "Last execution date",
  SPACE_CELL: "",
  EDIT_ROW_ICON: ""
};

export const DATA_CURATION_TABLE_HEADERS = Object.values(DATA_CURATION_TABLE_HEADERS_VALUES);

export const getFormattedDateForDisplay = date => {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return dd + "." + mm + "." + yyyy;
};

export const getFormattedTimeForDisplay = date => {
  return date.toLocaleTimeString("en-us", {
    hour: "2-digit",
    minute: "2-digit"
  });
};

export const NEW_CURATION_HEADER_TEXT = "New curation";
export const EDIT_CURATION_HEADER_TEXT = curationName => `${curationName || "Data curation"}`;

export const SUB_HEADER_TEXT = `Select the data files you wish to join and/or curate, providing a description of their contents.
Vanti will automatically generate a curation code tailored to your requirements.`;

export const SUPPORTED_FILE_EXTENSIONS = {
  CSV: "CSV",
  XML: "XML",
  JSON: "JSON",
  ZIP: "ZIP"
};

export const FILE_ERRORS = {
  HEADER_IS_MISSING: {
    error: "This dataset contains column/s without headers. Please add the missing headers and try again.",
    tip: "Please make sure your file header columns are not empty."
  },
  DUPLICATED_HEADER_COLUMN: {
    message: "The header names are not unique.",
    tip: "Please make sure your file includes only unique header names."
  },
  NOT_SUPPORTED_FILE: (fileName, fileType) => ({
    error: `File ${fileName || ""} is not supported (${fileType || ""})`
  }),
  FILE_IS_EMPTY: {
    error: "Empty file is not supported.",
    tip: "Please make sure your file is not empty."
  },
  ZIP_IS_EMPTY: {
    error: "Your ZIP folder is empty.",
    tip: "Please make sure your ZIP folder contains one of the supported file types."
  },
  ZIP_CONTAINING_DIRECTORIES: directoryName => ({
    error: `Your ZIP file contains a folder (${directoryName}).`,
    tip: "Please make sure your ZIP contains only files."
  }),
  FILE_NAME_ALREADY_EXIST: fileName => ({
    error: `File ${fileName} already exists.`,
    tip: "Please make sure your files names are unique."
  }),
  FILENAME_SPACE: fileName => ({
    error: `The file ${fileName} includes spaces.`,
    tip: "Please remove all of the spaces and try again."
  })
};

export const UPLOAD_STATUSES = {
  LOADING: "loading",
  ERROR: "error",
  COMPLETE: "complete"
};

export const checkIfFileIsValid = file => {
  if (file.path.includes(".")) {
    const fileType = file.path.substring(file.path.lastIndexOf(".") + 1).toUpperCase();

    if (file.name.includes(" ")) {
      return FILE_ERRORS.FILENAME_SPACE(file.name);
    } else {
      const fileExtension = file.path.substring(file.path.lastIndexOf(".") + 1).toUpperCase();

      if (!Object.values(SUPPORTED_FILE_EXTENSIONS).includes(fileExtension)) {
        return FILE_ERRORS.NOT_SUPPORTED_FILE(file.path, fileType);
      }
    }
  }

  return null;
};

export const PYTHON_CODE_PLACEHOLDERS = {
  json: `import sys
import json
data = json.load(sys.stdin)

for row in data["rows"]:
  print(",".join(map(str, row)))
`,
  xml: `import xml.etree.ElementTree as ET
import sys

root = ET.parse(sys.stdin).getroot()`,
  csv: `def curate(merged_file_path):
  lines = []
  with open(merged_file_path, "r") as f:
      reader = csv.reader(f)
      for line in reader:
          lines.append(line)

  return lines`
};

export const getMergeCodePlaceholder = () => {
  return `merge(files_paths):
  first_path = open(files_paths[0])
  second_path = open(files_paths[1])

  first_file = json.load(first_path)
  second_file = json.load(second_path)
  lines = []

  for line1 in first_file["rows"]:
    for line2 in second_file["rows"]:
      new_line = line1 + line2
      lines.append(new_line)
  
  return lines # This line is a must - should return a list of lists

def get_headers():
  # This function should return a list of strings that contains your headers
  return ["header_1", "header_2", "header_3", "header_4", "header_5", "header_6", "header_7"]
`;
};

export const API_QUERIES = {
  keys: {
    getExistingCuration: ["get-existing-curation"],
    createNewCuration: ["create-new-curation"],
    duplicateCuration: ["duplicate-curation"],
    updateCuration: ["update-curation"],
    deleteCuration: ["delete-curation"],
    uploadOriginalFileToS3: ["upload-original-file-to-s3"],
    mergeFiles: ["merge-files"],
    runDataCuration: ["run-data-curation"],
    presignDriftImage: "presign-drift-image"
  }
};

export const DEFAULT_SPEED_DIAL_DIRECTION = "left";

export const DELETE_DATA_CURATION_DIALOG = {
  title: "Confirm deletion",
  body: connectorName => `Are you sure you want to delete curation ${connectorName || "-"}?`
};

export const supportedFilesText = "Supported file types: CSV, XML, JSON, Flat ZIP";
export const supportedFilesHintText = "You can add multiple files per format";
export const leavePageDialogConfirmationText = "Would you like to leave this page without saving your changes?";
export const fetchingExistingCurationText = "Fetching your existing curation...";
export const changingFileWarningText = "Changing the original files may impact any existing output files";

export const pythonCodeText = "Python code";
export const mergeHintText = "You can combine your files into one valid CSV file";

export const fileChangingActionTypes = {
  ADD_FILE: "add.file",
  REMOVE_FILE: "remove.file"
};

export const CURATION_RUN_JOB_FAILED_TEXT = "Curation run job has failed";

export const SUCCESSFUL_API_REQ_STATUS = "finished";
export const FAILED_API_REQ_STATUS = "failed";

export const MOCKS = {
  CODE: `import pandas as pd
import numpy as np
from tqdm import tqdm
from pandasql import sqldf
import os
from pathlib import Path

vendor = pd.read_csv('raw_data/SM87B_yield_from_vendor - Sheet1.csv',usecols=['Batch Posting Date Qty','Yield'])
vendor.rename(columns={"Batch Posting Date Qty": "Vendor Batch"},inplace = True)
vendor.rename(columns={"Vendor Batch": "Vendor_Batch"},inplace = True)
vendor = vendor.head(39)
vendor.head()
## 39 distinct vendor batches
## from SM87B_yield_from_vendor - Sheet1.csv data

vendor_to_batch = pd.read_excel('raw_data/Amph_and_Ergo_Supplier_ot/argo.XLSX')
vendor_to_batch.rename(columns={"Batch": "philips_Batch"},inplace = True)
vendor_to_batch.rename(columns={"Vendor Batch": "Vendor_Batch"},inplace = True)
vendor_to_batch.rename(columns={"Insp. Lot Quantity": "Insp_Lot_Quantity"},inplace = True)
vendor_to_batch = vendor_to_batch[~vendor_to_batch.isnull().any(axis=1)]
vendor_to_batch.head()

# 345 distinct combinations of Vendor_Batch mapped to philips_Batches
# from Amph_and_Ergo_Supplier_ot/argo.XLSX

#q = "select distinct philips_Batch,Vendor_Batch from vendor_to_batch "
#sqldf(q, globals())

df = vendor.merge(vendor_to_batch, on='Vendor_Batch', how='left')
df1 = df[df['philips_Batch']>0]
df1.head()

# 28 unic combinations

#q = "select distinct philips_Batch,Vendor_Batch as dis_sfc_key_data from df1 "
#sqldf(q, globals())


key_data = pd.read_csv('raw_data/key_data_sfc.csv')
key_data.rename(columns={"SFC_FINISH_GOOD": "SFC"},inplace = True)
key_data.rename(columns={"BATCH_NUMBER": "philips_Batch"},inplace = True)
key_data.head()

# 47885 distinct 'SFC_FINISH_GOOD' units, to start proccess funnel
# q = "select count (distinct SFC) as dis_sfc_key_data from key_data "
# sqldf(q, globals())

# out of 599 distinct batches
#q = "select count (distinct philips_Batch) as dis_sfc_key_data from key_data "
#sqldf(q, globals())

df2 = df1.merge(key_data,on = 'philips_Batch', how='left')
df2 = df2[~df2.isnull().any(axis=1)]
df2 = df2[['Vendor_Batch','Yield','philips_Batch','SFC']]
df2.head()

## 4286 unic SFC's
## several SFC's are linked to multiple philips_Batch's

## all are linked to only 12 vendor batches!!!!

PL_COMPLETE  = pd.read_csv('raw_data/PL_DATA/FINISH_GOOD/PL_COMPLETE.csv')
PL_COMPLETE.rename(columns={"PL_SFC": "SFC"},inplace = True)
#PL_COMPLETE = PL_COMPLETE[['SFC','PL_DATE_TIME_LOCAL','PL_OPERATION','PL_ACTIVITY']]
PL_COMPLETE.head()

df3 = df2.merge(PL_COMPLETE,on = 'SFC', how='left')
df3 = df3[~df3.isnull().any(axis=1)]
df3.head()

#### 1977 unic SFC's on final table
#### only 9 vendor batch's are linked 

a1 = SMARTCAL.merge(DRIFTELITE,on = 'Vendor_Batch', how='left')
a2 = a1.merge(OFFSET,on = 'Vendor_Batch', how='left')
a3 = a2.merge(RESISTANCE,on = 'Vendor_Batch', how='left') 
a4 = a3.merge(PROD_VAL,on = 'Vendor_Batch', how='left')
a4.head(10)

a4['SMARTCAL_Yield'] = a4['DRIFTELITE']/a4['SMARTCAL']
a4['DRIFTELITE_Yield'] = a4['OFFSET']/a4['DRIFTELITE']
a4['OFFSET_Yield'] = a4['RESISTANCE']/a4['OFFSET']
a4['RESISTANCE_Yield'] = a4['PROD_VAL']/a4['RESISTANCE']

a4.head(10)

a4[['Vendor_Batch','Yield','SMARTCAL_Yield','DRIFTELITE_Yield','OFFSET_Yield','RESISTANCE_Yield']]

SMARTCAL = "select distinct Vendor_Batch,Yield,count( distinct SFC ) as SMARTCAL from df3 where PL_OPERATION = 'O_OMNI_SMARTCALELITE_TEST_FG' group by Vendor_Batch "
SMARTCAL = sqldf(SMARTCAL, globals())
SMARTCAL.head()

# and PL_ACTIVITY = 'COMPLETE'


OFFSET = "select distinct Vendor_Batch,count( distinct SFC ) as OFFSET from df3 where PL_OPERATION = 'O_OMNI_OFFSET_TEST'  group by Vendor_Batch "
OFFSET = sqldf(OFFSET, globals())
OFFSET.head()

#and PL_ACTIVITY = 'COMPLETE'

DRIFTELITE = "select distinct Vendor_Batch,count( distinct SFC ) as DRIFTELITE from df3 where PL_OPERATION = 'O_OMNI_DRIFTELITE_TEST_FG'  group by Vendor_Batch "
DRIFTELITE = sqldf(DRIFTELITE, globals())
DRIFTELITE.head()

#and PL_ACTIVITY = 'COMPLETE'

RESISTANCE = "select distinct Vendor_Batch,count( distinct SFC ) as RESISTANCE from df3 where PL_OPERATION = 'O_OMNI_TRAYPREP_ FINAL_TEST'  group by Vendor_Batch "
RESISTANCE = sqldf(RESISTANCE, globals())
RESISTANCE.head()

#and PL_ACTIVITY = 'COMPLETE'

PROD_VAL = "select distinct Vendor_Batch,count( distinct SFC ) as PROD_VAL from df3 where PL_OPERATION = 'O_BCCP_PROD_VAL'  group by Vendor_Batch "
PROD_VAL = sqldf(PROD_VAL, globals())
PROD_VAL.head()

#and PL_ACTIVITY = 'COMPLETE'

### num of unic SFC in process
#q = "select distinct SFC,PL_OPERATION from df3 where PL_OPERATION = 'O_OMNI_SMARTCALELITE_TEST_FG' and PL_ACTIVITY = 'COMPLETE' "

#q = "select distinct SFC from df3 where PL_OPERATION = 'O_OMNI_DRIFTELITE_TEST_FG' and PL_ACTIVITY = 'COMPLETE' "

#q = "select distinct SFC from df3 where PL_OPERATION = 'O_OMNI_OFFSET_TEST' and PL_ACTIVITY = 'COMPLETE' "

#q = "select distinct SFC from df3 where PL_OPERATION = 'TRAYPREP' and PL_ACTIVITY = 'COMPLETE' "

#q = "select distinct SFC from df3 where PL_OPERATION = 'O_UNILABEL_PRINTING' and PL_ACTIVITY = 'COMPLETE' "


#sqldf(q, globals())

q = "select distinct SFC from df3 where PL_OPERATION = 'O_UNILABEL_PRINTING' and PL_ACTIVITY = 'COMPLETE' "
sqldf(q, globals())

q = "select distinct PL_DATE_TIME_LOCAL,PL_OPERATION  from df3 where SFC = '300006496562-105157' order by PL_DATE_TIME_LOCAL asc "


sqldf(q, globals())

# O_OMNI_SMARTCALELITE_TEST_FG - 110
# O_OMNI_DRIFTELITE_TEST_FG - 120
# O_OMNI_OFFSET_TEST - 130
# O_OMNI_TRAYPREP_FINAL_TEST - 170

## num of PL_OPERATION
### num of unic SFC in process
q = "SELECT distinct   PL_OPERATION from df3"
sqldf(q, globals())

### num of unic SFC in process
q = "SELECT distinct   SFC from df3"
sqldf(q, globals())
  `,
  OUTPUT_PREVIEW: [
    [
      "Vendor_Batch",
      "Yield",
      "SMARTCAL",
      "DRIFTELITE",
      "OFFSET",
      "RESISTANCE",
      "PROD_VAL",
      "SMARTCAL_Yield",
      "DRIFTELITE_Yield",
      "OFFSET_Yield",
      "RESISTANCE_Yield"
    ],
    [
      "523288-06",
      "0.3794",
      "237",
      "222",
      "211",
      "197",
      "193",
      "0.9367088607594940",
      "0.9504504504504500",
      "0.933649289099526",
      "0.9796954314720810"
    ],
    [
      "523288-08",
      "0.3532",
      "168",
      "154",
      "139",
      "128",
      "126",
      "0.9166666666666670",
      "0.9025974025974030",
      "0.920863309352518",
      "0.984375"
    ],
    [
      "523288-09",
      "0.4531",
      "236",
      "220",
      "195",
      "181",
      "179",
      "0.9322033898305080",
      "0.8863636363636360",
      "0.9282051282051280",
      "0.988950276243094"
    ],
    [
      "523288-10",
      "0.3174",
      "200",
      "176",
      "162",
      "143",
      "141",
      "0.88",
      "0.9204545454545450",
      "0.8827160493827160",
      "0.986013986013986"
    ],
    [
      "523288-11",
      "0.374",
      "176",
      "160",
      "140",
      "132",
      "130",
      "0.9090909090909090",
      "0.875",
      "0.9428571428571430",
      "0.9848484848484850"
    ],
    [
      "523860-01",
      "0.876",
      "241",
      "219",
      "209",
      "195",
      "190",
      "0.9087136929460580",
      "0.954337899543379",
      "0.9330143540669860",
      "0.9743589743589740"
    ]
  ]
};

export const NEW_CURATION_TEXT = "New data curation";
export const CURATION_TEXT = "Curation";
export const CURATION_PLACEHOLDER_TEXT = "Your curations will appear here";
