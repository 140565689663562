import { call, put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./actions";
import * as stationService from "services/api/station";
import { stationQueryActionTypes } from "../../query";

export function* deleteStationWorker(action) {
  const { stationId, callback } = action.payload;
  try {
    yield call(stationService.deleteStation, stationId);
    yield put({ type: actionTypes.STATION_DELETE_SUCCESS, payload: { stationId, callback } });
    yield put({ type: stationQueryActionTypes.DELETE_STATION_FROM_STATE, payload: { stationId } });
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: actionTypes.STATION_DELETE_ERROR, payload: { stationId, error } });
  } finally {
    yield put({ type: actionTypes.STATION_DELETE_FULFILL, payload: { stationId } });
  }
}

export default function* deleteStationWatcher() {
  yield takeLatest(actionTypes.STATION_DELETE_REQUEST, deleteStationWorker);
}
