import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const getHours = date => {
  return new Date(date).getHours() > 9 ? new Date(date).getHours() : "0" + new Date(date).getHours();
};

const getMinutes = date => {
  return new Date(date).getMinutes() > 9 ? new Date(date).getMinutes() : "0" + new Date(date).getMinutes();
};

const getFormattedDateForDisplay = date =>
  `${new Date(date).getDate()} ${new Date(date).toLocaleDateString("default", { month: "short" })} ${new Date(date)
    .getFullYear()
    .toString()
    .slice(-2)}`;

const getFormattedTimeForDisplay = date => `${getHours(date)}:${getMinutes(date)}`;

export default function EnhancedTable({ rows, emptyRows }) {
  return (
    <TableBody>
      {rows.map((row, index) => {
        const labelId = `enhanced-table-${index}`;
        return (
          <TableRow hover tabIndex={-1} key={row.id}>
            <TableCell component="th" id={labelId} scope="row">
              {row.title || "-"}
            </TableCell>
            <TableCell>{row.modelId || "-"}</TableCell>
            <TableCell>{row.modelState || "-"}</TableCell>
            <TableCell>{row.modelType || "-"}</TableCell>
            <TableCell>{!Number.isNaN(Number(row.accuracy)) ? Number(row.accuracy).toFixed(3) : "-"}</TableCell>
            <TableCell>{!Number.isNaN(Number(row.precision)) ? Number(row.precision).toFixed(3) : "-"}</TableCell>
            <TableCell>{!Number.isNaN(Number(row.recall)) ? Number(row.recall)?.toFixed(3) : "-"}</TableCell>
            <TableCell>{!Number.isNaN(Number(row.silhouette)) ? Number(row.silhouette).toFixed(3) : "-"}</TableCell>
            <TableCell>{!Number.isNaN(Number(row.rmse)) ? Number(row.rmse).toFixed(3) : "-"}</TableCell>
            <TableCell>{!Number.isNaN(Number(row.mae)) ? Number(row.mae).toFixed(3) : "-"}</TableCell>
            <TableCell>{row.apiStatus || "-"}</TableCell>
            <TableCell>
              {row.createdAt ? (
                <>
                  {getFormattedTimeForDisplay(row.createdAt)}
                  <br />
                  {getFormattedDateForDisplay(row.createdAt)}
                </>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              {row.deployedAt ? (
                <>
                  {getFormattedTimeForDisplay(row.deployedAt)}
                  <br />
                  {getFormattedDateForDisplay(row.deployedAt)}
                </>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>{row.creatorName || "-"}</TableCell>
            <TableCell>{row.creatorId || "-"}</TableCell>
            <TableCell>{row.creatorEmail || "-"}</TableCell>
            <TableCell>{row.creatorLicense || "-"}</TableCell>
            <TableCell>{row.stationName || "-"}</TableCell>
            <TableCell>{row.stationId || "-"}</TableCell>
            <TableCell>{row.stationType || "-"}</TableCell>
            <TableCell>{row.stationState || "-"}</TableCell>
            <TableCell>{row.accountName || "-"}</TableCell>
            <TableCell>{row.accountId || "-"}</TableCell>
            <TableCell>
              {row.datasetLink ? (
                <a href={row.datasetLink} rel="noopener noreferrer" target="_blank">
                  link
                </a>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              {row.reportLink ? (
                <a href={row.reportLink} rel="noopener noreferrer" target="_blank">
                  link
                </a>
              ) : (
                "-"
              )}
            </TableCell>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
}
