import React, { useMemo } from "react";
import { Text } from "components/ui";
import { useSelector } from "react-redux";
import { userDisplayNameSelector } from "modules/user/state/selectors";
import { vantiColors } from "assets/jss/vanti-dashboard";
import { IconButton, styled } from "@material-ui/core";

const StyledProfileIconButton = styled(IconButton)(({ isUserMenuOpen }) => ({
  "&.MuiIconButton-root": {
    padding: 0,
    width: "24px",
    height: "24px"
  },
  "&:hover": {
    backgroundColor: vantiColors.lightGreen4,
    boxShadow: "0px 0px 0px 4px " + vantiColors.lightGray4
  },
  padding: "4px",
  color: vantiColors.black,
  backgroundColor: vantiColors.lightGreen4,
  boxShadow: isUserMenuOpen ? "0px 0px 0px 4px " + vantiColors.lightGray1 + " !important" : "none"
}));

const IconWrapper = styled("div")({
  width: "32px",
  height: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

export default function UserMenuIcon({ onClick, targetRef, isUserMenuOpen }) {
  const displayName = useSelector(userDisplayNameSelector);
  const shortDisplayName = useMemo(() => {
    return displayName ? displayName.at(0).toUpperCase() : "..";
  }, [displayName]);

  return (
    <IconWrapper>
      <StyledProfileIconButton
        onClick={onClick}
        isUserMenuOpen={isUserMenuOpen}
        ref={targetRef}
        data-testid="top-bar-vanti-icon-button"
      >
        <Text size={12} lineSize={"12px"} weight={500}>
          {shortDisplayName}
        </Text>
      </StyledProfileIconButton>
    </IconWrapper>
  );
}
