import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import directoryReducer, * as directoryModule from "./directory";

export default combineReducers({
  directory: directoryReducer
});

export function* sagas() {
  yield all([directoryModule.notificationListenWatcher(), directoryModule.notificationStopListenWatcher()]);
}
