import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Responsive as ResponsiveGridLayout, WidthProvider } from "react-grid-layout";
import useDashboardRequest from "common/hooks/use-dashboard-request";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { styled } from "@material-ui/core";
import DashboardItem from "./dashboard-item/DashboardItem";
import { VantiScrollBarProps } from "components/ui/VantiScrollBarProps";
import HomePageEmptyState from "../empty-state/HomePageEmptyState";
import GeneralModalPreviewComponent from "components/ui/Modal/GeneralModalPreviewComponent";
import {
  DATASTE_FILE_INFO_MODAL_HEIGHT,
  DATASTE_FILE_INFO_MODAL_WIDTH
} from "pages/side-bar/chat/components/DatasetFileInfoModal";
import DashboardItemReportExpandedView from "./dashboard-item/DashboardItemReportExpandedView";
import { DashboardItemType } from "@vanti-analytics-org/vanti-common";
import { vantiColors } from "assets/jss/palette";

const ReactGridLayout = WidthProvider(ResponsiveGridLayout);
const DASHBOARD_DEFAULT_ROW_HEIGHT = 226; //452px largest available item widget / 2
const DASHBOARD_GRID_SCREEN_WIDTH_UNITS = 3;

//ref: https://codesandbox.io/s/react-grid-layout-ykxts3?file=/src/App.tsx:401-405
const HomePageBody = ({
  rowHeight = DASHBOARD_DEFAULT_ROW_HEIGHT,
  cols = { allResolutions: DASHBOARD_GRID_SCREEN_WIDTH_UNITS },
  breakpoints = { allResolutions: 0 },
  onLayoutChange = () => {},
  onDragStop = () => {},
  onAddItemCallback = () => {},
  ...rest
}) => {
  const [layout, setLayout] = useState(null);
  const { dashboardContext } = useContext(AppContext);
  const {
    dashboardItems,
    currentDashboard,
    isItemDraggingInProgress,
    setIsItemDraggingInProgress,
    openExpandedDashboardItem,
    setOpenExpandedDashboardItem
  } = dashboardContext;
  const { getDashboardItemsRequest } = useDashboardRequest();
  useEffect(() => {
    if (currentDashboard) {
      getDashboardItemsRequest.mutate({ dashboardId: currentDashboard._id });
    }
  }, [currentDashboard]);

  const currentDashboardItems = useMemo(() => dashboardItems[(currentDashboard?._id)], [
    dashboardItems,
    currentDashboard?._id
  ]);

  const generateLayout = useCallback(() => {
    if (!currentDashboardItems) {
      return;
    }

    const layouts = {
      allResolutions: currentDashboardItems.map(item => ({
        x: item.screenData?.x,
        y: item.screenData?.y,
        w: item.screenData?.w,
        h: item.screenData?.h,
        i: item._id
      }))
    };

    return { ...layouts };
  }, [currentDashboardItems]);

  const generateDOM = () => {
    if (!currentDashboardItems) {
      return;
    }

    return currentDashboardItems.map(item => {
      return (
        <div key={item._id}>
          <DashboardItem dashboardItem={item} />
        </div>
      );
    });
  };

  useEffect(() => {
    if (currentDashboardItems?.length) {
      setLayout(generateLayout());
    }
  }, [currentDashboardItems, generateLayout]);

  const expandedViewByItemType = useMemo(() => {
    switch (openExpandedDashboardItem?.type) {
      case DashboardItemType.REPORT:
        return <DashboardItemReportExpandedView isDeleteEnabled={false} />;
      case DashboardItemType.WIDGET: //TODO: implement me
      case DashboardItemType.METRIC: //TODO: implement me
      default:
        return <></>;
    }
  }, [{ ...openExpandedDashboardItem }]);

  return layout && currentDashboardItems?.length ? (
    <>
      <ReactGridLayoutStyled
        isDroppable
        isResizable={false}
        layouts={layout}
        onLayoutChange={onLayoutChange}
        onDrag={() => {
          if (!isItemDraggingInProgress) {
            setIsItemDraggingInProgress(true);
          }
        }}
        onDragStop={onDragStop}
        onResizeStop={onDragStop}
        onBreakpointChange={Breakpoint => {}}
        items={currentDashboardItems?.length}
        cols={cols}
        breakpoints={breakpoints}
        rowHeight={rowHeight}
        {...rest}
      >
        {generateDOM()}
      </ReactGridLayoutStyled>
      {openExpandedDashboardItem && (
        <GeneralModalPreviewComponent
          height={DATASTE_FILE_INFO_MODAL_HEIGHT}
          width={DATASTE_FILE_INFO_MODAL_WIDTH}
          open={openExpandedDashboardItem}
          onClose={() => setOpenExpandedDashboardItem(null)}
          padding={"16px 4px 16px 16px"}
        >
          {expandedViewByItemType}
        </GeneralModalPreviewComponent>
      )}
    </>
  ) : (
    !!currentDashboardItems && <HomePageEmptyState onAddItemCallback={onAddItemCallback} />
  );
};

const ReactGridLayoutStyled = styled(ReactGridLayout)({
  overflow: "auto",
  height: "100% !important", //to prevent grid layout from pushing headers towards top
  "& .react-grid-item": {
    "&.react-grid-placeholder": {
      backgroundColor: vantiColors.gray35,
      opacity: 1, //override the 20% opacity of the component to match the exact color we want. see node_modules/react-grid-layout/css/styles.css      ,
      "&.placeholder-resizing": {
        opacity: 0.2 //restore 20% opacity on resize so it doesn't hide the item. see node_modules/react-grid-layout/css/styles.css
      }
    }
  },
  ...VantiScrollBarProps
});

export default memo(HomePageBody);
