import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Divider from "@material-ui/core/Divider";
import { getChatMessage } from "services/api/chat";
import ChatMessageReportGraphSection from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportGraphSection";
import ChatMessageReportResultSummarySection from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportResultSummarySection";
import ReportExecutiveSummarySectionHeadline from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportExecutiveSummarySection/ReportExecutiveSummarySectionHeadline";
import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import { DAYJS_DATE_FORMAT } from "utils/dateTime";
import UnpinIcon from "assets/icons/push-pin/UnpinIcon";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import ChatMessageReportCreatedByTitle from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReportExecutiveSummarySection/ChatMessageReportCreatedByTitle";
import { DashboardItemContainerStyled } from "./DashboardItemContainerStyled";
import DashboardItemPinUnpinMenu from "./DashboardItemPinUnpinMenu";
import { DashboardItemType } from "@vanti-analytics-org/vanti-common";
import { logger } from "utils/logger";
import ClickablePinIconWithTooltip from "./ClickablePinIconWithTooltip";

const DashboardItemReport = ({ dashboardItem }) => {
  const [reportMessage, setReportMessage] = useState(null);
  const [isHovered, setIsHovered] = useState(null);
  const [isHoveredOverButtonGroup, setIsHoveredOnButtonGroup] = useState(null);
  const { dashboardContext } = useContext(AppContext);
  const { isItemDraggingInProgress, setOpenExpandedDashboardItem } = dashboardContext;
  const [pinUnpinPopupRef, setPinUnpinPopupRef] = useState(null);
  const pinUnpinRef = useRef(null);

  useEffect(async () => {
    try {
      if (dashboardItem.renderData) {
        setReportMessage({
          _id: null,
          reportData: dashboardItem.renderData?.reportContents
        });
        return;
      }

      setReportMessage(await getChatMessage(dashboardItem.metadata.reportId));
    } catch (e) {
      logger.error("Failed to get chatMessage", { reportId: dashboardItem.metadata.reportId, error: e });
    }
  }, [dashboardItem]);

  const reportContent = useMemo(() => {
    return reportMessage?.reportData?.base64Img ? (
      <img src={`data:image/jpeg;base64,${reportMessage?.reportData?.base64Img}`} />
    ) : (
      <ChatMessageReportGraphSection
        message={reportMessage}
        displayHeaderSection={false}
        data-testid={`dashboard-item-report-graph-section-${reportMessage?._id}`}
        isDashboardPreview={true}
        isCompactView={true}
      />
    );
  }, [reportMessage]);

  const onMenuClosedCallback = useCallback(() => {
    setIsHoveredOnButtonGroup(false);
  }, []);

  return (
    reportMessage && (
      <DashbordItemReportContainer
        onClick={() => {
          if (!isItemDraggingInProgress && !isHoveredOverButtonGroup && !pinUnpinPopupRef) {
            setOpenExpandedDashboardItem({
              type: DashboardItemType.REPORT,
              itemId: dashboardItem._id,
              metadata: { reportId: reportMessage._id, reportData: reportMessage.reportData }
            });
          }
        }}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        isHovered={isHovered}
      >
        <DashboardItemReportHeaderContainer>
          <HeadlineAndButtonGroupWrapper>
            <ReportExecutiveSummarySectionHeadline
              message={reportMessage}
              isEditable={false}
              isEllipsis={true}
              size={18}
              weight={500}
              lineSize={"auto"}
              data-testid={`dashboard-item-report-executive-summary-section-headline-${dashboardItem._id}`}
            />
            {isHovered && (
              <ClickablePinIconWithTooltip
                ref={pinUnpinRef}
                onMouseOverCallback={() => {
                  setIsHoveredOnButtonGroup(true);
                }}
                onMouseLeaveCallback={() => {
                  setIsHoveredOnButtonGroup(false);
                }}
                onClick={e => {
                  setPinUnpinPopupRef({ ref: pinUnpinRef.current });
                  e.stopPropagation();
                }}
              />
            )}
          </HeadlineAndButtonGroupWrapper>
          <ChatMessageReportCreatedByTitle
            message={reportMessage}
            color={vantiColors.lightGray16}
            size={12}
            format={DAYJS_DATE_FORMAT.DATE_PICKER}
          />
        </DashboardItemReportHeaderContainer>
        {reportContent}
        {reportMessage.reportData?.resultSummary?.length > 0 && (
          <>
            <Divider />
            <ChatMessageReportResultSummarySection
              data-testid={`dashboard-item-report-result-summary-section-${dashboardItem._id}`}
              resultSummary={reportMessage.reportData.resultSummary}
              lineClamp={2}
            />
          </>
        )}
        {!!pinUnpinPopupRef && (
          <DashboardItemPinUnpinMenu
            dashboardItem={dashboardItem}
            onMenuClosedCallback={onMenuClosedCallback}
            anchorEl={pinUnpinPopupRef}
            setAnchorEl={setPinUnpinPopupRef}
          />
        )}
      </DashbordItemReportContainer>
    )
  );
};

const DashboardItemReportHeaderContainer = styled("div")({
  width: "100%",
  height: "fit-content",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: 2
});

const DashbordItemReportContainer = styled(DashboardItemContainerStyled)({
  gap: 16,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "relative"
});

const HeadlineAndButtonGroupWrapper = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between"
});

export default memo(DashboardItemReport);
