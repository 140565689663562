import config from "common/config";
import http from "../http";

// Server
const predictionApi = `${config.serverUrl}/api/v2/prediction`;

// API
export async function getPredictionResults(stationId, prefix = "", bucket) {
  const res = await http.get(`${predictionApi}/${stationId}?prefix=${prefix}&bucket=${bucket}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function updatePredictionFeedback(predictionId, feedback) {
  try {
    const res = await http.patch(`${predictionApi}/${predictionId}/feedback`, {
      feedback
    });
    const result = await res.json();

    if (res.status > 200) {
      // HTTP error
      throw new Error(result.message);
    }
    if (!result.ok) {
      throw new Error(result.message);
    }

    return result.data;
  } catch (error) {
    console.error(`Error in updatePredictionFeedback: ${error}`);
  }
}

export async function updatePrediction(predictionId, displayName, predictionResult) {
  const res = await http.patch(`${predictionApi}/${predictionId}`, {
    prediction: { data: { displayName }, predictionResult }
  });

  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function getRawPredictionsByType({
  stationId,
  predictionType,
  timeFrame,
  modelIds,
  batchPredictionIds,
  ignoreLimit
}) {
  let query = { "predictionTypes[]": predictionType };

  if (modelIds?.length) {
    query["modelIds[]"] = modelIds;
  }
  if (batchPredictionIds?.length) {
    query["batchPredictionIds[]"] = batchPredictionIds;
  }

  if (stationId) {
    query.stationId = stationId;
  }

  if (timeFrame) {
    query.timeFrame = timeFrame;
  }

  if (ignoreLimit) {
    query.ignoreLimit = ignoreLimit;
  }

  const res = await http.get(`${predictionApi}/`, query);

  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function getPredictionsByLines(startDate) {
  const res = await http.post(`${predictionApi}/predictions-by-lines`, {
    startDate
  });

  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function getPredictionsByApplications(applicationIds) {
  const res = await http.post(`${predictionApi}/predictions-by-applications`, {
    applicationIds
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}
