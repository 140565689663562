import React from "react";

const ChevronRight = ({ color = "#13115B", ...props }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <g transform="translate(0, 2)">
      <path d="M4 2L8 6L4 10" stroke={color} />
    </g>
  </svg>
);

export default React.memo(ChevronRight);
