import { makeStyles } from "@material-ui/core/styles";

import { vantiColors } from "assets/jss/vanti-dashboard";

export default makeStyles(theme => ({
  card: {
    minHeight: "450px"
  },
  cardLeftSection: {
    backgroundColor: vantiColors.darkBlue1,
    flex: 1,
    overflow: "hidden"
  },
  cardRightSection: {
    flex: 1.6
  },
  cardBody: {
    position: "relative",
    padding: theme.spacing(2.5, 5)
  },
  icon: {
    position: "absolute",
    opacity: "6%",
    height: "15vw",
    width: "15vw",
    bottom: "3vw",
    left: "-7.5vw"
  },
  signUp: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& > div": {
      marginTop: theme.spacing(9),
      "& a": {
        fontSize: "14px",
        color: theme.palette.vantiColors.white
      }
    }
  },
  link: {
    position: "relative",
    zIndex: 1,
    size: "14px",
    color: "#FFF",
    cursor: "pointer"
  }
}));
