import React from "react";
import FlexContainer from "../../components/ui/Flex/FlexContainer.js";
import FlexItem from "../../components/ui/Flex/FlexItem.js";
import { makeStyles } from "@material-ui/core/styles";
import Lottie from "react-lottie-player";
import VantiBackground from "../../assets/animations/VantiBackground.json";
import { vantiColors } from "assets/jss/palette.js";

const styles = {
  cardBody: {
    backgroundColor: "white",
    zIndex: 999,
    minHeight: "100vh"
  },
  cardBackground: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflow: "hidden"
  },
  lottieAnimation: {
    backgroundColor: vantiColors.lightGray7,
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  }
};

const useStyles = makeStyles(styles);

const VantiFullPageCard = ({ children }) => {
  const classes = useStyles();

  return (
    <FlexContainer item spacing={0}>
      <FlexItem container xs={12} md={10} className={classes.cardBody} justifyContent="center" dense="full">
        <FlexItem xs={12}>{children}</FlexItem>
      </FlexItem>

      <FlexItem xs={2} className={classes.cardBackground}>
        <Lottie
          rendererSettings={{ preserveAspectRatio: "xMinYMin slice" }}
          className={classes.lottieAnimation}
          loop
          animationData={VantiBackground}
          play
        />
      </FlexItem>
    </FlexContainer>
  );
};

export default VantiFullPageCard;
