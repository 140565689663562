import { vantiColors } from "assets/jss/palette";
import React, { memo } from "react";

const CheckboxFilled24 = ({ fill = vantiColors.darkBlue7, stroke = vantiColors.darkBlue7, ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M4.5 4.5H19.5V19.5H4.5V4.5Z" fill={fill} stroke={stroke} />
      <path d="M7.35669 11.4308L10.6027 14.709L16.6432 8.67926" stroke="white" strokeWidth="1.4" />
    </svg>
  );
};

export default memo(CheckboxFilled24);
