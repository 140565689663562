import { combineReducers } from "redux";
import { all } from "redux-saga/effects";

import onAuthChangedWatcher from "./operations/fetchAuthData/saga";
import fetchAuthDataReducer from "./operations/fetchAuthData/reducer";
import changePasswordReducer from "./operations/changePassword/reducer";
import changePasswordWatcher from "./operations/changePassword/saga";
import signInByEmailReducer from "./operations/signInByEmail/reducer";
import signInByEmailWatcher from "./operations/signInByEmail/saga";
import signOutReducer from "./operations/signOut/reducer";
import signOutWatcher from "./operations/signOut/saga";
import authStateReducer from "./state/reducer";

export default combineReducers({
  state: authStateReducer,
  operations: combineReducers({
    changePassword: changePasswordReducer,
    fetchAuthData: fetchAuthDataReducer,
    signInByEmail: signInByEmailReducer,
    signOut: signOutReducer
  })
});

export function* sagas() {
  yield all([onAuthChangedWatcher(), signOutWatcher(), signInByEmailWatcher(), changePasswordWatcher()]);
}
