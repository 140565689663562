import { makeStyles } from "@material-ui/core";
import { DEFAULT_SPACING } from "pages/side-bar/data-connectors/constants";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "0px !important",
    padding: "0px !important"
  },
  dataSourcesFieldsContainer: {
    "& > div": {
      display: "flex",
      alignItems: "flex-start",

      "&:nth-child(odd)": {
        paddingRight: `${DEFAULT_SPACING}px`
      }
    }
  },
  togglePasswordEyeIcon: {
    padding: theme.spacing(0.5),
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.vantiColors.gray20
    }
  },
  fullWidth: {
    paddingRight: "0px !important"
  }
}));

export default useStyles;
