export const actionTypes = {
  ADD_WEB_NOTIFICATION: "web_notification.add",
  UPDATE_WEB_NOTIFICATION: "web_notification.update",
  SET_WEB_NOTIFICATIONS: "web_notifications.set",
  HIDE_WEB_NOTIFICATION: "web_notifications.hide"
};

export const updateWebNotification = updatedNotification => {
  return { type: actionTypes.UPDATE_WEB_NOTIFICATION, payload: updatedNotification };
};

export const setWebNotifications = webNotificationsList => {
  return { type: actionTypes.SET_WEB_NOTIFICATIONS, payload: webNotificationsList };
};
