import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";

import AppLoader from "AppLoader";

import "assets/css/vanti-dashboard.css?v=1.8.0";
import "react-perfect-scrollbar/dist/css/styles.css";

import store, { history } from "./store";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { vantiColors } from "assets/jss/vanti-dashboard.js";

import * as palette from "assets/jss/palette";
import * as fonts from "assets/jss/fonts";
import { datadogRum } from "@datadog/browser-rum";
import config from "common/config";

// TODO: import all the colors from vanti-dashboard.js file.
// This is the right way to use theme in material-ui.
// Otherwise every component needs to explicitly import all the colors.
const theme = createTheme({
  palette: {
    primary: {
      main: vantiColors.green1
    },
    secondary: {
      main: vantiColors.turquoise1
    },
    transparentize: (hex, opacity) => `${hex}${opacity.split("%")[0]}`,
    ...palette
  },
  typography: {
    button: {
      textTransform: "none"
    }
  },
  fonts: {
    ...fonts
  }
});

document.getElementById("root").style.backgroundColor = palette.APP_MAIN_BACKGROUND_COLOR;
document.documentElement.style.backgroundColor = palette.APP_MAIN_BACKGROUND_COLOR;

const queryClient = new QueryClient();
if (!window.disableDataDog && config.monitor.applicationId && config.monitor.clientToken) {
  datadogRum.init({
    applicationId: config.monitor.applicationId,
    clientToken: config.monitor.clientToken,
    site: "datadoghq.com",
    service: "vanti-web",
    env: config.env,
    version: config.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input"
  });
} else {
  console.warn("Monitoring Disabled");
}

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <AppLoader />
          </QueryClientProvider>
        </HelmetProvider>
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
