export const actionTypes = {
  ACCOUNT_REMOVE_USER_REQUEST: "account.removeUser.request",
  ACCOUNT_REMOVE_USER_SUCCESS: "account.removeUser.success",
  ACCOUNT_REMOVE_USER_ERROR: "account.removeUser.error",
  ACCOUNT_REMOVE_USER_FULFILL: "account.removeUser.fulfill"
};

export function removeUserRequestAction(user) {
  return { type: actionTypes.ACCOUNT_REMOVE_USER_REQUEST, payload: user };
}
