const FULLSTORY_EVENTS = {
  NEW_ACCOUNT_CREATED_SUCCESSFULLY: "New account created successfully",
  USER_FIRST_SIGN_IN: "User signed in for the first time",
  USER_SUCCESSFULLY_TRAINED_MODEL: "User successfully trained a model",
  USER_SUCCESSFULLY_UPLOADED_VALID_DATASET: "User successfully uploaded a valid dataset",
  USER_CLICKED_ON_CREATE_NEW_STATION_BUTTON_FOR_THE_FIRST_TIME:
    "User has clicked on `create a new station` button for the first time",
  USER_CLICKED_CONTACT_ME_FILE_TYPE_NOT_SUPPORTED:
    "User has clicked on `contact me` because his file type is not supported",
  USER_SUCCESSFULLY_DEPLOYED_MODEL_BATCH: "User has successfully deployed a model (batch)",
  USER_SUCCESSFULLY_INTEGRATED_WITH_MODEL_BATCH: "User has successfully integrated with a model (batch)",
  USER_CLICKED_LETS_TALK_BUTTON_IN_TRIAL_BANNER: "User has clicked on the `let's talk` button inside the trial banner",
  MODEL_RETURNED_ERROR_DURING_TRAINING: "Model returned an error during training",
  MODEL_RETURNED_ERROR_DURING_DEPLOYMENT: "Model returned an error during deployment",
  MODEL_IN_PENDING_CUSTOM_STATE: "Model is pending in a custom state"
};

export default FULLSTORY_EVENTS;
