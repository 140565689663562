import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const CheckIcon = ({ color = vantiColors.darkBlue1, width = 13, height = 10, ...rest }) => (
  <svg width={width} height={height} viewBox={`0 0 13 10`} fill="none" {...rest}>
    <path
      d="M1.35355 5.14645L1 4.79289L0.292893 5.5L0.646447 5.85355L1.35355 5.14645ZM4.5 9L4.14645 9.35355L4.52435 9.73146L4.87629 9.32925L4.5 9ZM11.8763 1.32925L12.2055 0.952964L11.453 0.294459L11.1237 0.670748L11.8763 1.32925ZM0.646447 5.85355L4.14645 9.35355L4.85355 8.64645L1.35355 5.14645L0.646447 5.85355ZM4.87629 9.32925L11.8763 1.32925L11.1237 0.670748L4.12371 8.67075L4.87629 9.32925Z"
      fill={color}
    />
  </svg>
);

export default memo(CheckIcon);
