import { vantiColors } from "assets/jss/palette";

export const GRAPH_TEXTS = {
  LINE_GRAPH: {
    HEADER: "Units per line"
  },
  PRODUCT_GRAPH: {
    HEADER: "Units over time"
  }
};

let ticksLines = [];
let earlyFaultDetectionLines = [];
let defectDetectionLines = [];

export const updateTicksLines = newTicksLines => {
  ticksLines = newTicksLines.map(tickLine => {
    return {
      x: tickLine.date,
      y: tickLine.count,
      type: tickLine.type
    };
  });

  earlyFaultDetectionLines = ticksLines.filter(line => line.type === "Early Fault Detected");
  defectDetectionLines = ticksLines.filter(line => line.type !== "Early Fault Detected");
};

export const generateChartColors = context => {
  const ctx = context.chart.ctx;
  const gradient = ctx.createLinearGradient(0, context.chart.height, 0, 0);
  gradient.addColorStop(0, vantiColors.green5);
  gradient.addColorStop(0.25, vantiColors.green6);
  gradient.addColorStop(0.7, vantiColors.green7);
  gradient.addColorStop(1, vantiColors.turquoise5);
  return gradient;
};

export const generateSecondaryChartColors = context => {
  const ctx = context.chart.ctx;
  const gradient = ctx.createLinearGradient(0, context.chart.height, 0, 0);
  gradient.addColorStop(0, vantiColors.purple6);
  gradient.addColorStop(0.25, vantiColors.purple7);
  gradient.addColorStop(0.7, vantiColors.purple8);
  gradient.addColorStop(1, vantiColors.purple9);
  return gradient;
};

export const getChartLabels = valuesArray => {
  const labels = [];
  for (let i = 0; i < valuesArray.length; i++) {
    labels.push(i);
  }
  return labels;
};

const getOrCreateTooltip = chart => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.width = "170px";
    tooltipEl.style.height = "150px";
    tooltipEl.style.background = "#ffffff54";
    tooltipEl.style.backdropFilter = "blur(10px)";
    tooltipEl.style.borderRadius = "16px";
    tooltipEl.style.color = "black";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, -10%)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.fontSize = "14px";
    tooltipEl.style.boxShadow = "0px -1px 4px rgba(0, 0, 0, 0.25)";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const createExternalTooltipHandler = () => {
  return context => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    if (tooltip.body) {
      // Creating the elements that will be displayed inside the tooltip
      const container = document.createElement("div");
      container.style.paddingLeft = "12px";
      container.style.display = "flex";
      container.style.justifyContent = "center";
      container.style.textAlign = "center";

      const contentElement = document.createElement("div");
      contentElement.style.width = "144px";
      contentElement.style.height = "100px";

      const descriptionTextElement = document.createElement("p");
      descriptionTextElement.style.color = vantiColors.darkBlue6;
      descriptionTextElement.style.fontWeight = 600;
      descriptionTextElement.style.fontSize = "16px";
      descriptionTextElement.style.marginTop = "16px";
      descriptionTextElement.style.marginBottom = "2px";
      descriptionTextElement.innerText = "Data adaptation ";

      const unitsSavedPercentageTextElement = document.createElement("span");
      unitsSavedPercentageTextElement.style.fontWeight = 300;
      unitsSavedPercentageTextElement.style.fontSize = "16px";

      const unitsPredictedTextElement = document.createElement("span");
      unitsPredictedTextElement.innerText = "units predicted";

      const dataPointElement = document.createElement("div");
      dataPointElement.style.width = "16px";
      dataPointElement.style.height = "16px";
      dataPointElement.style.border = "3px solid white";
      dataPointElement.style.backgroundColor = vantiColors.green4;
      dataPointElement.style.borderRadius = "50px";
      dataPointElement.style.display = "inline-block";

      const datasetIndex = tooltip.dataPoints[0].datasetIndex;
      if (datasetIndex === 0) {
        unitsSavedPercentageTextElement.innerText = `${earlyFaultDetectionLines[tooltip.dataPoints[0].dataIndex].y} units predicted`;
        dataPointElement.style.backgroundColor = vantiColors.purple5;
      } else if (datasetIndex === 1) {
        unitsSavedPercentageTextElement.innerText = `${defectDetectionLines[tooltip.dataPoints[0].dataIndex].y} units predicted`;
        dataPointElement.style.backgroundColor = vantiColors.green4;
      }

      contentElement.appendChild(dataPointElement);
      contentElement.appendChild(descriptionTextElement);
      contentElement.appendChild(unitsSavedPercentageTextElement);

      container.appendChild(contentElement);
      descriptionTextElement.setAttribute("data-testid", descriptionTextElement.innerText);
      unitsSavedPercentageTextElement.setAttribute("data-testid", unitsSavedPercentageTextElement.innerText);
      container.setAttribute("data-testid", `${unitsSavedPercentageTextElement.innerText}-canvas-point`);

      const tableRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(container);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };
};

export const defaultOptions = {
  maintainAspectRatio: false,
  scales: {
    x: {
      title: {
        text: "Time",
        align: "center",
        display: true
      },
      grid: {
        borderDash: [3, 1],
        color: vantiColors.lightGray6
      },
      ticks: {
        color: vantiColors.lightGray6,
        font: {
          size: 12,
          weight: 400,
          family: "Inter"
        },
        crossAlign: "near",
        align: "end",
        labelOffset: -3,
        callback: function(value) {
          return new Date(this.getLabelForValue(value)).toLocaleTimeString("en-us", {
            hour: "2-digit",
            minute: "2-digit"
          });
        }
      }
    },
    y: {
      title: {
        text: "Units",
        align: "center",
        display: true
      },
      grid: {
        borderDash: [3, 1],
        color: vantiColors.lightGray6
      },
      ticks: {
        color: vantiColors.lightGray6,
        font: {
          size: 14,
          weight: 400,
          family: "Inter"
        }
      }
    }
  },
  elements: {
    point: {
      borderWidth: 2,
      radius: 6,
      pointHoverRadius: 6,
      pointBorderColor: vantiColors.white
    }
  },
  plugins: {
    datalabels: {
      display: true
    },
    legend: {
      display: true,
      align: "end",
      labels: {
        boxWidth: 16,
        boxHeight: 16
      }
    },
    title: {
      display: false,
      text: GRAPH_TEXTS.PRODUCT_GRAPH.HEADER,
      font: { size: 16, weight: "regular" },
      color: vantiColors.black,
      align: "start"
    },
    tooltip: {
      callbacks: {
        title: () => {
          return null;
        },
        label: () => {
          return null;
        }
      },
      enabled: false,
      external: createExternalTooltipHandler()
    }
  },
  borderWidth: 2
};
export const DEFAULT_CHART_LINE_TENSION = 0.5;

export default defaultOptions;
