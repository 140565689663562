import { vantiColors } from "assets/jss/palette";

export const TAGS_ENUM = {
  PRODUCT: "PRODUCT",
  LINE: "LINE"
};

export const TAGS = {
  PRODUCT: {
    type: TAGS_ENUM.PRODUCT,
    name: "Product",
    backgroundColor: vantiColors.yellow1,
    textColor: vantiColors.yellow2,
    themeColor: vantiColors.yellow3,
    hoverBackgroundColor: vantiColors.yellow4,
    highlightColor: vantiColors.yellow1
  },
  LINE: {
    type: TAGS_ENUM.LINE,
    name: "Line",
    backgroundColor: vantiColors.red1,
    textColor: vantiColors.red2,
    themeColor: vantiColors.red3,
    hoverBackgroundColor: vantiColors.red4,
    highlightColor: vantiColors.red1
  }
};

export const TAGS_TEXTS = {
  PRODUCT: {
    addProduct: "Add product"
  },
  LINE: {
    addLine: "Add line"
  }
};

export const EMPTY_CHIP_TEXT = "add";
