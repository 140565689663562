import http from "../http";
import config from "common/config";

const fileExplorerApi = `${config.serverUrl}/api/v2/file-explorer`;

export async function listObjects(connectorId, prefix = "", root) {
  const res = await http.get(
    `${fileExplorerApi}/list-objects/${connectorId}?prefix=${prefix || ""}${root ? `&root=${root}` : ""}`
  );
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  if (!result.ok) {
    return {
      permissionError: {
        restrictedOperation: result.message
      }
    };
  }

  return result.data;
}

export async function getRootFolder(connectorId) {
  const res = await http.get(`${fileExplorerApi}/root/${connectorId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  if (!result.ok) {
    return {
      permissionError: {
        restrictedOperation: result.message
      }
    };
  }

  return result.data;
}
