import React, { memo, useMemo, useState } from "react";
import { ChatMessageContentWrapper } from "pages/side-bar/chat/components/chat-message/ChatMessageContentCommonStyles";
import { styled } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { vantiColors } from "assets/jss/palette";
import ChatMessageReportExecutiveSummarySection from "./ChatMessageReportExecutiveSummarySection/ChatMessageReportExecutiveSummarySection";
import ChatMessageReportDataPreviewSection from "./ChatMessageReportDataPreviewSection/ChatMessageReportDataPreviewSection";
import ChatMessageReportGraphSection from "./ChatMessageReportGraphSection";
import ChatMessageReportResultSummarySection from "./ChatMessageReportResultSummarySection";
import { GRAPH_TYPES } from "../../DynamicGraph";
import ExportChatMessageReportModal from "./ExportChatMessageReportModal";
import StaticReportDatasetSelection from "./StaticReportDatasetSelection";

const ChatMessageReportContent = styled("div")(({ isStaticReport }) => ({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  width: "100%",
  backgroundColor: vantiColors.gray18,
  padding: 16,
  gap: 32,
  height: isStaticReport ? "100%" : "unset",
  overflowY: isStaticReport ? "auto" : "unset"
}));

export const isSufficientGraphDataForDisplay = reportData => {
  if (!reportData) {
    return false;
  }

  //reportData must have all these non-empty and existing fields: chartData, chartLabels, chartType
  return (
    reportData.chartData &&
    Object.values(reportData.chartData).every(elem => elem.length) &&
    reportData.chartLabels &&
    Object.values(reportData.chartLabels).every(elem => elem.length) &&
    reportData.chartType &&
    reportData.chartType.length &&
    Object.keys(GRAPH_TYPES).includes(reportData?.chartType?.toUpperCase())
  );
};

const ChatMessageReport = ({ message, staticReportCallbacks }) => {
  const { reportData } = message;
  const isStaticReport = !!staticReportCallbacks;
  const isStaticReportDatasetSelection = !!staticReportCallbacks?.onUpdateField;
  const { resultSummary, base64Img } = reportData;

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const isSufficientGraphData = useMemo(() => {
    return isSufficientGraphDataForDisplay(reportData);
  }, [{ ...reportData }]);

  const isSufficientResultSummaryData = useMemo(() => resultSummary?.length > 0, [resultSummary?.length]);

  return (
    <ChatMessageContentWrapper isStaticReport={isStaticReport}>
      <ChatMessageReportContent
        isStaticReport={isStaticReport}
        data-testid={`chat-message-report-content-${message._id}`}
      >
        <ChatMessageReportExecutiveSummarySection
          message={message}
          onExportModel={() => setIsExportModalOpen(true)}
          staticReportCallbacks={staticReportCallbacks}
          data-testid={`report-executive-summary-section-${message._id}`}
        />

        <Divider />

        {isStaticReportDatasetSelection ? (
          <StaticReportDatasetSelection onDatasetSelected={staticReportCallbacks?.onUpdateField} />
        ) : (
          <ChatMessageReportDataPreviewSection
            message={message}
            data-testid={`report-data-preview-section-${message._id}`}
          />
        )}
        {base64Img ? (
          <>
            <Divider />
            <img src={`data:image/jpeg;base64,${base64Img}`} />
          </>
        ) : (
          isSufficientGraphData && (
            <>
              <Divider />
              <ChatMessageReportGraphSection message={message} data-testid={`report-graph-section-${message._id}`} />
            </>
          )
        )}

        <Divider />

        {isSufficientResultSummaryData && (
          <ChatMessageReportResultSummarySection
            staticReportCallbacks={staticReportCallbacks}
            data-testid={`report-result-summary-section-${message._id}`}
            resultSummary={reportData.resultSummary}
          />
        )}
      </ChatMessageReportContent>

      <ExportChatMessageReportModal
        exportedModelName={reportData.description}
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
      />
    </ChatMessageContentWrapper>
  );
};

export default memo(ChatMessageReport);
