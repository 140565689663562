import React from "react";

const ChevronLeft = ({ color = "#13115B", ...props }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <g transform="translate(0, 2)">
      <path d="M8 10L4 6L8 2" stroke={color} />
    </g>
  </svg>
);

export default React.memo(ChevronLeft);
