import React from "react";
import { makeStyles } from "@material-ui/core";
import LoadingIcon from "./LoadingIcon";

import cs from "classnames";

const useStyles = makeStyles(() => ({
  "@keyframes rotating-icon": {
    "0%": {
      transform: "rotate(0deg)"
    },
    "100%": {
      transform: "rotate(360deg)"
    }
  },
  rotatingIcon: {
    animation: "$rotating-icon 1s linear infinite"
  },
  iconContainer: {
    display: "block",
    width: "20px",
    height: "20px"
  }
}));

const RotatingLoadingIcon = ({ className, width, height, color, fill, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={cs(className, { [classes.iconContainer]: true })} {...rest}>
      <LoadingIcon className={classes.rotatingIcon} width={width} height={height} color={color} fill={fill} />
    </div>
  );
};

export default RotatingLoadingIcon;
