import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import dataCurationsReducer from "./reducers";
import { fetchCurationsWatcher } from "./sagas";

export default combineReducers({
  state: dataCurationsReducer
});

export function* sagas() {
  yield all([fetchCurationsWatcher()]);
}
