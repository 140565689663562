import { vantiColors } from "assets/jss/palette";

export const GOAL_TYPE = {
  PREDICTIVE_QUALITY: "PREDICTIVE_QUALITY",
  DEFECT_DETECTION: "DEFECT_DETECTION",
  PROCESS_OPTIMIZATION: "PROCESS_OPTIMIZATION",
  CUSTOM_USE_CASE: "CUSTOM_USE_CASE",
  EMPTY: "None "
};

export const GOAL_VALUES = Object.freeze({
  [GOAL_TYPE.DEFECT_DETECTION]: GOAL_TYPE.DEFECT_DETECTION,
  [GOAL_TYPE.PREDICTIVE_QUALITY]: GOAL_TYPE.PREDICTIVE_QUALITY,
  [GOAL_TYPE.PROCESS_OPTIMIZATION]: GOAL_TYPE.PROCESS_OPTIMIZATION,
  [GOAL_TYPE.CUSTOM_USE_CASE]: [GOAL_TYPE.CUSTOM_USE_CASE],
  EMPTY: "None "
});

export const STATION_GOALS = {
  [GOAL_TYPE.PREDICTIVE_QUALITY]: {
    type: "Predictive quality",
    description: "Predict faulty units early in production"
  },
  [GOAL_TYPE.DEFECT_DETECTION]: {
    type: "Defect detection",
    description: "Identify defect in units\n" + "at the station"
  },
  [GOAL_TYPE.PROCESS_OPTIMIZATION]: {
    type: "Process optimization",
    description: "e.g optimize electricity consumption"
  },
  [GOAL_TYPE.CUSTOM_USE_CASE]: {
    type: "Custom goal",
    description: "Define your unique outcome"
  }
};

export const GROUP_BY_VALUES = Object.freeze({
  NONE: {
    value: "none",
    text: "none"
  },
  GOAL: {
    value: "goal",
    text: "goal"
  },
  STATUS: {
    value: "status",
    text: "status"
  }
});

export const GOAL_TEXT_MAP = Object.freeze({
  [GOAL_TYPE.PREDICTIVE_QUALITY]: {
    lowercaseText: "Predictive quality",
    color: vantiColors.purple2,
    checkedColor: vantiColors.purple2
  },
  [GOAL_TYPE.PROCESS_OPTIMIZATION]: {
    lowercaseText: "Process optimization",
    color: vantiColors.blue7,
    checkedColor: vantiColors.blue7
  },
  [GOAL_TYPE.DEFECT_DETECTION]: {
    lowercaseText: "Defect detection",
    color: vantiColors.green1,
    checkedColor: vantiColors.green1
  },
  [GOAL_TYPE.CUSTOM_USE_CASE]: { lowercaseText: "Custom ", color: vantiColors.blue6, checkedColor: vantiColors.blue6 },
  "None ": { lowercaseText: "None ", color: vantiColors.gray19, checkedColor: vantiColors.gray19 }
});

export const APPLICATIONS_HEADER = "Applications";
