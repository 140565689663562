import http from "../http";
import config from "common/config";

export const DASHBOARD_API_QUERY_KEYS = {
  CREATE_DASHBOARD: "CREATE_DASHBOARD",
  GET_DASHBOARD: "GET_DASHBOARD",
  GET_DASHBOARDS: "GET_DASHBOARDS",
  GET_DASHBOARD_ITEMS: "GET_DASHBOARD_ITEMS",
  UPDATE_DASHBOARD_ITEMS: "UPDATE_DASHBOARD_ITEMS",
  ADD_DASHBOARD: "ADD_DASHBOARD",
  ADD_DASHBOARD_ITEMS: "ADD_DASHBOARD_ITEMS",
  UPDATE_DASHBOARD: "UPDATE_DASHBOARD",
  DELETE_DASHBOARDS: "DELETE_DASHBOARDS",
  DELETE_DASHBOARD_ITEM: "DELETE_DASHBOARD_ITEM",
  GET_USER_DASHBOARD_ITEMS: "GET_USER_DASHBOARD_ITEMS",
  PIN_UNPIN_USER_DASHBOARD_ITEMS: "PIN_UNPIN_USER_DASHBOARD_ITEMS",
  GET_ACCOUNT_USERS_DASHBOARD_INVITATION_INFO: "GET_ACCOUNT_USERS_DASHBOARD_INVITATION_INFO",
  SHARE_DASHBOARD: "SHARE_DASHBOARD",
  ACCEPT_DASHBOARD_INVITATION: "ACCEPT_DASHBOARD_INVITATION",
  GET_ACCOUNT_DASHBOARD_ITEMS: "GET_ACCOUNT_DASHBOARD_ITEMS"
};

const DASHBOARD_API_URL = `${config.serverUrl}/api/v2/dashboard`;

export async function getDashboard(dashboardId) {
  const res = await http.get(`${DASHBOARD_API_URL}/${dashboardId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function shareDashboard(dashboardId, userId) {
  const res = await http.post(`${DASHBOARD_API_URL}/share/${dashboardId}`, { userId });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function acceptDashboardInvitation(notificationId) {
  const res = await http.post(`${DASHBOARD_API_URL}/accept-dashboard-invitation/${notificationId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function getAccountUsersDashboardInvitationInfo() {
  const res = await http.get(`${DASHBOARD_API_URL}/account-users-dashboard-invitation-info`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function getDashboards() {
  const res = await http.get(`${DASHBOARD_API_URL}/`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function getDashboardItems(dashboardId) {
  const res = await http.get(`${DASHBOARD_API_URL}/dashboard-items/${dashboardId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function addDashboard(dashboard) {
  const res = await http.post(`${DASHBOARD_API_URL}/`, dashboard);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}
export async function addDashboardItems(dashboardId, items) {
  const res = await http.post(`${DASHBOARD_API_URL}/dashboard-items/${dashboardId}`, items);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function deleteDashboards(dashboardIds) {
  const res = await http.delete(`${DASHBOARD_API_URL}/`, dashboardIds);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function deleteDashboardItem(id) {
  const res = await http.delete(`${DASHBOARD_API_URL}/dashboard-items/${id}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export const updateDashboard = async (dashboardId, updateFields) => {
  const res = await http.patch(`${DASHBOARD_API_URL}/${dashboardId}`, updateFields);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const updateDashboardItems = async (dashboardId, items) => {
  const res = await http.patch(`${DASHBOARD_API_URL}/dashboard-items/${dashboardId}`, items);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const pinUnpinUserDashboardItems = async (type, metadata, pinnedDashboardIds) => {
  const res = await http.patch(`${DASHBOARD_API_URL}/dashboard-items/`, { type, metadata, pinnedDashboardIds });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const getUserDashboardItems = async () => {
  const res = await http.get(`${DASHBOARD_API_URL}/dashboard-items`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export async function getDashboardItemDatabasePreview(dashboardItem, desiredPageNumber, itemsPerPage) {
  const response = await http.get(`${DASHBOARD_API_URL}/dashboard-items/${dashboardItem}/database-preview`, {
    desiredPageNumber,
    itemsPerPage
  });
  const res = await response.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(res.message);
  }
  if (!res.ok) {
    throw new Error(res.message);
  }

  return res.data;
}
