import predictionActionTypes from "./actionTypes";

const defaultState = {
  predictionResults: {},
  success: null,
  error: null
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case predictionActionTypes.PREDICTION_QUERY_SUCCESS: {
      const { predictionResult } = action.payload;

      if (!predictionResult?.length) {
        throw `Error encountered in ${predictionActionTypes.PREDICTION_QUERY_SUCCESS} case of prediction reducer`;
      }

      const newState = state;

      const newPredictionsData = predictionResult.map(prediction => {
        const correctTimeSplitted = new Date(prediction.timeOfPredict)
          .toLocaleString("en-US")
          .replace("PM", "")
          .replace("AM", "")
          .split(":");

        const correctTime = `${correctTimeSplitted[0]}:${correctTimeSplitted[1]}`;

        return { ...prediction, timeOfPredict: correctTime };
      });

      newState.predictionResults[predictionResult[0].stationId] = newPredictionsData;
      return { ...newState };
    }

    case predictionActionTypes.PREDICTION_QUERY_ERROR: {
      const { error } = action.payload;
      return { ...state, error };
    }

    case predictionActionTypes.ADD_PREDICTION_TO_STATE: {
      const { predictionResult } = action.payload;

      const newState = { ...state };

      const correctTimeSplitted = new Date(predictionResult.timeOfPredict)
        .toLocaleString("en-US")
        .replace("PM", "")
        .replace("AM", "")
        .split(":");

      const correctTime = `${correctTimeSplitted[0]}:${correctTimeSplitted[1]}`;

      if (newState.predictionResults[predictionResult.stationId]) {
        const indexOfExistingPredict = newState.predictionResults[predictionResult.stationId].findIndex(
          prediction => prediction.id === predictionResult.id
        );
        if (indexOfExistingPredict === -1) {
          newState.predictionResults[predictionResult.stationId].unshift({
            ...predictionResult,
            updated: predictionResult.updated ? new Date(predictionResult.updated).toLocaleString() : null,
            timeOfPredict: correctTime
          });
        } else {
          newState.predictionResults[predictionResult.stationId][indexOfExistingPredict] = {
            ...predictionResult,
            updated: predictionResult.updated ? new Date(predictionResult.updated).toLocaleString() : null,
            timeOfPredict: correctTime
          };
        }
      } else {
        newState.predictionResults[predictionResult.stationId] = [
          {
            ...predictionResult,
            updated: predictionResult.updated ? new Date(predictionResult.updated).toLocaleString() : null,
            timeOfPredict: correctTime
          }
        ];
      }

      return newState;
    }

    default: {
      return state;
    }
  }
}
