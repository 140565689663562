import { actionTypes } from "./actions";
import { PERSONAS_ENUM } from "../../../common/constants/AccountConstants";

const defaultState = {
  email: null,
  id: null,
  firstName: null,
  lastName: null,
  license: null,
  loadingRoles: true
};

export default function userStateReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.USER_SET_STATE_IS_READ_NOTIFICATION_CENTER_REQUEST: {
      return {
        ...state,
        isReadNotificationCenter: action.payload
      };
    }
    case actionTypes.USER_SET_STATE: {
      const user = action.payload;
      return {
        ...state,
        email: user.email,
        id: user.id,
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        displayName: user.displayName || "",
        fullName: user.displayName || "",
        permissions: user.permissions || {
          role: PERSONAS_ENUM.VIEWER
        },
        phoneNumber: user.phoneNumber,
        roleInCompany: user.roleInCompany,
        totalStationsOpened: user.metrics?.totalStationsOpened,
        isReadNotificationCenter: user.isReadNotificationCenter
      };
    }
    case actionTypes.USER_SET_ROLES: {
      return { ...state, roles: action.payload, loadingRoles: false };
    }
    case actionTypes.USER_CLEAR_STATE: {
      return {
        ...defaultState
      };
    }
    default:
      return state;
  }
}
