export const actionTypes = {
  ACCOUNT_RESEND_INVITE_REQUEST: "account.resendInvite.request",
  ACCOUNT_RESEND_INVITE_SUCCESS: "account.resendInvite.success",
  ACCOUNT_RESEND_INVITE_ERROR: "account.resendInvite.error",
  ACCOUNT_RESEND_INVITE_FULFILL: "account.resendInvite.fulfill"
};

export function resendInviteRequestAction(userId) {
  return { type: actionTypes.ACCOUNT_RESEND_INVITE_REQUEST, payload: userId };
}
