import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  accordion: {
    background: theme.palette.vantiColors.gray18
  },
  expandIcon: {
    "&$expanded": {
      transform: "rotate(0deg)"
    }
  },
  progressDirection: {
    transform: "scaleX(-1)"
  },
  expanded: {
    transform: "rotate(0deg)"
  },
  expandIconAccordion: {
    transform: "rotate(-90deg) !important",
    "&$expandedAccordion": {
      transform: "rotate(0deg) !important"
    }
  },
  expandedAccordion: {
    transform: "rotate(0deg)"
  },
  disabled: {
    color: "black !important"
  }
}));
