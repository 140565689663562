import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";

export const ChatBubble = styled("div")(({ isUserMessage }) => ({
  top: "0 !important",
  position: "relative !important",
  boxSizing: "border-box",
  width: "fit-content !important",
  minHeight: "43px",
  padding: "12px 16px",
  borderRadius: isUserMessage ? "20px 20px 2px 20px" : "20px 20px 20px 0px",
  backgroundColor: isUserMessage ? "#222E47" : vantiColors.gray35,
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center"
}));

export const ChatMessageContentWrapper = styled("div")(({ isUserMessage, isStaticReport }) => ({
  display: "flex",
  gap: 8,
  padding: "0px 16px",
  flexDirection: "row",
  justifyContent: isUserMessage ? "flex-end" : "flex-start",
  alignItems: "self-end",
  overflowY: isStaticReport ? "auto" : "hidden"
}));

export const ChatMessageContentContainer = styled("div")(({ isUserMessage }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 4,
  justifyContent: isUserMessage ? "flex-end" : "flex-start",
  boxSizing: "border-box"
}));

export const ChatMessageMultipartMessageWrapper = styled("div")(({ isFadingOut }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  opacity: isFadingOut ? 0 : 1,
  transition: isFadingOut ? "all 250ms linear 0s" : "none"
}));
