import React, { useState } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import FlexItem from "../../components/ui/Flex/FlexItem.js";
import FlexContainer from "../../components/ui/Flex/FlexContainer.js";
import CardBody from "../../components/ui/Card/CardBody";
import CardFooter from "../../components/ui/Card/CardFooter";
import CardHeader from "../../components/ui/Card/CardHeader";
import Text from "../../components/ui/Typography/Text";
import { ReactComponent as LogoWhiteGreenDark } from "assets/img/vanti-logo/logo-white-green-dark.svg";
import BrandedTitle from "../../components/ui/Typography/BrandedTitle";
import AuthCard from "./components/auth-card";
import * as authService from "../../services/auth/auth";
import TextUnderlinedInput from "components/ui/Inputs/TextUnderlinedInput.js";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton.js";

const CHANGE_PW_DESCRIPTION_TEXT = "Type in your email address below to receive your password reset instructions.";
const CHECK_YOUR_EMAIL_TEXT = "Please check your email.";
const CANCEL_TEXT = "Cancel";
const SUBMIT_TEXT = "Submit";
const DONE_TEXT = "Done";

const TextUnderlinedInputWrapperStyled = styled("div")({
  marginTop: 16
});

const FooterButtonsWrapperStyled = styled("div")({
  display: "flex",
  columnGap: 16,
  justifyContent: "flex-end",
  width: "100%"
});

const styles = {
  cardHeader: {
    padding: "0",
    display: "flex",
    justifyContent: "center"
  },
  cardBody: {
    position: "relative",
    padding: "20px 40px"
  },
  cardFooter: {
    padding: "35px 40px"
  }
};

const useStyles = makeStyles(styles);

const maxInputFieldLength = 128;

export default function ForgotPassword() {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isSent, setIsSent] = useState(false);

  const onClickResetPassword = () =>
    authService
      .resetPassword(email)
      .then(() => setIsSent(true))
      .catch(error => {
        setError(error);
      });
  const onClickCancel = () => history.push("/auth/sign-in");
  const onClickDone = () => history.push("/auth/sign-in");

  return (
    <FlexContainer justifyContent={"center"}>
      <FlexItem xs={12} sm={8} md={6} container direction={"row"}>
        <AuthCard withSignUp={false}>
          <CardHeader color="transparent" className={classes.cardHeader}>
            <div>
              <LogoWhiteGreenDark height={"50px"} />
            </div>
          </CardHeader>

          <CardBody className={classes.cardBody}>
            <BrandedTitle text={"Change Password"} size="28px" />

            {!isSent ? (
              <>
                <Text data-testid="change-password-body-text" size="16px">
                  {CHANGE_PW_DESCRIPTION_TEXT}
                </Text>

                <TextUnderlinedInputWrapperStyled>
                  <TextUnderlinedInput
                    labelText="Email"
                    id="email-reset-password"
                    labelProps={{ shrink: true }}
                    formControlProps={{ fullWidth: true, margin: "dense" }}
                    inputProps={{
                      value: email,
                      onChange: e => {
                        setError(null);
                        setEmail(e.target.value.trim());
                      },
                      maxLength: maxInputFieldLength
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </TextUnderlinedInputWrapperStyled>
              </>
            ) : (
              <Text data-testid="check-email-text" color="darkBlue" size="16px">
                {CHECK_YOUR_EMAIL_TEXT}
              </Text>
            )}
          </CardBody>

          <CardFooter className={classes.cardFooter}>
            <FooterButtonsWrapperStyled>
              {!isSent && (
                <>
                  <GeneralVantiButton
                    buttonInfo={{
                      onClick: onClickCancel,
                      label: CANCEL_TEXT,
                      disabled: false
                    }}
                    variant={VARIANTS.SECONDARY}
                    size={SIZES.BIG}
                    data-testid="cancel-btn"
                  />

                  <GeneralVantiButton
                    buttonInfo={{
                      onClick: onClickResetPassword,
                      label: SUBMIT_TEXT,
                      disabled: !email
                    }}
                    variant={VARIANTS.PRIMARY}
                    size={SIZES.BIG}
                    data-testid="submit-btn"
                  />
                </>
              )}

              {isSent && (
                <GeneralVantiButton
                  data-testid="done-btn"
                  buttonInfo={{
                    label: DONE_TEXT,
                    disabled: !isSent,
                    onClick: onClickDone
                  }}
                />
              )}
            </FooterButtonsWrapperStyled>
          </CardFooter>
        </AuthCard>
      </FlexItem>
    </FlexContainer>
  );
}
