import { dataConnectorsFlagTypes } from "./actionTypes";

/* Data connectors */
export function dataConnectorsStartFetch() {
  return { type: dataConnectorsFlagTypes.DATA_CONNECTORS_START_FETCH };
}
export function dataConnectorsAddConnectorToState(payload) {
  return { type: dataConnectorsFlagTypes.DATA_CONNECTORS_ADD_CONNECTOR_TO_STATE, payload };
}
export function dataConnectorsEditConnector(payload) {
  return { type: dataConnectorsFlagTypes.DATA_CONNECTORS_EDIT_CONNECTOR, payload };
}
export function dataConnectorsRemoveConnectorFromState(payload) {
  return { type: dataConnectorsFlagTypes.DATA_CONNECTORS_REMOVE_CONNECTOR_FROM_STATE, payload };
}

/* Data upload */
export function dataConnectorsSetUploadData(payload) {
  return { type: dataConnectorsFlagTypes.DATA_CONNECTORS_SET_UPLOAD_DATA, payload };
}
export function dataConnectorsSetSuccess() {
  return { type: dataConnectorsFlagTypes.DATA_CONNECTORS_UPLOAD_SET_SUCCESS };
}
export function dataConnectorsSetError() {
  return { type: dataConnectorsFlagTypes.DATA_CONNECTORS_UPLOAD_SET_ERROR };
}
export function dataConnectorsClearUploadData() {
  return { type: dataConnectorsFlagTypes.DATA_CONNECTORS_CLEAR_UPLOAD_DATA };
}
export function dataConnectorsClearSuccess() {
  return { type: dataConnectorsFlagTypes.DATA_CONNECTORS_UPLOAD_CLEAR_SUCCESS };
}
export function dataConnectorsClearError() {
  return { type: dataConnectorsFlagTypes.DATA_CONNECTORS_UPLOAD_CLEAR_ERROR };
}
