import * as authService from "services/auth/auth";
import { push } from "connected-react-router";
import { call, put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./actions";
import { fetchAuthDataAction } from "modules/auth/operations/fetchAuthData/actions";

export function* signInByEmailWorker(action) {
  try {
    const { email, password } = action.payload;
    yield call(authService.signInByEmail, email, password);
    yield put(fetchAuthDataAction());
    yield put({ type: actionTypes.USER_SIGN_IN_SUCCESS });
    yield put(push("/dashboard")); // On Account creation redirect to Dashboard
  } catch (error) {
    yield put({ type: actionTypes.USER_SIGN_IN_ERROR, payload: { error } });
  } finally {
    yield put({ type: actionTypes.USER_SIGN_IN_FULFILL });
  }
}

export default function* signInByEmailWatcher() {
  yield takeLatest(actionTypes.USER_SIGN_IN_REQUEST, signInByEmailWorker);
}
