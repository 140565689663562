import config from "../../common/config";
import http, { ServerError } from "../http";

// Server
const host = config.serverUrl;

export async function fetchBlocks() {
  return fetchItems("blocks");
}

export async function fetchStations() {
  return fetchItems("stations");
}

export async function fetchUsers() {
  return fetchItems("users");
}

export async function fetchAccounts() {
  return fetchItems("accounts");
}

export async function fetchStationBlocks() {
  return fetchItems("station-blocks");
}

export async function fetchItems(endpointName) {
  const res = await http.get(`${host}/admin/explorer/${endpointName}`);
  const result = await res.json();
  if (res.status > 200) {
    throw new ServerError(result);
  }
  return result;
}
