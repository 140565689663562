import React, { memo } from "react";
import { Skeleton } from "@material-ui/lab";
import { styled } from "@material-ui/core";

// MUI styled is expecting a function component, so if you touch this area please return a function component !!!
// DON'T REMOVE THE CLASSNAME PROP!!!

const DefaultComponent = ({ className }) => (
  <Skeleton variant="rect" animation="wave" width="100%" className={className} />
);

const SkeletonStyled = styled(DefaultComponent)({
  borderRadius: 4,
  opacity: 0.3
});

const StateWrapper = ({
  success,
  error,
  empty,
  LoadingComponent = <SkeletonStyled />,
  ErrorComponent = LoadingComponent,
  EmptyComponent = LoadingComponent,
  children = LoadingComponent
}) => {
  if (success) {
    return children;
  }

  if (error) {
    return ErrorComponent;
  }

  if (empty) {
    return EmptyComponent;
  }

  return LoadingComponent;
};

export default memo(StateWrapper);
