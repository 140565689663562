import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const ChatIcon24 = ({ outerFill = vantiColors.gray25, innerFill = vantiColors.black1, ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <mask
        id="mask0_607_2357"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill={outerFill} />
      </mask>
      <g mask="url(#mask0_607_2357)">
        <path
          d="M6.5 13.5H13.5V12.5H6.5V13.5ZM6.5 10.5H17.5V9.5H6.5V10.5ZM6.5 7.5H17.5V6.5H6.5V7.5ZM3 20.0769V3H21V17H6.07693L3 20.0769ZM5.3846 16H20V4H4V17.3846L5.3846 16Z"
          fill={innerFill}
        />
      </g>
    </svg>
  );
};

export default memo(ChatIcon24);
