export const defaultSingleResourceOperationState = () => ({
  loading: false,
  success: false,
  error: null
});

export const defaultMultipleResourceOperationState = () => ({
  loading: {},
  success: {},
  error: {}
});
