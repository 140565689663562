import React, { memo, useCallback, useContext, useMemo } from "react";
import { styled } from "@material-ui/core";
import PinIcon24 from "assets/icons/push-pin/PinIcon24";
import { Text } from "components/ui";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { vantiColors } from "assets/jss/palette";
import useDashboardRequest from "common/hooks/use-dashboard-request";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { useDashboardUtils } from "common/hooks/use-dashboard-utils";

const Wrapper = styled("div")({
  width: 320,
  boxSizing: "border-box",
  flexDirection: "column",
  padding: 16,
  display: "flex",
  rowGap: 16,
  height: 275,
  backgroundColor: vantiColors.lightBlue10,
  maxWidth: "33%"
});

const Header = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 4
});

const Content = styled("div")({
  padding: 10.8,
  display: "flex",
  flexDirection: "column",
  rowGap: 16.9,
  backgroundColor: vantiColors.white,
  flex: 1,
  overflow: "auto"
});

const TopSection = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start"
});

const ContentBottom = styled(TopSection)({
  flexDirection: "column",
  justifyContent: "center"
});

const PinUnpinButtonText = styled(Text)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const GalleryViewCardItemContainer = ({ item = {} }) => {
  const { dashboardContext } = useContext(AppContext);
  const { currentDashboard } = dashboardContext;

  const { addDashboardItemsRequest } = useDashboardRequest();

  const { convertMetricToDashboardItem } = useDashboardUtils();

  const valueStr = useMemo(() => (!isNaN(+item.textualResponse) ? item.textualResponse : "-"), [item.textualResponse]);

  const onPinUnpin = useCallback(() => {
    addDashboardItemsRequest.mutate({
      items: [convertMetricToDashboardItem(item, currentDashboard._id)],
      dashboardId: currentDashboard._id
    });
  }, [item, currentDashboard._id]);

  return (
    <Wrapper>
      <Header>
        <TopSection>
          <Text size={16} weight={500}>
            {item.reportHeadline}
          </Text>

          <GeneralVantiButton
            buttonInfo={{
              label: (
                <PinUnpinButtonText>
                  <PinIcon24 /> Pin
                </PinUnpinButtonText>
              ),
              disabled: false,
              onClick: onPinUnpin
            }}
            size={SIZES.SMALL}
            variant={VARIANTS.SECONDARY}
          />
        </TopSection>

        <Text>Explanation text blah blah</Text>
      </Header>

      <Content>
        <TopSection>
          <Text>Operator Yield A</Text>
          <GeneralVantiButton
            buttonInfo={{
              label: "Investigate",
              disabled: false,
              onClick: () => {}
            }}
            size={SIZES.SMALL}
            variant={VARIANTS.SECONDARY}
          />
        </TopSection>

        <ContentBottom>
          <Text size={28.32} weight={600}>
            {valueStr}
          </Text>
          <Text color={vantiColors.gray39} size={10.792}>
            Last week - not set yet
          </Text>
        </ContentBottom>
      </Content>
    </Wrapper>
  );
};

export default memo(GalleryViewCardItemContainer);
