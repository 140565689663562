import React, { memo } from "react";

const MariaDBIcon34 = () => {
  return (
    <svg width="50" height="34" viewBox="0 0 50 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.4017 0.333496C47.7063 0.333496 47.8454 0.750753 46.1764 1.16801C44.5073 1.58527 42.5601 1.3071 40.752 2.14162C36.0231 4.08882 35.3278 11.7386 29.7644 14.6594C26.1481 16.7457 22.3927 17.1629 19.0547 18.2756C16.412 19.3883 14.743 20.0837 12.7958 21.7528C11.2659 23.0045 10.8486 24.3954 9.31867 25.9254C7.64964 28.1507 1.52994 26.2035 0 28.9852C0.834514 29.5416 1.25177 29.6807 2.78171 29.5416C2.50354 30.0979 0.556275 30.9325 0.973532 31.767C0.973532 31.767 5.56332 32.6015 9.45772 30.237C11.2658 29.5416 12.935 27.8726 15.8558 27.5944C19.472 27.038 23.6445 28.4289 28.0952 28.7071C27.1216 30.5152 26.2872 31.6279 25.1745 33.1578C24.8963 33.436 25.4526 33.8533 26.5653 33.5751C28.5125 33.1578 29.9034 32.6015 31.2943 31.6279C33.1024 30.3761 33.9369 29.2634 35.4668 27.4553C36.8577 29.5416 41.4475 30.0979 42.4211 28.1507C40.613 27.4553 40.1958 23.4218 40.8912 21.7528C41.5866 20.0837 42.1429 17.8584 42.6992 15.633C43.2556 13.6858 43.5337 10.765 44.2291 9.23501C44.9246 7.42689 46.4545 6.73146 47.5672 5.75785C48.6799 4.78425 49.7926 3.94974 49.7926 1.72436C49.5144 0.750755 49.0971 0.333496 48.4017 0.333496Z"
        fill="#1F305F"
      />
      <path
        d="M1.11247 31.211C4.03327 31.3501 4.72876 31.211 7.09322 30.2374C9.04042 29.4029 11.6831 27.1775 13.9084 26.343C17.2465 25.2303 20.8627 25.3694 24.4789 25.7866C25.7307 25.9257 26.8434 26.0648 27.678 26.0648C29.0688 25.2303 29.0688 23.0049 29.9033 22.8658C29.6251 27.1775 27.8169 29.9592 26.0088 32.4628C29.9032 31.7673 32.5459 29.4029 34.0759 26.343C34.4931 25.3694 35.3276 23.9785 35.7448 22.8658C36.023 23.5613 35.3276 23.9785 35.7448 24.674C38.2484 22.5877 39.5002 20.2232 40.4738 16.746C41.5865 12.7125 42.8383 9.37447 43.6728 8.12269C44.3682 7.01 45.62 6.31457 46.5936 5.48005C47.8453 4.64553 48.8188 3.81102 49.097 2.14199C47.8452 2.0029 47.5672 1.72473 47.289 1.0293C46.5936 1.44656 46.0372 1.44655 45.4808 1.44655C44.9245 1.44655 44.229 1.44655 43.5336 1.58564C37.2747 2.28107 36.8575 8.40086 32.2677 12.9907C31.9895 13.2689 31.4333 13.6861 31.1551 13.9643C29.7642 15.077 28.2342 15.6333 26.5652 16.1897C23.9226 17.1633 21.5581 17.4415 19.0545 18.276C17.2464 18.9714 15.5774 19.6668 14.1866 20.6404C13.7693 20.9186 13.3521 21.3359 13.074 21.6141C12.2395 22.3095 11.6831 23.144 11.1267 23.8394C10.5704 24.6739 10.014 25.5085 9.1795 26.343C7.78865 27.5948 2.7815 26.7602 1.11247 28.012C0.973382 28.1511 0.695312 28.2902 0.695312 28.5684C1.66891 28.9856 2.36434 28.7075 3.47703 28.8465C3.19885 30.0983 0.834296 30.6547 1.11247 31.211Z"
        fill="#C0765A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.1699 4.50652C42.0044 5.34104 43.9517 4.64561 43.6735 3.11566C42.2827 2.97657 41.4481 3.39383 41.1699 4.50652Z"
        fill="#1F305F"
      />
      <path
        d="M47.4282 2.69791C47.1501 3.25426 46.7328 3.81061 46.7328 5.06238C46.7328 5.34055 46.5938 5.47964 46.5938 5.06238C46.5938 3.81061 46.8719 3.25426 47.4282 2.69791C47.4282 2.41974 47.4282 2.28066 47.4282 2.69791Z"
        fill="#1F305F"
      />
      <path
        d="M47.1502 2.41988C46.8721 2.83714 46.1766 3.81074 46.0375 5.06252C46.0375 5.34069 45.8984 5.47977 45.8984 5.06252C46.0375 3.81074 46.5939 3.11531 47.1502 2.41988C47.2893 2.28079 47.1502 2.14171 47.1502 2.41988Z"
        fill="#1F305F"
      />
      <path
        d="M47.0106 2.14107C46.7325 2.55833 45.6198 3.67102 45.4807 4.9228C45.4807 5.20097 45.2026 5.20097 45.3417 4.9228C45.6199 3.67102 46.4543 2.69742 47.0106 2.14107C47.0106 2.00199 47.0106 1.8629 47.0106 2.14107Z"
        fill="#1F305F"
      />
      <path
        d="M46.7327 1.8632C46.3154 2.28046 45.0636 3.53223 44.7854 4.78401C44.7854 4.92309 44.5073 5.06218 44.6464 4.78401C45.0636 3.67132 46.1763 2.28046 46.7327 1.72412C46.8717 1.58503 46.8717 1.72411 46.7327 1.8632Z"
        fill="#1F305F"
      />
    </svg>
  );
};

export default memo(MariaDBIcon34);
