import React, { memo } from "react";
import cs from "classnames";
import { TableCell } from "@material-ui/core";
import useCellResponsivenessStyles from "../../cell-responsiveness.styles";
import useStyles from "./styles";

const BodyCell = ({ children, className, id, ...rest }) => {
  const classes = useStyles();
  const cellClasses = useCellResponsivenessStyles();

  return (
    <TableCell classes={{ root: classes.root }} className={cs(className, { [cellClasses[id]]: !!id })} {...rest}>
      {children}
    </TableCell>
  );
};

export default memo(BodyCell);
