import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import FlexContainer from "components/ui/Flex/FlexContainer";
import FlexItem from "components/ui/Flex/FlexItem";
import Text from "components/ui/Typography/Text";
import BrandedTitle from "components/ui/Typography/BrandedTitle";
import Button from "components/ui/Buttons/Button";
import * as explorerService from "services/admin/explorer";
import { db } from "../../models/db";
import { ReactComponent as BackButtonIcon } from "assets/img/back-button.svg";
import Table from "./explorer/table";
import { useAsync } from "react-use";
import _ from "lodash";
import { logger } from "utils/logger";

export default function Explorer() {
  const [fetchError, setFetchError] = useState(null);
  const history = useHistory();
  useAsync(async () => {
    try {
      const creatorName = new URLSearchParams(window.location.search).get("creatorName");
      const creatorId = new URLSearchParams(window.location.search).get("creatorId");
      const creatorEmail = new URLSearchParams(window.location.search).get("creatorEmail");
      const creatorLicense = new URLSearchParams(window.location.search).get("creatorLicense");
      const title = new URLSearchParams(window.location.search).get("title");
      const modelId = new URLSearchParams(window.location.search).get("modelId");
      const modelState = new URLSearchParams(window.location.search).get("modelState");
      const stationId = new URLSearchParams(window.location.search).get("stationId");
      const stationType = new URLSearchParams(window.location.search).get("stationType");
      const stationName = new URLSearchParams(window.location.search).get("stationName");
      const stationState = new URLSearchParams(window.location.search).get("stationState");
      const accountName = new URLSearchParams(window.location.search).get("accountName");
      const accountId = new URLSearchParams(window.location.search).get("accountId");

      const blocksQueryConditions = {
        creatorName,
        creatorId,
        creatorEmail,
        creatorLicense,
        title,
        modelId,
        modelState,
        stationId,
        stationType,
        stationName,
        stationState,
        accountName,
        accountId
      };

      const queryParameters = [];

      for (const condition in blocksQueryConditions) {
        if (blocksQueryConditions[condition]) {
          const parameter = `${condition}=${blocksQueryConditions[condition]}`;
          queryParameters.push(parameter);
        }
      }
      const stationBlocks = await explorerService.fetchStationBlocks();
      const stationIdsByBlockId = _.chain(stationBlocks)
        .keyBy("blockId")
        .mapValues("stationId")
        .value();
      await Promise.all([
        db.blocks
          .clear()
          .then(explorerService.fetchBlocks)
          .then(blocks =>
            db.blocks.bulkPut(
              blocks.map(block => {
                return {
                  ...block,
                  stationId: stationIdsByBlockId[block.id]
                };
              })
            )
          ),
        db.stations
          .clear()
          .then(explorerService.fetchStations)
          .then(db.stations.bulkPut.bind(db.stations)),
        db.users
          .clear()
          .then(explorerService.fetchUsers)
          .then(db.users.bulkPut.bind(db.users)),
        db.accounts
          .clear()
          .then(explorerService.fetchAccounts)
          .then(db.accounts.bulkPut.bind(db.accounts))
      ]);
    } catch (error) {
      logger.error(error);
      setFetchError(error);
    }
  }, []);

  return (
    <FlexContainer direction="row">
      <FlexItem xs={12} sm={10} md={8} container justifyContent="flex-start">
        <div style={{ display: "flex" }}>
          <Button transparent={true} style={{ padding: "0 10px 0 0" }} onClick={() => history.push("/admin")}>
            <BackButtonIcon />
          </Button>
          <BrandedTitle text="Model Master" size="30px" />
        </div>
      </FlexItem>
      {fetchError && (
        <FlexContainer justifyContent="center" alignItems="center">
          <FlexItem xs={12} sm={8} md={8} container direction="column">
            <Text color="danger">{fetchError.message}</Text>
          </FlexItem>
        </FlexContainer>
      )}
      <div style={{ height: 400, width: "100%" }}>
        <Table />
      </div>
    </FlexContainer>
  );
}
