import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles(theme => ({
  progressBarContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    "& > div": {
      width: 25,
      height: 25,
      "& > svg": {
        color: theme.palette.vantiColors.blue4
      }
    }
  },
  uploadText: {
    marginLeft: theme.spacing(1.25),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    "& > div": {
      width: 25,
      height: 25,
      "& > svg": {
        color: theme.palette.vantiColors.blue4
      }
    }
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  percentageDoneText: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  dropZoneContent: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    position: "relative"
  },
  checkMarkIcon: {
    position: "absolute",
    marginTop: theme.spacing(0.25),
    marginLeft: 1,
    color: theme.palette.vantiColors.blue4
  },
  dropAreaTextContainer: {
    width: "74%",
    height: "93px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fileSuccessfullyAddedSection: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    animation: "$transitionAnimation 1.5s"
  },
  addedSuccessfullyCheckMarkSection: {
    height: "fit-content",
    width: "fit-content",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      color: theme.palette.vantiColors.blue4
    }
  },
  "@keyframes transitionAnimation": {
    "0%": {
      opacity: 0.7
    },
    "100%": {
      opacity: 1
    }
  },
  iconWrapper: {
    display: "flex",
    width: "fit-content",
    height: "fit-content",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(0.25)
  },
  errorContainer: {
    marginTop: theme.spacing(3.5),
    minHeight: "50px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    boxSizing: "border-box",
    textAlign: "left"
  },
  errorIconWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "fit-content",
    marginRight: theme.spacing(1),
    "& > svg": {
      marginTop: "1px",
      height: "15px",
      width: "17px"
    }
  },
  errorMessage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%"
  }
}));
