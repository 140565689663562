import React, { memo } from "react";
import { Divider, styled } from "@material-ui/core";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import { constants } from "utils/constants";
import DatasetDateRange from "pages/side-bar/data-page/components/DatasetDateRange";

const ChatMessageReportDataPreviewTableSummaryContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const ChatMessageReportDataPreviewTableSummaryStatsWrapper = styled("div")({
  display: "flex",
  gap: 12
});

const StyledDivider = styled(Divider)({
  "&.MuiDivider-root": {
    border: `1px solid ${vantiColors.lightGray13}`
  }
});

const ChatMessageReportDataPreviewTableSummary = ({ datasetId, numOfRecords, numOfColumns }) => {
  return (
    <ChatMessageReportDataPreviewTableSummaryContainer>
      <DatasetDateRange datasetId={datasetId} readOnly={true} />
      <ChatMessageReportDataPreviewTableSummaryStatsWrapper>
        <Text color={vantiColors.gray41} lineSize={"17px"}>
          {constants.datasets.NUM_OF_RECORDS_TEXT + " "}
          <span style={{ fontWeight: 600 }}>{numOfRecords}</span>
        </Text>
        <StyledDivider orientation={"horizontal"} flexItem />
        <Text color={vantiColors.gray41} lineSize={"17px"}>
          {constants.datasets.NUM_OF_COLUMNS_TEXT + " "}
          <span style={{ fontWeight: 600 }}>{numOfColumns}</span>
        </Text>
      </ChatMessageReportDataPreviewTableSummaryStatsWrapper>
    </ChatMessageReportDataPreviewTableSummaryContainer>
  );
};

export default memo(ChatMessageReportDataPreviewTableSummary);
