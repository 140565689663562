import { logger } from "utils/logger";
import { useQuery } from "react-query";
import { getAccountUserNames } from "services/auth/auth";
import { useContext } from "react";
import { AppContext } from "./context-hooks/use-app-context";

export const FETCH_ACCOUNT_USER_NAMES_KEY = "fetch.account.user.names";

const useAccountUserNamesRequest = () => {
  const { usersContext } = useContext(AppContext);
  const { accountUserNames, setAccountUserNames } = usersContext;

  useQuery([FETCH_ACCOUNT_USER_NAMES_KEY], async () => getAccountUserNames(), {
    skip: accountUserNames.length,
    manual: false,
    enabled: true,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    onSuccess: ({ data }) => {
      setAccountUserNames(prevState => [...prevState, ...data]);
    },
    onError: error => {
      logger.error("Failed to fetch account user names", error);
    }
  });
};

export default useAccountUserNamesRequest;
