import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

// TODO: Divide into functions.js/constants.js/hooks.js
export const FILE_ERRORS = {
  NOT_SUPPORTED: "File type not supported",
  FAILED_PARSING: "Failed parsing csv file.",
  EMPTY_FILE: "Empty file is not supported.",
  FILENAME_SPACE: "The file name includes spaces, please remove all spaces and try again.",
  ZIP_WITHOUT_CSV: "Only a CSV file is supported inside a ZIP file.",
  ZIP_WITHOUT_FOLDERS:
    "Images are not organized into separate folders. If you have a model target, please upload a zip file containing a folder with images for each target variable.",
  ZIP_WITH_FOLDERS:
    "Images are organized into separate folders. If you don’t have a model target, please upload a zip file with a single folder containing images.",
  ZIP_NUM_OF_FOLDERS: "Images must be organized into at least two separate folders.",
  ZIP_SUPERVISED_STRUCTURE:
    "To create a valid zip file, please compile the relevant folders that include the images into a designated folder and then compress the folder.",
  ZIP_UNSUPERVISED_STRUCTURE:
    "To create a valid zip file, please choose all relevant images and compress them into a zip folder.",
  ZIP_EMPTY_FOLDER: "The file uploaded contains empty folder/s, please remove the empty folder/s or add images."
};

export const MODEL_TYPES = {
  CLASSIFICATION: "classification",
  CLUSTERING: "clustering",
  REGRESSION: "regression",
  ANOMALY: "anomaly",
  CUSTOM: "custom"
};

export const MODEL_INTENTS = {
  [MODEL_TYPES.CLASSIFICATION]: "BUILD_A_NEW_MODEL",
  [MODEL_TYPES.CLUSTERING]: "BUILD_AN_UNSUPERVISED_MODEL",
  [MODEL_TYPES.ANOMALY]: "BUILD_AN_UNSUPERVISED_MODEL",
  [MODEL_TYPES.REGRESSION]: "BUILD_A_REGRESSION_MODEL",
  [MODEL_TYPES.CUSTOM]: "BUILD_A_CUSTOM_MODEL"
};

export const STATION_TYPES = {
  SUPERVISED: "SUPERVISED",
  UNSUPERVISED: "UNSUPERVISED",
  REGRESSION: "REGRESSION",
  CUSTOM: "CUSTOM"
};

export const ONBOARDING_FILE_TYPES = {
  CSV: "CSV",
  IMAGES: "IMAGES"
};

export const ONBOARDING_KEYWORDS = {
  FORMATTED: "formatted",
  UNFORMATTED: "unformatted",
  CUSTOM: "custom",

  LABELLED: "labelled",
  UNLABELLED: "unlabelled",

  CLASSIFICATION: "classification",
  REGRESSION: "regression"
};

export const CONFIGURATION_TOOLS = {
  MODEL_AUGMENTATION: "Model Augmentation",
  DATA_REFINEMENT: "Data Refinement",
  SENSITIVITY_CARDS: "Sensitivity Cards",
  CUSTOM_CLUSTERS: "Custom Clusters"
};

export const CONFIGURATION_SETTING_NAMES = {
  "Tabular Labelled Classification": [CONFIGURATION_TOOLS.MODEL_AUGMENTATION, CONFIGURATION_TOOLS.DATA_REFINEMENT],
  "Tabular Labelled Regression": [CONFIGURATION_TOOLS.SENSITIVITY_CARDS, CONFIGURATION_TOOLS.DATA_REFINEMENT],
  "Tabular Unlabelled": [CONFIGURATION_TOOLS.CUSTOM_CLUSTERS, CONFIGURATION_TOOLS.DATA_REFINEMENT],
  "Image Labelled": [CONFIGURATION_TOOLS.DATA_REFINEMENT],
  "Image Unlabelled": [CONFIGURATION_TOOLS.CUSTOM_CLUSTERS, CONFIGURATION_TOOLS.DATA_REFINEMENT]
};

const KEYWORD_CAPTIONS = {
  FORMATTED: "Tabular",
  UNFORMATTED: "Image",
  CUSTOM: "Custom",

  LABELLED: "Labelled",
  UNLABELLED: "Unlabelled",

  CLASSIFICATION: "Classification",
  REGRESSION: "Regression"
};

export const KPI_LABEL_VALUES = {
  PASS: "PASS",
  FAIL: "FAIL"
};

export const validateKpiLabels = kpiLabels => {
  let failLabelsPresent = false;
  let passLabelsPresent = false;
  let kpiLabelsValid = true;

  let i = 0;

  while ((!failLabelsPresent || !passLabelsPresent || kpiLabelsValid) && kpiLabels.length > i) {
    const kpiItem = kpiLabels[i];

    if (kpiItem.value) {
      if (kpiItem.value === KPI_LABEL_VALUES.PASS) {
        passLabelsPresent = true;
      } else if (kpiItem.value === KPI_LABEL_VALUES.FAIL) {
        failLabelsPresent = true;
      }
    } else {
      kpiLabelsValid = false;
    }

    i++;
  }

  return {
    failLabelsPresent,
    passLabelsPresent,
    kpiLabelsValid
  };
};

export const useModelType = defaultLocation => {
  const currentLocation = useLocation();

  const location = useMemo(() => (defaultLocation ? defaultLocation : currentLocation), [currentLocation]);

  const [modelType, setModelType] = useState("Unknown");

  useEffect(() => {
    const destructurizedLocation = location.pathname.split("/");
    const newModelType = Object.keys(ONBOARDING_KEYWORDS)
      .filter(keywordKey => destructurizedLocation.includes(ONBOARDING_KEYWORDS[keywordKey]))
      .map(keywordKey => KEYWORD_CAPTIONS[keywordKey])
      .join(" ");

    setModelType(newModelType);
  }, [location]);

  return modelType;
};

export const useSummary = defaultLocation => {
  const currentLocation = useLocation();

  const location = useMemo(() => (defaultLocation.pathname ? defaultLocation : currentLocation), [
    currentLocation,
    defaultLocation
  ]);

  return useMemo(() => {
    const destructurizedLocation = location.pathname.split("/");
    const newSummary = Object.keys(ONBOARDING_KEYWORDS)
      .filter(keywordKey => destructurizedLocation.includes(ONBOARDING_KEYWORDS[keywordKey]))
      .map(keywordKey => KEYWORD_CAPTIONS[keywordKey]);

    return {
      modelType: newSummary.join(" "),
      fileType: newSummary[0],
      configurationTools: CONFIGURATION_SETTING_NAMES[newSummary.join(" ").replace(/\s$/, "")] ?? []
    };
  }, [location]);
};
