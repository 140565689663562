import React, { memo } from "react";
import PlusIcon16 from "../../../assets/icons/plus-icon/PlusIcon16";
import { vantiColors } from "../../../assets/jss/palette";
import { Text } from "../index";
import { styled } from "@material-ui/core";

export const GeneralAddButton = ({ textLabel }) => {
  return (
    <ButtonLabelWrapper>
      <PlusIcon16 />
      <Text weight={500} size={16} lineSize={"19.36px"} color={vantiColors.darkBlue7}>
        {textLabel}
      </Text>
    </ButtonLabelWrapper>
  );
};

const ButtonLabelWrapper = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 8
});

export default memo(GeneralAddButton);
