import React, { memo } from "react";

import { TableRow } from "@material-ui/core";
import HeadCell from "./head-cell/HeadCell";

import useStyles from "./styles";

const TableHeadRows = ({ headerGroups }) => {
  const classes = useStyles();

  return headerGroups.map(headerGroup => {
    const { key, ...rest } = headerGroup.getHeaderGroupProps();

    return (
      <TableRow key={key} classes={{ root: classes.root }} {...rest}>
        {headerGroup.headers.map(column => {
          const { key, ...rest } = column.getHeaderProps(column.getSortByToggleProps());

          return (
            <HeadCell
              id={column.id}
              key={key}
              label={column.render("Header")}
              hint={column?.hint}
              isSortedDesc={column.isSortedDesc}
              {...rest}
            />
          );
        })}
      </TableRow>
    );
  });
};

export default memo(TableHeadRows);
