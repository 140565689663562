import React, { memo, useMemo } from "react";
import { Text } from "components/ui";
import { styled } from "@material-ui/core";
import CopyToClipboardIcon24 from "assets/icons/copy-to-clipboard/CopyToClipboardIcon24";

const ChatMessageMetadataSection = ({ title, content, copyToClipboardCallback }) => {
  const formattedContent = useMemo(() => {
    try {
      return typeof content === "object" ? JSON.stringify(content, null, 2) : content;
    } catch (err) {
      return "Failed to parse content. Is it not a JSON? -_-";
    }
  }, [content]);

  return (
    <ChatMessageMetadataSectionWrapper>
      <ChatMessageMetadataSectionHeader>
        <Text weight={600}>{title}</Text>
        <CopyToClipboardIcon24Styled
          onClick={async () => {
            copyToClipboardCallback(formattedContent, title);
          }}
        />
      </ChatMessageMetadataSectionHeader>
      <JsonResponseArea disabled value={formattedContent}></JsonResponseArea>
    </ChatMessageMetadataSectionWrapper>
  );
};

const ChatMessageMetadataSectionWrapper = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  gap: 8,
  flexDirection: "column",
  position: "relative",
  overflowY: "auto"
});

const CopyToClipboardIcon24Styled = styled(CopyToClipboardIcon24)({
  cursor: "pointer"
});

const JsonResponseArea = styled("textarea")({
  width: "100%",
  height: "100%",
  resize: "none",
  overflow: "auto",
  boxSizing: "border-box",
  display: "flex"
});

const ChatMessageMetadataSectionHeader = styled("div")({
  display: "flex",
  alignItems: "center"
});

export default memo(ChatMessageMetadataSection);
