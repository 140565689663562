import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const CalendarIcon24 = ({ stroke = vantiColors.gray29, ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M5.5 9.33333V19.9C5.5 19.9552 5.54477 20 5.6 20H18.4C18.4552 20 18.5 19.9552 18.5 19.9V9.33333M5.5 9.33333V6.32222C5.5 6.26699 5.54477 6.22222 5.6 6.22222H8.53333M5.5 9.33333H18.5M18.5 9.33333V6.32222C18.5 6.26699 18.4552 6.22222 18.4 6.22222H15.4667M15.4667 6.22222V4M15.4667 6.22222H8.53333M8.53333 6.22222V4"
        stroke={stroke}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default memo(CalendarIcon24);
