import React, { memo } from "react";
import { vantiColors } from "../../jss/palette";

const AutoAwesomeIcon24 = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="auto_awesome">
        <mask
          id="mask0_2225_218"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="28"
          height="28"
        >
          <rect id="Bounding box" width="28" height="28" fill={vantiColors.gray25} />
        </mask>
        <g mask="url(#mask0_2225_218)">
          <path
            id="auto_awesome_2"
            d="M20.0003 9.55765L18.9907 7.2916L16.7246 6.282L18.9907 5.27237L20.0003 3.00635L21.0099 5.27237L23.2759 6.282L21.0099 7.2916L20.0003 9.55765ZM20.0003 24.9935L18.9907 22.7275L16.7246 21.7179L18.9907 20.7083L20.0003 18.4422L21.0099 20.7083L23.2759 21.7179L21.0099 22.7275L20.0003 24.9935ZM9.23102 20.5064L7.21178 16.0192L2.72461 13.9999L7.21178 11.9807L9.23102 7.49349L11.2503 11.9807L15.7375 13.9999L11.2503 16.0192L9.23102 20.5064ZM9.23102 17.6749L10.3977 15.1666L12.906 13.9999L10.3977 12.8333L9.23102 10.3249L8.06436 12.8333L5.55602 13.9999L8.06436 15.1666L9.23102 17.6749Z"
            fill="#535D70"
          />
        </g>
      </g>
    </svg>
  );
};

export default memo(AutoAwesomeIcon24);
