import validator from "validator";
import { EmailValidationError } from "@vanti-analytics-org/vanti-common";
import { constants } from "./constants";

const emailValidationOptions = {
  all_lowercase: true,
  gmail_remove_dots: true,
  gmail_remove_subaddress: true,
  outlookdotcom_remove_subaddress: true,
  yahoo_remove_subaddress: true,
  icloud_remove_subaddress: true,
  gmail_convert_googlemaildotcom: true
};

export default function normalizeEmail(email) {
  return validator.normalizeEmail(email, emailValidationOptions);
}

export const toErrEmail = err => {
  switch (err) {
    case EmailValidationError.NON_BUSINESS:
      return constants.email.ERR_BUSINESS_EMAIL_REQUIRED;
    case EmailValidationError.INVALID:
    default:
      return constants.email.ERR_PROVIDE_VALID_EMAIL;
  }
};
