import { call, put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./actions";
import * as authService from "services/auth/auth";

export function* resendInviteWorker(action) {
  try {
    const result = yield call(authService.resendInvite, action.payload);
    yield put({ type: actionTypes.ACCOUNT_RESEND_INVITE_SUCCESS, payload: result.data.email });
  } catch (error) {
    yield put({ type: actionTypes.ACCOUNT_RESEND_INVITE_ERROR, payload: error });
  } finally {
    yield put({ type: actionTypes.ACCOUNT_RESEND_INVITE_FULFILL });
  }
}

export default function* resendInviteWatcher() {
  yield takeLatest(actionTypes.ACCOUNT_RESEND_INVITE_REQUEST, resendInviteWorker);
}
