import { Route, Redirect } from "react-router-dom";
import React, { memo, useContext } from "react";
import * as PropTypes from "prop-types";
import { styled } from "@material-ui/core";

import SideBar from "components/layouts/sidebar/SideBar";
import VantiStickyWarningBanner from "components/ui/Banner/VantiStickyWarningBanner/VantiStickyWarningBanner";
import { ApplicationContent, MainLayout, RootLayout } from "components/Router/AuthenticatedRouteStyled";
import { AppNotificationsContext } from "../../common/hooks/context-hooks/use-notifications-context";
import { WARNING_BANNER_HEIGHT } from "components/ui/Banner/VantiStickyWarningBanner/style";
import Topbar from "pages/dashboard/Topbar";
import { topBarHeight } from "assets/jss/vanti-dashboard";

const DashboardContentContainer = styled("div")(({ theme }) => ({
  height: `calc(100% - ${topBarHeight}px)`,
  width: "100%",
  display: "flex",
  flexDirection: "column"
}));

const AuthenticatedRoute = props => {
  const { component: Component, redirectCondition = false, redirectTo, ...rest } = props;
  const { isWarningBannerDisplayed } = useContext(AppNotificationsContext);
  return (
    <Route
      {...rest}
      render={_props =>
        redirectCondition ? (
          <Redirect to={redirectTo} />
        ) : (
          <RootLayout data-testid={"root-layout"}>
            <VantiStickyWarningBanner />
            <MainLayout heightOffset={isWarningBannerDisplayed ? WARNING_BANNER_HEIGHT : 0}>
              <SideBar />
              <ApplicationContent>
                <Topbar {...rest} />
                <DashboardContentContainer>
                  <Component {...(props.data || {})} />
                </DashboardContentContainer>
              </ApplicationContent>
            </MainLayout>
          </RootLayout>
        )
      }
    />
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.any,
  redirectCondition: PropTypes.bool,
  redirectTo: PropTypes.string
};

export default memo(AuthenticatedRoute);
