import React, { memo, useCallback, useState } from "react";
import { styled } from "@material-ui/core";
import { EntityRow, palette } from "../common";
import { Text } from "components/ui";
import Popup from "./Popup";
import { vantiColors } from "assets/jss/palette";

const ChildEntitiesWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 16,
  zIndex: 1
});

const EntityNamesDropdown = styled("div")({
  backgroundColor: vantiColors.white,
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.15)"
});

const EntityNameItem = styled(Text)({
  width: 142,
  height: 56,
  display: "flex",
  alignItems: "center",
  padding: "0px 12px",
  cursor: "pointer",
  boxSizing: "border-box",
  "&:hover": {
    backgroundColor: vantiColors.gray18
  }
});

const subEntities = ["Sub 1", "Sub 2", "Sub 3"];

const getInitSelectedSubEntityValue = () => ({
  ref: null,
  fatherEntityId: null
});

const Body = ({ childEntities = [], onAddSubEntity }) => {
  const [selectedSubEntity, setSelectedSubEntity] = useState(getInitSelectedSubEntityValue(0));

  const onClickOpenPopup = useCallback(entityInfo => {
    setSelectedSubEntity(entityInfo);
  }, []);

  const onClosePopup = useCallback(() => {
    setSelectedSubEntity(getInitSelectedSubEntityValue());
  }, []);

  const onClickEntityName = useCallback(
    subEntity => {
      onAddSubEntity(selectedSubEntity.fatherEntityId, subEntity);
    },
    [selectedSubEntity.fatherEntityId]
  );

  return (
    childEntities.length > 0 && (
      <ChildEntitiesWrapper>
        {selectedSubEntity?.ref && (
          <Popup ref={selectedSubEntity?.ref} onClose={onClosePopup}>
            <EntityNamesDropdown>
              {subEntities.map(subEntity => (
                <EntityNameItem
                  key={subEntity}
                  onClick={() => {
                    onClickEntityName(subEntity);
                  }}
                >
                  {subEntity}
                </EntityNameItem>
              ))}
            </EntityNamesDropdown>
          </Popup>
        )}

        {childEntities.map((childEntity, index) => (
          <EntityRow
            key={childEntity.name}
            childEntity={childEntity}
            color={palette[index % 10]}
            onClickOpenPopup={onClickOpenPopup}
            isAllowSubEntitySelection
          />
        ))}
      </ChildEntitiesWrapper>
    )
  );
};

export default memo(Body);
