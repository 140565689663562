import Papa from "papaparse";

import { CsvDatasetMetadataParser } from "@vanti-analytics-org/vanti-common";

export const countCsvRows = (file, callback) => {
  // without headers
  let rowCount = -1;

  Papa.parse(file, {
    error: () => {
      return false;
    },
    skipEmptyLines: true,
    complete: () => {
      callback(null, { rowCount });
    },
    step: () => {
      rowCount++;
    }
  });
};

export const readCsv = async (file, callback, updateProgress) => {
  try {
    const result = await CsvDatasetMetadataParser.parse(file, updateProgress);

    callback(null, {
      headers: result.metadata.headers,
      data: result.metadata.previewRows.slice(1),
      uniqueDataset: result.metadata.valuesDictionary,
      preflight: result.preflight
    });
  } catch (err) {
    callback(err.message);
  }
};

export const countHeaders = async (file, callback) => {
  Papa.parse(file, {
    error: () => {
      return false;
    },
    skipEmptyLines: false,
    step: (row, parser) => {
      callback(row.data.length);
      parser.abort();
    }
  });
};
