import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import GeneralVantiButton, { SIZES } from "components/ui/Buttons/GeneralVantiButton";
import StorageFileBrowser from "pages/dashboard/onboarding/sections/file-explorer-section/storage-file-explorer/StorageFileExplorer";
import { useQuery } from "react-query";
import { startDatasetJob, watchRemoteFolder } from "services/api/dataConnectors";
import { ADD_BUTTON_TEXT, ALLOWED_FILE_TYPES } from "../constants";
import { AddConnectorDatasetModalButtonStyled } from "./common-styled-components";
import { Text } from "components/ui";
import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import { WATCH_BUTTON_TEXT } from "../constants";
import { AppContext } from "common/hooks/context-hooks/use-app-context";

const FILE_NOT_SUPPORTED_ERROR_TEXT = `File type is not supported. Please select one of the following types: ${ALLOWED_FILE_TYPES.join(
  ", "
)}.`;

const FOLDER_ALREADY_WATCHED_ERROR_TEXT = `This folder is already being watched. Please, select another one or delete the current one.`;

const FooterStyled = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const CloudStorageFilesBrowser = ({ connectorId, onFinish }) => {
  const [fileToUpload, setFileToUpload] = useState(null);
  const [error, setError] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);

  const { datasetsContext } = useContext(AppContext);

  const addConnectorFileReq = useQuery(
    [connectorId, fileToUpload],
    async () => startDatasetJob(connectorId, fileToUpload),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false
    }
  );

  const watchRemoteFolderReq = useQuery(
    [connectorId, selectedFolder],
    async () => watchRemoteFolder(connectorId, selectedFolder),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false
    }
  );

  const onFolderSelected = newFolder => {
    const noTrailingSlashFolder = newFolder.replace(/\/$/, "");
    const datasets = Object.values(datasetsContext.datasets);

    if (
      datasets.find(
        dataset => dataset.sourceDataConnectorId == connectorId && dataset.watchedRemoteFolder === noTrailingSlashFolder
      )
    ) {
      setSelectedFolder(null);
      setError(FOLDER_ALREADY_WATCHED_ERROR_TEXT);
      return;
    }

    setSelectedFolder(noTrailingSlashFolder);
    setFileToUpload(null);
    setError(null);
  };

  const onAddFile = useCallback(async () => {
    if (selectedFolder) {
      watchRemoteFolderReq.refetch();
    } else {
      addConnectorFileReq.refetch();
    }
  }, [addConnectorFileReq, watchRemoteFolderReq]);

  const onSelectFile = useCallback(fileName => {
    if (!fileName) return;

    const fileType = fileName
      .split("/")
      .pop()
      .split(".")
      .pop();

    if (ALLOWED_FILE_TYPES?.includes(fileType)) {
      setFileToUpload(fileName);
      setSelectedFolder(null);
      setError(null);
      return;
    }

    setFileToUpload(null);
    setError(FILE_NOT_SUPPORTED_ERROR_TEXT);
  }, []);

  const isAddButtonDisabled = useMemo(
    () => (!fileToUpload && !selectedFolder) || addConnectorFileReq.isFetching || watchRemoteFolderReq.isFetching,
    [fileToUpload, addConnectorFileReq.isFetching, selectedFolder, watchRemoteFolderReq.isFetching]
  );

  useEffect(() => {
    if (addConnectorFileReq.data) {
      const { data } = addConnectorFileReq;
      onFinish(data);
    }
  }, [addConnectorFileReq.data, onFinish]);

  useEffect(() => {
    if (watchRemoteFolderReq.data) {
      const { data } = watchRemoteFolderReq;
      onFinish(data);
    }
  }, [watchRemoteFolderReq.data, onFinish]);

  const confirmButtonLabel = useMemo(() => {
    if (fileToUpload || !selectedFolder) {
      return ADD_BUTTON_TEXT;
    } else if (selectedFolder) {
      return WATCH_BUTTON_TEXT;
    }
  }, [fileToUpload, selectedFolder]);

  return (
    <>
      <StorageFileBrowser
        key={connectorId}
        onFileSelect={file => {
          onSelectFile(file);
        }}
        onFolderSelect={onFolderSelected}
        onFileUpload={() => {}}
        connectorId={connectorId}
      />

      <FooterStyled>
        <Text color={vantiColors.rose6}>{error}</Text>

        <AddConnectorDatasetModalButtonStyled>
          <GeneralVantiButton
            buttonInfo={{ disabled: isAddButtonDisabled, onClick: onAddFile, label: confirmButtonLabel }}
            size={SIZES.BIG}
          />
        </AddConnectorDatasetModalButtonStyled>
      </FooterStyled>
    </>
  );
};
export default memo(CloudStorageFilesBrowser);
