import { actionTypes } from "./actions";
import { defaultSingleResourceOperationState } from "utils/modules-utils";

const defaultState = defaultSingleResourceOperationState();

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_WEB_NOTIFICATION_LIST_REQUEST: {
      return { ...defaultState, loading: true };
    }
    case actionTypes.UPDATE_WEB_NOTIFICATION_LIST_SUCCESS: {
      return { ...state, success: true };
    }
    case actionTypes.UPDATE_WEB_NOTIFICATION_LIST_ERROR: {
      return { ...state, success: false, error: action.payload.error };
    }
    case actionTypes.UPDATE_WEB_NOTIFICATION_LIST_FULFILL: {
      return { ...state, loading: false };
    }
    default: {
      return state;
    }
  }
}
