export const INDUSTRIES = {
  AVIATION_AND_AEROSPACE: { value: "Aviation & aerospace", displayName: "Aviation & aerospace" },
  FOOD_AND_BEVERAGE_PRODUCTION: { value: "Food & beverage production", displayName: "Food & beverage production" },
  AUTOMOTIVE: { value: "Automotive", displayName: "Automotive" },
  MEDICAL_DEVICES: { value: "Medical devices", displayName: "Medical devices" },
  ELECTRICAL_AND_ELECTRONICS_MANUFACTURING: {
    value: "Electrical & electronics manufacturing",
    displayName: "Electrical & electronics manufacturing"
  },
  MECHANICAL_OR_INDUSTRIAL_ENGINEERING: {
    value: "Mechanical or industrial engineering",
    displayName: "Mechanical or industrial engineering"
  },
  INDUSTRIAL_AUTOMATION: { value: "Industrial automation", displayName: "Industrial automation" },
  CONSUMER_ELECTRONICS: { value: "Consumer electronics", displayName: "Consumer electronics" },
  TELECOMMUNICATIONS: { value: "Telecommunications", displayName: "Telecommunications" },
  HEALTHCARE: { value: "Healthcare", displayName: "Healthcare" },
  SEMICONDUCTORS: { value: "Semiconductors", displayName: "Semiconductors" },
  COMPUTER_HARDWARE: { value: "Computer hardware", displayName: "Computer hardware" },

  MECHANICAL_ENGINEERING: { value: "Mechanical engineering", displayName: "Mechanical engineering" },
  ELECTRICAL_AND_ELECTRONICS: {
    value: "Electrical & electronics",
    displayName: "Electrical & electronics"
  },

  OTHER: { value: "Other", displayName: "+ Other" }
};

export const industries = [
  [INDUSTRIES.AVIATION_AND_AEROSPACE, INDUSTRIES.FOOD_AND_BEVERAGE_PRODUCTION, INDUSTRIES.AUTOMOTIVE],
  [INDUSTRIES.MEDICAL_DEVICES, INDUSTRIES.ELECTRICAL_AND_ELECTRONICS_MANUFACTURING],
  [INDUSTRIES.MECHANICAL_OR_INDUSTRIAL_ENGINEERING, INDUSTRIES.INDUSTRIAL_AUTOMATION],
  [INDUSTRIES.CONSUMER_ELECTRONICS, INDUSTRIES.TELECOMMUNICATIONS, INDUSTRIES.HEALTHCARE],
  [INDUSTRIES.COMPUTER_HARDWARE, INDUSTRIES.SEMICONDUCTORS, INDUSTRIES.OTHER]
];

export const industriesForMobile = [
  [INDUSTRIES.HEALTHCARE, INDUSTRIES.FOOD_AND_BEVERAGE_PRODUCTION],
  [INDUSTRIES.MECHANICAL_ENGINEERING, INDUSTRIES.MEDICAL_DEVICES],
  [INDUSTRIES.CONSUMER_ELECTRONICS, INDUSTRIES.COMPUTER_HARDWARE],
  [INDUSTRIES.TELECOMMUNICATIONS, INDUSTRIES.ELECTRICAL_AND_ELECTRONICS],
  [INDUSTRIES.AUTOMOTIVE, INDUSTRIES.SEMICONDUCTORS, INDUSTRIES.OTHER]
];

export const roles = ["Executive", "Director", "Analyst", "Data scientist", "Developer", "Other"];

export const KEY_CODES = {
  TAB: 9,
  ENTER: 13
};

export const MAIL_LINKS = {
  GMAIL: "https://mail.google.com/mail/?view=cm&fs=1&to=sale@vanti.ai",
  OUTLOOK: "https://outlook.live.com",
  APPLE_MAIL: "https://www.icloud.com/mail"
};

export const PHONE_NUMBER_REGEX = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g;

export const maxInputFieldLength = 128;
