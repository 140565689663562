import React, { useState } from "react";
import { vantiColors } from "assets/jss/palette";
import { FlexContainer, FlexItem, Text } from "components/ui";
import { PlainTooltip } from "components/ui/tooltips/tooltip";
import { useSelector } from "react-redux";
import { Button, makeStyles } from "@material-ui/core";
import { ReactComponent as VantiLogo } from "../../../../assets/img/vanti-logo/basic-logo.svg";
import TrialBannerModal from "./TrialBannerModal";
import * as fullStory from "common/fullstory/fullstory";
import FULLSTORY_EVENTS from "common/fullstory/fullStoryEvents";
import TrialIcon from "assets/icons/TrialIcon";
import { licenseTimeLeftSelector } from "modules/account/state/selectors";
import { profileDataSelector } from "modules/user/state/selectors";
import { logger } from "utils/logger";

const useStyles = makeStyles({
  trialIconContainer: {
    marginRight: "10px",
    display: "flex",
    alignItems: "center"
  },
  vantiLogo: {
    width: "24px",
    height: "24px"
  },
  button: {
    backgroundColor: vantiColors.darkBlue1,
    padding: "10px",
    "&:hover": {
      backgroundColor: vantiColors.darkBlue1
    }
  },
  trialBannerPopupContainer: {
    padding: "10px"
  },
  plainTooltipContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
});

const TrialBannerPopup = ({ onTrialBannerButtonClick }) => {
  const classes = useStyles();

  return (
    <FlexContainer justifyContent="center" className={classes.trialBannerPopupContainer}>
      <FlexItem xs={12} dense="vertical">
        <Text size={14} color={vantiColors.darkBlue1}>
          Interested in keeping your vanti account?
        </Text>
      </FlexItem>

      <FlexItem xs={12}>
        <Button
          fullWidth
          className={classes.button}
          onClick={onTrialBannerButtonClick}
          id="lets-talk-button-for-trial-account"
        >
          <FlexContainer justifyContent="center" alignItems="flex-start">
            <VantiLogo className={classes.vantiLogo} />

            <FlexItem dense="rightTopBottom">
              <Text size={18} color={vantiColors.white} weight="medium">
                LET'S TALK
              </Text>
            </FlexItem>
          </FlexContainer>
        </Button>
      </FlexItem>
    </FlexContainer>
  );
};

const TrialBanner = () => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const licenseTimeLeft = useSelector(licenseTimeLeftSelector);
  const profileData = useSelector(profileDataSelector);

  const onTrialBannerButtonClick = () => {
    setIsModalOpen(true);

    try {
      fullStory.setCustomEvent(FULLSTORY_EVENTS.USER_CLICKED_LETS_TALK_BUTTON_IN_TRIAL_BANNER, profileData);
    } catch (error) {
      logger.error(`Error creating a custom Fullstory event: ${error}`);
    }
  };

  return (
    <>
      <PlainTooltip
        arrow
        interactive
        title={
          <TrialBannerPopup licenseTimeLeft={licenseTimeLeft} onTrialBannerButtonClick={onTrialBannerButtonClick} />
        }
        placement="bottom-start"
      >
        <div className={classes.plainTooltipContainer}>
          <FlexContainer justifyContent="center" alignItems="flex-start">
            <FlexItem container justifyContent="center" alignItems="center" dense="full">
              <FlexItem dense="full" className={classes.trialIconContainer}>
                <TrialIcon fillColor={vantiColors.turquoise4} />
              </FlexItem>

              <FlexItem dense="full">
                <Text size={20} color={vantiColors.darkBlue1} weight="medium">
                  TRIAL
                </Text>
              </FlexItem>
            </FlexItem>

            <FlexItem container xs={12} dense="vertical" justifyContent="center">
              <FlexItem dense="full">
                <Text size={16} color={vantiColors.darkBlue1}>
                  <Text size="inherit" color="inherit" weight="medium">
                    {licenseTimeLeft}{" "}
                  </Text>
                  days remaining{" "}
                </Text>

                <Text underline size="inherit" color={vantiColors.darkBlue1} weight="medium">
                  GET A PLAN
                </Text>
              </FlexItem>
            </FlexItem>
          </FlexContainer>
        </div>
      </PlainTooltip>

      <TrialBannerModal open={isModalOpen} setIsModalOpen={setIsModalOpen} onButtonClick={() => {}} />
    </>
  );
};

export default TrialBanner;
