import { WEB_NOTIFICATION_TOPIC } from "@vanti-analytics-org/vanti-common";

export { NOTIFICATION_CENTER_TOPICS } from "@vanti-analytics-org/vanti-common";
export { WEB_NOTIFICATION_TYPE as NOTIFICATION_TYPES } from "@vanti-analytics-org/vanti-common";

export const NOTIFICATION_TOPICS = WEB_NOTIFICATION_TOPIC; //Alias

export const NOTIFICATIONS_TRAINING_TOPICS = {
  [WEB_NOTIFICATION_TOPIC.TRAINING]: WEB_NOTIFICATION_TOPIC.TRAINING,
  [WEB_NOTIFICATION_TOPIC.TRAINING_SUCCESSFUL]: WEB_NOTIFICATION_TOPIC.TRAINING_SUCCESSFUL,
  [WEB_NOTIFICATION_TOPIC.TRAINING_FAILED]: WEB_NOTIFICATION_TOPIC.TRAINING_FAILED,
  [WEB_NOTIFICATION_TOPIC.TRAINING_GOOD_MODEL]: WEB_NOTIFICATION_TOPIC.TRAINING_GOOD_MODEL,
  [WEB_NOTIFICATION_TOPIC.TRAINING_BAD_MODEL]: WEB_NOTIFICATION_TOPIC.TRAINING_BAD_MODEL
};

export const NOTIFICATIONS_INSIGHT_TOPICS = {
  [WEB_NOTIFICATION_TOPIC.SIGNIFICANT_FEATURES]: WEB_NOTIFICATION_TOPIC.SIGNIFICANT_FEATURES,
  [WEB_NOTIFICATION_TOPIC.INSIGNIFICANT_FEATURES]: WEB_NOTIFICATION_TOPIC.INSIGNIFICANT_FEATURES,
  [WEB_NOTIFICATION_TOPIC.THRESHOLD]: WEB_NOTIFICATION_TOPIC.THRESHOLD,
  [WEB_NOTIFICATION_TOPIC.EXPLAINABILITY]: WEB_NOTIFICATION_TOPIC.EXPLAINABILITY,
  [WEB_NOTIFICATION_TOPIC.TIME_SERIES]: WEB_NOTIFICATION_TOPIC.TIME_SERIES
};

export const NOTIFICATIONS_WARNING_TOPICS = {
  [WEB_NOTIFICATION_TOPIC.DATA_DRIFT]: WEB_NOTIFICATION_TOPIC.DATA_DRIFT,
  [WEB_NOTIFICATION_TOPIC.IMAGE_DATA_DRIFT]: WEB_NOTIFICATION_TOPIC.IMAGE_DATA_DRIFT,
  [WEB_NOTIFICATION_TOPIC.BACKTEST]: WEB_NOTIFICATION_TOPIC.BACKTEST
};

export const PREDICTION_NOTIFICATION_ACTION_TYPE = {
  FIX_IT: "fix it",
  LEARN_MORE: "learn more",
  TRAIN_NEW_MODEL: "train new model",
  DISMISS: "dismiss"
};

const BACKTEST_MESSAGE_DISMISSED = "backtest-warning-dismiss";

export const getLocalStorageNotificationKey = stationId => {
  return `${stationId}-${BACKTEST_MESSAGE_DISMISSED}`;
};

export const BACKTEST_NOTIFICATION_ACTION_TYPE = {
  FIX_IT: "fix it",
  LEARN_MORE: "learn more",
  TRAIN_NEW_MODEL: "train new model",
  DISMISS: "dismiss"
};

export const BACKTEST_NOTIFICATION_TYPE = {
  ERROR: "error",
  SUCCESS: "success"
};

export const PREDICTION_STATE = {
  FINISHED: "Finished"
};

export const APPLICATION_NAME_PLACEHODER = "_app_name_placeholder_";
export const DASHBOARD_INVITED_BY_USER_PLACEHOLDER = "_dashboard_invited_by_user_placeholder_";
export const DASHBOARD_NAME_PLACEHOLDER = "_dashboard_name_placeholder_";
export const MAX_CHARS_ALLOWED_IN_NOTIFICATION_CENTER_USER_MESSAGE = 64;
export const NOTIFICATION_CENTER_USER_MESSAGE_ERROR_LABEL_TEXT = "Error";
export const NOTIFICATION_CENTER_USER_MESSAGE_EMPTY_NOTIFICATIONS_LABEL_TEXT = "There are currently no notifications";

export const NOTIFICATION_CENTER_USER_MESSAGE_TEPMAPLTES = {
  [NOTIFICATION_TOPICS.DATA_DRIFT]: `Features out of range detected (app ${APPLICATION_NAME_PLACEHODER})`,
  [NOTIFICATION_TOPICS.IMAGE_DATA_DRIFT]: `Features out of range detected (app ${APPLICATION_NAME_PLACEHODER})`,
  [NOTIFICATION_TOPICS.BACK_TO_NORMAL]: `Features are back to normal range (app ${APPLICATION_NAME_PLACEHODER})`,
  [NOTIFICATION_TOPICS.BACKTEST]: `Data structure changes detected (app ${APPLICATION_NAME_PLACEHODER})`,
  [NOTIFICATION_TOPICS.BACK_TO_NORMAL_BACKTEST]: `Data is back to a normal structure (app ${APPLICATION_NAME_PLACEHODER})`,
  [NOTIFICATION_TOPICS.FILE_UPLOADED]: `File uploaded successfully (app ${APPLICATION_NAME_PLACEHODER})`,
  [NOTIFICATION_TOPICS.TRAINING_SUCCESSFUL]: `Model finished training (app ${APPLICATION_NAME_PLACEHODER})`,
  [NOTIFICATION_TOPICS.TRAINING_GOOD_MODEL]: `Model finished training (app ${APPLICATION_NAME_PLACEHODER})`,
  [NOTIFICATION_TOPICS.TRAINING_BAD_MODEL]: `Model finished training (app ${APPLICATION_NAME_PLACEHODER})`,
  [NOTIFICATION_TOPICS.TRAINING_FAILED]: `Model training has failed (app ${APPLICATION_NAME_PLACEHODER})`,

  [NOTIFICATION_TOPICS.INSIGNIFICANT_FEATURES]: `New insight has been generated (app ${APPLICATION_NAME_PLACEHODER})`,
  [NOTIFICATION_TOPICS.SIGNIFICANT_FEATURES]: `New insight has been generated (app ${APPLICATION_NAME_PLACEHODER})`,
  [NOTIFICATION_TOPICS.THRESHOLD]: `New insight has been generated (app ${APPLICATION_NAME_PLACEHODER})`,
  [NOTIFICATION_TOPICS.EXPLAINABILITY]: `New insight has been generated (app ${APPLICATION_NAME_PLACEHODER})`,
  [NOTIFICATION_TOPICS.TIME_SERIES]: `New insight has been generated (app ${APPLICATION_NAME_PLACEHODER})`,
  [NOTIFICATION_TOPICS.DASHBOARD_INVITE]: `${DASHBOARD_INVITED_BY_USER_PLACEHOLDER} invited you to dashboard "${DASHBOARD_NAME_PLACEHOLDER}"`
};

export const NOTIFICATION_CENTER_BUTTON_TEXT = {
  FIX_IT: "FIX IT",
  CONTINUE: "CONTINUE",
  LEARN_MORE: "LEARN MORE",
  SEE_RESULT: "SEE RESULTS",
  DASHBOARD_INVITE: "JOIN"
};
