import { BLOCK_DB_STATES } from "common/constants/BlockConstants";
import * as fullStory from "common/fullstory/fullstory";
import FULLSTORY_EVENTS from "common/fullstory/fullStoryEvents";
import { logger } from "utils/logger";

export const checkIfShouldUpdateFullstoryWithBlockState = (oldBlock, newBlock, accountId) => {
  if (oldBlock.state === BLOCK_DB_STATES.IN_ANALYSIS && newBlock.state === BLOCK_DB_STATES.FAILED_ANALYSIS) {
    const fullStoryProps = {
      accountId,
      modelId: newBlock.id
    };
    try {
      fullStory.setCustomEvent(FULLSTORY_EVENTS.MODEL_RETURNED_ERROR_DURING_TRAINING, fullStoryProps);
    } catch (error) {
      logger.error(`Error creating a custom Fullstory event: ${error}`);
    }
  } else if (oldBlock.state !== BLOCK_DB_STATES.PENDING && newBlock.state === BLOCK_DB_STATES.PENDING) {
    const fullStoryProps = {
      accountId,
      modelId: newBlock.id
    };
    try {
      fullStory.setCustomEvent(FULLSTORY_EVENTS.MODEL_IN_PENDING_CUSTOM_STATE, fullStoryProps);
    } catch (error) {
      logger.error(`Error creating a custom Fullstory event: ${error}`);
    }
  } else if (oldBlock.state === BLOCK_DB_STATES.IN_DEPLOYMENT && newBlock.state === BLOCK_DB_STATES.FAILED_DEPLOYMENT) {
    const fullStoryProps = {
      accountId,
      modelId: newBlock.id
    };
    try {
      fullStory.setCustomEvent(FULLSTORY_EVENTS.MODEL_RETURNED_ERROR_DURING_DEPLOYMENT, fullStoryProps);
    } catch (error) {
      logger.error(`Error creating a custom Fullstory event: ${error}`);
    }
  }
};

export const getSelectedModelFromStation = station => {
  const stationBlocks = Object.values(station.blocks || {});
  return stationBlocks.find(block => block.state === station.blockState) || stationBlocks[0];
};

export const getActiveModelFromStation = station => {
  const stationBlocks = Object.values(station.blocks || {});

  const activeBlock = stationBlocks.find(block =>
    [BLOCK_DB_STATES.ACTIVE, BLOCK_DB_STATES.DEPLOYED, BLOCK_DB_STATES.IN_DEPLOYMENT].includes(block.state)
  );

  return activeBlock || null;
};
