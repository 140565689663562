import React, { memo, useEffect, useMemo, useState } from "react";
import { EdgeLabelRenderer, getSmoothStepPath, SmoothStepEdge } from "reactflow";
import { ClickableWrapperWithHoveredClipPath } from "../data-entities/body/activity-section/ActivityCard";
import { vantiColors } from "assets/jss/palette";
import { Grow, styled } from "@material-ui/core";
import MoreIconVertical24 from "assets/more-icon-vertical/MoreIconVertical24";
import PlusIcon24 from "assets/icons/plus-icon/PlusIcon24";
import TrashIcon24 from "assets/icons/trash-icon/TrashIcon24";
import DisplayIcon24 from "assets/icons/display-icon/DisplayIcon24";

const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, source, target, data }) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    targetX,
    targetY
  });

  const { isHovered = false, onClickDelete = undefined, onClickAdd = undefined, alerts, onClickShowAlerts } = data;

  const [isMenuClicked, setIsMenuClicked] = useState(false);

  useEffect(() => {
    if (!isHovered) {
      setIsMenuClicked(false);
    }
  }, [isHovered]);

  const edgeStyle = useMemo(() => {
    if (alerts && alerts.length) {
      return { stroke: "orange", animation: "dashdraw 0.7s linear infinite", strokeDasharray: 5 };
    }
    return {};
  }, [alerts]);

  return (
    <>
      <SmoothStepEdge
        id={id}
        sourceX={sourceX}
        sourceY={sourceY}
        targetX={targetX}
        targetY={targetY}
        animated={true}
        style={{ ...edgeStyle }}
      />

      <EdgeLabelRenderer>
        <EdgeIconButtonGroup
          isHovered={isHovered}
          labelX={labelX}
          labelY={labelY}
          onBlur={() => setIsMenuClicked(false)}
        >
          {isMenuClicked ? (
            <>
              <div />
              <Grow
                in={isMenuClicked}
                style={{ transformOrigin: "0 0 0" }}
                {...(isMenuClicked ? { timeout: 1000 } : {})}
              >
                <EdgeButtonWrapper onClick={() => onClickDelete && onClickDelete(id)}>
                  <TrashIcon24 color={vantiColors.gray18} stroke={vantiColors.darkBlue7} />
                </EdgeButtonWrapper>
              </Grow>

              <Grow
                in={isMenuClicked}
                style={{ transformOrigin: "0 0 0" }}
                {...(isMenuClicked ? { timeout: 1300 } : {})}
              >
                <EdgeButtonWrapper onClick={() => onClickAdd && onClickAdd(id, source, target)}>
                  <PlusIcon24 width={24} height={24} fill={vantiColors.lightBlue5} />
                </EdgeButtonWrapper>
              </Grow>

              {alerts?.length > 0 && (
                <Grow
                  in={isMenuClicked}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(isMenuClicked ? { timeout: 1300 } : {})}
                >
                  <EdgeButtonWrapper onClick={() => onClickShowAlerts && onClickShowAlerts(alerts)}>
                    <DisplayIcon24 width={24} height={24} fill={vantiColors.lightBlue5} />
                  </EdgeButtonWrapper>
                </Grow>
              )}
            </>
          ) : (
            <Grow in={!isMenuClicked}>
              <EdgeButtonWrapper>
                <MoreIconVertical24 color={vantiColors.darkBlue7} onClick={() => setIsMenuClicked(true)} />
              </EdgeButtonWrapper>
            </Grow>
          )}
        </EdgeIconButtonGroup>
      </EdgeLabelRenderer>
    </>
  );
};

const EdgeIconButtonGroup = styled("div")(({ isHovered, labelX, labelY }) => ({
  opacity: isHovered ? 1 : 0,
  transition: "all .25s ease-in-out",
  position: "absolute",
  transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
  pointerEvents: isHovered ? "all" : "unset",
  zIndex: 1000,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  padding: 8,
  height: 32,
  boxSizing: "border-box",
  gap: 8
}));

const EdgeButtonWrapper = styled(ClickableWrapperWithHoveredClipPath)({
  transition: "all .25s ease-in-out",
  backgroundColor: vantiColors.gray35
});

export default memo(CustomEdge);
