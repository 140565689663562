import React, { memo, useState } from "react";

import Input from "..";
import { IconButton, InputAdornment } from "@material-ui/core";

import EyeOpen from "assets/icons/EyeOpen";
import EyeClosed from "assets/icons/EyeClosed";

import useStyles from "./styles";

const UnderlinedPasswordInput = ({ ...rest }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Input
      {...rest}
      inputProps={{
        ...rest.inputProps,
        type: showPassword ? "text" : "password",
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              classes={{
                root: classes.iconButton
              }}
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeOpen /> : <EyeClosed />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default memo(UnderlinedPasswordInput);
