export const actionTypes = {
  STATION_DELETE_REQUEST: "station.delete.request",
  STATION_DELETE_SUCCESS: "station.delete.success",
  STATION_DELETE_ERROR: "station.delete.error",
  STATION_DELETE_FULFILL: "station.delete.fulfill"
};

export function stationDeleteRequestAction(stationId, callback) {
  return { type: actionTypes.STATION_DELETE_REQUEST, payload: { stationId, callback } };
}
