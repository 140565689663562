import React, { memo } from "react";

const BigQueryIcon34 = () => {
  return (
    <svg width="50" height="34" viewBox="0 0 50 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0123 32.0629L7.04169 18.1722C6.6085 17.3909 6.6085 16.5227 7.04169 15.7414L15.0123 1.85069C15.4455 1.06934 16.2253 0.635254 17.0916 0.635254H33.1195C33.9859 0.635254 34.7657 1.06934 35.1988 1.85069L43.1695 15.7414C43.6027 16.5227 43.6027 17.3909 43.1695 18.1722L35.1122 32.0629C34.679 32.8443 33.8993 33.2783 33.0329 33.2783H17.0916C16.2253 33.2783 15.4455 32.8443 15.0123 32.0629Z"
        fill="#4386FA"
      />
      <path
        opacity="0.1"
        d="M30.0873 12.5288C30.0873 12.5288 32.3399 17.9114 29.3076 20.95C26.2753 23.9886 20.7305 22.0786 20.7305 22.0786L31.9933 33.3648H33.1196C33.986 33.3648 34.7657 32.9307 35.1989 32.1494L40.4838 22.9468L30.0873 12.5288Z"
        fill="black"
      />
      <path
        d="M32.686 23.468L30.2601 21.0372L30.1735 20.9503C32.4261 17.9986 31.9062 13.8314 28.9606 11.5741C26.2748 9.49053 22.5494 9.75098 20.1235 12.1819C17.6111 14.6995 17.5244 18.6931 19.8636 21.3844C22.2028 23.9889 26.1882 24.423 28.9606 22.1658C28.9606 22.1658 28.9606 22.2526 29.0472 22.2526L31.473 24.6835C31.5597 24.7703 31.6463 24.7703 31.733 24.7703C31.8196 24.7703 31.9062 24.7703 31.9929 24.6835L32.686 23.9889C32.7726 23.9021 32.7726 23.8153 32.7726 23.7285C32.8592 23.6417 32.7726 23.5548 32.686 23.468ZM24.8886 21.8185C22.8959 21.8185 20.9899 20.6031 20.2102 18.6931C19.4304 16.7831 19.8636 14.6127 21.3365 13.2237C23.3291 11.2269 26.4481 11.2269 28.4407 13.2237C29.3937 14.1786 29.9136 15.4809 29.9136 16.7831C29.9136 19.5613 27.661 21.8185 24.8886 21.8185ZM21.683 16.5227V18.6063C22.0296 19.214 22.4627 19.6481 23.0692 19.9954V16.5227H21.683ZM24.1955 14.7864V20.4294C24.6287 20.5163 25.0619 20.5163 25.5817 20.4294V14.7864H24.1955ZM28.0942 18.6063V17.3909H26.708V19.9954C27.3144 19.6481 27.7476 19.214 28.0942 18.6063Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(BigQueryIcon34);
