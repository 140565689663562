import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import dataConnectorsReducer from "./reducers";
import { connectToConnectorWatcher, fetchDataConnectorsWatcher } from "./sagas";

export default combineReducers({
  state: dataConnectorsReducer
});

export function* sagas() {
  yield all([connectToConnectorWatcher(), fetchDataConnectorsWatcher()]);
}
