import React, { forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import classNames from "classnames";
import CircularProgressMaterialUi from "@material-ui/core/CircularProgress";
import { vantiColors } from "../../../assets/jss/vanti-dashboard.js";

const useStyles = makeStyles({
  gray: {
    color: vantiColors.gray2
  },
  lightGray: {
    color: vantiColors.gray5
  },
  dark: {
    color: vantiColors.gray30
  },
  darkGrayText: {
    color: vantiColors.gray15
  },
  blue: {
    color: vantiColors.darkBlue1
  },
  green: {
    color: vantiColors.green1
  },
  turquoise: {
    color: vantiColors.turquoise1
  },
  roundLinecap: {
    strokeLinecap: "round"
  },
  squareLinecap: {
    strokeLinecap: "square"
  }
});

const CircularProgress = (props, ref) => {
  const classes = useStyles();
  const { className, color, linecap = "square", ...rest } = props;

  const progressClasses = classNames({
    [classes[color]]: color,
    [className]: className !== undefined
  });

  const circleClasses = classNames({
    [classes[linecap + "Linecap"]]: linecap
  });

  return (
    <CircularProgressMaterialUi
      className={progressClasses}
      classes={{
        circle: circleClasses,
        circleStatic: circleClasses
      }}
      {...rest}
      ref={ref}
    />
  );
};

export default forwardRef(CircularProgress);

CircularProgress.propTypes = {
  className: PropTypes.string,
  linecap: PropTypes.oneOf(["square", "round"]),
  color: PropTypes.oneOf(["dark", "darkGray", "blue", "green", "turquoise", "gray", "lightGray"])
};
