import React, { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, styled } from "@material-ui/core";

import usePermissions from "common/hooks/use-permissions";
import { PERSONAS_ENUM, ROLES_ENUM } from "common/constants/AccountConstants";
import * as signOutActions from "modules/auth/operations/signOut/actions";

import { userPermissionsSelector } from "modules/user/state/selectors";
import { vantiColors } from "assets/jss/palette";

export const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  border: `1px solid ${vantiColors.lightGray4}`,
  padding: 0,
  margin: theme.spacing(0.5, 0, 0, 0),
  backgroundColor: theme.palette.vantiColors.white,
  boxShadow: `0px 4px 15px 0px #39425326`
}));

export const StyledMenuItem = styled(MenuItem)(({ theme, size = 13, color = vantiColors.darkBlue1 }) => ({
  ...theme.fonts.defaultFont,
  fontSize: size,
  padding: "10px 20px",
  margin: "0",
  borderRadius: "2px",
  WebkitTransition: "all 150ms linear",
  MozTransition: "all 150ms linear",
  OTransition: "all 150ms linear",
  MsTransition: "all 150ms linear",
  transition: "all 150ms linear",
  display: "block",
  clear: "both",
  fontWeight: "400",
  lineHeight: "1.42857143",
  color: color,
  whiteSpace: "nowrap",
  height: "unset",
  minHeight: "unset",
  "&:hover": {
    backgroundColor: vantiColors.gray18,
    color: color
  }
}));

export const StyledMenuList = styled(MenuList)({
  padding: 0,
  margin: 0
});

export default function UserMenu({ isOpen, onClose, anchorElement }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const userPermissions = useSelector(userPermissionsSelector);
  const allowPreferences = usePermissions([ROLES_ENUM.SET_PREFERENCES]);
  const isSuperAdmin = userPermissions?.persona === PERSONAS_ENUM.SUPER_ADMIN;
  const canInviteUsers = usePermissions([ROLES_ENUM.INVITE_USER]);

  const handleCloseMenu = () => {
    onClose();
  };
  const handleProfilePage = () => {
    onClose();
    history.push("/dashboard/profile");
  };
  const handleAdminPage = () => {
    onClose();
    history.push("/admin");
  };

  const handlePreferences = () => {
    if (allowPreferences) {
      onClose();
      history.push("/dashboard/preferences");
    } else {
      history.push("/dashboard/stations");
    }
  };

  const handleInvite = () => {
    onClose();
    history.push("/dashboard/invite");
  };

  const handleSettings = useCallback(() => {
    onClose();
    history.push("/dashboard/settings");
  }, [onClose]);

  const handleLogout = () => {
    dispatch(signOutActions.signOutRequestAction());
  };

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorElement}
      style={{ zIndex: 10000 }}
      transition
      popperOptions={{
        placement: "bottom-end"
      }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} id="profile-menu-list-grow">
          <StyledPaper>
            <ClickAwayListener onClickAway={handleCloseMenu}>
              <StyledMenuList role="menu" data-testid="user-menu-list">
                {isSuperAdmin && (
                  <>
                    <StyledMenuItem data-testid="user-menu-list-admin" onClick={handleAdminPage}>
                      Admin
                    </StyledMenuItem>
                  </>
                )}
                <StyledMenuItem data-testid="user-menu-list-profile" onClick={handleProfilePage}>
                  Profile
                </StyledMenuItem>
                {canInviteUsers && (
                  <StyledMenuItem data-testid="user-menu-list-invite" onClick={handleInvite}>
                    Invite
                  </StyledMenuItem>
                )}
                <StyledMenuItem data-testid="user-menu-list-settings" onClick={handleSettings}>
                  Info
                </StyledMenuItem>
                {allowPreferences && (
                  <>
                    <StyledMenuItem data-testid="user-menu-list-preferences" onClick={handlePreferences}>
                      Preferences
                    </StyledMenuItem>
                  </>
                )}
                <StyledMenuItem color={vantiColors.rose9} data-testid="user-menu-list-logout" onClick={handleLogout}>
                  Logout
                </StyledMenuItem>
              </StyledMenuList>
            </ClickAwayListener>
          </StyledPaper>
        </Grow>
      )}
    </Popper>
  );
}
