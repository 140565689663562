import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const PencilIcon16In24 = ({ stroke = vantiColors.darkBlue7, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M14.9729 6.97365L17.2545 9.25525L9.58006 16.9297L7.50588 16.9297L7.29846 14.6481L14.9729 6.97365Z"
      stroke={stroke}
    />
  </svg>
);

export default memo(PencilIcon16In24);
