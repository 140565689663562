import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import ChatSendArrowIcon24 from "assets/icons/chat-icon/ChatSendArrowIcon24";
import { AppContext } from "common/hooks/context-hooks/use-app-context";

const INPUT_PLACEHOLDER = "Ask a question about your manufacturing data...";

const InputContainer = styled("div")({
  paddingBottom: "16px",
  paddingLeft: "16px",
  paddingRight: "16px"
});

const InputWrapperStyled = styled("div")({
  width: "100%",
  height: 48,
  padding: "0px 12px 0px 16px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "transparent",
  border: `1px solid ${vantiColors.lightGray13}`,
  borderRadius: 4,
  boxSizing: "border-box"
});

const InputStyled = styled("input")(({ chatInputEnabled }) => ({
  border: "none",
  width: "100%",
  height: "100%",
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 400,
  backgroundColor: "transparent",
  color: vantiColors.gray29,
  spellCheck: true,
  "&::placeholder": {
    color: chatInputEnabled ? vantiColors.gray33 : vantiColors.lightGray13
  },
  boxSizing: "border-box"
}));

const ChatInput = ({ className, onSend }) => {
  const [inputValue, setInputValue] = useState("");
  const [emptyInputLine, setEmptyInputLine] = useState(true);
  const { chatContext } = useContext(AppContext);
  const { chatInputEnabled } = chatContext;

  useEffect(() => {
    setEmptyInputLine(!inputValue.length);
  }, [inputValue]);

  const onSendButtonClicked = useCallback(() => {
    if (!inputValue) return;

    onSend(inputValue);
    setInputValue("");
  }, [inputValue, onSend]);

  const onInputKeyPressed = event => {
    if (event.key === "Enter") {
      onSendButtonClicked();
      setInputValue("");
    }
  };

  const sendArrowIconStroke = useMemo(() => {
    return chatInputEnabled ? (emptyInputLine ? vantiColors.gray33 : vantiColors.darkBlue7) : vantiColors.lightGray13;
  }, [chatInputEnabled, emptyInputLine]);

  return (
    <InputContainer>
      <InputWrapperStyled className={className} chatInputEnabled={chatInputEnabled}>
        <InputStyled
          value={inputValue}
          chatInputEnabled={chatInputEnabled}
          onChange={e => {
            setInputValue(e.target.value);
          }}
          onKeyDown={onInputKeyPressed}
          variant="outlined"
          placeholder={INPUT_PLACEHOLDER}
          disabled={!chatInputEnabled}
        />
        <ChatSendArrowIcon24
          onClick={chatInputEnabled ? onSendButtonClicked : null}
          stroke={sendArrowIconStroke}
          style={{ cursor: chatInputEnabled ? "pointer" : "unset" }}
        />
      </InputWrapperStyled>
    </InputContainer>
  );
};

export default memo(ChatInput);
