import { call, put, select, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./actions";
import * as authService from "services/auth/auth";
import { accountUsersSelector } from "../../state/selectors";
import { setUsersAction } from "../../state/actions";

export function* changeUserPermissionsWorker(action) {
  try {
    const response = yield call(
      authService.changeUsersPermissions,
      action.payload.map(user => ({
        persona: user.persona,
        email: user.email
      }))
    );

    const updatedUsers = response.data.reduce((acc, user) => {
      acc[user.id] = user;
      return acc;
    }, {});

    const currentUsers = yield select(accountUsersSelector);
    yield put(
      setUsersAction([
        ...currentUsers.map(user => {
          const updatedUser = updatedUsers[user.id];
          if (updatedUser) {
            return updatedUser;
          }
          return user;
        })
      ])
    );

    const persona = action.payload[0].persona;
    const personaAdjusted = persona.charAt(0).toUpperCase() + persona.slice(1).toLowerCase();

    yield put({
      type: actionTypes.ACCOUNT_CHANGE_USER_PERMISSIONS_SUCCESS,
      payload: {
        email: action.payload[0].email,
        persona: personaAdjusted
      }
    });
  } catch (error) {
    yield put({ type: actionTypes.ACCOUNT_CHANGE_USER_PERMISSIONS_ERROR, payload: error });
  } finally {
    yield put({ type: actionTypes.ACCOUNT_CHANGE_USER_PERMISSIONS_FULFILL });
  }
}

export default function* changeUserPermissionsWatcher() {
  yield takeLatest(actionTypes.ACCOUNT_CHANGE_USER_PERMISSIONS_REQUEST, changeUserPermissionsWorker);
}
