import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import deleteReducer, * as deleteModule from "./delete";

export default combineReducers({
  delete: deleteReducer
});

export function* sagas() {
  yield all([deleteModule.blockDeleteWatcher()]);
}
