import React, { memo, useCallback } from "react";
import List from "@material-ui/core/List";
import { vantiColors } from "assets/jss/palette";
import { ListItemIcon, styled } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";

export const TreeViewFooter = styled(List)(({ theme }) => ({
  "&.MuiList-root": {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: "0px 0px 32px 0px",
    boxSizing: "border-box"
  }
}));

export const FooterListItem = styled(ListItem)(({ theme }) => ({
  "&.MuiListItem-root": {
    padding: theme.spacing(0, 0, 0, 0),
    margin: theme.spacing(0, 0, 0, 0)
  }
}));

export const FooterListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  "&.MuiListItemIcon-root": {
    minWidth: "0px"
  },
  color: vantiColors.darkBlue7,
  paddingRight: theme.spacing(0.5)
}));

export const FooterListItemText = styled(ListItemText)({
  color: vantiColors.darkBlue7,
  "& .MuiListItemText-primary": {
    fontSize: "14px"
  },
  "&.MuiListItemText-root": {
    margin: 0
  }
});

const CustomTreeFooter = ({ list }) => {
  const FooterItemListComponent = useCallback(
    ({ item }) => (
      <FooterListItem key={item.name} button onClick={item.onClick} disabled={item.disabled} disableGutters>
        <FooterListItemIcon>{item.icon}</FooterListItemIcon>
        <FooterListItemText primary={item.name} />
      </FooterListItem>
    ),
    []
  );

  const buildItem = useCallback((item, index) => {
    return item.component ? (
      React.cloneElement(item.component, {
        onClick: item.onClick,
        disabled: item.disabled,
        ...(item.componentProps || {})
      })
    ) : (
      <FooterItemListComponent item={item} key={index} />
    );
  }, []);

  return <TreeViewFooter>{list.items.map(buildItem)}</TreeViewFooter>;
};

export default memo(CustomTreeFooter);
