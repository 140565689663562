import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import EmailsList from "./emailsAdmin/EmailsList";
import EmailsSend from "./emailsAdmin/EmailsSend";
import EmailsEdit from "./emailsAdmin/EmailsEdit";

const basePath = "/admin/emails";

function EmailsAdminItem() {
  return (
    <Switch>
      <Route path={`${basePath}/:emailId/edit`} component={EmailsEdit} />
      <Route path={`${basePath}/:emailId/send`} component={EmailsSend} />
      <Redirect to={`${basePath}`} />
    </Switch>
  );
}

export default function EmailsAdmin() {
  return (
    <Switch>
      <Route exact path={`${basePath}`} component={EmailsList} />
      <Route path={`${basePath}/:emailId`} component={EmailsAdminItem} />
    </Switch>
  );
}
