import React, { memo } from "react";
import BellNotificationIcon from "assets/icons/notification-center/BellNotificationIcon";
import BellNotificationIconWithBadge from "assets/icons/notification-center/BellNotificationIconWithBadge";
import { IconButton } from "@material-ui/core";
import styled from "styled-components";
import { vantiColors } from "assets/jss/palette";

const StyledBellNotificationIconButton = styled(IconButton)`
  background-color: ${props => (props.isPopoverOpen ? vantiColors.lightGray1 : "transparent")};
  &.MuiIconButton-root {
    padding: 0px 0px 0px 0px;
    width: 32px;
    height: 32px;
  }
`;

const BellNotificationIconWrapper = ({ onClick, isPopoverOpen, showBadge }) => {
  return (
    <StyledBellNotificationIconButton
      onClick={onClick}
      isPopoverOpen={isPopoverOpen}
      data-testid={showBadge ? "bell-notification-icon-with-badge" : "bell-notification-icon"}
    >
      {showBadge ? <BellNotificationIconWithBadge /> : <BellNotificationIcon />}
    </StyledBellNotificationIconButton>
  );
};

export default memo(BellNotificationIconWrapper);
