import React, { memo } from "react";

const PaintShopIcon = ({ className }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.6 13.8C31.3023 13.8 38.0045 16.2923 38.0045 16.2923C38.0045 16.2923 36.6944 16.7795 34.6534 17.3231C30.776 18.3815 27.4526 18.7056 26.2756 18.7354C25.7196 18.7672 25.1598 18.7846 24.6 18.7846C24.0402 18.7846 23.4804 18.7672 22.9244 18.7354V35.8049C22.9244 36.4989 22.3618 37.0615 21.6678 37.0615C20.9737 37.0615 20.4111 36.4989 20.4111 35.8049V25.4448C20.4111 24.5194 19.6609 23.7692 18.7355 23.7692C17.8101 23.7692 17.06 24.5194 17.06 25.4448V28.3279C17.06 29.022 16.4973 29.5846 15.8033 29.5846C15.1092 29.5846 14.5466 29.022 14.5466 28.3279V17.3231C12.5056 16.7795 11.1955 16.2923 11.1955 16.2923C11.1955 16.2923 17.8977 13.8 24.6 13.8Z"
      fill="#86E8B6"
    />
    <path
      d="M11.1955 16.2923C11.1955 16.2923 17.8977 13.8 24.6 13.8C31.3023 13.8 38.0045 16.2923 38.0045 16.2923M11.1955 16.2923C11.1955 16.2923 12.5056 16.7795 14.5466 17.3231V28.3279C14.5466 29.022 15.1092 29.5846 15.8033 29.5846V29.5846C16.4973 29.5846 17.06 29.022 17.06 28.3279V25.4448C17.06 24.5194 17.8101 23.7692 18.7355 23.7692V23.7692C19.6609 23.7692 20.4111 24.5194 20.4111 25.4448V35.8049C20.4111 36.4989 20.9737 37.0615 21.6678 37.0615V37.0615C22.3618 37.0615 22.9244 36.4989 22.9244 35.8049V18.7354C23.4804 18.7672 24.0402 18.7846 24.6 18.7846C25.1598 18.7846 25.7196 18.7672 26.2756 18.7354C27.4526 18.7056 30.776 18.3815 34.6534 17.3231C36.6944 16.7795 38.0045 16.2923 38.0045 16.2923M11.1955 16.2923L11.7869 21.2769M38.0045 16.2923L37.4131 21.2769M37.4131 21.2769L34.6534 44.5385C34.6534 44.5385 28.5802 46.2 24.6 46.2C20.6198 46.2 14.5466 44.5385 14.5466 44.5385L11.7869 21.2769M37.4131 21.2769H41.3557C42.0259 10.6431 34.9326 5.49231 31.3023 4.24615M11.7869 21.2769H7.84436C7.17414 10.6431 14.2674 5.49231 17.8977 4.24615M31.3023 5.49231H17.8977V4.24615M31.3023 5.49231V3M31.3023 5.49231V4.24615M31.3023 3H17.8977V4.24615M31.3023 3V4.24615"
      stroke="black"
    />
  </svg>
);

export default memo(PaintShopIcon);
