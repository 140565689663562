import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import inviteUserWatcher from "./operations/inviteUser/saga";
import inviteUserReducer from "./operations/inviteUser/reducer";
import fetchUsersWatcher from "./operations/fetchUsers/saga";
import fetchUsersReducer from "./operations/fetchUsers/reducer";
import removeUserWatcher from "./operations/removeUser/saga";
import removeUserReducer from "./operations/removeUser/reducer";
import resendInviteReducer from "./operations/resendInvite/reducer";
import resendInviteWatcher from "./operations/resendInvite/saga";
import changeUserPermissionsReducer from "./operations/changeUserPermissions/reducer";
import changeUserPermissionsWatcher from "./operations/changeUserPermissions/saga";
import createAccountReducer from "./operations/createAccount/reducer";
import createAccountWatcher from "./operations/createAccount/saga";
import fetchAccountReducer from "./operations/fetchAccount/reducer";
import fetchAccountWatcher from "./operations/fetchAccount/saga";
import accountStateReducer from "./state/reducer";

export default combineReducers({
  state: accountStateReducer,
  operations: combineReducers({
    fetchAccount: fetchAccountReducer,
    fetchUsers: fetchUsersReducer,
    inviteUser: inviteUserReducer,
    removeUser: removeUserReducer,
    resendInvite: resendInviteReducer,
    changeUserPermissions: changeUserPermissionsReducer,
    createAccount: createAccountReducer
  })
});

export function* sagas() {
  yield all([
    fetchAccountWatcher(),
    inviteUserWatcher(),
    fetchUsersWatcher(),
    removeUserWatcher(),
    resendInviteWatcher(),
    changeUserPermissionsWatcher(),
    createAccountWatcher()
  ]);
}
