import React, { memo } from "react";

const TreeViewArrowExtended = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2971_5625)">
        <path d="M4.66797 6L8.0013 9.33333L11.3346 6H4.66797Z" fill="#091733" />
      </g>
      <defs>
        <clipPath id="clip0_2971_5625">
          <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(TreeViewArrowExtended);
