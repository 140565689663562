import { actionTypes } from "./actions";
import uniqBy from "lodash/uniqBy";

const defaultState = {
  notificationsList: []
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.ADD_WEB_NOTIFICATION: {
      return { ...state, notificationsList: uniqBy([...state.notificationsList, action.payload], item => item.id) };
    }
    case actionTypes.HIDE_WEB_NOTIFICATION: {
      return { ...state, notificationsList: state.notificationsList.filter(item => item.id !== action.payload.id) };
    }
    case actionTypes.UPDATE_WEB_NOTIFICATION: {
      return {
        ...state,
        notificationsList: state.notificationsList.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload
            };
          }
          return item;
        })
      };
    }
    case actionTypes.SET_WEB_NOTIFICATIONS: {
      return {
        ...state,
        notificationsList: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
