export const actionTypes = {
  FETCH_ACCOUNT_REQUEST: "account.fetchAccount.request",
  FETCH_ACCOUNT_SUCCESS: "account.fetchAccount.success",
  FETCH_ACCOUNT_ERROR: "account.fetchAccount.error",
  FETCH_ACCOUNT_FULFILL: "account.fetchAccount.fulfill"
};

export function fetchAccountRequestAction() {
  return { type: actionTypes.FETCH_ACCOUNT_REQUEST };
}
