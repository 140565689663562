export const CURATION_PAGE_TITLE = "New curation";
export const CURATION_PAGE_DESC =
  "Select the data files you wish to join and/or curate, providing a description of their contents.\n" +
  "Vanti will automatically generate a curation code tailored to your requirements.\n";

export const SECONDARY_TITLES = {
  CURATION_NAME: "Curation name",
  YOUR_DATA: "Your data",
  UPLOAD_DESC: "Review the files, add or remove files according to your needs",
  INPUT_PREVIEW: "Input preview",
  CURATION: "Curation",
  CURATION_PREVIEW: "Output preview",
  NO_FILES_MESSAGE: "Please add the relevant files for the curation",
  ADD_DATASETS: "Add Datasets"
};

export const TERTIARY_TITLES = {
  PYTHON_CODE: "Python code",
  CURATION_OUTPUT_DESC: `This is a preview of your curation`
};

export const SAMPLE_CODE = `def merge(files_paths): 
  first_path = open(files_paths[0])
  second_path = open(files_paths[1])

  first_file = json.load(first_path)
  second_file = json.load(second_path)
  lines = []

  for line1 in first_file["rows"]:
    for line2 in second_file["rows"]:
      new_line = line1 + line2
      lines.append(new_line)
  
  return lines # This line is a must - should return a list of lists

def get_headers():
  # This function should return a list of strings that contains your headers
  return ["header_1", "header_2", "header_3", "header_4", "header_5", "header_6", "header_7"]`;

export const BUTTONS = {
  ADD_DATASET: "Add dataset"
};
