import React, { memo } from "react";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import { DarkToolTip } from "components/ui/tooltips/tooltip";

export const UserAvatar = styled("div")(({ backgroundColor, customAvatarStyle }) => ({
  display: "flex",
  boxSizing: "border-box",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: 32,
  height: 32,
  borderRadius: "50%",
  order: 1,
  color: vantiColors.black,
  backgroundColor,
  ...customAvatarStyle
}));

const ChatMessageUserAvatar = ({ displayName, backgroundColor = vantiColors.lightBlue11, customAvatarStyle = {} }) => {
  return (
    <DarkToolTip placement={"bottom-end"} title={displayName} arrow>
      <UserAvatar backgroundColor={backgroundColor} customAvatarStyle={customAvatarStyle}>
        <Text
          size={18}
          weight={200}
          capitalize={true}
          style={{ alignSelf: "center", lineHeight: "20px", cursor: "auto", pointerEvents: "none" }}
        >
          {displayName ? displayName[0] : ".."}
        </Text>
      </UserAvatar>
    </DarkToolTip>
  );
};

export default memo(ChatMessageUserAvatar);
