import React, { memo, useEffect, useRef } from "react";

import DataSourceFieldsSection from "../DataSourceFieldsSection";
import EditConnectButtonSection from "../../connect-button-section/EditConnectButtonSection";
import { FlexItem } from "components/ui";

const EditDataSourceWrapper = ({
  onClick,
  setDataSourceFieldsValues,
  selectedDataSource,
  fieldsChanged,
  actionResponse
}) => {
  const saveButtonRef = useRef();

  useEffect(() => {
    if (saveButtonRef.current) {
      saveButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [saveButtonRef.current]);

  return (
    <FlexItem container dense="full">
      <FlexItem container dense="horizontal">
        <DataSourceFieldsSection
          isEditMode
          selectedDataSource={selectedDataSource}
          onChangeDataSourceFieldsValues={setDataSourceFieldsValues}
        />
      </FlexItem>

      <FlexItem container justifyContent="flex-end" dense="full" ref={saveButtonRef}>
        <EditConnectButtonSection
          dataTestId="data-connector-connect-btn-section"
          actionResponse={actionResponse}
          fieldsChanged={fieldsChanged}
          onConnectClick={onClick}
        />
      </FlexItem>
    </FlexItem>
  );
};

export default memo(EditDataSourceWrapper);
