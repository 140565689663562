import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import CloseIcon16 from "assets/icons/close-icon/CloseIcon16";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import ChatMessageUserAvatar from "pages/side-bar/chat/components/chat-message/ChatMessageUserAvatar";
import { avatarsColorPalette, vantiColors } from "assets/jss/palette";
import { VantiScrollBarProps } from "components/ui/VantiScrollBarProps";
import { DashboardUserInvitationStatus } from "@vanti-analytics-org/vanti-common";
import { getAccountUsersDashboardInvitationInfo } from "services/api/dashboard";
import CheckMarkIcon from "assets/icons/checkmark/CheckMarkIcon";
import RotatingLoadingIcon from "assets/icons/RotatingLoadingIcon";
import useDashboardRequest from "common/hooks/use-dashboard-request";

const DASHBOARD_INVITE_MODAL_TITLE_TEXT = `Share this dashboard with your colleague`;
const USER_INVITATION_STATUS_TO_LABEL_TEXT = {
  [DashboardUserInvitationStatus.PENDING]: "Pending",
  [DashboardUserInvitationStatus.CONFIRMED]: "Shared"
};
const SHARE_BUTTON_LABEL_TXT = "Share";

const DashboardInviteUsersModal = ({ closeModal }) => {
  const { dashboardContext } = useContext(AppContext);
  const { currentDashboard } = dashboardContext;
  const [accountUsersDashboardInfo, setAccountUsersDashboardInfo] = useState(null);
  const { shareDashboardRequest } = useDashboardRequest();

  useEffect(async () => {
    setAccountUsersDashboardInfo(await getAccountUsersDashboardInvitationInfo());
  }, []);

  const onShareDashboard = useCallback(
    userId => {
      if (!currentDashboard?._id) {
        return;
      }
      shareDashboardRequest.mutate({ dashboardId: currentDashboard?._id, userId });
      setAccountUsersDashboardInfo(prevState => {
        const idx = prevState.findIndex(user => user._id === userId);
        if (idx !== -1) {
          prevState[idx].status = DashboardUserInvitationStatus.PENDING;
        }
        return [...prevState];
      });
    },
    [currentDashboard?._id]
  );

  const createUserInvitationStatusComponent = useCallback((status, userId) => {
    if (!status) {
      return (
        <GeneralVantiButton
          buttonInfo={{
            onClick: () => {
              onShareDashboard(userId);
            },
            label: SHARE_BUTTON_LABEL_TXT
          }}
          variant={VARIANTS.TERTIARY}
          size={SIZES.BIG}
        />
      );
    }
    return (
      <InvitationStatusWrapper>
        <Text size={16}>{USER_INVITATION_STATUS_TO_LABEL_TEXT[status]}</Text>
        {status === DashboardUserInvitationStatus.CONFIRMED ? (
          <CheckMarkIcon width={16} height={16} color={vantiColors.gray29} />
        ) : (
          <RotatingLoadingIcon width={16} height={16} color={vantiColors.green4} />
        )}
      </InvitationStatusWrapper>
    );
  }, []);

  const usersInvitationList = useMemo(() => {
    if (!accountUsersDashboardInfo?.length) {
      return;
    }

    const usersIdsInCurrentDashboard = currentDashboard.usersInfo.map(user => user._id);
    const usersNotInCurrentDashboard = accountUsersDashboardInfo.filter(
      user => !usersIdsInCurrentDashboard.includes(user._id)
    );

    const sortedUsersInvitationList = [...currentDashboard.usersInfo, ...usersNotInCurrentDashboard].sort((a, b) =>
      b.name[0]?.toLowerCase() > a.name[0]?.toLowerCase() ? -1 : 1
    );

    return sortedUsersInvitationList.map((user, i) => (
      <InvitationRowContainer key={`dashboard-invite-row-${user._id}`}>
        <AvatarAndNameWrapper>
          <ChatMessageUserAvatar
            displayName={user.name}
            backgroundColor={avatarsColorPalette[i % avatarsColorPalette.length]}
          />
          <DashboardUserDetailsWrapper>
            <TextStyled weight={500}>{user.name}</TextStyled>
            <TextStyled size={12} color={vantiColors.gray33}>
              {user.email}
            </TextStyled>
          </DashboardUserDetailsWrapper>
        </AvatarAndNameWrapper>
        {createUserInvitationStatusComponent(user.status, user._id)}
      </InvitationRowContainer>
    ));
  }, [accountUsersDashboardInfo, currentDashboard, createUserInvitationStatusComponent]);

  return (
    <DashboardInviteUsersContainer>
      <DashboardInviteUsersHeader>
        <Text size={18} weight={500}>
          {DASHBOARD_INVITE_MODAL_TITLE_TEXT}
        </Text>
        <CloseIcon16 onClick={closeModal} />
      </DashboardInviteUsersHeader>
      <DashboardInviteUsersList>{usersInvitationList}</DashboardInviteUsersList>
    </DashboardInviteUsersContainer>
  );
};

const DashboardInviteUsersContainer = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: 32
});

const DashboardInviteUsersHeader = styled("div")({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between"
});

const DashboardInviteUsersList = styled("div")({
  width: "100%",
  height: "100%",
  overflowY: "auto",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: 16,
  ...VantiScrollBarProps
});

const InvitationRowContainer = styled("div")({
  width: "100%",
  height: 40,
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  paddingRight: 4
});

const AvatarAndNameWrapper = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  gap: 12,
  alignItems: "center"
});

const DashboardUserDetailsWrapper = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
});

const InvitationStatusWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: 8
});

const TextStyled = styled(Text)({
  maxWidth: "70%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  cursor: "default"
});
export default memo(DashboardInviteUsersModal);
