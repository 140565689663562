import React, { memo } from "react";
import classNames from "classnames";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { vantiColors } from "../../../assets/jss/vanti-dashboard";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(() => ({
  root: {},
  checked: {},
  darkColor: {
    color: vantiColors.gray30,
    "&$checked": {
      color: vantiColors.gray30
    }
  },
  greenColor: {
    color: vantiColors.green1,
    "&$checked": {
      color: vantiColors.green1
    }
  },
  customColor: props => ({
    color: props.color,
    "&$checked": {
      color: props.color
    }
  }),
  formControl: {
    paddingBottom: "10px",
    margin: "15px 0 0 0",
    position: "relative",
    verticalAlign: "unset"
  }
}));

const CheckboxInput = props => {
  const { formControlProps = {}, id, label = "", checkboxProps = {}, color = "auto", onChange, checked } = props;
  const { classes: { root, ...restMuiClasses } = {}, ...restCheckboxProps } = checkboxProps;

  const classes = useStyles({ color });

  const rootClasses = classNames({
    [classes.root]: true,
    [classes.customColor]: color,
    [root]: root
  });

  return (
    <FormControlLabel
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
      control={
        <Checkbox
          data-testid="checkbox-input"
          checked={checked}
          color="green"
          classes={{
            root: rootClasses,
            checked: classes.checked,
            ...restMuiClasses
          }}
          inputProps={{ "aria-label": id }}
          onChange={onChange}
          {...restCheckboxProps}
        />
      }
      label={label}
    />
  );
};

CheckboxInput.propTypes = {
  formControlProps: PropTypes.object,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  color: PropTypes.oneOf(["dark", "green"]).isRequired,
  checkboxProps: PropTypes.object
};

export default memo(CheckboxInput);
