import React, { memo } from "react";
import { vantiColors } from "../../jss/palette";

const SearchIcon24 = ({ stroke = vantiColors.gray29, ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="10.3002" cy="9.94666" r="6.5" stroke={stroke} />
      <line x1="14.8964" y1="14.5428" x2="20.5533" y2="20.1997" stroke={stroke} />
    </svg>
  );
};

export default memo(SearchIcon24);
