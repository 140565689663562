import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Divider, Grid, IconButton, styled } from "@material-ui/core";
import dayjs from "dayjs";
import cn from "classnames";

import { Text } from "components/ui/index";
import SecondaryVantiButton from "components/ui/Buttons/secondary/SecondaryVantiButton";

import DismissNotificationIcon from "assets/icons/notification-center/DismissNotificationIcon";
import { vantiColors } from "assets/jss/palette";

import {
  APPLICATION_NAME_PLACEHODER,
  NOTIFICATION_CENTER_USER_MESSAGE_ERROR_LABEL_TEXT,
  NOTIFICATION_CENTER_USER_MESSAGE_TEPMAPLTES,
  NOTIFICATION_TYPES,
  NOTIFICATION_TOPICS,
  DASHBOARD_INVITED_BY_USER_PLACEHOLDER,
  DASHBOARD_NAME_PLACEHOLDER
} from "common/constants/NotificationConstants";
import { AppNotificationsContext } from "common/hooks/context-hooks/use-notifications-context";
import { useStations } from "common/hooks/use-station";

import { notificationItemTopicToButtonText, notificationItemTopicToIcon } from "./AccountNotificationsItem.util";
import useStyles from "./AccountNotificationsItem.style";

import { uidSelector } from "modules/auth/state/selectors";
import { stationsSelector } from "modules/station/query";
import { updateWebNotificationRequestAction } from "modules/web-notifications/operations/update-web-notification/actions";
import { MAX_DASHBOARDS_PER_USER, NOTIFICATION_CENTER_TOPICS } from "@vanti-analytics-org/vanti-common";
import useDashboardRequest from "common/hooks/use-dashboard-request";
import { DarkToolTip } from "../../tooltips/tooltip";
import { MAX_DASHBOARDS_PER_USER_TOOLTIP_TEXT } from "pages/side-bar/home/HomePageTabs";
import { AppContext } from "common/hooks/context-hooks/use-app-context";

const NotificationItemDivider = styled(Divider)(({ theme }) => ({
  width: "100%",
  margin: theme.spacing(0, 2, 0, 2)
}));

const StyledNotificationCenterUserWarningText = styled("div")(({ theme }) => ({
  color: theme.palette.vantiColors.rose6,
  display: "inline"
}));

const StyledDismissNotificationIconButton = styled(IconButton)(
  ({ theme, pointerEvents = "none", cursor = "pointer" }) => ({
    "&.MuiIconButton-root": {
      padding: theme.spacing(0, 0, 1, 1.25),
      backgroundColor: "transparent",
      pointerEvents,
      cursor
    }
  })
);

const StyledNotificationUserActionButton = styled(SecondaryVantiButton)(({ theme }) => ({
  height: "30px",
  width: "120px",
  borderRadius: theme.spacing(0.5),
  border: `1px solid ${theme.palette.vantiColors.lightGray3}`,
  padding: theme.spacing(1.5, 3, 1.5, 3),
  whiteSpace: "nowrap",
  fontFamily: "Inter",
  weight: 500,
  verticalAlign: "top"
}));

const AccountNotificationsItem = ({ notification, renderDivider }) => {
  const { id, topic, type, data, createdAt } = notification;
  const classes = useStyles();
  const history = useHistory();
  const userId = useSelector(uidSelector);
  const dispatch = useDispatch();
  const [disableDismissButton, setDisableDismissButton] = useState(false);
  const { dashboardContext } = useContext(AppContext);
  const { dashboards } = dashboardContext;
  const { acceptDashboardInvitationRequest } = useDashboardRequest();
  const { setIsNotificationCenterOpen, setApplicationFeedFocusNotificationId } = useContext(AppNotificationsContext);
  const stations = useStations(stationsSelector);
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  const buildNotificationCenterUserMessage = useCallback(() => {
    let userMessageTemplate = NOTIFICATION_CENTER_USER_MESSAGE_TEPMAPLTES[topic];

    if (topic === NOTIFICATION_CENTER_TOPICS.DASHBOARD_INVITE) {
      return userMessageTemplate
        .replace(DASHBOARD_INVITED_BY_USER_PLACEHOLDER, data.inviterName.split(" ").at(0))
        .replace(DASHBOARD_NAME_PLACEHOLDER, data.dashboardName);
    }

    const appName = data?.stationName || stations.find(station => station.id === data?.stationId)?.name || "N/A";
    return userMessageTemplate.replace(APPLICATION_NAME_PLACEHODER, appName);
  }, [data, stations, topic]);

  const elapsedTimePretty = useMemo(() => {
    const todayMoment = dayjs();
    const createdDateMoment = dayjs(createdAt);
    let diff = todayMoment.diff(createdDateMoment, "days");
    if (diff >= 1) return diff + " days ago";
    diff = todayMoment.diff(createdDateMoment, "hours");
    if (diff >= 1) return diff + " hours ago";
    return Math.ceil(todayMoment.diff(createdDateMoment, "minutes")) + " min ago";
  }, [notification]);

  const notificationCenterUserMessage = useMemo(() => {
    let errorLabelText = "";
    let errorLabel = null;
    if (type === NOTIFICATION_TYPES.WARNING) {
      errorLabelText = NOTIFICATION_CENTER_USER_MESSAGE_ERROR_LABEL_TEXT + " ";
      errorLabel = <StyledNotificationCenterUserWarningText>{errorLabelText}</StyledNotificationCenterUserWarningText>;
    }

    let userMessage = buildNotificationCenterUserMessage(notification.topic) || `missing ${topic} msg`;

    return (
      <Text lineClamp={2} lineSize={"18px"} style={{ verticalAlign: "top" }}>
        {errorLabel}
        {userMessage}
      </Text>
    );
  }, [notification]);

  const handleUserActionClick = useCallback(() => {
    setIsNotificationCenterOpen(false);

    switch (topic) {
      case NOTIFICATION_TOPICS.DASHBOARD_INVITE:
        acceptDashboardInvitationRequest.mutate(notification.id);
        break;
      default: {
        setApplicationFeedFocusNotificationId(id);

        const stationsUrl =
          topic === NOTIFICATION_TOPICS.FILE_UPLOADED
            ? `/dashboard/onboarding/stations/${data.stationId}/blocks/${data.blockId}/regular`
            : `/dashboard/stations/${data.stationId}`;

        history.push(stationsUrl);
        break;
      }
    }
  }, [data, topic]);

  const handleDismiss = useCallback(() => {
    setDisableDismissButton(true);
    dispatch(
      updateWebNotificationRequestAction(notification.id, {
        userId,
        active: false,
        hideUntil: null
      })
    );
  }, [userId, notification, disableDismissButton]);

  const isButtonEnabled = useMemo(
    () =>
      topic === NOTIFICATION_TOPICS.DASHBOARD_INVITE ? Object.keys(dashboards).length < MAX_DASHBOARDS_PER_USER : true,
    [dashboards, topic]
  );

  const disabledButtonTooltipText = useMemo(
    () => (topic === NOTIFICATION_TOPICS.DASHBOARD_INVITE ? MAX_DASHBOARDS_PER_USER_TOOLTIP_TEXT : ""),
    [topic]
  );

  return (
    <Grid data-testid={notification.id}>
      <Grid className={cn(classes.cardItem)} container>
        {
          <Grid className={classes.topCardPart}>
            {notificationItemTopicToIcon(topic)}
            <Grid className={classes.content}>{notificationCenterUserMessage}</Grid>
            {type !== NOTIFICATION_TYPES.WARNING && (
              <Grid>
                <StyledDismissNotificationIconButton
                  disableRipple
                  pointerEvents={disableDismissButton ? "none" : "auto"}
                  cursor={disableDismissButton ? "none" : "pointer"}
                  onClick={handleDismiss}
                >
                  <DismissNotificationIcon fill={vantiColors.gray19} />
                </StyledDismissNotificationIconButton>
              </Grid>
            )}
          </Grid>
        }
        <DarkToolTip title={disabledButtonTooltipText} open={isButtonHovered && !isButtonEnabled}>
          <Grid
            className={cn(classes.bottomCardPart)}
            container
            onMouseEnter={() => setIsButtonHovered(true)}
            onMouseLeave={() => setIsButtonHovered(false)}
          >
            <StyledNotificationUserActionButton
              onClick={handleUserActionClick}
              disableRipple
              disabled={!isButtonEnabled}
            >
              {notificationItemTopicToButtonText(topic)}
            </StyledNotificationUserActionButton>
            <Text color={vantiColors.lightGray6} size={"12px"}>
              {elapsedTimePretty}
            </Text>
          </Grid>
        </DarkToolTip>
      </Grid>
      {renderDivider && <NotificationItemDivider />}
    </Grid>
  );
};

export default memo(AccountNotificationsItem);
