import React, { memo } from "react";
import { Text } from "components/ui";
import { styled } from "@material-ui/core";

const CellText = ({ children }) => {
  return <StyledText>{children}</StyledText>;
};

const StyledText = styled(Text)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  color: "inherit !important"
});

export default memo(CellText);
