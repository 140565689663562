import React, { memo, useState } from "react";
import isEmpty from "lodash/isEmpty";

import ChipsInput from "components/ui/Inputs/ChipsInput/ChipsInput";

import { KEY_CODES } from "common/constants/CommonConstants";

import useStyles from "./styles";

const DataSourceMultiInput = ({ field, onChange, values }) => {
  const classes = useStyles();

  const [currentStorageItem, setCurrentStorageItem] = useState("");
  const [newStorageItems, setNewStorageItems] = useState(values[field.key] || []);

  const onAddChip = fieldKey => {
    if (!isEmpty(currentStorageItem) && !newStorageItems.includes(currentStorageItem)) {
      setNewStorageItems(state => {
        const updatedState = [...state, currentStorageItem];
        onChange(updatedState, fieldKey);
        return updatedState;
      });
      setCurrentStorageItem("");
    }
  };

  const onRemoveStorageItem = (storageItem, fieldKey) => {
    setNewStorageItems(state => {
      const filteredState = state.filter(item => item !== storageItem);
      onChange(filteredState, fieldKey);
      return filteredState;
    });
  };

  const onChangeCurrentStorageItem = e => {
    setCurrentStorageItem(e.target.value);
  };

  const onStorageItemKeyDown = (e, fieldKey) => {
    if ([KEY_CODES.ENTER, KEY_CODES.TAB, KEY_CODES.SPACE].includes(e.keyCode)) {
      onAddChip(fieldKey);
    }
  };

  return (
    <ChipsInput
      currentValue={currentStorageItem}
      values={newStorageItems}
      onChipRemove={value => onRemoveStorageItem(value, field.key)}
      formControlProps={{
        fullWidth: true,
        className: classes.formControl
      }}
      inputProps={{
        onChange: onChangeCurrentStorageItem,
        onKeyDown: e => onStorageItemKeyDown(e, field.key),
        className: classes.multiInput,
        "data-testid": field.title
      }}
    />
  );
};

export default memo(DataSourceMultiInput);
