import React, { memo, useEffect } from "react";
import styles from "./styles";

const LeftBorderDynamicWrapper = ({ children, active, onMount, onReturn, ...props }) => {
  const classes = styles({ active });

  useEffect(() => {
    return () => {
      if (onReturn) onReturn();
    };
  }, []);

  useEffect(() => {
    if (onMount) onMount();
  }, []);

  return (
    <div className={classes.root} {...props}>
      {children}
    </div>
  );
};

export default memo(LeftBorderDynamicWrapper);
