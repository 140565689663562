import React, { memo, useCallback, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Grid, styled } from "@material-ui/core";

import { FlexItem } from "components/ui/index";
import DynamicTabs from "components/ui/Tabs";
import AccountNotificationsItem from "components/ui/WebNotificationCenter/AccountNotificationsItem/AccountNotificationsItem";
import EmptyAccountNotificationsItem from "components/ui/WebNotificationCenter/AccountNotificationsItem/EmptyAccountNotificationsItem";

import { uidSelector } from "modules/auth/state/selectors";

import { NOTIFICATION_TOPICS, NOTIFICATION_TYPES } from "common/constants/NotificationConstants";
import { AppNotificationsContext } from "common/hooks/context-hooks/use-notifications-context";

import useStyles from "./AccountNotificationsActive.style";
import useDashboardRequest from "common/hooks/use-dashboard-request";

const TAB_TITLES = {
  TAB_TITLE_ALL: "All",
  TAB_TITLE_CRITICAL_ERRORS: "Errors"
};

const StyledNotificationCardsWrapper = styled(Grid)(({ theme }) => ({
  overflowX: "hidden",
  overflowY: "overlay",
  "&::-webkit-scrollbar": {
    width: theme.spacing(2),
    backgroundColor: "transparent"
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.vantiColors.lightGray1,
    borderRadius: theme.spacing(1),
    border: `${theme.spacing(0.5)}px solid rgba(0, 0, 0, 0)`,
    backgroundClip: "padding-box"
  },
  maxHeight: "704px",
  [theme.breakpoints.down("1600")]: {
    maxHeight: "600px"
  },
  [theme.breakpoints.down("1441")]: {
    maxHeight: "420px"
  }
}));

const AccountNotificationsActive = ({ activeNotificationList }) => {
  const classes = useStyles();
  const userId = useSelector(uidSelector);
  const { selectedNotificationCenterSecondaryTabIndex, setSelectedNotificationCenterSecondaryTabIndex } = useContext(
    AppNotificationsContext
  );

  const { getDashboardsRequest } = useDashboardRequest();

  useEffect(() => {
    if (activeNotificationList.some(n => n.topic === NOTIFICATION_TOPICS.DASHBOARD_INVITE)) {
      getDashboardsRequest.mutate();
    }
  }, [activeNotificationList]);

  //in case of insight notifications, only render the first and most recent INSIGHT notification for each app
  const shouldRenderNotification = useCallback(
    notification => {
      const INSIGHTS_TOPICS = [
        NOTIFICATION_TOPICS.INSIGNIFICANT_FEATURES,
        NOTIFICATION_TOPICS.SIGNIFICANT_FEATURES,
        NOTIFICATION_TOPICS.TIME_SERIES,
        NOTIFICATION_TOPICS.THRESHOLD,
        NOTIFICATION_TOPICS.EXPLAINABILITY
      ];

      if (!INSIGHTS_TOPICS.includes(notification.topic)) {
        return true;
      }

      const insightsNotifications = activeNotificationList.filter(n => INSIGHTS_TOPICS.includes(n.topic));
      const stationInsightsNotifications = insightsNotifications.filter(
        n => n.data?.stationId === notification.data?.stationId
      );

      return stationInsightsNotifications[0].id === notification.id;
    },
    [activeNotificationList]
  );

  const handleChange = useCallback((event, newValue) => {
    setSelectedNotificationCenterSecondaryTabIndex(newValue);
  }, []);

  const tabContent = useMemo(() => {
    let list = activeNotificationList.filter(
      notification =>
        notification.usersInfo[userId]?.active === true ||
        (notification.usersInfo[userId] === undefined && notification.topic !== NOTIFICATION_TOPICS.DASHBOARD_INVITE)
    );
    const tabTitleValue = TAB_TITLES[Object.keys(TAB_TITLES)[selectedNotificationCenterSecondaryTabIndex]];

    if (tabTitleValue === TAB_TITLES.TAB_TITLE_CRITICAL_ERRORS) {
      list = list.filter(n => n.type === NOTIFICATION_TYPES.WARNING);
    }

    return list.length ? (
      <StyledNotificationCardsWrapper>
        {list.map((notification, i) => {
          if (!Object.values(NOTIFICATION_TOPICS).includes(notification.topic)) {
            return null;
          }

          return (
            shouldRenderNotification(notification) && (
              <AccountNotificationsItem
                key={notification.id}
                notification={notification}
                renderDivider={list.length > 1 && i < list.length - 1}
              />
            )
          );
        })}
      </StyledNotificationCardsWrapper>
    ) : (
      <EmptyAccountNotificationsItem />
    );
  }, [selectedNotificationCenterSecondaryTabIndex, activeNotificationList, shouldRenderNotification]);

  return (
    <FlexItem container dense={"full"}>
      <DynamicTabs
        data-testid={"all-account-notifications-tabs"}
        tabProps={{ classes: { root: classes.tabRoot } }}
        tabsProps={{ classes: { indicator: classes.tabsIndicator, flexContainer: classes.tabsFlexContainer } }}
        tabs={Object.values(TAB_TITLES)}
        selectedTabIndex={selectedNotificationCenterSecondaryTabIndex}
        handleChange={handleChange}
      />
      {tabContent}
    </FlexItem>
  );
};

export default memo(AccountNotificationsActive);
