import React from "react";

import Lottie from "react-lottie-player";

import FlexContainer from "components/ui/Flex/FlexContainer.js";
import FlexItem from "components/ui/Flex/FlexItem.js";
import Text from "components/ui/Typography/Text";
import Button from "components/ui/Buttons/Button.js";

import { MAIL_LINKS } from "../constants";

import { ReactComponent as VantiFullLogo } from "assets/icons/VantiFullLogo.svg";
import AppleMailIcon from "assets/img/icons/apple-mail-icon.png";
import OutlookIcon from "assets/img/icons/outlook-icon.png";
import ConfettiAnimation from "assets/animations/Confetti.json";
import GmailIcon from "assets/img/icons/gmail-icon.png";

import { vantiColors } from "assets/jss/palette.js";

import useStyles from "./styles";

const CreationCompletedPage = ({ accountName, emailAddress }) => {
  const classes = useStyles();

  return (
    <FlexContainer data-testid="creation-completed-page" item justifyContent="center" dense="full">
      <FlexItem container xs={8} dense="full" className={classes.innerContainer}>
        <FlexItem xs={12} dense="full">
          <VantiFullLogo />
        </FlexItem>

        <FlexItem xs={12} dense="vertical" className={classes.subText}>
          <Text size={14} weight="bold">
            Welcome to Vanti
          </Text>
        </FlexItem>

        <FlexItem xs={12} dense="vertical">
          <Text size={70} weight="semi">
            Congratulations
          </Text>
        </FlexItem>

        <FlexItem xs={12} dense="vertical" className={classes.lottieTextContainer}>
          <Lottie loop animationData={ConfettiAnimation} play className={classes.lottiePlayer} />
          <Text size={70} weight="semi" color={vantiColors.green1}>
            {accountName}
          </Text>

          <br />

          <Text size={70} weight="semi" color={vantiColors.green1}>
            🎉
          </Text>
        </FlexItem>

        <FlexItem xs={12} dense="vertical">
          <Text size={30} weight="semi">
            Please check your email
          </Text>
        </FlexItem>

        <FlexItem container xs={12} dense="vertical" className={classes.emailDescriptionSection} direction="column">
          <FlexItem dense="full">
            <Text size={14}>Your account has been successfully registered with Vanti.</Text>
          </FlexItem>

          <FlexItem dense="full">
            <Text size={14}>
              To start using Vanti’s web application, check your email inbox <Text weight="medium">{emailAddress}</Text>
            </Text>
          </FlexItem>

          <FlexItem dense="full">
            <Text size={12}>Please check your spam folder if you don&apos;t receive it right away.</Text>
          </FlexItem>
        </FlexItem>

        <FlexItem container justifyContent="flex-start" dense="full" className={classes.buttonsContainer}>
          <FlexItem dense="vertical">
            <Button
              fullWidth
              transparent={true}
              className={classes.button}
              onClick={() => {
                window.open(MAIL_LINKS.GMAIL);
              }}
            >
              <img className={classes.icon} src={GmailIcon} />
              <Text size={14}>Open Gmail</Text>
            </Button>
          </FlexItem>

          <FlexItem dense="vertical">
            <Button
              fullWidth
              transparent={true}
              className={classes.button}
              onClick={() => {
                window.open(MAIL_LINKS.OUTLOOK);
              }}
            >
              <img className={classes.icon} src={OutlookIcon} />
              <Text size={14}>Open Outlook</Text>
            </Button>
          </FlexItem>

          <FlexItem dense="vertical">
            <Button
              fullWidth
              transparent={true}
              className={classes.button}
              onClick={() => {
                window.open(MAIL_LINKS.APPLE_MAIL);
              }}
            >
              <img className={classes.icon} src={AppleMailIcon} />
              <Text size={14}>Open Apple mail</Text>
            </Button>
          </FlexItem>
        </FlexItem>
      </FlexItem>
    </FlexContainer>
  );
};

export default CreationCompletedPage;
