export const actionTypes = {
  USER_SIGN_IN_CLEAR: "user.signInByEmail.clear",
  USER_SIGN_IN_REQUEST: "user.signInByEmail.request",
  USER_SIGN_IN_SUCCESS: "user.signInByEmail.success",
  USER_SIGN_IN_ERROR: "user.signInByEmail.error",
  USER_SIGN_IN_FULFILL: "user.signInByEmail.fulfill"
};

export function signInClearAction() {
  return { type: actionTypes.USER_SIGN_IN_CLEAR, payload: {} };
}

export function signInRequestAction(email, password) {
  return { type: actionTypes.USER_SIGN_IN_REQUEST, payload: { email, password } };
}