import React, { memo } from "react";

const DotIcon = ({ color = "#31CB7D", width = 4, height = 4, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 4 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="2" cy="2" r="2" fill={color} />
  </svg>
);

export default memo(DotIcon);
