import { vantiColors } from "assets/jss/vanti-dashboard";
import { MODEL_STATE } from "@vanti-analytics-org/vanti-common";

export { STATION_STATE as STATION_STATES } from "@vanti-analytics-org/vanti-common";

export const BLOCK_DB_STATES = MODEL_STATE;

export const blockModelIntent = {
  SupervisedModel: "BUILD_A_NEW_MODEL",
  UnsupervisedModel: "BUILD_AN_UNSUPERVISED_MODEL",
  ProcessTuningModel: "BUILD_A_REGRESSION_MODEL",
  ExistingModel: "BUILD_BY_EXISTING_MODEL",
  CustomModel: "BUILD_A_CUSTOM_MODEL"
};

export const SERVER_STATUS_LABELS = {
  [MODEL_STATE.DRAFT]: {
    text: "DRAFT",
    backgroundColor: vantiColors.gray19,
    textColor: "white",
    lowercaseText: "Draft"
  },
  [MODEL_STATE.IN_ANALYSIS]: {
    text: "TRAINING",
    backgroundColor: vantiColors.blue6,
    textColor: "white",
    lowercaseText: "Training"
  },
  [MODEL_STATE.READY]: {
    text: "READY",
    backgroundColor: vantiColors.purple2,
    textColor: "white",
    lowercaseText: "Ready"
  },
  [MODEL_STATE.ACTIVE]: {
    text: "LIVE",
    backgroundColor: vantiColors.green1,
    textColor: "white",
    lowercaseText: "Live"
  },
  [MODEL_STATE.DEPLOYED]: {
    text: "LIVE",
    backgroundColor: vantiColors.green1,
    textColor: "white",
    lowercaseText: "Live"
  },
  [MODEL_STATE.FAILED_ANALYSIS]: {
    text: "ERROR",
    backgroundColor: vantiColors.rose6,
    textColor: "white",
    lowercaseText: "Error"
  },
  [MODEL_STATE.FAILED_DEPLOYMENT]: {
    text: "ERROR",
    backgroundColor: vantiColors.rose6,
    textColor: "white",
    lowercaseText: "Error"
  },
  [MODEL_STATE.IN_DEPLOYMENT]: {
    text: "DEPLOYING",
    backgroundColor: vantiColors.green1,
    textColor: "white",
    lowercaseText: "Deploying"
  },
  [MODEL_STATE.PENDING]: {
    text: "PENDING",
    backgroundColor: vantiColors.blue7,
    textColor: "white",
    lowercaseText: "Pending"
  },
  [MODEL_STATE.UPLOADING]: {
    text: "UPLOADING",
    backgroundColor: vantiColors.gray24,
    textColor: "white",
    lowercaseText: "Uploading"
  },
  [MODEL_STATE.UPLOADED]: {
    text: "UPLOADED",
    backgroundColor: vantiColors.gray24,
    textColor: "white",
    lowercaseText: "Uploaded"
  }
};
