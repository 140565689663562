import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";
import AutoAwesomeIcon24 from "assets/icons/auto-awesome/AutoAwesomeIcon24";
import { Text } from "components/ui";
import { styled } from "@material-ui/core";

const InlineButtonFollowupQuestionWrapper = styled("div")({
  height: "100%",
  cursor: "pointer",
  padding: 16,
  display: "flex",
  alignItems: "flex-start",
  flexShrink: 0,
  justifyContent: "flex-start",
  gap: 16,
  border: `1px solid ${vantiColors.lightGray13}`,
  borderRadius: 4,
  boxSizing: "border-box",
  transition: "all 0.3s ease-out;",
  "&:hover": {
    backgroundColor: vantiColors.gray18
  },
  "&:active": {
    transition: "none",
    backgroundColor: vantiColors.lightGray12
  }
});

const ChatMessageInlineButtonFollowupQuestion = ({ title, onClickInlineButton }) => {
  return (
    <InlineButtonFollowupQuestionWrapper onClick={onClickInlineButton}>
      <div>
        <AutoAwesomeIcon24 />
      </div>
      <div>
        <Text size={16} lineSize={"normal"} color={vantiColors.gray39}>
          {title}
        </Text>
      </div>
    </InlineButtonFollowupQuestionWrapper>
  );
};

export default memo(ChatMessageInlineButtonFollowupQuestion);
