import React, { memo } from "react";

const ChartDownIcon24 = ({ ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <mask
        id="mask0_1754_3009"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1754_3009)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.1576 16.8705L20.2196 16.3743L20.7196 12.3743L19.7273 12.2503L19.3664 15.1377L14.1355 7.52906L13.8471 7.10957L13.4328 7.40546L10.3181 9.63026L6.61388 4.99998L5.83301 5.62467L9.83301 10.6247L10.1288 10.9944L10.5141 10.7192L13.5998 8.51508L18.5097 15.6567L15.7855 15.3162L15.6614 16.3085L19.6614 16.8085L20.1576 16.8705ZM3 5.05808V19.0581V19.5581H3.5H21.5V18.5581H4V5.05808H3Z"
          fill="#FF3C78"
        />
      </g>
    </svg>
  );
};

export default memo(ChartDownIcon24);
