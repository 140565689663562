import React, { memo, useCallback, useContext, useMemo } from "react";
import { ChatButtonActionType, ROLE } from "@vanti-analytics-org/vanti-common";
import ChatMessageInlineButtonFollowupQuestion from "./ChatMessageInlineButtonFollowupQuestion";
import ChatMessageInlineButtonDatasetSelection from "./ChatMessageInlineButtonDatasetSelection";
import usePermissions from "common/hooks/use-permissions";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import useChatMessages from "common/hooks/use-chat-messages";

const ChatMessageInlineButton = ({
  chatId,
  title,
  buttonAction,
  isButtonDisabled,
  isButtonSelected,
  onClickCallback
}) => {
  const { chatContext } = useContext(AppContext);
  const { setChatInputEnabled, setDisplayOtherDatasets } = chatContext;

  const { sendChatMessage } = useChatMessages();
  const action = useMemo(() => buttonAction.split("/")[0], [buttonAction]);

  const canUseSuggestions = usePermissions([ROLE.USE_CHAT_SUGGESTIONS]);
  const canSendMessages = usePermissions([ROLE.SEND_CHAT_MESSAGE]);

  const onClickInlineButton = useCallback(async () => {
    if (isButtonDisabled) return;

    switch (action) {
      case ChatButtonActionType.SHOW_DATASET_SELECTION_UI:
        setDisplayOtherDatasets(true);
        break;

      case ChatButtonActionType.CHOOSE_REPORT_FOLLOWUP_QUESTION:
        if (canUseSuggestions) {
          await sendChatMessage(title, chatId, buttonAction);
        }
        break;

      case ChatButtonActionType.USE_DATASET:
        if (!canSendMessages) return;
        if (onClickCallback && typeof onClickCallback === "function") onClickCallback();
        setChatInputEnabled(false);
        await sendChatMessage(title, chatId, buttonAction);
        break;

      default:
        break;
    }
  }, [chatId, title, buttonAction, isButtonDisabled, action, onClickCallback, sendChatMessage]);

  switch (action) {
    case ChatButtonActionType.CHOOSE_REPORT_FOLLOWUP_QUESTION:
      return <ChatMessageInlineButtonFollowupQuestion title={title} onClickInlineButton={onClickInlineButton} />;

    case ChatButtonActionType.SHOW_DATASET_SELECTION_UI:
    case ChatButtonActionType.USE_DATASET:
      return (
        <ChatMessageInlineButtonDatasetSelection
          title={title}
          onClickInlineButton={onClickInlineButton}
          isButtonDisabled={isButtonDisabled}
          isButtonSelected={isButtonSelected}
        />
      );

    default:
      return null;
  }
};

export default memo(ChatMessageInlineButton);
