import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { vantiColors } from "assets/jss/palette";
import SuccessIcon24 from "assets/icons/success/SuccessIcon24";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton";

const USER_CONFIRMATION_WARNING_MESSAGE_TEXT =
  "Are you sure you want to change the industry semantic info?" +
  "This action may impact chat results in the entire account.";

const USER_CONFIRMATION_UPDATE_SUCCESS_MESSAGE_TEXT = "Changes saved";
const USER_SAVE_BUTTON_TEXT = "Save";
const USER_CONFIRMATION_BUTTON_TEXT = "Confirm";

const SemanticInfoUserActionSectionContainer = styled("div")(({ showBackgroundColor, startFadeoutAnimation }) => ({
  width: "100%",
  height: 50,
  marginTop: 4,
  padding: "9px 8px",
  boxSizing: "border-box",
  backgroundColor: showBackgroundColor ? vantiColors.lightBlue10 : "transparent",
  display: "flex",
  justifyContent: "space-between",
  opacity: startFadeoutAnimation ? 0 : 1,
  transition: "opacity 0.75s"
}));

const UserConfirmationWarningMessage = styled(Text)({
  width: 409,
  boxSizing: "border-box"
});

const UserConfirmationSuccessMessage = styled("div")({
  height: 32,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 8
});

const SemanticInfoUserActionSection = ({ onSave, onSaveRequiresConfirmation, hasTextChanged }) => {
  const [isClickedSave, setIsClickedSave] = useState(false);
  const [isClickedConfirm, setIsClickedConfirm] = useState(false);
  const [startFadeoutAnimation, setStartFadeoutAnimation] = useState(false);

  const onConfirmHandler = useCallback(() => {
    setIsClickedConfirm(true);
    setIsClickedSave(false);
    onSave();
  }, [onSave]);

  const onSaveHandler = useCallback(() => {
    if (onSaveRequiresConfirmation) {
      setIsClickedSave(true);
    } else {
      onConfirmHandler();
    }
  }, [onSaveRequiresConfirmation, onConfirmHandler]);

  const onCancelHandler = useCallback(() => setIsClickedSave(false), []);

  useEffect(() => {
    if (isClickedConfirm) {
      setTimeout(() => {
        setStartFadeoutAnimation(true);
      }, 1500);
    }
  }, [isClickedConfirm]);

  const userConfirmationMessage = useMemo(() => {
    if (isClickedSave) {
      return (
        <UserConfirmationWarningMessage color={vantiColors.gray29} lineSize={"16.94px"}>
          {USER_CONFIRMATION_WARNING_MESSAGE_TEXT}
        </UserConfirmationWarningMessage>
      );
    }

    if (isClickedConfirm) {
      return (
        <UserConfirmationSuccessMessage>
          <SuccessIcon24 />
          <Text color={vantiColors.gray29} lineSize={"16.94px"}>
            {USER_CONFIRMATION_UPDATE_SUCCESS_MESSAGE_TEXT}
          </Text>
        </UserConfirmationSuccessMessage>
      );
    }

    return <div style={{ width: "100%" }} />; //space filler placeholder
  }, [isClickedSave, isClickedConfirm]);

  const userActionButtonSection = useMemo(() => {
    return (
      !isClickedConfirm && (
        <div>
          {isClickedSave && (
            <GeneralVantiButton
              buttonInfo={{
                onClick: onCancelHandler,
                label: "Cancel"
              }}
              size={SIZES.SMALL}
              variant={VARIANTS.TEXT_LINK}
            />
          )}
          <GeneralVantiButton
            buttonInfo={{
              onClick: isClickedSave ? onConfirmHandler : onSaveHandler,
              label: isClickedSave ? USER_CONFIRMATION_BUTTON_TEXT : USER_SAVE_BUTTON_TEXT,
              disabled: !hasTextChanged
            }}
            size={SIZES.SMALL}
          />
        </div>
      )
    );
  }, [isClickedSave, isClickedConfirm, onConfirmHandler, onSaveHandler, onCancelHandler, onSaveRequiresConfirmation]);

  return (
    <SemanticInfoUserActionSectionContainer
      showBackgroundColor={isClickedSave || isClickedConfirm}
      startFadeoutAnimation={startFadeoutAnimation}
      onTransitionEnd={event => {
        if (event.propertyName === "opacity") {
          setStartFadeoutAnimation(false);
          setIsClickedConfirm(false);
          setIsClickedSave(false);
        }
      }}
    >
      {userConfirmationMessage}
      {userActionButtonSection}
    </SemanticInfoUserActionSectionContainer>
  );
};

export default memo(SemanticInfoUserActionSection);
