import React, { memo } from "react";

import Text from "components/ui/Typography/Text";
import { vantiColors } from "assets/jss/palette";

const ProfileInfo = ({ email, account }) => (
  <>
    <Text h={3} data-testid="verify-email-title">
      Welcome to your{" "}
      <Text h={3} color={vantiColors.green1}>
        {account}
      </Text>{" "}
      <Text h={3}>account</Text>
    </Text>

    <Text
      color={vantiColors.darkBlue1}
      body={1}
      weight="bold"
      style={{ paddingTop: "8px", paddingBottom: 16 }}
      data-testid="verify-email-description"
    >
      Let&apos;s create your profile {email && "|"}{" "}
      {email && (
        <Text color={vantiColors.lightGray6} size="16px" weight="bold">
          {email}
        </Text>
      )}
    </Text>
  </>
);

export default memo(ProfileInfo);
