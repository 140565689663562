import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  modalHeader: {
    padding: "10px 15px"
  }
}));

export default function ModalHeader(props) {
  const classes = useStyles();
  const { className, children, ...rest } = props;
  const modalHeaderClasses = classNames({
    [classes.modalHeader]: true,
    [className]: className !== undefined
  });
  return (
    <div className={modalHeaderClasses} {...rest}>
      {children}
    </div>
  );
}

ModalHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
