import React, { memo } from "react";
import BodyCell from "../../table-body-rows/body-cell/BodyCell";
import EllipsisText from "components/ui/EllipsisText/EllipsisText";
import useStyles from "./styles";
import { vantiColors } from "assets/jss/palette";

const StationNameCell = ({ station }) => {
  const classes = useStyles();

  return (
    <BodyCell data-testid={`${station?.name}-station-name`} component="th" scope="row">
      <div className={classes.stationName}>
        <EllipsisText tooltipText={station.name} color={vantiColors.gray39} size={16} weight={500}>
          {station.name}
        </EllipsisText>
      </div>
    </BodyCell>
  );
};

export default memo(StationNameCell);
