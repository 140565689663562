import React, { memo, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { FlexItem, Text } from "components/ui";
import GeneralVantiButton from "components/ui/Buttons/GeneralVantiButton";
import usePermissions from "common/hooks/use-permissions";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import { CONNECTORS_TEXT, NEW_CONNECTOR_TEXT } from "pages/side-bar/data-connectors/constants";

const Header = () => {
  const history = useHistory();
  const isGrantedToManageDc = usePermissions([ROLES_ENUM.MANAGE_DATA_CONNECTORS]);

  const newDataConnectorButtonInfo = useMemo(() => {
    return {
      label: NEW_CONNECTOR_TEXT,
      onClick: () => {
        history.push("/dashboard/data-connectors/create");
      },
      disabled: !isGrantedToManageDc
    };
  }, [isGrantedToManageDc]);

  return (
    <FlexItem
      container
      justifyContent="space-between"
      alignItems="center"
      dense="full"
      data-testid="data-connectors-header-section"
    >
      <FlexItem dense="full">
        <Text data-testid="data-connectors-title" size="24px" weight="bold">
          {CONNECTORS_TEXT}
        </Text>
      </FlexItem>

      <FlexItem dense="full">
        <GeneralVantiButton data-testid="data-connectors-btn" buttonInfo={newDataConnectorButtonInfo} />
      </FlexItem>
    </FlexItem>
  );
};

export default memo(Header);
