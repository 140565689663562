import React, { memo, useEffect, useMemo, useState } from "react";
import { FlexItem } from "components/ui";
import TextUnderlinedInput from "components/ui/Inputs/TextUnderlinedInput";
import { InputAdornment } from "@material-ui/core";
import classNames from "classnames";
import styles from "../../../../styles";

const CurationNameInput = ({ numOfCurations, existingName, onSubmit, onBlur, ...props }) => {

  const classes = styles();

  const [curationCount, setCurationCount] = useState(numOfCurations + 1);
  const [curationName, setCurationName] = useState('');
  const isCurationNameValid = useMemo(() => curationName.trim(), [curationName]);

  useEffect(() => {
      setCurationName(`Curated_dataset_${curationCount}`);
  }, []);

  const generateCurationName = () => {
      setCurationCount(curationCount + 1);
      setCurationName(`Curated_dataset_${curationCount + 1}`);
  };

  useEffect(() => {
    if (existingName) {
      setCurationName(existingName);
    }
  }, [existingName]);

  return (
    <FlexItem container dense="full" {...props} data-testid="curation-name-section">
      <TextUnderlinedInput
        id="data-curation-name"
        dataTestId="data-curation-name-input"
        formControlProps={{ fullWidth: true, margin: "dense", className: classes.denseInput }}
        labelProps={{ shrink: true }}
        inputProps={{
          value: curationName,
            onChange: e => {
            setCurationName(e.target.value);
          },
          onKeyDown: e => {
            if (e.keyCode === 13) {
              onSubmit(e.target.value);
            }
          },
          onBlur: e => {
            if (onBlur) onBlur(e.target.value);
          },
          endAdornment: (
            <InputAdornment
              position="end"
              className={classNames(classes.nameNextIconButton, {
                [classes.nameNextIconButtonDisabled]: !isCurationNameValid
              })}
            />
          )
        }}
      />
    </FlexItem>
  );
};

export default memo(CurationNameInput);
