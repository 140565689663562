import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";

import * as modules from "modules";
import authReducer from "modules/auth";
import accountReducer from "modules/account";
import blockReducer from "modules/block";
import userReducer from "modules/user";
import stationReducer from "modules/station";
import sandboxReducer from "modules/sandbox";
import onboardingReducer from "modules/onboarding";
import predictionReducer from "modules/prediction";
import navigationReducer from "modules/navigation";
import dataConnectorsReducer from "modules/data-connectors";
import webNotificationsReducer from "modules/web-notifications";
import dataCurationsReducer from "modules/data-curations";
import config from "common/config";

let composeEnhancers = compose;

if (process && config.nodeEnv === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

const sagaMiddleware = createSagaMiddleware();

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    account: accountReducer,
    user: userReducer,
    block: blockReducer,
    station: stationReducer,
    onboarding: onboardingReducer,
    prediction: predictionReducer,
    sandbox: sandboxReducer,
    navigation: navigationReducer,
    dataConnectors: dataConnectorsReducer,
    webNotifications: webNotificationsReducer,
    dataCurations: dataCurationsReducer
  });

export const history = createBrowserHistory();
const isDevMode = config.nodeEnv === "development" || config.forceDev === true;

const middlewares = [sagaMiddleware, routerMiddleware(history)];

const store = createStore(
  createRootReducer(history),
  composeEnhancers(
    applyMiddleware(...middlewares),
    isDevMode && window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

sagaMiddleware.run(modules.sagas);
export default store;
