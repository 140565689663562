import { call, put, takeLatest } from "redux-saga/effects";
import { getAllCurations } from "services/api/dataCuration";
import { dataCurationsFlagTypes } from "./actionTypes";

function* fetchCurationsSaga() {
  try {
    const allDataCurations = yield call(getAllCurations);
    yield put({ type: dataCurationsFlagTypes.SET_SUCCESS, payload: allDataCurations });
  } catch (error) {
    yield put({ type: dataCurationsFlagTypes.SET_ERROR, payload: error });
  }
}

export function* fetchCurationsWatcher() {
  yield takeLatest(dataCurationsFlagTypes.START_FETCH, fetchCurationsSaga);
}
