import { createSelector } from "reselect";

export const navigationSelector = state => state.navigation.state;

export const navigationBreadcrumbsTypeSelector = createSelector(
  navigationSelector,
  state => state.breadcrumbs.type
);

export const navigationPreviousLocationSelector = createSelector(
  navigationSelector,
  state => state.previousLocation
);
