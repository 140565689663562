import React, { memo, useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import uuid from "uuid";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";

import useStyles from "./styles";
import { roseColor, vantiColors } from "assets/jss/palette";

const UnderlinedInput = ({ formControlProps = {}, label, labelProps = {}, inputProps, helpLabel, color, required }) => {
  const classes = useStyles();

  const id = useMemo(() => inputProps.id || uuid(), [inputProps.id]);

  return (
    <FormControl {...formControlProps} className={classNames(classes.formControl, formControlProps.className)}>
      {label && (
        <InputLabel className={classes.label} htmlFor={id} {...labelProps}>
          {required && <span className={classes.required}>* </span>}
          {label}
        </InputLabel>
      )}
      <Input
        classes={{
          root: classNames(classes.inputWrapper, {
            [classes.marginTop]: !label
          }),
          input: classes.input,
          disabled: classes.disabled,
          underline: classNames(classes.underline, { [classes[color + "Underline"]]: color })
        }}
        {...inputProps}
      />
      {helpLabel && (
        <FormHelperText
          id={`${id}-helper`}
          className={classes.helpLabel}
          style={{ color: helpLabel.color || vantiColors.rose6 }}
        >
          {helpLabel.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

UnderlinedInput.propTypes = {
  label: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.object,
  color: PropTypes.oneOf(["green"])
};

export default memo(UnderlinedInput);
