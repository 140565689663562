import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const PencilIcon24 = ({ stroke = vantiColors.gray39, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M17.9081 3.99998L21.4238 7.51575L9.59807 19.3415L6.40193 19.3415L6.08231 15.8257L17.9081 3.99998Z"
      stroke={stroke}
    />
  </svg>
);

export default memo(PencilIcon24);
