import React, { memo } from "react";

const UploadIconBG24 = () => {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.583008" width="40" height="40" rx="20" fill="#E6E7F6" />
      <path d="M26.083 27.5H15.083V12.5H22.3951L26.083 15.7269V27.5Z" stroke="#0313A4" strokeMiterlimit="10" />
      <path d="M22.083 12.5V16H25.583" stroke="#0313A4" />
      <path
        d="M20.5826 18.4998L20.9572 18.1689L20.581 17.7927L20.2064 18.1672L20.5826 18.4998ZM20.0675 24.4976L20.0675 24.9819L21.0979 24.9861L21.0979 24.5018L20.0675 24.4976ZM20.2064 18.1672L18.2064 20.1672L18.9587 20.8323L20.9587 18.8323L20.2064 18.1672ZM20.2079 18.8307L22.2081 20.8307L22.9574 20.1688L20.9572 18.1689L20.2079 18.8307ZM20.0673 18.4977L20.0675 24.4976L21.0979 24.5018L21.0978 18.5019L20.0673 18.4977Z"
        fill="#0313A4"
      />
    </svg>
  );
};

export default memo(UploadIconBG24);
