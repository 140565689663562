import React, { memo, useContext, useEffect, useMemo } from "react";
import { navigationSetBreadcrumbsType } from "modules/navigation/actions";
import { BREADCRUMBS_TYPES } from "common/constants/NavigationConstants";
import { useDispatch } from "react-redux";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { useParams } from "react-router-dom";
import DatasetQuestionsSection from "./DatasetQuestionsSection";
import { DatasetQuestionSource } from "@vanti-analytics-org/vanti-common";
import useDatasetQuestionRequest from "common/hooks/use-dataset-question-request";
import PlaygroundIcon24 from "assets/icons/playground-icon/PlaygroundIcon24";
import { vantiColors } from "assets/jss/palette";
import RegenerateQuestion from "./regenerate-questions/RegenerateQuestion";

const DATASET_QUESTIONS_TITLE_TEXT_PREFIX = "File name:";

const DatasetQuestionsContainer = styled("div")({
  padding: 16,
  rowGap: 16,
  display: "flex",
  flexDirection: "column",
  overflow: "auto"
});

const HeaderWrapperStyled = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: 40
});

const DatasetQuestionsSectionWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 8
});

const DatasetQuestionsPage = () => {
  const dispatch = useDispatch();
  const { datasetsContext } = useContext(AppContext);
  const { datasets, datasetQuestions } = datasetsContext;
  const { datasetId } = useParams();
  const { getQuestionRequest } = useDatasetQuestionRequest();

  useEffect(async () => {
    dispatch(navigationSetBreadcrumbsType(BREADCRUMBS_TYPES.NONE));
    if (!datasetQuestions[datasetId] && datasetId) {
      await getQuestionRequest.mutate({ datasetId });
    }
  }, [datasetId, datasetQuestions]);

  const dataset = useMemo(() => {
    return datasets[datasetId] || {};
  }, [datasets, datasetId]);

  const title = useMemo(() => [DATASET_QUESTIONS_TITLE_TEXT_PREFIX, dataset.name].join(" "), [dataset]);

  const customAccordionRootStyle = {
    backgroundImage: `linear-gradient(190deg, white, white, ${vantiColors.lightGray12})`
  };

  return (
    <DatasetQuestionsContainer>
      <HeaderWrapperStyled>
        <Text size={22} weight={600}>
          {title}
        </Text>
      </HeaderWrapperStyled>
      <DatasetQuestionsSectionWrapper>
        <DatasetQuestionsSection datasetId={datasetId} source={DatasetQuestionSource.Manual} />
        <DatasetQuestionsSection
          datasetId={datasetId}
          source={DatasetQuestionSource.VantiAI}
          CustomListHeaderRow={<RegenerateQuestion datasetId={datasetId} />}
          isQuestionInputEditable={false}
          displayNewQuestionInput={false}
        />
        <DatasetQuestionsSection
          datasetId={datasetId}
          source={DatasetQuestionSource.Playground}
          icon={<PlaygroundIcon24 />}
          isQuestionInputEditable={false}
          accordionRootStyle={customAccordionRootStyle}
        />
      </DatasetQuestionsSectionWrapper>
    </DatasetQuestionsContainer>
  );
};

export default memo(DatasetQuestionsPage);
