import React, { memo } from "react";
import { AppContext, useAppContext } from "./use-app-context";
import { ChatContext, useChatContext } from "./use-chat-context";
import { DashboardContext, useDashboardContext } from "./use-dashboard-context";
import { DatasetContext, useDatasetContext } from "./use-datasets-context";
import { AppNotificationsContext, useAppNotificationsContext } from "./use-notifications-context";
import { TreeViewContext, useTreeViewContext } from "./use-treeview-context";
import { UsersContext, useUsersContext } from "./use-users-context";
import { ReportsContext, useReportsContext } from "./use-reports-context";
import { DataEntitiesContext, useDataEntitiesContext } from "./use-data-entities-context";
import { TagsContext, useTagsContext } from "./use-tags-context";
import { FlowContext, useFlowContext } from "./use-flow-context";

const CombinedContextProviders = ({ children }) => {
  const appContext = useAppContext();
  const appNotificationsContext = useAppNotificationsContext();
  const treeViewContext = useTreeViewContext();
  const datasetContext = useDatasetContext();
  const chatContext = useChatContext();
  const userContext = useUsersContext();
  const dashboardContext = useDashboardContext();
  const reportsContext = useReportsContext();
  const dataEntitiesContext = useDataEntitiesContext();
  const tagsContext = useTagsContext();
  const flowContext = useFlowContext();

  return (
    <AppContext.Provider value={appContext}>
      <AppNotificationsContext.Provider value={appNotificationsContext}>
        <TreeViewContext.Provider value={treeViewContext}>
          <DatasetContext.Provider value={datasetContext}>
            <ChatContext.Provider value={chatContext}>
              <UsersContext.Provider value={userContext}>
                <DashboardContext.Provider value={dashboardContext}>
                  <ReportsContext.Provider value={reportsContext}>
                    <DataEntitiesContext.Provider value={dataEntitiesContext}>
                      <TagsContext.Provider value={tagsContext}>
                        <FlowContext.Provider value={flowContext}>{children}</FlowContext.Provider>
                      </TagsContext.Provider>
                    </DataEntitiesContext.Provider>
                  </ReportsContext.Provider>
                </DashboardContext.Provider>
              </UsersContext.Provider>
            </ChatContext.Provider>
          </DatasetContext.Provider>
        </TreeViewContext.Provider>
      </AppNotificationsContext.Provider>
    </AppContext.Provider>
  );
};

export default memo(CombinedContextProviders);
