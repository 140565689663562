import React, { memo, useEffect, useState } from "react";
import { FlexContainer, Text } from "components/ui";
import CheckboxInput from "components/ui/Inputs/CheckboxInput";
import { TAGS } from "../constants";
import styles from "../common/commonStyles";
import { Box, IconButton, TextField } from "@material-ui/core";
import { KEY_CODES } from "common/constants/CommonConstants";
import { ReactComponent as TrashIcon } from "assets/icons/trash_16.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil_16.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check_16.svg";
import classNames from "classnames";

const Product = ({
  isSelected,
  product,
  isBeingEdited,
  editedProductValue,
  setEditedProductValue,
  onFinishEditing,
  onNameClick,
  onDelete,
  onCheckboxClick
}) => {
  const classes = styles();

  const [isCheckboxClicked, setIsCheckboxClicked] = useState(isSelected);

  const onChange = e => {
    e.stopPropagation();

    if (isBeingEdited) {
      return;
    }

    setIsCheckboxClicked(prevState => {
      onCheckboxClick(!prevState);

      return !prevState;
    });
  };

  useEffect(() => {
    setIsCheckboxClicked(isSelected);
  }, [isSelected]);

  return (
    <FlexContainer item alignItems="center" className={classes.chipItem} onClick={onChange}>
      <Box marginRight="10px" display="flex">
        <CheckboxInput
          checkboxProps={{
            classes: { root: classes.checkboxRoot }
          }}
          formControlProps={{
            classes: { root: classes.checkboxFormControl }
          }}
          color={TAGS.PRODUCT.themeColor}
          checked={isCheckboxClicked}
        />
      </Box>

      <Box className={classes.textWrapper}>
        {isBeingEdited ? (
          <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
            <TextField
              id={`edit-product-item-input`}
              variant="standard"
              InputLabelProps={{
                shrink: true,
                style: { fontFamily: "Inter", fontSize: 12 }
              }}
              FormHelperTextProps={{ style: { fontFamily: "Inter" } }}
              inputProps={{
                autoFocus: true,
                "data-testid": `edit-product-item-input`,
                style: {
                  fontFamily: "Inter",
                  fontSize: 12,
                  paddingLeft: 11
                },
                value: editedProductValue,
                onChange: e => setEditedProductValue(e.target.value),
                onKeyDown: e => {
                  e.stopPropagation();
                  if (e.keyCode === KEY_CODES.ENTER) {
                    onFinishEditing();
                  }
                },
                placeholder: product.name,
                maxLength: 50
              }}
              InputProps={{
                classes: { underline: classes.textFieldUnderline }
              }}
            />
            <Box display="flex">
              <IconButton
                data-testid="product-delete-icon"
                onClick={e => {
                  e.stopPropagation();
                  onDelete();
                }}
                className={classes.button}
              >
                <TrashIcon />
              </IconButton>

              <IconButton data-testid="product-edit-finish-icon" onClick={onFinishEditing} className={classes.button}>
                <CheckIcon />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box className={classes.item}>
            <Text data-testid={`product-name-${product.name}`}>{product.name}</Text>

            <Box display="flex">
              <IconButton
                data-testid="product-delete-icon"
                onClick={e => {
                  e.stopPropagation();
                  onDelete();
                }}
                className={classNames({
                  [classes.button]: true,
                  [classes.buttonHidden]: true
                })}
              >
                <TrashIcon />
              </IconButton>

              <IconButton
                data-testid="product-edit-icon"
                onClick={onNameClick}
                className={classNames({
                  [classes.button]: true,
                  [classes.buttonHidden]: true
                })}
              >
                <PencilIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </FlexContainer>
  );
};

export default memo(Product);
