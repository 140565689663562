import React, { memo } from "react";
import cn from "classnames";

import { TableCell } from "@material-ui/core";
import { FlexContainer, Text } from "components/ui";

import ChevronUpIcon from "assets/icons/station-table/head-cell/ChevronUpIcon";

import useCellResponsivenessStyles from "../../cell-responsiveness.styles";
import useStyles from "./styles";

const HeadCell = ({ label, hint, isSortedDesc, children, className, ...rest }) => {
  const classes = useStyles();
  const cellClasses = useCellResponsivenessStyles();

  return (
    <TableCell
      classes={{ root: classes.root }}
      className={cn(className, { [cellClasses[rest.id]]: !!rest.id })}
      {...rest}
      title={label}
      data-testid={`table-header-title-${label}`}
    >
      <FlexContainer className={classes.labelContainer}>
        <Text className={classes.label}>{label}</Text>
        <ChevronUpIcon
          className={cn({
            [classes.chevronRotateToUp]: isSortedDesc,
            [classes.chevronHide]: isSortedDesc === undefined
          })}
        />
      </FlexContainer>
      {hint && (
        <Text title={hint} className={classes.hint}>
          {hint}
        </Text>
      )}
      {children}
    </TableCell>
  );
};

export default memo(HeadCell);
