import React, { memo, useCallback, useEffect, useState } from "react";
import { styled } from "@material-ui/core";

const HomePageHeaderInput = ({ initValue, placeholder, updateCallback, inputStyle = {} }) => {
  const [value, setValue] = useState(null);
  const [isEdited, setIsEdited] = useState(null);

  useEffect(() => {
    setValue(initValue);
    setIsEdited(false);
  }, [initValue, initValue?.length]);

  const onKeyDown = useCallback(
    event => {
      if ((isEdited && event.key === "Enter") || event.key === "Tab") {
        updateCallback(value);
        setIsEdited(false);
      }
    },
    [updateCallback, value]
  );

  return (
    <InputStyled
      value={value}
      placeholder={placeholder}
      onChange={e => {
        setIsEdited(e.target.value !== value);
        setValue(e.target.value);
      }}
      onKeyDown={onKeyDown}
      onBlur={() => {
        if (isEdited) {
          updateCallback(value);
        }
        setIsEdited(false);
      }}
      inputStyle={inputStyle}
    />
  );
};

const InputStyled = styled("input")(({ inputStyle }) => ({
  border: "none",
  backgroundColor: "transparent",
  fontFamily: "Inter",
  fontSize: 22,
  lineHeight: "normal",
  ...inputStyle
}));

export default memo(HomePageHeaderInput);
