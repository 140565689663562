import React, { memo } from "react";

const ReportIcon16 = ({ ...rest }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <mask
        id="mask0_2971_5630"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2971_5630)">
        <path
          d="M4.96576 11.0334H9.43239V10.1667H4.96576V11.0334ZM4.96576 8.43337H11.0324V7.56673H4.96576V8.43337ZM4.96576 5.83337H11.0324V4.96673H4.96576V5.83337ZM2.73242 13.2667V2.7334H13.2657V13.2667H2.73242ZM3.59907 12.4H12.3991V3.60005H3.59907V12.4Z"
          fill="#091733"
        />
      </g>
    </svg>
  );
};

export default memo(ReportIcon16);
