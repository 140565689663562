import { actionTypes } from "./actions";
import { defaultMultipleResourceOperationState } from "utils/modules-utils";

const defaultState = defaultMultipleResourceOperationState();

export default function editProductReducer(state = defaultState, action) {
  const { product } = action.payload || {};
  switch (action.type) {
    case actionTypes.STATION_EDIT_PRODUCT_REQUEST: {
      state.loading[product._id] = true;
      state.success[product._id] = false;
      return { ...state };
    }
    case actionTypes.STATION_EDIT_PRODUCT_SUCCESS: {
      state.success[product._id] = true;
      return { ...state };
    }
    case actionTypes.STATION_EDIT_PRODUCT_ERROR: {
      state.error[product._id] = action.payload.error;
      state.success[product._id] = false;
      return { ...state };
    }
    case actionTypes.STATION_EDIT_PRODUCT_FULFILL: {
      delete state.loading[product._id];
      delete state.success[product._id];
      return { ...state };
    }
    default:
      return state;
  }
}
