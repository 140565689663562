/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FlexItem from "components/ui/Flex/FlexItem.js";
import FlexContainer from "components/ui/Flex/FlexContainer.js";
import Button from "components/ui/Buttons/Button.js";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import CardBody from "components/ui/Card/CardBody";
import CardHeader from "components/ui/Card/CardHeader";
import CardFooter from "components/ui/Card/CardFooter";
import { ReactComponent as LogoWhiteGreenDark } from "assets/img/vanti-logo/logo-white-green-dark.svg";
import Text from "components/ui/Typography/Text";
import AuthCard from "./components/auth-card";
import BrandedTitle from "components/ui/Typography/BrandedTitle";
import * as authService from "services/auth/auth";
import * as signOutActions from "modules/auth/operations/signOut/actions";
import * as authStateSelectors from "modules/auth/state/selectors";

const styles = {
  cardHeader: {
    padding: "0",
    display: "flex",
    justifyContent: "center"
  },
  cardBody: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "20px 40px"
  }
};

const useStyles = makeStyles(styles);

export default function ConfirmAccount() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const uid = useSelector(authStateSelectors.uidSelector);
  const emailVerified = useSelector(authStateSelectors.emailVerifiedSelector);
  const email = useSelector(authStateSelectors.emailSelector);

  useEffect(() => {
    dispatch(signOutActions.signOutClearAction());
    return () => dispatch(signOutActions.signOutClearAction());
  }, []);

  const onClick = () => authService.verifyEmail().catch(setError);
  const onSignOut = () => dispatch(signOutActions.signOutRequestAction());

  if (!uid) {
    return <Redirect to="/sign-in" />;
  }

  if (emailVerified) {
    return <Redirect to="/dashboard/stations" />;
  }

  return (
    <FlexContainer justifyContent="center">
      <FlexItem xs={12} sm={8} md={6} container direction="row">
        <AuthCard>
          <CardHeader color="transparent" className={classes.cardHeader}>
            <div>
              <LogoWhiteGreenDark height="50px" />
            </div>
          </CardHeader>
          <CardBody className={classes.cardBody}>
            <BrandedTitle text="Confirm Account" size="28px" />
            <Text color="darkBlue" size="16px">
              We have sent you a confirmation email to {email}. Please click the link in the email to confirm your
              account and login to Vanti.
            </Text>
            <Button transparent={true} size="small" onClick={onClick} style={{ padding: 0 }}>
              <Text color="green" size="16px" family="poppins">
                Resend confirmation email
              </Text>
            </Button>
            {error && <Text color="danger">{error.message}</Text>}
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            <Button color="lightGrayBlue" block disabled={false} onClick={onSignOut} size="small">
              <Text color="white" size="16px" weight="bold" family="poppins">
                Sign Out
              </Text>
            </Button>
          </CardFooter>
        </AuthCard>
      </FlexItem>
    </FlexContainer>
  );
}
