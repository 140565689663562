import moment from "moment";
import dayjs from "dayjs";

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export const DATE_FORMAT = Object.freeze({
  TIME_DATE_WITH_VERTICAL_LINES: 0,
  WITH_COMMA: 1,
  FROM_NOW: 2,
  REVERSE_TIME_DATE_WITH_VERTICAL_LINES: 3,
  SIMPLE_SLASH: 4,
  TIME_DATE_WITH_DOTS: 5,
  MONTH_DAY: 6,
  SHORT_TIME_DATE_WITH_VERTICAL_LINES: 7,
  SIMPLE_SPACES: 8,
  TIME_ONLY: 9
});

export const getDaysDiff = (startDate, endDate) => {
  const diffMs = endDate.valueOf() - startDate.valueOf();

  const diffDays = diffMs / MS_PER_DAY;

  return Math.floor(diffDays);
};

export const DAYJS_DATE_FORMAT = {
  DEFAULT: "MMM DD, YYYY | HH:mm",
  DATE_PICKER: "MMM DD, YYYY"
};

export const getDateFormatDayjs = (date, format = DAYJS_DATE_FORMAT.DEFAULT) => {
  return dayjs(date).format(format);
};

export const getDateFormat = (date, format = DATE_FORMAT.TIME_DATE_WITH_VERTICAL_LINES) => {
  const momentDate = moment(date);

  if (format === DATE_FORMAT.MONTH_DAY) {
    return momentDate.format("MMM DD");
  }

  if (format === DATE_FORMAT.SIMPLE_SPACES) {
    return momentDate.format("DD MMM YYYY");
  }

  if (format === DATE_FORMAT.SIMPLE_SLASH) {
    return momentDate.format("D/M/YY");
  }

  if (format === DATE_FORMAT.SHORT_TIME_DATE_WITH_VERTICAL_LINES) {
    return momentDate.format("HH:mm | MMM DD");
  }

  if (format === DATE_FORMAT.TIME_ONLY) {
    return momentDate.format("HH:mm");
  }

  if (format === DATE_FORMAT.TIME_DATE_WITH_VERTICAL_LINES) {
    return momentDate.format("HH:mm | DD MMMM YY");
  }

  if (format === DATE_FORMAT.REVERSE_TIME_DATE_WITH_VERTICAL_LINES) {
    return momentDate.format("MMM DD, YY | h:mm A");
  }

  if (format === DATE_FORMAT.WITH_COMMA) {
    return momentDate.format("MMMM DD YYYY, HH:mm");
  }

  if (format === DATE_FORMAT.FROM_NOW) {
    return momentDate.fromNow();
  }

  if (format === DATE_FORMAT.TIME_DATE_WITH_DOTS) {
    return momentDate.format("HH:mm D.M.YY");
  }
};

export const getLocalTime = time => new Date(time).getTimezoneOffset() * 60 * 1000 * -1 + time;
