import React, { memo, useCallback, useContext, useMemo } from "react";
import { styled } from "@material-ui/core";
import DataEntityCard from "./DataEntitiesCard";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import AddDataEntityCard from "./AddDataEntityCard";

const DataEntitiesBody = ({ onClickEntityCard, setDisplayEntityDeletionDialogId }) => {
  const { dataEntitiesContext } = useContext(AppContext);
  const { dataEntities } = dataEntitiesContext;

  const dataEntitiesList = useMemo(() => Object.values(dataEntities), [dataEntities]);

  const onDeleteEntity = useCallback((event, entityId) => {
    setDisplayEntityDeletionDialogId(entityId);
  }, []);

  return (
    <DataEntitiesBodyContainer>
      {!!dataEntitiesList.length && <AddDataEntityCard dataEntitiesNames={dataEntitiesList.map(e => e.name)} />}
      {dataEntitiesList.map(e => (
        <DataEntityCard
          key={Math.random()}
          entity={e}
          onClickEntityCard={onClickEntityCard}
          onDeleteEntity={onDeleteEntity}
        />
      ))}
    </DataEntitiesBodyContainer>
  );
};

const DataEntitiesBodyContainer = styled("div")({
  width: "100%",
  maxHeight: "100%",
  overflowY: "auto",
  boxSizing: "border-box",
  gap: 16,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  paddingBottom: 24 //to keep bottom boxshadow intact
});

export default memo(DataEntitiesBody);
