import React, { memo } from "react";

const ChartUpIcon24 = ({ ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <mask
        id="mask0_1149_3775"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1149_3775)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.9341 5.44189L20.9961 5.93803L21.4961 9.93803L20.5038 10.0621L20.1429 7.17471L14.912 14.7833L14.6236 15.2028L14.2094 14.9069L11.0946 12.6821L7.39041 17.3124L6.60954 16.6877L10.6095 11.6877L10.9053 11.318L11.2906 11.5932L14.3764 13.7973L19.2862 6.65566L16.562 6.99619L16.438 6.00391L20.438 5.50391L20.9341 5.44189ZM3 5.55817V19.5582V20.0582H3.5H21.5V19.0582H4V5.55817H3Z"
          fill="#31CB7D"
        />
      </g>
    </svg>
  );
};

export default memo(ChartUpIcon24);
