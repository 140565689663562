export const actionTypes = {
  USER_SIGN_OUT_CLEAR: "user.signOut.clear",
  USER_SIGN_OUT_REQUEST: "user.signOut.request",
  USER_SIGN_OUT_SUCCESS: "user.signOut.success",
  USER_SIGN_OUT_ERROR: "user.signOut.error",
  USER_SIGN_OUT_FULFILL: "user.signOut.fulfill"
};

export function signOutClearAction() {
  return { type: actionTypes.USER_SIGN_OUT_CLEAR, payload: {} };
}

export function signOutRequestAction() {
  return { type: actionTypes.USER_SIGN_OUT_REQUEST, payload: {} };
}