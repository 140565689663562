import { actionTypes } from "./actions";

const defaultState = {
  uid: null,
  email: null,
  emailVerified: null,
  role: null,
  displayName: null
};

export default function authStateReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.AUTH_SET_STATE: {
      return {
        ...defaultState,
        email: action.payload.email,
        emailVerified: action.payload.emailVerified,
        uid: action.payload.uid,
        displayName: action.payload.displayName
      };
    }

    case actionTypes.AUTH_CLEAR_STATE: {
      return {
        ...defaultState
      };
    }
    default:
      return state;
  }
}
