import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import { PinUnpinMenu } from "./PinUnpinMenu";
import { DEFAULT_NEW_BOARD_NAME_TITLE_TEXT } from "../../HomePageTabs";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import useDashboardRequest from "common/hooks/use-dashboard-request";

export const PIN_ICON_ENABLED_BTN_TOOLTIP = "Pin to dashboard";
const PIN_ICON_DISABLED_BTN_TOOLTIP = "Create a dashboard to enable pin options";
const PIN_TO_DASHBOARD_MENU_ITEM_TEXT = "Pin to dashboard";

const DashboardItemPinUnpinMenu = ({
  dashboardItem,
  onMenuClosedCallback,
  onToggleItemCallback,
  anchorEl,
  setAnchorEl
}) => {
  const { dashboardContext } = useContext(AppContext);
  const { dashboards, dashboardItems } = dashboardContext;
  const [pinnedItems, setPinnedItems] = useState([]);
  const { pinUnpinUserDashboardItemsRequest } = useDashboardRequest();

  useEffect(() => {
    if (Object.values(dashboardItems).length) {
      setPinnedItems(prevState => {
        const reportMessageId = dashboardItem?.metadata?.reportId;
        return [
          ...new Set([
            ...prevState,
            ...Object.values(dashboardItems)
              .flat(1)
              .filter(item => item?.metadata?.reportId === reportMessageId)
              .map(filteredItem => filteredItem.dashboardId)
          ])
        ];
      });
    }
  }, [dashboardItem, dashboardItems]);

  const onTogglePinItemToDashboard = useCallback(
    (event, dashboardId) => {
      setPinnedItems(prevState => {
        const itemsCopy = [...prevState];
        const idx = itemsCopy.findIndex(id => id === dashboardId);
        if (idx !== -1) {
          itemsCopy.splice(idx, 1);
          return [...itemsCopy];
        }

        return [...new Set([...itemsCopy, dashboardId])];
      });
      onToggleItemCallback && onToggleItemCallback();
    },
    [onToggleItemCallback]
  );

  const onMenuClosed = useCallback(() => {
    pinUnpinUserDashboardItemsRequest.mutate({
      type: dashboardItem?.type,
      metadata: dashboardItem?.metadata,
      pinnedDashboardIds: pinnedItems
    });
    onMenuClosedCallback && onMenuClosedCallback();
  }, [dashboardItem, pinnedItems]);

  return (
    <PinUnpinMenu
      items={Object.values(dashboards)}
      pinnedItemsIds={pinnedItems}
      menuTitleText={PIN_TO_DASHBOARD_MENU_ITEM_TEXT}
      tooltipText={PIN_ICON_ENABLED_BTN_TOOLTIP}
      emptyListTooltipText={PIN_ICON_DISABLED_BTN_TOOLTIP}
      onToggleItem={onTogglePinItemToDashboard}
      onMenuClosed={onMenuClosed}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      defaultNameTitleText={DEFAULT_NEW_BOARD_NAME_TITLE_TEXT}
    />
  );
};

export default memo(DashboardItemPinUnpinMenu);
