export const constants = {
  email: {
    ERR_PROVIDE_VALID_EMAIL: "Please provide a valid E-Mail address",
    ERR_BUSINESS_EMAIL_REQUIRED: "Business email is required",
    ERR_MAX_USERS_INVITED: "You can invite up to 5 members",
    ERR_EMAIL_ADDRESS_ALREADY_IN_USE: "Email address is already in use"
  },
  liveGraphFields: {
    PREDICT: "PREDICT",
    FEEDBACK: "FEEDBACK",
    FAIL: "FAIL",
    PASS: "PASS"
  },
  datasets: {
    NUM_OF_RECORDS_TEXT: "Number of rows ",
    NUM_OF_COLUMNS_TEXT: "Number of columns ",
    FILE_TYPE_TEXT: "File type ",
    SEMANTIC_INFO_TEXT: "semantic info",
    SEMANTIC_INFO_MAX_CHARS: 1500
  },
  chat: {
    CHAT_MESSAGE_METADATA_BUTTON_TEXT: "{ }"
  }
};

export const TIME_UNITS = {
  HOUR: "hour",
  HOURS: "hours",
  DAY: "day",
  DAYS: "days",
  MONTHS: "months",
  MONTH: "month"
};
