import { PERSONA, getRestrictedDomainsSet } from "@vanti-analytics-org/vanti-common";

export { PERSONA as PERSONAS_ENUM } from "@vanti-analytics-org/vanti-common";
export { ROLE as ROLES_ENUM } from "@vanti-analytics-org/vanti-common";

export const Licenses = {
  TRIAL: "TRIAL",
  FULL: "FULL"
};

export const PERSONAS_MAP = {
  [PERSONA.ADMIN]: {
    id: 1,
    key: PERSONA.ADMIN,
    name: "Admin",
    inviteDisabled: false,
    description: "Can manage all application aspects within the account"
  },
  [PERSONA.CREATOR]: {
    id: 2,
    key: PERSONA.CREATOR,
    name: "Creator",
    inviteDisabled: true,
    description: "Can create models and edit stations"
  },
  [PERSONA.VIEWER]: {
    id: 3,
    key: PERSONA.VIEWER,
    name: "Viewer",
    inviteDisabled: false,
    description: "Can view all information within the account"
  },
  [PERSONA.SUPER_ADMIN]: {
    id: 4,
    key: PERSONA.SUPER_ADMIN,
    name: "SuperAdmin",
    inviteDisabled: true,
    description: "Can do everything within account and platform itself"
  },
  [PERSONA.STATION_ADMIN]: {
    id: 5,
    key: PERSONA.STATION_ADMIN,
    name: "StationAdmin",
    inviteDisabled: true,
    description: "Can manage all application aspects within the account"
  }
};

export const MAXIMUM_INVITE_EMAILS = 5;

export const RESTRICTED_EMAIL_DOMAINS = getRestrictedDomainsSet();
