import { vantiColors } from "assets/jss/palette";
import React, { memo } from "react";

const EmptyBellNotificationIcon = ({ ...rest }) => {
  return (
    <svg {...rest} width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="80" height="80" rx="40" fill={vantiColors.gray18} />
      <path
        d="M53.4405 61.9626C49.7329 55.3144 54.7378 41.3521 54.2479 33.1912C53.9002 27.397 49.5202 24.4467 46.8238 23.1704C45.603 22.5926 44.7274 22.358 44.7274 22.358M53.4405 61.9626L40.5615 58.5115M53.4405 61.9626L56.0163 62.6528M44.7274 22.358L46.2119 16.8177M44.7274 22.358C44.7274 22.358 43.8517 22.1233 42.5056 22.0134C39.5324 21.7704 34.264 22.1355 31.0657 26.9796C26.561 33.8022 23.9143 48.3963 17.3793 52.3M40.5615 58.5115L35.4099 57.1312L30.2583 55.7508M40.5615 58.5115C39.6969 61.7385 37.6523 64.2175 33.7886 63.1822C29.9249 62.147 29.3937 58.9778 30.2583 55.7508M17.3793 52.3L30.2583 55.7508M17.3793 52.3L14.8035 51.6098"
        stroke="#848B99"
        strokeWidth="2.66667"
      />
    </svg>
  );
};

export default memo(EmptyBellNotificationIcon);
