import React, { memo, useMemo } from "react";
import DashboardItemReport from "./DashboardItemReport";
import { DashboardItemType } from "@vanti-analytics-org/vanti-common";
import DashboardItemMetric from "./DashboardItemMetric";

const DashboardItem = ({ dashboardItem }) => {
  const item = useMemo(() => {
    switch (dashboardItem.type) {
      case DashboardItemType.REPORT:
        return <DashboardItemReport dashboardItem={dashboardItem} />;
      case DashboardItemType.WIDGET:
        return <></>; //TODO: implement widget
      case DashboardItemType.METRIC:
        return <DashboardItemMetric dashboardItem={dashboardItem}></DashboardItemMetric>; //TODO: implement widget
    }
  }, [dashboardItem]);

  return item;
};

export default memo(DashboardItem);
