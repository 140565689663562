import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const SuccessIcon24 = ({ stroke = vantiColors.green4, fill = vantiColors.green4 }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.52955 7.7209L5.25082 7.4112L4.63141 7.96867L4.91014 8.27837L5.52955 7.7209ZM7.27231 10.2802L6.96261 10.5589L7.24931 10.8775L7.55967 10.5819L7.27231 10.2802ZM4.91014 8.27837L6.96261 10.5589L7.58202 10.0014L5.52955 7.7209L4.91014 8.27837ZM7.55967 10.5819L12.3488 6.02085L11.774 5.4174L6.98496 9.97843L7.55967 10.5819Z"
      fill={fill}
    />
    <path
      d="M15.5833 8C15.5833 8.99586 15.3872 9.98196 15.0061 10.902C14.625 11.8221 14.0664 12.658 13.3622 13.3622C12.658 14.0664 11.8221 14.625 10.902 15.0061C9.98196 15.3872 8.99586 15.5833 8 15.5833C7.00414 15.5833 6.01804 15.3872 5.09798 15.0061C4.17793 14.625 3.34195 14.0664 2.63777 13.3622C1.9336 12.658 1.37501 11.8221 0.993913 10.902C0.612815 9.98196 0.416667 8.99586 0.416667 8C0.416667 7.00414 0.612815 6.01804 0.993914 5.09798C1.37501 4.17793 1.9336 3.34195 2.63777 2.63777C3.34195 1.9336 4.17793 1.37501 5.09798 0.993913C6.01804 0.612815 7.00414 0.416666 8 0.416667C8.99586 0.416667 9.98196 0.612816 10.902 0.993914C11.8221 1.37501 12.658 1.9336 13.3622 2.63777C14.0664 3.34195 14.625 4.17793 15.0061 5.09799C15.3872 6.01804 15.5833 7.00414 15.5833 8L15.5833 8Z"
      stroke={stroke}
      strokeWidth="0.833333"
    />
  </svg>
);

export default memo(SuccessIcon24);
