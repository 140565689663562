import React, { memo } from "react";

const SuccessGreenIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 15.9375C12.6076 15.9375 15.9375 12.6076 15.9375 8.5C15.9375 4.39238 12.6076 1.0625 8.5 1.0625C4.39238 1.0625 1.0625 4.39238 1.0625 8.5C1.0625 12.6076 4.39238 15.9375 8.5 15.9375ZM17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 2.24004e-06 13.1944 2.65044e-06 8.5C3.06084e-06 3.80558 3.80558 7.53856e-07 8.5 1.16426e-06C13.1944 1.57465e-06 17 3.80558 17 8.5Z"
      fill="#52DE97"
    />
    <path
      d="M5.8072 8.30284L5.1001 7.59574L3.68588 9.00995L4.39299 9.71706L5.8072 8.30284ZM7.4801 11.39L6.77299 12.0971L7.52881 12.8529L8.23267 12.0485L7.4801 11.39ZM12.9927 6.60846L13.6512 5.85588L12.146 4.53887L11.4875 5.29145L12.9927 6.60846ZM4.39299 9.71706L6.77299 12.0971L8.1872 10.6828L5.8072 8.30284L4.39299 9.71706ZM8.23267 12.0485L12.9927 6.60846L11.4875 5.29145L6.72752 10.7314L8.23267 12.0485Z"
      fill="#52DE97"
    />
  </svg>
);

export default memo(SuccessGreenIcon);
