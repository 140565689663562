import { FlexItem, Text } from "components/ui";
import CustomTable from "components/ui/CsvTable/CustomTable";
import React, { memo, useContext, useMemo } from "react";
import { DataCurationContext } from "../../../DataCurationPage";
import { CURATION_PLACEHOLDER_TEXT, DATA_CURATION_TABLE_HEADERS } from "../../../utils";
import { isEmpty } from "lodash";
import DataCurationTableBody from "./DataCurationTableBody";
import CircularProgress from "components/ui/Progress/CircularProgress";
import DataCurationIcon80 from "assets/icons/data-curation/DataCurationIcon80";
import { useHistory } from "react-router-dom";
import { styled } from "@material-ui/core";
import GeneralVantiButton, { VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { APP_MAIN_BACKGROUND_COLOR, vantiColors } from "assets/jss/palette";

// Note - the "className" properties in these file's components is not being sent from any father of this component, but it's just
// being used in the props for the sake of wrapping it in "styled" component which is a must

const PlaceholderAreaInner = styled(FlexItem)({
  rowGap: 16
});

const AddConnectorButtonStyled = styled(GeneralVantiButton)({
  width: 215
});

const PlaceholderArea = ({ className }) => {
  const history = useHistory();

  return (
    <FlexItem container className={className}>
      <PlaceholderAreaInner dense="full" container direction="column" alignItems="center">
        <DataCurationIcon80 />

        <Text color={vantiColors.gray33} size={16} weight={400}>
          {CURATION_PLACEHOLDER_TEXT}
        </Text>

        <AddConnectorButtonStyled
          variant={VARIANTS.TERTIARY}
          buttonInfo={{
            label: "Add data curation",
            onClick: () => {
              history.push("/dashboard/data-curation/_");
            }
          }}
        ></AddConnectorButtonStyled>
      </PlaceholderAreaInner>
    </FlexItem>
  );
};

const PlaceholderAreaStyled = styled(PlaceholderArea)({
  height: "100%",
  backgroundColor: "white",
  borderTop: `2px solid ${APP_MAIN_BACKGROUND_COLOR}`,
  padding: "166px 497px 304.5px 497px !important"
});

const DataCurationTable = ({ className }) => {
  const { dataCurations, fetchingLoading } = useContext(DataCurationContext);
  const hasDataCurations = useMemo(() => !isEmpty(dataCurations), [dataCurations]);

  return (
    <FlexItem
      dense="full"
      container
      justifyContent="center"
      className={className}
      data-testid="data-curation-table-container"
    >
      {fetchingLoading && (
        <FlexItem dense="full">
          <CircularProgress />
        </FlexItem>
      )}

      <CustomTable
        dataTestId="data-curation-table-header"
        headers={DATA_CURATION_TABLE_HEADERS}
        tableContainerProps={{ "data-testid": "data-curations-table-section" }}
        tableHeadProps={{ "data-testid": "data-curations-table-header-section" }}
      >
        {hasDataCurations && <DataCurationTableBody rows={dataCurations} />}
      </CustomTable>

      {!hasDataCurations && <PlaceholderAreaStyled />}
    </FlexItem>
  );
};

export default memo(DataCurationTable);
