import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  cardText: {
    marginBottom: 48,
    textAlign: "left"
  },
  cardElementText: {
    textAlign: "left"
  },
  subTextContainer: {
    marginBottom: 48
  }
});
