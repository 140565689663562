import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  root: {
    display: "flex",
    padding: 0,
    borderBottom: "none",
    width: "100%",
    "& > div": {
      width: "100%"
    }
  }
}));
