import React, { memo } from "react";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import { startCase } from "lodash";
import { isCommonEntity } from "../DataEntitiesPage";

const ENTITIES_PAGE_TITLE_TEXT = "Entities";

const DataEntitiesPagedHeader = ({ chosenEntity }) => {
  return (
    <DataEntitiesPageHeaderContainer>
      <Text size={22} weight={600}>
        {isCommonEntity(chosenEntity?.name)
          ? startCase(chosenEntity?.name?.toLowerCase())
          : chosenEntity?.name || ENTITIES_PAGE_TITLE_TEXT}
      </Text>
    </DataEntitiesPageHeaderContainer>
  );
};

const DataEntitiesPageHeaderContainer = styled("div")({
  width: "100%",
  height: 54,
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  paddingTop: 4
});

export default memo(DataEntitiesPagedHeader);
