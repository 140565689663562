import React, { memo } from "react";
import { Doughnut, defaults } from "react-chartjs-2";

const DonutGraph = ({ data, cutout = "80%" }) => {
  defaults.animation = false;
  defaults.plugins.legend.display = false;

  return (
    <Doughnut
      data={data}
      width="150px"
      height="131px"
      options={{
        maintainAspectRatio: false,
        cutout
      }}
    />
  );
};

export default memo(DonutGraph);
