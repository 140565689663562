import React, { memo, useMemo } from "react";
import { FlexItem, Text } from "components/ui";
import GeneralVantiButton from "components/ui/Buttons/GeneralVantiButton";
import { useHistory } from "react-router-dom";
import usePermissions from "common/hooks/use-permissions";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import { CURATION_TEXT, NEW_CURATION_TEXT } from "pages/side-bar/data-curation/utils";

const Header = () => {
  const history = useHistory();
  const isDataCurationAvailable = usePermissions([ROLES_ENUM.MANAGE_DATA_CURATIONS]);

  const newDataCurationButtonInfo = useMemo(() => {
    return {
      disabled: !isDataCurationAvailable,
      label: NEW_CURATION_TEXT,
      onClick: () => {
        history.push("/dashboard/data-curation/_");
      }
    };
  }, [isDataCurationAvailable]);

  return (
    <FlexItem
      container
      justifyContent="space-between"
      alignItems="center"
      dense="full"
      data-testid="data-curation-home-page-header"
    >
      <FlexItem dense="full">
        <Text data-testid="data-curation-home-page-title" size={"24px"} weight="bold">
          {CURATION_TEXT}
        </Text>
      </FlexItem>

      <FlexItem dense="full">
        <GeneralVantiButton data-testid="data-curation-home-page-button" buttonInfo={newDataCurationButtonInfo} />
      </FlexItem>
    </FlexItem>
  );
};

export default memo(Header);
