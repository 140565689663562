import React, { memo } from "react";
import { FlexItem } from "components/ui";
import styles from "../../../../styles";
import LeftBorderDynamicWrapper from "components/ui/LeftBorderDynamicWrapper/LeftBorderDynamicWrapper";
import NumberedSection from "../NumberedSection";
import CurationFileUpload from "./CurationFileUpload";

const CurationFileUploadWrapper = ({
  titleProps,
  files,
  onCurationFileAdd,
  onCurationFileRemove
}) => {
  const classes = styles();

  return (
    <FlexItem dense="full" className={classes.marginBottom}>
        <CurationFileUpload
            titleProps={titleProps}
            files={files}
            onCurationFileAdd={onCurationFileAdd}
            onCurationFileRemove={onCurationFileRemove}
          />
    </FlexItem>
  );
};

export default memo(CurationFileUploadWrapper);
