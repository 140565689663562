import React, { memo, useCallback, useMemo } from "react";
import { isEmpty } from "lodash";
import { FlexItem, Text } from "components/ui";
import CircularProgress from "components/ui/Progress/CircularProgress";
import { CONNECT_BUTTON_TEXT } from "pages/side-bar/data-connectors/constants";
import { vantiColors } from "assets/jss/palette";
import CheckIcon from "assets/icons/CheckIcon";
import useStyles from "./styles";
import usePermissions from "common/hooks/use-permissions";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import GeneralVantiButton from "components/ui/Buttons/GeneralVantiButton";

const CreateConnectButtonSection = ({
  actionResponse,
  testConnection,
  dataSourceFieldsValues,
  fieldsChanged,
  selectedDataSource,
  onTestConnection,
  dataTestId
}) => {
  const classes = useStyles();

  const shouldReplaceMinioWithDatabricksConnector = usePermissions([ROLES_ENUM.ViewDatabricksConnector]);

  const error = useMemo(() => actionResponse.error || testConnection.error, [actionResponse, testConnection]);
  const loading = useMemo(() => actionResponse.loading || testConnection.loading, [actionResponse, testConnection]);

  const getMessages = useCallback(() => {
    if (shouldReplaceMinioWithDatabricksConnector && testConnection.value?.ok && !testConnection.loading) {
      return (
        <FlexItem container dense="full" alignItems="center" justifyContent="space-between">
          <FlexItem dense="full">
            <CheckIcon color={vantiColors.green1} />
          </FlexItem>

          <FlexItem dense="full">
            <Text data-testid="connection-succeeded-text" size={14} color={vantiColors.lightGray6}>
              Connection succeeded
            </Text>
          </FlexItem>
        </FlexItem>
      );
    }

    if (loading || fieldsChanged) {
      return null;
    }

    return (
      <>
        {testConnection.value?.ok && (
          <FlexItem container dense="full" alignItems="center" justifyContent="space-between">
            <FlexItem dense="full">
              <CheckIcon color={vantiColors.green1} />
            </FlexItem>

            <FlexItem dense="full">
              <Text data-testid="connection-succeeded-text" size={14} color={vantiColors.lightGray6}>
                Connection succeeded
              </Text>
            </FlexItem>
          </FlexItem>
        )}
      </>
    );
  }, [loading, error, testConnection, fieldsChanged, shouldReplaceMinioWithDatabricksConnector]);

  const isButtonDisabled = useMemo(
    () => {
      const fieldWithMissingValue = selectedDataSource.fields.some(
        field => field.required && isEmpty(dataSourceFieldsValues[field.key])
      );

      return loading || (error && !fieldsChanged) || fieldWithMissingValue;
    },
    [dataSourceFieldsValues, error, fieldsChanged, selectedDataSource.fields, loading],
    shouldReplaceMinioWithDatabricksConnector
  );

  const testConnectionButtonProps = useMemo(
    () => ({
      disabled: isButtonDisabled,
      onClick: async () => {
        onTestConnection();
      }
    }),
    [isButtonDisabled, onTestConnection]
  );

  return (
    <FlexItem dtata-testid={dataTestId} container dense="full" justifyContent="flex-end" style={{ textAlign: "left" }}>
      <FlexItem dense="full" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <FlexItem className={classes.connectButton} dense="full">
          <GeneralVantiButton
            buttonInfo={{
              ...testConnectionButtonProps,
              label: loading ? (
                <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <CircularProgress style={{ height: 15, width: 15, color: "white" }} />
                </span>
              ) : (
                CONNECT_BUTTON_TEXT
              )
            }}
          />
        </FlexItem>

        <FlexItem dense="full" container>
          {getMessages()}
        </FlexItem>
      </FlexItem>
    </FlexItem>
  );
};

export default memo(CreateConnectButtonSection);
