import { makeStyles } from "@material-ui/core";

export default makeStyles({
  status: {
    maxWidth: "130px"
  },
  goal: {
    maxWidth: "40px"
  },
  station: {
    maxWidth: "110px"
  },
  products: {
    maxWidth: "106px"
  },
  lines: {
    maxWidth: "80px"
  },
  predictionsPerDay: {
    maxWidth: "100px"
  },
  lastPrediction: {
    maxWidth: "129px"
  },
  createdBy: {
    maxWidth: "105px"
  }
});
