import React, { memo, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import { Text } from "components/ui";
import BodyCell from "../../table-body-rows/body-cell/BodyCell";
import Chip from "@material-ui/core/Chip";
import * as stationService from "services/api/station";
import { BLOCK_DB_STATES, STATION_STATES } from "common/constants/BlockConstants";
import { STATION_TYPES } from "utils/onboarding";
import ErrorIcon from "assets/icons/error/ErrorIcon";
import { vantiColors } from "assets/jss/palette";
import styles from "./styles";

const DASHBOARD_STATUS_COLORS = {
  [STATION_STATES.DRAFT]: {
    backgroundColor: vantiColors.gray38,
    textColor: vantiColors.white,
    weight: 600
  },
  [STATION_STATES.LIVE]: {
    backgroundColor: vantiColors.green1,
    textColor: vantiColors.white,
    weight: 600
  },
  [STATION_STATES.READY]: {
    backgroundColor: vantiColors.lightGreen2,
    textColor: vantiColors.darkBlue6,
    weight: 500
  },
  [STATION_STATES.ERROR]: {
    backgroundColor: vantiColors.rose6,
    textColor: vantiColors.white,
    weight: 600
  }
};

const StationStatusCell = ({ station, onStationStatusUpdate }) => {
  const classes = styles();
  const history = useHistory();

  const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false);

  const statusRef = useRef();

  const onStationClick = async () => {
    const stationBlocks = await stationService.getAllStationBlocks(station.id, false);
    const firstStationBlock =
      stationBlocks.find(block => block?.state === station.blockState) || stationBlocks[0] || {};

    if (firstStationBlock?.state === BLOCK_DB_STATES.UPLOADED) {
      history.push(
        `/dashboard/onboarding/stations/${station.id}/blocks/${firstStationBlock?.id}/${
          station.type === STATION_TYPES.CUSTOM ? STATION_TYPES.CUSTOM : "regular"
        }`
      );
      return;
    }

    history.push(`/dashboard/stations/${station.id}`);
  };

  const onStatusChipClick = e => {
    e.preventDefault();
    e.stopPropagation();

    if (onStationStatusUpdate && station.blockState !== BLOCK_DB_STATES.READY) {
      setIsStatusPopupOpen(!isStatusPopupOpen);
    } else {
      onStationClick();
    }
  };

  const uiErrorForStation = useMemo(() => {
    //TODO: move this to separate utility module for i18n
    const pluralizeModelsNoun = count => (count === 1 ? `1 Model` : `${count} Models`);
    const pluralizeHaveVerb = count => (count === 1 ? "has" : "have");
    const pluralizeBeVerb = count => (count === 1 ? "is" : "are");

    const modelErrorMessagesBuilder = count => ({
      deploymentError: `The deployment of ${pluralizeModelsNoun(count)} ${pluralizeHaveVerb(count)} failed.`,
      trainingError: `The training of ${pluralizeModelsNoun(count)} has failed`,
      runtimeError: `${pluralizeModelsNoun(count)} ${pluralizeBeVerb(count)} experiencing critical errors`
    });

    const uiErrorState = {
      errorType: null,
      message: "Unknown error"
    };

    if (station.runtimeErrorCount > 0) {
      uiErrorState.errorType = "runtimeError";
      uiErrorState.message = modelErrorMessagesBuilder(
        station.runtimeErrorCount + station.trainingErrorCount + station.deploymentErrorCount
      ).runtimeError;
    } else if (station.deploymentErrorCount > 0) {
      uiErrorState.errorType = "deploymentError";
      uiErrorState.message = modelErrorMessagesBuilder(station.deploymentErrorCount).deploymentError;
    } else if (station.trainingErrorCount > 0) {
      uiErrorState.errorType = "trainingError";
      uiErrorState.message = modelErrorMessagesBuilder(station.trainingErrorCount).trainingError;
    } else {
      return null;
    }

    return uiErrorState;
  }, []);

  const capitalizedAppStatus = useMemo(
    () => station.status?.charAt(0)?.toUpperCase() + station.status?.slice(1)?.toLowerCase(),
    [station.status]
  );

  return (
    <BodyCell align="left" data-testid={`${station.name}-station-status`}>
      <div ref={statusRef}>
        <Chip
          className={classes.chip}
          style={{
            backgroundColor: DASHBOARD_STATUS_COLORS[station.status]?.backgroundColor,
            color: DASHBOARD_STATUS_COLORS[station.status]?.textColor,
            fontWeight: DASHBOARD_STATUS_COLORS[station.status]?.weight,
            fontFamily: "Inter"
          }}
          label={capitalizedAppStatus}
          onClick={onStatusChipClick}
        />

        {uiErrorForStation && (
          <div className={classes.errorsContainer}>
            <ErrorIcon width={16} height={16} />
            <Text size={10} className={classes.errorText}>
              {uiErrorForStation.message}
            </Text>
          </div>
        )}
      </div>

      <Popper
        open={isStatusPopupOpen}
        anchorEl={statusRef?.current}
        style={{ zIndex: 10000 }}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={setIsStatusPopupOpen.bind(null, false)}>
                <MenuList role="menu">
                  <MenuItem
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onStationStatusUpdate(station.id, BLOCK_DB_STATES.READY);
                      setIsStatusPopupOpen(false);
                    }}
                  >
                    Ready
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </BodyCell>
  );
};

export default memo(StationStatusCell);
