import React, { memo, useMemo } from "react";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import OpenInFullIcon16 from "assets/icons/open-in-full/OpenInFullIcon16";
import { DarkToolTip } from "components/ui/tooltips/tooltip";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import Loader from "components/ui/Loader";

const TableStyled = styled("table")(
  ({ cellPadding, borderColor, lowOpacity, fullBorder = true, footerFadeout = true }) => ({
    border: fullBorder ? `1px solid ${borderColor}` : "none",
    height: "100%",
    width: "100%",
    opacity: lowOpacity ? 0.2 : 1,
    backgroundColor: "white",
    "& th, td": {
      textAlign: "left",
      padding: cellPadding,
      borderBottom: `1px solid ${borderColor}`,
      "&:not(:last-of-type)": {
        borderRight: `1px solid ${borderColor}`
      }
    },
    "& th:last-of-type": {
      padding: cellPadding,
      paddingRight: "8px",
      borderTopRightRadius: 4
    },
    "& tr:first-of-type th:first-of-type": {
      borderTopLeftRadius: 4
    },
    "& tr:last-of-type td:first-of-type": {
      borderBottomLeftRadius: 4
    },
    "& tr:last-of-type td:last-of-type": {
      borderBottomRightRadius: 4
    },
    "& > tr:nth-last-child(2)": {
      opacity: footerFadeout ? 0.6 : 1
    },
    "& > tr:last-child": {
      opacity: footerFadeout ? 0.3 : 1
    }
  })
);

const TableHeaderStyled = styled("th")(({ backgroundColor }) => ({
  backgroundColor
}));

const TableCellTextStyled = styled(Text)({
  display: "block"
});

const TableCellStyled = styled("td")(({ height, minWidth }) => ({
  height,
  minWidth,
  boxSizing: "border-box"
}));

const TableWrapper = styled("div")(({ maxHeight }) => ({
  overflowX: "auto",
  boxSizing: "border-box",
  maxHeight,
  overflow: "scroll"
}));

const TableContainer = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  rowGap: 16,
  overflow: "hidden"
});

const LoadMoreWrapper = styled("div")({
  display: "flex",
  justifyContent: "flex-end"
});

const MINIMUM_TABLE_PREVIEW_ROWS = 5;

const TableCellText = ({ text, ...rest }) => {
  let modifiedText = text || "";

  if (modifiedText.length > 20) {
    modifiedText = modifiedText.slice(0, 17) + "...";
  }

  return <TableCellTextStyled {...rest}>{modifiedText}</TableCellTextStyled>;
};

const { cellHeight, cellPadding, cellMinWidth, headerBgColor, borderColor, fontSize, headerFontWeight } = {
  cellHeight: 10,
  cellPadding: "12px 16px",
  cellMinWidth: "auto",
  headerBgColor: vantiColors.lightGray12,
  borderColor: vantiColors.lightGray13,
  borderWidth: "0px 1px 1px 0px",
  fontSize: 14,
  headerFontWeight: 600
};

export const ChatMessageReportDataPreviewTable = ({
  preview,
  isEnlargedPreview,
  canEnlargePreview,
  lowOpacity,
  onClickEnlargePreviewCallback,
  fullBorder,
  maxTableHeight = 454,
  isReachedMaximumRows,
  isLoadingMoreItems,
  showLoadMore,
  setPageNumber = () => {},
  tableFooter = null
}) => {
  const headers = useMemo(() => preview?.headers || [], [preview?.headers]);
  const body = useMemo(() => preview?.body || [], [preview?.body]);

  return (
    <TableContainer data-testid={"report-data-preview-table-container"}>
      {!isEnlargedPreview && canEnlargePreview && (
        <OpenInFullIcon16
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "8px",
            right: "8px"
          }}
          onClick={onClickEnlargePreviewCallback}
        />
      )}

      <TableWrapper
        maxHeight={maxTableHeight}
        data-testid={"report-data-preview-table-wrapper"}
        id="report-data-preview-table-wrapper"
      >
        <TableStyled
          data-testid={"report-data-preview-table"}
          cellSpacing={0}
          cellPadding={cellPadding}
          borderColor={borderColor}
          lowOpacity={lowOpacity}
          footerFadeout={Object.values(body)?.length >= MINIMUM_TABLE_PREVIEW_ROWS}
          fullBorder={fullBorder}
        >
          <tr>
            {headers.map(header => {
              return (
                <DarkToolTip interactive title={header} arrow placement="top-start" key={header}>
                  <TableHeaderStyled key={header} backgroundColor={headerBgColor}>
                    <TableCellText
                      size={fontSize}
                      weight={headerFontWeight}
                      color={vantiColors.gray29}
                      lineSize={"17px"}
                      text={header}
                    />
                  </TableHeaderStyled>
                </DarkToolTip>
              );
            })}
          </tr>

          {Object.values(body).map((row, rowIndex) => {
            return (
              <tr key={`tr-${rowIndex}`}>
                {row.map((cell, cellIndex) => (
                  <TableCellStyled key={`cell-${rowIndex}-${cellIndex}`} height={cellHeight} minWidth={cellMinWidth}>
                    <TableCellText
                      size={fontSize}
                      weight={400}
                      color={vantiColors.gray41}
                      lineSize={"17px"}
                      text={cell}
                    />
                  </TableCellStyled>
                ))}
              </tr>
            );
          })}
        </TableStyled>
        {tableFooter}
      </TableWrapper>

      {showLoadMore && (
        <LoadMoreWrapper>
          <GeneralVantiButton
            buttonInfo={{
              label: isLoadingMoreItems ? <Loader /> : isReachedMaximumRows ? "Table is fully loaded" : "Load More",
              disabled: isReachedMaximumRows || isLoadingMoreItems,
              onClick: () => setPageNumber(prevVal => prevVal + 1)
            }}
            size={SIZES.SMALL}
            variant={VARIANTS.SECONDARY}
          />
        </LoadMoreWrapper>
      )}
    </TableContainer>
  );
};

export default memo(ChatMessageReportDataPreviewTable);
