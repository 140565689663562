import { actionTypes } from "./actions";
import { call, put, takeLatest } from "redux-saga/effects";
import * as authService from "services/auth/auth";

export function* signOutWorker() {
  try {
    yield call(authService.signOut);
    yield put({ type: actionTypes.USER_SIGN_OUT_SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.USER_SIGN_OUT_ERROR, payload: { error } });
  } finally {
    yield put({ type: actionTypes.USER_SIGN_OUT_FULFILL });
  }
}

export default function* signOutWatcher() {
  yield takeLatest(actionTypes.USER_SIGN_OUT_REQUEST, signOutWorker);
}
