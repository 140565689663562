import { vantiColors } from "assets/jss/palette";

export const VantiScrollBarProps = {
  "&::-webkit-scrollbar": {
    width: "6px",
    backgroundColor: "transparent"
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: vantiColors.lightGray1,
    borderRadius: "40px"
  }
};
