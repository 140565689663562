import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  modalBody: {
    padding: "0px 15px"
  }
}));

export default function ModalBody(props) {
  const classes = useStyles();
  const { className, children, ...rest } = props;
  const modalBodyClasses = classNames({
    [classes.modalBody]: true,
    [className]: className !== undefined
  });
  return (
    <div className={modalBodyClasses} {...rest}>
      {children}
    </div>
  );
}

ModalBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
