import React, { memo, useRef } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { Link } from "react-router-dom";

import { Breadcrumbs, Grid } from "@material-ui/core";
import Text from "components/ui/Typography/Text";

import isEmpty from "lodash/isEmpty";

import useStyles from "./styles";
import { darkBlueColor, vantiColors } from "assets/jss/palette";

const VantiBreadcrumbsComponent = ({ links = [], baseBreadCrumb }) => {
  const classes = useStyles();
  const linkRef = useRef();
  const titleRef = useRef();

  return (
    <Grid container item alignItems="flex-start" xs={12} className={classes.topNavbar}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={
          <Text data-testid="breadcrumb-separator" weight="bold" body={1}>
            /
          </Text>
        }
      >
        {baseBreadCrumb.disabled ? (
          <Text
            data-testid={`breadcrumb-base`}
            color={!isEmpty(links) && vantiColors.darkBlue4}
            body={1}
            className={cn(!isEmpty(links) ? classes.clickableLinkLight : classes.clickableLink)}
          >
            {baseBreadCrumb.title}
          </Text>
        ) : (
          <Link
            data-testid={`breadcrumb-base`}
            ref={titleRef}
            to={baseBreadCrumb.mainLink}
            className={cn(classes.link, classes.title)}
          >
            <Text
              color={!isEmpty(links) && vantiColors.darkBlue4}
              body={1}
              className={cn(!isEmpty(links) ? classes.clickableLinkLight : classes.clickableLink)}
            >
              {baseBreadCrumb.title}
            </Text>
          </Link>
        )}

        {links.map((link, index) => (
          <Link
            key={index}
            ref={linkRef}
            to={link.to}
            className={cn(
              classes.link,
              link.disabled || index == links.length - 1
                ? classes.noneClickableLink
                : index != links.length - 1
                ? classes.clickableLinkLight
                : classes.clickableLink
            )}
          >
            {link.title && (
              <Text
                data-testid={link.title && `breadcrumb-${link.title}`}
                color={index != links.length - 1 && vantiColors.darkBlue4}
                body={1}
              >
                {link.title}{" "}
              </Text>
            )}
            <Text
              data-testid={link.text && `breadcrumb-${link.text}`}
              color={index != links.length - 1 && vantiColors.darkBlue4}
              body={1}
            >
              {link.text}
            </Text>
          </Link>
        ))}
      </Breadcrumbs>
    </Grid>
  );
};

VantiBreadcrumbsComponent.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      text: PropTypes.string,
      title: PropTypes.string.isRequired
    })
  )
};

export default memo(VantiBreadcrumbsComponent);
