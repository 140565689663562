import React from "react";
import { NOTIFICATION_CENTER_BUTTON_TEXT, NOTIFICATION_TOPICS } from "common/constants/NotificationConstants";
import RedCircleExclamationMark from "assets/icons/notification-center/RedCircleExclamationMark";
import SuccessIcon16 from "assets/icons/batch-prediction-status/SuccessIcon16";
import AddDashboardIcon16 from "assets/icons/notification-center/AddDashboardIcon16";

export const notificationItemTopicToIcon = topic => {
  switch (topic) {
    case NOTIFICATION_TOPICS.BACKTEST:
    case NOTIFICATION_TOPICS.IMAGE_DATA_DRIFT:
    case NOTIFICATION_TOPICS.DATA_DRIFT:
    case NOTIFICATION_TOPICS.TRAINING_FAILED:
      return <RedCircleExclamationMark />;
    case NOTIFICATION_TOPICS.DASHBOARD_INVITE:
      return <AddDashboardIcon16 />;
    default:
      return <SuccessIcon16 />;
  }
};

export const notificationItemTopicToButtonText = topic => {
  switch (topic) {
    case NOTIFICATION_TOPICS.BACKTEST:
    case NOTIFICATION_TOPICS.IMAGE_DATA_DRIFT:
    case NOTIFICATION_TOPICS.DATA_DRIFT:
      return NOTIFICATION_CENTER_BUTTON_TEXT.FIX_IT;
    case NOTIFICATION_TOPICS.TRAINING_SUCCESSFUL:
    case NOTIFICATION_TOPICS.TRAINING_BAD_MODEL:
    case NOTIFICATION_TOPICS.TRAINING_GOOD_MODEL:
      return NOTIFICATION_CENTER_BUTTON_TEXT.SEE_RESULT;
    case NOTIFICATION_TOPICS.FILE_UPLOADED:
      return NOTIFICATION_CENTER_BUTTON_TEXT.CONTINUE;
    case NOTIFICATION_TOPICS.DASHBOARD_INVITE:
      return NOTIFICATION_CENTER_BUTTON_TEXT.DASHBOARD_INVITE;
    case NOTIFICATION_TOPICS.BACK_TO_NORMAL:
    case NOTIFICATION_TOPICS.BACK_TO_NORMAL_BACKTEST:
    case NOTIFICATION_TOPICS.INSIGNIFICANT_FEATURES:
    case NOTIFICATION_TOPICS.SIGNIFICANT_FEATURES:
    case NOTIFICATION_TOPICS.THRESHOLD:
    case NOTIFICATION_TOPICS.TIME_SERIES:
    case NOTIFICATION_TOPICS.EXPLAINABILITY:
    case NOTIFICATION_TOPICS.TRAINING_FAILED:
    default:
      return NOTIFICATION_CENTER_BUTTON_TEXT.LEARN_MORE;
  }
};
