import React, { memo, useMemo } from "react";

const ArrowIcon22 = ({ direction }) => {
  const rotation = useMemo(
    () => (direction === "up" ? 180 : direction === "right" ? -90 : direction === "left" ? 90 : 0),
    [direction]
  );

  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation})`}
    >
      <path
        d="M11.779 17.3045L11.779 5.70193M11.779 17.3045L15.4425 13.641M11.779 17.3045L8.11551 13.6411"
        stroke="#091733"
        strokeWidth="1.00833"
      />
    </svg>
  );
};

export default memo(ArrowIcon22);
