import React, { memo } from "react";
import GeneralModalPreviewComponent from "components/ui/Modal/GeneralModalPreviewComponent";
import {
  DATASTE_FILE_INFO_MODAL_HEIGHT,
  DATASTE_FILE_INFO_MODAL_WIDTH
} from "pages/side-bar/chat/components/DatasetFileInfoModal";
import HomePageItemBrowser from "./HomePageItemBrowser";

const HomePageAddItemModal = ({ openAddItemModal, setOpenAddItemModal }) => {
  return (
    <GeneralModalPreviewComponent
      height={DATASTE_FILE_INFO_MODAL_HEIGHT}
      width={DATASTE_FILE_INFO_MODAL_WIDTH}
      padding={24}
      open={!!openAddItemModal}
      onClose={() => setOpenAddItemModal(false)}
    >
      <HomePageItemBrowser />
    </GeneralModalPreviewComponent>
  );
};

export default memo(HomePageAddItemModal);
