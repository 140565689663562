import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const CopyToClipboardIcon24 = ({ stroke = vantiColors.gray29, ...rest }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      {...rest}
    >
      <path d="M7 7V4H20V17H17M7 7H4V20H17V17M7 7H17V17" stroke={stroke} />
    </svg>
  );
};

export default memo(CopyToClipboardIcon24);
