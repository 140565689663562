export const isUserAgentMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const shouldSkipMobileRestriction = () => {
  const URL = window.location.href;
  if (URL.indexOf("create-account") > -1) return true;
};

export const scrollToBottom = behavior => {
  window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior });
};

export const scrollToTop = behavior => {
  window.scrollTo({ top: 0, left: 0, behavior });
};

export const alertBeforeRefresh = e => {
  e.preventDefault();
  e.returnValue = "";
};
