import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const ChatSendArrowIcon24 = ({ stroke = vantiColors.gray33, ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M21.1327 12.0356L5.04695 19.5356L8.04695 12.0356M21.1327 12.0356L5.04695 4.53557L8.04695 12.0356M21.1327 12.0356H8.04695"
        stroke={stroke}
      />
    </svg>
  );
};

export default memo(ChatSendArrowIcon24);
