import React, { memo } from "react";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Text from "../Typography/Text";
import { APP_MAIN_BACKGROUND_COLOR, vantiColors } from "assets/jss/palette";
import { styled } from "@material-ui/core";

const TableContainerInnerStyled = styled("div")({
  backgroundColor: "white"
});

const TableRowStyled = styled(TableRow)({
  borderBottom: `2px solid ${APP_MAIN_BACKGROUND_COLOR}`
});

const HeaderCellStyled = styled(TableCell)({
  borderBottom: "none"
});

const CustomTable = ({ headers = [], children: TableBody, headersAlignment = "left", dataTestId, ...props }) => {
  const { tableContainerProps, tableProps, tableHeadProps, tableRowProps, tableCellProps, tableCellTextProps } = props;

  return (
    <TableContainer component={TableContainerInnerStyled} {...tableContainerProps}>
      <Table {...tableProps}>
        <TableHead {...tableHeadProps}>
          <TableRowStyled {...tableRowProps}>
            {headers.map(header => (
              <HeaderCellStyled key={header} align={headersAlignment} {...tableCellProps}>
                <Text
                  data-testid={`${dataTestId}-${header}`}
                  size={16}
                  weight="600"
                  color={vantiColors.darkBlue6}
                  {...tableCellTextProps}
                >
                  {header}
                </Text>
              </HeaderCellStyled>
            ))}
          </TableRowStyled>
        </TableHead>

        {TableBody}
      </Table>
    </TableContainer>
  );
};

export default memo(CustomTable);
