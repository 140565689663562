import { makeStyles } from "@material-ui/core";

const styles = makeStyles(theme => ({
  multiInput: {
    marginTop: `${theme.spacing(0.5)}px !important`
  },
  togglePasswordEyeIcon: {
    padding: theme.spacing(0.5),
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.vantiColors.gray20
    }
  },
  formControl: {
    margin: "0px !important",
    padding: "0px !important"
  },
  inputFormControl: {
    marginTop: theme.spacing(0.5),
    height: "100%"
  },
  textFieldFormControl: {
    width: "100%"
  },
  textField: {
    width: "100%"
  }
}));

export default styles;
