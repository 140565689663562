import makeStyles from "@material-ui/core/styles/makeStyles";
import { vantiColors } from "assets/jss/palette";

// BEN- download these images
const dashBorderSource = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%2313115BFF' stroke-width='2' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`;
const disabledDashBorderSource = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23768299' stroke-width='2' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`;

export default makeStyles(theme => ({
  headerOuterContainer: {
    display: "flex",
    justifyContent: ""
  },
  deleteIcon: {
    width: 18,
    height: 18
  },
  customTableContainer: {
    borderBottom: `2px solid ${vantiColors.blue10}`,
    borderTop: `2px solid ${vantiColors.blue10}`
  },
  customTableCell: {
    width: "240px",
    maxWidth: "240px",
    padding: 22
  },
  thirdWidth: {
    width: "240px"
  },
  tableRow: {
    "&:hover": {
      backgroundColor: vantiColors.gray18
    }
  },
  customTableCellTex: {
    color: vantiColors.darkBlue1
  },
  tabButton: {
    fontFamily: "Inter, sans-serif !important",
    borderBottom: `2m solid ${vantiColors.darkBlue7}`
  },
  tableBodyPlaceholderOuter: {
    backgroundColor: vantiColors.white,
    height: 243
  },
  tableBodyPlaceholderInner: {
    textAlign: "center"
  },
  curationPageContainer: {
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up("1366")]: {
      width: "950px"
    },
    [theme.breakpoints.up("1600")]: {
      width: "1275px"
    }
  },
  newCurationHeader: {
    width: 693
  },
  newCurationHeaderWrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: "-5px"
  },
  denseInput: {
    paddingBottom: "0px !important"
  },
  numberedSection: {
    padding: "10px 0px"
  },
  curationNameWrapper: {
    width: 346
  },
  formControl: {
    width: "100%",
    marginBottom: 26
  },
  container: {
    alignSelf: "center"
  },
  dragAndDropArea: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: theme.palette.vantiColors.lightBlue5,
    cursor: "pointer",
    boxSizing: "border-box",
    height: "93px",
    padding: theme.spacing(0, 2.75),
    marginTop: theme.spacing(3),
    backgroundImage: dashBorderSource,
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: vantiColors.lightGray8
    },
    overflow: "hidden",
    position: "relative"
  },
  areaDisabled: {
    backgroundImage: disabledDashBorderSource,
    backgroundColor: "transparent",
    pointerEvents: "none"
  },
  whiteBackground: {
    backgroundColor: theme.palette.vantiColors.white
  },
  chip: {
    backgroundColor: theme.palette.vantiColors.lightGray12,
    display: "flex",
    height: "32px",
    padding: "8px 8px 8px 12px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14,
    color: "#091733",
    marginBottom:17
  },
  chipDeleteIconContainer: {
    display: "flex",
    alignItems: "center",
    paddingRight: 5
  },
  tableContainer: {
    background: "transparent",
    border: `1px solid ${theme.palette.vantiColors.lightGray4}`,
    // width: "fit-content",
    marginRight: "1px",
    boxShadow: "none"
  },
  table: {
    minWidth: 650,
    "& th": {
      padding: theme.spacing(2, 1, 0, 4.75),
    },
    "& td": {
      paddingLeft: theme.spacing(4.75),
      paddingTop: 0,
      paddingBottom: theme.spacing(0.75),
      fontSize: "16px",
      fontWeight: 400,
      color: theme.palette.vantiColors.darkBlue1,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "170px"
    },
    "& tbody > tr:nth-last-child(2)": {
      opacity: 0.5
    },
    "& tbody > tr:last-of-type": {
      opacity: 0.3
    },
    "& th, & td": {
      borderBottom: "none",
      borderRight: `1px solid ${theme.palette.vantiColors.lightGray4}`,
      "&:last-of-type": {
        border: "none"
      }
    }
  },
  wrapper: {
    position: "relative",
    width: "89vw",
    marginLeft:"22px"
  },
  scrollContainer: {
    position: "relative",
    overflow: "auto",
    // paddingBottom: theme.spacing(2.75),
    maxWidth: "100%",
    padding: "0px !important",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      height: "6px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.vantiColors.gray19,
      borderRadius: "40px"
    },
    "&::-webkit-scrollbar-thumb:active": {
      background: theme.palette.vantiColors.blue5
    }
  },
  dataTableContainer: {
    width: "100%"
  },
  headCell: {
    marginRight: theme.spacing(1),
    fontWeight: 700,
    fontSize: "17px",
    lineHeight: "26px",
    color: theme.palette.vantiColors.darkBlue1,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  heading: {
    display: "flex",
    resize: "horizontal",
    alignItems: "center",
    overflow: "auto",
    width: "170px",
    minWidth: "70px",
    paddingBottom: theme.spacing(1.125)
  },
  codeEditor: {
    // width: "100% !important",
    overflow: "visible",
    maxHeight: "100%",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    backgroundColor: vantiColors.white,
    fontFamily: "'Anonymous Pro', monospace",
    lineHeight: "16px",
    position: "relative",

    /* Scrollbars */
    "& .ace_scrollbar-v": {
      position: "absolute",
      top: 10,
      right: 10,
      maxHeight: "100%",
      height: "auto !important",
      marginBottom: "10px",
      "&::-webkit-scrollbar": {
        width: "8px"
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.vantiColors.lightBlue7,
        borderRadius: "40px"
      },
      "&::-webkit-scrollbar-thumb:active": {
        background: theme.palette.vantiColors.blue5
      }
    },
    "& .ace_scrollbar-h": {
      marginBottom: "-25px",
      "&::-webkit-scrollbar": {
        height: "6px"
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.vantiColors.lightBlue7,
        borderRadius: "40px"
      },
      "&::-webkit-scrollbar-thumb:active": {
        background: theme.palette.vantiColors.blue5
      }
    },
    /* Scrollbars */

    "& > div:first-of-type": {
      backgroundColor: "transparent",
      marginRight: "10px"
    },
    "& div.ace_active-line": {
      background: "transparent !important"
    },
    "& div.ace_bracket": {
      display: "none !important"
    },
    "& div.ace_cursor": {
      opacity: 0
    },
    "& div.ace_gutter-cell": {
      width: "fit-content",
      margin: "0",
      padding: "0 2px"
    },
    "& .ace_scroller.ace_scroll-left": {
      boxShadow: "none !important"
    },
    "& div.ace_gutter-active-line": {
      backgroundColor: theme.palette.vantiColors.lightBlue7
    },
    "& span.ace_variable": {
      color: theme.palette.vantiColors.darkBlue1
    },
    "& span.ace_string": {
      color: theme.palette.vantiColors.blue4
    },
    "& .ace_content": {
      height: "100% !important",
      margin: "18px !important"
    }
  },
  codeErrorsMarker: {
    position: "absolute",
    backgroundColor: theme.palette.vantiColors.danger1
  },
  codeEditorContainer: {
    position: "relative"
  },
  iconContainer: {
    display: "flex",
    marginRight: 8
  },
  downloadButton: {
    margin: 0
  },
  buttonContainer: {
    margin: "19px 0 0 19px"
  },
  nameNextIconButton: {
    height: "100% !important",
    transition: "0.3s all ease-in-out",
    marginRight: theme.spacing(1),
    "& > button": {
      padding: 0
    }
  },
  nameNextIconButtonDisabled: {
    cursor: "not-allowed !important",
    opacity: 0.3
  },
  fileUploadErrorSection: {
    padding: "0px !important",
    margin: "0px !important"
  },
  curationLoadingContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  fab: {
    backgroundColor: `${vantiColors.lightGray4} !important`
  },
  headerTitleRow: {
    display: "flex",
    alignItems: "center",
    width: "250px"
  },
  headerCloseButton: {
    marginRight: theme.spacing(1.875),
    marginLeft: theme.spacing(-7)
  },
  tabsRoot: {
    marginBottom: 16
  },
  tabsIndicator: {
    height: "2px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    backgroundColor: vantiColors.darkBlue7
  },
  circularProgress: {
    color: theme.palette.vantiColors.lightGray6
  },
  errorContainer: {
    marginTop: theme.spacing(1),
    paddingBottom: 0,
    minHeight: "fit-content",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    boxSizing: "border-box",
    textAlign: "left"
  }
}));