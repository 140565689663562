import React, { memo, useCallback, useState } from "react";
import { styled } from "@material-ui/core";
import CheckboxFilled24 from "assets/icons/checkbox/CheckboxFilled24";
import PinIcon24 from "assets/icons/push-pin/PinIcon24";
import TrashIcon24 from "assets/icons/trash-icon/TrashIcon24";
import { vantiColors } from "assets/jss/palette";
import { Text } from "components/ui";
import useReportRequest from "common/hooks/use-report-request";
import CustomDeleteConfirmationDialog from "pages/side-bar/data-page/components/CustomDeleteConfirmationDialog";
import RotatingLoadingIcon from "assets/icons/RotatingLoadingIcon";

const BulkActionsPopupStyled = styled("div")({
  position: "absolute",
  bottom: 26,
  left: "50%",
  transform: "translateX(-50%)",
  backgroundColor: vantiColors.gray29,
  width: 368,
  height: 48,
  borderRadius: 4,
  display: "flex",
  padding: "14px 16px 14px 24px",
  boxSizing: "border-box",
  justifyContent: "space-between"
});

const RightWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  width: 135,
  justifyContent: "flex-end",
  columnGap: 16
});

const LeftWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: 8,
  maxWidth: 160,
  flex: 1
});

const ClickableWrapper = styled("div")({
  cursor: "pointer",
  display: "flex"
});

const BulkActionsPopup = ({ selectedTableRows }) => {
  const { deleteReports } = useReportRequest();
  const [isDeletingInProgress, setIsDeletingInProgress] = useState(false);

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const onClickDelete = useCallback(() => {
    deleteReports.mutate({ ids: selectedTableRows.map(row => row.original._id) });
    setIsDeletePopupOpen(false);
    setIsDeletingInProgress(true);
  }, [selectedTableRows]);

  return (
    <BulkActionsPopupStyled>
      <LeftWrapper>
        <CheckboxFilled24 />
        <Text color={vantiColors.white}>{selectedTableRows.length} reports selected</Text>
      </LeftWrapper>

      <RightWrapper>
        {/*<ClickableWrapper>*/}
        {/*  <PinIcon24 color={vantiColors.gray25} />*/}
        {/*</ClickableWrapper>*/}

        <ClickableWrapper>
          {isDeletingInProgress ? (
            <RotatingLoadingIcon width={16} height={16} color={vantiColors.gray25} />
          ) : (
            <TrashIcon24
              stroke={vantiColors.gray25}
              onClick={() => {
                setIsDeletePopupOpen(true);
              }}
            />
          )}
        </ClickableWrapper>
      </RightWrapper>

      <CustomDeleteConfirmationDialog
        isOpenedCondition={isDeletePopupOpen}
        entity={{}}
        deletionWarningText={"Are you sure you want to delete these reports?"}
        onCloseCallback={() => setIsDeletePopupOpen(false)}
        onClickCancelCallback={() => setIsDeletePopupOpen(null)}
        onClickDeleteCallback={onClickDelete}
        isDisabled={false}
      />
    </BulkActionsPopupStyled>
  );
};

export default memo(BulkActionsPopup);
