export const dataConnectorsFlagTypes = {
  DATA_CONNECTORS_SET_UPLOAD_DATA: "data.connectors.set.upload.data",
  DATA_CONNECTORS_UPLOAD_SET_SUCCESS: "data.connectors.upload.set.success",
  DATA_CONNECTORS_UPLOAD_SET_ERROR: "data.connectors.upload.set.error",
  DATA_CONNECTORS_CLEAR_UPLOAD_DATA: "data.connectors.clear.upload.data",
  DATA_CONNECTORS_UPLOAD_CLEAR_SUCCESS: "data.connectors.upload.clear.success",
  DATA_CONNECTORS_UPLOAD_CLEAR_ERROR: "data.connectors.upload.clear.error",

  DATA_CONNECTORS_START_FETCH: "data.connectors.start.fetch",
  DATA_CONNECTORS_FETCH_SUCCESS: "data.connectors.fetch.success",
  DATA_CONNECTORS_FETCH_ERROR: "data.connectors.fetch.error",

  DATA_CONNECTORS_ADD_CONNECTOR_TO_STATE: "data.connector.add.connector.to.state",
  DATA_CONNECTORS_EDIT_CONNECTOR: "data.connector.edit.connector",
  DATA_CONNECTORS_REMOVE_CONNECTOR_FROM_STATE: "data.connector.remove.connector.from.state"
};
