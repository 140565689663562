import React, { forwardRef, memo } from "react";
import classNames from "classnames";

import useStyles from "./Text.style";
import { vantiColors } from "assets/jss/palette";

const Text = (
  {
    className,
    children,
    color = vantiColors.darkBlue6,
    weight,
    uppercase,
    capitalize,
    underline,
    h,
    body,
    subtitle,
    family = "Inter",
    size,
    lineSize,
    disabled,
    lineClamp,
    cursor,
    style = {},
    ...rest
  },
  ref
) => {
  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={classNames(classes.defaultFontStyle, {
        [classes.disabled]: disabled,
        [classes[family + "FontFamily"]]: family,
        [classes[weight + "FontWeight"]]: weight,
        [classes.uppercase]: uppercase,
        [classes.capitalize]: capitalize,
        [classes.underline]: underline,
        [classes["h" + h]]: h,
        [classes["subtitle" + subtitle]]: subtitle,
        [classes["body" + body]]: body,
        [className]: className !== undefined
      })}
      style={{
        ...(size && { fontSize: size }),
        ...{ color: color },
        ...(lineSize && { lineHeight: lineSize }),
        ...(weight && { fontWeight: weight }),
        ...(lineClamp && {
          display: "-webkit-box",
          ["-webkit-line-clamp"]: lineClamp.toString(),
          ["-webkit-box-orient"]: "vertical",
          overflow: "hidden"
        }),
        ...{ cursor },
        ...style
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default memo(forwardRef(Text));
