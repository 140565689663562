import http from "../http";
import config from "common/config";

const webNotificationsApi = `${config.serverUrl}/api/v2/web-notifications`;

export async function deleteWebNotification(webNotificationId) {
  const res = await http.delete(`${webNotificationsApi}/${webNotificationId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function getWebNotifications() {
  const res = await http.get(`${webNotificationsApi}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function getWebNotificationById(webNotificationId) {
  const res = await http.get(`${webNotificationsApi}/${webNotificationId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  if (!result.ok) {
    throw new Error(result.message);
  }

  return res.data;
}

export async function getWebNotificationByStationId(stationId) {
  const res = await http.get(`${webNotificationsApi}?stationId=${stationId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  return result.data;
}

/**
 * @param {string} webNotificationId
 * @param {object} notification
 * @returns {Promise<Block>}
 */
export async function updateWebNotification(webNotificationId, notification, emit) {
  const res = await http.patch(`${webNotificationsApi}/${webNotificationId}?emit=${emit}`, notification);

  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}
