import config from "../../common/config";
import http from "../http";

// Server
const host = config.serverUrl;
const emailsApi = `${host}/admin/v2/emails`;

/**
 * @param {string} title
 * @returns {Promise<Object>}
 */
export async function createEmail(title) {
  const res = await http.post(`${emailsApi}`, { title });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @returns {Promise<Object>}
 */
export async function queryEmail() {
  const res = await http.get(`${emailsApi}`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @param {string} emailId
 * @returns {Promise<Object>}
 */
export async function getEmail(emailId) {
  const res = await http.get(`${emailsApi}/${emailId}`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @param {string} emailId
 * @param {string} title
 * @param {string} action
 * @param {string} constants
 * @param {string} body
 * @param {string} bodySchema
 * @param {string} subject
 * @param {string} subjectSchema
 * @returns {Promise<Object>}
 */
export async function updateEmail(emailId, { title, action, constants, body, bodySchema, subject, subjectSchema }) {
  const res = await http.patch(`${emailsApi}/${emailId}`, {
    title,
    action,
    constants,
    body,
    bodySchema,
    subject,
    subjectSchema
  });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @param {string} emailId
 * @returns {Promise<Object>}
 */
export async function deleteEmail(emailId) {
  const res = await http.delete(`${emailsApi}/${emailId}`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @param {string} emailId
 * @param {Array<string>} to
 * @param {Array<string>} bcc
 * @param {Object} body
 * @param {Object} subject
 * @returns {Promise<Object>}
 */
export async function sendEmail(emailId, { to, bcc, body, subject }) {
  const res = await http.post(`${emailsApi}/${emailId}/send`, { to, bcc, body, subject });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}
