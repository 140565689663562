import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import reducer from "./reducer";
import { createModelWatcher, createStationWatcher } from "./sagas";

export default combineReducers({
  state: reducer
});

export function* sagas() {
  yield all([createModelWatcher(), createStationWatcher()]);
}
