export default {
  NOT_SUPPORTED: "File type is not supported.",
  FAILED_PARSING: "Failed parsing csv file.",
  EMPTY_FILE: "Empty file is not supported.",
  FILENAME_SPACE: "The file name includes spaces, please remove all spaces and try again.",
  ZIP_WITHOUT_CSV: "Only a CSV file is supported inside a ZIP file.",
  ZIP_WITHOUT_FOLDERS:
    "Images are not organized into separate folders. If you have a model target, please upload a zip file containing a folder with images for each target variable.",
  ZIP_WITH_FOLDERS:
    "Images are organized into separate folders. If you don’t have a model target, please upload a zip file with a single folder containing images.",
  ZIP_NUM_OF_FOLDERS: "Images must be organized into at least two separate folders.",
  ZIP_SUPERVISED_STRUCTURE:
    "To create a valid zip file, please compile the relevant folders that include the images into a designated folder and then compress the folder.",
  ZIP_UNSUPERVISED_STRUCTURE:
    "To create a valid zip file, please choose all relevant images and compress them into a zip folder.",
  ZIP_EMPTY_FOLDER: "The file uploaded contains empty folder/s, please remove the empty folder/s or add images."
};
