import { Text } from "components/ui";
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { makeStyles, styled } from "@material-ui/core";
import { CURATED_DATASETS_TEXT, UPLOADED_FILES_TEXT } from "../../../data-page/components/DataRowsWrapper";
import CloseIcon16 from "assets/icons/close-icon/CloseIcon16";
import { vantiColors } from "assets/jss/palette";
import DynamicTabs from "components/ui/Tabs";
import GeneralVantiButton, { VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { useDispatch, useSelector } from "react-redux";
import { allDataConnectorsSelector } from "modules/data-connectors/selectors";
import { dataConnectorsStartFetch } from "modules/data-connectors/actions";
import { allDataCurationsSelector } from "modules/data-curations/selectors";
import { fetchCurations } from "modules/data-curations/actions";
import DatasetRubric from "../../../data-page/components/dataset-rubric/DatasetRubric";
import { buildDatasetForDisplay, SOURCE_TYPES } from "../../../data-page/constants";
import { AppContext } from "common/hooks/context-hooks/use-app-context";

const DatasetSelectionContentHeader = styled("div")({
  boxSizing: "border-box",
  justifyContent: "space-between",
  display: "flex",
  paddingBottom: 24
});

const DatasetSelectionContentBody = styled("div")({
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  paddingBottom: 16
});

const DatasetSelectionContentFooter = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const DatasetSelectionContentBodyWrapper = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  flexWrap: "wrap",
  gap: 8,
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: "8px"
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: vantiColors.lightBlue7,
    borderRadius: "40px"
  }
});

const DynamicTabsWrapper = styled("div")({
  paddingBottom: 24
});

const DATASET_SELECTION_MODAL_HEADER_TITLE = "Add datasets";

const DATASET_SELECTION_MODAL_DEFAULT_TAB_TITLES = {
  CURATED_DATASETS_TAB_TITLE: CURATED_DATASETS_TEXT,
  UPLOADED_FILES_TAB_TITLE: UPLOADED_FILES_TEXT
};

const useStyles = makeStyles(theme => ({
  tabsRoot: {
    minHeight: 1
  },
  tabRoot: {
    minHeight: 1,
    minWidth: 1,
    lineHeight: "normal",
    color: vantiColors.gray33,
    boxSizing: "border-box",
    borderBottom: `2px solid white`,
    "&.Mui-selected": {
      color: "black",
      borderBottom: `2px solid ${theme.palette.vantiColors.darkBlue7}`
    }
  },
  tabsIndicator: {
    display: "none"
  },
  tabsFlexContainer: {
    display: "flex",
    gap: 16
  }
}));

const AddDatasetModal = ({ closeDataSelectionModal }) => {
  const classes = useStyles();
  const [selectedDataset, setSelectedDataset] = useState(null);
  const dispatch = useDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const dataConnectors = useSelector(allDataConnectorsSelector);
  const dataCurations = useSelector(allDataCurationsSelector);
  const { datasetsContext, chatContext } = useContext(AppContext);
  const { datasets: datasetsObject, selectedDatasetsForCuration } = datasetsContext;
  const { setDisplayOtherDatasets } = chatContext;
  const [isSelectButtonEnabled, setIsSelectButtonEnabled] = useState(false);

  const { setContext } = datasetsContext;

  const datasets = useMemo(() => {
    return Object.values(datasetsObject).map(dataset => buildDatasetForDisplay(dataset)) || [];
  }, [datasetsObject]);

  const datasetSelectionTabTitles = useMemo(() => {
    const tabTitles = [];

    if (datasets.some(ds => ds.sourceType === SOURCE_TYPES.CURATION))
      tabTitles.push({ title: DATASET_SELECTION_MODAL_DEFAULT_TAB_TITLES.CURATED_DATASETS_TAB_TITLE, disabled: false });
    if (datasets?.some(ds => ds.sourceType === SOURCE_TYPES.LOCAL_UPLOAD))
      tabTitles.push({ title: DATASET_SELECTION_MODAL_DEFAULT_TAB_TITLES.UPLOADED_FILES_TAB_TITLE, disabled: false });

    const nonEmptyDataConnectorIds = datasets
      ?.filter(ds => ds.sourceType === SOURCE_TYPES.DATA_CONNECTOR)
      .map(ds => ds?.sourceDataConnectorId.toString());

    for (const dataConnector of dataConnectors) {
      const connectorIdString = dataConnector._id.toString();
      const isEnabled = nonEmptyDataConnectorIds.includes(connectorIdString);
      tabTitles.push({ title: dataConnector.title, disabled: !isEnabled });
    }

    return tabTitles;
  }, [datasets, dataConnectors]);

  useEffect(async () => {
    if (dataConnectors && dataConnectors.length < 1) {
      dispatch(dataConnectorsStartFetch());
    }

    if (dataCurations && dataCurations.length < 1) {
      dispatch(fetchCurations());
    }
  }, []);

  const removeDatasetSelectedForCuration = () => {
    const updatedContext = [];
    setContext(prevValue => ({ ...prevValue, selectedDatasetsForCuration: updatedContext }));
  };

  const tabContent = useMemo(() => {
    if (!datasetSelectionTabTitles) {
      return;
    }

    let filteredDatasets;
    switch (datasetSelectionTabTitles[selectedTabIndex]?.title) {
      case DATASET_SELECTION_MODAL_DEFAULT_TAB_TITLES.CURATED_DATASETS_TAB_TITLE:
        filteredDatasets = datasets.filter(ds => ds.sourceType === SOURCE_TYPES.CURATION);
        break;
      case DATASET_SELECTION_MODAL_DEFAULT_TAB_TITLES.UPLOADED_FILES_TAB_TITLE:
        filteredDatasets = datasets.filter(ds => ds.sourceType === SOURCE_TYPES.LOCAL_UPLOAD);
        break;
      default: {
        const dataConnector = dataConnectors.filter(
          dc => dc.title === datasetSelectionTabTitles[selectedTabIndex]?.title
        )[0];
        filteredDatasets = datasets.filter(
          ds => ds.sourceType === SOURCE_TYPES.DATA_CONNECTOR && ds.sourceDataConnectorId === dataConnector?._id
        );
        break;
      }
    }

    return filteredDatasets.map((dataset, index) => (
      <DatasetRubric
        dataset={dataset}
        key={`dataset-rubric-${index}-${dataset.id}`}
        onDownloadDataset={() => {}}
        onMoveDataset={() => {}}
        onDeleteDataset={() => {}}
        checkSupportedDatasetType={true}
        isReadOnly={true}
      />
    ));
  }, [selectedDataset, selectedTabIndex, datasets, dataCurations, dataConnectors]);

  const handleChange = useCallback((event, newValue) => {
    setSelectedTabIndex(newValue);
  }, []);

  return (
    <>
      <DatasetSelectionContentHeader data-testid={"dataset-selection-content-header"}>
        <Text size={18} weight={500} lineSize={"22px"}>
          {DATASET_SELECTION_MODAL_HEADER_TITLE}
        </Text>
        <CloseIcon16 onClick={() => setDisplayOtherDatasets(false)} style={{ cursor: "pointer" }} />
      </DatasetSelectionContentHeader>
      {datasetSelectionTabTitles && (
        <DynamicTabsWrapper>
          <DynamicTabs
            tabProps={{ classes: { root: classes.tabRoot } }}
            tabsProps={{
              classes: {
                root: classes.tabRoot,
                indicator: classes.tabsIndicator,
                flexContainer: classes.tabsFlexContainer
              }
            }}
            tabs={datasetSelectionTabTitles}
            selectedTabIndex={selectedTabIndex}
            handleChange={handleChange}
            indicatorColor={vantiColors.darkBlue7}
            data-testid={"dataset-selection-content-tabs"}
          />
        </DynamicTabsWrapper>
      )}
      <DatasetSelectionContentBody data-testid={"dataset-selection-content-body"}>
        {datasetSelectionTabTitles?.length ? (
          <DatasetSelectionContentBodyWrapper>{tabContent}</DatasetSelectionContentBodyWrapper>
        ) : null}
      </DatasetSelectionContentBody>
      <DatasetSelectionContentFooter>
        <selectedDatasetsContainerStyled>
          <CloseIcon16
            onClick={() => removeDatasetSelectedForCuration()}
            style={{ cursor: "pointer", verticalAlign: "middle" }}
          />

          <Text style={{ verticalAlign: "" }}> {selectedDatasetsForCuration.length} selected </Text>
        </selectedDatasetsContainerStyled>

        <GeneralVantiButton
          variant={VARIANTS.PRIMARY}
          buttonInfo={{
            disabled: !selectedDatasetsForCuration.length,
            onClick: () => {
              setIsSelectButtonEnabled(false);
              closeDataSelectionModal();
            },
            label: "Add"
          }}
          style={{ width: "fit-content", alignSelf: "flex-end" }}
        />
      </DatasetSelectionContentFooter>
    </>
  );
};

export default memo(AddDatasetModal);
