import React, { memo, useCallback, useState } from "react";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import usePermissions from "common/hooks/use-permissions";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import PencilIcon16In24 from "assets/icons/pencil-icon/PencilIcon16In24";
import { vantiColors } from "assets/jss/palette";
import { updateMessageReportExecSummary } from "services/api/chat";
import capitalize from "lodash/capitalize";

const ReportHeadlineContainer = styled(Text)(({ isEllipsis }) => ({
  width: isEllipsis ? "90%" : "100%",
  boxSizing: "border-box",
  display: "flex",
  gap: 16
}));

const ReportHeadline = styled(Text)(({ isEllipsis }) => ({
  height: "24px",
  width: "100%",
  boxSizing: "border-box",
  textOverflow: isEllipsis ? "ellipsis" : "unset",
  overflow: isEllipsis ? "hidden" : "unset",
  whiteSpace: isEllipsis ? "nowrap" : "unset",
  display: isEllipsis ? "inline-block" : "unset"
}));

const ReportHeadlineWrapper = styled("div")(() => ({
  maxWidth: "100%",
  position: "relative"
}));

const PencilIcon24Styled = styled(PencilIcon16In24)(() => ({
  cursor: "pointer",
  position: "absolute",
  right: "-36px",
  top: "0px",
  boxSizing: "border-box",
  backgroundColor: vantiColors.gray36,
  borderRadius: "50%",
  padding: 0
}));

const InputStyled = styled("input")(({ isEditingHeadline }) => ({
  height: "21px", //combination of height + margin
  marginBottom: 3, //to avoid height changes when switching between Text and Input
  width: "95%",
  fontSize: 16,
  padding: 0,
  margin: 0,
  fontFamily: "Inter",
  fontWeight: 600,
  lineHeight: "20px",
  color: vantiColors.gray29,
  backgroundColor: "transparent",
  boxSizing: "border-box",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  borderBottom: isEditingHeadline ? "1px solid black" : "none"
}));

const MAX_EXEC_SUMMARY_HEADLINE_TEXT_LENGTH = 256;

const ReportExecutiveSummarySectionHeadline = ({
  message,
  onHeadlineUpdated,
  isEditable = true,
  isEllipsis = false,
  size = 16,
  weight = 600,
  lineSize = "20px"
}) => {
  const headline = message.reportData.name || message.reportData.question;
  const { chatId, _id: messageId } = message;
  const isAllowedToEditChatReports = usePermissions([ROLES_ENUM.EDIT_CHAT_REPORTS]);
  const [isHovered, setIsHovered] = useState(false);
  const [isEditingHeadline, setIsEditingHeadline] = useState(false);
  const [editedHeadline, setEditedHeadline] = useState(headline);
  const [displayedHeadline, setDisplayedHeadline] = useState(headline);

  const updateHeadline = useCallback(
    async newHeadline => {
      if (newHeadline && newHeadline.length) {
        setDisplayedHeadline(newHeadline);
        setIsEditingHeadline(false);

        if (onHeadlineUpdated) {
          onHeadlineUpdated({ question: newHeadline });
        } else {
          await updateMessageReportExecSummary(chatId, messageId, { question: newHeadline });
        }
      } else {
        setEditedHeadline(headline);
        setDisplayedHeadline(headline);
        setIsEditingHeadline(false);
      }
    },
    [chatId, messageId, headline]
  );

  return (
    isAllowedToEditChatReports && (
      <ReportHeadlineContainer
        isEllipsis={isEllipsis}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        {isEditingHeadline && isEditable ? (
          <InputStyled
            onKeyDown={e => {
              if (e.key === "Enter" || e.key === "Tab") {
                updateHeadline(editedHeadline);
              }
              if (e.key === "Escape") {
                setEditedHeadline(displayedHeadline);
                setDisplayedHeadline(displayedHeadline);
                setIsEditingHeadline(false);
              }
            }}
            open={true}
            type={"text"}
            size={editedHeadline.length}
            maxLength={MAX_EXEC_SUMMARY_HEADLINE_TEXT_LENGTH}
            value={editedHeadline}
            disabled={!isEditable}
            isEditingHeadline={isEditingHeadline}
            autoFocus
            onChange={e => setEditedHeadline(e.target.value)}
            onBlur={e => {
              updateHeadline(editedHeadline);
            }}
          />
        ) : (
          <ReportHeadlineWrapper>
            <ReportHeadline
              size={size}
              weight={weight}
              lineSize={lineSize}
              isEllipsis={isEllipsis}
              onClick={() => setIsEditingHeadline(true)}
            >
              {capitalize(displayedHeadline)}
            </ReportHeadline>
            {isEditable && isHovered && <PencilIcon24Styled onClick={() => setIsEditingHeadline(true)} />}
          </ReportHeadlineWrapper>
        )}
      </ReportHeadlineContainer>
    )
  );
};

export default memo(ReportExecutiveSummarySectionHeadline);
