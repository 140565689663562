import React, { memo } from "react";
import { styled } from "@material-ui/core";
import { FlexItem } from "components/ui";
import EmptyBellNotificationIcon from "assets/icons/notification-center/EmptyBellNotificationIcon";
import { vantiColors } from "assets/jss/palette";
import { NOTIFICATION_CENTER_USER_MESSAGE_EMPTY_NOTIFICATIONS_LABEL_TEXT } from "common/constants/NotificationConstants";

const EmptyAccountNotificationsItemStyled = styled(FlexItem)(() => ({
  height: "149px",
  width: "346px",
  flexDirection: "column",
  alignItems: "center"
}));

const EmptyBellNotificationIconStyled = styled(EmptyBellNotificationIcon)(({ theme }) => ({
  padding: theme.spacing(2, 0, 2, 0)
}));

const EmptyAccountNotificationsItemTextStyled = styled("div")(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: theme.spacing(1.75),
  fontWeight: 500,
  letterSpacing: "0em",
  textAlign: "left",
  color: vantiColors.gray33
}));

const EmptyAccountNotificationsItem = () => {
  return (
    <EmptyAccountNotificationsItemStyled container dense={"full"} data-testid={"empty-account-notification-item-id"}>
      <EmptyBellNotificationIconStyled data-testid={"empty-account-bell-notification-icon-id"} />
      <EmptyAccountNotificationsItemTextStyled data-testid={"empty-account-user-message-id"}>
        {NOTIFICATION_CENTER_USER_MESSAGE_EMPTY_NOTIFICATIONS_LABEL_TEXT}
      </EmptyAccountNotificationsItemTextStyled>
    </EmptyAccountNotificationsItemStyled>
  );
};

export default memo(EmptyAccountNotificationsItem);
