import { vantiColors } from "assets/jss/palette";
import React, { memo } from "react";

const ArrowDownIcon20 = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.2863 8.5714L10.0006 12.8571L5.71484 8.5714" stroke={vantiColors.gray29} />
    </svg>
  );
};

export default memo(ArrowDownIcon20);
