import React from "react";
import classNames from "classnames";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  section: {
    width: "100%",
    // flex: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    wordWrap: "break-word"
  }
});

export default function CardSection(props) {
  const classes = useStyles();
  const { className, children, ...rest } = props;
  const sectionClasses = classNames({
    [classes.section]: true,
    [className]: className !== undefined
  });
  return (
    <div className={sectionClasses} {...rest}>
      {children}
    </div>
  );
}

CardSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
