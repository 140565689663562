import React, { useEffect, useState } from "react";
import useQuery from "common/hooks/use-query";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import * as signInByEmailSelectors from "modules/auth/operations/signInByEmail/selectors";
import * as signInByEmailActions from "modules/auth/operations/signInByEmail/actions";
import * as signOutActions from "modules/auth/operations/signOut/actions";
import { useHistory } from "react-router-dom";
import FlexItem from "components/ui/Flex/FlexItem.js";
import FlexContainer from "components/ui/Flex/FlexContainer.js";
import Button from "components/ui/Buttons/Button.js";
import CardBody from "components/ui/Card/CardBody";
import CardHeader from "components/ui/Card/CardHeader";
import Text from "components/ui/Typography/Text";
import TextUnderlinedInput from "components/ui/Inputs/TextUnderlinedInput";
import { ReactComponent as LogoWhiteGreenDark } from "assets/img/vanti-logo/logo-white-green-dark.svg";
import AuthCard from "./components/auth-card";
import BrandedTitle from "components/ui/Typography/BrandedTitle";
import { emailVerifiedSelector, uidSelector } from "modules/auth/state/selectors";
import { KEY_CODES } from "common/constants/CommonConstants";
import { vantiColors } from "assets/jss/palette";
import * as authService from "services/auth/auth";
import { getShowPasswordProps } from "./Utils";
import GeneralVantiButton from "components/ui/Buttons/GeneralVantiButton";

const FORGOT_PASSWORD_TEXT = "Forgot password";
const DONT_HAVE_A_USER_TEXT = "don't have a user? ";
const ACCOUNT_TEXT = "account";
const JOIN_TEXT = "join";
const shouldAllowToCreateAccount = false;

const styles = theme => ({
  cardHeader: {
    padding: "0",
    display: "flex",
    justifyContent: "center"
  },
  cardBody: {
    position: "relative",
    padding: "20px 40px"
  },
  AzureSignInButton: {
    display: "flex",
    alignItems: "flex-start"
  },
  azureIcon: {
    margin: "-2px 10px 0 0"
  },
  generalErrorTextContainer: {
    textAlign: "center"
  },
  mainErrorWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  triangleIcon: {
    marginRight: "10px",
    width: "18px"
  },
  errorMainText: {
    fontWeight: 600,
    fontSize: "17px",
    color: theme.palette.primaryColor[2]
  },
  errorExplanation: {
    fontSize: "14px",
    marginTop: "5px",
    color: theme.palette.primaryColor[2]
  },
  mailToLink: {
    "&:active, &:hover, &:visited": {
      color: theme.palette.primaryColor[2],
      textDecoration: "underline"
    },
    color: theme.palette.primaryColor[2],
    textDecoration: "underline"
  }
});

const useStyles = makeStyles(styles);

export default function SignIn() {
  const query = useQuery();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const uid = useSelector(uidSelector);
  const emailVerified = useSelector(emailVerifiedSelector);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [generalError, setGeneralError] = useState();
  const loadingSignIn = useSelector(signInByEmailSelectors.loadingSelector);
  const errorSignIn = useSelector(signInByEmailSelectors.errorSelector);

  const onClickSignIn = () => dispatch(signInByEmailActions.signInRequestAction(email, password));
  const onClickForgotPassword = () => history.push("/auth/forgot-password");
  const onClickJoinAccount = () => {
    const { accountName, encryptedAccountId, token, marketplace } = query;

    const accountNameEncoded = `accountName=${encodeURIComponent(accountName)}`;
    const encryptedAccountIdEncoded = `encryptedAccountId=${encodeURIComponent(encryptedAccountId)}`;
    const tokenEncoded = `token=${encodeURIComponent(token)}`;
    const marketplaceEncoded = `marketplace=${encodeURIComponent(marketplace)}`;
    const modeEncoded = `mode=createUserEmailViaMarketplace`;

    const queryParams = [
      modeEncoded,
      accountNameEncoded,
      encryptedAccountIdEncoded,
      tokenEncoded,
      marketplaceEncoded
    ].join("&");

    history.push(`/auth/action?${queryParams}`);
  };

  useEffect(() => {
    if (uid && emailVerified) {
      if (query.marketplace && query.token) {
        authService.signOut();
      } else {
        history.push("/dashboard/stations");
        return;
      }
    }

    dispatch(signInByEmailActions.signInClearAction());
    return () => {
      dispatch(signInByEmailActions.signInClearAction());
      dispatch(signOutActions.signOutClearAction());
    };
  }, []);

  useEffect(() => {
    setGeneralError();
  }, [email, password]);

  useEffect(() => {
    setGeneralError(errorSignIn);
  }, [loadingSignIn, errorSignIn]);

  const isSignInDisabled = !email || !password || generalError || loadingSignIn;

  const onKeyDown = event => {
    if (!isSignInDisabled && KEY_CODES.ENTER === event.keyCode) {
      onClickSignIn();
    }
  };

  return (
    <FlexContainer justifyContent="center">
      <FlexItem xs={12} sm={8} md={6} container direction="row">
        <AuthCard withSignUp={false}>
          <CardHeader color="transparent" className={classes.cardHeader}>
            <div data-testid="sign-in-vanti-logo">
              <LogoWhiteGreenDark height="50px" />
            </div>
          </CardHeader>

          <CardBody className={classes.cardBody}>
            <BrandedTitle text="Sign In" />

            <TextUnderlinedInput
              labelText="Email"
              dataTestId="signin-error-message"
              id="email-login"
              labelProps={{ shrink: true }}
              formControlProps={{ fullWidth: true, margin: "dense" }}
              inputProps={{
                value: email,
                onChange: e => setEmail(e.target.value),
                onKeyDown: onKeyDown
              }}
              error={generalError}
            />

            <TextUnderlinedInput
              labelText="Password"
              id="password-login"
              labelProps={{ shrink: true }}
              formControlProps={{ fullWidth: true, margin: "dense" }}
              inputProps={{
                ...getShowPasswordProps(showPassword, setShowPassword),
                value: password,
                onChange: e => setPassword(e.target.value),
                onKeyDown: onKeyDown
              }}
              error={generalError}
            />

            <Button
              data-testid="forgot-password-btn"
              transparent
              size="small"
              onClick={onClickForgotPassword}
              style={{ padding: 0 }}
            >
              <Text color={vantiColors.darkBlue7} size="14px">
                {FORGOT_PASSWORD_TEXT}
              </Text>
            </Button>

            <GeneralVantiButton
              data-testid="sign-in-btn"
              block
              disabled={isSignInDisabled}
              onClick={onClickSignIn}
              size="small"
              buttonInfo={{
                onClick: onClickSignIn,
                label: "Sign In"
              }}
            />

            {(generalError || query.error) && (
              <FlexItem xs={12} className={classes.generalErrorTextContainer}>
                <Text data-testid="error-text" size="14px" weight="bold">
                  {generalError ?? query.error}
                </Text>
              </FlexItem>
            )}

            {shouldAllowToCreateAccount && query.marketplace && query.token && query.accountName && (
              <FlexItem xs={12} className={classes.generalErrorTextContainer}>
                <Text size="12px">{DONT_HAVE_A_USER_TEXT}</Text>
                <Button transparent size="small" onClick={onClickJoinAccount} style={{ padding: 0 }}>
                  <Text color={vantiColors.green1} size="12px">
                    {JOIN_TEXT}{" "}
                  </Text>
                </Button>
                <Text size="12px" weight="bold">
                  {" "}
                  {query.accountName}{" "}
                </Text>
                <Text size="12px">{ACCOUNT_TEXT}</Text>
              </FlexItem>
            )}
          </CardBody>
        </AuthCard>
      </FlexItem>
    </FlexContainer>
  );
}
