import React, { memo } from "react";

const HomePageEmptyStateIcon = () => {
  return (
    <svg width="278" height="264" viewBox="0 0 278 264" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="142.5" cy="129.5" r="98.5" fill="#E6E7F6" />
      <g filter="url(#filter0_d_1044_28628)">
        <rect
          x="161.561"
          y="2.81592"
          width="110"
          height="110"
          rx="8"
          transform="rotate(11.9336 161.561 2.81592)"
          fill="white"
          shape-rendering="crispEdges"
        />
        <rect
          x="165.429"
          y="61.8925"
          width="18"
          height="37"
          transform="rotate(11.9336 165.429 61.8925)"
          fill="#B3B8E4"
        />
        <rect
          x="199.123"
          y="47.5496"
          width="18"
          height="58"
          transform="rotate(11.9336 199.123 47.5496)"
          fill="#6871C8"
        />
        <rect x="231.37" y="40.0555" width="18" height="72" transform="rotate(11.9336 231.37 40.0555)" fill="#0313A4" />
      </g>
      <g filter="url(#filter1_d_1044_28628)">
        <circle cx="73" cy="183" r="59" fill="white" />
        <path
          d="M111.655 183C111.655 204.349 94.3487 221.655 73 221.655C51.6514 221.655 34.3448 204.349 34.3448 183C34.3448 161.651 51.6514 144.345 73 144.345C94.3487 144.345 111.655 161.651 111.655 183ZM53.6724 183C53.6724 193.674 62.3257 202.328 73 202.328C83.6744 202.328 92.3276 193.674 92.3276 183C92.3276 172.326 83.6744 163.672 73 163.672C62.3257 163.672 53.6724 172.326 53.6724 183Z"
          fill="#B3B8E4"
        />
        <path
          d="M111.655 183C111.655 191.036 109.151 198.872 104.491 205.418C99.8305 211.964 93.2461 216.895 85.6531 219.526C78.0602 222.156 69.8362 222.355 62.1252 220.094C54.4141 217.833 47.5992 213.226 42.6284 206.912C37.6575 200.598 34.7779 192.893 34.3899 184.866C34.002 176.84 36.125 168.892 40.4637 162.129C44.8024 155.365 51.1411 150.122 58.5982 147.128C66.0553 144.134 74.26 143.539 82.0713 145.424L77.5357 164.212C73.63 163.269 69.5277 163.567 65.7991 165.064C62.0706 166.561 58.9012 169.183 56.7319 172.564C54.5625 175.946 53.501 179.92 53.695 183.933C53.889 187.946 55.3288 191.799 57.8142 194.956C60.2996 198.113 63.7071 200.417 67.5626 201.547C71.4181 202.677 75.5301 202.578 79.3266 201.263C83.123 199.948 86.4153 197.482 88.7454 194.209C91.0755 190.936 92.3276 187.018 92.3276 183H111.655Z"
          fill="#0313A4"
        />
      </g>
      <g filter="url(#filter2_d_1044_28628)">
        <circle cx="212.5" cy="192.5" r="36.5" fill="white" />
        <path
          d="M213.435 190.263C211.124 190.263 209.159 189.454 207.541 187.836C205.923 186.218 205.114 184.253 205.114 181.942C205.114 179.677 205.923 177.723 207.541 176.081C209.159 174.441 211.124 173.621 213.435 173.621C215.747 173.621 217.711 174.441 219.329 176.081C220.947 177.723 221.756 179.677 221.756 181.942C221.756 184.253 220.947 186.218 219.329 187.836C217.711 189.454 215.747 190.263 213.435 190.263ZM194.019 210.372C194.019 202.078 196.403 197.176 204.665 194.77C207.045 194.077 209.968 193.73 213.435 193.73L215.001 193.828C214.816 194.29 214.349 194.655 214.234 195.117C214.118 195.579 214.63 196.118 214.537 196.581L213.435 196.528C210.338 196.528 207.634 196.815 205.322 197.438C200.636 198.706 196.793 202.535 196.793 207.599H214.267C214.406 208.015 214.568 208.477 214.753 208.985C214.938 209.494 215.146 209.956 215.377 210.372H194.019ZM213.435 187.489C214.961 187.489 216.267 186.946 217.355 185.858C218.44 184.773 218.983 183.467 218.983 181.942C218.983 180.416 218.44 179.111 217.355 178.025C216.267 176.938 214.961 176.394 213.435 176.394C211.91 176.394 210.604 176.938 209.519 178.025C208.432 179.111 207.888 180.416 207.888 181.942C207.888 183.467 208.432 184.773 209.519 185.858C210.604 186.946 211.91 187.489 213.435 187.489Z"
          fill="#B3B8E4"
        />
        <path
          d="M211.963 193.73C215.43 193.73 218.354 194.076 220.734 194.77C228.995 197.175 231.379 202.078 231.379 210.372H210.021C210.253 209.956 210.461 209.493 210.646 208.985C210.83 208.476 210.992 208.014 211.131 207.598H228.605C228.605 202.535 224.762 198.705 220.076 197.438C217.765 196.815 215.06 196.503 211.963 196.503L211.101 196.567C211.009 196.105 211.281 195.579 211.164 195.116C211.05 194.654 210.712 194.282 210.527 193.819L211.963 193.73Z"
          fill="#B3B8E4"
        />
        <path
          d="M213.435 187.489C214.961 187.489 216.267 186.946 217.355 185.858C218.44 184.773 218.983 183.467 218.983 181.942C218.983 180.416 218.44 179.111 217.355 178.025C216.267 176.938 214.961 176.394 213.435 176.394C211.91 176.394 210.604 176.938 209.519 178.025C208.432 179.111 207.888 180.416 207.888 181.942C207.888 183.467 208.432 184.773 209.519 185.858C210.604 186.946 211.91 187.489 213.435 187.489Z"
          fill="#B3B8E4"
        />
        <path
          d="M214.537 196.581L213.435 196.528C210.338 196.528 207.634 196.815 205.322 197.438C200.636 198.706 196.793 202.535 196.793 207.599L211.131 207.598C211.131 207.598 211.131 207.598 211.131 207.598H228.605C228.605 202.535 224.762 198.705 220.076 197.438C218.429 196.994 216.583 196.708 214.537 196.581Z"
          fill="#B3B8E4"
        />
      </g>
      <g filter="url(#filter3_d_1044_28628)">
        <circle cx="65.5" cy="60.5" r="29.5" fill="white" />
        <path
          d="M66.2559 58.6919C64.3878 58.6919 62.7999 58.0381 61.4922 56.7304C60.1845 55.4227 59.5306 53.8348 59.5306 51.9667C59.5306 50.1359 60.1845 48.5569 61.4922 47.2298C62.7999 45.9042 64.3878 45.2414 66.2559 45.2414C68.124 45.2414 69.712 45.9042 71.0197 47.2298C72.3273 48.5569 72.9812 50.1359 72.9812 51.9667C72.9812 53.8348 72.3273 55.4227 71.0197 56.7304C69.712 58.0381 68.124 58.6919 66.2559 58.6919ZM50.5636 74.9447C50.5636 68.2412 52.4902 64.2789 59.1675 62.3348C61.0909 61.7744 63.4537 61.4941 66.2559 61.4941L67.5212 61.5729C67.3717 61.9466 66.9942 62.2414 66.9015 62.615C66.8074 62.9886 67.2212 63.4244 67.1465 63.798L66.2559 63.7553C63.7526 63.7553 61.5669 63.9877 59.6988 64.4914C55.9114 65.5155 52.8054 68.6106 52.8054 72.7029H66.9284C67.0405 73.0392 67.1713 73.4128 67.3208 73.8238C67.4702 74.2348 67.6383 74.6084 67.8251 74.9447H50.5636ZM66.2559 56.4502C67.4889 56.4502 68.5448 56.0108 69.4235 55.132C70.3008 54.2547 70.7394 53.1996 70.7394 51.9667C70.7394 50.7337 70.3008 49.6786 69.4235 48.8013C68.5448 47.9225 67.4889 47.4831 66.2559 47.4831C65.0229 47.4831 63.9678 47.9225 63.0906 48.8013C62.2118 49.6786 61.7724 50.7337 61.7724 51.9667C61.7724 53.1996 62.2118 54.2547 63.0906 55.132C63.9678 56.0108 65.0229 56.4502 66.2559 56.4502Z"
          fill="#0313A4"
        />
        <path
          d="M65.0661 61.4938C67.8683 61.4938 70.2311 61.774 72.1545 62.3345C78.8317 64.2785 80.7584 68.2409 80.7584 74.9444H63.4968C63.6836 74.6081 63.8518 74.2345 64.0012 73.8235C64.1507 73.4125 64.2814 73.0389 64.3935 72.7026H78.5166C78.5166 68.6102 75.4105 65.5151 71.6232 64.491C69.7551 63.9874 67.5694 63.7356 65.0661 63.7356L64.3697 63.7869C64.295 63.4132 64.5146 62.9883 64.4204 62.6147C64.3278 62.2411 64.0548 61.94 63.9054 61.5663L65.0661 61.4938Z"
          fill="#0313A4"
        />
        <path
          d="M66.2559 56.4502C67.4889 56.4502 68.5448 56.0108 69.4235 55.132C70.3008 54.2547 70.7394 53.1996 70.7394 51.9667C70.7394 50.7337 70.3008 49.6786 69.4235 48.8013C68.5448 47.9225 67.4889 47.4831 66.2559 47.4831C65.0229 47.4831 63.9678 47.9225 63.0906 48.8013C62.2118 49.6786 61.7724 50.7337 61.7724 51.9667C61.7724 53.1996 62.2118 54.2547 63.0906 55.132C63.9678 56.0108 65.0229 56.4502 66.2559 56.4502Z"
          fill="#0313A4"
        />
        <path
          d="M67.1465 63.798L66.2559 63.7553C63.7526 63.7553 61.5669 63.9877 59.6988 64.4914C55.9114 65.5155 52.8054 68.6106 52.8054 72.7029L64.3935 72.7026C64.3935 72.7027 64.3936 72.7025 64.3935 72.7026H78.5166C78.5166 68.6102 75.4105 65.5151 71.6232 64.491C70.2922 64.1322 68.8 63.9012 67.1465 63.798Z"
          fill="#0313A4"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1044_28628"
          x="128.816"
          y="-1.18408"
          width="150.368"
          height="150.368"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.429167 0 0 0 0 0.429167 0 0 0 0 0.429167 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_28628" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_28628" result="shape" />
        </filter>
        <filter
          id="filter1_d_1044_28628"
          x="0.436782"
          y="118.575"
          width="145.126"
          height="145.126"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8.13793" />
          <feGaussianBlur stdDeviation="6.78161" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.429167 0 0 0 0 0.429167 0 0 0 0 0.429167 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_28628" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_28628" result="shape" />
        </filter>
        <filter
          id="filter2_d_1044_28628"
          x="167.609"
          y="152.644"
          width="89.7816"
          height="89.7816"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.03448" />
          <feGaussianBlur stdDeviation="4.1954" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.429167 0 0 0 0 0.429167 0 0 0 0 0.429167 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_28628" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_28628" result="shape" />
        </filter>
        <filter
          id="filter3_d_1044_28628"
          x="29.2184"
          y="28.2874"
          width="72.5632"
          height="72.5632"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.06897" />
          <feGaussianBlur stdDeviation="3.3908" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.429167 0 0 0 0 0.429167 0 0 0 0 0.429167 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_28628" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_28628" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default memo(HomePageEmptyStateIcon);
