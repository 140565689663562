export const BREADCRUMBS_TYPES = {
  STATION_CREATE: "station-create",
  STATION_REVIEW: "station-review",
  MODEL_CREATE: "model-create",
  MODELS_REVIEW: "models-review",
  MODEL_USE_EXISTING: "model-use-existing",
  STATIONS_DASHBOARD: "stations-dashboard",
  SITE_VIEW: "site-view",
  INVITE_AND_ROLES: "invite-and-roles",
  DATA_CONNECTORS: "data-connectors",
  NEW_CONNECTOR: "new-connector",
  ADMIN: "admin",
  PROFILE: "profile",
  PREFERENCES: "preferences",
  NEW_CURATION: "new-curation",
  DATA_CURATION: "data-curation",
  DEMOS: "Demos",
  CHAT_CSV: "chat-csv",
  EMPTY: "empty",
  DATASET_ANALYZER: "dataset-analyzer",
  CHAT: "chat",
  NONE: "NONE"
};

export const BREADCRUMB = {
  [BREADCRUMBS_TYPES.SITE_VIEW]: {
    title: "Site view",
    mainLink: "/dashboard/site-view",
    links: []
  },
  [BREADCRUMBS_TYPES.INVITE_AND_ROLES]: {
    title: "Invite and roles",
    mainLink: "/dashboard/invite"
  },
  [BREADCRUMBS_TYPES.DATA_CONNECTORS]: {
    title: "Data connectors",
    mainLink: "/dashboard/data-connectors"
  },
  [BREADCRUMBS_TYPES.NEW_CONNECTOR]: {
    title: "Data connectors",
    mainLink: "/dashboard/data-connectors"
  },
  [BREADCRUMBS_TYPES.ADMIN]: {
    title: "Admin",
    mainLink: "/admin"
  },
  [BREADCRUMBS_TYPES.PREFERENCES]: {
    title: "Preferences",
    mainLink: "/dashboard/preferences"
  },
  [BREADCRUMBS_TYPES.PROFILE]: {
    title: "Profile",
    mainLink: "/dashboard/profile"
  },
  [BREADCRUMBS_TYPES.DATA_CURATION]: {
    title: "Data curation",
    mainLink: "/dashboard/data-curation"
  },
  [BREADCRUMBS_TYPES.NEW_CURATION]: {
    title: "Data curation",
    mainLink: "/dashboard/data-curation"
  },
  [BREADCRUMBS_TYPES.DEMOS]: {
    title: "Demos",
    mainLink: "/demos"
  },
  [BREADCRUMBS_TYPES.CHAT_CSV]: {
    title: "Chat CSV",
    mainLink: "/chatCsv"
  },
  [BREADCRUMBS_TYPES.EMPTY]: {},
  DEFAULT: {
    title: "Applications",
    mainLink: "/dashboard/stations"
  },
  [BREADCRUMBS_TYPES.DATASET_ANALYZER]: {
    title: "Dataset Analyzer",
    mainLink: "/dataset-analyzer"
  },
  [BREADCRUMBS_TYPES.CHAT]: {
    title: "Chat",
    mainLink: "/chat"
  },
  [BREADCRUMBS_TYPES.CHAT]: {
    title: "Reports",
    mainLink: "/reports"
  },
  [BREADCRUMBS_TYPES.NONE]: {
    title: "",
    mainLink: ""
  }
};

export const BREADCRUMBS_LINKS = {
  [BREADCRUMBS_TYPES.STATION_CREATE]: ({ stationName }) => [
    {
      text: stationName,
      title: "Create a new Application",
      to: `/dashboard/onboarding/select-type/stations`
    }
  ],
  [BREADCRUMBS_TYPES.MODEL_CREATE]: ({ stationName, stationId, stationNumOfModels }) => {
    if (stationId && stationName && stationNumOfModels) {
      return [
        {
          text: stationName,
          title: "Application name: ",
          to: `/dashboard/stations/${stationId}`,
          disabled: stationNumOfModels < 2
        },
        {
          text: "Model creation",
          to: `/dashboard/onboarding/stations/${stationId}`,
          disabled: stationNumOfModels < 2
        }
      ];
    }
    return [];
  },
  [BREADCRUMBS_TYPES.STATION_REVIEW]: ({ stationName, stationId }) => [
    {
      text: stationName,
      title: "Application:",
      to: `/dashboard/stations/${stationId}`
    }
  ],
  [BREADCRUMBS_TYPES.MODELS_REVIEW]: ({ stationName, stationId }) => {
    if (stationId) {
      return [
        {
          text: stationName,
          title: "Application:",
          to: `/dashboard/stations/${stationId}`
        },
        {
          text: "Model review",
          to: `/dashboard/stations/${stationId}/review`
        }
      ];
    }
    return [];
  },
  [BREADCRUMBS_TYPES.MODEL_USE_EXISTING]: ({ stationName, stationId, stationNumOfModels }) => {
    if (stationId && stationName && stationNumOfModels) {
      return [
        {
          text: stationName,
          title: "Application name: ",
          to: `/dashboard/stations/${stationId}`,
          disabled: stationNumOfModels < 2
        },
        {
          text: "Model creation",
          to: `/dashboard/onboarding/stations/${stationId}`,
          disabled: stationNumOfModels < 2
        }
      ];
    }
    return [];
  },
  [BREADCRUMBS_TYPES.NEW_CONNECTOR]: ({ foundConnectorTitle, foundConnectorId }) => [
    {
      title: foundConnectorTitle || "New connector",
      to: foundConnectorId ? `/dashboard/data-connectors${foundConnectorId}` : "/dashboard/data-connectors/create"
    }
  ],
  [BREADCRUMBS_TYPES.NEW_CURATION]: ({ foundCurationName, foundCurationId }) => [
    {
      title: foundCurationName || "New curation",
      to: `/dashboard/data-curation/${foundCurationId || "_"}`
    }
  ],
  [BREADCRUMBS_TYPES.DEFAULT]: () => []
};

export const getBreadcrumbLinks = (
  breadcrumbsType,
  {
    stationName,
    stationId,
    stationNumOfModels,
    foundConnectorTitle,
    foundConnectorId,
    foundCurationName,
    foundCurationId
  }
) => {
  return BREADCRUMBS_LINKS[breadcrumbsType]
    ? BREADCRUMBS_LINKS[breadcrumbsType]({
        stationName,
        stationId,
        stationNumOfModels,
        foundConnectorTitle,
        foundConnectorId,
        foundCurationName,
        foundCurationId
      })
    : BREADCRUMBS_LINKS.DEFAULT;
};

export const BASE_PAGES_LINKS = {
  DATA_CONNECTORS: "/dashboard/data-connectors"
};
