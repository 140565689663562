import { createSelector } from "reselect";

const createAccountSelector = state => state.account?.operations?.createAccount;

export const loadingSelector = createSelector(
  createAccountSelector,
  state => state.loading
);

export const errorSelector = createSelector(
  createAccountSelector,
  state => {
    const errorMessage = state?.error?.message;
    return typeof errorMessage === "object" ? JSON.stringify(errorMessage) : errorMessage;
  }
);

export const successSelector = createSelector(
  createAccountSelector,
  state => state.success
);
