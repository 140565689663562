import React, { memo } from "react";
import { makeStyles } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";

const DotsFlashingLoader = () => {
  const classes = useStyles();

  return <div className={classes.dotFlashing} />;
};

const useStyles = makeStyles(() => ({
  "@keyframes k-dot-flashing": {
    "0%": {
      backgroundColor: vantiColors.darkBlue6
    },
    "50%, 100%": {
      backgroundColor: "rgba(9, 23, 51, 0.2)"
    }
  },
  dotFlashing: {
    position: "relative",
    width: "4px",
    height: "4px",
    borderRadius: "5px",
    backgroundColor: vantiColors.darkBlue6,
    color: vantiColors.darkBlue6,
    animation: "$k-dot-flashing 1s infinite linear alternate",
    animationDelay: "0.5s",

    "&:before, &:after": {
      content: '""',
      display: "inline-block",
      position: "absolute",
      top: "0"
    },
    "&:before": {
      left: "-8px",
      width: "4px",
      height: "4px",
      borderRadius: "5px",
      backgroundColor: vantiColors.darkBlue6,
      color: vantiColors.darkBlue6,
      animation: "$k-dot-flashing 1s infinite alternate",
      animationDelay: "0s"
    },
    "&:after": {
      left: "8px",
      width: "4px",
      height: "4px",
      borderRadius: "5px",
      backgroundColor: vantiColors.darkBlue6,
      color: vantiColors.darkBlue6,
      animation: "$k-dot-flashing 1s infinite alternate",
      animationDelay: "1s"
    }
  }
}));
export default memo(DotsFlashingLoader);
