import React, { memo, useCallback, useContext, useMemo, useRef, useState } from "react";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { Text } from "components/ui";
import Popup from "./Popup";
import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import { Dot, palette } from "../common";

const EntityNamesDropdown = styled("div")({
  backgroundColor: vantiColors.white,
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.15)"
});

const EntityNameItem = styled(Text)({
  width: "100%",
  height: 56,
  display: "flex",
  alignItems: "center",
  padding: "0px 12px",
  cursor: "pointer",
  boxSizing: "border-box",
  columnGap: 8,
  "&:hover": {
    backgroundColor: vantiColors.gray18
  }
});

const ButtonStyled = styled(GeneralVantiButton)({
  width: "100%"
});

const AddEntitySection = ({ onAddChildEntity, childEntities }) => {
  const { dataEntitiesContext } = useContext(AppContext);

  const buttonRef = useRef(null);

  const [isChildEntitiesPopupOpen, setIsChildEntitiesPopupOpen] = useState(false);

  const { dataEntities } = dataEntitiesContext;

  const entities = useMemo(() => {
    const childEntitiesToDisplay = [];

    Object.keys(dataEntities).map(entityId => {
      if (!childEntities?.find(entity => entity._id === entityId)) {
        childEntitiesToDisplay.push(dataEntities[entityId]);
      }
    });

    return childEntitiesToDisplay;
  }, [dataEntities, childEntities]);

  const onClosePopup = () => {
    setIsChildEntitiesPopupOpen(false);
  };

  const onClickAddEntityButton = useCallback(() => {
    setIsChildEntitiesPopupOpen(true);
  }, []);

  const onClickEntityName = useCallback(
    entity => {
      onAddChildEntity(entity);
      setIsChildEntitiesPopupOpen(false);
    },
    [onAddChildEntity]
  );

  return (
    <div>
      {isChildEntitiesPopupOpen && (
        <Popup onClose={onClosePopup} ref={buttonRef}>
          <EntityNamesDropdown>
            {entities.map((entity, index) => (
              <EntityNameItem
                key={entity.name}
                onClick={() => {
                  onClickEntityName(entity);
                }}
              >
                <Dot color={palette[index % palette.length]} />

                {entity.name}
              </EntityNameItem>
            ))}
          </EntityNamesDropdown>
        </Popup>
      )}

      <ButtonStyled
        variant={VARIANTS.TERTIARY}
        size={SIZES.SMALL}
        ref={buttonRef}
        buttonInfo={{
          label: "+ Add Entity",
          onClick: onClickAddEntityButton,
          disabled: false
        }}
      />
    </div>
  );
};

export default memo(AddEntitySection);
