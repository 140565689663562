import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const UnpinIcon = ({ stroke = vantiColors.darkBlue7, ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <mask
        id="mask0_916_540"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={24}
        height={24}
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_916_540)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3396 11.6322L15.0503 10.799L11.6231 3.44947L12.6132 2.98778L12.1515 1.9977L6.21521 4.76585L7.16297 5.52928L10.6331 3.91115L13.929 10.9794L15.2528 12.0457L15.9268 12.291L15.6928 12.4001L16.6406 13.1635L17.8013 12.6223L17.3396 11.6322ZM14.1185 14.3396L13.1707 13.5762L9.6893 15.1996L10.297 13.5301L8.60263 9.89656L6.67214 8.34154L9.10977 13.5691L8.27652 15.8584L8.73821 16.8485L12.7747 14.9662L15.0831 19.9167L15.809 20.1809L16.0732 19.455L13.7648 14.5045L14.1185 14.3396Z"
          fill="#0313A4"
        />
        <line
          y1="-0.5"
          x2="21.0336"
          y2="-0.5"
          transform="matrix(0.778773 0.627306 -0.595781 0.803147 2.26428 4.79086)"
          stroke={stroke}
        />
      </g>
    </svg>
  );
};

export default memo(UnpinIcon);
