import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigationSetBreadcrumbsType } from "modules/navigation/actions";
import { BREADCRUMBS_TYPES } from "common/constants/NavigationConstants";
import { styled } from "@material-ui/core";
import DataEntitiesPagedHeader from "./header/DataEntitiesPagedHeader";
import DataEntitiesBody from "./body/DataEntitiesBody";
import SingleDataEntityBody from "./body/SingleDataEntityBody";
import useDataEntityRequest from "common/hooks/use-data-entity-request";
import GeneralModalPreviewComponent from "components/ui/Modal/GeneralModalPreviewComponent";
import ChatPageDatasetSelectionModal from "pages/side-bar/chat/components/ChatPageDatasetSelectionModal";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import CustomDeleteConfirmationDialog from "../data-page/components/CustomDeleteConfirmationDialog";
import { listenToEvent, stopListeningToEvent } from "utils/socket-utils";
import { accountIdSelector } from "modules/account/state/selectors";
import { useNotifications } from "common/hooks/use-notification";
import { useHistory } from "react-router-dom";
import { CommonDataEntitiesTypes } from "@vanti-analytics-org/vanti-common";
import { FOLDER_SOURCE_TYPE } from "../data-page/components/DataRow";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import useFlowRequest from "common/hooks/use-flow-request";
import PlusIconBG40 from "assets/icons/plus-icon/PlusIconBG40";
import FlowCard from "./body/FlowCard";
import { VantiScrollBarProps } from "components/ui/VantiScrollBarProps";
import { DataEntitiesCardContainerStyled } from "./body/AddDataEntityCard";
import PlusIconAnimatedOnHover from "./PlusIconAnimatedOnHover";

const DELETE_ASSOCIATED_DATA_WARNING_TEXT = "Removing this data from entity will require a new curation process!";
const DELETE_ENTITY_WARNING_TEXT =
  "Removing this Entity will permanently delete all of its dashboard reports and widgets.";
const DELETE_FLOW_WARNING_TEXT = "Removing this Flow will permanently delete it";

const DATA_ENTITY_UPDATE_SOCKET_EVENT = "data-entity/update";

export const isCommonEntity = entityName =>
  entityName && Object.values(CommonDataEntitiesTypes).includes(entityName.toUpperCase());

const DataEntitiesPage = () => {
  const dispatch = useDispatch();

  const { dataEntitiesContext, flowContext } = useContext(AppContext);
  const { dataEntities, setDataEntities } = dataEntitiesContext;
  const { flows } = flowContext;

  const {
    getDataEntitiesRequest,
    bindDatasetsToDataEntityRequest,
    unbindDatasetsToDataEntityRequest,
    deleteDataEntityRequest
  } = useDataEntityRequest();
  const { getAccountFlowsReq, deleteFlowReq } = useFlowRequest();

  const [chosenEntityId, setChosenEntityId] = useState(null);
  const { chatContext, datasetsContext } = useContext(AppContext);
  const { selectedDatasetsForCuration, setContext: setDatasetCtx } = datasetsContext;
  const { displayOtherDatasets, setDisplayOtherDatasets } = chatContext;
  const [displayDataDeletionDialogId, setDisplayDataDeletionDialogId] = useState(null);
  const [displayEntityDeletionDialogId, setDisplayEntityDeletionDialogId] = useState(null);
  const [displayFlowDeletionDialogId, setDisplayFlowDeletionDialogId] = useState(null);
  const [isHoveredAddFlowCard, setIsHoveredAddFlowCard] = useState(false);

  const history = useHistory();

  //We invoke this hook to make sure web sockets (which is implemented ugly now)
  //Is authorized and joined the correct room for account data entity messages
  //TODO: this was duplicated from chatPage - move to separate re-usable file!
  const accountId = useSelector(accountIdSelector);
  useNotifications(accountId);

  useEffect(() => {
    dispatch(navigationSetBreadcrumbsType(BREADCRUMBS_TYPES.NONE));
    getDataEntitiesRequest.mutate();
    setDatasetCtx(prevState => ({ ...prevState, selectedDatasetsForCuration: [] }));
  }, []);

  useEffect(() => {
    const entityNameFromUrl = history.location.pathname.split("/").at(-1);
    if (entityNameFromUrl !== "entities") {
      setChosenEntityId(() => {
        return Object.values(dataEntities).find(e => e.name.toLowerCase() === entityNameFromUrl.toLowerCase())?._id;
      });
    } else {
      setChosenEntityId(null);
    }
  }, [history.location.pathname, dataEntities]);

  const selectedEntity = useMemo(() => dataEntities[chosenEntityId], [dataEntities[chosenEntityId]]);

  const onBindDatasetsToEntity = useCallback(() => {
    bindDatasetsToDataEntityRequest.mutate({
      dataEntityId: chosenEntityId,
      entries: selectedDatasetsForCuration.map(d => ({
        type: d.entryType === FOLDER_SOURCE_TYPE ? "folder" : "single",
        entryId: d.id || d._id
      }))
    });
  }, [chosenEntityId, selectedDatasetsForCuration]);

  const handleSocketEntityUpdate = useCallback(
    entity => {
      const newDataEntities = { ...dataEntities };

      //Update entity in data entities map
      newDataEntities[entity._id] = entity;

      setDataEntities(newDataEntities);
    },
    [dataEntities]
  );

  useEffect(() => {
    listenToEvent(DATA_ENTITY_UPDATE_SOCKET_EVENT, entity => {
      handleSocketEntityUpdate(entity);
    });

    return () => {
      stopListeningToEvent(DATA_ENTITY_UPDATE_SOCKET_EVENT);
    };
  }, [handleSocketEntityUpdate]);

  useEffect(() => {
    getAccountFlowsReq.mutate();
  }, []);

  const onClickEntityCard = useCallback(
    entityName => {
      history.push(`/dashboard/entities/${entityName}`);
    },
    [history]
  );

  const onDisplayDeleteFlowDialog = useCallback(flowId => {
    setDisplayFlowDeletionDialogId(flowId);
  }, []);

  return (
    <>
      <PageContainer>
        <SectionContainer>
          <DataEntitiesPagedHeader chosenEntity={selectedEntity} />

          {selectedEntity ? (
            <SingleDataEntityBody
              entity={selectedEntity}
              setDisplayOtherDatasets={setDisplayOtherDatasets}
              setDisplayDataDeletionDialogId={setDisplayDataDeletionDialogId}
            />
          ) : (
            <DataEntitiesBody
              onClickEntityCard={onClickEntityCard}
              setDisplayEntityDeletionDialogId={setDisplayEntityDeletionDialogId}
            />
          )}
        </SectionContainer>

        {!selectedEntity && (
          <>
            <Divider />

            <SectionContainer>
              <FlowsHeader>
                <Text size={22} weight={600}>
                  Flows
                </Text>
              </FlowsHeader>

              <FlowsWrapper>
                <DataEntitiesCardContainerStyled
                  isHovered={isHoveredAddFlowCard}
                  onMouseEnter={() => setIsHoveredAddFlowCard(true)}
                  onMouseLeave={() => setIsHoveredAddFlowCard(false)}
                  onClick={() => history.push("/dashboard/flows/new")}
                >
                  <PlusIconAnimatedOnHover isHovered={isHoveredAddFlowCard} data-testid={"add-new-flow-plus-icon"} />
                  <Text color={isHoveredAddFlowCard ? vantiColors.darkBlue7 : vantiColors.darkBlue6}>
                    {"Add New Flow"}
                  </Text>
                  <Text style={{ opacity: 0 }}>{"invisible text"}</Text>
                  <div />
                </DataEntitiesCardContainerStyled>

                {Object.values(flows).map((flow, index) => (
                  <FlowCard key={flow._id} flow={flow} index={index} onClickDelete={onDisplayDeleteFlowDialog} />
                ))}
              </FlowsWrapper>
            </SectionContainer>
          </>
        )}
      </PageContainer>

      {displayOtherDatasets && (
        <GeneralModalPreviewComponent
          onClose={(event, reason) => {
            setDisplayOtherDatasets(false);
          }}
        >
          <ChatPageDatasetSelectionModal
            closeDataSelectionModal={() => setDisplayOtherDatasets(false)}
            useDefaultCustomRubricHeader={false}
            onClickFinish={onBindDatasetsToEntity}
            onlyChatSuitableDatasets={false}
            onlyIngestedDatasets={true}
          />
        </GeneralModalPreviewComponent>
      )}

      {displayDataDeletionDialogId && (
        <CustomDeleteConfirmationDialog
          isOpenedCondition={displayDataDeletionDialogId}
          entity={{ type: "entity", name: selectedEntity?.name || "" }}
          deletionWarningText={DELETE_ASSOCIATED_DATA_WARNING_TEXT}
          onCloseCallback={() => setDisplayDataDeletionDialogId(null)}
          onClickCancelCallback={() => setDisplayDataDeletionDialogId(null)}
          onClickDeleteCallback={() => {
            unbindDatasetsToDataEntityRequest.mutate({
              dataEntityId: chosenEntityId,
              datasetId: displayDataDeletionDialogId
            });
            setDisplayDataDeletionDialogId(null);
          }}
          isDisabled={false}
        />
      )}

      {displayEntityDeletionDialogId && (
        <CustomDeleteConfirmationDialog
          isOpenedCondition={displayEntityDeletionDialogId}
          entity={{ type: "entity", name: dataEntities[displayEntityDeletionDialogId]?.name || "" }}
          deletionWarningText={DELETE_ENTITY_WARNING_TEXT}
          onCloseCallback={() => setDisplayEntityDeletionDialogId(null)}
          onClickCancelCallback={() => setDisplayEntityDeletionDialogId(null)}
          onClickDeleteCallback={() => {
            deleteDataEntityRequest.mutate({
              dataEntityId: displayEntityDeletionDialogId
            });
            setDisplayEntityDeletionDialogId(null);
          }}
          isDisabled={false}
        />
      )}

      {displayFlowDeletionDialogId && (
        <CustomDeleteConfirmationDialog
          isOpenedCondition={displayFlowDeletionDialogId}
          entity={{ type: "flow", name: flows[displayFlowDeletionDialogId]?.title }}
          deletionWarningText={DELETE_FLOW_WARNING_TEXT}
          onCloseCallback={() => setDisplayFlowDeletionDialogId(null)}
          onClickCancelCallback={() => setDisplayFlowDeletionDialogId(null)}
          onClickDeleteCallback={() => {
            deleteFlowReq.mutate({
              flowId: displayFlowDeletionDialogId
            });
            setDisplayFlowDeletionDialogId(null);
          }}
          isDisabled={false}
        />
      )}
    </>
  );
};

const PageContainer = styled("div")({
  width: "100%",
  height: "100%",
  padding: 16,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  rowGap: 40,
  overflowY: "auto",
  ...VantiScrollBarProps
});

const SectionContainer = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  height: "100%"
});

const FlowsWrapper = styled("div")({
  display: "flex",
  columnGap: 16,
  flexWrap: "wrap",
  gap: 16
});

const FlowsHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: 16
});

const Divider = styled("div")({
  width: "100%",
  height: 1,
  backgroundColor: vantiColors.gray36
});

export default memo(DataEntitiesPage);
