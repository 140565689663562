import { useMutation } from "react-query";
import { logger } from "utils/logger";
import {
  DATASET_API_QUERY_KEYS,
  deleteDatasetQuestion,
  getDatasetQuestions,
  saveOrUpdateDatasetQuestions
} from "services/api/dataset";
import { useContext } from "react";
import { AppContext } from "./context-hooks/use-app-context";

const useDatasetQuestionRequest = () => {
  const { datasetsContext } = useContext(AppContext);
  const { datasetQuestions, setContext: setDatasetsContext } = datasetsContext;

  const saveOrUpdateQuestionsRequest = useMutation(
    [DATASET_API_QUERY_KEYS.SAVE_OR_UPDATE_DATASET_QUESTIONS],
    async ({ datasetId, questions }) => {
      return saveOrUpdateDatasetQuestions(datasetId, questions);
    },
    {
      manual: false,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: (data, { datasetId }) => {
        setDatasetsContext(prevState => {
          const datasetQuestionsCopy = { ...datasetQuestions };
          const existingDatasetQuestions = datasetQuestionsCopy[datasetId] || [];
          for (const savedQuestion of data) {
            let index = existingDatasetQuestions.findIndex(item => item._id === savedQuestion._id);
            if (index !== -1) {
              existingDatasetQuestions[index] = savedQuestion;
            } else {
              existingDatasetQuestions.push(savedQuestion);
            }
          }
          datasetQuestionsCopy[datasetId] = [...existingDatasetQuestions];
          return { ...prevState, datasetQuestions: datasetQuestionsCopy };
        });
      },
      onError: (error, { datasetId, questions }) =>
        logger.error("Failed to save/update dataset questions", { error, datasetId, questions })
    }
  );

  const getQuestionRequest = useMutation(
    [DATASET_API_QUERY_KEYS.GET_DATASET_QUESTIONS],
    async ({ datasetId }) => getDatasetQuestions(datasetId),
    {
      manual: false,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: (data, { datasetId }) => {
        setDatasetsContext(prevState => {
          const datasetQuestionsCopy = { ...datasetQuestions };
          datasetQuestionsCopy[datasetId] = data;
          return { ...prevState, datasetQuestions: datasetQuestionsCopy };
        });
        return data;
      },
      onError: (error, { datasetId }) => logger.error("Failed to get dataset questions", { error, datasetId })
    }
  );

  const deleteQuestionRequest = useMutation(
    [DATASET_API_QUERY_KEYS.GET_DATASET_QUESTIONS],
    async ({ datasetId, questionId }) => deleteDatasetQuestion(datasetId, questionId),
    {
      manual: false,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: (data, { datasetId, questionId }) => {
        setDatasetsContext(prevState => {
          const datasetQuestionsCopy = { ...datasetQuestions };
          const existingDatasetQuestions = datasetQuestionsCopy[datasetId] || [];
          let index = existingDatasetQuestions.findIndex(q => q._id === questionId);
          if (index !== -1) {
            existingDatasetQuestions.splice(index, 1);
            datasetQuestionsCopy[datasetId] = [...existingDatasetQuestions];
          }
          return { ...prevState, datasetQuestions: datasetQuestionsCopy };
        });
      },
      onError: (error, { datasetId }) => logger.error("Failed to get dataset questions", { error, datasetId })
    }
  );

  return {
    saveOrUpdateQuestionsRequest,
    getQuestionRequest,
    deleteQuestionRequest
  };
};

export default useDatasetQuestionRequest;
