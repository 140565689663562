export const KEY_CODES = {
  TAB: 9,
  ENTER: 13,
  SPACE: 32
};

export const BATCH_TYPES = {
  FEEDBACK: "FEEDBACK",
  PREDICT: "PREDICT"
};
