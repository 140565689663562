import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  cardHeader: {
    padding: 0,
    display: "flex",
    justifyContent: "center"
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "70px",
    background: `linear-gradient(270deg, ${theme.palette.vantiColors.gray17} 0%, ${
      theme.palette.vantiColors.darkBlue1
    } 100%)`
  }
}));
