import config from "common/config";
import http from "services/http";
const accountsApiURL = `${config.serverUrl}/api/v2/accounts`;

export const hubspotUpdatePredictionMade = async stationId => {
  await http.post(`${accountsApiURL}/update-hubspot-prediction-made`, { stationId });
};

export const hubspotUpdateUploadedValidDataset = async () => {
  await http.post(`${accountsApiURL}/update-hubspot-uploaded-valid-dataset`);
};
