import socket from "services/sockets/socket";

export const listenToEvent = (event, handler, customRoom = {}) => {
  if (customRoom.name) {
    socket.joinRoom(customRoom.name, async () => socket.emit(customRoom.message, customRoom.resource, "join"));
  }
  socket.on(event, handler);
};

export const stopListeningToEvent = event => {
  socket.off(event);
};

export const leaveCustomRoom = customRoom => {
  socket.leaveRoom(customRoom.name, async () => socket.emit(customRoom.message, customRoom.resource, "leave"));
};
