import { vantiColors } from "assets/jss/palette";
import React, { memo } from "react";

const LoadingIcon = ({
  width = "20",
  height = "20",
  viewBox = "0 0 20 20",
  fill = "none",
  color = vantiColors.blue4,
  ...rest
}) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M19.5 10C19.5 12.1979 18.7379 14.3278 17.3436 16.0267C15.9493 17.7257 14.009 18.8887 11.8534 19.3175C9.69771 19.7462 7.46008 19.4143 5.52173 18.3783C3.58338 17.3422 2.06424 15.6661 1.22314 13.6355C0.382054 11.6049 0.271057 9.34553 0.909067 7.2423C1.54708 5.13906 2.89462 3.32211 4.72208 2.10104C6.54955 0.879965 8.74387 0.330316 10.9312 0.545745C13.1185 0.761174 15.1634 1.72835 16.7175 3.28248"
      stroke={color}
    />
  </svg>
);

export default memo(LoadingIcon);
