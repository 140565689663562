import { vantiColors } from "assets/jss/palette";
import React, { memo } from "react";

const RedCircleExclamationMark = ({ width = "16", height = "16", viewBox = "0 0 16 16", fill = "none", ...rest }) => (
  <svg {...rest} width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 11.9789C8.19649 11.9789 8.35789 11.9158 8.48421 11.7895C8.61053 11.6632 8.67368 11.5018 8.67368 11.3053C8.67368 11.1088 8.61053 10.9474 8.48421 10.8211C8.35789 10.6947 8.19649 10.6316 8 10.6316C7.80351 10.6316 7.64211 10.6947 7.51579 10.8211C7.38947 10.9474 7.32632 11.1088 7.32632 11.3053C7.32632 11.5018 7.38947 11.6632 7.51579 11.7895C7.64211 11.9158 7.80351 11.9789 8 11.9789ZM7.36842 8.90526H8.63158V3.85263H7.36842V8.90526ZM8 16C6.89123 16 5.84926 15.7895 4.87411 15.3684C3.89839 14.9474 3.05263 14.3789 2.33684 13.6632C1.62105 12.9474 1.05263 12.1016 0.631579 11.1259C0.210526 10.1507 0 9.10877 0 8C0 6.89123 0.210526 5.84898 0.631579 4.87326C1.05263 3.89811 1.62105 3.05263 2.33684 2.33684C3.05263 1.62105 3.89839 1.05263 4.87411 0.631579C5.84926 0.210526 6.89123 0 8 0C9.10877 0 10.151 0.210526 11.1267 0.631579C12.1019 1.05263 12.9474 1.62105 13.6632 2.33684C14.3789 3.05263 14.9474 3.89811 15.3684 4.87326C15.7895 5.84898 16 6.89123 16 8C16 9.10877 15.7895 10.1507 15.3684 11.1259C14.9474 12.1016 14.3789 12.9474 13.6632 13.6632C12.9474 14.3789 12.1019 14.9474 11.1267 15.3684C10.151 15.7895 9.10877 16 8 16ZM8 14.7368C9.8807 14.7368 11.4737 14.0842 12.7789 12.7789C14.0842 11.4737 14.7368 9.8807 14.7368 8C14.7368 6.1193 14.0842 4.52632 12.7789 3.22105C11.4737 1.91579 9.8807 1.26316 8 1.26316C6.1193 1.26316 4.52632 1.91579 3.22105 3.22105C1.91579 4.52632 1.26316 6.1193 1.26316 8C1.26316 9.8807 1.91579 11.4737 3.22105 12.7789C4.52632 14.0842 6.1193 14.7368 8 14.7368Z"
      fill={vantiColors.rose6}
    />
  </svg>
);

export default memo(RedCircleExclamationMark);
