import { NEW_CURATION_ACTION_TYPES, USE_SINGLE_DATA_CURATION_ACTION_TYPES } from "./actionTypes";

export const newCurationSetName = payload => {
  return {
    type: NEW_CURATION_ACTION_TYPES.SET_NAME,
    payload
  };
};
export const newCurationSetCurationCode = payload => {
  return {
    type: NEW_CURATION_ACTION_TYPES.SET_CURATION_CODE,
    payload
  };
};
export const newCurationSetMergeCode = payload => {
  return {
    type: NEW_CURATION_ACTION_TYPES.SET_MERGE_CODE,
    payload
  };
};
export const singleCurationSetCuration = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_CURATION,
    payload
  };
};
export const singleCurationSetCurationJob = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_CURATION_JOB,
    payload
  };
};
export const singleCurationSetDownloadUrl = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_DOWNLOAD_URL,
    payload
  };
};
export const singleCurationSetActiveSectionIndex = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_ACTIVE_SECTION_INDEX,
    payload
  };
};
export const singleCurationSetExistingCurationError = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_EXISTING_CURATION_ERROR,
    payload
  };
};
export const singleCurationSetNewCurationError = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_NEW_CURATION_ERROR,
    payload
  };
};
export const singleCurationSetNextLocationProps = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_NEXT_LOCATION_PROPS,
    payload
  };
};
export const singleCurationSetIsSafeToLeave = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_IS_SAFE_TO_LEAVE,
    payload
  };
};
export const singleCurationSetActiveFileTab = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_ACTIVE_FILE_TAB,
    payload
  };
};
export const singleCurationSetPresignedUrls = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_PRESIGNED_URLS,
    payload
  };
};
export const singleCurationSetIsMergeLoading = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_IS_MERGE_LOADING,
    payload
  };
};
export const singleCurationSetMergeError = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_MERGE_ERROR,
    payload
  };
};
export const singleCurationSetMergeCode = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_MERGE_CODE,
    payload
  };
};
export const singleCurationSetMergeJob = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_MERGE_JOB,
    payload
  };
};
export const singleCurationSetMergeCodeAnnotations = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_MERGE_CODE_ANNOTATIONS,
    payload
  };
};
export const singleDataCurationSetFiles = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_FILES,
    payload
  };
};
export const singleDataCurationRemoveFile = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.REMOVE_FILE,
    payload
  };
};
export const singleDataCurationChangedFileAction = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_CHANGED_FILE_ACTION,
    payload
  };
};
export const singleDataCurationSetShouldNotifyAboutOriginalFilesChange = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SHOULD_NOTIFY_ABOUT_ORIGINAL_FILES_CHANGE,
    payload
  };
};
export const singleCurationSetCurationRunError = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_CURATION_RUN_ERROR,
    payload
  };
};
export const singleCurationSetIsCurationLoading = payload => {
  return {
    type: USE_SINGLE_DATA_CURATION_ACTION_TYPES.SET_IS_CURATION_LOADING,
    payload
  };
};
