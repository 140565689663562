import {vantiColors} from "assets/jss/palette";
import {useNotifications} from "common/hooks/use-notification";
import {FlexItem} from "components/ui";
import {accountIdSelector} from "modules/account/state/selectors";
import React, {memo, useCallback, useContext, useEffect, useState} from "react";
import {Text} from "components/ui";
import {useQuery} from "react-query";
import {useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {
    createNewCuration,
    getExistingCuration,
    mergeFiles,
    runDataCuration,
    updateCuration
} from "services/api/dataCuration";
import {API_QUERIES, fileChangingActionTypes} from "../../utils";
import CurationFileUploadWrapper from "./components/curation-file-upload/CurationFileUploadWrapper";
import CurationFilesTabsWrapper from "./components/CurationFilesTabsWrapper";
import {
    YourDataWrapperStyled,
    FilesPreviewContainerStyled,
    ExecErrorContainer,
    AddDatasetLinkStyled,
    BackButtonStyled,
    ArrowLeftStyled,
    CodeWrapperStyled,
    CurationCodeContainerStyled,
    CurationCodeInnerContainerStyled,
    CurationCodeRunButtonStyled,
    CurationCodeSectionTitleStyled,
    CurationFileUploadBHeaderStyled,
    CurationFileUploadContainerStyled,
    CurationFileUploadDescStyled,
    CurationFileUploadHeaderTextStyled,
    CurationInputPreviewTitleStyled,
    CurationNameContainerStyled,
    CurationNameInputStyled,
    CurationNoFilesContainerStyled,
    CurationOutputTextStyled,
    CurationPageDescStyled,
    CurationPageHeaderStyled,
    CurationPagePrimaryTitleStyled,
    CurationPageSecondaryTitleStyled,
    CurationPreviewContainerStyled,
    CurationPreviewTextStyled,
    CurationSaveButtonStyled,
    CurationSectionTitleStyled,
    DataSectionStyled,
    ScrollTextStyled
} from "./SingleDataCuration.style";
import {singleCurationSetActiveFileTab} from "./useSingleDataCuration/actions";
import useSingleDataCuration from "./useSingleDataCuration/useSingleDataCuration";



import Divider from "@material-ui/core/Divider";
import {
    CURATION_PAGE_DESC,
    CURATION_PAGE_TITLE,
    SAMPLE_CODE,
    SECONDARY_TITLES,
    TERTIARY_TITLES
} from "common/constants/CurationPage";
import * as PropTypes from "prop-types";
import {AppContext} from "../../../../../common/hooks/context-hooks/use-app-context";
import {VARIANTS} from "../../../../../components/ui/Buttons/GeneralVantiButton";
import CurationCSVOutputPreviewWrapper from "./components/curation-csv-output-preview/CurationCSVOutputPreviewWrapper";
import CustomAddDatasetPopoverButton from "./CustomAddDatasetPopoverButton";

ScrollTextStyled.propTypes = {children: PropTypes.node};

const SingleDataCuration = () => {
    const { datasetsContext, chatContext } = useContext(AppContext);

    const {
        displayOtherDatasets,
        setDisplayOtherDatasets,
    } = chatContext;
    const {selectedDatasetsForCuration, curatedDatasets} = datasetsContext;
    const {curationId} = useParams();
    const history = useHistory();
    const accountId = useSelector(accountIdSelector);
    useNotifications(accountId);
    const [anchorEl, setAnchorEl] = useState(null);
    const [datasetSelectionCount, setDatasetSelectionCount] = useState(0)
    const numOfCurations = curatedDatasets ? curatedDatasets.length : 1;
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const createNewCurationReq = useQuery(
        API_QUERIES.keys.createNewCuration,
        () =>
            createNewCuration({
                ...curationData,
                fileNames: generateOriginalFileNamesForDB,
                originalFilesPreview: generateOriginalFilesPreviewObject
            }),
        {
            manual: true,
            enabled: false,
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: false
        }
    );

    const updateCurationReq = useQuery(
        API_QUERIES.keys.updateCuration,
        () =>
            updateCuration(singleDataCuration.curation ? singleDataCuration.curation._id : null, {
                ...curationData,
                fileNames: generateOriginalFileNamesForDB,
                originalFilesPreview: generateOriginalFilesPreviewObject
            }),
        {
            manual: true,
            enabled: false,
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: false
        }
    );

    const existingCurationReq = useQuery(API_QUERIES.keys.getExistingCuration, () => getExistingCuration(curationId), {
        enabled: curationId !== "_",
        staleTime: 0,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false
    });

    const mergeFilesReq = useQuery(
        API_QUERIES.keys.mergeFiles,
        () => {
            let allPresignedKeys = {...singleDataCuration.presignedUrls};
            allPresignedKeys = Object.values(allPresignedKeys).map(presignedUrl => presignedUrl.key);
            return mergeFiles(
                singleDataCuration.curation ? singleDataCuration.curation._id : existingCurationReq.data?._id,
                allPresignedKeys,
                curationData.mergeCode,
                singleDataCuration.files.map(file => file.name)
            );
        },
        {
            manual: true,
            enabled: false,
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: false
        }
    );

    const runDataCurationReq = useQuery(
        [],
        () => {
            return runDataCuration(
                singleDataCuration.curation ? singleDataCuration.curation._id : null,
                selectedDatasetsForCuration.map(dataset => dataset.id)
            );
        },
        {
            manual: true,
            enabled: false,
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: false
        }
    );

    const {
        curationData,
        singleDataCuration,
        setSingleDataCuration,
        generateOriginalFilesPreviewObject,
        generateOriginalFileNamesForDB,
        onBeforeFileChange,
        onCurationFileAdd,
        onCurationFileRemove,
        onSectionFocus,
        onCurationCodeUpdate,
        uploadDataTabs,
        onClickRunCurate
    } = useSingleDataCuration({
        createNewCurationReq,
        existingCurationReq,
        runDataCurationReq,
        mergeFilesReq,
        updateCurationReq
    });

    useEffect(() => {
        selectedDatasetsForCuration.forEach(file => {
            onCurationFileAdd(file);
        });
    }, [selectedDatasetsForCuration]);

    const onChangeActiveFileTab = useCallback(payload => {
        setSingleDataCuration(singleCurationSetActiveFileTab(payload));
    }, []);

    const handleButtonClick = useCallback(() => {
        setButtonDisabled(true);
        onClickRunCurate();
    }, [onClickRunCurate]);

    const renderCurationMessage = () => {
        if (selectedDatasetsForCuration.length === 0) {
            return (
                <CurationNoFilesContainerStyled>
                    {SECONDARY_TITLES.NO_FILES_MESSAGE}
                    <AddDatasetLinkStyled variant={"text"} onClick={() => history.push("/dashboard/data")}>
                        {SECONDARY_TITLES.ADD_DATASETS}
                    </AddDatasetLinkStyled>
                </CurationNoFilesContainerStyled>
            );
        } else {
            return SECONDARY_TITLES.UPLOAD_DESC;
        }
    };

    return (
        <>
            <div>
                <div>
                    <div>
                        <ArrowLeftStyled/>
                        <BackButtonStyled
                            variant="text"
                            onClick={() => {
                                history.push("/dashboard/data-curation");
                            }}
                            disableElevation={true}
                            disableRipple={true}
                            disableFocusRipple={true}
                        >
                            <Text size={14} color={vantiColors.darkBlue7}>
                                Back
                            </Text>
                        </BackButtonStyled>
                    </div>
                    <CurationPageHeaderStyled data-testid={"data-curation-page-header"}>
                        <CurationPagePrimaryTitleStyled data-testid={"data-curation-page-header-title"} size={22}>
                            {CURATION_PAGE_TITLE}
                        </CurationPagePrimaryTitleStyled>
                        <CurationPageDescStyled
                            data-testid={"data-curation-page-header-subheader"}
                            color={vantiColors.gray41}
                            size={16}
                        >
                            {CURATION_PAGE_DESC}
                        </CurationPageDescStyled>
                    </CurationPageHeaderStyled>

                    <CurationNameContainerStyled>
                        <CurationPageSecondaryTitleStyled>{SECONDARY_TITLES.CURATION_NAME}</CurationPageSecondaryTitleStyled>
                        <CurationNameInputStyled
                            numOfCurations={numOfCurations}
                            data-testid={"data-curation-name-input"}
                        ></CurationNameInputStyled>
                    </CurationNameContainerStyled>
                    <CurationFileUploadContainerStyled>

                        <FilesPreviewContainerStyled>
                            <YourDataWrapperStyled>

                            <CurationFileUploadBHeaderStyled>
                                <CurationFileUploadHeaderTextStyled>
                                    <DataSectionStyled>{SECONDARY_TITLES.YOUR_DATA}</DataSectionStyled>
                                    <CurationFileUploadDescStyled color={vantiColors.gray41} size={16}>
                                        {renderCurationMessage()}
                                    </CurationFileUploadDescStyled>
                                </CurationFileUploadHeaderTextStyled>
                            </CurationFileUploadBHeaderStyled>
                                <FlexItem dense={"full"}>

                                <CustomAddDatasetPopoverButton
                                    open={anchorEl}
                                    displayOtherDatasets={displayOtherDatasets}
                                    setDisplayOtherDatasets={setDisplayOtherDatasets}
                                    id={!!anchorEl && "simple-popover"}
                                    setAnchorEl={setAnchorEl}
                                    anchorEl={anchorEl}
                                    transitionDuration={600}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                    }}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right"
                                    }}
                                    popoverButtonText='Add dataset'
                                    datasetSelectionCount={datasetSelectionCount}
                                    setDatasetSelectionCount={setDatasetSelectionCount}
                                >

                                </CustomAddDatasetPopoverButton>
                                </FlexItem>
                            </YourDataWrapperStyled>

                            <CurationFileUploadWrapper
                                titleProps={selectedDatasetsForCuration}
                                onSectionFocus={() => {
                                }}
                                files={selectedDatasetsForCuration}
                                onCurationFileAdd={newFile => {
                                    if (
                                        singleDataCuration.shouldNotifyAboutOriginalFilesChange &&
                                        (existingCurationReq.data?._id || singleDataCuration.curation)
                                    ) {
                                        onBeforeFileChange(fileChangingActionTypes.ADD_FILE, newFile);
                                        return;
                                    }
                                    onCurationFileAdd(newFile);
                                }}
                                onCurationFileRemove={fileId => {
                                    if (
                                        singleDataCuration.shouldNotifyAboutOriginalFilesChange &&
                                        (existingCurationReq.data?._id || singleDataCuration.curation)
                                    ) {
                                        onBeforeFileChange(fileChangingActionTypes.REMOVE_FILE, fileId);
                                        return;
                                    }
                                    onCurationFileRemove(fileId);
                                }}
                            ></CurationFileUploadWrapper>

                            <Divider/>

                            <CurationInputPreviewTitleStyled> {SECONDARY_TITLES.INPUT_PREVIEW} </CurationInputPreviewTitleStyled>
                            <CurationFilesTabsWrapper
                                data-testid={"view-files-tabs-container"}
                                titleProps={{}}
                                activeSectionIndex={singleDataCuration.activeSectionIndex}
                                onSectionFocus={onSectionFocus}
                                activeFileTab={singleDataCuration.activeFileTab}
                                setActiveFileTab={onChangeActiveFileTab}
                                files={selectedDatasetsForCuration}
                                uploadDataTabs={uploadDataTabs}
                            />
                        </FilesPreviewContainerStyled>


                    </CurationFileUploadContainerStyled>


                    <CurationCodeContainerStyled>
                        <CurationSectionTitleStyled>{SECONDARY_TITLES.CURATION}</CurationSectionTitleStyled>
                        <CurationCodeInnerContainerStyled>
                            <CurationCodeSectionTitleStyled color={vantiColors.gray41} size={16}>
                                {TERTIARY_TITLES.PYTHON_CODE}
                            </CurationCodeSectionTitleStyled>
                            {singleDataCuration.newCurationError ||
                                singleDataCuration.curationRunError ||
                                runDataCurationReq.error?.message && (
                                    <ExecErrorContainer>
                                        <Text data-testid="connection-ok-text" color={vantiColors.rose6}>
                                            {singleDataCuration.curationRunError}
                                        </Text>
                                    </ExecErrorContainer>
                                )}
                            <CodeWrapperStyled
                                preview={SAMPLE_CODE}
                                code={curationData.code}
                                onCodeUpdate={onCurationCodeUpdate}
                                error={singleDataCuration.mergeError || mergeFilesReq.error?.message}
                            />
                        </CurationCodeInnerContainerStyled>

                        <CurationCodeRunButtonStyled
                            buttonInfo={{
                                disabled: !curationData.code.trim().length || isButtonDisabled,
                                onClick: handleButtonClick,
                                label: "Run"
                            }}
                            variant={VARIANTS.SECONDARY}
                        />

                        {singleDataCuration?.curationJob?.curatedFilePreview && (
                            <CurationPreviewContainerStyled>
                                <CurationPreviewTextStyled>{SECONDARY_TITLES.CURATION_PREVIEW}</CurationPreviewTextStyled>
                                <CurationOutputTextStyled color={vantiColors.gray41} size={16}>
                                    {TERTIARY_TITLES.CURATION_OUTPUT_DESC}
                                </CurationOutputTextStyled>

                                <FlexItem container dense="full">
                                    <CurationCSVOutputPreviewWrapper
                                        titleProps={{}}
                                        curatedPreview={singleDataCuration?.curationJob?.curatedFilePreview}
                                    />
                                </FlexItem>

                                <CurationSaveButtonStyled
                                    buttonInfo={{
                                        disabled: !curationData.code.trim().length,
                                        onClick: () => {
                                            history.push("/dashboard/data");
                                        },
                                        label: "Save"
                                    }}
                                    variant={VARIANTS.PRIMARY}
                                />
                            </CurationPreviewContainerStyled>
                        )}
                    </CurationCodeContainerStyled>
                </div>
            </div>
        </>
    );
};

export default memo(SingleDataCuration);
