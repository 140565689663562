import socket from "./socket";

const getRoomIdentifier = accountId => {
  return `account/${accountId}`;
};

export function joinAccountEvents(accountId) {
  socket.joinRoom(getRoomIdentifier(accountId), async () => socket.emit("account", accountId, "join"));
}

export function leaveAccountEvents(accountId) {
  socket.leaveRoom(getRoomIdentifier(accountId), async () => socket.emit("account", accountId, "leave"));
}

export function onUpdateNotification(handler) {
  socket.on(`notification/update`, handler);

  socket.on(`web-notification/create`, handler);
  socket.on(`web-notification/delete`, handler);
  socket.on(`web-notification/update`, handler);

  return () => {
    socket.off(`notification/update`, handler);
    socket.off(`web-notification/create`, handler);
  };
}

export async function emitStationIdToDelete(stationId) {
  await socket.emit("station-delete", stationId);
}
