import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import qs from "querystring";

export default function useQuery() {
  const location = useLocation();

  const resultQuery = useMemo(() => {
    const query = location.search;

    if (query.startsWith("?")) {
      return qs.parse(query.replace("?", ""));
    }

    return qs.parse(query);
  }, [location.search]);

  return resultQuery;
}
