import React, { memo, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import EditSingleDataConnector from "./EditSingleDataConnector";

import { DataConnectorsContext } from "../../../DataConnectorsPage";

import { getDataConnector } from "services/api/dataConnectors";

const EditSingleDataConnectorWrapper = () => {
  const params = useParams();

  const { connectorId } = params;
  const { dataConnectors } = useContext(DataConnectorsContext);

  const [existingConnector, setExistingContainer] = useState(null);

  useEffect(async () => {
    const found = dataConnectors.find(connector => connector._id === connectorId);

    if (found) {
      setExistingContainer(found);
    } else {
      const connector = await getDataConnector(connectorId);

      setExistingContainer(connector);
    }
  }, [connectorId, dataConnectors]);

  return existingConnector && <EditSingleDataConnector existingConnector={existingConnector} />;
};

export default memo(EditSingleDataConnectorWrapper);
