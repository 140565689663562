import React, { memo } from "react";

const AssemblyTestingElectronicIcon45 = () => (
  <svg width="31" height="45" viewBox="0 0 31 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.37885 7.72632H23.821V6.23158V3.98947H19.3367C19.3367 3.98947 18.5894 1 15.5999 1C12.6104 1 11.8631 3.98947 11.8631 3.98947H7.37885V6.23158V7.72632Z"
      fill="#86E8B6"
    />
    <path
      d="M23.821 6.23158V7.72632H7.37885V6.23158M23.821 6.23158V3.98947H19.3367C19.3367 3.98947 18.5894 1 15.5999 1C12.6104 1 11.8631 3.98947 11.8631 3.98947H7.37885V6.23158M23.821 6.23158H29.7999V43.6H1.3999V6.23158H7.37885M14.8525 16.6947H26.8104M14.8525 25.6632H26.8104M14.8525 34.6316H26.8104M5.13674 15.9474L7.37885 18.1895L11.8631 14.4526M5.13674 24.9158L7.37885 27.1579L11.8631 23.4211M5.13674 37.621L10.3683 32.3895M10.3683 37.621L5.13674 32.3895"
      stroke="black"
    />
    <circle cx="15.5999" cy="4.36318" r="1.12105" fill="black" />
  </svg>
);

export default memo(AssemblyTestingElectronicIcon45);
