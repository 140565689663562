import { actionTypes } from "./actions";
import { defaultMultipleResourceOperationState } from "utils/modules-utils";

const defaultState = defaultMultipleResourceOperationState();

export default function deleteProductReducer(state = defaultState, action) {
  const productId = action?.payload?.productId;
  switch (action.type) {
    case actionTypes.STATION_DELETE_PRODUCT_REQUEST: {
      state.loading[productId] = true;
      state.success[productId] = false;
      return { ...state };
    }
    case actionTypes.STATION_DELETE_PRODUCT_SUCCESS: {
      state.success[productId] = true;
      return { ...state };
    }
    case actionTypes.STATION_DELETE_PRODUCT_ERROR: {
      state.error[productId] = action.payload.error;
      state.success[productId] = false;
      return { ...state };
    }
    case actionTypes.STATION_DELETE_PRODUCT_FULFILL: {
      delete state.loading[productId];
      delete state.success[productId];
      return { ...state };
    }
    default:
      return state;
  }
}
