/* eslint-disable no-prototype-builtins */
import isEmpty from "lodash/isEmpty";
import { unzip } from "unzipit";
import { logger } from 'utils/logger'
import { FILE_TYPES, getFileType } from "./";
import { readCsv } from "./csv";
import errors from "./errors";

import { ZipDatasetContentType, ZipDatasetMetadataBuildingError, ZipDatasetMetadataParser,  } from "@vanti-analytics-org/vanti-common";

export const zipStructure = {
  STRUCTURE_OK: "STRUCTURE_OK",
  FOLDER_DEPTH: "FOLDER_DEPTH",
  SINGLE_1LVL_FOLDER: "SINGLE_1LVL_FOLDER",
  MULTIPLE_1LVL_FOLDERS: "MULTIPLE_1LVL_FOLDERS",
  EMPTY_FOLDER: "EMPTY_FOLDER"
};

const SUPPORTED_FILE_TYPES = [".png", ".jpg", ".jpeg", ".tiff", ".bmp", ".csv"];

const FILE_VALIDATION_ERROR = {
  ZIP_BAD_FORMAT: {
    error: "Error: invalid zip file",
    tip: null
  },
  CSV_IN_ZIP: {
    error: "File type not supported",
    tip: "Please unzip the file and upload a single CSV"
  },
  TYPE_NOT_SUPPORTED: {
    error: "File type not supported",
    tip: "For image data, upload a flat zip, or a zip with folders. For tabular data upload a CSV file."
  },
  EMPTY_FOLDER: {
    error: "The file contains empty folder/s",
    tip: "Please remove the empty folder/s or add images"
  },
  BAD_COMPRESSION: {
    error: "File type is not supported",
    tip:
      "To create a valid zip file, please compile the relevant folders/files to a designated folder and then compress the folder."
  },
  BAD_FILENAME: {
    error: "The file name contains space",
    tip: "Please remove all spaces and try again."
  }
};

class ZipValidationError extends Error {
  constructor({ error, tip }) {
    super();

    Error.captureStackTrace(this, ZipValidationError);

    this.error = error;
    this.tip = tip;
  }
}

export async function read(uploadedFile, callback, updateProgress) {
  try {
    const { entries } = await unzip(uploadedFile);
    const [fileName, file] = Object.entries(entries)[0];
    if (getFileType(fileName) !== FILE_TYPES.CSV) {
      callback(new Error(errors.ZIP_WITHOUT_CSV), null);
      return;
    }
    readCsv(file, callback, updateProgress);
  } catch (error) {
    callback(error);
  }
}
export async function getFileTypesInZip(uploadedFile) {
  try {
    const { entries } = await unzip(uploadedFile);
    const zipFiles = Object.values(entries).filter(
      file => !file.name.startsWith("__MACOSX") && !file.name.endsWith(".DS_Store") && !file.isDirectory
    );
    let fileTypes = new Set();
    for (const file of zipFiles) {
      fileTypes.add(
        file.name
          .split(".")
          .pop()
          .toUpperCase()
      );
    }
    return fileTypes;
  } catch (error) {
    logger.error(error);
    return null;
  }
}

export async function zipStructureValidation(uploadedFile) {
  try {
    const { entries } = await unzip(uploadedFile);

    const zipFiles = Object.values(entries).filter(
      file => !(file.name.startsWith("__MACOSX") || file.name.endsWith(".DS_Store"))
    );

    if (isEmpty(zipFiles)) {
      throw new ZipValidationError(FILE_VALIDATION_ERROR.EMPTY_FOLDER);
    }

    // Expected structure inside zip for supervised flow is: <main folder>/<inner folder>/<file>, 2 inner folders at least
    // Expected structure inside zip for unsupervised flow is: <file>
    const folderNames = [];
    const foldersFiles = {};
    let oneLvlFolderCounter = 0;
    for (const file of zipFiles) {
      const nameComponents = file.name.split("/").filter(name => !!name && !name.startsWith("."));

      if (!file.isDirectory) {
        if (file.name.includes(" ")) {
          throw new ZipValidationError(FILE_VALIDATION_ERROR.BAD_FILENAME);
        }

        const fileExtension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();

        if (!SUPPORTED_FILE_TYPES.includes(fileExtension)) {
          throw new ZipValidationError(FILE_VALIDATION_ERROR.TYPE_NOT_SUPPORTED);
        }
      } else {
        if (nameComponents.length > 3) {
          throw new ZipValidationError(FILE_VALIDATION_ERROR.BAD_COMPRESSION);
        }
      }

      if (nameComponents.length > 3) {
        return zipStructure.FOLDER_DEPTH;
      } else if (nameComponents.length === 2 && file.name.at(-1) !== "/") {
        const folderName = nameComponents[0];
        if (!folderNames.includes(folderName)) {
          folderNames.push(folderName);
          oneLvlFolderCounter++;
        }
      } else if (nameComponents.length > 2) {
        foldersFiles[nameComponents.at(-2)] = Object.hasOwn(foldersFiles, nameComponents.at(-2))
          ? foldersFiles[nameComponents.at(-2)] + 1
          : 0;
      }
    }

    if (oneLvlFolderCounter >= 1) {
      throw new ZipValidationError(FILE_VALIDATION_ERROR.BAD_COMPRESSION);
    }

    return null;
  } catch (error) {
    if (error instanceof ZipValidationError) {
      return error;
    } else {
      return new ZipValidationError(FILE_VALIDATION_ERROR.ZIP_BAD_FORMAT);
    }
  }
}

export async function validateAndExtractZipContentFlexible(uploadedFile) {
  const result = await ZipDatasetMetadataParser.parse(uploadedFile);

  if (result.contentType == ZipDatasetContentType.Tabular) {
    return result.data;
  }

  const makeObjectUrl = zipParserEntry =>
    zipParserEntry.content
      .blob("image/png")
      .then(blob => {
        return URL.createObjectURL(blob);
      })
      .catch(logger.error);

  if (result.error) {
    switch (result.error.code) {
      case ZipDatasetMetadataBuildingError.EmptyZip:
        throw new ZipValidationError(FILE_VALIDATION_ERROR.EMPTY_FOLDER);
      case ZipDatasetMetadataBuildingError.InvalidZipStructure:
        throw new ZipValidationError(FILE_VALIDATION_ERROR.BAD_COMPRESSION);
      case ZipDatasetMetadataBuildingError.InvalidZipEntryFilename:
        throw new ZipValidationError(
          FILE_VALIDATION_ERROR[result.error.data] || FILE_VALIDATION_ERROR.TYPE_NOT_SUPPORTED
        );
    }
  }

  return {
    kpiLabels: Object.entries(result.data.kpiLabels || {}).reduce((acc, [key, entry]) => {
      return {
        ...acc,
        [key]: makeObjectUrl(entry)
      };
    }, {}),
    imagesPreview: (result.data.clustersPreviews || []).map(makeObjectUrl)
  };
}

export async function formatAndGetZipContent(uploadedFile, numberOfImages = 3) {
  try {
    const { entries } = await unzip(uploadedFile);
    const zipFiles = Object.values(entries).filter(
      file => !(file.name.startsWith("__MACOSX") || file.name.endsWith(".DS_Store"))
    );

    const result = zipFiles.reduce(
      (acc, zipFile) => {
        if (!zipFile.isDirectory) {
          const nameComponents = zipFile.name.split("/");

          if (nameComponents.length === 3) {
            const folderName = nameComponents[1];

            if (!acc.kpiLabels[folderName]) {
              const content = zipFile.blob("image/png").then(blob => {
                return URL.createObjectURL(blob);
              });

              acc.kpiLabels[folderName] = content;
            }
          } else if (acc.imagesPreview.length < numberOfImages) {
            const content = zipFile.blob("image/png").then(blob => {
              return URL.createObjectURL(blob);
            });

            acc.imagesPreview.push(content);
          }
        }

        return acc;
      },
      {
        kpiLabels: {},
        imagesPreview: []
      }
    );

    return result;
  } catch (error) {
    logger.error(error);
    return {
      kpiLabels: null,
      imagesPreview: null
    };
  }
}

export async function getZipImages(uploadedFile, numberOfImages) {
  try {
    const { entries } = await unzip(uploadedFile);
    const zipFiles = Object.values(entries).filter(
      file => !(file.name.startsWith("__MACOSX") || file.name.endsWith(".DS_Store"))
    );

    const result = zipFiles.reduce((acc, zipFile) => {
      if (!zipFile.isDirectory) {
        if (acc.length < numberOfImages) {
          const content = zipFile.blob("image/png").then(blob => {
            return URL.createObjectURL(blob);
          });

          acc.push = content;
        }
      }

      return acc;
    }, []);

    return [result, isEmpty(zipFiles)];
  } catch (error) {
    logger.error(error);
    return [null, false];
  }
}

export async function getZipFolderNames(uploadedFile) {
  try {
    const { entries } = await unzip(uploadedFile);
    const zipFiles = Object.values(entries).filter(
      file => !(file.name.startsWith("__MACOSX") || file.name.endsWith(".DS_Store"))
    );
    let folderNames = [];

    for (const file of zipFiles) {
      let nameComponents = file.name.split("/");
      if (nameComponents.length === 3) {
        const folderName = nameComponents[1];
        if (!folderNames.includes(folderName)) {
          folderNames.push(folderName);
        }
      }
    }
    return folderNames;
  } catch (error) {
    logger.error(error);
    return null;
  }
}

export async function getExtractedFilesFromZip(zipFile, fileTypesToInclude) {
  try {
    const files = [];
    const directories = [];

    const { entries } = await unzip(zipFile);

    for await (const [name, entry] of Object.entries(entries)) {
      const fileExtensionParts = name.split(".");
      const fileExtension = fileExtensionParts[fileExtensionParts.length - 1].toUpperCase();
      if (
        !name.startsWith("__MACOSX") &&
        !name.endsWith(".DS_Store") &&
        !entry.isDirectory &&
        fileTypesToInclude.includes(fileExtension)
      ) {
        const blob = await entry.blob();
        const file = new File([blob], name);
        files.push(file);
      } else if (entry.isDirectory) {
        directories.push(entry);
      }
    }

    return { files, directories };
  } catch (error) {
    logger.error(error);
    return null;
  }
}
