import React, { memo, useMemo } from "react";
import { useHistory } from "react-router-dom";

import CircularProgress from "components/ui/Progress/CircularProgress";
import { FlexItem, Text } from "components/ui";
import { UPDATE_CONNECTOR_BUTTON_TEXT } from "pages/side-bar/data-connectors/constants";

import { vantiColors } from "assets/jss/palette";

import SecondaryVantiButtonStyles from "components/ui/Buttons/secondary/SecondaryVantiButton";

import useStyles from "../styles";

const EditConnectButtonSection = ({ actionResponse, onConnectClick, fieldsChanged, dataTestId }) => {
  const history = useHistory();
  const classes = useStyles();

  const messages = useMemo(() => {
    if (actionResponse.loading) {
      return null;
    }

    if (actionResponse.error) {
      return (
        <FlexItem dense="bottom">
          <Text data-testid="connection-error-text" color={vantiColors.rose6}>
            {actionResponse.error.message}
          </Text>
        </FlexItem>
      );
    }
  }, [actionResponse.loading, actionResponse.error, fieldsChanged]);

  const buttonProps = useMemo(
    () => ({
      "data-testid": `update-connector-button`,
      disabled: !onConnectClick || actionResponse.loading || (actionResponse.error && !fieldsChanged),
      onClick: async () => {
        const result = await onConnectClick();

        if (result.ok) {
          history.push("/dashboard/data-connectors");
        }
      }
    }),
    [history, actionResponse, onConnectClick, fieldsChanged]
  );

  return (
    <FlexItem dtata-testid={dataTestId} container dense="full" justifyContent="flex-end" style={{ textAlign: "left" }}>
      <FlexItem dense="full" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <FlexItem className={classes.connectButton} dense="full">
          <SecondaryVantiButtonStyles {...buttonProps}>
            {actionResponse.loading ? (
              <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress style={{ height: 15, width: 15, color: vantiColors.darkBlue6 }} />
              </span>
            ) : (
              UPDATE_CONNECTOR_BUTTON_TEXT
            )}
          </SecondaryVantiButtonStyles>
        </FlexItem>

        <FlexItem dense="full" container>
          {messages}
        </FlexItem>
      </FlexItem>
    </FlexItem>
  );
};

export default memo(EditConnectButtonSection);
