export const actionTypes = {
  ACCOUNT_SET_STATE: "account.set.state",
  ACCOUNT_CLEAR_STATE: "account.clear.state",
  ACCOUNT_SET_USERS: "account.set.users",
  ACCOUNT_SET_PRODUCTS: "account.set.products",
  ACCOUNT_SET_LINES: "account.set.lines",
  ACCOUNT_ADD_LINE: "account.add.line",
  ACCOUNT_REMOVE_LINE: "account.remove.line",
  ACCOUNT_ADD_PRODUCT: "account.add.product",
  ACCOUNT_REMOVE_PRODUCT: "account.remove.product"
};

export function setUsersAction(users) {
  return { type: actionTypes.ACCOUNT_SET_USERS, payload: users };
}

export function clearAccountStateAction() {
  return { type: actionTypes.ACCOUNT_CLEAR_STATE };
}
