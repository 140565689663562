import Handlebars from "handlebars";

Handlebars.registerHelper({
  ifeq: function(v1, v2, options) {
    if (v1 === v2) {
      return options.fn(this);
    }
    return options.inverse(this);
  },
  ifne: function(v1, v2, options) {
    if (v1 !== v2) {
      return options.fn(this);
    }
    return options.inverse(this);
  },
  iflt: function(v1, v2, options) {
    if (v1 < v2) {
      return options.fn(this);
    }
    return options.inverse(this);
  },
  ifgt: function(v1, v2, options) {
    if (v1 > v2) {
      return options.fn(this);
    }
    return options.inverse(this);
  },
  iflte: function(v1, v2, options) {
    if (v1 <= v2) {
      return options.fn(this);
    }
    return options.inverse(this);
  },
  ifgte: function(v1, v2, options) {
    if (v1 >= v2) {
      return options.fn(this);
    }
    return options.inverse(this);
  },
  ifand: function(...rest) {
    const options = Array.prototype.slice.call(rest, -1);
    if (Array.prototype.slice.call(rest).every(Boolean)) {
      return options.fn(this);
    }
    return options.inverse(this);
  },
  ifor: function(...rest) {
    const options = Array.prototype.slice.call(rest, -1);
    if (Array.prototype.slice.call(rest, 0, -1).some(Boolean)) {
      return options.fn(this);
    }
    return options.inverse(this);
  },
  or: function(...rest) {
    const options = Array.prototype.slice.call(rest, -1)[0];
    const items = Array.prototype.slice.call(rest, 0, -1);
    const item = Array.prototype.filter.call(items, Boolean)[0];
    return options.fn(item);
  }
});

export default Handlebars;
