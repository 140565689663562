import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    justifyContent: "space-between",
    padding: theme.spacing(2, 9.5, 2, 3),
    borderBottom: `2px solid ${theme.palette.vantiColors.gray18}`,
    backgroundColor: theme.palette.vantiColors.white
  }
}));
