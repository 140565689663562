import { Text } from "components/ui";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import CustomTreeItemLabel from "components/layouts/tree-view/CustomTreeItemLabel";
import { TreeView } from "@material-ui/lab";
import CustomTreeFooter from "components/layouts/tree-view/CustomTreeFooter";
import Divider from "@material-ui/core/Divider";
import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import { TreeViewContext } from "common/hooks/context-hooks/use-treeview-context";

import TreeViewArrowCollapsed from "assets/icons/tree-view/TreeViewArrowCollapsed16";
import TreeViewArrowExpanded from "assets/icons/tree-view/TreeViewArrowExpanded16";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { useQuery } from "react-query";
import { FETCH_ACCOUNT_DATASETS_KEY } from "pages/side-bar/data-page/DataPage";
import { getAccountDatasets } from "services/api/dataset";
import { logger } from "utils/logger";
import TreeViewEmptyState from "./TreeViewEmptyState";
import TreeItemStyled from "./CustomTreeItem";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import CustomTreeSearchBar from "./CustomTreeSearchBar";

const CustomTreeDivider = styled(Divider)({
  marginBottom: 15,
  marginTop: 24
});

const TreeViewTitle = styled(Text)(({ theme, usePadding = true }) => ({
  fontWeight: 600,
  fontSize: "14px",
  color: vantiColors.gray19,
  minHeight: 80,
  display: "flex",
  alignItems: "center",
  paddingLeft: usePadding ? theme.spacing(1) : 0
}));

const TreeViewWrapper = styled("div")(({ treeViewOpen }) => ({
  order: 1,
  display: treeViewOpen ? "flex" : "none",
  minWidth: "219px",
  "& .MuiTypography-root": {
    fontFamily: "Inter"
  }
}));

const TreeViewContentContainer = styled("div")(({ theme }) => ({
  width: theme.spacing(27.375),
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  padding: "0 8px",
  background: theme.palette.vantiColors.white
}));

const TreeViewSectionName = styled("div")({
  color: vantiColors.gray33,
  margin: "8.5px 0",
  fontSize: "12px",
  lineHeight: "14px"
});

const TreeViewBodyWrapperStyled = styled("div")({
  height: "100%",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: 8,
  marginTop: 12,
  "&::-webkit-scrollbar": {
    width: "6px",
    backgroundColor: "transparent"
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: vantiColors.lightGray1,
    borderRadius: "40px"
  }
});

const CSSTransitionStyled = styled(CSSTransition)({
  "&.enter": null, //we dont need enter (node add) animation
  "&.exit": {
    opacity: 0,
    transition: "opacity 0.4s ease-out 0.4s"
  }
});

const CustomTreeView = () => {
  const {
    isTreeViewOpen,
    treeViewSearchValue,
    treeViewContent: { title, tree, footer, dialog, options = {} }
  } = useContext(TreeViewContext);

  const [expanded, setExpanded] = useState([]);
  const [isTreeExpanded, setIsTreeExpanded] = useState(false);
  const { datasetsContext, chatContext } = useContext(AppContext);
  const { datasets, setContext: setDatasetsContext } = datasetsContext;
  const { chatList } = chatContext;
  const isInEmptyState = useMemo(() => !Object.keys(datasets).length || !chatList.length, [datasets, chatList]);

  const fetchAccountDatasetsReq = useQuery([FETCH_ACCOUNT_DATASETS_KEY], async () => getAccountDatasets(), {
    manual: false,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    onSuccess: ({ data }) => {
      setDatasetsContext(prevState => {
        const fetchedDatasets = {};
        for (const dataset of data) {
          fetchedDatasets[dataset._id] = dataset;
        }
        return { ...prevState, datasets: fetchedDatasets };
      });
    },
    onError: error => logger.error("Failed to get all datasets in account", error)
  });

  useEffect(async () => {
    if (!Object.keys(datasets).length) {
      await fetchAccountDatasetsReq.refetch();
    }
  }, []);

  const treeBuilder = useCallback(
    nodes => {
      if (Array.isArray(nodes)) {
        return nodes.map(node => treeBuilder(node));
      }

      return (
        <CSSTransitionStyled
          key={`tree-item-css-transition-wrapper-${nodes.id || nodes.name}`}
          data-testid={`tree-item-css-transition-wrapper-${nodes.id || nodes.name}`}
          timeout={treeViewSearchValue?.length ? 0 : 800}
        >
          {nodes.type === "section" ? (
            <TreeViewSectionName data-testid={`tree-item-view-section-${nodes.name}`}>{nodes.name}</TreeViewSectionName>
          ) : (
            <TreeItemStyled
              data-testid={nodes.id}
              nodeId={nodes.id}
              icon={nodes.icon}
              label={<CustomTreeItemLabel node={nodes} shouldHighlight={nodes.shouldHighlight} />}
              onLabelClick={nodes.onLabelClick}
              displayIcon={nodes.displayIcon === false ? "false" : null}
              shouldHighlight={nodes.shouldHighlight}
              visualNestingLevel={nodes.visualNestingLevel || 0}
            >
              {nodes.children?.length > 0 ? nodes.children.map(node => treeBuilder(node)) : <></>}
            </TreeItemStyled>
          )}
        </CSSTransitionStyled>
      );
    },
    [tree, treeViewSearchValue]
  );

  const getNodesIdList = useCallback((nodes, nodeIdList) => {
    nodeIdList.push(nodes.id);
    if (Array.isArray(nodes?.children)) {
      nodes.children.forEach(node => {
        getNodesIdList(node, nodeIdList);
      });
    }
    return nodeIdList;
  }, []);

  useEffect(() => {
    if (!isTreeExpanded && tree?.children) {
      setExpanded(getNodesIdList(tree, []));
      setIsTreeExpanded(true);
    }
  }, [isTreeExpanded, tree]);

  const treeContent = useMemo(() => {
    const result = tree && treeBuilder(tree);

    if (!result) return null;

    return result;
  }, [tree, treeViewSearchValue]); //treeViewSearchValue is needed here!

  const onNodeSelect = useCallback(
    (event, selectedNodeId) => {
      expanded.indexOf(selectedNodeId) === -1
        ? setExpanded(expanded.concat(selectedNodeId))
        : setExpanded(expanded.filter(nodeId => nodeId !== selectedNodeId));
    },
    [expanded]
  );

  return (
    <TreeViewWrapper treeViewOpen={isTreeViewOpen} data-testid={"tree-view-wrapper"}>
      {dialog}
      <TreeViewContentContainer>
        {!isInEmptyState ? (
          <>
            {title && (
              <TreeViewTitle usePadding={options.useTitlePadding} data-testid={"tree-view-title"}>
                {title}
              </TreeViewTitle>
            )}
            <CustomTreeSearchBar />
            <TreeViewBodyWrapperStyled data-testid={"tree-view-body-wrapper"}>
              {(tree?.children?.length > 0 || treeContent) && (
                <TreeView
                  data-testid={"tree-view"}
                  expanded={expanded}
                  defaultCollapseIcon={<TreeViewArrowExpanded />}
                  defaultExpandIcon={<TreeViewArrowCollapsed />}
                  onNodeSelect={onNodeSelect}
                >
                  <TransitionGroup>{treeContent}</TransitionGroup>
                </TreeView>
              )}
            </TreeViewBodyWrapperStyled>
          </>
        ) : (
          <TreeViewEmptyState data-testid={"tree-view-empty-state"} />
        )}
        {footer && (
          <>
            <CustomTreeDivider data-testid={"tree-view-divider"} />
            <CustomTreeFooter list={footer} data-testid={"custom-tree-view-footer"} />
          </>
        )}
      </TreeViewContentContainer>
    </TreeViewWrapper>
  );
};

export default CustomTreeView;
