import React, { memo, useRef, useEffect, useState, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { TableRow } from "@material-ui/core";
import { Text } from "components/ui";
import BodyCell from "./body-cell/BodyCell";
import ExpandCell from "../expand-cell/ExpandCell";
import Button from "@material-ui/core/Button";
import { LightTooltip } from "components/ui/tooltips/tooltip";
import { accountLicenseSelector } from "modules/account/state/selectors";
import { Licenses, ROLES_ENUM } from "common/constants/AccountConstants";
import { BLOCK_DB_STATES } from "common/constants/BlockConstants";
import { STATION_TYPES } from "utils/onboarding";
import * as stationService from "services/api/station";
import usePermissions from "common/hooks/use-permissions";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as QuestionMark } from "assets/icons/station-table/empty-view/QuestionMark.svg";
import useStyles from "./styles";

const TableBodyRows = ({ rowChunks = [], prepareRow, onDelete }) => {
  const classes = useStyles();
  const history = useHistory();

  const anchorRef = useRef();

  const accountLicense = useSelector(accountLicenseSelector);
  const isGrantedToDelete = usePermissions([ROLES_ENUM.DELETE_STATION]);

  const [chunksToRender, setChunksToRender] = useState(2);

  const observerCallback = useCallback(() => {
    setChunksToRender(state => state + 1);
  }, [chunksToRender, setChunksToRender]);

  useEffect(() => {
    if (anchorRef?.current) {
      const observer = new IntersectionObserver(observerCallback, {
        rootMargin: "100px 0px 0px 0px"
      });

      observer.observe(anchorRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [anchorRef]);

  const onStationClick = async station => {
    const stationBlocks = await stationService.getAllStationBlocks(station.id, false);
    const firstStationBlock =
      stationBlocks.find(block => block?.state === station.blockState) || stationBlocks[0] || {};

    if ([BLOCK_DB_STATES.UPLOADED, BLOCK_DB_STATES.UPLOADING].includes(firstStationBlock?.state)) {
      history.push(
        `/dashboard/onboarding/stations/${station?.id}/blocks/${firstStationBlock?.id}/${
          station.type === STATION_TYPES.CUSTOM ? STATION_TYPES.CUSTOM : "regular"
        }`
      );
    } else {
      history.push(`/dashboard/stations/${station.id}`);
    }
  };

  const rowsToRender = useMemo(() => {
    return rowChunks.slice(0, chunksToRender).reduce((acc, item) => {
      acc.push(...item);
      return acc;
    }, []);
  }, [chunksToRender, rowChunks]);

  if (isEmpty(rowChunks)) {
    return (
      <div className={classes.emptyView}>
        <div className={classes.emptyIconWrapper}>
          <QuestionMark />
        </div>
        <Text h={4}>There are no results for that filter combination.</Text>
        <Text body={1}>Please try a different criterion.</Text>
      </div>
    );
  }

  return (
    <>
      {rowsToRender.map(row => {
        prepareRow(row);
        const { key, ...rest } = row.getRowProps();

        if (row.isGrouped) {
          // TODO: Undefined value should be somehow treated, need to clarify with Product Team
          if (row.groupByVal !== "undefined") {
            return (
              <TableRow key={key} classes={{ root: classes.root }} {...rest}>
                {row.cells.map(cell => {
                  const { key, ...rest } = cell.getCellProps();

                  return cell.isGrouped ? (
                    <ExpandCell
                      isClosed={!row.isExpanded}
                      groupedBy={cell.row.groupByID}
                      value={cell.value}
                      expandProps={row.getToggleRowExpandedProps()}
                    />
                  ) : (
                    <BodyCell key={key} {...rest}>
                      {cell.render("Cell")}
                    </BodyCell>
                  );
                })}
              </TableRow>
            );
          }

          return null;
        }

        return (
          <TableRow
            data-testid={`station-table-row-${row.original.stationObject.id}`}
            key={key}
            classes={{ root: classes.root }}
            onClick={() => onStationClick(row.original.stationObject)}
            {...rest}
          >
            {row.cells.map(cell => {
              const { key, ...rest } = cell.getCellProps();

              return (
                <BodyCell key={key} id={cell.column.id} {...rest}>
                  {cell.render("Cell")}
                </BodyCell>
              );
            })}

            <div className={classes.trashTooltip}>
              <LightTooltip
                data-testid="can't-delete-tooltip"
                title={
                  row.original.stationObject.blockState === BLOCK_DB_STATES.IN_DEPLOYMENT
                    ? "Can't delete while block is in deployment"
                    : ""
                }
                onClick={e => {
                  e.stopPropagation();
                }}
                className={classes.toolTip}
              >
                <div className={classes.notAllowedCursor}>
                  <Button
                    disableRipple
                    id="test-delete-station-icon"
                    onClick={e => onDelete(e, row.original.stationObject)}
                    size="small"
                    variant="text"
                    className={classes.delete}
                    disabled={
                      !isGrantedToDelete ||
                      row.original.stationObject.blockState === BLOCK_DB_STATES.IN_DEPLOYMENT ||
                      (row.original.stationObject.isDummy && accountLicense === Licenses.TRIAL)
                    }
                  >
                    <TrashIcon />
                  </Button>
                </div>
              </LightTooltip>
            </div>
          </TableRow>
        );
      })}
      <span ref={anchorRef} />
    </>
  );
};

export default memo(TableBodyRows);
