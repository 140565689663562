export const actionTypes = {
  CREATE_ACCOUNT_REQUEST: "account.createAccount.request",
  CREATE_ACCOUNT_SUCCESS: "account.createAccount.success",
  CREATE_ACCOUNT_ERROR: "account.createAccount.error",
  CREATE_ACCOUNT_FULFILL: "account.createAccount.fulfill"
};

export function createAccountRequestAction(
  email,
  accountName,
  phoneNumber,
  selectedIndustries,
  selectedRole,
  marketplace,
  token
) {
  return {
    type: actionTypes.CREATE_ACCOUNT_REQUEST,
    payload: { email, accountName, phoneNumber, selectedIndustries, selectedRole, marketplace, token }
  };
}
