import React from "react";

import { logger } from "utils/logger";
import imageExtensions from "image-extensions";

import { getFileTypesInZip, getZipFolderNames, zipStructureValidation } from "utils/user-data-reader/zip";

import { STATION_TYPE } from "@vanti-analytics-org/vanti-common";

import { FILE_TYPES, getFileTypeByFile } from "utils/user-data-reader";
import { GOAL_TYPE } from "pages/dashboard/station/constants/dashboard";
import { vantiColors } from "assets/jss/palette";
import { BLOCK_DB_STATES, STATION_STATES } from "./BlockConstants";

import PaintShopIcon from "assets/icons/goal-types/PaintShopIcon";
import ManualAssemblyIcon from "assets/icons/goal-types/ManualAssemblyIcon";
import AssemblyTestingIcon48 from "assets/icons/goal-types/AssemblyTestingNewIcon48";
import MechanicalPartsIcon from "assets/icons/goal-types/MechanicalPartsIcon";
import VisualInspectionIcon from "assets/icons/goal-types/VisualInspectionIcon";
import NewDefectDetectionIcon from "assets/icons/goal-types/NewDefectDetectionIcon";
import NewPredictiveQualityIcon from "assets/icons/goal-types/NewPredictiveQualityIcon";
import NewProcessOptimizationIcon from "assets/icons/goal-types/NewProcessOptimizationIcon";
import ProductionEfficiencyIcon from "assets/icons/goal-types/ProductionEfficiencyIcon";
import AssemblyTestingElectronicIcon45 from "assets/icons/goal-types/AssemblyTestingElectronicIcon45";
import AssemblyOptimization from "assets/icons/goal-types/AssemblyOptimization";
import OptimizeRecipeIcon from "assets/icons/goal-types/OptimizeRecipeIcon";
import VirtualMetrologyIcon from "assets/icons/goal-types/VirtualMetrologyIcon";
import AdaptiveSPCIcon from "assets/icons/goal-types/AdaptiveSPCIcon";
import DefectsInspectionIcon from "assets/icons/goal-types/DefectsInspectionIcon";
import CustomGoalIcon from "assets/icons/goal-types/CustomGoalIcon";

export const STATION_STATUSES = {
  DRAFT: "DRAFT",
  IN_ANALYSIS: "IN_ANALYSIS",
  IN_DEPLOYMENT: "IN_DEPLOYMENT",
  READY: "READY",
  ACTIVE: "ACTIVE",
  FAILED_DEPLOYMENT: "FAILED_DEPLOYMENT",
  FAILED_ANALYSIS: "FAILED_ANALYSIS",
  DEPLOYED: "DEPLOYED",
  PENDING: "PENDING",
  UPLOADING: "UPLOADING",
  UPLOADED: "UPLOADED"
};

const allTypesSupport = ["*"];
export const stationFilesProperties = (stationType, originalFile) => {
  const { fileType } = originalFile || {};

  if (stationType === STATION_TYPE.CUSTOM) {
    return {
      isSelectKPI: false,
      isDatasetSupported: async file =>
        await isSupportedFileTypes([FILE_TYPES.ZIP, FILE_TYPES.CSV], file, allTypesSupport),
      isPredictionsSupported: async file =>
        await isSupportedFileTypes([FILE_TYPES.ZIP, FILE_TYPES.CSV], file, allTypesSupport),
      isSemiAutomated: true,
      printDatasetSupportedTypes: ``,
      printPredictionsSupportedTypes: ``,
      kpiLabels: [],
      fileStructure: null
    };
  }

  switch (fileType) {
    case FILE_TYPES.CSV:
      if (stationType === STATION_TYPE.SUPERVISED) {
        return {
          isSelectKPI: true,
          isDatasetSupported: async file => await isSupportedFileTypes([FILE_TYPES.CSV], file),
          isPredictionsSupported: async file => await isSupportedFileTypes([FILE_TYPES.CSV], file),
          isSemiAutomated: false,
          printDatasetSupportedTypes: `Supported file types: CSV or a ZIP that contains a CSV file`,
          printPredictionsSupportedTypes: `Supported file types: CSV or a ZIP that contains a CSV file`,
          kpiLabels: [],
          fileStructure: null
        };
      }

      if (stationType === STATION_TYPE.UNSUPERVISED) {
        return {
          isSelectKPI: true,
          isDatasetSupported: async file => await isSupportedFileTypes([FILE_TYPES.CSV], file),
          isPredictionsSupported: async file => await isSupportedFileTypes([FILE_TYPES.CSV], file),
          isSemiAutomated: false,
          printDatasetSupportedTypes: `Supported file types: CSV or a ZIP that contains a CSV file`,
          printPredictionsSupportedTypes: `Supported file types: CSV or a ZIP that contains a CSV file`,
          kpiLabels: [],
          fileStructure: null
        };
      }

      if (stationType === STATION_TYPE.REGRESSION) {
        return {
          isSelectKPI: true,
          isDatasetSupported: async file => await isSupportedFileTypes([FILE_TYPES.CSV], file),
          isPredictionsSupported: async file => await isSupportedFileTypes([FILE_TYPES.CSV], file),
          isSemiAutomated: false,
          // In the near future: `Supported file types: CSV or a ZIP that contains a CSV file.`
          printDatasetSupportedTypes: `Supported file types: CSV or a ZIP that contains a CSV file`,
          printPredictionsSupportedTypes: `Supported file types: CSV or a ZIP that contains a CSV file`,
          kpiLabels: [],
          fileStructure: null
        };
      }
      break;

    case FILE_TYPES.ZIP:
      return {
        isSelectKPI: false,
        isDatasetSupported: async file =>
          await isSupportedFileTypes([FILE_TYPES.ZIP], file, imageExtensions.map(ext => ext.toUpperCase())),
        isPredictionsSupported: async file =>
          await isSupportedFileTypes([FILE_TYPES.ZIP], file, imageExtensions.map(ext => ext.toUpperCase())),
        isSemiAutomated: true,
        printDatasetSupportedTypes: `Supported files: ZIP that contains image files, or folders with image files.`,
        printPredictionsSupportedTypes: `Supported files: ZIP that contains image files, or folders with image files.`,
        kpiLabels: async file => await getZipFolderNames(file),
        fileStructure: async file => await zipStructureValidation(file)
      };
    default:
      logger.error("Failed to return limitation in batch predict modal");
      break;
  }
};

const isSupportedFileTypes = async (fileTypes, file, supportedTypesInZip) => {
  const fileType = getFileTypeByFile(file);
  if (!fileTypes.includes(fileType)) {
    return false;
  }
  const fileTypesInZip = await getFileTypesInZip(file);
  if (fileType === FILE_TYPES.ZIP && supportedTypesInZip !== allTypesSupport) {
    if (fileTypesInZip === null || fileTypesInZip.length === 0) {
      return false;
    }
    for (const inZipfileType of fileTypesInZip) {
      if (!supportedTypesInZip.includes(inZipfileType)) {
        return false;
      }
    }
  }

  return true;
};

export const SERVER_STATUS_LABELS = {
  [BLOCK_DB_STATES.DRAFT]: { text: "DRAFT", backgroundColor: vantiColors.gray19, textColor: "white" },
  [BLOCK_DB_STATES.IN_ANALYSIS]: { text: "IN ANALYSIS", backgroundColor: vantiColors.blue6, textColor: "white" },
  [BLOCK_DB_STATES.READY]: { text: "READY", backgroundColor: vantiColors.purple2, textColor: "white" },
  [BLOCK_DB_STATES.ACTIVE]: { text: "ACTIVE", backgroundColor: vantiColors.green1, textColor: "white" },
  [BLOCK_DB_STATES.DEPLOYED]: { text: "DEPLOYED", backgroundColor: vantiColors.green1, textColor: "white" },
  [BLOCK_DB_STATES.FAILED_ANALYSIS]: { text: "ERROR", backgroundColor: vantiColors.rose6, textColor: "white" },
  [BLOCK_DB_STATES.FAILED_DEPLOYMENT]: { text: "ERROR", backgroundColor: vantiColors.rose6, textColor: "white" },
  [BLOCK_DB_STATES.IN_DEPLOYMENT]: { text: "IN DEPLOYMENT", backgroundColor: vantiColors.green1, textColor: "white" },
  [BLOCK_DB_STATES.PENDING]: { text: "PENDING", backgroundColor: vantiColors.blue7, textColor: "white" },
  [BLOCK_DB_STATES.UPLOADING]: { text: "UPLOADING", backgroundColor: vantiColors.blue7, textColor: "white" },
  [BLOCK_DB_STATES.UPLOADED]: { text: "UPLOADED", backgroundColor: vantiColors.blue7, textColor: "white" }
};

export const WEB_STATUS_LABELS = {
  [STATION_STATES.LIVE]: {
    text: "LIVE",
    lowercaseText: "Live",
    backgroundColor: vantiColors.green1,
    textColor: vantiColors.gray29
  },
  [STATION_STATES.ERROR]: {
    text: "ERROR",
    lowercaseText: "Error",
    backgroundColor: vantiColors.rose6,
    textColor: vantiColors.gray29
  },
  [STATION_STATES.READY]: {
    text: "READY",
    lowercaseText: "Ready",
    backgroundColor: vantiColors.lightGreen2,
    textColor: vantiColors.gray29
  },
  [STATION_STATES.DRAFT]: {
    text: "DRAFT",
    lowercaseText: "Draft",
    backgroundColor: vantiColors.gray38,
    textColor: vantiColors.gray29
  }
};

export const initStationDataState = {
  stationName: "",
  stationDescription: "",
  stationType: ""
};

export const getApplicationCards = onStationGoalClick => {
  const applicationCards = {
    paintShop: {
      title: "Paint shop defect detection",
      image: <PaintShopIcon {...{}} />,
      description: "Reduce number of defects and rework time",
      onClick: () => {
        onStationGoalClick(STATION_TYPE.UNSUPERVISED, GOAL_TYPE.DEFECT_DETECTION, applicationCards.paintShop.title);
      }
    },
    manualAssembly: {
      title: "Manual assembly (e.g. harnesses)",
      image: <ManualAssemblyIcon {...{}} />,
      description: "Reduce scrap rate and cycle time",
      onClick: () => {
        onStationGoalClick(
          STATION_TYPE.REGRESSION,
          GOAL_TYPE.PROCESS_OPTIMIZATION,
          applicationCards.manualAssembly.title
        );
      }
    },
    assemblyTestingElectronic: {
      title: "Assembly testing (electronic modules)",
      image: <AssemblyTestingElectronicIcon45 {...{}} />,
      description: "Reduce test time, reduce scrap",
      onClick: () => {
        onStationGoalClick(
          STATION_TYPE.SUPERVISED,
          GOAL_TYPE.PREDICTIVE_QUALITY,
          applicationCards.assemblyTestingElectronic.title
        );
      }
    },
    mechanicalPartsManufacturing: {
      title: "Mechanical parts manufacturing",
      image: <MechanicalPartsIcon {...{}} />,
      description: "Reduce scrap rate",
      onClick: () => {
        onStationGoalClick(
          STATION_TYPE.UNSUPERVISED,
          GOAL_TYPE.DEFECT_DETECTION,
          applicationCards.mechanicalPartsManufacturing.title
        );
      }
    },
    visualInspection: {
      title: "Visual inspection",
      image: <VisualInspectionIcon {...{}} />,
      description: "Reduce cycle time, improve coverage",
      onClick: () => {
        onStationGoalClick(
          STATION_TYPE.UNSUPERVISED,
          GOAL_TYPE.DEFECT_DETECTION,
          applicationCards.visualInspection.title
        );
      }
    },
    defectDetection: {
      title: "Defect detection",
      image: <NewDefectDetectionIcon {...{}} />,
      description: "Predict faulty units based on production line data",
      onClick: () => {
        onStationGoalClick(
          STATION_TYPE.UNSUPERVISED,
          GOAL_TYPE.DEFECT_DETECTION,
          applicationCards.defectDetection.title
        );
      }
    },
    predictiveQuality: {
      title: "Predictive quality",
      image: <NewPredictiveQualityIcon {...{}} />,
      description: "Predict faulty units early in production",
      onClick: () => {
        onStationGoalClick(
          STATION_TYPE.SUPERVISED,
          GOAL_TYPE.PREDICTIVE_QUALITY,
          applicationCards.predictiveQuality.title
        );
      }
    },
    processOptimization: {
      title: "Process optimization",
      image: <NewProcessOptimizationIcon {...{}} />,
      description: "E.g. optimize electricity consumption",
      onClick: () => {
        onStationGoalClick(
          STATION_TYPE.REGRESSION,
          GOAL_TYPE.PROCESS_OPTIMIZATION,
          applicationCards.processOptimization.title
        );
      }
    },
    productionEfficiency: {
      title: "Production efficiency enhancement",
      image: <ProductionEfficiencyIcon {...{}} />,
      description: "",
      onClick: () => {
        onStationGoalClick(
          STATION_TYPE.UNSUPERVISED,
          GOAL_TYPE.DEFECT_DETECTION,
          applicationCards.productionEfficiency.title
        );
      }
    },
    assemblyTesting: {
      title: "Assembly Testing",
      image: <AssemblyTestingIcon48 {...{}} />,
      description: "",
      onClick: () => {
        onStationGoalClick(
          STATION_TYPE.UNSUPERVISED,
          GOAL_TYPE.DEFECT_DETECTION,
          applicationCards.assemblyTesting.title
        );
      }
    },
    assemblyOptimization: {
      title: "Assembly optimization",
      image: <AssemblyOptimization {...{}} />,
      description: "",
      onClick: () => {
        onStationGoalClick(
          STATION_TYPE.REGRESSION,
          GOAL_TYPE.PROCESS_OPTIMIZATION,
          applicationCards.assemblyOptimization.title
        );
      }
    },
    optimizeRecipe: {
      title: "Optimize recipe continuously",
      image: <OptimizeRecipeIcon {...{}} />,
      description: "",
      onClick: () => {
        onStationGoalClick(
          STATION_TYPE.UNSUPERVISED,
          GOAL_TYPE.DEFECT_DETECTION,
          applicationCards.optimizeRecipe.title
        );
      }
    },
    virtualMetrology: {
      title: "Virtual metrology",
      image: <VirtualMetrologyIcon {...{}} />,
      description: "",
      onClick: () => {
        onStationGoalClick(
          STATION_TYPE.UNSUPERVISED,
          GOAL_TYPE.DEFECT_DETECTION,
          applicationCards.virtualMetrology.title
        );
      }
    },
    adaptiveSPC: {
      title: "Adaptive SPC",
      image: <AdaptiveSPCIcon {...{}} />,
      description: "",
      onClick: () => {
        onStationGoalClick(STATION_TYPE.REGRESSION, GOAL_TYPE.PROCESS_OPTIMIZATION, applicationCards.adaptiveSPC.title);
      }
    },
    defectsInspection: {
      title: "Defects inspection",
      image: <DefectsInspectionIcon {...{}} />,
      description: "",
      onClick: () => {
        onStationGoalClick(
          STATION_TYPE.UNSUPERVISED,
          GOAL_TYPE.DEFECT_DETECTION,
          applicationCards.defectsInspection.title
        );
      }
    },
    custom: {
      title: "Custom goal",
      image: <CustomGoalIcon />,
      description: "Define your unique outcome",
      onClick: () => {
        onStationGoalClick(STATION_TYPE.CUSTOM, GOAL_TYPE.CUSTOM_USE_CASE, applicationCards.custom.title);
      }
    }
  };
  return applicationCards;
};

export { STATION_TYPE as stationTypes } from "@vanti-analytics-org/vanti-common";
