import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  value: {
    display: "flex",
    alignItems: "center",
    background: theme.palette.vantiColors.white,
    border: "none !important",
    width: "fit-content",
    height: "fit-content",
    padding: theme.spacing(0.5, 1.25),
    borderRadius: "30px",
    color: theme.palette.vantiColors.blue4,
    "& > svg": {
      marginLeft: theme.spacing(1.25),
      cursor: "pointer"
    }
  }
}));
