import React from "react";
import classNames from "classnames";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  cardFooter: {
    padding: "20px",
    borderRadius: "0",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    backgroundColor: "transparent",
    border: "0"
  },
  topBorder: {
    borderTop: `1px solid #EAEAEA`
  }
});

export default function CardFooter(props) {
  const classes = useStyles();
  const { className, children, topBorder, ...rest } = props;
  const cardFooterClasses = classNames({
    [classes.cardFooter]: true,
    [classes.topBorder]: topBorder,
    [className]: className !== undefined
  });
  return (
    <div className={cardFooterClasses} {...rest}>
      {children}
    </div>
  );
}

CardFooter.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  topBorder: PropTypes.bool,
  children: PropTypes.node
};
