import { createSelector } from "reselect";

export const dataConnectorsSelector = state => state.dataConnectors.state;

export const allDataConnectorsSelector = createSelector(
  dataConnectorsSelector,
  state => state.dataConnectors
);

export const isLoadingConnectorsSelector = createSelector(
  dataConnectorsSelector,
  state => state.loading
);

export const uploadingErrorSelector = createSelector(
  dataConnectorsSelector,
  state => state.error
);

export const loadingSuccessSelector = createSelector(
  dataConnectorsSelector,
  state => state.success
);
