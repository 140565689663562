import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  iconButton: {
    padding: theme.spacing(1),
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.vantiColors.gray20
    }
  }
}));
