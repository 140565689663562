import React, { memo } from "react";
import { Skeleton } from "@material-ui/lab";
import { styled } from "@material-ui/core";

const DashboardItemReportSkeleton = () => {
  return (
    <DashboardItemReportSkeletonContainer>
      <Skeleton variant="rect" animation="wave" width={"100%"} height={"10%"} />
      <Skeleton variant="rect" animation="wave" width={"100%"} height={"65%"} />
      <Skeleton variant="rect" animation="wave" width={"100%"} height={"15%"} />
    </DashboardItemReportSkeletonContainer>
  );
};

const DashboardItemReportSkeletonContainer = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
});

export default memo(DashboardItemReportSkeleton);
