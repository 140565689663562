import { defaultSingleResourceOperationState } from "utils/modules-utils";
import { actionTypes } from "./actions";

const defaultState = defaultSingleResourceOperationState();

export default function signOutReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.USER_SIGN_OUT_CLEAR: {
      return { ...defaultState };
    }
    case actionTypes.USER_SIGN_OUT_REQUEST: {
      return { ...defaultState, loading: true };
    }
    case actionTypes.USER_SIGN_OUT_SUCCESS: {
      return { ...state, success: true };
    }
    case actionTypes.USER_SIGN_OUT_ERROR: {
      return { ...state, success: false, error: action.payload.error };
    }
    case actionTypes.USER_SIGN_OUT_FULFILL: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
}
