import React, { memo } from "react";
import { TextField } from "@material-ui/core";
import { FlexContainer, FlexItem, Text } from "components/ui";
import CheckboxInput from "components/ui/Inputs/CheckboxInput";
import styles from "../common/commonStyles";

const AddNewItemSection = ({
  onAddNewItemCheckboxClick,
  newItemValue,
  setNewItemValue,
  isAddingNewItem,
  text,
  color,
  onFinish,
  onTitleClick
}) => {
  const classes = styles();

  const onAddNewValue = () => {
    if (!newItemValue.trim()) {
      return;
    }
    onFinish();
  };

  return (
    <FlexContainer item dense="full" alignItems="center" className={classes.chipItem}>
      <FlexItem dense="leftTopBottom">
        <CheckboxInput
          checkboxProps={{
            classes: { root: classes.checkboxRoot },
            checkedIcon: <span style={{ width: "30px", height: "30px" }}>&mdash;</span>,
            icon: <span style={{ width: "30px", height: "30px" }}>+</span>
          }}
          formControlProps={{
            classes: { root: classes.checkboxFormControl }
          }}
          color={color}
          onChange={onAddNewItemCheckboxClick}
          checked={isAddingNewItem}
        />
      </FlexItem>

      <FlexItem data-testid="add-item-input-text" dense="full">
        {isAddingNewItem ? (
          <TextField
            id={`add-item-input`}
            variant="standard"
            InputLabelProps={{
              shrink: true,
              style: { fontFamily: "Inter", fontSize: 12 }
            }}
            FormHelperTextProps={{ style: { fontFamily: "Inter" } }}
            inputProps={{
              autoFocus: true,
              style: {
                fontFamily: "Inter",
                fontSize: 12,
                paddingLeft: 11
              },
              value: newItemValue,
              onChange: e => setNewItemValue(e.target.value),
              onKeyDown: e => {
                if (e.keyCode === 13) {
                  onAddNewValue();
                }
              },
              placeholder: text,
              maxLength: 50
            }}
            InputProps={{
              classes: { underline: classes.textFieldUnderline }
            }}
          />
        ) : (
          <Text onClick={onTitleClick}>{text}</Text>
        )}
      </FlexItem>
    </FlexContainer>
  );
};

export default memo(AddNewItemSection);
