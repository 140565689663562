import React, { memo } from "react";

const PlaygroundIcon24 = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 1024 1024"
      className="icon"
      version="1.1"
      {...rest}
    >
      <path
        d="M972.7 271.9h-2.6c-13.6-81-83.9-142.7-168.7-142.7-84.8 0-155.1 61.8-168.7 142.7H392.1c-13.6-81-83.9-142.7-168.7-142.7-84.8 0-155.1 61.8-168.7 142.7h-2.6L23.6 802.4c0 49.9 40.4 90.3 90.3 90.3 42.3 0 78.9-29.3 88.1-70.5l91.1-200.6h438.5l91.1 200.6c9.3 41.2 45.9 70.5 88.1 70.5 49.9 0 90.3-40.4 90.3-90.3l-28.4-530.5z"
        fill="#FAFCFB"
      />
      <path
        d="M910.9 905.8c-23.4 0-46.3-8-64.6-22.7-17.9-14.3-30.6-34.4-35.9-56.7l-87.1-191.8H301.5l-87.1 191.8c-5.3 22.3-18 42.4-35.9 56.7-18.3 14.6-41.2 22.7-64.6 22.7-57 0-103.3-46.4-103.3-103.3v-0.7l28.5-530.5c0.2-3.5 1.7-6.6 4.1-8.8 8.3-39.6 29.7-75.6 60.6-102.1 33.2-28.5 75.7-44.1 119.5-44.1s86.3 15.7 119.5 44.1c30 25.7 51 60.4 59.8 98.6H622c8.8-38.2 29.8-72.9 59.8-98.6 33.2-28.5 75.7-44.1 119.5-44.1s86.3 15.7 119.5 44.1c30.9 26.5 52.3 62.5 60.6 102.1 2.4 2.2 3.9 5.3 4.1 8.8l28.5 530.5v0.7c0.2 56.9-46.2 103.3-103.1 103.3zM293.1 608.6h438.5c5.1 0 9.7 3 11.8 7.6l91.1 200.6c0.4 0.8 0.7 1.7 0.8 2.5 3.8 17.1 13.5 32.5 27.1 43.4 13.7 10.9 30.8 17 48.3 17 42.5 0 77.2-34.5 77.3-77l-28.1-522.5c-1.4-1.7-2.4-3.8-2.8-6.1-6.1-36.5-25.1-69.9-53.3-94-28.5-24.4-65-37.9-102.6-37.9-37.6 0-74.1 13.5-102.6 37.9-28.2 24.1-47.1 57.5-53.3 94-1.1 6.3-6.5 10.8-12.8 10.8H392.1c-6.3 0-11.8-4.6-12.8-10.8-6.1-36.5-25.1-69.9-53.3-94-28.5-24.4-65-37.9-102.6-37.9-37.6 0-74.1 13.5-102.6 37.9-28.2 24.1-47.1 57.5-53.3 94-0.4 2.3-1.4 4.4-2.8 6.1L36.6 802.8c0.2 42.5 34.8 77 77.3 77 17.5 0 34.7-6 48.3-17 13.7-10.9 23.3-26.4 27.1-43.4 0.2-0.9 0.5-1.7 0.8-2.5l91.1-200.6c2.2-4.7 6.8-7.7 11.9-7.7z"
        fill="#0F0F0F"
      />
      <path d="M322.4 594.8m-98.1 0a98.1 98.1 0 1 0 196.2 0 98.1 98.1 0 1 0-196.2 0Z" fill="#9DC6AF" />
      <path
        d="M322.4 705.9c-61.3 0-111.1-49.8-111.1-111.1s49.8-111.1 111.1-111.1 111.1 49.8 111.1 111.1-49.8 111.1-111.1 111.1z m0-196.2c-46.9 0-85.1 38.2-85.1 85.1s38.2 85.1 85.1 85.1c46.9 0 85.1-38.2 85.1-85.1s-38.2-85.1-85.1-85.1z"
        fill="#191919"
      />
      <path d="M702.4 594.8m-98.1 0a98.1 98.1 0 1 0 196.2 0 98.1 98.1 0 1 0-196.2 0Z" fill="#9DC6AF" />
      <path
        d="M702.4 705.9c-61.3 0-111.1-49.8-111.1-111.1s49.8-111.1 111.1-111.1 111.1 49.8 111.1 111.1-49.9 111.1-111.1 111.1z m0-196.2c-46.9 0-85.1 38.2-85.1 85.1s38.2 85.1 85.1 85.1 85.1-38.2 85.1-85.1-38.2-85.1-85.1-85.1z"
        fill="#191919"
      />
      <path d="M511 497.9m-45.2 0a45.2 45.2 0 1 0 90.4 0 45.2 45.2 0 1 0-90.4 0Z" fill="#D39E33" />
      <path
        d="M511 556.1c-32.1 0-58.2-26.1-58.2-58.2s26.1-58.2 58.2-58.2 58.2 26.1 58.2 58.2-26.1 58.2-58.2 58.2z m0-90.4c-17.8 0-32.2 14.4-32.2 32.2s14.4 32.2 32.2 32.2c17.8 0 32.2-14.4 32.2-32.2s-14.4-32.2-32.2-32.2z"
        fill="#111111"
      />
      <path
        d="M262.3 308.9h-27.5v-27.5c0-14.7-12-26.7-26.7-26.7s-26.7 12-26.7 26.7v27.5h-27.5c-14.7 0-26.7 12-26.7 26.7s12 26.7 26.7 26.7h27.5v27.5c0 14.7 12 26.7 26.7 26.7s26.7-12 26.7-26.7v-27.5h27.5c14.7 0 26.7-12 26.7-26.7s-12-26.7-26.7-26.7z"
        fill="#FAFCFB"
      />
      <path
        d="M208.1 429.5c-21.9 0-39.7-17.8-39.7-39.7v-14.5h-14.5c-21.9 0-39.7-17.8-39.7-39.7 0-21.9 17.8-39.7 39.7-39.7h14.5v-14.5c0-21.9 17.8-39.7 39.7-39.7s39.7 17.8 39.7 39.7v14.5h14.5c21.9 0 39.7 17.8 39.7 39.7 0 21.9-17.8 39.7-39.7 39.7h-14.5v14.5c0 21.9-17.8 39.7-39.7 39.7z m-54.2-107.6c-7.5 0-13.7 6.1-13.7 13.7 0 7.5 6.1 13.7 13.7 13.7h27.5c7.2 0 13 5.8 13 13v27.5c0 7.5 6.1 13.7 13.7 13.7s13.7-6.1 13.7-13.7v-27.5c0-7.2 5.8-13 13-13h27.5c7.5 0 13.7-6.1 13.7-13.7 0-7.5-6.1-13.7-13.7-13.7h-27.5c-7.2 0-13-5.8-13-13v-27.5c0-7.5-6.1-13.7-13.7-13.7s-13.7 6.1-13.7 13.7v27.5c0 7.2-5.8 13-13 13h-27.5z"
        fill="#0F0F0F"
      />
      <path d="M805.3 243.4m-33.3 0a33.3 33.3 0 1 0 66.6 0 33.3 33.3 0 1 0-66.6 0Z" fill="#0F0F0F" />
      <path d="M805.3 402.2m-33.3 0a33.3 33.3 0 1 0 66.6 0 33.3 33.3 0 1 0-66.6 0Z" fill="#0F0F0F" />
      <path d="M884.7 322.8m-33.3 0a33.3 33.3 0 1 0 66.6 0 33.3 33.3 0 1 0-66.6 0Z" fill="#0F0F0F" />
      <path d="M725.8 322.8m-33.3 0a33.3 33.3 0 1 0 66.6 0 33.3 33.3 0 1 0-66.6 0Z" fill="#0F0F0F" />
    </svg>
  );
};

export default memo(PlaygroundIcon24);
