export const REPORT_HOURLY_FETCH = {
  PREDICT: "REPORT_HOURLY_FETCH_PREDICT",
  FAILURES: "REPORT_HOURLY_FETCH_FAILURES",
  FEEDBACK: "REPORT_HOURLY_FETCH_FEEDBACK"
};

export const TIME_ENUM = {
  HALF_A_MINUTE: 60000,
  ONE_HOUR: 3600000,
  SIX_HOURS: 21600000
};

export const MOCK_DATA_ID = "raw_chart_data";

export const mockDataGenerator = date => {
  const fetchType = Math.floor(Math.random() * 5);
  const pointsCount = Math.floor(Math.random() * 11);

  switch (fetchType) {
    case 0:
      return {
        type: REPORT_HOURLY_FETCH.FAILURES,
        data: {
          date: date,
          count: pointsCount
        }
      };
    case 1:
      return {
        type: REPORT_HOURLY_FETCH.FEEDBACK,
        data: {
          date: date,
          count: pointsCount
        }
      };
    case 2:
    case 3:
    case 4:
      return {
        type: REPORT_HOURLY_FETCH.PREDICT,
        data: {
          date: date,
          count: pointsCount
        }
      };
    default:
      return null;
  }
};

export const getMockData = createdAt => {
  if (window.localStorage.getItem(MOCK_DATA_ID)) return JSON.parse(window.localStorage.getItem(MOCK_DATA_ID));

  const resultData = {
    [REPORT_HOURLY_FETCH.FAILURES]: [],
    [REPORT_HOURLY_FETCH.FEEDBACK]: [],
    [REPORT_HOURLY_FETCH.PREDICT]: []
  };

  const currentTime = new Date();
  const createdTime = createdAt < currentTime - TIME_ENUM.SIX_HOURS ? currentTime - TIME_ENUM.SIX_HOURS : createdAt;

  if (currentTime > createdTime) {
    const pointsCount = Math.round((currentTime - createdTime) / TIME_ENUM.ONE_HOUR);

    let reducedTime = createdTime;
    for (let i = 0; i < pointsCount; i++) {
      const { type, data } = mockDataGenerator(reducedTime);
      resultData[type].push(data);
      reducedTime += TIME_ENUM.ONE_HOUR;
    }
  }
  window.localStorage.setItem("raw_chart_data", JSON.stringify(resultData));
  return resultData;
};
