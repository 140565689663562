import React, { memo, useEffect, useMemo } from "react";
import { styled } from "@material-ui/core";
import GalleryViewCardItemContainer from "./GalleryViewCardItemContainer";
import { DashboardItemType } from "@vanti-analytics-org/vanti-common";
import { VantiScrollBarProps } from "components/ui/VantiScrollBarProps";
import useMetricsRequest from "common/hooks/use-metrics-request";

const MetricItemsPage = () => {
  const { getAllAccountMetricsReq } = useMetricsRequest();

  useEffect(() => {
    getAllAccountMetricsReq.mutate();
  }, []);

  const metricItems = useMemo(() => getAllAccountMetricsReq?.data || [], [getAllAccountMetricsReq?.data]);

  return metricItems.length ? (
    <MetricItemsPageContainer>
      {metricItems.map(item => (
        <GalleryViewCardItemContainer key={item._id} type={DashboardItemType.METRIC} item={item} />
      ))}
    </MetricItemsPageContainer>
  ) : (
    <div>metric item empty state</div>
  );
};

const MetricItemsPageContainer = styled("div")({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexWrap: "wrap",
  gap: 16,
  overflowY: "auto",
  ...VantiScrollBarProps
});

export default memo(MetricItemsPage);
