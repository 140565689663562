import React, { memo, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { styled } from "@material-ui/core";
import { withSuspense } from "utils/lazy-loading/hocs";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import DataConnectorsPage from "./side-bar/data-connectors/DataConnectorsPage";
import DataCurationPage from "./side-bar/data-curation/DataCurationPage";
import ChatPage from "./side-bar/chat/ChatPage";
import HomePage from "./side-bar/home/HomePage";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import usePermissions from "common/hooks/use-permissions";
import { useSelector } from "react-redux";
import { fetchUserRolesSuccessSelector } from "modules/user/operations/fetchUserRoles/selectors";
import { ReactComponent as VantiIconGreenBeating } from "assets/img/icons/vanti-icon-green-beating-heart.svg";
import { rolesSelector } from "modules/user/state/selectors";
import DataPage from "./side-bar/data-page/DataPage";
import DatasetQuestionsPage from "./side-bar/data-page/dataset-quetsions-page/DatasetQuestionsPage";
import ReportsPage from "./side-bar/reports/ReportsPage";
import DataEntitiesPage from "./side-bar/data-entities/DataEntitiesPage";
import FlowPage from "./side-bar/flow-page/FlowPage";

const BlockReportPage = withSuspense(React.lazy(() => import("./dashboard/block/BlockReport")));
const ModelsComparisonPage = withSuspense(React.lazy(() => import("./dashboard/station/ModelsComparison")));
const ProfilePage = withSuspense(React.lazy(() => import("pages/dashboard/Profile")));
const PreferencesPage = withSuspense(React.lazy(() => import("pages/dashboard/Preferences")));
const StationPage = withSuspense(React.lazy(() => import("./dashboard/station/Station")));
const StationDashboardPage = withSuspense(
  React.lazy(() => import("./dashboard/station/station-dashboard/StationsDashboard"))
);
const SiteView = withSuspense(React.lazy(() => import("./side-bar/site-view/SiteView")));
const InviteMembersPage = withSuspense(React.lazy(() => import("pages/dashboard/invite-members")));
const SettingsPage = withSuspense(React.lazy(() => import("pages/dashboard/settings/SettingsPage")));
const OnboardingPage = withSuspense(React.lazy(() => import("pages/dashboard/onboarding/pages/Onboarding")));

export const Content = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%"
}));

export const LoadingContainer = styled("div")({
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const Dashboard = () => {
  const roles = useSelector(rolesSelector);
  const isFetchedRolesSuccessfully = useSelector(fetchUserRolesSuccessSelector);

  const isAllowedToViewSiteView = usePermissions([ROLES_ENUM.VIEW_SITE_VIEW_PAGE]);
  const isAllowedToViewAppsDashboard = usePermissions([ROLES_ENUM.VIEW_APPS_DASHBOARD]);
  const isAllowedToViewChatPage = usePermissions([ROLES_ENUM.VIEW_CHAT_PAGE]);
  const isAllowedToViewHomePage = usePermissions([ROLES_ENUM.VIEW_HOME_PAGE]);
  const isAllowedToViewDataEntitiesPage = usePermissions([ROLES_ENUM.VIEW_DATA_ENTITIES_PAGE]);
  const isAllowedToViewDatasetQuestionsPage = usePermissions([ROLES_ENUM.EDIT_DATASET_QUESTIONS]);
  const isAllowedToViewFlowPage = usePermissions([ROLES_ENUM.VIEW_FLOW_PAGE]);

  if (!isFetchedRolesSuccessfully || !roles) {
    return (
      <LoadingContainer>
        <VantiIconGreenBeating />
      </LoadingContainer>
    );
  }

  return (
    <Content>
      <Suspense fallback={null}>
        <Switch>
          <Route path="/dashboard/profile" render={props => <ProfilePage {...props} />} />
          <Route path="/dashboard/preferences" render={props => <PreferencesPage {...props} />} />
          <Route path="/dashboard/invite" render={props => <InviteMembersPage {...props} />} />
          <Route path="/dashboard/settings" render={props => <SettingsPage {...props} />} />
          <Route path="/dashboard/onboarding" render={props => <OnboardingPage {...props} />} />

          {isAllowedToViewFlowPage && (
            <Route path="/dashboard/flows/:flowId" render={props => <FlowPage {...props} />} />
          )}

          <Route path="/dashboard/stations/:stationId/review" render={props => <ModelsComparisonPage {...props} />} />
          <Route exact path="/dashboard/stations/:stationId" render={props => <StationPage {...props} />} />
          <Route
            exact
            path="/dashboard/stations/:stationId/blocks/:blockId/results"
            render={props => <BlockReportPage {...props} />}
          />

          {isAllowedToViewSiteView && (
            <Route exact path="/dashboard/site-view" render={props => <SiteView {...props} />} />
          )}

          <Route path="/dashboard/data-curation" render={props => <DataCurationPage {...props} />} />
          <Route path="/dashboard/data-connectors" render={props => <DataConnectorsPage {...props} />} />
          <Route exact path="/dashboard/reports" render={props => <ReportsPage {...props} />} />

          {isAllowedToViewDatasetQuestionsPage && (
            <Route
              exact
              path="/dashboard/data/questions/:datasetId"
              render={props => <DatasetQuestionsPage {...props} />}
            />
          )}
          <Route path="/dashboard/data" render={props => <DataPage {...props} />} />

          {isAllowedToViewHomePage && <Route exact path="/dashboard/home" render={props => <HomePage {...props} />} />}
          {isAllowedToViewDataEntitiesPage && (
            <Route exact path="/dashboard/entities" render={props => <DataEntitiesPage {...props} />} />
          )}
          {isAllowedToViewDataEntitiesPage && (
            <Route exact path="/dashboard/entities/:entityName" render={props => <DataEntitiesPage {...props} />} />
          )}
          {isAllowedToViewChatPage && <Route exact path="/dashboard/chat" render={props => <ChatPage {...props} />} />}
          {isAllowedToViewChatPage && (
            <Route exact path="/dashboard/chat/:chatId" render={props => <ChatPage {...props} />} />
          )}

          {isAllowedToViewAppsDashboard ? (
            <>
              <Route exact path="/dashboard/stations" render={props => <StationDashboardPage {...props} />} />
              <Redirect to="/dashboard/stations" />
            </>
          ) : isAllowedToViewHomePage ? (
            <Redirect to="/dashboard/home" />
          ) : (
            <Redirect to="/dashboard/chat" />
          )}
        </Switch>
      </Suspense>
    </Content>
  );
};

export default memo(Dashboard);
