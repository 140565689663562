import { call, put, select, takeLatest } from "redux-saga/effects";
import { accountUsersSelector } from "../../state/selectors";
import { actionTypes } from "./actions";
import * as authService from "services/auth/auth";
import { uidSelector } from "modules/auth/state/selectors";
import { setUsersAction } from "../../state/actions";
import { mapUsersResponse } from "../utils";

export function* inviteUserWorker(action) {
  try {
    const result = yield call(authService.inviteUsers, action.payload);
    yield put({ type: actionTypes.ACCOUNT_INVITE_USER_SUCCESS });
    const currentUserId = yield select(uidSelector);
    const currentUsers = yield select(accountUsersSelector);
    const newUsers = mapUsersResponse(currentUserId, [...currentUsers, ...result.data]);
    yield put(setUsersAction(newUsers));
  } catch (error) {
    yield put({ type: actionTypes.ACCOUNT_INVITE_USER_ERROR, payload: error });
  } finally {
    yield put({ type: actionTypes.ACCOUNT_INVITE_USER_FULFILL });
  }
}

export default function* inviteUserWatcher() {
  yield takeLatest(actionTypes.ACCOUNT_INVITE_USER_REQUEST, inviteUserWorker);
}
