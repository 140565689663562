import { TreeItem } from "@material-ui/lab";
import { memo } from "react";
import { vantiColors } from "assets/jss/palette";
import { styled } from "@material-ui/core";

const TreeItemStyled = styled(TreeItem)(({ shouldHighlight, displayIcon, icon, visualNestingLevel }) => ({
  "& .MuiTreeItem-content": {
    backgroundColor: shouldHighlight ? vantiColors.lightBlue10 : "transparent",
    "&:hover": {
      backgroundColor: vantiColors.lightBlue10,
      borderRadius: "4px"
    },
    borderRadius: "4px",
    border: 0,
    "&:hover .endIcon": {
      opacity: "1",
      pointerEvents: "auto"
    }
  },
  "& .Mui-selected": {
    color: vantiColors.darkBlue7
  },
  "& .MuiTreeItem-iconContainer": {
    display: displayIcon === "false" ? "none" : "inherit",
    justifyContent: icon ? "flex-end" : "auto",
    paddingLeft: (visualNestingLevel || 0) * 16 + "px",
    margin: 0
  },
  "& .MuiTreeItem-label": {
    minHeight: "34px",
    padding: 0,
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    backgroundColor: "transparent !important",
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: vantiColors.darkBlue7
  },
  "& .MuiTreeItem-group": {
    margin: 0, //prevent auto indentation inside tree
    "& li > ul .MuiTreeItem-iconContainer": {
      paddingLeft: "12px"
    }
  }
}));

export default memo(TreeItemStyled);
