import React, { useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";

import { listObjects } from "services/api/fileExplorer";
import { FILES_SORT_BY } from "../common-file-explorer/CommonFileExplorer";

const sortCallback = key => (a, b) => {
  if (a && b) {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
  } else {
    if (a && !b) {
      return 1;
    }

    if (!a && b) {
      return -1;
    }
  }

  return 0;
};

const useFiles = (connectorId, prefix, root, sortBy) => {
  const [files, setFiles] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setFiles([null, null, null, null, null]);
    setError(null);

    const fetchObjects = async () => {
      try {
        const res = await listObjects(connectorId, prefix, root);

        if (res.permissionError) {
          setError(
            <div>
              Missing S3 permission. <br /> Please provide access rights for{" "}
              <b>{res.permissionError.restrictedOperation}</b> operation and try again.
            </div>
          );
          setFiles([null, null, null, null, null]);
        } else {
          setFiles(res);
        }
      } catch (err) {
        setFiles([]);
        setError(err.message);
      }
    };

    fetchObjects();
  }, [setFiles, prefix, connectorId, root]);

  const sortedFiles = useMemo(() => {
    if (!isEmpty(files)) {
      const filesToSortMutable = [...files];

      switch (sortBy.key) {
        case FILES_SORT_BY.KEYS.NAME:
          if (sortBy.order === FILES_SORT_BY.ORDER.ASC) {
            return filesToSortMutable.sort(sortCallback("name"));
          } else {
            return filesToSortMutable.sort((a, b) => sortCallback("name")(b, a));
          }
        case FILES_SORT_BY.KEYS.DATE:
          if (sortBy.order === FILES_SORT_BY.ORDER.ASC) {
            return filesToSortMutable.sort(sortCallback("modDate"));
          } else {
            return filesToSortMutable.sort((a, b) => sortCallback("modDate")(b, a));
          }
        case FILES_SORT_BY.KEYS.SIZE:
          if (sortBy.order === FILES_SORT_BY.ORDER.ASC) {
            return filesToSortMutable.sort(sortCallback("size"));
          } else {
            return filesToSortMutable.sort((a, b) => sortCallback("size")(b, a));
          }
        default:
          return [];
      }
    }

    return [];
  }, [sortBy, files]);

  return [sortedFiles, error];
};

export default useFiles;
