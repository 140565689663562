import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { useAsyncFn } from "react-use";
import { styled } from "@material-ui/core";

import { FlexItem, Text } from "components/ui";
import ConnectorName from "../components/ConnectorName";
import CreateDataSourcesWrapper from "../components/data-sources/data-source-wrapper/CreateDataSourceWrapper";
import DataSources from "../components/DataSources";
import LoadingOverlay from "components/ui/loading-overlay";
import Header from "../components/Header";

import { DataConnectorsContext } from "../../../DataConnectorsPage";

import { dataConnectorsAddConnectorToState } from "modules/data-connectors/actions";
import { createNewConnector } from "services/api/dataConnectors";

import { CONNECTOR_PAGE_MODES, DEFAULT_CARD_WIDTH, DEFAULT_SPACING } from "../../../constants";

import LeavePageDialog from "../components/leave-page-dialog/LeavePageDialog";
import useRequestWatcher from "../request-watcher";
import { vantiColors } from "assets/jss/palette";
import { allDataConnectorsSelector } from "modules/data-connectors/selectors";
import { useSelector } from "react-redux";

const ConnecorNameContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  backgroundColor: "white",
  padding: "16px",
  marginTop: "16px",
  borderRadius: "4px"
});

const CreateSingleDataConnector = () => {
  const history = useHistory();

  const { dataSources } = useContext(DataConnectorsContext);

  const [connectorName, setConnectorName] = useState("");
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [dataSourceFieldsValues, setDataSourceFieldsValues] = useState({});
  const [fieldsChanged, setFieldsChanged] = useState(true);
  const [connectorTablesCatalogue, setConnectorTablesCatalogue] = useState([]);
  const accountDataConnectors = useSelector(allDataConnectorsSelector);

  const sourceTypeKey = useMemo(() => {
    for (const dataSourceKey of Object.keys(dataSources)) {
      if (dataSources[dataSourceKey].title === selectedDataSource?.title) {
        return dataSourceKey;
      }
    }
  }, [selectedDataSource, dataSources]);

  const [createConnectorResponse, onCreateConnector] = useAsyncFn(
    async reset => {
      if (reset) {
        return null;
      }

      const connectorData = {
        title: connectorName,
        sourceType: sourceTypeKey,
        credentials: {
          ...dataSourceFieldsValues
        },
        tableNames: connectorTablesCatalogue
      };

      return await createNewConnector(connectorData);
    },
    [selectedDataSource, connectorName, sourceTypeKey, dataSourceFieldsValues, connectorTablesCatalogue]
  );

  useRequestWatcher(createConnectorResponse, dataConnectorsAddConnectorToState);

  useEffect(async () => {
    setFieldsChanged(true);
    await onCreateConnector(true);
  }, [connectorName, selectedDataSource, dataSourceFieldsValues]);

  useEffect(() => {
    setConnectorName(`Connector-${accountDataConnectors.length + 1}`);
  }, [accountDataConnectors]);

  const dataSourcesArray = useMemo(() => {
    if (dataSources) {
      return Object.values(dataSources);
    }
  }, [dataSources]);

  const shouldDisplayDataSourcesSection = useMemo(() => !isEmpty(connectorName?.trim()), [connectorName]);

  const onClick = async () => {
    return await onCreateConnector();
  };

  return (
    <>
      <FlexItem container dense="full" style={{ width: "100%", marginBottom: 128 }}>
        <FlexItem data-testid="header-section" container dense="full" direction="column">
          <Header onCloseClicked={history.goBack.bind(null)} title="< Back" />
          <Text size={22}>{connectorName || "New connector"}</Text>
          <Text size={16} weight={400} color={vantiColors.gray41}>
            Upload a sample of your data and convert it to the desired format to create a customized curation, <br />
            then use the same format next time without having to re-curate.
          </Text>
        </FlexItem>

        <ConnecorNameContainer data-testid="connector-name-section">
          <FlexItem style={{ width: DEFAULT_CARD_WIDTH + DEFAULT_SPACING }} dense="full">
            <ConnectorName
              connectorName={connectorName}
              pageMode={CONNECTOR_PAGE_MODES.NEW_CONNECTOR}
              onConnectorNameChange={setConnectorName}
            />
          </FlexItem>
        </ConnecorNameContainer>

        <FlexItem
          container
          dense="horizontal"
          style={{ transition: "opacity 0.5s", opacity: shouldDisplayDataSourcesSection ? 1 : 0 }}
        >
          {shouldDisplayDataSourcesSection && (
            <DataSources
              dataSources={dataSourcesArray}
              selectedDataSource={selectedDataSource}
              onSelectDataSource={setSelectedDataSource}
            />
          )}
        </FlexItem>

        {shouldDisplayDataSourcesSection && selectedDataSource && (
          <CreateDataSourcesWrapper
            onClick={onClick}
            actionResponse={createConnectorResponse}
            setDataSourceFieldsValues={setDataSourceFieldsValues}
            setFieldsChanged={setFieldsChanged}
            sourceTypeKey={sourceTypeKey}
            dataSourceFieldsValues={dataSourceFieldsValues}
            connectorName={connectorName}
            selectedDataSource={selectedDataSource}
            fieldsChanged={fieldsChanged}
            setConnectorTablesCatalogue={setConnectorTablesCatalogue}
          />
        )}

        <LeavePageDialog fieldsChanged={fieldsChanged} />
      </FlexItem>

      {createConnectorResponse.loading && <LoadingOverlay fullscreen />}
    </>
  );
};

export default memo(CreateSingleDataConnector);
