import { makeStyles } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";

export default makeStyles(theme => ({
  topNavbar: {
    backgroundColor: "transparent",
    display: "flex"
  },
  getCodeButtonWrapper: {
    marginRight: `${theme.spacing(1.25)}px`
  },
  title: {
    fontSize: `${theme.spacing(3)}px`,
    fontWeight: 700,
    color: theme.palette.vantiColors.white
  },
  titleName: {
    fontSize: `${theme.spacing(3)}px`,
    fontWeight: 600,
    color: theme.palette.vantiColors.white
  },
  link: {
    position: "relative",
    width: "auto",
    overflow: "hidden",
    gap: theme.spacing(1),
    fontSize: `${theme.spacing(3)}px`,
    color: theme.palette.vantiColors.white,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",

    "&:hover, &:focus": {
      color: theme.palette.vantiColors.white
    }
  },
  clickableLinkLight: {
    display: "flex",
    lineHeight: `${theme.spacing(2.75)}px !important`,
    "&:hover": {
      borderBottom: `${theme.spacing(0.25)}px solid ${vantiColors.darkBlue4}`
    }
  },
  clickableLink: {
    display: "flex",
    lineHeight: `${theme.spacing(2.75)}px !important`,
    "&:hover": {
      borderBottom: `${theme.spacing(0.25)}px solid ${vantiColors.darkBlue1}`
    }
  },
  noneClickableLink: {
    display: "flex",
    pointerEvents: "none"
  },
  linkText: {
    lineHeight: "0 !important",
    fontSize: `${theme.spacing(3)}px`,
    fontWeight: 300,
    color: theme.palette.vantiColors.white
  },
  linkTitle: {
    fontSize: `${theme.spacing(3)}px`,
    fontWeight: 500,
    color: theme.palette.vantiColors.white
  }
}));
