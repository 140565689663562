import { navigationActionTypes } from "./actionTypes";

const defaultState = {
  breadcrumbs: {
    type: null
  },
  previousLocation: null
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case navigationActionTypes.SET_BREADCRUMBS_TYPE: {
      return {
        ...state,
        breadcrumbs: {
          type: action.payload
        }
      };
    }
    case navigationActionTypes.SET_PREVIOUS_LOCATION: {
      return {
        ...state,
        previousLocation: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
