import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@material-ui/core";
import CheckboxFilled24 from "assets/icons/checkbox/CheckboxFilled24";
import CheckboxUnfilled24 from "assets/icons/checkbox/CheckboxUnfilled24";
import SearchIcon from "assets/icons/search-icon/SearchIcon";
import { vantiColors } from "assets/jss/palette";
import { Text } from "components/ui";
import GeneralVantiButton, { SIZES } from "components/ui/Buttons/GeneralVantiButton";
import { useQuery } from "react-query";
import { startDatasetJob } from "services/api/dataConnectors";
import { getFormattedDateForDisplay } from "utils/utils";
import { ADD_BUTTON_TEXT } from "../constants";
import { AddConnectorDatasetModalButtonStyled } from "./common-styled-components";

const DATABASE_CONNECTOR_FILE_SELECT_TABLE_HEADERS = {
  FILE: "File",
  UPDATE_DATE: "Update date"
};

const DATABASE_TABLE_SUFFIX = ".CSV";

const CheckboxWrapperStyled = styled("div")({
  cursor: "pointer",
  width: "fit-content",
  display: "contents"
});

const DatabaseFileBrowserWrapperStyled = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 16,
  paddingTop: 16,
  boxSizing: "border-box",
  flex: 1
});

const SingleTableNameWrapperStyled = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: 8
});

const SearchInputStyled = styled("input")(({ theme }) => ({
  width: 230,
  boxSizing: "border-box",
  backgroundColor: theme.palette.vantiColors.gray18,
  border: "none",
  borderRadius: 100,
  padding: "8px 16px 8px 48px"
}));

const TableHeaderRowStyled = styled("tr")(({ theme }) => ({
  textAlign: "left",
  "& > th": {
    padding: "8.5px 0px 8.5px 12px"
  },
  backgroundColor: theme.palette.vantiColors.gray18,
  position: "sticky",
  top: 0
}));

const TableBodyRowStyled = styled("tr")(({ theme }) => ({
  "& > td": {
    padding: "8.5px 0px 8.5px 12px",
    borderBottom: `2px solid ${theme.palette.vantiColors.gray18}`
  },
  backgroundColor: theme.palette.vantiColors.white
}));

const TableWrapperStyled = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: 16,
  maxHeight: 241,
  overflow: "auto",
  border: `2px solid ${theme.palette.vantiColors.gray18}`
}));

const SearchInputFieldWrapperStyled = styled("div")({
  position: "relative"
});

const SearchIconWrapperStyled = styled("div")({
  display: "flex",
  position: "absolute",
  left: 16,
  top: "50%",
  transform: "translateY(-50%)"
});

const DatabaseFilesBrowser = ({ connectorId, tableNames = [], createdAt, onFinish }) => {
  const [tableNamesFiltered, setTableNamesFiltered] = useState(tableNames);
  const [fileToUpload, setFileToUpload] = useState(null);

  const addConnectorFileReq = useQuery(
    [connectorId, fileToUpload],
    async () => startDatasetJob(connectorId, null, fileToUpload),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false
    }
  );

  const onAddConnectorFile = useCallback(async () => {
    addConnectorFileReq.refetch();
  }, [addConnectorFileReq]);

  const onClickCheckbox = useCallback(tableName => {
    setFileToUpload(prevState => {
      if (prevState === tableName) return null;

      return tableName;
    });
  }, []);

  const onChangeSearchValue = useCallback(
    event => {
      const inputValue = event.target.value.trim();

      if (inputValue === "") {
        setTableNamesFiltered(tableNames);
        return;
      }

      const tableNamesFilteredNew = tableNamesFiltered.filter(name => name.includes(inputValue));
      setTableNamesFiltered(tableNamesFilteredNew);
    },
    [tableNamesFiltered]
  );

  const isAddButtonDisabled = useMemo(() => !fileToUpload || addConnectorFileReq.isFetching, [
    fileToUpload,
    addConnectorFileReq.isFetching
  ]);

  useEffect(() => {
    if (addConnectorFileReq.data) {
      const { data } = addConnectorFileReq;
      onFinish(data);
    }
  }, [addConnectorFileReq.data, onFinish]);

  return (
    <DatabaseFileBrowserWrapperStyled>
      <SearchInputFieldWrapperStyled>
        <SearchInputStyled type="text" placeholder="Search" onChange={onChangeSearchValue} />

        <SearchIconWrapperStyled>
          <SearchIcon />
        </SearchIconWrapperStyled>
      </SearchInputFieldWrapperStyled>

      <TableWrapperStyled>
        <table cellSpacing={0}>
          <TableHeaderRowStyled>
            {Object.values(DATABASE_CONNECTOR_FILE_SELECT_TABLE_HEADERS).map(header => (
              <th key={header}>
                <Text weight={600}>{header}</Text>
              </th>
            ))}
          </TableHeaderRowStyled>

          {tableNamesFiltered.map(tableName => (
            <TableBodyRowStyled key={tableName}>
              <td>
                <Text color={vantiColors.gray39}>
                  <SingleTableNameWrapperStyled>
                    <CheckboxWrapperStyled
                      onClick={() => {
                        onClickCheckbox(tableName);
                      }}
                    >
                      {fileToUpload === tableName ? <CheckboxFilled24 /> : <CheckboxUnfilled24 />}
                    </CheckboxWrapperStyled>

                    {tableName + DATABASE_TABLE_SUFFIX}
                  </SingleTableNameWrapperStyled>
                </Text>
              </td>

              <td>
                <Text color={vantiColors.gray39}>{getFormattedDateForDisplay(createdAt)}</Text>
              </td>
            </TableBodyRowStyled>
          ))}
        </table>
      </TableWrapperStyled>

      <AddConnectorDatasetModalButtonStyled>
        <GeneralVantiButton
          buttonInfo={{ disabled: isAddButtonDisabled, onClick: onAddConnectorFile, label: ADD_BUTTON_TEXT }}
          size={SIZES.BIG}
        />
      </AddConnectorDatasetModalButtonStyled>
    </DatabaseFileBrowserWrapperStyled>
  );
};

export default memo(DatabaseFilesBrowser);
