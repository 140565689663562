import React, { memo } from "react";

const CustomGoalIcon = ({ className, ...rest }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 144 134"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}
  >
    <path
      d="M6.59061 61.8201C7.87477 61.8213 9.10689 61.3128 10.0163 60.4059C10.9256 59.4991 11.4377 58.2684 11.4401 56.9842L11.4401 56.9823C11.4377 55.6975 10.9259 54.466 10.017 53.5579C9.10809 52.6499 7.87634 52.1393 6.59155 52.138L6.58827 52.1381C5.30713 52.1453 4.08069 52.6584 3.17607 53.5656C2.27143 54.4728 1.76196 55.7007 1.75838 56.9819L1.75837 56.9828C1.75778 57.6178 1.8823 58.2466 2.1249 58.8335C2.36749 59.4204 2.72337 59.9538 3.17222 60.4031C3.62108 60.8524 4.15406 61.2087 4.74064 61.4518C5.32712 61.6949 5.95573 61.8201 6.59061 61.8201ZM6.59061 61.8201L6.59108 61.3201M6.59061 61.8201C6.59076 61.8201 6.59092 61.8201 6.59108 61.8201V61.3201M6.59108 61.3201C6.02175 61.3201 5.45803 61.2079 4.93209 60.9899C4.40615 60.7719 3.92833 60.4525 3.52594 60.0497C3.12356 59.6469 2.80448 59.1687 2.58698 58.6425C2.36948 58.1163 2.25784 57.5526 2.25837 56.9833C2.26159 55.834 2.71862 54.7325 3.53013 53.9186C4.34164 53.1048 5.44183 52.6445 6.59108 52.6381C7.74349 52.6391 8.84833 53.0971 9.66359 53.9116C10.4789 54.7262 10.938 55.8308 10.9401 56.9833C10.938 58.135 10.4787 59.2387 9.66319 60.0519C8.8477 60.8651 7.74274 61.3212 6.59108 61.3201ZM2.28494 52.6741C3.42679 51.5308 4.97544 50.887 6.59124 50.8838C8.20854 50.886 9.75895 51.5292 10.9029 52.6725C12.0469 53.8157 12.6911 55.366 12.6943 56.9833C12.6911 58.5997 12.0468 60.1489 10.9027 61.2909C9.75881 62.4327 8.20866 63.074 6.59237 63.0744C4.97914 63.0661 3.43425 62.4216 2.2935 61.2808C1.15278 60.1401 0.508302 58.5954 0.500001 56.9822C0.501363 55.3665 1.14324 53.8173 2.28494 52.6741Z"
      fill="#31DE86"
      stroke="#31DE86"
    />
    <path
      d="M24.032 11.4363C25.3162 11.4375 26.5483 10.929 27.4577 10.0222C28.367 9.11535 28.8791 7.88464 28.8815 6.60042L28.8815 6.59854C28.8791 5.31372 28.3673 4.08221 27.4584 3.17415C26.5495 2.26607 25.3177 1.75547 24.033 1.75425L24.0297 1.75427C22.7485 1.76147 21.5221 2.27458 20.6175 3.18179C19.7128 4.08903 19.2034 5.31694 19.1998 6.59808L19.1998 6.59901C19.1992 7.23406 19.3237 7.86285 19.5663 8.44973C19.8089 9.03658 20.1648 9.57002 20.6136 10.0193C21.0625 10.4686 21.5955 10.8249 22.182 11.068C22.7685 11.3111 23.3971 11.4363 24.032 11.4363ZM24.032 11.4363L24.0325 10.9363M24.032 11.4363C24.0322 11.4363 24.0323 11.4363 24.0325 11.4363V10.9363M24.0325 10.9363C23.4632 10.9363 22.8994 10.8241 22.3735 10.6061C21.8476 10.3881 21.3697 10.0687 20.9673 9.66592C20.565 9.26314 20.2459 8.78489 20.0284 8.25872C19.8109 7.73256 19.6992 7.16883 19.6998 6.59948C19.703 5.45018 20.16 4.34867 20.9715 3.53484C21.783 2.72101 22.8832 2.26072 24.0325 2.25426C25.1849 2.25534 26.2897 2.71333 27.105 3.52786C27.9203 4.34238 28.3794 5.44703 28.3815 6.59948C28.3794 7.75118 27.9201 8.85489 27.1046 9.66811C26.2891 10.4813 25.1841 10.9374 24.0325 10.9363ZM19.7263 2.29034C20.8682 1.14704 22.4169 0.503202 24.0326 0.500001C25.6499 0.5022 27.2004 1.14536 28.3443 2.28868C29.4883 3.43195 30.1325 4.98217 30.1357 6.59948C30.1325 8.21595 29.4882 9.76512 28.3442 10.9071C27.2002 12.0489 25.6501 12.6902 24.0338 12.6906C22.4205 12.6823 20.8757 12.0378 19.7349 10.897C18.5942 9.75628 17.9497 8.21161 17.9414 6.59841C17.9428 4.98276 18.5846 3.4335 19.7263 2.29034Z"
      fill="#31DE86"
      stroke="#31DE86"
    />
    <path
      d="M130.613 28.8777C131.897 28.8789 133.129 28.3704 134.039 27.4636C134.948 26.5568 135.46 25.326 135.463 24.0418L135.463 24.0399C135.46 22.7551 134.948 21.5236 134.039 20.6156C133.131 19.7075 131.899 19.1969 130.614 19.1957L130.611 19.1957C129.33 19.2029 128.103 19.716 127.199 20.6232C126.294 21.5304 125.784 22.7583 125.781 24.0395L125.781 24.0404C125.78 24.6755 125.905 25.3043 126.147 25.8911C126.39 26.478 126.746 27.0114 127.195 27.4607C127.644 27.91 128.177 28.2663 128.763 28.5094C129.35 28.7525 129.978 28.8777 130.613 28.8777ZM130.613 28.8777L130.614 28.3777M130.613 28.8777C130.613 28.8777 130.613 28.8777 130.614 28.8777V28.3777M130.614 28.3777C130.044 28.3777 129.48 28.2655 128.955 28.0475C128.429 27.8295 127.951 27.5101 127.548 27.1073C127.146 26.7045 126.827 26.2263 126.609 25.7001C126.392 25.174 126.28 24.6102 126.281 24.0409C126.284 22.8916 126.741 21.7901 127.553 20.9762C128.364 20.1624 129.464 19.7021 130.614 19.6957C131.766 19.6967 132.871 20.1547 133.686 20.9693C134.501 21.7838 134.96 22.8884 134.963 24.0409C134.96 25.1926 134.501 26.2963 133.686 27.1095C132.87 27.9227 131.765 28.3788 130.614 28.3777ZM126.307 19.7317C127.449 18.5884 128.998 17.9446 130.614 17.9414C132.231 17.9436 133.781 18.5868 134.925 19.7301C136.069 20.8734 136.714 22.4236 136.717 24.0409C136.714 25.6574 136.069 27.2065 134.925 28.3485C133.781 29.4903 132.231 30.1317 130.615 30.132C129.002 30.1237 127.457 29.4792 126.316 28.3384C125.175 27.1977 124.531 25.653 124.522 24.0398C124.524 22.4242 125.166 20.8749 126.307 19.7317Z"
      fill="#31DE86"
      stroke="#31DE86"
    />
    <path
      d="M118.987 131.584C120.271 131.585 121.503 131.076 122.413 130.17C123.322 129.263 123.834 128.032 123.837 126.748L123.837 126.746C123.834 125.461 123.322 124.23 122.413 123.322C121.505 122.414 120.273 121.903 118.988 121.902L118.985 121.902C117.704 121.909 116.477 122.422 115.573 123.329C114.668 124.236 114.158 125.464 114.155 126.746L114.155 126.746C114.154 127.382 114.279 128.01 114.521 128.597C114.764 129.184 115.12 129.717 115.569 130.167C116.018 130.616 116.551 130.972 117.137 131.215C117.724 131.459 118.352 131.584 118.987 131.584ZM118.987 131.584L118.988 131.084M118.987 131.584C118.987 131.584 118.987 131.584 118.988 131.584V131.084M118.988 131.084C118.418 131.084 117.855 130.972 117.329 130.754C116.803 130.536 116.325 130.216 115.922 129.813C115.52 129.411 115.201 128.932 114.983 128.406C114.766 127.88 114.654 127.316 114.655 126.747C114.658 125.598 115.115 124.496 115.927 123.682C116.738 122.868 117.838 122.408 118.988 122.402C120.14 122.403 121.245 122.861 122.06 123.675C122.875 124.49 123.334 125.594 123.337 126.747C123.334 127.899 122.875 129.002 122.06 129.816C121.244 130.629 120.139 131.085 118.988 131.084ZM114.681 122.438C115.823 121.294 117.372 120.651 118.988 120.647C120.605 120.65 122.155 121.293 123.299 122.436C124.443 123.579 125.088 125.13 125.091 126.747C125.088 128.363 124.443 129.913 123.299 131.055C122.155 132.196 120.605 132.838 118.989 132.838C117.376 132.83 115.831 132.185 114.69 131.044C113.549 129.904 112.905 128.359 112.896 126.746C112.898 125.13 113.54 123.581 114.681 122.438Z"
      fill="#31DE86"
      stroke="#31DE86"
    />
    <path d="M65.7217 23.3498V12.5723" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M54.7891 23.4582V12.6807" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M44.167 23.3498V12.5723" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M98.0537 23.3498V12.5723" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M87.1211 23.4582V12.6807" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M76.499 23.3498V12.5723" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M65.7217 112.265V101.487" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M54.7891 112.372V101.595" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M29.903 112.666L36.1279 106.559" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M44.167 112.265V101.487" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M36.335 106.559L36.335 101.715" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path
      d="M106.705 23.6865H32.5611C31.6304 23.6865 30.876 24.441 30.876 25.3716V99.5159C30.876 100.447 31.6304 101.201 32.5611 101.201H106.705C107.636 101.201 108.39 100.447 108.39 99.5159V25.3716C108.39 24.441 107.636 23.6865 106.705 23.6865Z"
      stroke="#31DE86"
      strokeWidth="2.5"
    />
    <path d="M98.0537 112.265V101.487" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M87.1211 112.372V101.595" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M76.499 112.265V101.487" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M108.886 57.084L119.663 57.084" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M108.777 46.1514L119.555 46.1514" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M108.886 35.5283L119.663 35.5283" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M34.8809 23.2542L34.8809 16.4717" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M120.632 34.8575L126.445 29.0439" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M34.881 16.4718L29.0674 10.6582" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M108.886 89.416L119.663 89.416" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M108.52 78.46H130.806" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M108.886 67.8613L119.663 67.8613" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M19.3787 67.8613L30.1562 67.8613" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M101.738 126.93H112.396" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M87.2033 112.372L101.737 126.906" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M12.5962 57.167L31.0059 57.167" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M19.8631 46.1514L30.6406 46.1514" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M19.9705 35.5283L30.748 35.5283" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M19.9705 89.416L30.748 89.416" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M19.8631 78.4834L30.6406 78.4834" stroke="#31DE86" strokeWidth="2.5" strokeLinecap="round" />
    <path
      d="M72.4923 31.1475C71.6232 31.0496 70.1419 30.9946 69.2544 30.9946C61.7562 30.9877 54.5479 33.8895 49.1474 39.089C48.5843 39.6335 48.0395 40.172 47.5254 40.7838C47.3418 40.9857 47.1643 41.1876 46.9929 41.3956C46.393 42.1176 45.8238 42.866 45.2851 43.641C44.2143 45.2121 43.2961 46.8818 42.543 48.6274C42.237 49.3494 41.9554 50.0897 41.7044 50.8361L41.5086 51.4479C40.9412 53.2729 40.5598 55.1506 40.3701 57.0523L40.3089 57.6641C40.211 58.4534 40.211 59.2487 40.211 60.0564C40.2062 63.7026 40.8916 67.3167 42.2309 70.7083L42.4757 71.3201C44.0097 74.9582 46.267 78.247 49.1107 80.987C49.331 81.195 49.5453 81.403 49.7717 81.5988C52.5197 84.0942 55.7218 86.0384 59.204 87.3255C59.4059 87.3989 59.6141 87.4724 59.816 87.5397C61.0167 87.9518 62.2436 88.2829 63.4886 88.5308C64.0027 88.6409 64.5169 88.7266 65.031 88.8061C66.4376 89.0124 67.8572 89.1167 69.2789 89.1182L69.891 89.1182C73.1154 89.0613 76.3076 88.4657 79.3354 87.3561C79.5435 87.2827 79.7454 87.2032 79.9473 87.1237L79.9475 87.1236C80.7432 86.8116 81.5206 86.4628 82.2795 86.0835C84.8859 84.7802 87.2831 83.0954 89.392 81.0849L90.0041 80.473C91.1913 79.2713 92.2726 77.9694 93.2359 76.5818L93.646 75.97C94.563 74.5684 95.3576 73.0905 96.0209 71.5526L96.2718 70.9408C96.8529 69.5107 97.3156 68.0353 97.6552 66.5295C97.7041 66.3276 97.7531 66.1257 97.7898 65.9177C98.0908 64.462 98.277 62.985 98.3468 61.5003L98.3468 60.8884L98.3468 60.0625C98.3477 58.864 98.2741 57.6666 98.1264 56.4772C98.102 56.2691 98.0775 56.0672 98.0407 55.8653C97.8277 54.371 97.4961 52.8959 97.0492 51.4541L96.8533 50.8422C95.697 47.3924 93.9031 44.1901 91.5649 41.4017C91.3935 41.1937 91.216 40.9918 91.0324 40.7899C90.6896 40.3984 90.3346 40.019 89.9673 39.6519C89.7673 39.4399 89.5673 39.2459 89.3614 39.0461L89.3553 39.0401C86.6273 36.4343 83.42 34.3815 79.9108 32.9952C79.7088 32.9218 79.5068 32.8423 79.2987 32.7689C78.7193 32.5405 78.1256 32.3365 77.5175 32.157C75.8763 31.67 74.1944 31.3321 72.4923 31.1475Z"
      stroke="#31DE86"
      strokeWidth="2.5"
    />
    <path d="M68.7939 47.4776L68.7939 36.8193" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M57.167 60.0501L57.167 42.6094" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M80.9063 77.4906L80.9062 60.0498" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M86.7197 83.3046L86.7197 36.7959" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M73.9912 83.5038L73.9912 36.9951" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M63.9492 60.0502L63.9492 36.7959" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M53.291 77.4906L53.291 60.0498" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M46.9935 60.0498L92.5332 60.0498" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M46.9935 48.4229L92.5332 48.4229" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M80.906 71.6777L92.5332 71.6777" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M46.5082 77.5143L86.2344 77.4912" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M45.5401 65.8885L92.0488 65.8633" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M46.5086 54.2363H62.9805" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M46.0241 42.6094H63.4648" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M63.9494 54.2607H73.6387" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M92.5332 77.4909L92.5332 42.6094" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M46.0234 76.522L46.0234 42.6094" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M46.0245 60.0498H92.5332" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M46.5082 48.4229H86.2344" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M46.5087 71.6777H52.3223" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path d="M52.8071 36.7959L86.7197 36.7959" stroke="#31DE86" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M97.3891 67.9423L93.7598 74.3945V46.3834L96.0418 47.8323L97.768 57.839L97.3891 67.9423Z"
      fill="#31DE86"
      stroke="#31DE86"
    />
    <path d="M55.195 36.3194L57.4433 32.9469L77.8369 31.9983L83.3364 36.3194H55.195Z" fill="#31DE86" stroke="#31DE86" />
    <path
      d="M85.1525 82.9688L75.8011 88.1282L60.8571 87.3705L52.8224 82.9688L85.1525 82.9688Z"
      fill="#31DE86"
      stroke="#31DE86"
    />
    <path
      d="M40.9621 56.3364L43.5931 46.564L45.7335 45.2263L45.3827 74.6985L40.7142 65.7706L40.9621 56.3364Z"
      fill="#31DE86"
      stroke="#31DE86"
    />
    <circle cx="64.9182" cy="80.3976" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="53.2912" cy="80.3976" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="59.1047" cy="80.3976" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="70.7326" cy="80.3976" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="67.8244" cy="74.6555" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="67.8244" cy="68.7707" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="64.9182" cy="71.7248" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="56.1974" cy="68.7941" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="56.1974" cy="74.6789" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="59.1047" cy="71.7482" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="62.0109" cy="68.8185" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="62.0109" cy="74.7023" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="70.7326" cy="71.7238" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="59.1047" cy="71.7238" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="67.8254" cy="68.7941" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="67.8254" cy="74.6789" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="62.0119" cy="68.8176" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="62.0119" cy="74.7023" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <circle cx="64.9182" cy="71.7482" r="0.5" fill="#31DE86" stroke="#31DE86" strokeWidth="0.937862" />
    <path
      d="M137.396 83.1365C138.68 83.1377 139.913 82.6292 140.822 81.7224C141.731 80.8155 142.243 79.5848 142.246 78.3006L142.246 78.2987C142.243 77.0139 141.732 75.7824 140.823 74.8743C139.914 73.9663 138.682 73.4557 137.397 73.4544L137.394 73.4545C136.113 73.4617 134.886 73.9748 133.982 74.882C133.077 75.7892 132.568 77.0171 132.564 78.2983L132.564 78.2992C132.563 78.9343 132.688 79.563 132.931 80.1499C133.173 80.7368 133.529 81.2702 133.978 81.7195C134.427 82.1688 134.96 82.5251 135.546 82.7682C136.133 83.0113 136.761 83.1365 137.396 83.1365ZM137.396 83.1365L137.397 82.6365M137.396 83.1365C137.396 83.1365 137.397 83.1365 137.397 83.1365V82.6365M137.397 82.6365C136.827 82.6365 136.264 82.5243 135.738 82.3063C135.212 82.0883 134.734 81.7689 134.332 81.3661C133.929 80.9633 133.61 80.4851 133.393 79.9589C133.175 79.4328 133.064 78.869 133.064 78.2997C133.067 77.1504 133.524 76.0489 134.336 75.235C135.147 74.4212 136.247 73.9609 137.397 73.9545C138.549 73.9555 139.654 74.4135 140.469 75.2281C141.285 76.0426 141.744 77.1472 141.746 78.2997C141.744 79.4514 141.284 80.5551 140.469 81.3683C139.653 82.1815 138.548 82.6376 137.397 82.6365ZM133.091 73.9905C134.232 72.8472 135.781 72.2034 137.397 72.2002C139.014 72.2024 140.565 72.8456 141.709 73.9889C142.853 75.1321 143.497 76.6824 143.5 78.2997C143.497 79.9161 142.852 81.4653 141.708 82.6073C140.564 83.7491 139.014 84.3904 137.398 84.3908C135.785 84.3825 134.24 83.738 133.099 82.5972C131.958 81.4565 131.314 79.9118 131.306 78.2986C131.307 76.683 131.949 75.1337 133.091 73.9905Z"
      fill="#31DE86"
      stroke="#31DE86"
    />
    <path
      d="M96.701 94.7644C97.9851 94.7657 99.2172 94.2571 100.127 93.3503C101.036 92.4435 101.548 91.2128 101.55 89.9285L101.55 89.9267C101.548 88.6418 101.036 87.4103 100.127 86.5023C99.2184 85.5942 97.9867 85.0836 96.7019 85.0824L96.6986 85.0824C95.4175 85.0896 94.191 85.6027 93.2864 86.5099C92.3818 87.4172 91.8723 88.6451 91.8687 89.9262L91.8687 89.9271C91.8681 90.5622 91.9927 91.191 92.2353 91.7779C92.4778 92.3647 92.8337 92.8981 93.2826 93.3474C93.7314 93.7967 94.2644 94.153 94.851 94.3961C95.4375 94.6392 96.0661 94.7644 96.701 94.7644ZM96.701 94.7644L96.7014 94.2644M96.701 94.7644C96.7011 94.7644 96.7013 94.7644 96.7014 94.7644V94.2644M96.7014 94.2644C96.1321 94.2644 95.5684 94.1522 95.0424 93.9342C94.5165 93.7162 94.0387 93.3968 93.6363 92.994C93.2339 92.5913 92.9148 92.113 92.6973 91.5868C92.4798 91.0607 92.3682 90.497 92.3687 89.9276C92.3719 88.7783 92.829 87.6768 93.6405 86.863C94.452 86.0491 95.5522 85.5888 96.7014 85.5824C97.8538 85.5835 98.9587 86.0415 99.7739 86.856C100.589 87.6705 101.048 88.7752 101.05 89.9276C101.048 91.0793 100.589 92.183 99.7735 92.9962C98.958 93.8095 97.8531 94.2655 96.7014 94.2644ZM92.3953 85.6185C93.5371 84.4752 95.0858 83.8313 96.7016 83.8281C98.3189 83.8303 99.8693 84.4735 101.013 85.6168C102.157 86.7601 102.801 88.3103 102.805 89.9276C102.801 91.5441 102.157 93.0932 101.013 94.2352C99.8692 95.3771 98.319 96.0184 96.7027 96.0187C95.0895 96.0105 93.5446 95.366 92.4038 94.2252C91.2631 93.0844 90.6187 91.5397 90.6104 89.9265C90.6117 88.3109 91.2536 86.7616 92.3953 85.6185Z"
      fill="#31DE86"
      stroke="#31DE86"
    />
    <path
      d="M24.032 121.894C25.3162 121.896 26.5483 121.387 27.4577 120.48C28.367 119.573 28.8791 118.343 28.8815 117.058L28.8815 117.057C28.8791 115.772 28.3673 114.54 27.4584 113.632C26.5495 112.724 25.3177 112.213 24.033 112.212L24.0297 112.212C22.7485 112.219 21.5221 112.733 20.6175 113.64C19.7128 114.547 19.2034 115.775 19.1998 117.056L19.1998 117.057C19.1992 117.692 19.3237 118.321 19.5663 118.908C19.8089 119.495 20.1648 120.028 20.6136 120.477C21.0625 120.927 21.5955 121.283 22.182 121.526C22.7685 121.769 23.3971 121.894 24.032 121.894ZM24.032 121.894L24.0325 121.394M24.032 121.894C24.0322 121.894 24.0323 121.894 24.0325 121.894V121.394M24.0325 121.394C23.4632 121.394 22.8994 121.282 22.3735 121.064C21.8476 120.846 21.3697 120.527 20.9673 120.124C20.565 119.721 20.2459 119.243 20.0284 118.717C19.8109 118.191 19.6992 117.627 19.6998 117.057C19.703 115.908 20.16 114.807 20.9715 113.993C21.783 113.179 22.8832 112.719 24.0325 112.712C25.1849 112.713 26.2897 113.171 27.105 113.986C27.9203 114.8 28.3794 115.905 28.3815 117.057C28.3794 118.209 27.9201 119.313 27.1046 120.126C26.2891 120.939 25.1841 121.395 24.0325 121.394ZM19.7263 112.748C20.8682 111.605 22.4169 110.961 24.0326 110.958C25.6499 110.96 27.2004 111.603 28.3443 112.747C29.4883 113.89 30.1325 115.44 30.1357 117.057C30.1325 118.674 29.4882 120.223 28.3442 121.365C27.2002 122.507 25.6501 123.148 24.0338 123.149C22.4205 123.14 20.8757 122.496 19.7349 121.355C18.5942 120.214 17.9497 118.67 17.9414 117.056C17.9428 115.441 18.5846 113.892 19.7263 112.748Z"
      fill="#31DE86"
      stroke="#31DE86"
    />
    <rect x="57.5352" y="49.0566" width="15.9563" height="10.5611" fill="#31DE86" stroke="#31DE86" />
  </svg>
);

export default memo(CustomGoalIcon);
