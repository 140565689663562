import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { DatasetQuestionCategory, DatasetQuestionSource } from "@vanti-analytics-org/vanti-common";
import { MenuItem, Select, styled } from "@material-ui/core";
import capitalize from "lodash/capitalize";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { vantiColors } from "assets/jss/palette";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { VantiScrollBarProps } from "components/ui/VantiScrollBarProps";
import CopyToClipboardIcon24 from "assets/icons/copy-to-clipboard/CopyToClipboardIcon24";
import { logger } from "utils/logger";
import { defaultAnswerObj } from "./DatasetQuestionsSection";
import useDatasetQuestionRequest from "common/hooks/use-dataset-question-request";
import TrashIcon24 from "assets/icons/trash-icon/TrashIcon24";
import PromoteIcon16 from "assets/icons/PromoteIcon16/PromoteIcon16";
import { Text } from "components/ui";

const DATASET_QUESTION_DESTINATION_TOGGLE_BUTTON_TEXT = {
  [DatasetQuestionSource.Manual]: "Vanti",
  [DatasetQuestionSource.VantiAI]: "GPT"
};

const hardTrim = str => str.replace(/\s+/g, "");

const DatasetQuestionsRow = ({ question, isQuestionInputEditable }) => {
  const [answerJson, setAnswerJson] = useState(
    JSON.stringify(question.answer ?? defaultAnswerObj(question.question), null, 2) || ""
  );
  const [destination, setDestination] = useState(question.destination || undefined);
  const [category, setCategory] = useState(question.category || null);
  const [editedQuestionText, setEditedQuestionText] = useState(question.question || "");
  const [editedResponseText, setEditedResponseText] = useState(answerJson);
  const [isQuestionTextFocused, setIsQuestionTextFocused] = useState(null);
  const [isResponseTextFocused, setIsResponseTextFocused] = useState(null);
  const { saveOrUpdateQuestionsRequest, deleteQuestionRequest } = useDatasetQuestionRequest();
  const [isEditing, setIsEditing] = useState(false);
  const [editError, setEditError] = useState(null);

  useEffect(() => {
    const editing =
      (hardTrim(editedQuestionText) !== hardTrim(question.question) && !!editedQuestionText.length) ||
      editedResponseText !== answerJson ||
      destination !== question.destination ||
      category !== question.category;

    if (editing) setEditError(null);

    setIsEditing(editing);
  }, [{ ...question }, answerJson, editedQuestionText, editedResponseText, destination, category]);

  //requires https:// (secured) connection
  const copyTextToClipboard = useCallback(async text => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
    } else {
      logger.warn("Failed to copy response text to clipboard. Make sure you are using secured (HTTPS) connection.");
    }
  }, []);

  const handleOnDestinationChange = useCallback((event, newValue) => {
    if (newValue) {
      setDestination(newValue);
    }
  }, []);

  const saveOrUpdateQuestions = useCallback(
    async data => saveOrUpdateQuestionsRequest.mutate({ datasetId: question.datasetId, questions: [data] }),
    [question.datasetId]
  );

  const deleteQuestion = useCallback(() => {
    deleteQuestionRequest.mutate({ datasetId: question.datasetId, questionId: question._id });
  }, [question.datasetId, question._id]);

  const shouldDisplayResponseTextArea = useMemo(
    () => destination === DatasetQuestionSource.Manual || question.source === DatasetQuestionSource.Playground,
    [destination, question.source]
  );

  const updateQuestionToManual = useCallback(() => {
    saveOrUpdateQuestions({
      _id: question._id,
      question: question.question,
      source: DatasetQuestionSource.Manual,
      destination: DatasetQuestionSource.Manual,
      category: question.category,
      answer: JSON.parse(editedResponseText),
      createdAt: new Date()
    });
  }, [saveOrUpdateQuestions, editedResponseText, { ...question }]);

  const onSave = useCallback(async () => {
    setIsEditing(false);
    try {
      const answer = JSON.parse(editedResponseText);
      const newData = {
        _id: question._id,
        question: editedQuestionText,
        source: question.source || DatasetQuestionSource.Manual,
        destination: destination,
        category: category,
        answer,
        createdAt: question.createdAt
      };
      await saveOrUpdateQuestions(newData);
    } catch (err) {
      setEditError(err.message);
    } finally {
      setAnswerJson(editedResponseText);
    }
  }, [saveOrUpdateQuestions, { ...question }, editedResponseText, editedQuestionText, destination, category]);

  return (
    <DatasetQuestionsRowContainer data-testid={`dataset-questions-row-container-${question._id.toString()}`}>
      <DatasetQuestionRowQuestionSection
        data-testid={`dataset-questions-row-question-section-${question._id.toString()}`}
      >
        <DatasetQuestionText
          value={editedQuestionText}
          autoComplete={"on"}
          onChange={e => setEditedQuestionText(e.target.value)}
          onFocus={() => setIsQuestionTextFocused(true)}
          onBlur={() => setIsQuestionTextFocused(false)}
          isFocused={isQuestionTextFocused}
          disabled={!isQuestionInputEditable}
        />
        <DatasetQuestionUserActionButtonGroup>
          {question.source !== DatasetQuestionSource.Manual && (
            <PromoteIcon16 fill={vantiColors.gray29} onClick={updateQuestionToManual} />
          )}
          <CopyToClipboardIcon24Styled onClick={() => copyTextToClipboard(editedQuestionText)} />
          <TrashIcon24 stroke={vantiColors.rose5} onClick={deleteQuestion} />
        </DatasetQuestionUserActionButtonGroup>
        <DatasetQuestionCategorySelect
          value={category}
          onChange={event => {
            setCategory(event.target.value);
          }}
        >
          {Object.values(DatasetQuestionCategory).map(category => {
            return (
              <MenuItem value={category} key={`dataset-question-category-${question._id}`}>
                {capitalize(category)}
              </MenuItem>
            );
          })}
        </DatasetQuestionCategorySelect>
        {question.source === DatasetQuestionSource.Manual && (
          <DatasetQuestionDestinationGroup
            color="primary"
            value={destination}
            exclusive
            onChange={handleOnDestinationChange}
          >
            <ToggleButtonStyled value={DatasetQuestionSource.Manual}>
              {DATASET_QUESTION_DESTINATION_TOGGLE_BUTTON_TEXT[DatasetQuestionSource.Manual]}
            </ToggleButtonStyled>
            <ToggleButtonStyled value={DatasetQuestionSource.VantiAI}>
              {DATASET_QUESTION_DESTINATION_TOGGLE_BUTTON_TEXT[DatasetQuestionSource.VantiAI]}
            </ToggleButtonStyled>
          </DatasetQuestionDestinationGroup>
        )}
      </DatasetQuestionRowQuestionSection>
      {shouldDisplayResponseTextArea && (
        <DatasetQuestionRowResponseSection>
          <DatasetResponseText
            value={editedResponseText}
            autoComplete={"on"}
            onChange={e => setEditedResponseText(e.target.value)}
            onFocus={() => setIsResponseTextFocused(true)}
            onBlur={() => setIsResponseTextFocused(false)}
            isFocused={isResponseTextFocused}
          ></DatasetResponseText>
          <DatasetResponseUserActionButtonGroup data-testid={"dataset-question-row-user-action-button-group"}>
            <CopyToClipboardIcon24Styled onClick={() => copyTextToClipboard(editedResponseText)} />
          </DatasetResponseUserActionButtonGroup>
        </DatasetQuestionRowResponseSection>
      )}
      {isEditing && (
        <SaveChangesButtonWrapper>
          <GeneralVantiButton
            variant={VARIANTS.SECONDARY}
            size={SIZES.SMALL}
            buttonInfo={{
              onClick: onSave,
              label: "Save"
            }}
          />
        </SaveChangesButtonWrapper>
      )}
      {!!editError && <Text color={vantiColors.rose6}>{editError}</Text>}
    </DatasetQuestionsRowContainer>
  );
};

const DatasetQuestionText = styled("textarea")(({ isFocused }) => ({
  flex: 8,
  alignSelf: "flex-end",
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: 16,
  border: "none",
  color: vantiColors.gray40,
  lineHeight: "19.36px",
  boxSizing: "border-box",
  resize: "none",
  ...VantiScrollBarProps,
  overflowY: isFocused ? "auto" : "hidden",
  "&:disabled": {
    backgroundColor: "transparent"
  }
}));

const DatasetResponseText = styled(DatasetQuestionText)(({ isFocused }) => ({
  height: "100%",
  backgroundColor: "transparent",
  color: vantiColors.darkBlue7,
  fontWeight: 400,
  overflowY: isFocused ? "auto" : "hidden"
}));

const DatasetQuestionCategorySelect = styled(Select)({
  boxSizing: "border-box"
});

const DatasetQuestionDestinationGroup = styled(ToggleButtonGroup)({
  boxSizing: "border-box"
});

const ToggleButtonStyled = styled(ToggleButton)({
  height: 33,
  width: 79,
  "&.MuiToggleButton-root:first-child": {
    borderTopLeftRadius: 32,
    borderBottomLeftRadius: 32
  },
  "&.MuiToggleButton-root:last-child": {
    borderTopRightRadius: 32,
    borderBottomRightRadius: 32
  }
});

const SaveChangesButtonWrapper = styled("div")({
  marginTop: 12
});

const DatasetQuestionRowQuestionSection = styled("div")({
  height: 65,
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  gap: 56
});

const DatasetQuestionRowResponseSection = styled("div")({
  height: 240,
  boxSizing: "border-box",
  position: "relative",
  display: "flex",
  alignItems: "center",
  paddingLeft: 16,
  backgroundColor: vantiColors.gray35,
  gap: 12
});

const DatasetQuestionsRowContainer = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingBottom: 12,
  "&:not(:last-child)": {
    borderBottom: `1px solid ${vantiColors.gray35}`
  }
});

const DatasetResponseUserActionButtonGroup = styled("div")({
  position: "absolute",
  top: "8px",
  right: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignSelf: "center",
  gap: 8
});

const DatasetQuestionUserActionButtonGroup = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  gap: 8
});

const CopyToClipboardIcon24Styled = styled(CopyToClipboardIcon24)({
  cursor: "pointer"
});

export default memo(DatasetQuestionsRow);
