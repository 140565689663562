import { createSelector } from "reselect";

const assignProduct = state => state.station?.operations?.assignProduct;

export const loadingSelector = stationId =>
  createSelector(
    assignProduct,
    state => state.loading[stationId]
  );

export const errorSelector = stationId =>
  createSelector(
    assignProduct,
    state => state.error[stationId]
  );
export const successSelector = stationId =>
  createSelector(
    assignProduct,
    state => state.success[stationId]
  );
