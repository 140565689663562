import React from "react";

const SuccessIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.85355 13.6464L8.5 13.2929L7.79289 14L8.14645 14.3536L8.85355 13.6464ZM12 17.5L11.6464 17.8536L12.0244 18.2315L12.3763 17.8293L12 17.5ZM19.3763 9.82925L19.7055 9.45296L18.953 8.79446L18.6237 9.17075L19.3763 9.82925ZM8.14645 14.3536L11.6464 17.8536L12.3536 17.1464L8.85355 13.6464L8.14645 14.3536ZM12.3763 17.8293L19.3763 9.82925L18.6237 9.17075L11.6237 17.1707L12.3763 17.8293Z"
      fill="#13115B"
    />
    <path
      d="M25 13C25 14.5759 24.6896 16.1363 24.0866 17.5922C23.4835 19.0481 22.5996 20.371 21.4853 21.4853C20.371 22.5996 19.0481 23.4835 17.5922 24.0866C16.1363 24.6896 14.5759 25 13 25C11.4241 25 9.86371 24.6896 8.4078 24.0866C6.95189 23.4835 5.62902 22.5996 4.51472 21.4853C3.40042 20.371 2.5165 19.0481 1.91345 17.5922C1.31039 16.1363 1 14.5759 1 13C1 11.4241 1.31039 9.8637 1.91345 8.4078C2.5165 6.95189 3.40042 5.62902 4.51472 4.51472C5.62902 3.40041 6.95189 2.5165 8.4078 1.91345C9.86371 1.31039 11.4241 1 13 1C14.5759 1 16.1363 1.31039 17.5922 1.91345C19.0481 2.5165 20.371 3.40042 21.4853 4.51472C22.5996 5.62902 23.4835 6.95189 24.0866 8.4078C24.6896 9.86371 25 11.4241 25 13L25 13Z"
      stroke="#13115B"
    />
  </svg>
);

export default SuccessIcon;
