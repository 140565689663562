import { put, select, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./actions";
import * as stationService from "services/api/station";
import { actionTypes as onboardingActionTypes } from "modules/onboarding/actionTypes";

export function* assignProductWorker(action) {
  const { stationId, product, isChecked } = action.payload;
  try {
    const accountId = yield select(state => state.account.state.id);
    yield stationService.assignStationProducts(isChecked, accountId, stationId, product);
    yield put({ type: actionTypes.STATION_ASSIGN_PRODUCT_SUCCESS, payload: { stationId } });

    //Temporary code until we'll have station state
    if (isChecked) {
      yield put({ type: onboardingActionTypes.ONBOARDING_SET_STATION_PRODUCT, payload: product });
    } else {
      yield put({ type: onboardingActionTypes.ONBOARDING_UNSET_STATION_PRODUCT, payload: product });
    }
  } catch (error) {
    yield put({ type: actionTypes.STATION_ASSIGN_PRODUCT_ERROR, payload: { stationId, error } });
  } finally {
    yield put({ type: actionTypes.STATION_ASSIGN_PRODUCT_FULFILL, payload: { stationId } });
  }
}

export default function* assignProductWatcher() {
  yield takeLatest(actionTypes.STATION_ASSIGN_PRODUCT_REQUEST, assignProductWorker);
}
