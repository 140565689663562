import makeStyles from "@material-ui/core/styles/makeStyles";
import arrowUp from "assets/img/arrow-up.svg";

export default makeStyles(theme => ({
  filesError: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    marginTop: theme.spacing(3),
    "& > div": {
      textAlign: "start",
      marginLeft: theme.spacing(1)
    }
  },
  fileBrowserWrapper: {
    height: "330px",
    marginTop: theme.spacing(3)
  },
  headersWrapper: {
    display: "flex",
    marginLeft: "28px",
    "& > span": {
      position: "relative",
      padding: "2px 8px",
      flex: "0 1 150px",
      fontWeight: "500",
      boxSizing: "border-box",
      "&:first-of-type": {
        paddingLeft: "8px",
        flex: "1 1 300px",
        fontWeight: "500"
      }
    }
  },
  arrowUp: {
    "&:after": {
      content: "''",
      display: "block",
      height: "16px",
      width: "16px",
      position: "absolute",
      top: 6,
      left: -10,
      transform: "rotate(360deg)",
      background: `url(${arrowUp}) no-repeat center center fixed`
    }
  },
  arrowDown: {
    "&:after": {
      content: "''",
      display: "block",
      height: "16px",
      width: "16px",
      position: "absolute",
      top: 6,
      left: -10,
      transform: "rotate(180deg)",
      background: `url(${arrowUp}) no-repeat center center fixed`
    }
  }
}));
