import React, { forwardRef, memo, useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@material-ui/core";
import SearchBar from "./SearchBar";
import { vantiColors } from "assets/jss/palette";
import CheckboxUnfilled24 from "assets/icons/checkbox/CheckboxUnfilled24";
import { Text } from "components/ui";
import CheckboxFilled24 from "assets/icons/checkbox/CheckboxFilled24";

const BackdropWrapper = styled("div")({
  position: "absolute",
  width: "100vw",
  height: "100vh",
  top: 0,
  left: 0
});

const SearchPopupWrapper = styled("div")(({ position }) => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  background: vantiColors.white,
  transform: "translateY(4px)",
  padding: "8px 8px 0px 8px",
  width: 218,
  maxHeight: 282,
  overflow: "hidden",
  boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.15)",
  boxSizing: "border-box",
  top: position.top,
  left: position.left
}));

const Header = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 8
});

const ItemsList = styled("div")({
  overflow: "scroll"
});

const ItemStyled = styled("div")({
  padding: "16px 12px",
  display: "flex",
  alignItems: "center",
  columnGap: 8
});

const IconButtonWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  cursor: "pointer"
});

const TextItemStyled = styled(Text)({
  overflow: "hidden",
  maxWidth: 218,
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
});

const Item = ({ text, isSelected, onClickedCheckbox }) => {
  return (
    <ItemStyled>
      <IconButtonWrapper onClick={onClickedCheckbox}>
        {isSelected ? <CheckboxFilled24 /> : <CheckboxUnfilled24 />}
      </IconButtonWrapper>

      <TextItemStyled color={vantiColors.gray29}>{text}</TextItemStyled>
    </ItemStyled>
  );
};

const SearchPopup = (
  { items = [], onSelectCallback = () => {}, inputPlaceholder, onClose, selectedFilterItems, title },
  ref
) => {
  const [itemsForDisplay, setItemsForDisplay] = useState(items);
  const [searchValue, setSearchValue] = useState("");

  const position = useMemo(() => {
    const position = {
      top: 0,
      left: 0
    };

    if (!ref) return {};

    const rect = ref?.getBoundingClientRect();

    if (!rect) return {};

    position.top = rect.top + rect.height;
    position.left = rect.left;

    return position;
  }, [ref]);

  const onChangeSearchValue = useCallback(val => {
    setSearchValue(val);
  }, []);

  const onSelectOrUnselect = useCallback(
    clickedItem => {
      let selectedFilterItemsCopy = [...selectedFilterItems];

      const itemInListIndex = selectedFilterItemsCopy.findIndex(
        item => item.id === clickedItem.id && item.key === clickedItem.key
      );

      if (itemInListIndex === -1) {
        selectedFilterItemsCopy = [...selectedFilterItemsCopy, clickedItem];
      } else {
        selectedFilterItemsCopy.splice(itemInListIndex, 1);
      }

      onSelectCallback(selectedFilterItemsCopy);
    },
    [selectedFilterItems, onSelectCallback]
  );

  useEffect(() => {
    const newItems = [...items];

    if (!searchValue.trim().length) setItemsForDisplay(newItems);

    setItemsForDisplay(newItems.filter(item => item.title?.toLowerCase()?.includes(searchValue.toLowerCase())));
  }, [items, searchValue]);

  return (
    <BackdropWrapper onClick={onClose}>
      <SearchPopupWrapper position={position} onClick={e => e.stopPropagation()}>
        <Header>
          <Text size={12} color={vantiColors.lightGray16}>
            {title}
          </Text>

          <SearchBar placeholder={inputPlaceholder} onChange={onChangeSearchValue} />
        </Header>

        <ItemsList>
          {itemsForDisplay.map(item => (
            <Item
              key={item.id}
              text={item.title}
              onClickedCheckbox={() => onSelectOrUnselect(item)}
              isSelected={selectedFilterItems.find(
                selectedItem => selectedItem.id === item.id && selectedItem.key === item.key
              )}
            />
          ))}
        </ItemsList>
      </SearchPopupWrapper>
    </BackdropWrapper>
  );
};

export default memo(forwardRef(SearchPopup));
