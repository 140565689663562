export const actionTypes = {
  ACCOUNT_FETCH_USERS_REQUEST: "account.fetchUsers.request",
  ACCOUNT_FETCH_USERS_SUCCESS: "account.fetchUsers.success",
  ACCOUNT_FETCH_USERS_ERROR: "account.fetchUsers.error",
  ACCOUNT_FETCH_USERS_FULFILL: "account.fetchUsers.fulfill"
};

export function fetchUsersRequestAction() {
  return { type: actionTypes.ACCOUNT_FETCH_USERS_REQUEST };
}
