import React, { memo, useEffect, useState } from "react";
import { styled } from "@material-ui/core";
import useDataEntityRequest from "common/hooks/use-data-entity-request";
import { ReactFlowProvider } from "reactflow";
import "reactflow/dist/style.css";
import HorizontalFlow from "./HorizontalFlow";

const FlowPageWrapper = styled("div")({
  position: "relative",
  height: "100%"
});

const FlowPage = () => {
  const { getDataEntitiesRequest } = useDataEntityRequest();

  const [nodeToModify, setNodeToModify] = useState(null);

  useEffect(() => {
    getDataEntitiesRequest.mutate();
  }, []);

  return (
    <FlowPageWrapper>
      <ReactFlowProvider>
        <HorizontalFlow
          nodeToModify={nodeToModify}
          onAddNodeFinishCallback={() => {
            setNodeToModify(null);
          }}
        />
      </ReactFlowProvider>
    </FlowPageWrapper>
  );
};

export default memo(FlowPage);
