import React, { memo, useCallback, useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, styled } from "@material-ui/core";
import ArrowDown from "assets/icons/arrow-down/ArrowDown";
import DataConnectorsIcon80 from "assets/icons/data-connectors/DataConnectorsIcon80";
import DataCurationIcon80 from "assets/icons/data-curation/DataCurationIcon80";
import PlusIconBG40 from "assets/icons/plus-icon/PlusIconBG40";
import UploadIconBG24 from "assets/icons/upload-icon-bg/UploadIconBG24";
import { vantiColors } from "assets/jss/palette";
import { Text } from "components/ui";
import { useDropzone } from "react-dropzone";
import { ALLOWED_FILE_TYPES, CONNECTORS_ICONS, ROW_TYPES } from "../constants";
import { RubricStyled } from "./common-styled-components";
import DatasetRubric from "./dataset-rubric/DatasetRubric";
import { finishLocalUpload, startLocalFileUpload } from "services/api/dataset";
import { uploadFileDirectlyToUrl } from "services/files/files";
import { countHeaders } from "utils/user-data-reader/csv";
import CustomDialog from "components/ui/Dialog/CustomDialog";
import GeneralVantiButton from "components/ui/Buttons/GeneralVantiButton";
import usePermissions from "common/hooks/use-permissions";
import { ROLE } from "@vanti-analytics-org/vanti-common";

const UPLOAD_FILE_TEXT = "Upload files";
const ALLOWED_FILE_FORMATS_TEXT = "csv, xml, json, flat zip";
const CONNECTORS_PLACEHOLDER_TEXT = "Your data connectors will appear here";
const CURATED_DATASETS_PLACEHOLDER_TEXT = "Your curated datasets will appear here";
const ADD_DATA_TEXT = "Add data";

const TOO_MANY_COLUMNS_DIALOG = {
  title: "Column limit exceeded",
  body:
    "The file you uploaded has exceeded the maximum allowed columns. Please upload a file with a maximum of 100 column"
};

const ACCEPTED_FILE_TYPES = ALLOWED_FILE_TYPES.map(fileType => `.${fileType}`).join(",");

const FileRowStyled = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.vantiColors.white,
  borderRadius: 4,
  padding: "14px 16px",
  boxSizing: "border-box",
  "& .MuiAccordionSummary-content": {
    margin: 0
  },
  "& .MuiAccordionSummary-root": {
    padding: 0
  },
  "& .MuiAccordionDetails-root": {
    padding: 0,
    backgroundColor: theme.palette.vantiColors.white
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "auto",
    marginBottom: 24
  }
}));

const RowPreviewStyled = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: 8
});

const AddDatasetRubricStyled = styled(RubricStyled)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  rowGap: 16,
  cursor: "pointer"
});

const AccordionDetailsStyled = styled(AccordionDetails)({
  columnGap: 12,
  overflowX: "scroll",
  rowGap: 12,
  flexWrap: "wrap",
  maxHeight: 366
});

const PlaceholderWrapperStyled = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: 16,
  alignItems: "center",
  justifyContent: "center",
  padding: "24px 0px",
  backgroundColor: theme.palette.vantiColors.white
}));

const UploadFileTextsWrapperStyled = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
});

const HiddenInputStyled = styled("input")({
  display: "none"
});

export const FOLDER_SOURCE_TYPE = "FOLDER";
const AZURE_BLOB_STORAGE_TYPE = "azure_blob";

export const AddDatasetRubric = ({ onClick }) => {
  const canUploadDataset = usePermissions([ROLE.UPLOAD_DATASET]);

  if (!canUploadDataset) return null;

  return (
    <AddDatasetRubricStyled onClick={onClick}>
      <HiddenInputStyled />
      <PlusIconBG40 />

      <Text size={14} weight={400} color={vantiColors.darkBlue7} lineSize={"16.94px"}>
        {ADD_DATA_TEXT}
      </Text>
    </AddDatasetRubricStyled>
  );
};

const UploadFilesRubric = ({ onClick, onTooManyColumns }) => {
  const canUploadFiles = usePermissions([ROLE.UPLOAD_DATASET]);

  const performMultipartUpload = async file => {
    const { uploadUrls, uploadId, partSize, dataset } = await startLocalFileUpload(file.name, file.size);

    let partChecksums = [];

    for (const [index, url] of uploadUrls.entries()) {
      const chunk = file.slice(
        index * partSize,
        index * partSize + (index === uploadUrls.length - 1 ? file.size % partSize : partSize)
      );

      const response = await uploadFileDirectlyToUrl(url, chunk);

      partChecksums.push({
        etag: response.headers.get("ETag"),
        partNumber: index + 1
      });
    }

    await finishLocalUpload(dataset._id, uploadId, partChecksums);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ondrop: () => {},
    multiple: true,
    getFilesFromEvent: async event => {
      const files = [];
      const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;

      if (event.dataTransfer) {
        const droppedItem = event.dataTransfer.items[0];

        if (!droppedItem.type) {
          return [];
        }
      }

      for await (const file of fileList) {
        file.path = {
          value: file.path || file.webkitRelativePath || file.name,
          writable: false,
          configurable: false,
          enumerable: true
        };

        files.push(file);

        countHeaders(file, async headersCount => {
          if (headersCount > 100) {
            if (onTooManyColumns) onTooManyColumns();
            return;
          } else {
            await performMultipartUpload(file);
          }
        });
      }

      return files;
    }
  });

  if (!canUploadFiles) return null;

  return (
    <AddDatasetRubricStyled onClick={onClick} {...getRootProps({ isDragActive })}>
      <HiddenInputStyled {...getInputProps()} accept={ACCEPTED_FILE_TYPES} />
      <UploadIconBG24 />

      <UploadFileTextsWrapperStyled>
        <Text size={14} weight={400} color={vantiColors.darkBlue7} lineSize={"16.94px"}>
          {UPLOAD_FILE_TEXT}
        </Text>

        <Text size={14} weight={400} color={vantiColors.gray33} lineSize={"16.94px"}>
          {ALLOWED_FILE_FORMATS_TEXT}
        </Text>
      </UploadFileTextsWrapperStyled>
    </AddDatasetRubricStyled>
  );
};

const NoConnectorsPlaceholderRubric = () => {
  return (
    <PlaceholderWrapperStyled>
      <DataConnectorsIcon80 />
      <Text color={vantiColors.gray33}>{CONNECTORS_PLACEHOLDER_TEXT}</Text>
    </PlaceholderWrapperStyled>
  );
};

const CuratedDatasetsPlaceholderRubric = () => {
  return (
    <PlaceholderWrapperStyled>
      <DataCurationIcon80 />
      <Text color={vantiColors.gray33}>{CURATED_DATASETS_PLACEHOLDER_TEXT}</Text>
    </PlaceholderWrapperStyled>
  );
};

const DataRow = ({
  source,
  title,
  datasets = [],
  rowType = ROW_TYPES.CONNECTOR,
  onDownloadDataset,
  onMoveDataset,
  onDeleteDataset,
  onAddDatasetClick
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLocalFileHasTooManyCols, setIsLocalFileHasTooManyCols] = useState(false);
  const isAllowedToSendChatMessage = usePermissions([ROLE.SEND_CHAT_MESSAGE]);

  const onTooManyColumnsUploaded = useCallback(() => {
    setIsLocalFileHasTooManyCols(true);
  }, []);

  const ADD_DATA_RUBRIC = useMemo(
    () => ({
      [ROW_TYPES.CONNECTOR]: <AddDatasetRubric onClick={onAddDatasetClick} />,
      [ROW_TYPES.UPLOADED_FILES]: (
        <UploadFilesRubric onClick={onAddDatasetClick} onTooManyColumns={onTooManyColumnsUploaded} />
      )
    }),
    [AddDatasetRubric, UploadFilesRubric, onAddDatasetClick]
  );

  const PLACEHOLDER_RUBRIC = useMemo(
    () => ({
      [ROW_TYPES.CONNECTOR]: ADD_DATA_RUBRIC[ROW_TYPES.CONNECTOR],
      [ROW_TYPES.CURATED_DATASETS]: <CuratedDatasetsPlaceholderRubric />,
      [ROW_TYPES.UPLOADED_FILES]: <UploadFilesRubric onTooManyColumns={onTooManyColumnsUploaded} />,
      [ROW_TYPES.NO_CONNECTORS]: <NoConnectorsPlaceholderRubric />
    }),
    [NoConnectorsPlaceholderRubric, CuratedDatasetsPlaceholderRubric, UploadFilesRubric]
  );
  const handleExpand = useCallback((event, isExpanded) => {
    if (isExpanded) {
      setIsExpanded(true);
    } else {
      // This timeout is for the animation of collapsing the row to be finished
      setTimeout(() => {
        setIsExpanded(false);
      }, 0);
    }
  }, []);

  return (
    <div>
      <FileRowStyled onChange={handleExpand}>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <RowPreviewStyled>
            {source && CONNECTORS_ICONS[source.name]}

            <Text size={18} weight={600}>
              {title}
            </Text>
          </RowPreviewStyled>
        </AccordionSummary>

        {isExpanded &&
          (!datasets.length ? (
            PLACEHOLDER_RUBRIC[rowType]
          ) : (
            <AccordionDetailsStyled>
              {ADD_DATA_RUBRIC[rowType]}
              {datasets.map((dataset, index) => (
                <DatasetRubric
                  key={index}
                  dataset={dataset}
                  onDownloadDataset={onDownloadDataset}
                  onMoveDataset={onMoveDataset}
                  onDeleteDataset={onDeleteDataset}
                  isFolder={dataset.entryType === FOLDER_SOURCE_TYPE}
                />
              ))}
            </AccordionDetailsStyled>
          ))}
      </FileRowStyled>

      <CustomDialog
        isOpened={isLocalFileHasTooManyCols}
        title={TOO_MANY_COLUMNS_DIALOG.title}
        body={TOO_MANY_COLUMNS_DIALOG.body}
        onClose={() => {
          setIsLocalFileHasTooManyCols(false);
        }}
      >
        <GeneralVantiButton
          buttonInfo={{
            label: "Ok",
            disabled: false,
            onClick: () => {
              setIsLocalFileHasTooManyCols(false);
            }
          }}
        />
      </CustomDialog>
    </div>
  );
};

export default memo(DataRow);
