import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  cardBody: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(2.75, 11.75, 0, 11.75)
  },
  cardBodyContainer: {
    alignSelf: "center",
    display: "grid",
    width: "auto"
  }
}));
