import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import Carousel from "react-grid-carousel";
import { vantiColors } from "assets/jss/palette";
import ChatMessageInlineButton from "../buttons/ChatMessageInlineButton";
import React, { memo } from "react";

const MAX_FOLLOWUP_QUESTIONS_SHOWN = 9;
const MAX_QUESTIONS_PER_SLIDE = 3;

const InlineButtonsFollowupQuestionsWrapper = styled("div")(() => ({
  width: "100%",
  boxSizing: "border-box",
  flexDirection: "row",
  paddingBottom: 16,
  "& > div > div ": {
    margin: 0
  }
}));

const CustomCarouselNavigationButtonsStyle = styled(Text)(({ isNext }) => ({
  position: "absolute",
  top: "100%",
  right: isNext ? "0px" : "",
  cursor: "pointer"
}));

const FOLLOWUP_QUESTIONS_NEXT_BUTTON_TEXT = "More suggestions >";
const FOLLOWUP_QUESTIONS_PREVIOUS_BUTTON_TEXT = "< Previous suggestions";

const InlineButtonsFollowupQuestionsContainer = ({ message }) => {
  return (
    <InlineButtonsFollowupQuestionsWrapper data-testid={"inline-buttons-followup-questions-wrapper"}>
      <Carousel
        rows={1}
        cols={MAX_QUESTIONS_PER_SLIDE}
        gap={8}
        showDots
        dotColorActive={vantiColors.darkBlue7}
        dotColorInactive={vantiColors.lightBlue11}
        containerStyle={{ paddingBottom: 14 }}
        arrowRight={
          <div>
            <CustomCarouselNavigationButtonsStyle isNext={true} color={vantiColors.darkBlue7}>
              {FOLLOWUP_QUESTIONS_NEXT_BUTTON_TEXT}
            </CustomCarouselNavigationButtonsStyle>
          </div>
        }
        arrowLeft={
          <div>
            <CustomCarouselNavigationButtonsStyle isNext={false} color={vantiColors.darkBlue7}>
              {FOLLOWUP_QUESTIONS_PREVIOUS_BUTTON_TEXT}
            </CustomCarouselNavigationButtonsStyle>
          </div>
        }
        data-testid={"inline-buttons-followup-questions-carousel"}
      >
        {message?.inlineButtons?.slice(0, MAX_FOLLOWUP_QUESTIONS_SHOWN).map(({ action, title }, index) => {
          const buttonId = action?.split("/")[1];
          return (
            <Carousel.Item key={`carousel-item-${buttonId}`}>
              <ChatMessageInlineButton
                chatId={message.chatId}
                key={buttonId}
                title={title}
                buttonAction={action}
                index={index}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </InlineButtonsFollowupQuestionsWrapper>
  );
};

export default memo(InlineButtonsFollowupQuestionsContainer);
