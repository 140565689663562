import React from "react";
import { Chip, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import { FlexContainer, FlexItem, Text } from "components/ui";
import { INDUSTRIES, KEY_CODES, maxInputFieldLength, roles } from "../constants";

const ADDITIONAL_INFO_CARD = (
  industries,
  selectedRole,
  otherIndustry,
  onSelectedIndustriesChange,
  isShowOtherIndustryField,
  selectedIndustries,
  onOtherIndustryChange,
  onSelectedRoleChange,
  onIsShowOtherIndustryField,
  isUserBrowsingFromMobile
) => {
  const headerTextSize = isUserBrowsingFromMobile ? 40 : 30;

  return {
    text: (
      <FlexContainer data-testid="step3-title-container" item xs={11} md={4} dense="full">
        <Text color={vantiColors.darkBlue1} size={headerTextSize} weight="bold">
          Tell us about your{" "}
          <Text size={headerTextSize} weight="bold" color={vantiColors.green1}>
            company{" "}
          </Text>
          and your{" "}
          <Text size={headerTextSize} weight="bold" color={vantiColors.green1}>
            role.
          </Text>
        </Text>
      </FlexContainer>
    ),
    description: "Additional info",
    element: (
      <FlexContainer item xs={11} md={6} justifyContent="space-evenly" data-testid="step3-industry-section">
        <FlexItem container justifyContent="flex-start" dense="full" xs={11} md={8}>
          <FlexItem dense="horizontal">
            <Text data-testid="step3-subtitle-text" size={14}>
              Company industry
            </Text>
          </FlexItem>
        </FlexItem>

        {industries.map(industryArray => (
          <FlexItem key={industryArray[0].value} container dense="full" justifyContent="center">
            {industryArray.map((industry, index) => (
              <FlexItem
                data-testid={`${industry.displayName}-chip`}
                key={industry.displayName}
                dense="full"
                style={{
                  marginRight: index < industryArray.length - 1 ? 16 : 0,
                  marginBottom: isUserBrowsingFromMobile ? 16 : 32
                }}
              >
                <Chip
                  label={industry.displayName}
                  onClick={() => {
                    if (industry.value === INDUSTRIES.OTHER.value) {
                      const newState = !isShowOtherIndustryField;
                      onIsShowOtherIndustryField(newState);
                    } else {
                      onSelectedIndustriesChange(industry.value);
                    }
                  }}
                  clickable
                  style={{
                    backgroundColor:
                      industry.value === INDUSTRIES.OTHER.value
                        ? isShowOtherIndustryField
                          ? vantiColors.green1
                          : vantiColors.lightBlue8
                        : selectedIndustries.includes(industry.value)
                        ? vantiColors.green1
                        : vantiColors.lightBlue8,
                    color: vantiColors.darkBlue1,
                    fontSize: isUserBrowsingFromMobile ? 12 : 14,
                    padding: "4px",
                    fontWeight: 500,
                    fontFamily: "Inter",
                    height: isUserBrowsingFromMobile ? 40 : "auto",
                    borderRadius: isUserBrowsingFromMobile ? "100px" : "auto"
                  }}
                />
              </FlexItem>
            ))}
          </FlexItem>
        ))}

        {isShowOtherIndustryField && (
          <FlexItem xs={11} md={8} dense="horizontal">
            <TextField
              label={INDUSTRIES.OTHER.displayName}
              id="other-industry"
              variant="standard"
              InputLabelProps={{
                shrink: true,
                style: { fontFamily: "Inter" }
              }}
              fullWidth
              inputProps={{
                "data-testid": "create-account-other-industry-input",
                style: {
                  fontFamily: "Inter",
                  paddingLeft: "16px"
                },
                value: otherIndustry,
                onChange: e => onOtherIndustryChange(e.target.value),
                onKeyDown: e => {
                  if (e.keyCode === KEY_CODES.TAB) e.preventDefault();
                },
                maxLength: maxInputFieldLength,
                placeholder: "e.g. 3d printing"
              }}
            />
          </FlexItem>
        )}

        <FlexItem xs={11} md={8} dense="horizontal">
          <FormControl fullWidth>
            <InputLabel id="role-select-label">Select your role</InputLabel>
            <Select
              SelectDisplayProps={{
                "data-testid": "create-account-role-select"
              }}
              id="role-select"
              value={selectedRole}
              onChange={e => {
                onSelectedRoleChange(e.target.value);
              }}
              style={{
                textAlign: "left"
              }}
            >
              {roles.map(role => (
                <MenuItem value={role} key={role} data-testid={`${role}-role-option`}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FlexItem>
      </FlexContainer>
    ),
    isNextDisabled: false
  };
};

export default ADDITIONAL_INFO_CARD;
