import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";
import { FlexItem, Text } from "components/ui";
import AceEditor from "react-ace";
import styles from "../../../../styles";

const CodePreview = ({ preview, mode, fileFormat, onCodeUpdate, readOnly, error, code, ...props }) => {
  const classes = styles();

  return (
    <FlexItem container dense="full" data-testid={`curation-preview-section`}>
      <FlexItem container dense="full" className={classes.codeEditorContainer}>
        <AceEditor
          height={320}
          width={"100%"}
          readOnly={readOnly}
          className={classes.codeEditor}
          value={code}
          onChange={onCodeUpdate}
          mode={mode || "python"}
          theme="textmate"
          name="request_body_editor"
          enableBasicAutocompletion={true}
          fontSize={12}
          showGutter={false}
          showPrintMargin={false}
          setOptions={{
            showLineNumbers: false,
            tabSize: 2,
            showFoldWidgets: true,
            useWorker: false
          }}
          {...props}
        />
      </FlexItem>

      {error && (
        <FlexItem dense="bottom">
          <Text color={vantiColors.rose6} data-testid="create-new-curation-error" size={14}>
            {error}
          </Text>
        </FlexItem>
      )}
    </FlexItem>
  );
};

export default memo(CodePreview);
