import React, { useEffect, useRef, useState, memo } from "react";
import { logger } from 'utils/logger'
import { Box } from "@material-ui/core";
import Accordion from "components/ui/Accordion/Accordion";
import Text from "components/ui/Typography/Text";
import Tracker from "../Tracker";
import TrackerInfoBlock from "../TrackerInfoBlock/TrackerInfoBlock";
import StepData from "./StepData/StepData";

import { BLOCK_DB_STATES } from "common/constants/BlockConstants";
import { StepsDataMap, stepsTimeTrainingSumMap } from "./TrainingStepOverview.constants";

import * as blockService from "services/api/block";

import styles from "./styles";

export const ONBOARDING_TEXT_TRAINING_STATUS = Object.freeze({
  DRAFT: "DRAFT",
  TRAINING: "TRAINING",
  FINISH_TRAINING: "FINISH_TRAINING"
});

const TrainingStepOverview = props => {
  const {
    model,
    size,
    accordionDetailsStyles,
    accordionStyles = {},
    callbackStartTraining,
    callbackFinishTraining,
    inReport
  } = props;

  const classes = styles();

  const timerRef = useRef();

  const [currentStep, setCurrentStep] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);

  const currentStepIndex = Object.values(StepsDataMap).findIndex(item => currentStep?.step === item.step);
  const lastStep = Object.values(StepsDataMap).pop();

  useEffect(() => {
    imitateTrainingHandle();

    return () => clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    clearTimeout(timerRef.current);
    imitateTrainingHandle();
  }, [model]);

  const imitateTrainingHandle = async () => {
    if (model && model.state === BLOCK_DB_STATES.IN_ANALYSIS) {
      const updatedModelDate = model.updatedAt;
      const minutesDateDiff = parseInt((Date.now() - updatedModelDate.getTime()) / 1000 / 60);

      let currentStep = null;

      for (const [stepName, time] of Object.entries(stepsTimeTrainingSumMap)) {
        if (time > minutesDateDiff) {
          currentStep = Object.values(StepsDataMap).find(stepItem => stepItem.step === stepName);
          break;
        }
      }

      if (currentStep) {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(
          () => imitateTrainingHandle(),
          (stepsTimeTrainingSumMap[currentStep.step] - minutesDateDiff) * 60 * 1000
        );
        setCurrentStep(currentStep);
        callbackStartTraining && callbackStartTraining();
      } else {
        try {
          const stepsArr = Object.values(StepsDataMap);
          const currentModel = await blockService.getBlock(model.id);
          if (currentModel.state === BLOCK_DB_STATES.IN_ANALYSIS) {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => imitateTrainingHandle(), 60 * 1000);
            let lastTrainingStep = stepsArr[stepsArr.length - 2];
            setCurrentStep(lastTrainingStep);
            callbackStartTraining && callbackStartTraining();
          } else {
            clearTimeout(timerRef.current);
            const readyStep = stepsArr[stepsArr.length - 1];
            setCurrentStep(readyStep);
            callbackFinishTraining && callbackFinishTraining();
          }
        } catch (error) {
          logger.log(error);
        }
      }
    }
    if (model && model.state !== BLOCK_DB_STATES.IN_ANALYSIS) {
      clearTimeout(timerRef.current);
      const stepsArr = Object.values(StepsDataMap);
      const readyStep = stepsArr[stepsArr.length - 1];
      setCurrentStep(readyStep);
      callbackFinishTraining && callbackFinishTraining();
    }
  };

  const graphValue = 100 / Object.values(StepsDataMap).length;
  const data = Object.values(StepsDataMap)
    .filter(item => item.step !== lastStep.step)
    .map(item => ({ name: item.step, value: graphValue }));

  return (
    <Box display="flex" justifyContent={inReport ? "space-evenly" : "space-between"} width="100%">
      <Box data-testid="model-training-steps-section" mt="30px" width={size ? null : "100%"}>
        <Accordion
          style={accordionStyles}
          detailsStyles={accordionDetailsStyles}
          className={classes.accordion}
          title={<Text h={3}>Model training steps</Text>}
          isSummaryExpanded={isExpanded}
          setIsSummaryExpanded={() => setIsExpanded(prev => !prev)}
        >
          <Box className={classes.wrapper}>
            {Object.keys(StepsDataMap).map((itemKey, index) => {
              return (
                <StepData
                  key={itemKey}
                  itemKey={itemKey}
                  item={StepsDataMap[itemKey]}
                  lastStep={lastStep}
                  isActive={currentStep?.step === itemKey || (currentStepIndex > -1 && index < currentStepIndex)}
                />
              );
            })}
          </Box>
        </Accordion>
      </Box>
      {currentStep && (
        <Tracker
          currentStepIndex={currentStepIndex}
          size={size}
          data={data}
          currentStep={currentStep}
          infoBlock={
            <TrackerInfoBlock
              lastStep={lastStep}
              step={currentStep.step}
              time={currentStep.time}
              text={currentStep.title}
            />
          }
        />
      )}
    </Box>
  );
};

export default memo(TrainingStepOverview);
