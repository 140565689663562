import React, { useRef, useState } from "react";

import { useSelector } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import { styled } from "@material-ui/core";

import VantiBreadcrumbs from "components/ui/Breadcrumbs";
import { FlexContainer, FlexItem } from "components/ui";
import AccountNotificationCenter from "components/ui/WebNotificationCenter/AccountNotificationCenter/AccountNotificationCenter";

import TrialBanner from "./topbar/trial-banner/TrialBanner";

import {Licenses, PERSONAS_ENUM, ROLES_ENUM} from "common/constants/AccountConstants";
import usePermissions from "common/hooks/use-permissions";

import { accountLicenseSelector } from "modules/account/state/selectors";

import { APP_MAIN_BACKGROUND_COLOR, vantiColors } from "assets/jss/palette";
import { topBarHeight } from "assets/jss/vanti-dashboard";

import UserMenuIcon from "./topbar/UserMenuIcon";
import UserMenu from "./topbar/UserMenu/UserMenu";

export const VantiAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: APP_MAIN_BACKGROUND_COLOR,
  boxShadow: "none",
  borderBottom: `1px solid ${vantiColors.lightGray4}`,
  position: "sticky",
  width: "100%",
  height: topBarHeight,
  color: vantiColors.gray8,
  transition: "all 150ms ease 0s",
  display: "flex",
  flexDirection: "row"
}));

export const ContentWrapper = styled(FlexContainer)(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 2),
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex"
}));

export const HeaderInfo = styled(FlexItem)(({ theme }) => ({
  display: "flex"
}));

export const IconsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: theme.spacing(1.5)
}));


export default function Topbar() {
  const accountLicense = useSelector(accountLicenseSelector);
  const isGrantedToViewTrialBanner = usePermissions([ROLES_ENUM.TRIAL_BANNER]);
  const isGrantedToViewBellNotifications = usePermissions([ROLES_ENUM.NOTIFICATION_CENTER]);

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const topBarIconsContainerRef = useRef(null);

  const onUserIconClicked = () => {
    setIsUserMenuOpen(true);
  };

  const onUserMenuClose = () => {
    setIsUserMenuOpen(false);
  };

  return (
    <VantiAppBar data-testid={"top-bar"}>
      <ContentWrapper>
        <HeaderInfo dense={"full"}>
          <VantiBreadcrumbs />
        </HeaderInfo>
        <IconsContainer ref={topBarIconsContainerRef}>
          {isGrantedToViewBellNotifications && <AccountNotificationCenter anchorElement={topBarIconsContainerRef} data-testid="notification-center" />}
          <UserMenuIcon
            onClick={onUserIconClicked}
            targetRef={topBarIconsContainerRef}
            isUserMenuOpen={isUserMenuOpen}
          />
          <UserMenu anchorElement={topBarIconsContainerRef.current} isOpen={isUserMenuOpen} onClose={onUserMenuClose} />
        </IconsContainer>

        {isGrantedToViewTrialBanner && accountLicense === Licenses.TRIAL && <TrialBanner />}
      </ContentWrapper>
    </VantiAppBar>
  );
}

Topbar.propTypes = {};
