import { vantiColors } from "assets/jss/palette";

const buttonStyle = {
  button: {
    minHeight: "auto",
    minWidth: "auto",
    backgroundColor: vantiColors.gray1,
    color: vantiColors.blue4,
    border: "none",
    borderRadius: "5px",
    position: "relative",
    padding: "12px 30px",
    margin: ".3125rem 1px",
    fontSize: "12px",
    fontWeight: "400",
    textTransform: "none",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition: "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    "&:hover": {
      color: vantiColors.white,
      backgroundColor: vantiColors.gray1
    },
    "& .fab,& .fas,& .far,& .fal, &.material-icons": {
      position: "relative",
      display: "inline-directory",
      top: "0",
      marginTop: "-1em",
      marginBottom: "-1em",
      fontSize: "1.1rem",
      marginRight: "4px",
      verticalAlign: "middle"
    }
  },
  white: {
    "&:hover": {
      backgroundColor: vantiColors.white,
      color: vantiColors.gray1
    }
  },
  darkBlue: {
    backgroundColor: vantiColors.darkBlue1,
    "&:hover": {
      backgroundColor: vantiColors.darkBlue3
    }
  },
  lightBlue: {
    backgroundColor: vantiColors.lightBlue1,
    "&:hover": {
      backgroundColor: vantiColors.lightBlue3
    }
  },
  gray: {
    backgroundColor: vantiColors.gray1,
    "&:hover": {
      backgroundColor: vantiColors.gray3
    }
  },
  lightGrayBlue: {
    backgroundColor: vantiColors.lightGrayBlue1,
    "&:hover": {
      backgroundColor: vantiColors.lightGrayBlue3
    }
  },
  darkGray: {
    backgroundColor: vantiColors.gray15,
    "&:hover": {
      backgroundColor: vantiColors.gray14
    }
  },
  green: {
    backgroundColor: vantiColors.green1,
    "&:hover": {
      backgroundColor: vantiColors.lightGreen1
    }
  },
  turquoise: {
    backgroundColor: vantiColors.turquoise1,
    "&:hover": {
      backgroundColor: vantiColors.turquoise3
    }
  },
  danger: {
    backgroundColor: vantiColors.danger1,
    "&:hover": {
      backgroundColor: vantiColors.danger3
    }
  },
  simple: {
    "&,&:focus,&:hover": {
      color: vantiColors.white,
      background: "transparent",
      boxShadow: "none"
    },
    "&dark": {
      "&,&:focus,&:hover,&:visited": {
        color: vantiColors.darkBlue3
      }
    },
    "&green": {
      "&,&:focus,&:hover,&:visited": {
        color: vantiColors.green3
      }
    },
    "&turquoise": {
      "&,&:focus,&:hover,&:visited": {
        color: vantiColors.turquoise3
      }
    }
  },
  transparent: {
    "&,&:focus,&:hover": {
      color: "inherit",
      background: "transparent",
      boxShadow: "none"
    }
  },
  disabled: {
    pointerEvents: "none",
    backgroundColor: "#DDDEDF"
  },
  lg: {
    padding: "1.125rem 2.25rem",
    fontSize: "0.875rem",
    lineHeight: "1.333333"
  },
  sm: {
    padding: "0.40625rem 1.25rem",
    fontSize: "0.6875rem",
    lineHeight: "1.5"
  },
  xs: {
    padding: "0.2rem 0.75rem",
    fontSize: "0.6875rem",
    lineHeight: "1.5"
  },
  round: {
    borderRadius: "30px"
  },
  block: {
    width: "100% !important"
  },
  link: {
    "&,&:hover,&:focus": {
      backgroundColor: "transparent",
      color: vantiColors.gray1,
      boxShadow: "none"
    }
  }
};

export default buttonStyle;
