import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const PinIcon24 = ({ color = vantiColors.darkBlue7, fill = "none", ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1274_5547)">
        <path
          d="M15.0501 10.799L17.3395 11.6322L17.8012 12.6223L13.7647 14.5045L16.0731 19.455L15.8089 20.1809L15.083 19.9167L12.7746 14.9662L8.73808 16.8485L8.2764 15.8584L9.10964 13.5691L5.68252 6.21957L4.69243 6.68125L4.23075 5.69116L12.1514 1.99769L12.6131 2.98778L11.623 3.44946L15.0501 10.799ZM9.68917 15.1996L15.9267 12.291L14.2572 11.6833L10.6329 3.91115L6.6726 5.75788L10.2968 13.5301L9.68917 15.1996Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default memo(PinIcon24);
