export const StepsDataMap = Object.freeze({
  "Step 1": { step: "Step 1", title: "Data uploading", time: "1-2", imitateTime: 1 },
  "Step 2": { step: "Step 2", title: "Prepare training machine", time: "5-7", imitateTime: 2 },
  "Step 3": { step: "Step 3", title: "Data validation", time: "1-2", imitateTime: 1 },
  "Step 4": { step: "Step 4", title: "Model pre-processing", time: "1-2", imitateTime: 1 },
  "Step 5": { step: "Step 5", title: "Parallel model training", time: "4-5", imitateTime: 2 },
  "Step 6": { step: "Step 6", title: "Model is ready", time: "10-20" }
});

// map where key is step name and value is sum time value from 1 step to current
export const stepsTimeTrainingSumMap = Object.values(StepsDataMap).reduce((res, item, index, arr) => {
  if (item.imitateTime) {
    const array = arr.slice(0, index + 1);
    const timeSum = array.reduce((sum, step) => {
      return sum + step.imitateTime;
    }, 0);
    res[item.step] = timeSum;
    return res;
  }
  return res;
}, {});
