export const actionTypes = {
  FETCH_USER_REQUEST: "user.fetch.request",
  FETCH_USER_SUCCESS: "user.fetch.success",
  FETCH_USER_ERROR: "user.fetch.error",
  FETCH_USER_FULFILL: "user.fetch.fulfill"
};

export function fetchUserRequestAction() {
  return { type: actionTypes.FETCH_USER_REQUEST };
}
