import { createContext, useMemo, useState } from "react";

const defaultValue = {
  accountUserNames: [],
  setAccountUserNames: () => {}
};

export const UsersContext = createContext({ ...defaultValue });

export const useUsersContext = () => {
  const [accountUserNames, setAccountUserNames] = useState(defaultValue.accountUserNames);

  return useMemo(
    () => ({
      setAccountUserNames,
      accountUserNames
    }),
    [accountUserNames]
  );
};
