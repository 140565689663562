import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  createdWrapper: {
    maxWidth: "100%"
  },
  creatorDisplayName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "pre",
    maxWidth: "180px !important",
    display: "block"
  },
  trashTooltip: {
    zIndex: 2
  },
  toolTip: { width: "fit-content" },
  notAllowedCursor: {
    cursor: "not-allowed"
  },
  delete: {
    whiteSpace: "unset",
    opacity: "0",
    transition: "opacity .25s ease-in-out",
    backgroundColor: "transparent !important",
    "&:hover": {
      opacity: "1"
    }
  }
});

export default useStyles;
