import React, { memo, useState } from "react";
import { styled } from "@material-ui/core";
import { FlexColumn, FlexRow } from "components/ui/Flex/FlexContainer";
import { Text } from "components/ui";
import ChatMessageUserAvatar from "pages/side-bar/chat/components/chat-message/ChatMessageUserAvatar";
import { elapsedTimePretty } from "utils/utils";
import { vantiColors } from "assets/jss/palette";
import TrashIcon16 from "assets/icons/trash-icon/TrashIcon16";
import PencilIcon16In24 from "assets/icons/pencil-icon/PencilIcon16In24";
import Divider from "@material-ui/core/Divider";
import Spacer from "components/ui/Spacer";
import { ActivitySectionInput } from "./ActivitySection";
import CheckMarkIcon from "assets/icons/checkmark/CheckMarkIcon";
import CloseIcon16 from "assets/icons/close-icon/CloseIcon16";
import useDataEntityRequest from "common/hooks/use-data-entity-request";

const ActivityCard = ({ activity, avatarBgColor }) => {
  const [isHovered, setIsHovered] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [activityText, setActivityText] = useState(activity?.text || "");
  const [newActivityText, setNewActivityText] = useState(activity?.text || "");
  const { updateActivityRequest, deleteActivityRequest } = useDataEntityRequest();

  const onSaveEdit = () => {
    setIsEditing(false);
    setActivityText(newActivityText);
    updateActivityRequest.mutate({
      dataEntityId: activity.dataEntityId,
      activityId: activity._id,
      text: newActivityText
    });
  };

  const onCancelEdit = () => {
    setNewActivityText(activityText);
    setIsEditing(false);
  };

  const onDeleteActivity = () => {
    deleteActivityRequest.mutate({
      dataEntityId: activity.dataEntityId,
      activityId: activity._id
    });
    setIsEditing(false);
  };

  return (
    <ActivityCardContainer
      onClick={() => {}}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isHovered={isHovered}
    >
      <ActivityCardHeader>
        <div style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center", gap: 6 }}>
          <ChatMessageUserAvatar backgroundColor={avatarBgColor} displayName={activity.user?.name} />
          <Text weight={500} lineSize={"normal"}>
            {activity.user?.name}
          </Text>
        </div>
        {isHovered ? (
          <ActivityCardActionsBar>
            <ClickableWrapperWithHoveredClipPath onClick={() => setIsEditing(true)} onBlur={() => setIsEditing(false)}>
              <PencilIcon16In24 stroke={vantiColors.gray29} />
            </ClickableWrapperWithHoveredClipPath>
            <ClickableWrapperWithHoveredClipPath onClick={onDeleteActivity}>
              <TrashIcon16 stroke={vantiColors.gray29} />
            </ClickableWrapperWithHoveredClipPath>
          </ActivityCardActionsBar>
        ) : (
          <Text size={12} lineSize={"normal"} style={{ alignSelf: "center" }}>
            {elapsedTimePretty(activity.createdAt)}
          </Text>
        )}
      </ActivityCardHeader>
      <Text lineSize={"normal"} style={{ whiteSpace: "pre-wrap" }}>
        {activityText}
      </Text>
      {isEditing && (
        <FlexColumn style={{ height: "unset", transition: "0.3s all ease-in-out" }}>
          <Spacer space={8} />
          <Divider />
          <Spacer space={8} />
          <FlexRow style={{ height: "unset", flex: "0", position: "relative" }}>
            <ActivitySectionInput
              type={"text"}
              value={newActivityText}
              multiline
              maxRows={4}
              variant={"outlined"}
              onChange={event => setNewActivityText(event.target.value)}
              onKeyDown={e => {
                if (!e.shiftKey && e.key === "Enter") {
                  onSaveEdit();
                  e.preventDefault();
                }
                if (e.key === "Escape") {
                  onCancelEdit();
                  e.preventDefault();
                }
              }}
            />
            <EditActionsButtonGroup>
              <ClickableWrapperWithHoveredClipPath>
                <CloseIcon16 color={vantiColors.gray29} onClick={onCancelEdit} />
              </ClickableWrapperWithHoveredClipPath>
              <ClickableWrapperWithHoveredClipPath>
                <CheckMarkIcon width={16} height={16} color={vantiColors.gray29} onClick={onSaveEdit} />
              </ClickableWrapperWithHoveredClipPath>
            </EditActionsButtonGroup>
          </FlexRow>
        </FlexColumn>
      )}
    </ActivityCardContainer>
  );
};

const ActivityCardContainer = styled(FlexColumn)({
  width: "100%",
  height: "fit-content",
  justifyContent: "flex-start",
  borderRadius: 4,
  backgroundColor: vantiColors.gray18,
  padding: 16,
  boxSizing: "border-box",
  gap: 8,
  cursor: "pointer",
  border: `1px solid ${vantiColors.gray18}`,
  "&:hover": {
    border: `1px solid ${vantiColors.gray38}`
  },
  transition: "all ease-in-out 0.2s"
});

const ActivityCardHeader = styled(FlexRow)({
  height: "fit-content"
});

const ActivityCardActionsBar = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  gap: 4
});

export const ClickableWrapperWithHoveredClipPath = styled("div")(
  ({ bgColor = vantiColors.gray36, borderColor = "transparent" }) => ({
    display: "flex",
    backgroundColor: "transparent",
    transition: "0.3s all ease-in-out",
    boxSizing: "border-box",
    clipPath: "circle(50%)",
    border: "1px solid transparent",
    borderRadius: 100,
    "&:hover": {
      border: `1px solid ${borderColor}`,
      borderRadius: 100,
      backgroundColor: bgColor
    }
  })
);

const EditActionsButtonGroup = styled(FlexRow)({
  width: "fit-content",
  height: "fit-content",
  position: "absolute",
  bottom: 20,
  right: 8,
  gap: 8,
  alignItems: "center"
});

export default memo(ActivityCard);
