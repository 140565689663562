import { createSelector } from "reselect";

export const userSelector = state => state.user?.state;

export const userFirstNameSelector = createSelector(
  userSelector,
  user => user.firstName
);
export const userLastNameSelector = createSelector(
  userSelector,
  user => user.lastName
);
export const userDisplayNameSelector = createSelector(
  userSelector,
  user => user.displayName
);
export const userEmailSelector = createSelector(
  userSelector,
  user => user.email
);
export const userPermissionsSelector = createSelector(
  userSelector,
  user => user.permissions
);
export const userPhoneNumberSelector = createSelector(
  userSelector,
  user => user.phoneNumber
);
export const totalStationsOpenedSelector = createSelector(
  userSelector,
  user => user?.totalStationsOpened
);
export const rolesSelector = createSelector(
  userSelector,
  user => user?.roles
);
export const profileDataSelector = createSelector(
  userSelector,
  user => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user.phoneNumber,
    role: user.role
  })
);
export const userRolesLoadingSelector = createSelector(
  userSelector,
  user => user?.loadingRoles
);
