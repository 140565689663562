/* eslint-disable max-lines */
import { call, put, select, takeEvery } from "redux-saga/effects";
import { createSelector } from "reselect";

import { getMockData, REPORT_HOURLY_FETCH } from "pages/dashboard/block/blockReports/live-data-graph/mock-data/index";
import { checkIfShouldUpdateFullstoryWithBlockState, getActiveModelFromStation } from "./utils";
import { DEFAULT_SORT_FIELD } from "pages/dashboard/station/sections/stations-dashboard-table/station-table/StationTable";

import * as stationService from "services/api/station";

import { actionTypes } from "modules/onboarding/actionTypes";
import { actionTypes as accountStateActionTypes } from "modules/account/state/actions";

import config from "common/config";
import { logger } from "utils/logger";

import { getConfFromBlock, getFormattedDateForDisplay } from "../../utils/utils";
import { BLOCK_DB_STATES } from "common/constants/BlockConstants";

// -------------------------
// -------- ACTIONS --------
// -------------------------

export const stationQueryActionTypes = {
  STATION_QUERY_CLEAR: "station.query.clear",
  STATION_QUERY_REQUEST: "station.query.request",
  STATION_QUERY_SUCCESS: "station.query.success",
  STATION_QUERY_ERROR: "station.query.error",
  STATION_QUERY_FULFILL: "station.query.fulfill",
  ADD_STATION_TO_STATE: "add.station.to.state",
  ADD_BLOCK_TO_STATE: "add.block.to.state",
  DELETE_STATION_FROM_STATE: "delete.station.from.state",
  DELETE_BLOCK_FROM_STATE: "delete.block.from.state",
  STATION_DISSMIS: "station.dismiss",
  STATION_DISSMIS_SUCCESS: "station.dismiss.success",
  STATION_EDIT_PRODUCT_SUCCESS_STATIONS: "station.edit.produc.success.stations",
  STATION_ADD_PRODUCT_SUCCESS: "station.add.product.success",
  STATION_PRODUCT_LOADING_STOP: "station.product.loading.stop",
  STATION_PRODUCT_LOADING_START: "station.product.loading.start",
  STATION_EDIT_LINE: "station.edit.line",
  STATION_EDIT_LINES_SUCCESS: "station.edit.line.success",
  STATION_ASSIGN_LINE: "station.assign.line",
  STATION_DELETE_LINE: "station.delete.line",
  STATION_CREATE_LINE: "station.create.line",
  STATION_ADD_LINE_SUCCESS: "station.addLine.success",
  STATION_LINE_LOADING_STOP: "station.line.loading.stop",
  STATION_LINE_LOADING_START: "station.line.loading.start",
  STATION_ADD_LINE_ERROR: "station.add.line.error",
  STATION_ASSIGN_LINE_ERROR: "station.assign.line.error",
  STATION_DELETE_LINE_ERROR: "station.delete.line.error",
  STATION_EDIT_LINE_ERROR: "station.edit.line.error",
  STATION_UPDATE_BLOCK_STATUS: "station.set.block.status",
  STATION_SET_STATIONS: "station.set.stations",
  STATION_UPDATE_STATUS: "station.update.status",
  STATION_UPDATE: "station.update",
  STATION_CLEAR_ERROR: "station.clear.error"
};

export function stationUpdateStatus(payload) {
  return { type: stationQueryActionTypes.STATION_UPDATE_BLOCK_STATUS, payload };
}

export function stationUpdateStatusAction(stationId, blockState) {
  return { type: stationQueryActionTypes.STATION_UPDATE_STATUS, payload: { stationId, blockState } };
}

export function stationQueryClearAction() {
  return { type: stationQueryActionTypes.STATION_QUERY_CLEAR, payload: {} };
}

export function stationLineEditAction({ accountId, newValue, stationIdList }) {
  return { type: stationQueryActionTypes.STATION_EDIT_LINE, payload: { accountId, newValue, stationIdList } };
}

export function createLineAction({ accountId, newLineValue, stationIdList }) {
  return { type: stationQueryActionTypes.STATION_CREATE_LINE, payload: { accountId, newLineValue, stationIdList } };
}

export function deleteLineAction({ accountId, lineId }) {
  return { type: stationQueryActionTypes.STATION_DELETE_LINE, payload: { accountId, lineId } };
}

export function stationLineAssignAction({ isChecked, accountId, stationId, line }) {
  return {
    type: stationQueryActionTypes.STATION_ASSIGN_LINE,
    payload: { isChecked, accountId, stationId, line }
  };
}

export function getStationQueryActions(stationId) {
  return { type: stationQueryActionTypes.STATION_QUERY_REQUEST, payload: { stationId } };
}

export function getAllAccountStationsQueryActions() {
  return { type: stationQueryActionTypes.STATION_QUERY_REQUEST, payload: {} };
}

export function dismissStationActions(stationId) {
  return { type: stationQueryActionTypes.STATION_DISSMIS, payload: { stationId } };
}

export function clearStationErrorAction(payload) {
  return { type: stationQueryActionTypes.STATION_CLEAR_ERROR, payload };
}

// -------------------------
// -------- REDUCER --------
// -------------------------

const defaultState = {
  stations: {},
  deletedStationIds: [],
  loading: null,
  success: null,
  error: null,
  productLoading: false,
  lineLoading: false
};

function parseStation(stationItem) {
  const today = new Date();
  const stationCreationDate = new Date(stationItem.createdAt);
  const diffTime = Math.abs(today - stationCreationDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const totalPredictions = () => {
    if (stationItem.isDummy) {
      const mockData = getMockData(stationCreationDate);
      return mockData[REPORT_HOURLY_FETCH.PREDICT].length;
    }
    return stationItem.totalPredictions;
  };

  const avgStationPredictionsPerDay = () => {
    const avegareBetweenZeroAndOne = 1;
    if (stationItem.isDummy) {
      const avgPredictionsPerDay = totalPredictions() / diffDays;
      if (avgPredictionsPerDay > 0 && avgPredictionsPerDay < 1) return avegareBetweenZeroAndOne;
      return avgPredictionsPerDay;
    }
    return stationItem.totalPredictions / diffDays;
  };

  const lastPredictTimestamp = () => {
    if (stationItem.isDummy) {
      const mockData = getMockData(stationCreationDate);
      const predict = mockData[REPORT_HOURLY_FETCH.PREDICT];
      if (predict.length) {
        return predict[predict.length - 1].date;
      }
    }
    return stationItem.lastPredictTimestamp;
  };

  const convertedDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
  convertedDate.setUTCSeconds(lastPredictTimestamp() / 1000);

  return {
    id: stationItem.id,
    blocks: stationItem.blocks,
    name: stationItem.name,
    description: stationItem.description,
    type: stationItem.type,
    goal: stationItem.goal,
    goalByUsecase: stationItem.goalByUsecase,
    blockState: stationItem.blockState,
    status: stationItem.state,
    error: stationItem.error,
    timestampLastPredict: stationItem.lastPredictTimestamp,
    lastPredictTimestamp: lastPredictTimestamp() ? getFormattedDateForDisplay(convertedDate) : "-",
    totalPredictions: totalPredictions() || 0,
    avgStationPredictionsPerDay: avgStationPredictionsPerDay().toFixed(0),
    deploymentErrorCount: stationItem.deploymentErrorCount,
    trainingErrorCount: stationItem.trainingErrorCount,
    runtimeErrorCount: stationItem.runtimeErrorCount,
    updatedAt: new Date(stationItem.updatedAt),
    createdAt: new Date(stationItem.createdAt),
    creatorDisplayName: stationItem.creatorDisplayName,
    token: stationItem.token,
    account: stationItem.account,
    apiEnabled: stationItem.apiEnabled,
    numOfModels: stationItem.numOfBlocks,
    isDummy: stationItem.isDummy,
    products: stationItem.products,
    lines: stationItem.lines,
    lineList: stationItem.lineList,
    activeModel: stationItem.activeModel || getActiveModelFromStation(stationItem),
    kpiFailureValues: stationItem.kpiFailureValues,
    activeModelId: stationItem.activeModelId || null,
    deletedBlocks: stationItem.deletedBlocks || null
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case stationQueryActionTypes.STATION_QUERY_CLEAR: {
      return defaultState;
    }
    case stationQueryActionTypes.STATION_EDIT_PRODUCT_SUCCESS_STATIONS: {
      return { ...state, stations: action.payload };
    }
    case stationQueryActionTypes.STATION_ADD_PRODUCT_SUCCESS: {
      const stations = { ...state.stations };
      stations[action.payload.data.id] = action.payload.data;
      return { ...state, stations };
    }
    case stationQueryActionTypes.STATION_PRODUCT_LOADING_STOP: {
      return { ...state, productLoading: false };
    }
    case stationQueryActionTypes.STATION_PRODUCT_LOADING_START: {
      return { ...state, productLoading: true };
    }
    case stationQueryActionTypes.STATION_LINE_LOADING_STOP: {
      return {
        ...state,
        lineLoading: false,
        deleteLineError: null,
        addLineError: null,
        editLineError: null,
        assignLineError: null
      };
    }
    case stationQueryActionTypes.STATION_LINE_LOADING_START: {
      return { ...state, lineLoading: true };
    }
    case stationQueryActionTypes.STATION_DELETE_LINE_ERROR: {
      return { ...state, deleteLineError: action.payload };
    }
    case stationQueryActionTypes.STATION_EDIT_LINE_ERROR: {
      return { ...state, editLineError: action.payload };
    }
    case stationQueryActionTypes.STATION_ASSIGN_LINE_ERROR: {
      return { ...state, assignLineError: action.payload };
    }
    case stationQueryActionTypes.STATION_ADD_LINE_ERROR: {
      return { ...state, addLineError: action.payload };
    }
    case stationQueryActionTypes.STATION_ADD_LINE_SUCCESS: {
      const stations = { ...state.stations };
      stations[action.payload.data.id] = action.payload.data;
      return { ...state, stations };
    }
    case stationQueryActionTypes.STATION_SET_STATIONS: {
      return { ...state, stations: action.payload };
    }
    case stationQueryActionTypes.STATION_QUERY_REQUEST: {
      return { ...state, loading: true };
    }
    case stationQueryActionTypes.STATION_DISSMIS_SUCCESS: {
      const { stationId } = action.payload;

      const stations = { ...state.stations };
      const station = stations[stationId];
      if (station) {
        const blocks = Object.keys(station.blocks || {}).map(blockId => ({
          ...station.blocks[blockId],
          isDissmised: true
        }));
        const blocksMap = blocks.reduce((res, item) => {
          res[item.id] = item;
          return res;
        }, {});
        station.blocks = blocksMap;

        return { ...state, stations };
      }

      return state;
    }
    case stationQueryActionTypes.STATION_QUERY_SUCCESS: {
      const { stations = [], station } = action.payload;
      let stationsObj = state.stations || {};

      if (station) {
        stations.push(station);
      }

      for (const stationItem of stations) {
        stationsObj[stationItem.id] = parseStation(stationItem);
        stationsObj[stationItem.id].activeModel = getActiveModelFromStation(stationItem);
      }

      return { ...state, success: true, stations: stationsObj, loading: false };
    }
    case stationQueryActionTypes.STATION_QUERY_ERROR: {
      return { ...state, success: false, error: action.payload.error };
    }
    case stationQueryActionTypes.STATION_QUERY_FULFILL: {
      return { ...state };
    }
    case stationQueryActionTypes.ADD_STATION_TO_STATE: {
      try {
        const stations = state.stations ? { ...state.stations } : {};

        const station = { ...action.payload.station };
        const oldStation = stations[station.id];

        if (oldStation?.blocks && !station.blocks) {
          station.blocks = oldStation.blocks;
        }

        const newStation = parseStation(station);

        if (state.deletedStationIds.includes(newStation.id)) {
          return { ...state };
        }

        if (oldStation && oldStation.updatedAt > newStation.updatedAt) {
          // this update is obsolete
          return { ...state };
        }

        stations[newStation.id] = newStation;
        return { ...state, stations };
      } catch (error) {
        logger.error("Error updating state: ", error);
      }
      return { ...state };
    }
    case stationQueryActionTypes.ADD_BLOCK_TO_STATE: {
      const { stationId, block } = action.payload;
      const newBlock = parseRawBlock(stationId, block);

      const stations = state.stations;

      if (!stations || !stations[stationId]?.blocks) {
        return state;
      }
      const station = stations[stationId];
      const oldBlock = station.blocks[block.id];

      checkIfShouldUpdateFullstoryWithBlockState(oldBlock, newBlock, station.account);

      if (oldBlock && oldBlock.updatedAt > newBlock.updatedAt) {
        // this update is obsolete
        return state;
      }
      if (oldBlock?.clusteringReport?.dynamicPlot && newBlock.clusteringReport) {
        newBlock.clusteringReport.dynamicPlot = oldBlock.clusteringReport.dynamicPlot;
      }
      station.blocks[block.id] = newBlock;

      //trigger effects that rely on station as dependency
      stations[stationId] = { ...station };

      return { ...state, stations };
    }
    case stationQueryActionTypes.DELETE_STATION_FROM_STATE: {
      const { stationId } = action.payload;
      const stations = { ...state.stations };

      if (stations[stationId]) {
        delete stations[stationId];
      }

      const deletedStationIds = [...state.deletedStationIds];

      deletedStationIds.push(stationId);

      return { ...state, stations, deletedStationIds };
    }
    case stationQueryActionTypes.DELETE_BLOCK_FROM_STATE: {
      const { stationId, blockId } = action.payload;
      const stations = state.stations;
      if (stations[stationId]?.blocks[blockId]) {
        delete stations[stationId].blocks[blockId];
      }
      return { ...state, stations };
    }
    case stationQueryActionTypes.STATION_UPDATE: {
      const { station } = action.payload;

      return {
        ...state,
        stations: {
          ...state.stations,
          [station.id]: station
        }
      };
    }
    case stationQueryActionTypes.STATION_CLEAR_ERROR: {
      const { stationId, blockId } = action.payload;

      const stations = { ...state.stations };

      if (stations[stationId]) {
        const newStation = {
          ...stations[stationId]
        };

        newStation.error.dismissedAt = new Date();
        stations[stationId] = newStation;
      }

      if (blockId && stations[stationId]?.blocks && stations[stationId].blocks[blockId]) {
        const newBlock = { ...stations[stationId].blocks[blockId] };
        newBlock.error.dismissedAt = new Date();

        stations[stationId].blocks[blockId] = newBlock;
      }

      return {
        ...state,
        stations
      };
    }

    default: {
      return { ...state };
    }
  }
}

// ---------------------------
// -------- SELECTORS --------
// ---------------------------

export const stationQuerySelector = state => {
  return state.station?.query;
};

export const singleStationSelector = stationId => {
  return createSelector(
    stationQuerySelector,
    state => {
      return state.stations[stationId];
    }
  );
};

export const stationProductsSelector = stationId => {
  return createSelector(
    stationQuerySelector,
    state => state?.stations[stationId]?.products || []
  );
};

export const stationLinesSelector = stationId => {
  return createSelector(
    stationQuerySelector,
    state => state?.stations[stationId]?.lineList || []
  );
};

export const stationsSelector = createSelector(
  stationQuerySelector,
  state => Object.values(state.stations)
);

export const stationQueryLoadingSelector = createSelector(
  stationQuerySelector,
  state => state.loading
);

export const stationQuerySuccessSelector = createSelector(
  stationQuerySelector,
  state => state.success
);

export const stationActiveModelSelector = stationId => {
  return createSelector(
    stationQuerySelector,
    state => state.stations[stationId].activeModel
  );
};

// ---------------------------
// ---------- SAGAS ----------
// ---------------------------

function* stationQuerySaga(action) {
  const existingStations = yield select(state => state.station.query.stations) || {};
  const products = yield select(state => state.account.state.products);
  const lines = yield select(state => state.account.state.lines);

  const productsMap = (products || []).reduce((res, item) => {
    res[item._id] = item;
    return res;
  }, {});

  const linesMap = (lines || []).reduce((res, item) => {
    res[item._id] = item;
    return res;
  }, {});

  try {
    const { stationId } = action.payload;
    const payload = {};
    if (stationId) {
      const station = yield call(stationService.getStation, stationId);
      const blocks = yield call(stationService.getAllStationBlocks, stationId, true);
      const blocksObj = {};
      blocks.forEach(block => {
        blocksObj[block.id] = block;
      });
      station.blocks = blocksObj;
      station.products = (station.products || []).map(productId => {
        if (typeof productId === "string") {
          return productsMap[productId] || {};
        }

        return productId;
      });

      station.lineList = station.lines;
      station.lines = (station.lines || []).map(lineId => {
        if (typeof lineId === "string") {
          return linesMap[lineId] || {};
        }

        return lineId;
      });
      payload.station = station;
    }

    if (!Object.keys(existingStations).length) {
      const stations = yield call(stationService.getAllAccountStations);
      stations.forEach(station => {
        station.products = (station.products || []).map(productId => {
          if (typeof productId === "string") {
            return productsMap[productId] || {};
          }
          return productId;
        });
      });
      stations.forEach(station => {
        station.lineList = station.lines;
        station.lines = (station.lines || []).map(lineId => {
          if (typeof lineId === "string") {
            return linesMap[lineId] || {};
          }
          return lineId;
        });
      });
      payload.stations = stations.sort((a, b) => b[DEFAULT_SORT_FIELD].localeCompare(a[DEFAULT_SORT_FIELD]));
    }

    yield put({ type: stationQueryActionTypes.STATION_QUERY_SUCCESS, payload });
  } catch (error) {
    yield put({ type: stationQueryActionTypes.STATION_QUERY_ERROR, payload: { error } });
  } finally {
    yield put({ type: stationQueryActionTypes.STATION_QUERY_FULFILL, payload: {} });
  }
}

function* stationEditLineSaga(action) {
  try {
    const { accountId, newValue, stationIdList } = action.payload;
    const lines = yield select(state => state.account.state.lines);
    const stations = yield select(state => state.station.query.stations);
    const onboardingStation = yield select(state => state.onboarding.state.station);
    yield put({ type: stationQueryActionTypes.STATION_LINE_LOADING_START });

    const { line, stationsChanged } = yield stationService.editStationLines(accountId, newValue, stationIdList);

    const lineIndex = lines.findIndex(lineData => lineData._id === line._id);

    if (lineIndex > -1) {
      const updatedLines = [...lines];
      updatedLines[lineIndex] = line;
      yield put({ type: accountStateActionTypes.ACCOUNT_SET_LINES, payload: updatedLines });
    }

    const updatedStations = { ...stations };
    const updatedProductStations = {};

    Object.keys(updatedStations).forEach(stationId => {
      const stationChanged = stationsChanged.find(stationChanged => stationChanged.stationId === stationId);
      if (stationChanged) {
        if (stationChanged.isAssigned) {
          updatedStations[stationId].lineList = [line._id];
        } else {
          updatedStations[stationId].lineList = [];
        }
      }

      const lineIndex = updatedStations[stationId].lines.findIndex(lineData => lineData._id === line._id);

      if (lineIndex > -1) {
        const updatedLines = [...updatedStations[stationId].lines];
        updatedLines[lineIndex] = line;
        updatedProductStations[stationId] = { ...updatedStations[stationId], lines: updatedLines };
      }
    });

    Object.keys(updatedProductStations).forEach(stationId => {
      updatedStations[stationId] = updatedProductStations[stationId];
    });

    const onboardingLineIndex = onboardingStation.lines.findIndex(lineData => lineData._id === line._id);

    if (onboardingLineIndex > -1) {
      const updatedLines = [...onboardingStation.lines];
      updatedLines[onboardingLineIndex] = line;
      yield put({ type: actionTypes.ONBOARDING_EDIT_STATION_LINE_SUCCESS, payload: updatedLines });
    }

    yield put({ type: stationQueryActionTypes.STATION_EDIT_PRODUCT_SUCCESS_STATIONS, payload: updatedStations });
  } catch (error) {
    yield put({ type: stationQueryActionTypes.STATION_EDIT_LINE_ERROR, payload: error });
  } finally {
    yield put({ type: stationQueryActionTypes.STATION_LINE_LOADING_STOP });
  }
}

function* stationAssignLineSaga(action) {
  try {
    const { isChecked, accountId, stationId, line } = action.payload;
    yield put({ type: stationQueryActionTypes.STATION_LINE_LOADING_START });

    if (stationId) {
      yield stationService.assignStationLines(isChecked, accountId, stationId, line);
    }

    if (isChecked) {
      yield put({ type: actionTypes.ONBOARDING_SET_STATION_LINES, payload: line });
    } else {
      yield put({ type: actionTypes.ONBOARDING_UNSET_STATION_LINE, payload: line });
    }
  } catch (error) {
    yield put({ type: stationQueryActionTypes.STATION_LINE_LOADING_STOP });
  } finally {
    yield put({ type: stationQueryActionTypes.STATION_LINE_LOADING_STOP });
  }
}

function* stationDeleteLineSaga(action) {
  try {
    const { accountId, lineId } = action.payload;
    yield put({ type: stationQueryActionTypes.STATION_LINE_LOADING_START });

    yield stationService.deleteStationLine(accountId, lineId);

    const stations = yield select(state => state.station.query.stations);

    Object.values(stations).forEach(station => {
      station.lineList = station.lineList.filter(line => line !== lineId);
    });

    yield put({ type: accountStateActionTypes.ACCOUNT_REMOVE_LINE, payload: lineId });
    yield put({ type: stationQueryActionTypes.STATION_EDIT_PRODUCT_SUCCESS_STATIONS, payload: { ...stations } });
  } catch (error) {
    yield put({ type: stationQueryActionTypes.STATION_DELETE_LINE_ERROR, payload: error });
  } finally {
    yield put({ type: stationQueryActionTypes.STATION_LINE_LOADING_STOP });
  }
}

function* stationStatusUpdateSaga(action) {
  const { stationId, blockState } = action.payload;

  const stationBeforeUpdate = yield select(singleStationSelector(stationId));

  try {
    yield put({
      type: stationQueryActionTypes.STATION_UPDATE,
      payload: {
        station: {
          ...stationBeforeUpdate,
          blockState
        }
      }
    });

    yield call(stationService.updateStationBlockStatus, stationId, blockState);
  } catch (error) {
    yield put({ type: stationQueryActionTypes.STATION_UPDATE, payload: { station: stationBeforeUpdate } });
  }
}

export function* stationDismissSaga(action) {
  try {
    const { stationId } = action.payload;
    yield call(stationService.dismissStation, stationId);
    yield put({ type: stationQueryActionTypes.STATION_DISSMIS_SUCCESS, payload: action.payload });
  } catch (error) {
    logger.error(error);
  }
}

function* createLine(action) {
  try {
    const { newLineValue, accountId, stationIdList } = action.payload;
    const stations = yield select(state => state.station.query.stations);
    yield put({ type: stationQueryActionTypes.STATION_LINE_LOADING_START });

    const line = yield stationService.addLineToStations(accountId, stationIdList, newLineValue);

    yield put({ type: accountStateActionTypes.ACCOUNT_ADD_LINE, payload: { data: line } });

    if (!stationIdList) {
      yield put({ type: actionTypes.ONBOARDING_SET_STATION_LINES, payload: line });
      yield put({ type: stationQueryActionTypes.STATION_LINE_LOADING_STOP });

      return;
    }

    for (const stationId of stationIdList) {
      const updatedStation = yield {
        ...stations[stationId],
        lines: [line],
        lineList: [line._id]
      };
      yield put({ type: stationQueryActionTypes.STATION_ADD_LINE_SUCCESS, payload: { data: updatedStation } });
    }
  } catch (error) {
    yield put({ type: stationQueryActionTypes.STATION_ADD_LINE_ERROR, payload: error });
  } finally {
    yield put({ type: stationQueryActionTypes.STATION_LINE_LOADING_STOP });
  }
}

function* stationUpdateBlockStatusSaga(action) {
  const { stationId, blockId, state } = action.payload;
  const stations = yield select(state => state.station.query.stations);
  let updatedStation = { ...stations[stationId] };
  const stationBlocks = { ...(updatedStation.blocks || {}) };

  if (stationBlocks[blockId]) {
    stationBlocks[blockId] = { ...stationBlocks[blockId], state };

    if (state === BLOCK_DB_STATES.ACTIVE) {
      //deactivate current active model
      if (stations[stationId].activeModel) {
        const oldActiveBlock = stationBlocks[stations[stationId].activeModel.id];
        stationBlocks[stations[stationId].activeModel.id] = { ...oldActiveBlock, state: BLOCK_DB_STATES.READY };
      }
      //update the activeModel in station
      updatedStation = { ...stations[stationId], activeModel: stationBlocks[blockId], activeModelId: blockId };
    }

    updatedStation.blocks = stationBlocks;
    yield put({
      type: stationQueryActionTypes.STATION_SET_STATIONS,
      payload: {
        ...stations,
        [stationId]: updatedStation
      }
    });
  }
}

export function parseRawBlock(stationId, data) {
  const { metadata = {}, results = {}, files = {} } = data;
  const _files = Object.values(files);
  // TODO: refactor _results
  let _results = Object.fromEntries(
    Object.entries(results).map(([key, { id, data }]) => [
      key,
      {
        id: id,
        data: typeof data === "string" ? JSON.parse(data) : data // This is here for migration
      }
    ])
  );
  // Prevent cases where block.results exists as an empty object {}.
  _results = !_results || Object.keys(_results).length === 0 ? null : _results;

  const getFailureKpiValues = kpiLabelsMap => {
    if (!kpiLabelsMap) {
      return [];
    }

    const failedEntries = kpiLabelsMap.filter(label => Object.values(label)[0] === "FAIL");
    return Object.keys(failedEntries);
  };

  const kpiFailureValues = getFailureKpiValues(Object.values(data.files).at(0)?.kpiLabels);

  return {
    id: data.id,
    stationId: stationId,
    creatorDisplayName: data.creatorDisplayName,
    type: data.type,
    state: data.state,
    refBlockId: data.refBlockId,
    rootBlockId: data.rootBlockId,
    title: data.title,
    description: data.description,
    deployStartedAt: data.deployStartedAt,
    executionName: data.executionName,
    updatedAt: new Date(data.updatedAt),
    createdAt: new Date(data.createdAt),
    creatorId: data.creatorId,
    isDummy: data.isDummy,
    kpiFailureValues,
    files: _files.map(file => ({
      id: file.id,
      name: file.name,
      location: file.location,
      fileType: file.fileType,
      url: file.url,
      kpiIndex: file.kpiIndex,
      kpiLabels: file.kpiLabels,
      sheetIndex: file.sheetIndex || 0, // default 0 as this is missing in some blocks
      fileColumns: file.fileColumns,
      seriesIdIndexes: file.seriesIdIndexes,
      columnIdentifierIndexes: file.columnIdentifierIndexes,
      sheets: file.sheets,
      kpiIndexes: file.kpiIndexes,
      originalKpiIndexes: file.originalKpiIndexes,
      numOfClasses: file.numOfClasses,
      numOfTargets: file.numOfTargets,
      clientValidationError: file.clientValidationError,
      timeSeriesIndexes: file.timeSeriesIndexes,
      dataSource: file.dataSource
    })),
    // lastFile:
    batchPredictionsFiles: Object.values(data.batchPredictionsFiles),
    metadata: {
      modelIntent: metadata.modelIntent,
      dataSource: metadata.dataSource
    },
    results: _results,
    classificationReport: data.classificationReport,
    clusteringReport: data.clusteringReport,
    regressionReport: data.regressionReport,
    customReport: data.customReport,
    report: data.classificationReport || data.clusteringReport || data.regressionReport | data.customReport,
    blockInsights: data.blockInsights,

    clusterLabels: data.clusterLabels,

    // Tells us if the block has a report or a manual uploaded result - any of them is considered a result.
    hasAnyResultsToShow:
      !!_results ||
      !!data.classificationReport ||
      !!data.clusteringReport ||
      !!data.regressionReport ||
      !!data.customReport,
    isFetching: false,
    unsupervisedConf: data.unsupervisedConf,
    supervisedConf: data.supervisedConf,
    regressionConf: data.regressionConf,
    conf: getConfFromBlock(data),
    code: data.code ? atob(data.code) : "",
    // Additional fields we keep on the block for the components:
    modelId: `${data.id}-${config.env}`,
    error: data.error,
    isDissmised: data.isDissmised,
    useCase: data.useCase
  };
}

export const singleBlockSelector = (stationId, blockId) => {
  return createSelector(
    stationQuerySelector,
    state => {
      if (!state?.stations) {
        return null;
      }
      const station = stationId
        ? state.stations[stationId]
        : Object.values(state.stations).find(station => station.blocks && station.blocks[blockId]);
      return station?.blocks && station.blocks[blockId];
    }
  );
};

export function* watchStationQuerySaga() {
  yield takeEvery(stationQueryActionTypes.STATION_UPDATE_STATUS, stationStatusUpdateSaga);
  yield takeEvery(stationQueryActionTypes.STATION_DISSMIS, stationDismissSaga);
  yield takeEvery(stationQueryActionTypes.STATION_QUERY_REQUEST, stationQuerySaga);

  yield takeEvery(stationQueryActionTypes.STATION_UPDATE_BLOCK_STATUS, stationUpdateBlockStatusSaga);

  yield takeEvery(stationQueryActionTypes.STATION_ASSIGN_LINE, stationAssignLineSaga);
  yield takeEvery(stationQueryActionTypes.STATION_DELETE_LINE, stationDeleteLineSaga);
  yield takeEvery(stationQueryActionTypes.STATION_EDIT_LINE, stationEditLineSaga);
  yield takeEvery(stationQueryActionTypes.STATION_CREATE_LINE, createLine);
}
