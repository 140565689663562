import React, { memo } from "react";

const PromoteIcon16 = ({ fill = "#71717A", ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 24 24"
      fill="none"
      style={{ alignSelf: "center", cursor: "pointer" }}
      {...rest}
    >
      <path
        d="M22 22.75C22.4142 22.75 22.75 22.4142 22.75 22C22.75 21.5858 22.4142 21.25 22 21.25V22.75ZM4.75 2C4.75 1.58579 4.41421 1.25 4 1.25C3.58579 1.25 3.25 1.58579 3.25 2H4.75ZM22 21.25H5.5V22.75H22V21.25ZM4.75 20.5V2H3.25V20.5H4.75ZM5.5 21.25C5.08579 21.25 4.75 20.9142 4.75 20.5H3.25C3.25 21.7426 4.25736 22.75 5.5 22.75V21.25Z"
        fill={fill}
      />
      <path
        d="M22 12.75C22.4142 12.75 22.75 12.4142 22.75 12C22.75 11.5858 22.4142 11.25 22 11.25V12.75ZM10 11.25C9.58579 11.25 9.25 11.5858 9.25 12C9.25 12.4142 9.58579 12.75 10 12.75V11.25ZM22 11.25H10V12.75H22V11.25Z"
        fill={fill}
      />
      <path
        d="M10 6.5H9.25H10ZM20.5 7.25H11.5V8.75H20.5V7.25ZM10.75 6.5V3.5H9.25V6.5H10.75ZM11.5 2.75H20.5V1.25H11.5V2.75ZM21.25 3.5V6.5H22.75V3.5H21.25ZM20.5 2.75C20.9142 2.75 21.25 3.08579 21.25 3.5H22.75C22.75 2.25736 21.7426 1.25 20.5 1.25V2.75ZM10.75 3.5C10.75 3.08579 11.0858 2.75 11.5 2.75V1.25C10.2574 1.25 9.25 2.25736 9.25 3.5H10.75ZM11.5 7.25C11.0858 7.25 10.75 6.91421 10.75 6.5H9.25C9.25 7.74264 10.2574 8.75 11.5 8.75V7.25ZM20.5 8.75C21.7426 8.75 22.75 7.74264 22.75 6.5H21.25C21.25 6.91421 20.9142 7.25 20.5 7.25V8.75Z"
        fill={fill}
      />
      <path
        d="M22 16.75C22.4142 16.75 22.75 16.4142 22.75 16C22.75 15.5858 22.4142 15.25 22 15.25V16.75ZM10 15.25C9.58579 15.25 9.25 15.5858 9.25 16C9.25 16.4142 9.58579 16.75 10 16.75V15.25ZM22 15.25H10V16.75H22V15.25Z"
        fill={fill}
      />
      <path
        d="M4.00002 2L4.46854 1.41435C4.19463 1.19522 3.80542 1.19522 3.5315 1.41435L4.00002 2ZM6.01102 4.56926C6.33447 4.82802 6.80643 4.77558 7.06519 4.45213C7.32395 4.12869 7.27151 3.65672 6.94806 3.39796L6.01102 4.56926ZM1.05199 3.39796C0.72854 3.65672 0.676099 4.12869 0.934856 4.45213C1.19361 4.77558 1.66558 4.82802 1.98903 4.56926L1.05199 3.39796ZM6.94806 3.39796L4.46854 1.41435L3.5315 2.58565L6.01102 4.56926L6.94806 3.39796ZM3.5315 1.41435L1.05199 3.39796L1.98903 4.56926L4.46854 2.58565L3.5315 1.41435Z"
        fill={fill}
      />
    </svg>
  );
};

export default memo(PromoteIcon16);
