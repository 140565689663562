import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  primary: {
    color: theme.palette.vantiColors.blue4,
    fontSize: "16px",
    lineHeight: "36px",
    padding: theme.spacing(0.75, 3),
    minWidth: "115px",
    fontWeight: 500,
    height: "34px",
    backgroundColor: theme.palette.vantiColors.green1,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme.palette.vantiColors.lightGreen1,
      color: theme.palette.vantiColors.blue4
    },
    "&:focused": {
      backgroundColor: theme.palette.vantiColors.lightGreen2,
      color: theme.palette.vantiColors.blue4
    },
    "& > span > span": {
      "& > span": {
        backgroundColor: theme.palette.vantiColors.white
      }
    },
    cursor: "pointer"
  },
  disabled: {
    backgroundColor: `${theme.palette.vantiColors.lightGray1} !important`,
    color: `${theme.palette.vantiColors.darkBlue4} !important`
  },
  withIcon: {
    "& svg": {
      marginRight: theme.spacing(1)
    },
    padding: theme.spacing(0, 2.5)
  }
}));
