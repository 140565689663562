import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  button: {
    border: "1px solid grey",
    padding: theme.spacing(1.25, 2.5),
    height: "48px",
    display: "flex",
    justifyContent: "center"
  },
  icon: {
    marginRight: theme.spacing(1.25),
    width: "32px"
  },
  innerContainer: {
    marginTop: theme.spacing(4)
  },
  subText: {
    marginTop: theme.spacing(2)
  },
  buttonsContainer: {
    marginTop: theme.spacing(6)
  },
  lottieTextContainer: {
    position: "relative"
  },
  lottiePlayer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "150px",
    height: "200px"
  },
  emailDescriptionSection: {
    marginTop: theme.spacing(1)
  }
}));
