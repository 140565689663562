import React, { forwardRef, memo, useCallback, useEffect, useMemo, useState } from "react";
import Button from "./Button";
import Text from "../Typography/Text";
import makeStyles from "@material-ui/styles/makeStyles";
import classNames from "classnames";
import { vantiColors } from "assets/jss/palette";
import RotatingLoadingIcon from "assets/icons/RotatingLoadingIcon";

export const VARIANTS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  TEXT_LINK: "textLink",
  TEXT: "text",
  SELECT: "select"
};

export const SIZES = {
  BIG: "big",
  SMALL: "small",
  CUSTOM: "custom"
};

const styles = theme => ({
  button: {
    borderRadius: 4,
    margin: "0"
  },
  disabled: {
    backgroundColor: `${theme.palette.vantiColors.lightGray1} !important`,
    "& span": {
      "& div": {
        color: `${theme.palette.vantiColors.darkBlue4} !important`
      }
    },
    cursor: "inherit"
  },
  customButtonProps: props => ({
    minWidth: props.minWidth || 200,
    height: props.height || 32,
    borderRadius: props.borderRadius || 4,
    backgroundColor: props.backgroundColor || theme.palette.vantiColors.green1,
    transition: props.transition || "0.1s ease-in-out",
    color: props.color || theme.palette.vantiColors.darkBlue6,
    "&:hover": {
      backgroundColor: props.hoverBackgroundColor || theme.palette.vantiColors.lightGreen1,
      color: props.hoverColor || theme.palette.vantiColors.darkBlue6
    },
    "&:focused": {
      backgroundColor: props.focusedBackgroundColor || theme.palette.vantiColors.lightGreen2
    },
    "&:active": {
      backgroundColor: props.activeBackgroundColor || theme.palette.vantiColors.lightGreen2,
      opacity: props.opacity || "0.5"
    }
  }),
  [VARIANTS.PRIMARY]: {
    backgroundColor: vantiColors.darkBlue7,
    "&:hover": {
      backgroundColor: vantiColors.darkBlue9
    }
  },
  [VARIANTS.SECONDARY]: {
    backgroundColor: vantiColors.lightGray12,
    "&:hover": {
      backgroundColor: vantiColors.lightBlue11
    }
  },
  [VARIANTS.TERTIARY]: {
    backgroundColor: vantiColors.white,
    border: "1px solid #B5B9C2",
    "&:hover": {
      backgroundColor: vantiColors.gray18
    }
  },
  [VARIANTS.TEXT_LINK]: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent"
    },
    color: vantiColors.darkBlue7
  },
  [VARIANTS.TEXT]: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent"
    },
    color: vantiColors.darkBlue7,
    fontWeight: "500"
  },
  [VARIANTS.SELECT]: {
    border: `1px solid ${vantiColors.darkBlue7}`,
    borderRadius: "20px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: vantiColors.lightBlue10
    },
    "&:focus": {
      backgroundColor: vantiColors.lightGray12
    },
    padding: "8px 16px"
  },
  [SIZES.BIG]: {
    padding: "0px 24px",
    height: "40px"
  },
  [SIZES.SMALL]: {
    padding: "0px 24px",
    height: "32px"
  },
  [SIZES.CUSTOM]: {} //derived from inline style on your component
});

const useStyles = makeStyles(styles);

const determineTextColor = variant => {
  switch (variant) {
    case VARIANTS.TERTIARY:
      return vantiColors.darkBlue6;

    case VARIANTS.SECONDARY:
    case VARIANTS.TEXT_LINK:
    case VARIANTS.TEXT:
    case VARIANTS.SELECT:
      return vantiColors.darkBlue7;

    default:
      return vantiColors.white;
  }
};

const determineTextSize = size => {
  switch (size) {
    case SIZES.SMALL:
      return 14;

    default:
      return 16;
  }
};

const determineFontWeight = variant => {
  switch (variant) {
    case VARIANTS.TERTIARY:
    case VARIANTS.SELECT:
      return 400;

    default:
      return 500;
  }
};

const GeneralVantiButton = (
  {
    buttonInfo = {},
    size = "big",
    variant = "primary",
    children,
    customButtonProps,
    className,
    textClassName,
    onClickShowLoader,
    onClickDisable,
    ...props
  },
  ref
) => {
  const { textProps } = props;
  const classes = useStyles(customButtonProps);

  const GeneralVantiBtnClasses = classNames(
    {
      [classes.button]: true,
      [classes.disabled]: buttonInfo.disabled,
      [classes.customButtonProps]: customButtonProps,
      [classes[variant]]: true,
      [classes[size]]: true
    },
    className
  );

  const { textColor, textSize, fontWeight } = useMemo(() => {
    return {
      textColor: determineTextColor(variant),
      textSize: determineTextSize(size),
      fontWeight: determineFontWeight(variant)
    };
  }, [variant, size]);

  const [isDisabled, setIsDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setIsDisabled(buttonInfo?.disabled);
  }, [buttonInfo?.disabled]);

  const onClickHandler = useCallback(
    args => {
      if (onClickShowLoader) {
        setShowLoader(true);
      }
      if (onClickDisable) {
        setIsDisabled(true);
      }
      buttonInfo.onClick(args);
    },
    [buttonInfo, onClickShowLoader, onClickDisable]
  );

  useEffect(() => {
    if (onClickShowLoader?.eventFinished) {
      setShowLoader(false);
    }
    if (onClickDisable?.eventFinished) {
      setIsDisabled(false);
    }
  }, [onClickShowLoader, onClickDisable]);

  return (
    <Button
      size={size}
      disabled={isDisabled}
      {...props}
      onClick={onClickHandler}
      className={GeneralVantiBtnClasses}
      ref={ref}
    >
      {children}
      <>
        <RotatingLoadingIcon
          width={16}
          height={16}
          color={vantiColors.darkBlue7}
          style={{
            opacity: showLoader ? 1 : 0,
            position: "absolute",
            alignSelf: "center"
          }}
        />
        <Text
          {...textProps}
          data-testid={`button-${props.id || "general-default"}`}
          color={textColor}
          size={textSize}
          weight={fontWeight}
          className={classNames(textClassName)}
          style={{ opacity: showLoader ? 0 : 1 }}
        >
          {buttonInfo.label}
        </Text>
      </>
    </Button>
  );
};

export default memo(forwardRef(GeneralVantiButton));
