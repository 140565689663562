import { createContext, useMemo, useState } from "react";

const defaultValue = {
  reports: {},
  reportIdToDelete: null,
  selectedTableRows: [],
  setReports: () => {},
  setReportIdToDelete: () => {},
  setSelectedTableRows: () => {}
};

export const ReportsContext = createContext({ ...defaultValue });

export const useReportsContext = () => {
  const [reports, setReports] = useState(defaultValue.reports);
  const [reportIdToDelete, setReportIdToDelete] = useState(defaultValue.reportIdToDelete);
  const [selectedTableRows, setSelectedTableRows] = useState(defaultValue.selectedTableRows);

  return useMemo(
    () => ({
      reports,
      reportIdToDelete,
      selectedTableRows,
      setReports,
      setReportIdToDelete,
      setSelectedTableRows
    }),
    [reports, reportIdToDelete, selectedTableRows]
  );
};
