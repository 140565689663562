import * as palette from "./palette";

const hexToRgb = input => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return parseInt(first, 16) + ", " + parseInt(second, 16) + ", " + parseInt(last, 16);
};

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const topBarHeight = 50;
const drawerWidth = 260;

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

const container = {
  marginRight: "auto",
  marginLeft: "auto"
};

const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(" +
    hexToRgb(palette.vantiColors.black) +
    ", 0.42), 0 4px 25px 0px rgba(" +
    hexToRgb(palette.vantiColors.black) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(palette.vantiColors.black) +
    ", 0.2)"
};

const blackBoxShadow = {
  boxShadow:
    "0 1px 4px 0 rgba(" +
    hexToRgb(palette.vantiColors.black) +
    ",.14), 2px 5px 10px 0 rgba(" +
    hexToRgb(palette.vantiColors.black) +
    ",.4)"
};

const primaryBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(palette.vantiColors.black) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(palette.primaryColor[0]) +
    ",.4)"
};

const greenCardHeader = {
  backgroundColor: palette.vantiColors.green1
};
const darkBlueCardHeader = {
  backgroundColor: palette.vantiColors.darkBlue1
};
const turquoiseCardHeader = {
  backgroundColor: palette.vantiColors.turquoise1
};
const darkCardHeader = {
  backgroundColor: palette.vantiColors.gray30
};

const card = {
  display: "inline-directory",
  position: "relative",
  width: "100%",
  margin: "25px 0",
  boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(palette.vantiColors.black) + ", 0.14)",
  borderRadius: "0",
  color: "rgba(" + hexToRgb(palette.vantiColors.black) + ", 0.87)",
  background: palette.vantiColors.white
};

const title = {
  color: palette.vantiColors.gray3,
  textDecoration: "none",
  fontWeight: "300",
  marginTop: "30px",
  marginBottom: "25px",
  minHeight: "32px",
  fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
  "& small": {
    color: palette.vantiColors.gray2,
    fontWeight: "400",
    lineHeight: "1"
  }
};

export * from "./palette";
export {
  topBarHeight,
  hexToRgb,
  //variables
  drawerWidth,
  transition,
  container,
  boxShadow,
  card,
  primaryBoxShadow,
  greenCardHeader,
  darkCardHeader,
  turquoiseCardHeader,
  darkBlueCardHeader,
  title,
  blackBoxShadow
};
