import React, { memo } from "react";
import { vantiColors } from "../../jss/palette";

const PlusIcon24 = ({ width = 24, height = 24, stroke = vantiColors.darkBlue7, ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M5.5 12H18.5M12 5.5V18.5" stroke={stroke} strokeWidth="1.25" />
    </svg>
  );
};

export default memo(PlusIcon24);
