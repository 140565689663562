import React, { memo } from "react";
import { styled } from "@material-ui/core";

/*
  This component can be used to create a fixed space between two components
  This practice helps to eliminate the horrible usage of margin when working with a generic component that requires
  a padding, but contains a border
 */

const Spacer = ({ space = 0, horizontal = true }) => {
  return <SpacerDiv space={space} horizontal={horizontal} />;
};

const SpacerDiv = styled("div")(({ space, horizontal }) => ({
  height: horizontal ? space : "100%",
  width: horizontal ? "100%" : space,
  boxSizing: "border-box"
}));

export default memo(Spacer);
