import { makeStyles, styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import { Link } from "react-router-dom";

export const Root = styled("div")(({ theme }) => ({
  display: "flex",
  fontFamily: "Inter",
  fontWeight: 400,
  height: "100%"
}));

export const StyledSideBar = styled("div")(({ theme }) => ({
  width: theme.spacing(10.625),
  boxSizing: "border-box",
  whiteSpace: "nowrap",
  display: "flex",
  flexDirection: "column",
  backgroundColor: vantiColors.white,
  borderRight: `1px solid ${vantiColors.gray37}`
}));

export const Nav = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: theme.spacing(0, 0.5)
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  height: theme.spacing(10),
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));

export const NavIcon = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0.375, 0),
  "&:hover": {
    backgroundColor: theme.palette.vantiColors.lightGray4
  }
}));

export const NavIconText = styled("div")(({ theme }) => ({
  fontSize: "10px",
  lineHeight: "24px",
  color: theme.palette.vantiColors.darkBlue1
}));

export default makeStyles(theme => ({
  activeClassName: {
    "& > div": {
      backgroundColor: theme.palette.vantiColors.lightBlue10
    }
  }
}));
