import http from "../http";
import config from "../../common/config";

// Server
const usersUrl = `${config.serverUrl}/api/v2/users`;

/**
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} account
 * @returns {() => void}
 */
export async function updateUser(firstName, lastName, account) {
  const res = await http.patch(`${usersUrl}`, { firstName, lastName, account });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @returns {() => void}
 */
export async function getUser() {
  const res = await http.get(`${usersUrl}`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function getAccountUserNames() {
  const res = await http.get(`${usersUrl}`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function postUserInquiry(inquiry) {
  const res = await http.post(`${usersUrl}/inquiry`, { inquiry });
  const result = await res.json();

  if (res.status > 200) {
    throw new Error(result.message);
  }

  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function updateUserWebNotificationCenterReadStatus(isReadNotificationCenter = true) {
  const res = await http.patch(`${usersUrl}/read-notification-center-status`, { isReadNotificationCenter });
  const result = await res.json();

  if (res.status > 200) {
    throw new Error(result.message);
  }

  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}
