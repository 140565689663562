import { call, put, select, takeLatest } from "redux-saga/effects";

import { actionTypes } from "./actions";
import * as authService from "services/auth/auth";
import { setUsersAction } from "../../state/actions";
import { accountUsersSelector } from "../../state/selectors";

export function* removeUserWorker(action) {
  try {
    const user = action.payload;
    yield call(authService.removeUserFromAccount, user.id);
    yield put({ type: actionTypes.ACCOUNT_REMOVE_USER_SUCCESS });
    const currentUsers = yield select(accountUsersSelector);
    yield put(setUsersAction(currentUsers.filter(currentUser => currentUser.id !== user.id)));
  } catch (error) {
    yield put({ type: actionTypes.ACCOUNT_REMOVE_USER_ERROR, payload: error });
  } finally {
    yield put({ type: actionTypes.ACCOUNT_REMOVE_USER_FULFILL });
  }
}

export default function* fetchUsersWatcher() {
  yield takeLatest(actionTypes.ACCOUNT_REMOVE_USER_REQUEST, removeUserWorker);
}
