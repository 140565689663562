import React, { memo } from "react";
import * as PropTypes from "prop-types";

import { ReactComponent as CrossIcon } from "assets/img/cross.svg";

import useStyles from "./styles";

const SelectedValue = ({ onClick, id, children, onRemoveClick, testId }) => {
  const styles = useStyles();

  return (
    <div className={styles.value} value={id} onClick={onClick} data-testid={testId}>
      <span>{children}</span>
      <CrossIcon onClick={onRemoveClick.bind(null, id)} data-testid={`delete-${testId}`} />
    </div>
  );
};

SelectedValue.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  onRemoveClick: PropTypes.func
};

export default memo(SelectedValue);
