import React, { forwardRef, memo, useEffect, useState } from "react";
import { styled } from "@material-ui/core";

const Backdrop = styled("div")({
  position: "fixed",
  height: "100vh",
  width: "100vw",
  top: 0,
  left: 0
});

const ContentWrapper = styled("div")(({ top, left, minWidth }) => ({
  position: "fixed",
  top,
  left,
  minWidth
}));

const Popup = ({ children, onClose }, ref) => {
  const [contentCoordinates, setContentCoordinates] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const fatherEl = ref?.current;

    if (fatherEl) {
      const { top, left, right, height } = fatherEl.getBoundingClientRect();
      setContentCoordinates({ top: top + height, left, minWidth: right - left });
    }
  }, [ref?.current]);

  return (
    <>
      <Backdrop onClick={() => onClose()} />
      <ContentWrapper
        onClick={e => e.stopPropagation()}
        top={contentCoordinates.top}
        left={contentCoordinates.left}
        minWidth={contentCoordinates.minWidth}
      >
        {children}
      </ContentWrapper>
    </>
  );
};

export default memo(forwardRef(Popup));
