import React, { memo, useState } from "react";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import RegenerateIcon from "assets/icons/regenerate/RegenerateIcon";
import "./RegenerateQuestion.css"; //https://web.dev/css-border-animations/
import { useMutation } from "react-query";
import { DATASET_API_QUERY_KEYS, regeneratePredefinedQuestionsForDataset } from "services/api/dataset";
import { logger } from "utils/logger";
import useDatasetQuestionRequest from "common/hooks/use-dataset-question-request";

const GENERATE_QUESTIONS_BUTTON_TEXT = "Generate new predefined questions";

const RegenerateQuestion = ({ datasetId }) => {
  const { getQuestionRequest } = useDatasetQuestionRequest(datasetId);
  const [isLoading, setIsLoading] = useState(null);

  const regeneratePredefinedQuestionsRequest = useMutation(
    [DATASET_API_QUERY_KEYS.REGENERATE_PREDEFINED_QUESTIONS],
    async () => regeneratePredefinedQuestionsForDataset(datasetId),
    {
      manual: false,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: () => {
        setTimeout(async () => {
          await getQuestionRequest.mutate({ datasetId });
          setIsLoading(false);
        }, 30000);
      },
      onError: (error, { datasetId }) => logger.error("Failed to regenerate predefined questions", { error, datasetId })
    }
  );

  return (
    <RegenerateQuestionsWrapper
      className={"rainbow"}
      style={{
        animation: `rotate 
        ${isLoading ? "2s" : "20s"} linear infinite, opacityChange
         ${isLoading ? "4s" : "10s"} infinite alternate`
      }}
      isLoading={isLoading}
      onClick={() => {
        if (isLoading) {
          return;
        }
        setIsLoading(true);
        regeneratePredefinedQuestionsRequest.mutate();
      }}
    >
      <SpinningRegenerateIcon width={24} height={24} isLoading={isLoading} />
      <Text>{GENERATE_QUESTIONS_BUTTON_TEXT}</Text>
      <SpinningRegenerateIcon width={24} height={24} isLoading={isLoading} />
    </RegenerateQuestionsWrapper>
  );
};

const SpinningRegenerateIcon = styled(RegenerateIcon)(({ isLoading }) => ({
  animation: isLoading ? "rotation 2s infinite linear" : "none"
}));

const RegenerateQuestionsWrapper = styled("div")(({ isLoading }) => ({
  boxSizing: "border-box",
  alignItems: "center",
  display: "flex",
  padding: 8,
  gap: 16,
  "&:hover": {
    cursor: isLoading ? "not-allowed" : "pointer"
  }
}));

export default memo(RegenerateQuestion);
