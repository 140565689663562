import React from "react";

import { FlexItem, Text } from "components/ui";
import Button from "components/ui/Buttons/Button.js";
import Loader from "components/ui/Loader/index.js";

import { ReactComponent as ArrowLongIcon } from "assets/icons/ArrowLong.svg";

import { vantiColors } from "assets/jss/palette.js";

import { isUserAgentMobile } from "utils/app-utils.js";

import useStyles from "./styles";

const CardNextButton = ({
  isNextDisabled,
  isLastCardDisplaying,
  onClickFinish,
  onClickNext,
  buttonTextDisplay,
  isCreationRequestLoading,
  index
}) => {
  const classes = useStyles();

  return (
    <FlexItem container justifyContent="center" dense="full">
      <FlexItem xs={10} md={4} dense="full">
        <Button
          data-testid={`${buttonTextDisplay}-btn-${index}`}
          disabled={isNextDisabled}
          onClick={isLastCardDisplaying ? onClickFinish : onClickNext}
          color={isNextDisabled ? vantiColors.lightBlue8 : vantiColors.green1}
          style={isUserAgentMobile() ? { height: 50 } : {}}
          fullWidth
        >
          <Text weight="bold" color={isNextDisabled ? vantiColors.lightGray6 : "black"}>
            {buttonTextDisplay}{" "}
            {!isLastCardDisplaying && !isNextDisabled && <ArrowLongIcon className={classes.arrowLongIcon} />}
            {isCreationRequestLoading && <Loader />}
          </Text>
        </Button>
      </FlexItem>
    </FlexItem>
  );
};

export default CardNextButton;
