import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const ExportModelIcon24 = ({ stroke = vantiColors.gray29, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <mask id="mask0_2766_3854" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2766_3854)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.9341 5.44184L20.9961 5.93798L21.4961 9.93798L20.5038 10.062L20.1429 7.17465L14.912 14.7833L14.6236 15.2028L14.2094 14.9069L11.0946 12.6821L7.39041 17.3123L6.60954 16.6877L10.6095 11.6877L10.9053 11.3179L11.2906 11.5931L14.3764 13.7972L19.2862 6.65561L16.562 6.99614L16.438 6.00386L20.438 5.50386L20.9341 5.44184ZM3 5.55812V19.5581V20.0581H3.5H21.5V19.0581H4V5.55812H3Z"
          fill={stroke}
        />
      </g>
    </svg>
  );
};

export default memo(ExportModelIcon24);
