import { createSelector } from "reselect";

const signInSelector = state => state.auth?.operations?.signInByEmail;

export const loadingSelector = createSelector(
  signInSelector,
  state => state.loading
);
export const errorSelector = createSelector(
  signInSelector,
  state => state.error
);
export const successSelector = createSelector(
  signInSelector,
  state => state.success
);