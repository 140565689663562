import React from "react";

const DismissNotificationIcon = ({ width = "10", height = "10", viewBox = "0 0 10 10", fill = "none" , ...rest}) => (
    <svg {...rest} width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.26659 9.66666L0.333252 8.73333L4.06659 4.99999L0.333252 1.26666L1.26659 0.333328L4.99992 4.06666L8.73325 0.333328L9.66658 1.26666L5.93325 4.99999L9.66658 8.73333L8.73325 9.66666L4.99992 5.93333L1.26659 9.66666Z"
        fill={fill}
      />
    </svg>
);

export default DismissNotificationIcon;

