import { vantiColors } from "assets/jss/palette";
import React, { memo } from "react";

const DisplayIcon24 = ({ width = 24, height = 24, stroke = vantiColors.darkBlue7, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke={stroke}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="miter"
      {...rest}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M2,12S5,4,12,4s10,8,10,8-2,8-10,8S2,12,2,12Z"></path>
        <circle cx="12" cy="12" r="4"></circle>
      </g>
    </svg>
  );
};

export default memo(DisplayIcon24);
