import React, { Fragment } from "react";
import useQuery from "../../common/hooks/use-query";
import ResetPasswordAction from "./action/ResetPasswordAction";
import ConfirmAccountAction from "./action/confirm-account";
import CreateUserEmailViaMarketplace from "./action/create-user-email-via-marketplace";

import { Redirect } from "react-router-dom";

export default function Action() {
  const query = useQuery();
  const { mode, verifyToken, resetToken, accountName, encryptedAccountId, marketplace, token } = query;

  const actionHandler = {
    resetPassword: <ResetPasswordAction resetToken={resetToken} />,
    verifyEmail: <ConfirmAccountAction verifyToken={verifyToken} />,
    createUserEmailViaMarketplace: (
      <CreateUserEmailViaMarketplace
        accountName={accountName}
        encryptedAccountId={encryptedAccountId}
        marketplace={marketplace}
        token={token}
      />
    )
  };
  return <Fragment>{actionHandler[mode] || <Redirect to={"/dashboard/auth"} />}</Fragment>;
}
