import React, { memo, useContext, useMemo } from "react";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import GeneralVantiButton, { VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { useHistory } from "react-router-dom";
import { NEW_CONNECTOR_PAGE_LINK } from "../constants";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import usePermissions from "common/hooks/use-permissions";
import { ROLE } from "@vanti-analytics-org/vanti-common";

const HeaderWrapperStyled = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const ButtonsWrapperStyled = styled("div")({
  display: "flex",
  columnGap: 8
});

const HEADER_TEXT = "Data";

const DataPageHeader = () => {
  const history = useHistory();
  const { datasetsContext } = useContext(AppContext);
  const { selectedDatasetsForCuration } = datasetsContext;

  const canCreateNewConnector = usePermissions([ROLE.MANAGE_DATA_CONNECTORS]);
  const canCurate = usePermissions([ROLE.CURATE_DATASET]);

  const addConnectorButtonInfo = useMemo(
    () => ({
      label: "Add data connector",
      disabled: false,
      onClick: () => {
        history.push(NEW_CONNECTOR_PAGE_LINK);
      }
    }),
    [history]
  );

  const curationButtonInfo = useMemo(
    () => ({
      label: "Curate",
      disabled: !selectedDatasetsForCuration.length,
      onClick: () => {
          history.push("/dashboard/data-curation/_");
      }
    }),
    [selectedDatasetsForCuration.length]
  );

  return (
    <HeaderWrapperStyled>
      <Text size={22} weight={600}>
        {HEADER_TEXT}
      </Text>

      <ButtonsWrapperStyled>
        {canCreateNewConnector && (
          <GeneralVantiButton buttonInfo={addConnectorButtonInfo} variant={VARIANTS.SECONDARY} />
        )}
        {canCurate && <GeneralVantiButton buttonInfo={curationButtonInfo} />}
      </ButtonsWrapperStyled>
    </HeaderWrapperStyled>
  );
};

export default memo(DataPageHeader);
