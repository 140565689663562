import React, { memo, useCallback } from "react";
import { Text } from "../../../../../../components/ui"

import {styled, Tab, Tabs} from "@material-ui/core";
import CodePreview from "./code-preview/CodePreview";
import CurationCSVInputPreview from "./CurationCSVInputPreview";
import CurationCSVOutputPreview from "./curation-csv-output-preview/CurationCSVOutputPreview";

import { SUPPORTED_FILE_EXTENSIONS } from "pages/side-bar/data-curation/utils";
import { vantiColors } from "assets/jss/palette";

import styles from "../../../styles";

const TabsStyled = styled(Tabs) ({
  "& .MuiTabs-indicator":{
    display:"none"
  },
  "& .MuiTab-root" :{
    minHeight: 0,
    padding:0,
    marginRight: 8
  },
  "& .Mui-selected":{
    borderBottom: `2px solid #0313A4`,
    margin:"2px 6px 0px 9px"
  }
})

const DataPreviewMessageStyled = styled("div")({
  padding:8,
  color: vantiColors.gray41,
  fontFamily:"inter",
  fontSize:16,
  fontWeight:400
})

const CurationFilesTabsWrapper = ({ activeFileTab, setActiveFileTab, files, uploadDataTabs }) => {
  const classes = styles();
  const file1 = files.find(file => `FILE_${file?.id}` === activeFileTab);
  const getInputPreviewSection = useCallback(() => {
    const file = files.find(file => `FILE_${file?.id}` === activeFileTab);
    if (!file) return <DataPreviewMessageStyled>Data preview will be displayed here</DataPreviewMessageStyled>;
    switch (file.datasetType) {
      case SUPPORTED_FILE_EXTENSIONS.CSV:
        return file.isJustForPreview ? (
          <CurationCSVOutputPreview preview={file.mediumPreview || file.preview} id={file.name} />
        ) : (
          <CurationCSVInputPreview fileData={file.mediumPreview || file.preview} id={file.name} />
        );
      case SUPPORTED_FILE_EXTENSIONS.JSON:
        return <CodePreview readOnly mode={"json"} preview={file.preview} id={file.name} />;
      case SUPPORTED_FILE_EXTENSIONS.XML:
        return <CodePreview readOnly mode={"xml"} preview={file.preview} id={file.name} />;
      default:
        return <DataPreviewMessageStyled> Data preview will be displayed here </DataPreviewMessageStyled>;
    }
  }, [files, activeFileTab]);
  return (
      <div>
        {file1 && (<TabsStyled
        data-testid="view-files-tabs-container"
        classes={{
          indicator: classes.tabsIndicator,
          root: classes.tabsRoot
        }}
        value={activeFileTab}
        onChange={(_, value) => setActiveFileTab(value)}
      >
        {uploadDataTabs &&
          uploadDataTabs.map(item => {
            return (
              <Tab
                data-testid={`view-file-tab-${item.id}`}
                classes={{ root: classes.tabButton }}
                key={item.id}
                value={item.id}
                label={item.name}
              />
            );
          })}
      </TabsStyled>)}

      {activeFileTab && getInputPreviewSection()}
      </div>
  );
};

export default memo(CurationFilesTabsWrapper);
