export const mapUsersResponse = (currentUserId, data) => {
  const result = [null];

  data
    .sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    })
    .forEach(user => {
      if (currentUserId === user.id) {
        result[0] = {
          ...user,
          isCurrentUser: true
        };
      } else {
        result.push(user);
      }
    });

  return result;
};