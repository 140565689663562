import { put, select, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./actions";
import { actionTypes as accountStateActionTypes } from "modules/account/state/actions";
import * as stationService from "services/api/station";
import { stationQueryActionTypes } from "../../query";

export function* deleteProductWorker(action) {
  const { productId } = action.payload;
  try {
    const accountId = yield select(state => state.account.state.id);
    yield stationService.deleteStationProduct(accountId, productId);
    yield put({ type: actionTypes.STATION_DELETE_PRODUCT_SUCCESS, payload: { productId } });
    yield put({ type: accountStateActionTypes.ACCOUNT_REMOVE_PRODUCT, payload: productId });
    const stations = yield select(state => state.station.query.stations);
    Object.values(stations).forEach(station => {
      station.products = station.products.filter(product => product._id !== productId);
    });
    yield put({ type: stationQueryActionTypes.STATION_SET_STATIONS, payload: stations });
  } catch (error) {
    yield put({ type: actionTypes.STATION_DELETE_PRODUCT_ERROR, payload: { productId, error } });
  } finally {
    yield put({ type: actionTypes.STATION_DELETE_PRODUCT_FULFILL, payload: { productId } });
  }
}

export default function* deleteProductWatcher() {
  yield takeLatest(actionTypes.STATION_DELETE_PRODUCT_REQUEST, deleteProductWorker);
}
