import { dataCurationsFlagTypes } from "./actionTypes";

/* Data curations */
export const fetchCurations = () => ({
  type: dataCurationsFlagTypes.START_FETCH
});
export const addCurationToState = payload => ({
  type: dataCurationsFlagTypes.ADD_CURATION,
  payload
});
export const editCuration = payload => ({
  type: dataCurationsFlagTypes.EDIT_CURATION,
  payload
});
export const removeCuration = payload => ({
  type: dataCurationsFlagTypes.REMOVE_CURATION,
  payload
});

/* Data upload */
export const dataCurationsSetLoading = payload => ({
  type: dataCurationsFlagTypes.SET_LOADING,
  payload
});
export const dataCurationsSetSuccess = () => ({
  type: dataCurationsFlagTypes.SET_SUCCESS
});
export const dataCurationsSetError = () => ({
  type: dataCurationsFlagTypes.SET_ERROR
});
export const dataCurationsClearLoading = () => ({
  type: dataCurationsFlagTypes.CLEAR_LOADING
});
export const dataCurationsClearSuccess = () => ({
  type: dataCurationsFlagTypes.CLEAR_SUCCESS
});
export const dataCurationsClearError = () => ({
  type: dataCurationsFlagTypes.CLEAR_ERROR
});
