import React from "react";
import { TextField } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import { FlexContainer, FlexItem, Text } from "components/ui";

import { emailRegex } from "utils/regex.js";
import { KEY_CODES, maxInputFieldLength } from "../constants";

import PhoneInput from "react-phone-input-2";

const PERSONAL_INFO_CARD = (
  email,
  phoneNumber,
  emailError,
  phoneNumberError,
  onEmailChange,
  onPhoneNumberChange,
  onClickNext,
  isUserBrowsingFromMobile
) => {
  const headerTextSize = isUserBrowsingFromMobile ? 40 : 30;

  return {
    text: (
      <FlexContainer data-testid="step2-title-container" item xs={10} md={4} dense="full">
        <Text color={vantiColors.darkBlue1} size={headerTextSize} weight="bold">
          Tell us
        </Text>

        <Text size={headerTextSize} weight="bold" color={vantiColors.green1}>
          <Text color={vantiColors.darkBlue1} size={headerTextSize} weight="bold">
            &nbsp;about{" "}
          </Text>
          you.
        </Text>
      </FlexContainer>
    ),
    description: "Personal info",
    element: (
      <FlexContainer item xs={10} md={4} justifyContent="center">
        <FlexItem xs={12} dense="full">
          <FlexItem xs={12} dense="full">
            <TextField
              label="Business email address"
              id="business-email-address"
              variant="standard"
              InputLabelProps={{
                shrink: true,
                style: { fontFamily: "Inter" }
              }}
              FormHelperTextProps={{ style: { fontFamily: "Inter" } }}
              fullWidth
              inputProps={{
                "data-testid": "create-account-email-input",
                style: {
                  fontFamily: "Inter",
                  paddingLeft: "16px"
                },
                value: email,
                onChange: e => onEmailChange(e.target.value),
                onKeyDown: e => {
                  if (e.keyCode === KEY_CODES.TAB) e.preventDefault();
                  if (e.keyCode === KEY_CODES.ENTER) onClickNext();
                },
                maxLength: maxInputFieldLength,
                placeholder: "e.g. davidb@companyname.com"
              }}
              error={!!emailError}
              helperText={emailError}
            />
          </FlexItem>
        </FlexItem>

        <FlexItem data-testid="phone-input" xs={12} dense="horizontal" style={{ marginTop: "30px" }}>
          <PhoneInput
            inputProps={{
              "data-testid": "create-account-phone-input"
            }}
            data-testid="create-account-phone-input"
            specialLabel="Phone number (optional)"
            country={"us"}
            value={phoneNumber}
            onChange={phone => onPhoneNumberChange(phone)}
            onKeyDown={e => {
              if (e.keyCode === KEY_CODES.TAB) e.preventDefault();
              if (e.keyCode === KEY_CODES.ENTER) onClickNext();
            }}
            inputStyle={{ width: "100%" }}
          />

          {phoneNumberError && (
            <FlexItem container justifyContent="flex-start" dense="full">
              <FlexItem dense="full">
                <Text size={12}>{phoneNumberError}</Text>
              </FlexItem>
            </FlexItem>
          )}
        </FlexItem>
      </FlexContainer>
    ),
    isNextDisabled: !!emailError || !email.match(emailRegex)
  };
};

export default PERSONAL_INFO_CARD;
