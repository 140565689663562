import React, { memo } from "react";
import PropTypes from "prop-types";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Chip from "./chip/Chip";

import useStyles from "./styles";

const ChipsInput = ({ id, values, currentValue, onChipRemove, disabled, label, inputProps, formControlProps }) => {
  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.formControl} {...formControlProps}>
        <InputLabel id={id}>{label}</InputLabel>

        <Input disabled={disabled} value={currentValue} id={id} className={classes.input} {...inputProps} />
      </FormControl>
      <div className={classes.chipsWrapper}>
        {values.map((item, index) => (
          <Chip key={index} id={index} onRemoveClick={onChipRemove.bind(null, item)} testId={`chip-${item}`}>
            {item}
          </Chip>
        ))}
      </div>
    </div>
  );
};

ChipsInput.propTypes = {
  values: PropTypes.arrayOf(String).isRequired,
  id: PropTypes.string,
  labelProps: PropTypes.object,
  formControlProps: PropTypes.object,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default memo(ChipsInput);
