import React from "react";
import { styled } from "@material-ui/core";
import { avatarsColorPalette, vantiColors } from "assets/jss/palette";
import { Text } from "components/ui";
import { useCallback, useRef } from "react";
import CaretUpIcon from "assets/icons/caret-up-icon/CaretUpIcon";
import CaretDownIcon from "assets/icons/caret-down-icon/CaretDownIcon";

export const palette = [
  avatarsColorPalette[9],
  avatarsColorPalette[0],
  avatarsColorPalette[1],
  avatarsColorPalette[2],
  avatarsColorPalette[3],
  avatarsColorPalette[4],
  avatarsColorPalette[5],
  avatarsColorPalette[6],
  avatarsColorPalette[7],
  avatarsColorPalette[8]
];

export const Dot = styled("div")(({ color }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: color
}));

export const TextWithPopupWrapper = styled(Text)({
  display: "flex",
  columnGap: 4,
  alignItems: "center",
  cursor: "pointer",
  width: "fit-content"
});

const ChildEntityWrapper = styled(Text)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
});

const ChildEntityTextWrapper = styled(Text)({
  display: "flex",
  alignItems: "center",
  columnGap: 8
});

export const EntityRow = ({ childEntity, color, onClickOpenPopup, isOpen, isAllowSubEntitySelection = false }) => {
  const popupRef = useRef(null);

  const onClick = useCallback(() => {
    onClickOpenPopup({
      ref: popupRef,
      fatherEntityId: childEntity._id
    });
  }, [onClickOpenPopup, popupRef]);

  return (
    <ChildEntityWrapper key={childEntity.name}>
      <ChildEntityTextWrapper key={childEntity.name} weight={500}>
        <Dot color={color} />
        {childEntity.name}
      </ChildEntityTextWrapper>

      <TextWithPopupWrapper onClick={onClick} ref={popupRef} color={vantiColors.gray41}>
        {childEntity.subEntity ??
          (isAllowSubEntitySelection && (
            <>
              {`Choose ${childEntity.name.toLowerCase()}`}
              {isOpen ? <CaretUpIcon /> : <CaretDownIcon />}
            </>
          ))}
      </TextWithPopupWrapper>
    </ChildEntityWrapper>
  );
};
