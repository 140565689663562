import React, { memo } from "react";

import BodyCell from "../../table-body-rows/body-cell/BodyCell";
import LineChip from "pages/dashboard/station/sections/products-and-lines/lines/LineChip";

const StationLinesCell = ({ stationName, stationId }) => {
  return (
    <BodyCell data-testid={`${stationName}-lines`} align="left">
      <LineChip
        stationId={stationId}
        chipProps={{
          onClick: e => {
            e.stopPropagation();
          }
        }}
      />
    </BodyCell>
  );
};

export default memo(StationLinesCell);
