import React, { memo } from "react";

const NewPredictiveQualityIcon = ({ className }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M37.5 4.5C41.9183 4.5 45.6425 8.08402 45.5 12.5C45.3619 16.7802 41.7824 20 37.5 20C33.2176 20 29.6381 16.7802 29.5 12.5C29.3575 8.08402 33.0817 4.5 37.5 4.5Z"
      fill="#86E8B6"
    />
    <path
      d="M6.5 4V45M3 41H11.5M45.5 41H39.5M11.5 41V30.5H17.5V41M11.5 41H17.5M17.5 41H22.5M22.5 41V22.5H28.5V41M22.5 41H28.5M28.5 41H33.5M33.5 41V25.5H39.5V41M33.5 41H39.5M37.5 4.5C41.9183 4.5 45.6425 8.08402 45.5 12.5C45.3619 16.7802 41.7824 20 37.5 20C33.2176 20 29.6381 16.7802 29.5 12.5C29.3575 8.08402 33.0817 4.5 37.5 4.5Z"
      stroke="black"
    />
    <path
      d="M37.9129 8C38.7463 8 39.4129 8.225 39.9129 8.675C40.4129 9.125 40.6629 9.75 40.6629 10.55C40.6629 11.4167 40.3921 12.0708 39.8504 12.5125C39.3171 12.9458 38.5879 13.1708 37.6629 13.1875V14.5625H36.3129V12.2375H36.9129C37.6379 12.2375 38.2088 12.1208 38.6254 11.8875C39.0421 11.6458 39.2504 11.2042 39.2504 10.5625C39.2504 10.1292 39.1254 9.79167 38.8754 9.55C38.6254 9.3 38.2879 9.175 37.8629 9.175C37.4129 9.175 37.0504 9.30417 36.7754 9.5625C36.5004 9.82083 36.3629 10.1583 36.3629 10.575H35.0004C34.9921 10.075 35.1046 9.63333 35.3379 9.25C35.5713 8.85833 35.9088 8.55417 36.3504 8.3375C36.7921 8.1125 37.3129 8 37.9129 8ZM37.8129 15.675V17.25H36.1879V15.675H37.8129Z"
      fill="black"
    />
  </svg>
);

export default memo(NewPredictiveQualityIcon);
