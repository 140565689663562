import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const MoreIconHorizontal24 = ({ fill = vantiColors.darkBlue7, ...rest }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      {...rest}
    >
      <circle cx="20" cy="12" r="2" fill={fill} />
      <circle cx="12" cy="12" r="2" fill={fill} />
      <circle cx="4" cy="12" r="2" fill={fill} />
    </svg>
  );
};

export default memo(MoreIconHorizontal24);
