import React, { memo } from "react";

const UploadCloudIcon = ({ width = 40, height = 50, ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M8.40363 15.1017C8.58081 12.0682 9.66866 9.66366 11.5641 8.12337C13.1084 6.92642 14.9944 6.30128 16.9209 6.34775C20.6501 6.34775 22.7475 9.58664 23.6046 11.328C24.5653 10.7528 25.6479 10.4327 26.7563 10.3961C27.8647 10.3595 28.9647 10.6076 29.9586 11.1184C33.115 12.6458 33.457 15.7863 33.457 17.3566C34.9177 17.6812 36.224 18.5235 37.1518 19.7391C38.0796 20.9546 38.5711 22.4676 38.5418 24.0183C38.5619 24.8079 38.4198 25.5929 38.125 26.3211C37.8301 27.0492 37.3893 27.7038 36.8318 28.2413C35.7532 29.2465 34.3498 29.7933 32.9007 29.773H7.70724C5.54391 29.7174 1.45626 28.1129 1.45626 22.4352C1.4439 17.7716 4.94231 15.6109 8.40363 15.1017Z"
        stroke="#394253"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M13.454 23.126L19.2742 17.3359L25.0944 23.126" stroke="#394253" strokeWidth="2" />
      <path d="M19.2729 17.3359L19.2729 36.1536" stroke="#394253" strokeWidth="2" />
    </svg>
  );
};

export default memo(UploadCloudIcon);
