import { takeLatest, call, put, select, all } from "redux-saga/effects";

import * as webNotificationService from "services/api/webNotifications";

import { actionTypes } from "./actions";
import { actionTypes as stateActionTypes } from "../../state/actions";

import { webNotificationsByApplicationIdSelector } from "../../state/selectors";

function* updateExistingNotifications(existingNotifications, notification) {
  const existingNotification = existingNotifications.find(item => item.id === notification.id);

  if (existingNotification) {
    yield put({ type: stateActionTypes.UPDATE_WEB_NOTIFICATION, payload: notification });
  } else {
    yield put({ type: stateActionTypes.ADD_WEB_NOTIFICATION, payload: notification });
  }
}

export function* fetchWebNotificationsWorker(action) {
  try {
    if (action.payload.applicationId) {
      const result = yield call(webNotificationService.getWebNotificationByStationId, action.payload.applicationId);
      const existingNotifications = yield select(webNotificationsByApplicationIdSelector(action.payload.applicationId));

      yield all(result.map(item => call(updateExistingNotifications.bind(null, existingNotifications), item)));
    } else {
      const result = yield call(webNotificationService.getWebNotifications);

      yield put({ type: stateActionTypes.SET_WEB_NOTIFICATIONS, payload: result });
    }

    yield put({ type: actionTypes.FETCH_WEB_NOTIFICATIONS_SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_WEB_NOTIFICATIONS_ERROR, payload: { error } });
  } finally {
    yield put({ type: actionTypes.FETCH_WEB_NOTIFICATIONS_FULFILL, payload: {} });
  }
}

export default function* webNotificationsWatcher() {
  yield takeLatest(actionTypes.FETCH_WEB_NOTIFICATIONS_REQUEST, fetchWebNotificationsWorker);
}
