import React, { forwardRef, memo, useCallback } from "react";
import {
  CurationAddDatasetButtonStyled,
  CurationAddDatasetButtonTextStyled,
  PlusButtonLabelStyled,
  PlusButtonStyled
} from "./SingleDataCuration.style";
import { VARIANTS } from "../../../../../components/ui/Buttons/GeneralVantiButton";
import GeneralModalPreviewComponent from "../../../../../components/ui/Modal/GeneralModalPreviewComponent";
import AddDatasetModal from "./AddDatasetModal";

const CustomAddDatasetPopoverButton = ({
  setDisplayOtherDatasets,
  setDatasetSelectionCount,
  datasetSelectionCount,
  displayOtherDatasets,
  setAnchorEl,
  popoverButtonText
}) => {
  const handleClick = useCallback(
    event => {
      setDisplayOtherDatasets(true);
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  return (
    <>
      <CurationAddDatasetButtonStyled
        buttonInfo={{
          disabled: false,
          onClick: handleClick,
          label: (
            <PlusButtonLabelStyled>
              <PlusButtonStyled />
              <CurationAddDatasetButtonTextStyled>{popoverButtonText}</CurationAddDatasetButtonTextStyled>
            </PlusButtonLabelStyled>
          )
        }}
        data-testid={"curation-files-upload-input"}
        variant={VARIANTS.TERTIARY}
      ></CurationAddDatasetButtonStyled>

      {displayOtherDatasets && (
        <GeneralModalPreviewComponent
          onClose={(event, reason) => {
            setDisplayOtherDatasets(false);
          }}
        >
          <AddDatasetModal
            setDatasetSelectionCount={setDatasetSelectionCount}
            datasetSelectionCount={datasetSelectionCount}
            closeDataSelectionModal={() => setDisplayOtherDatasets(false)}
          />
        </GeneralModalPreviewComponent>
      )}
    </>
  );
};

export default memo(forwardRef(CustomAddDatasetPopoverButton));
