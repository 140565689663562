import React, { memo, useCallback, useMemo } from "react";
import { styled } from "@material-ui/core";
import { ChatMessageType } from "@vanti-analytics-org/vanti-common";
import ChatMessageUserAvatar from "pages/side-bar/chat/components/chat-message/ChatMessageUserAvatar";
import VantiIcon32 from "assets/icons/vanti-icons/VantiIcon32";
import ChatMessageBubbleWithAvatar from "../chat-message/ChatMessageBubbleWithAvatar";
import ChatMessageBubbleWithAvatarAndInlineButtons from "pages/side-bar/chat/components/chat-message/ChatMessageBubbleWithAvatarAndInlineButtons";
import ChatMessageReport from "pages/side-bar/chat/components/chat-message/ChatMessageReport/ChatMessageReport";

const PaddingWrapperStyled = styled("div")(() => ({
  paddingBottom: "16px",
  width: "100%"
}));

const ChatMessageContent = ({ message, isUserMessage, onChatMessageMetadataClick }) => {
  const hasInlineButtons = useMemo(() => !isUserMessage && message.inlineButtons && message.inlineButtons.length > 0, [
    message.inlineButtons,
    isUserMessage
  ]);

  const chatMessageAvatar = useMemo(() => {
    return isUserMessage ? (
      <ChatMessageUserAvatar displayName={message.senderUserDisplayName} />
    ) : (
      <VantiIcon32 style={{ order: 0 }} />
    );
  }, [isUserMessage, message?.senderUserDisplayName]);

  const textMessage = useMemo(() => {
    return hasInlineButtons ? (
      <ChatMessageBubbleWithAvatarAndInlineButtons
        data-testid={`chat-msg-bubble-with-avatar-and-inline-buttons-${message._id}`}
        message={message}
        isUserMessage={isUserMessage}
        chatMessageAvatar={chatMessageAvatar}
        onChatMessageMetadataClick={onChatMessageMetadataClick}
      />
    ) : (
      <ChatMessageBubbleWithAvatar
        data-testid={`chat-msg-bubble-with-avatar-${message._id}`}
        isUserMessage={isUserMessage}
        chatMessageAvatar={chatMessageAvatar}
        textContent={message.textContent}
        timestamp={message.createdAt}
        onChatMessageMetadataClick={onChatMessageMetadataClick}
      />
    );
  }, [hasInlineButtons, { ...message }]);

  const reportMessage = useMemo(() => {
    return message.reportData ? <ChatMessageReport message={message} /> : null;
  }, [{ ...message }]);

  const uniqueLoadingMessage = useMemo(() => {
    return (
      <ChatMessageBubbleWithAvatar
        data-testid={`chat-msg-bubble-with-avatar-${message._id}`}
        isUserMessage={false}
        chatMessageAvatar={chatMessageAvatar}
        textContent={message.textContent}
        isLoading={true}
        timestamp={message.createdAt}
      />
    );
  }, [{ ...message }]);

  return useMemo(() => {
    switch (message.type) {
      case ChatMessageType.TEXT:
        return <PaddingWrapperStyled>{textMessage}</PaddingWrapperStyled>;
      case ChatMessageType.REPORT:
        return <PaddingWrapperStyled>{reportMessage}</PaddingWrapperStyled>;
      case ChatMessageType.UNIQUE_LOADING:
        return <PaddingWrapperStyled>{uniqueLoadingMessage}</PaddingWrapperStyled>;
    }
  }, [{ ...message }]);
};

export default memo(ChatMessageContent);
