import React, { memo } from "react";

const DatabricksIcon34 = () => {
  return (
    <svg width="50" height="34" viewBox="0 0 50 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.6121 13.9973L25.6051 21.9416L10.705 13.4771L10 13.8554V20.0028L25.6051 28.893L39.6121 20.9486V24.2114L25.6051 32.1558L10.705 23.6912L10 24.0696V25.11L25.6051 34L41.2573 25.11V18.9625L40.5523 18.5841L25.6051 27.0487L11.6451 19.1043V15.8887L25.6051 23.7859L41.2573 14.8957V8.84285L40.4582 8.41726L25.6051 16.8345L12.3971 9.31573L25.6051 1.84423L36.5099 7.99167L37.497 7.4715V6.71489L25.6051 0L10 8.84285V9.78861L25.6051 18.6787L39.6121 10.7344V13.9973Z"
        fill="#FF3621"
      />
    </svg>
  );
};

export default memo(DatabricksIcon34);
