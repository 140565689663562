import React, { memo, useMemo, useRef, useState } from "react";
import { Chip, Popover } from "@material-ui/core";
import { FlexContainer, FlexItem } from "components/ui";
import { TAGS, TAGS_ENUM } from "../constants";
import { vantiColors } from "assets/jss/palette";
import Lines from "./Lines";
import { useSelector } from "react-redux";
import { accountLinesSelector } from "modules/account/state/selectors";
import { stationLinesSelector } from "modules/station/query";
import ChipLabel from "../common/ChipLabel";
import classNames from "classnames";
import styles from "../common/commonStyles";

const LineChip = ({ title, stationId, chipProps, ...props }) => {
  const classes = styles({
    backgroundColor: TAGS.LINE.backgroundColor,
    textColor: TAGS.LINE.textColor,
    hoverBackgroundColor: TAGS.LINE.hoverBackgroundColor
  });

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const stationLines = useSelector(stationLinesSelector(stationId));
  const accountLines = useSelector(accountLinesSelector);

  const chipElement = useRef(null);

  const stationLinesWithData = useMemo(() => accountLines.filter(accLine => accLine._id === stationLines[0]), [
    stationLines,
    accountLines
  ]);

  const stationLinesMap = useMemo(
    () =>
      stationLinesWithData.reduce((res, item) => {
        res[item._id] = item;
        return res;
      }, {}),
    [stationLinesWithData]
  );

  const shouldDisplayDefaultChip = useMemo(() => !stationLinesWithData.length && !title, [
    stationLinesWithData.length,
    title
  ]);

  const lineOptions = [...stationLinesWithData].concat(accountLines.filter(line => !stationLinesMap[line._id]));

  const onChipClick = e => {
    setIsPopoverOpen(true);
    e.stopPropagation();
  };

  const onPopoverClose = e => {
    setIsPopoverOpen(false);
    e.stopPropagation();
  };

  const chipStyle = classNames({
    [classes.chip]: !shouldDisplayDefaultChip,
    [classes.defaultChip]: shouldDisplayDefaultChip
  });

  return (
    <FlexContainer {...props}>
      <FlexItem dense="full">
        <Chip
          label={<ChipLabel label={title} stationItems={stationLinesWithData} type={TAGS_ENUM.LINE} />}
          ref={chipElement}
          onClick={e => {
            onChipClick(e);
          }}
          className={chipStyle}
          data-testid="line-chip"
        />
      </FlexItem>

      <FlexItem dense="full">
        <Popover
          {...chipProps}
          open={isPopoverOpen}
          anchorEl={chipElement.current}
          onClose={e => {
            onPopoverClose(e);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          PaperProps={{
            style: {
              backgroundColor: "white",
              borderRadius: 0,
              minWidth: 194,
              maxWidth: 250,
              boxShadow: "0px 8px 20px rgba(135, 155, 195, 0.2)",
              border: `1px solid ${vantiColors.gray18}`,
              height: 210
            }
          }}
        >
          <Lines
            stationLinesMap={stationLinesMap}
            lines={lineOptions}
            stationId={stationId}
            onPopoverClose={onPopoverClose}
          />
        </Popover>
      </FlexItem>
    </FlexContainer>
  );
};

export default memo(LineChip);
