import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { DarkToolTip } from "components/ui/tooltips/tooltip";
import Text from "components/ui/Typography/Text";
import cn from "classnames";

import { styles } from "./EllipsisText.styles";

const EllipsisText = ({
  className,
  tooltipClassName,
  classes,
  tooltipText,
  arrow = true,
  placement = "bottom",
  style = {},
  children,
  interactive,
  weight = 400,
  size = "14px",
  color,
  body,
  ...rest
}) => {
  const [isEllipsisShown, setIsEllipsisShown] = useState(false);

  const divRef = useRef();

  const isEllipsisActive = useCallback(
    e => {
      return e.offsetWidth < e.scrollWidth;
    },
    [tooltipText, children]
  );

  useEffect(() => {
    if (divRef.current) {
      setIsEllipsisShown(isEllipsisActive(divRef.current));
    }
  }, [divRef.current]);

  return isEllipsisShown ? (
    <DarkToolTip
      classes={{
        tooltip: tooltipClassName
      }}
      interactive={interactive}
      placement={placement}
      arrow={arrow}
      title={tooltipText || children}
    >
      <Text
        ref={divRef}
        style={style}
        weight={weight}
        size={size}
        color={color}
        body={body}
        className={cn(classes.root, className)}
        {...rest}
      >
        {children}
      </Text>
    </DarkToolTip>
  ) : (
    <Text
      ref={divRef}
      style={style}
      weight={weight}
      size={size}
      color={color}
      body={body}
      className={cn(classes.root, className)}
      {...rest}
    >
      {children}
    </Text>
  );
};

EllipsisText.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default withStyles(styles)(EllipsisText);
