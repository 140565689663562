import React, { memo } from "react";

const ChatIcon80 = ({ ...rest }) => {
  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.5" width="80" height="80" rx="40" fill="#F9F9F9" />
      <mask
        id="mask0_2937_12021"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="12"
        y="12"
        width="57"
        height="56"
      >
        <rect x="12.5" y="12" width="56" height="56" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2937_12021)">
        <path
          d="M27.6667 43.5H44V41.1667H27.6667V43.5ZM27.6667 36.5H53.3333V34.1667H27.6667V36.5ZM27.6667 29.5H53.3333V27.1667H27.6667V29.5ZM19.5 58.8462V19H61.5V51.6667H26.6795L19.5 58.8462ZM25.0641 49.3333H59.1667V21.3333H21.8333V52.5641L25.0641 49.3333Z"
          fill="#848B99"
        />
      </g>
    </svg>
  );
};

export default memo(ChatIcon80);
