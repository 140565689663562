import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialModal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import classNames from "classnames";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { vantiColors } from "../../../assets/jss/vanti-dashboard";
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0"
  },
  paper: {
    backgroundColor: vantiColors.white,
    minHeight: "10vh",
    minWidth: "33vw",
    borderRadius: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  paperFullscreen: {
    width: "100%",
    height: "100%"
  }
});

const Modal = ({ children, open, fullscreen, scrollable, modalProps = {}, paperProps = {} }) => {
  const classes = useStyles();

  const { className: modalClassName, ...restModalProps } = modalProps;
  const { className: paperClassName, ...restPaperProps } = paperProps;

  return (
    <MaterialModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classNames({
        [classes.modal]: true,
        [modalClassName]: modalClassName !== undefined
      })}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      open={open}
      {...restModalProps}
    >
      <Fade in={open}>
        <Paper
          className={classNames({
            [classes.paper]: true,
            [classes.paperFullscreen]: fullscreen,
            [paperClassName]: paperClassName !== undefined
          })}
          {...restPaperProps}
        >
          {scrollable && <PerfectScrollbar>{children}</PerfectScrollbar>}
          {!scrollable && children}
        </Paper>
      </Fade>
    </MaterialModal>
  );
};

export default memo(Modal);

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  block: PropTypes.bool,
  fullscreen: PropTypes.bool,
  scrollable: PropTypes.bool,
  modalProps: PropTypes.object,
  paperProps: PropTypes.object
};
