import { put, takeLatest, call } from "redux-saga/effects";
import * as stationService from "services/api/station";
import * as modelService from "services/api/block";
import { actionTypes, fetchingFlagsTypes } from "./actionTypes";

function* createStationSaga(action) {
  try {
    const { stationName, stationDescription, stationType = null } = action.payload;
    const stationRes = yield call(stationService.createStation, stationName, stationDescription, stationType);
    yield put({
      type: actionTypes.ONBOARDING_SET_STATION,
      payload: {
        stationId: stationRes.id,
        stationDescription: stationRes.description,
        stationName: stationRes.name,
        type: stationRes.type
      }
    });
  } catch (error) {
    yield put({ type: fetchingFlagsTypes.ONBOARDING_QUERY_ERROR, payload: { error } });
  }
}

export function* createStationWatcher() {
  yield takeLatest(actionTypes.ONBOARDING_CREATE_STATION, createStationSaga);
}

function* createModelSaga(action) {
  try {
    const { stationId, modelName, modelDescription } = action.payload;
    const modelRes = yield call(modelService.createBlock, stationId, modelName, modelDescription);
    yield put({ type: fetchingFlagsTypes.ONBOARDING_MODEL_QUERY_SUCCESS, payload: { modelId: modelRes.id } });
  } catch (error) {
    yield put({ type: fetchingFlagsTypes.ONBOARDING_QUERY_ERROR, payload: { error } });
  }
}

export function* createModelWatcher() {
  yield takeLatest(actionTypes.ONBOARDING_CREATE_MODEL, createModelSaga);
}
