import React, { memo } from "react";
import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import SearchIcon24 from "assets/icons/search-icon/SearchIcon24";

const SearchBarWrapper = styled("div")({
  position: "relative"
});

const IconWrapper = styled("div")({
  position: "absolute",
  left: 8,
  top: "50%",
  transform: "translateY(-50%)",
  display: "flex"
});

const Input = styled("input")(({ width }) => ({
  width,
  height: 40,
  backgroundColor: "transparent",
  padding: "4px 8px 4px 40px",
  border: `1px solid ${vantiColors.gray36}`,
  fontFamily: "Inter",
  fontSize: 14,
  color: vantiColors.lightGray16,
  borderRadius: 4,
  boxSizing: "border-box"
}));

const SearchBar = ({ placeholder, width = "100%", onChange = () => {} }) => {
  return (
    <SearchBarWrapper>
      <IconWrapper>
        <SearchIcon24 />
      </IconWrapper>

      <Input placeholder={placeholder} width={width} onChange={e => onChange(e.target.value)} />
    </SearchBarWrapper>
  );
};

export default memo(SearchBar);
