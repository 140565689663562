import React from "react";

import { FlexItem } from "components/ui";

import { ReactComponent as VantiFullLogo } from "assets/icons/VantiFullLogo.svg";

import useStyles from "./styles";

const CardSubHeader = ({ index }) => {
  const classes = useStyles();

  return (
    <FlexItem
      container
      justifyContent="center"
      dense="full"
      className={classes.subHeaderContainer}
      data-testid="create-account-vanti-logo-section"
    >
      <FlexItem dense="full">
        <VantiFullLogo data-testid={`create-account-vanti-logo-${index}`} />
      </FlexItem>
    </FlexItem>
  );
};

export default CardSubHeader;
