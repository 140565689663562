export const actionTypes = {
  ONBOARDING_CLEAR: "onboarding.clear",
  ONBOARDING_SET_EXISTING_CONFIG: "onboarding.set.existing.config",

  ONBOARDING_SET_WARNINGS: "onboarding.set.warning",
  ONBOARDING_ADD_WARNING: "onboarding.add.warning",
  ONBOARDING_REMOVE_WARNING: "onboarding.remove.warning",

  ONBOARDING_CREATE_STATION: "onboarding.create.station",
  ONBOARDING_SET_STATION: "onboarding.set.station",
  ONBOARDING_SET_STATION_ID: "onboarding.set.station.id",
  ONBOARDING_SET_STATION_NAME: "onboarding.set.station.name",
  ONBOARDING_SET_STATION_ERROR: "onboarding.set.station.error",
  ONBOARDING_SET_STATION_DESCRIPTION: "onboarding.set.station.description",
  ONBOARDING_SET_STATION_PRODUCT: "onboarding.set.station.product",
  ONBOARDING_UNSET_STATION_PRODUCT: "onboarding.unset.station.product",
  ONBOARDING_SET_STATION_LINES: "onboarding.set.station.lines",
  ONBOARDING_UNSET_STATION_LINE: "onboarding.unset.station.line",
  ONBOARDING_DELETE_STATION_LINES: "onboarding.delete.station.line",
  ONBOARDING_DELETE_STATION_PRODUCT: "onboarding.delete.station.product",
  ONBOARDING_EDIT_STATION_PRODUCT_SUCCESS: "onboarding.edit.station.product.success",
  ONBOARDING_EDIT_STATION_LINE_SUCCESS: "onboarding.edit.station.line.success",

  ONBOARDING_CREATE_MODEL: "onboarding.create.model",
  ONBOARDING_SET_MODEL_NAME: "onboarding.set.model.name",
  ONBOARDING_SET_MODEL_TYPE: "onboarding.set.model.type",
  ONBOARDING_SET_MODEL_ERROR: "onboarding.set.model.error",
  ONBOARDING_SET_MODEL_ID: "onboarding.set.model.id",
  ONBOARDING_CLEAR_MODEL_ERROR: "onboarding.clear.model.error",
  ONBOARDING_SET_FILE: "onboarding.set.file",
  ONBOARDING_SET_KPI_LABELS: "onboarding.set.kpi.labels",
  ONBOARDING_SET_KPI_INDEXES: "onboarding.set.kpi",
  ONBOARDING_SET_TIME_SERIES_INDEXES: "onboarding.set.timeSeries",
  ONBOARDING_SET_COLUMN_IDENTIFIER_INDEXES: "onboarding.set.column.identifier",
  ONBOARDING_SET_SERIES_ID_INDEXES: "onboarding.set.series.id",
  ONBOARDING_CLEAR_FILE: "onboarding.clear.model.file",

  ONBOARDING_CONFIGURATION_SET_AUTO_MODE: "onboarding.model.configuration.autoMode",
  ONBOARDING_CONFIGURATION_RESET: "onboarding.model.configuration.reset",
  ONBOARDING_CONFIGURATION_SET_SMART_CROP: "onboarding.model.configuration.smartCrop",
  ONBOARDING_CONFIGURATION_SET_CLUSTERS_NUMBER: "onboarding.model.configuration.numOfClusters",
  ONBOARDING_CONFIGURATION_SET_AVERAGE_FAIL_RATE: "onboarding.model.configuration.failRate",
  ONBOARDING_CONFIGURATION_SET_MODEL_AUGMENTATION: "onboarding.model.configuration.augmentationValue",
  ONBOARDING_CONFIGURATION_SET_DATA_REFINEMENT: "onboarding.model.configuration.dataRefinement",
  ONBOARDING_CONFIGURATION_SET_SENSITIVITY: "onboarding.model.configuration.sensitivity",
  ONBOARDING_NAVIGATION_SET_CURRENT_STEP: "onboarding.navigation.stepper.currentStep",
  ONBOARDING_NAVIGATION_SET_NEXT_CALLBACK: "onboarding.set.navigation.next.callback",
  ONBOARDING_SET_PIPELINE_VERSION: "onboarding.set.pipeline.version",
  ONBOARDING_CONFIGURATION_SET_FALSE_POSITIVE_MAXIMUM_RATE: "onboarding.model.configuration.falsePositiveMaxRate",
  ONBOARDING_CONFIGURATION_SET_FALSE_NEGATIVE_MAXIMUM_RATE: "onboarding.model.configuration.falseNegativeMaxRate",
  ONBOARDING_SUBMIT_PAGE_LOADED_STATUS: "onboarding.submit.page.loaded.status",
  ONBOARDING_SUBMIT_PAGE_TRAINING_STATUS: "onboarding.submit.page.training.status",
  ONBOARDING_CONFIGURATION_SET_IS_TIME_SPLIT_SELECTED: "onboarding.model.configuration.set.is.timeSplit.selected"
};

export const fetchingFlagsTypes = {
  ONBOARDING_STATION_QUERY_SUCCESS: "onboarding.station.query.success",
  ONBOARDING_MODEL_QUERY_SUCCESS: "onboarding.model.query.success",
  ONBOARDING_QUERY_ERROR: "onboarding.query.error"
};
