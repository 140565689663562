import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const AlertIcon16 = ({ stroke = vantiColors.gray29, fill = vantiColors.gray29 }) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8.5" r="7.5" stroke={stroke} />
      <path d="M8.49999 9.5105H7.5L7.50001 4.5105H8.49999V9.5105Z" fill={fill} />
      <path
        d="M8.4843 12.3C8.35799 12.4263 8.19659 12.4895 8.0001 12.4895C7.80361 12.4895 7.6422 12.4263 7.51589 12.3C7.38957 12.1737 7.32642 12.0123 7.32642 11.8158C7.32642 11.6193 7.38957 11.4579 7.51589 11.3316C7.6422 11.2052 7.80361 11.1421 8.0001 11.1421C8.19659 11.1421 8.35799 11.2052 8.4843 11.3316C8.61062 11.4579 8.67378 11.6193 8.67378 11.8158C8.67378 12.0123 8.61062 12.1737 8.4843 12.3Z"
        fill={fill}
      />
    </svg>
  );
};

export default memo(AlertIcon16);
