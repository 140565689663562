import { createContext, useMemo, useState } from "react";

const defaultValue = {
  isTreeViewOpen: false,
  treeViewContent: {},
  treeViewSearchValue: "",
  setIsTreeViewOpen: () => {},
  setTreeViewContent: () => {},
  setTreeViewSearchValue: () => {}
};

export const TreeViewContext = createContext({ ...defaultValue });

export const useTreeViewContext = () => {
  const [isTreeViewOpen, setIsTreeViewOpen] = useState(defaultValue.isTreeViewOpen);
  const [treeViewContent, setTreeViewContent] = useState(defaultValue.treeViewContent);
  const [treeViewSearchValue, setTreeViewSearchValue] = useState(defaultValue.treeViewSearchValue);

  return useMemo(
    () => ({
      setIsTreeViewOpen,
      setTreeViewContent,
      setTreeViewSearchValue,
      isTreeViewOpen,
      treeViewContent,
      treeViewSearchValue
    }),
    [treeViewContent, isTreeViewOpen, treeViewSearchValue]
  );
};
