import http from "../http";
import config from "common/config";

const TAGS_API_URL = `${config.serverUrl}/api/v2/tags`;

export async function getAllAccountTags() {
  const res = await http.get(`${TAGS_API_URL}/`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function createTag(reportId, value) {
  const res = await http.post(`${TAGS_API_URL}/`, { reportId, value });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function deleteTag(reportId, tagId) {
  const res = await http.delete(`${TAGS_API_URL}/${reportId}/${tagId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}
