export const actionTypes = {
  ACCOUNT_CHANGE_USER_PERMISSIONS_REQUEST: "account.changeUserPermissions.request",
  ACCOUNT_CHANGE_USER_PERMISSIONS_SUCCESS: "account.changeUserPermissions.success",
  ACCOUNT_CHANGE_USER_PERMISSIONS_ERROR: "account.changeUserPermissions.error",
  ACCOUNT_CHANGE_USER_PERMISSIONS_FULFILL: "account.changeUserPermissions.fulfill"
};

export function changeUserPermissionsRequestAction(users) {
  return { type: actionTypes.ACCOUNT_CHANGE_USER_PERMISSIONS_REQUEST, payload: users };
}
