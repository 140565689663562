import React, { memo } from "react";
import Text from "components/ui/Typography/Text";
import { vantiColors } from "assets/jss/palette";
import ErrorIcon from "assets/icons/error/ErrorIcon";
import useStyles from "./styles";
import { Box } from "@material-ui/core";

const FileErrorSection = ({ children, tip, ...props }) => {
  const classes = useStyles();
  const { className } = { ...props };

  return (
    <Box data-testid="file-upload-error-message" className={className || classes.errorContainer}>
      <Box className={classes.errorIconWrapper}>
        <ErrorIcon />
      </Box>

      <Box className={classes.errorMessage}>
        <Text color={vantiColors.darkBlue6} size="12px">
          {children} {tip}
        </Text>
      </Box>
    </Box>
  );
};

export default memo(FileErrorSection);
