import * as auth from "modules/auth";
import * as user from "modules/user";
import * as blockModule from "modules/block";
import * as stationModule from "modules/station";
import * as sandboxModule from "modules/sandbox";
import * as notificationModule from "modules/notification";
import * as onboardingModule from "modules/onboarding";
import * as predictionResultsSagas from "modules/prediction";
import * as dataConnectorsModule from "modules/data-connectors";
import * as webNotifications from "modules/web-notifications";
import * as dataCurationsModule from "modules/data-curations";
import * as account from "modules/account";

import { all } from "redux-saga/effects";

export function* sagas() {
  yield all([
    sandboxModule.sagas(),
    auth.sagas(),
    account.sagas(),
    blockModule.sagas(),
    user.sagas(),
    stationModule.sagas(),
    onboardingModule.sagas(),
    predictionResultsSagas.sagas(),
    notificationModule.sagas(),
    dataConnectorsModule.sagas(),
    dataCurationsModule.sagas(),
    webNotifications.sagas()
  ]);
}
