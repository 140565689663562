import { takeLatest, call, put } from "redux-saga/effects";

import * as webNotificationService from "services/api/webNotifications";

import { actionTypes } from "./actions";
import { actionTypes as stateActionTypes } from "../../state/actions";

export function* updateNotificationWorker({ payload }) {
  const { id, updatedNotification, serverEmit, updateStore } = payload;

  try {
    const result = yield call(webNotificationService.updateWebNotification, id, updatedNotification, serverEmit);
    if (updateStore) {
      yield put({ type: stateActionTypes.UPDATE_WEB_NOTIFICATION, payload: result });
    }

    yield put({ type: actionTypes.UPDATE_WEB_NOTIFICATION_SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_WEB_NOTIFICATION_ERROR, payload: { error } });
  } finally {
    yield put({ type: actionTypes.UPDATE_WEB_NOTIFICATION_FULFILL, payload: {} });
  }
}

export default function* webNotificationsWatcher() {
  yield takeLatest(actionTypes.UPDATE_WEB_NOTIFICATION_REQUEST, updateNotificationWorker);
}
