import { vantiColors } from "assets/jss/palette";
import React, { memo } from "react";

const CircleSolidIcon = ({ color = vantiColors.green1, width = 16 }) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="circle"
      className="svg-inline--fa fa-circle fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
    >
      <path fill={color} d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
    </svg>
  );
};

export default memo(CircleSolidIcon);
