import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import predictionResultsReducer from "./reducer";
import { predictionWatcher } from "./sagas";

export default combineReducers({
  state: predictionResultsReducer
});

export function* sagas() {
  yield all([predictionWatcher()]);
}
