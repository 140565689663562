import { call, put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./actions";
import { setUserStateAction } from "../../state/actions";
import * as userService from "services/api/user";

export function* fetchUserWorker() {
  try {
    const user = yield call(userService.getUser);
    yield put({ type: actionTypes.FETCH_USER_SUCCESS });
    yield put(setUserStateAction(user));
  } catch (error) {
    yield put({ type: actionTypes.FETCH_USER_ERROR, payload: { error } });
  } finally {
    yield put({ type: actionTypes.FETCH_USER_FULFILL, payload: {} });
  }
}

export default function* fetchUserWatcher() {
  yield takeLatest(actionTypes.FETCH_USER_REQUEST, fetchUserWorker);
}
