import React, { memo } from "react";
import Card from "components/ui/Card/Card";
import CardBody from "components/ui/Card/CardBody";
import Text from "components/ui/Typography/Text";
import CardSection from "components/ui/Card/CardSection";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "@material-ui/core";
import { ReactComponent as IconWhite } from "assets/img/icons/vanti-icon-white.svg";
import useStyles from "./styles";
import { vantiColors } from "assets/jss/palette";

const GEN_AI_POWERED_TEXT = "GenAI-Powered";
const MANUFACTURING_INSIGHTS_TEXT = "Manufacturing Insights";
const CREATE_NEW_ACCOUNT_TEXT = "Create new account";
const DONT_HAVE_AN_ACCOUNT_TEXT = "Don't have an account?";

const AuthCard = ({ children, withSignUp }) => {
  const classes = useStyles();

  return (
    <Card data-testid="card-sign-in" direction="row" className={classes.card}>
      <Hidden smDown>
        <CardSection className={classes.cardLeftSection}>
          <CardBody data-testid="card-body-sign-in" className={classes.cardBody}>
            <Text data-testid="card-title-text" color={vantiColors.white} size={22} weight="bold" lineSize="1.8em">
              {GEN_AI_POWERED_TEXT}
              <br />
              {MANUFACTURING_INSIGHTS_TEXT}
            </Text>

            {withSignUp && (
              <div className={classes.signUp}>
                <div data-testid="dont-have-an-account-text">
                  <Text size="14px" color="#FFF">
                    {DONT_HAVE_AN_ACCOUNT_TEXT}
                  </Text>
                  <br />
                  <Link
                    data-testid="link-create-account"
                    className={classes.link}
                    underlineAlways={true}
                    href="/create-account/"
                  >
                    <Text size="14px" color="#FFF">
                      {CREATE_NEW_ACCOUNT_TEXT}
                    </Text>
                  </Link>
                </div>
              </div>
            )}
            <IconWhite className={classes.icon} />
          </CardBody>
        </CardSection>
      </Hidden>

      <CardSection className={classes.cardRightSection}>{children}</CardSection>
    </Card>
  );
};

export default memo(AuthCard);
