import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button } from "@material-ui/core";
import { Text } from "components/ui";
import CustomDialog from "components/ui/Dialog/CustomDialog";

import { navigationSetBreadcrumbsType } from "modules/navigation/actions";

import { BREADCRUMBS_TYPES } from "common/constants/NavigationConstants";

import { grayColor, redColor, vantiColors } from "assets/jss/palette";

const LeavePageDialog = ({ fieldsChanged }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [nextLocationProps, setNextLocationProps] = useState({});

  useEffect(() => {
    dispatch(navigationSetBreadcrumbsType(BREADCRUMBS_TYPES.NEW_CONNECTOR));

    return () => {
      dispatch(navigationSetBreadcrumbsType(BREADCRUMBS_TYPES.DATA_CONNECTORS));
    };
  }, []);

  useEffect(() => {
    history.block(tx => {
      if (history.location.pathname === tx.pathname) {
        return true;
      }

      if (nextLocationProps.force) {
        return true;
      }

      if (!fieldsChanged) {
        return true;
      }

      const nextLocationPropsNew = {
        ...nextLocationProps,
        nextPath: tx.pathname
      };

      setNextLocationProps(nextLocationPropsNew);

      return nextLocationProps.nextPath ? true : false;
    });
  }, [history, nextLocationProps, fieldsChanged]);

  return (
    <CustomDialog
      isOpened={nextLocationProps.nextPath}
      title={"Process cancellation"}
      body={<Text size={16}>Would you like to leave this page without saving your changes?</Text>}
    >
      <Button
        onClick={() => {
          const nextLocationPropsNew = {
            ...nextLocationProps,
            nextPath: null,
            state: null
          };
          setNextLocationProps(nextLocationPropsNew);
        }}
      >
        <Text color={vantiColors.gray23}>Cancel</Text>
      </Button>
      <Button
        onClick={() => {
          history.push(nextLocationProps.nextPath);
        }}
      >
        <Text color={vantiColors.red5}>Leave</Text>
      </Button>
    </CustomDialog>
  );
};

export default memo(LeavePageDialog);
