import { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import * as reportsApi from "../../services/api/report";
import { AppContext } from "./context-hooks/use-app-context";
import useDashboardRequest from "./use-dashboard-request";

const GET_ACCOUNT_REPORTS_REQUEST_KEY = "get.account.reports.request.key";
const DELETE_REPORT_REQUEST_KEY = "delete.report.request.key";
const TOGGLE_REPORT_TAG_REQUEST_KEY = "toggle.report.tag.request.key";

const useReportRequest = () => {
  const { reportsContext, dashboardContext, tagsContext } = useContext(AppContext);
  const { reports, setReports, setReportIdToDelete, setSelectedTableRows } = reportsContext;
  const { tags } = tagsContext;
  const { setOpenExpandedDashboardItem } = dashboardContext;
  const { ctxDeleteDashboardItems } = useDashboardRequest();

  const getAllAccountReports = useQuery(GET_ACCOUNT_REPORTS_REQUEST_KEY, () => reportsApi.getAllAccountReports(), {
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    onSettled: reports => {
      const reportsDict = {};

      if (!reports || !reports.length) {
        setReports({});
        return;
      }

      reports.forEach(report => {
        reportsDict[report._id] = { ...report };
      });

      setReports(reportsDict);
    }
  });

  const deleteReports = useMutation([DELETE_REPORT_REQUEST_KEY, reports], ({ ids }) => reportsApi.deleteReports(ids), {
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    onSuccess: ({ deletedReports, deletedDashboardItems }) => {
      const reportsNew = { ...reports };

      for (const report of deletedReports) {
        delete reportsNew[report._id];
      }

      setReports(reportsNew);
      ctxDeleteDashboardItems(deletedDashboardItems);

      setReportIdToDelete(null);
      setOpenExpandedDashboardItem(null);
      setSelectedTableRows([]);
    }
  });

  const toggleReportTag = useMutation(
    TOGGLE_REPORT_TAG_REQUEST_KEY,
    ({ reportId, tagId }) => reportsApi.toggleReportTag(reportId, tagId),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: (data, { reportId, tagId }) => {
        setReports(prevValue => {
          const prevValueCopy = { ...prevValue };

          if (prevValueCopy[reportId]?.reportData?.tags) {
            const tagIndex = prevValueCopy[reportId].reportData.tags.findIndex(tag => tag._id === tagId);

            if (tagIndex >= 0) {
              prevValueCopy[reportId].reportData.tags.splice(tagIndex, 1);
            } else {
              const foundTag = tags.find(tag => tag._id === tagId);
              prevValueCopy[reportId].reportData.tags.push(foundTag);
            }
          }

          return prevValueCopy;
        });
      }
    }
  );

  return {
    getAllAccountReports,
    deleteReports,
    toggleReportTag
  };
};

export default useReportRequest;
