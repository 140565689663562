import React, { memo } from "react";
import { styled } from "@material-ui/core";
import PlusIconBG40 from "assets/icons/plus-icon/PlusIconBG40";

const PlusIconAnimatedOnHover = ({ isHovered, ...rest }) => {
  return (
    <PlusIconAnimatedWrapper {...rest}>
      <PlusIconAnimated isHovered={isHovered} />
    </PlusIconAnimatedWrapper>
  );
};

const PlusIconAnimatedWrapper = styled("div")({
  padding: 4
});

const PlusIconAnimated = styled(PlusIconBG40)(({ isHovered }) => ({
  transform: `rotate(${isHovered ? 90 : 0}deg)`,
  transformOrigin: "center",
  transition: "all ease-in-out 0.3s"
}));

export default memo(PlusIconAnimatedOnHover);
