import React, { memo, useCallback, useContext, useEffect } from "react";

import { Button, Grid, IconButton, styled } from "@material-ui/core";

import StyledVantiStickyWarningBanner from "./style";

import { useDispatch, useSelector } from "react-redux";

import WhiteExclamationMark from "assets/icons/sticky-warning-banner/WhiteExclamationMark";
import DismissNotificationIcon from "assets/icons/notification-center/DismissNotificationIcon";
import { vantiColors } from "assets/jss/palette";

import {
  WARNING_BANNER_LABEL_TEXT,
  WARNING_BANNER_OPEN_NOTIFICATIONS_LABEL_TEXT
} from "common/constants/StickyWarningBannerConstants";

import moment from "moment/moment";

import { activeWarningWebNotificationSelector } from "modules/web-notifications/state/selectors";
import { uidSelector } from "modules/auth/state/selectors";
import { updateWebNotificationListRequestAction } from "modules/web-notifications/operations/update-web-notification-list/actions";
import { AppNotificationsContext } from "common/hooks/context-hooks/use-notifications-context";

const StyledVantiStickyWarningBannerContentWrapper = styled(Grid)(({ theme }) => ({
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}));

const StyledVantiStickyWarningBannerText = styled("div")(
  ({ theme, texttransform = "none", textdecorationline = "none" }) => ({
    textTransform: texttransform,
    textDecorationLine: textdecorationline,
    fontFamily: "Inter",
    fontSize: theme.spacing(1.75),
    fontWeight: "500",
    paddingRight: theme.spacing(3),
    letterSpacing: "0px",
    textAlign: "left"
  })
);

const StyledWhiteExclamationMark = styled(WhiteExclamationMark)(({ theme }) => ({
  paddingRight: `${theme.spacing(0.8125)}px`,
  color: "red"
}));

const StyledViewNotificationBannerButton = styled(Button)(({ theme }) => ({
  border: "none",
  color: vantiColors.white,
  height: "100%",
  width: "fit-content",
  padding: theme.spacing(0, 0, 0, 0),
  "&:hover": {
    backgroundColor: "transparent"
  }
}));

const StyledDismissNotificationIcon = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "16px",
  width: "16px",
  padding: theme.spacing(1, 2, 0, 0)
}));

const VantiStickyWarningBanner = () => {
  const userId = useSelector(uidSelector);
  const activeAccountWarningNotifications = useSelector(activeWarningWebNotificationSelector) || null;
  const dispatch = useDispatch();
  const {
    setIsNotificationCenterOpen,
    setSelectedNotificationCenterSecondaryTabIndex,
    setIsWarningBannerDisplayed,
    isWarningBannerDisplayed
  } = useContext(AppNotificationsContext);

  useEffect(() => {
    const hasSomeActiveNonretiredError = activeAccountWarningNotifications.some(item => {
      const userInfoEntry = item.usersInfo[userId];
      if (!userInfoEntry) {
        return true;
      }

      const retireTime = userInfoEntry.hideUntil;
      return (userInfoEntry.active && !retireTime) || moment().isAfter(retireTime);
    });

    setIsWarningBannerDisplayed(hasSomeActiveNonretiredError);
  }, [activeAccountWarningNotifications, userId]);

  const handleDismiss = useCallback(() => {
    const list = [];
    for (const notification of activeAccountWarningNotifications) {
      list.push({
        id: notification.id,
        updatedNotificationFields: {
          userId,
          hideUntil: moment().add(1, "days")
        }
      });
    }
    dispatch(updateWebNotificationListRequestAction(list, false, false));
  }, [activeAccountWarningNotifications, userId]);

  const handleDismissClick = useCallback(() => {
    setIsWarningBannerDisplayed(false);
    handleDismiss();
  }, [handleDismiss, setIsWarningBannerDisplayed]);

  const handleViewNotificationClick = useCallback(() => {
    setIsWarningBannerDisplayed(false);
    handleDismiss();
    setSelectedNotificationCenterSecondaryTabIndex(1);

    setTimeout(() => {
      setIsNotificationCenterOpen(true);
    }, 200);
  }, [handleDismiss, setSelectedNotificationCenterSecondaryTabIndex, setIsNotificationCenterOpen]);

  return (
    isWarningBannerDisplayed && (
      <StyledVantiStickyWarningBanner data-testid={"sticky-warning-banner"}>
        <div />
        <StyledVantiStickyWarningBannerContentWrapper data-testid={"sticky-warning-banner-content-wrapper"}>
          <StyledWhiteExclamationMark />
          <StyledVantiStickyWarningBannerText>{WARNING_BANNER_LABEL_TEXT}</StyledVantiStickyWarningBannerText>
          <StyledViewNotificationBannerButton disableRipple onClick={handleViewNotificationClick}>
            <StyledVantiStickyWarningBannerText texttransform={"lowercase"} textdecorationline={"underline"}>
              {WARNING_BANNER_OPEN_NOTIFICATIONS_LABEL_TEXT}
            </StyledVantiStickyWarningBannerText>
          </StyledViewNotificationBannerButton>
        </StyledVantiStickyWarningBannerContentWrapper>
        <StyledDismissNotificationIcon>
          <IconButton onClick={handleDismissClick}>
            <DismissNotificationIcon fill={vantiColors.white}></DismissNotificationIcon>
          </IconButton>
        </StyledDismissNotificationIcon>
      </StyledVantiStickyWarningBanner>
    )
  );
};

export default memo(VantiStickyWarningBanner);
