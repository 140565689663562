import React, { memo, useContext, useEffect, useState } from "react";
import { styled } from "@material-ui/core";
import CheckboxFilled24 from "assets/icons/checkbox/CheckboxFilled24";
import CheckboxUnfilled24 from "assets/icons/checkbox/CheckboxUnfilled24";
import { vantiColors } from "assets/jss/palette";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import Tag from "./Tag";
import useReportRequest from "common/hooks/use-report-request";
import { Text } from "components/ui";
import useTagsRequest from "common/hooks/use-tags-request";
import Loader from "components/ui/Loader";

const Wrapper = styled("div")({
  padding: 8,
  width: 212
});

const Input = styled("input")({
  width: "100%",
  height: 40,
  boxSizing: "border-box",
  border: `1px solid ${vantiColors.gray36}`,
  borderRadius: 4,
  padding: "4px 8px"
});

const TagStyled = styled("div")(({ isDisabled }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: 8,
  height: 56,
  padding: "0px 12px",
  cursor: isDisabled ? "not-allowed" : "pointer",
  "&:hover": {
    backgroundColor: vantiColors.gray18
  }
}));

const TagsList = styled("div")({
  maxHeight: 226,
  overflow: "scroll"
});

const AddNewTagWrapper = styled("div")({
  height: 56,
  display: "flex",
  columnGap: 8,
  padding: "0px 12px",
  alignItems: "center",
  cursor: "pointer",
  borderTop: `1px solid ${vantiColors.gray1}`,
  "&:hover": {
    backgroundColor: vantiColors.gray18
  }
});

const TagsListPopup = ({ tags = [], reportId }) => {
  const { toggleReportTag } = useReportRequest();
  const { createTag } = useTagsRequest();
  const { reportsContext } = useContext(AppContext);
  const { reports } = reportsContext;

  const [tagsToDisplay, setTagsToDisplay] = useState(tags);
  const [inputValue, setInputValue] = useState("");

  const onSearchValueChange = e => {
    const value = e.target.value;
    setInputValue(value);
  };

  const getIsChecked = tagId => {
    return reports[reportId]?.reportData?.tags?.find(reportTag => reportTag._id && reportTag._id === tagId);
  };

  const onTagClick = tagId => {
    toggleReportTag.mutate({ reportId, tagId });
  };

  const onCreateTagClick = () => {
    createTag.mutate({ reportId, value: inputValue });
  };

  useEffect(() => {
    setTagsToDisplay(tags);
  }, [tags]);

  useEffect(() => {
    if (inputValue.trim().length) {
      const filteredTags = tags.filter(tag => tag.value.toLowerCase().includes(inputValue.toLowerCase()));
      setTagsToDisplay(filteredTags);
      return;
    }

    setTagsToDisplay(tags);
  }, [inputValue]);

  return (
    <Wrapper>
      <>
        <Input placeholder="Type to search or add" onChange={onSearchValueChange} />
        {inputValue.trim().length > 0 && (
          <Text size={12} color={vantiColors.gray39}>
            Select or add new tag
          </Text>
        )}
      </>

      <TagsList>
        {tagsToDisplay.map((tag, index) => (
          <TagStyled
            key={tag.value}
            onClick={() => !toggleReportTag.isLoading && onTagClick(tag._id)}
            isDisabled={toggleReportTag.isLoading}
          >
            {getIsChecked(tag._id) ? <CheckboxFilled24 /> : <CheckboxUnfilled24 />}
            <Tag text={tag.value} order={index % 3} isPartOfGroup={false} isDisabled={toggleReportTag.isLoading} />
          </TagStyled>
        ))}
      </TagsList>

      {inputValue.trim().length > 0 &&
        (createTag.isLoading ? (
          <Loader />
        ) : (
          <AddNewTagWrapper onClick={onCreateTagClick}>
            <Text size={12}>+Add</Text>
            <Tag text={inputValue} order={0} isPartOfGroup={false} />
          </AddNewTagWrapper>
        ))}
    </Wrapper>
  );
};

export default memo(TagsListPopup);
