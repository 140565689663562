import React, { memo } from "react";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./styles";
import classNames from "classnames";

const Accordion = ({
  isSummaryExpanded,
  setIsSummaryExpanded,
  title,
  icon = <ExpandMoreIcon />,
  rotateIcon = true,
  className,
  style = {},
  detailsStyles = {},
  children
}) => {
  const classes = styles();

  const iconClasses = {
    expandIcon: !rotateIcon ? classes.expandIcon : classes.expandIconAccordion,
    expanded: !rotateIcon ? classes.expanded : classes.expandedAccordion
  };

  return (
    <MuiAccordion style={style} expanded={isSummaryExpanded} className={classNames(classes.accordion, className)}>
      <MuiAccordionSummary
        data-testid="accordion-summary"
        expandIcon={icon}
        onClick={setIsSummaryExpanded}
        classes={iconClasses}
      >
        <Typography data-testid="title">{title}</Typography>
      </MuiAccordionSummary>

      <MuiAccordionDetails style={detailsStyles}>{children}</MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default memo(Accordion);
