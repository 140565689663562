import React, { memo } from "react";
import { styled } from "@material-ui/core";

const SquareBulletItemWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
});

const SquareBulletStyled = styled("div")(({ theme, color }) => ({
  width: 16,
  height: 16,
  borderRadius: 2,
  marginRight: theme.spacing(1),
  backgroundColor: color
}));

const SquareBulletItem = ({ children, color, label }) => {
  return (
    <SquareBulletItemWrapper>
      <SquareBulletStyled data-testid={`color-bullet-${label.toLowerCase()}`} color={color} />
      {children}
    </SquareBulletItemWrapper>
  );
};

export default memo(SquareBulletItem);
