import { takeLatest, call, put, select } from "redux-saga/effects";

import * as webNotificationService from "services/api/webNotifications";

import { actionTypes } from "./actions";
import { actionTypes as stateActionTypes } from "../../state/actions";

import { webNotificationSelector } from "../../state/selectors";

export function* deleteNotificationWorker(action) {
  const notification = yield select(webNotificationSelector(action.payload.id));

  try {
    yield put({ type: stateActionTypes.HIDE_WEB_NOTIFICATION, payload: { id: action.payload.id } });

    yield call(webNotificationService.deleteWebNotification, action.payload.id);

    yield put({ type: actionTypes.DELETE_WEB_NOTIFICATION_SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.DELETE_WEB_NOTIFICATION_ERROR, payload: { error } });
    yield put({ type: stateActionTypes.ADD_WEB_NOTIFICATION, payload: notification });
  } finally {
    yield put({ type: actionTypes.DELETE_WEB_NOTIFICATION_FULFILL, payload: {} });
  }
}

export default function* webNotificationsWatcher() {
  yield takeLatest(actionTypes.DELETE_WEB_NOTIFICATION_REQUEST, deleteNotificationWorker);
}
