import React, { memo } from "react";
import { vantiColors } from "../jss/palette";

const MoreIconVertical24 = ({ color = vantiColors.gray40, ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="11.5" cy="4.5" r="1.5" transform="rotate(-90 11.5 4.5)" fill={color} />
      <circle cx="11.5" cy="11.5" r="1.5" transform="rotate(-90 11.5 11.5)" fill={color} />
      <circle cx="11.5" cy="18.5" r="1.5" transform="rotate(-90 11.5 18.5)" fill={color} />
    </svg>
  );
};

export default memo(MoreIconVertical24);
