import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import GeneralModalPreviewComponent from "components/ui/Modal/GeneralModalPreviewComponent";
import DatasetFileInfo from "pages/side-bar/data-page/components/DatasetFileInfo";
import ChatMessageReportDataPreviewTable from "./ChatMessageReportDataPreviewSection/ChatMessageReportDataPreviewTable";
import { getMessageDatabasePreview } from "services/api/chat";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { getDashboardItemDatabasePreview } from "services/api/dashboard";
import { styled } from "@material-ui/core";

export const DATASET_PREVIEW_MODAL_WIDTH = "84%";
export const DATASET_PREVIEW_MODAL_HEIGHT = "84%";

const LoadMoreButton = styled(GeneralVantiButton)(({}) => ({
  margin: "32px 0",
  width: "100%"
}));

const StyledChatMessageDatabaseReportPreviewModalWrapper = styled(GeneralModalPreviewComponent)(({}) => ({
  paddingBottom: 0
}));

const ChatMessageDatabaseReportPreviewModal = ({ chatMessage, onCloseHandler }) => {
  const [previewRows, setPreviewRows] = useState([]);
  const [isLoadingMoreItems, setIsLoadingMoreItems] = useState(false);
  const [isReachedMaximumRows, setIsReachedMaximumRows] = useState(false);

  const [currentPage, setCurrentPage] = useState(-1);

  const fetchNextChunk = useCallback(async () => {
    setIsLoadingMoreItems(true);
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
    const newPreviewRows = chatMessage.dashboardItemId
      ? await getDashboardItemDatabasePreview(chatMessage.dashboardItemId, newPage, 50)
      : await getMessageDatabasePreview(chatMessage?.chatId, chatMessage?._id, newPage, 50);
    setPreviewRows([...previewRows, ...newPreviewRows]);
    setIsLoadingMoreItems(false);

    if (newPreviewRows.length == 0) {
      setIsReachedMaximumRows(true);
    }
  }, [chatMessage, currentPage, previewRows]);

  const previewContents = useMemo(() => {
    if (!previewRows.length) {
      return {
        headers: [],
        body: []
      };
    }

    return {
      headers: chatMessage.reportData.previewRows[0],
      body: previewRows
    };
  }, [previewRows]);

  useEffect(() => {
    fetchNextChunk();
  }, []);

  return (
    <StyledChatMessageDatabaseReportPreviewModalWrapper
      height={DATASET_PREVIEW_MODAL_HEIGHT}
      width={DATASET_PREVIEW_MODAL_WIDTH}
      onClose={onCloseHandler}
    >
      <ChatMessageReportDataPreviewTable
        maxTableHeight={"700px"}
        isEnlargedPreview={true}
        lowOpacity={false}
        preview={previewContents}
        tableFooter={
          previewRows.length > 0 && (
            <LoadMoreButton
              buttonInfo={{
                label: "Load More",
                disabled: isReachedMaximumRows || isLoadingMoreItems,
                onClick: fetchNextChunk
              }}
              size={SIZES.SMALL}
              variant={VARIANTS.SECONDARY}
            />
          )
        }
      />
      <br />
    </StyledChatMessageDatabaseReportPreviewModalWrapper>
  );
};

export default memo(ChatMessageDatabaseReportPreviewModal);
