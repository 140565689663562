const env = { ...window["env"], ...process.env };

export default {
  /**
   * General
   */
  env: env.REACT_APP_ENV,
  nodeEnv: env.NODE_ENV,
  appName: env.REACT_APP_APP_NAME,
  version: env.REACT_APP_GIT_TAG,
  forceDev: env.REACT_APP_FORCE_DEV,
  trialDeployedModelsQuota: parseInt(env.REACT_APP_TRIAL_DEPLOYED_MODELS_QUOTA) ?? 5,

  /**
   * Monitoring
   */
  monitor: {
    applicationId: env.REACT_APP_DD_APP_ID,
    clientToken: env.REACT_APP_DD_CLIENT_TOKEN
  },

  /**
   * API Server
   */
  serverUrl: env.REACT_APP_SERVER_URL,

  /**
   * SSAPI Server
   */
  ssApiUrl: env.REACT_APP_SSAPI_URL,

  storage: {
    type: env.REACT_APP_AGNOSTIC_STORAGE_TYPE?.toLowerCase() || "s3"
  },
  /**
   * Evaporate
   */

  evaporate: {
    awsKey: env.REACT_APP_EVAPORATE_AWS_KEY,
    bucket: env.REACT_APP_EVAPORATE_BUCKET,
    batchPredictionsBucket: env.REACT_APP_BATCH_PREDICTIONS_BUCKET,
    batchFeedbacksBucket: env.REACT_APP_BATCH_FEEDBACKS_BUCKET,
    awsRegion: env.REACT_APP_EVAPORATE_AWS_REGION
  },

  /**
   * Logger
   */
  logs: {
    level: env.LOG_LEVEL || "info"
  },

  /**
   *  Sign up key
   */
  signUp: {
    key: env.SIGNUP_KEY
  },

  fullStory: {
    orgId: env.REACT_APP_FULLSTORY_ORG_ID,
    enable: env.REACT_APP_FULLSTORY_ENABLE
  },

  /**
   * PDF api
   */
  pdfApi: {
    link: env.REACT_APP_API_PDF_LINK
  },
  streamlit: {
    url: env.REACT_APP_STREAMLIT_URL || "",
    operatorViewUrl: env.REACT_APP_STREAMLIT_OPERATOR_VIEW_URL || "",
    chatCsvUrl: env.REACT_APP_STREAMLIT_CHAT_CSV || ""
  },

  rath: {
    url: env.REACT_APP_RATH_URL || ""
  }
};
