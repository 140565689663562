import React, { memo } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import { Text } from "components/ui/index";
import DialogActions from "@material-ui/core/DialogActions";
import GeneralVantiButton, { VARIANTS, SIZES } from "components/ui/Buttons/GeneralVantiButton";
import Dialog from "@material-ui/core/Dialog";
import { styled } from "@material-ui/core";
import CloseIcon16 from "assets/icons/close-icon/CloseIcon16";
import AlertIcon16 from "assets/icons/alert-icon/AlertIcon16";
import { vantiColors } from "assets/jss/palette";

const DIALOG_BACK_TO_EDIT_TEXT = "Cancel";
const DIALOG_LEAVE_PAGE_TEXT = "Leave page";
const DIALOG_HEADER_TEXT = "Leave page";
const DIALOG_BODY_TEXT = "Are you sure you want to leave this page?";

const CustomUnsavedChangesDialog = ({ showDialogConditions, onConfirmationHandler }) => {
  return (
    <Dialog
      open={showDialogConditions.every(close => close)}
      data-test={"semantic-info-leave-confirmation-prompt"}
      data-testid={"rubric-unsaved-changes-dialog"}
    >
      <DialogContainer data-testid={"rubric-unsaved-changes-dialog-container"}>
        <DialogContentStyled data-testid={"rubric-unsaved-changes-dialog-content"}>
          <DialogHeaderStyled data-testid={"rubric-unsaved-changes-dialog-header"}>
            <AlertIconAndTitleWrapper data-testid={"rubric-unsaved-changes-dialog-alert-and-title-wrapper"}>
              <AlertIcon16 stroke={vantiColors.rose6} fill={vantiColors.rose6} />
              <Text size={18} lineSize={"21.78px"} weight={500} color={vantiColors.gray29}>
                {DIALOG_HEADER_TEXT}
              </Text>
            </AlertIconAndTitleWrapper>

            <CloseIcon16Styled onClick={() => onConfirmationHandler(false)} />
          </DialogHeaderStyled>
          <Text lineSize={"16.94px"} color={vantiColors.gray39}>
            {DIALOG_BODY_TEXT}
          </Text>
        </DialogContentStyled>
        <DialogActions data-testid={"rubric-unsaved-changes-dialog-actions"}>
          <GeneralVantiButton
            variant={VARIANTS.TEXT_LINK}
            size={SIZES.SMALL}
            buttonInfo={{
              label: DIALOG_BACK_TO_EDIT_TEXT,
              onClick: () => onConfirmationHandler(false)
            }}
          />
          <GeneralVantiButton
            size={SIZES.SMALL}
            buttonInfo={{
              label: DIALOG_LEAVE_PAGE_TEXT,
              onClick: () => onConfirmationHandler(true)
            }}
          />
        </DialogActions>
      </DialogContainer>
    </Dialog>
  );
};

const DialogContainer = styled("div")({
  width: 550,
  height: 167
});

const DialogContentStyled = styled(DialogContent)({
  padding: 16,
  gap: 32,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
});

const DialogHeaderStyled = styled("div")({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between"
});

const CloseIcon16Styled = styled(CloseIcon16)({
  cursor: "pointer"
});

const AlertIconAndTitleWrapper = styled("div")({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 8
});

export default memo(CustomUnsavedChangesDialog);
