import React, { memo } from "react";
import cs from "classnames";

import Button from "components/ui/Buttons/Button";

import useStyles from "./styles";

const PrimaryButton = ({ children, disabled, startIcon = null, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      className={cs(classes.primary, className, {
        [classes.disabled]: disabled,
        [classes.withIcon]: !!startIcon
      })}
      disabled={disabled}
      {...rest}
    >
      {startIcon}
      {children}
    </Button>
  );
};

export default memo(PrimaryButton);
