import React, { memo } from "react";
import Table from "components/ui/Table/Table";
import { styled } from "@material-ui/core";
import { VantiScrollBarProps } from "components/ui/VantiScrollBarProps";

const TableWrapper = styled("div")(({ tableBorderColor }) => ({
  maxHeight: 700,
  overflow: "auto",
  border: tableBorderColor ? `1px solid ${tableBorderColor}` : "none",
  ...VantiScrollBarProps
}));

const ReportsTable = ({
  headers,
  filteredContent,
  onFilter,
  onSort,
  actions,
  tableBorderColor,
  onSelectRow,
  isSelectableRows,
  setActionsPopupRef,
  setPinUnpinPopupRef,
  setCustomPopupRef,
  onWheel,
  onClickRow,
  selectedTableRows,
  displayRowMenu
}) => {
  return (
    <TableWrapper tableBorderColor={tableBorderColor}>
      <Table
        headers={headers}
        bodyData={filteredContent}
        onFilter={onFilter}
        onSort={onSort}
        onSelectRow={onSelectRow}
        isSelectableRows={isSelectableRows}
        setActionsPopupRef={setActionsPopupRef}
        setPinUnpinPopupRef={setPinUnpinPopupRef}
        setCustomPopupRef={setCustomPopupRef}
        onWheel={onWheel}
        actions={actions}
        onClickRow={onClickRow}
        selectedTableRows={selectedTableRows}
        displayRowMenu={displayRowMenu}
      />
    </TableWrapper>
  );
};

export default memo(ReportsTable);
