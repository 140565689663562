export const actionTypes = {
  ACCOUNT_INVITE_USER_REQUEST: "account.inviteUser.request",
  ACCOUNT_INVITE_USER_SUCCESS: "account.inviteUser.success",
  ACCOUNT_INVITE_USER_ERROR: "account.inviteUser.error",
  ACCOUNT_INVITE_USER_FULFILL: "account.inviteUser.fulfill"
};

export function inviteUserRequestAction(users) {
  return { type: actionTypes.ACCOUNT_INVITE_USER_REQUEST, payload: users };
}
