import React, { memo } from "react";

const PlusIconBG40 = ({ ...rest }) => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.583313" y="0.66333" width="40" height="40" rx="20" fill="#E6E7F6" />
      <path d="M14.0833 20.6633H27.0833M20.5833 14.1633V27.1633" stroke="#0313A4" strokeWidth="1.25" />
    </svg>
  );
};

export default memo(PlusIconBG40);
