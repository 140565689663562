import { call, put, select, takeLatest } from "redux-saga/effects";

import { actionTypes } from "./actions";
import * as authService from "services/auth/auth";
import { uidSelector } from "modules/auth/state/selectors";
import { setUsersAction } from "modules/account/state/actions";
import { mapUsersResponse } from "../utils";

export function* fetchUsersWorker() {
  try {
    const response = yield call(authService.getAccountUsers);
    const currentUserId = yield select(uidSelector);
    const users = mapUsersResponse(currentUserId, response.data);
    yield put({ type: actionTypes.ACCOUNT_FETCH_USERS_SUCCESS });
    yield put(setUsersAction(users));
  } catch (error) {
    yield put({ type: actionTypes.ACCOUNT_FETCH_USERS_ERROR, payload: error });
  } finally {
    yield put({ type: actionTypes.ACCOUNT_FETCH_USERS_FULFILL });
  }
}

export default function* fetchUsersWatcher() {
  yield takeLatest(actionTypes.ACCOUNT_FETCH_USERS_REQUEST, fetchUsersWorker);
}
