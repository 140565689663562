import React, { memo } from "react";
import cs from "classnames";

import { ReactComponent as VantiIconGreenBeating } from "assets/img/icons/vanti-icon-green-beating-heart.svg";

import useStyles from "./styles";

const LoadingOverlay = ({ fullscreen, className, size, ...props }) => {
  const classes = useStyles();

  return (
    <div className={className || cs(classes.overlay, { [classes.fullscreen]: fullscreen })} {...props}>
      <VantiIconGreenBeating style={{ width: size, height: size }} />
    </div>
  );
};

export default memo(LoadingOverlay);
