import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  arrowIcon: {
    width: "12px"
  },
  backText: {
    marginLeft: theme.spacing(0.5)
  },
  backButton: {
    padding: 0,
    marginTop: 0
  }
}));
