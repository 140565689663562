import http from "../http";
import { logger } from "utils/logger";
import config from "common/config";
import { hubspotUpdatePredictionMade } from "common/hubspot/hubspot";

const predictURL = `${config.ssApiUrl}/v1/predict`;
const filePredictURL = `${config.ssApiUrl}/v1/file-predict`;

export default async function predict(request) {
  const res = await http.post(predictURL, request.body, {
    Authorization: `Bearer ${request.token}`
  });

  const result = await res.json();

  if (!res.ok) {
    return result.messages;
  }

  try {
    const stationId = request?.body?.stationId;
    await hubspotUpdatePredictionMade(stationId);
  } catch (error) {
    logger.error("Error trying to update Hubspot: ", error);
  }

  return result;
}

export async function predictOnFile({ token, stationId, contentType, body }) {
  const file = body;
  const fd = new FormData();

  fd.append("file", file);

  const res = await http.post(`${filePredictURL}`, fd, {
    Authorization: `Bearer ${token}`,
    "x-vanti-api-stationid": stationId,
    "x-vanti-content-type": contentType || "image/png"
  });

  const result = await res.json();

  if (!res.ok) {
    return result.messages;
  }

  return await result;
}
