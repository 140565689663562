export const actionTypes = {
  USER_UPDATE_REQUEST: "user.update.request",
  USER_UPDATE_SUCCESS: "user.update.success",
  USER_UPDATE_ERROR: "user.update.error",
  USER_UPDATE_FULFILL: "user.update.fulfill"
};

export function userUpdateRequestAction(userData) {
  return { type: actionTypes.USER_UPDATE_REQUEST, payload: userData };
}
