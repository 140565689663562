import { logger } from "utils/logger";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "./context-hooks/use-app-context";
import { CHAT_API_QUERY_KEYS, CHAT_LOADING_MESSAGE_ID } from "pages/side-bar/chat/constants";
import { saveStaticReport, sendTextMessageToChat, startChat } from "services/api/chat";
import useChatRequest from "./use-chat-request";
import { getDisposableId, isDisposableChat } from "utils/chat/chat-utils";
import { useMutation } from "react-query";

const useChatMessages = () => {
  const { chatListRequest } = useChatRequest();
  const { chatContext } = useContext(AppContext);
  const { setChatSessions, selectedChatId, chatSessions, setChatList, setSelectedChatId } = chatContext;
  const [sendChatMessage, setSendChatMessage] = useState(null);
  const [addMessageToChatsHistory, setAddMessageToChatsHistory] = useState(null);
  const { reportsContext } = useContext(AppContext);
  const { setReports } = reportsContext;

  const saveStaticReportRequest = useMutation(
    [CHAT_API_QUERY_KEYS.SAVE_STATIC_REPORT],
    async staticReportMessage => saveStaticReport(staticReportMessage),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: data => {
        if (!data) {
          return null;
        }

        setReports(prevState => ({ ...prevState, [data._id]: data }));
      },
      onError: error => logger.error("Failed to save static report", error)
    }
  );
  const addMessageToChatsHistoryFunc = useCallback(
    message => {
      if (!message) {
        return;
      }

      const oldChatHistory = chatSessions[message.chatId] || [];
      if (oldChatHistory.some(msg => msg._id === message._id)) {
        return;
      }

      //remove web-injected loading bubble when a followed message is added to history
      if (oldChatHistory.length && oldChatHistory.at(-1)._id === CHAT_LOADING_MESSAGE_ID) {
        oldChatHistory.pop();
      }

      setChatSessions(prevState => ({
        ...prevState,
        [message.chatId]: [...oldChatHistory, message]
      }));

      if (message.reportData) {
        //refetch chat list because it will contain new report in 'bookmarks'
        chatListRequest.refetch();
      }
    },
    [chatSessions]
  );

  const handleDisposableChatAction = useCallback(async (disposableChatId, message) => {
    const datasetId = getDisposableId(disposableChatId);
    const result = await startChat(message, datasetId, null);

    setChatList(prev => {
      return [...prev.filter(chat => !isDisposableChat(chat._id)), result.chat];
    });

    setChatSessions(prev => {
      if (prev[disposableChatId]) {
        delete prev[disposableChatId];
      }
      return prev;
    });
    setSelectedChatId(result.chat._id);
  }, []);

  const sendChatMessageFunc = useCallback(
    async (text, chatId = null, buttonAction = null) => {
      try {
        const targetChatId = chatId || selectedChatId;
        if (isDisposableChat(targetChatId)) {
          return await handleDisposableChatAction(targetChatId, text);
        }
        const savedMessage = await sendTextMessageToChat(targetChatId, text, buttonAction);
        addMessageToChatsHistoryFunc(savedMessage);
      } catch (error) {
        logger.error("Failed to send chat message", error); //TODO: handle error
      }
    },
    [selectedChatId, sendTextMessageToChat, addMessageToChatsHistoryFunc]
  );

  useEffect(() => {
    setSendChatMessage(() => sendChatMessageFunc);
    setAddMessageToChatsHistory(() => addMessageToChatsHistoryFunc);
  }, [sendChatMessageFunc, addMessageToChatsHistoryFunc]);

  return {
    sendChatMessage,
    addMessageToChatsHistory,
    saveStaticReportRequest
  };
};

export default useChatMessages;
