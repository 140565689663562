import { styled } from "@material-ui/core";
import CheckboxUnfilled24 from "assets/icons/checkbox/CheckboxUnfilled24";
import { vantiColors } from "assets/jss/palette";
import { Text } from "components/ui";
import GeneralHorizontalBarChart from "components/ui/Chart/horizontal-bar-chart/GeneralHorizontalBarChart";
import React, { memo } from "react";

const TileWrapper = styled("div")({
  backgroundColor: vantiColors.white,
  display: "flex",
  flexDirection: "column",
  height: 274,
  justifyContent: "space-between",
  padding: 16,
  rowGap: 8,
  "& > div:first-of-type": {
    visibility: "hidden",
    pointerEvents: "none"
  },
  "&:hover": {
    backgroundColor: vantiColors.gray36,
    "& > div:first-of-type": {
      visibility: "visible",
      pointerEvents: "auto"
    }
  }
});

const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const CheckboxStyled = styled(CheckboxUnfilled24)({
  cursor: "pointer"
});

const Body = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 16
});

const GraphWrapper = styled("div")({
  border: `1px solid ${vantiColors.gray36}`,
  borderRadius: 6,
  backgroundColor: vantiColors.white,
  padding: 8
});

const Tile = ({ title, createdBy, creationDate }) => {
  return (
    <TileWrapper>
      <Header>
        <CheckboxStyled />
      </Header>

      <Body>
        <GraphWrapper>
          <GeneralHorizontalBarChart
            data={{
              labels: ["January", "February", "March", "April", "May", "June", "July"],
              datasets: [
                {
                  label: "Dataset 1",
                  data: [100, 45, 97, 51, 103, 40],
                  borderColor: "rgb(255, 99, 132)",
                  backgroundColor: "rgba(255, 99, 132, 0.5)"
                },
                {
                  label: "Dataset 2",
                  data: [100, 45, 97, 51, 103, 40],
                  borderColor: "rgb(53, 162, 235)",
                  backgroundColor: "rgba(53, 162, 235, 0.5)"
                }
              ]
            }}
          />
        </GraphWrapper>

        <Text>{title}</Text>
        <Text>
          {createdBy} | {creationDate}
        </Text>
      </Body>
    </TileWrapper>
  );
};

export default memo(Tile);
