import { makeStyles } from "@material-ui/core";

const styles = makeStyles({
  root: {
    position: "relative",
    fontFamily: "Inter",
    marginTop: "30px"
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    backgroundColor: "transparent",
    transform: "translate(-50%, -50%)"
  }
});

export default styles;
