import React, { memo } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import useStyles from "./styles";

const TabButton = ({ children, active, disabled, ...rest }) => {
  const styles = useStyles();

  return (
    <button className={cs(styles.button, { [styles.active]: active, [styles.disabled]: disabled })} {...rest}>
      {children}
    </button>
  );
};

TabButton.propTypes = {
  children: PropTypes.element,
  active: PropTypes.bool,
  disabled: PropTypes.bool
};

export default memo(TabButton);
