import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  cardFooter: {
    padding: theme.spacing(0, 2.75, 8, 2.75)
  },
  buttonContainer: {
    paddingTop: "0px !important",
    flexGrow: 1,
    maxWidth: "fit-content",
    minWidth: "380px",
    textAlign: "center",
    "& > button": {
      marginTop: 0,
      borderColor: theme.palette.vantiColors.lightGray1,
      "& > span > div": {
        display: "flex",
        alignItems: "center",
        "& > svg": {
          width: "25px",
          height: "25px",
          marginRight: theme.spacing(1)
        }
      }
    }
  },
  error: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(3.5),
    "& > div:first-of-type": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(1),
      "& > svg": {
        marginRight: theme.spacing(1)
      }
    }
  },
  getStartedButton: {
    height: "40px !important",
    width: 224
  }
}));
