import http from "../http";

import config from "common/config";

const feedbackURL = `${config.ssApiUrl}/v1/feedback`;

export default async function feedback(request) {
  const res = await http.post(feedbackURL, request.body, {
    Authorization: `Bearer ${request.token}`
  });

  const result = await res.json();

  if (!res.ok) {
    return result.messages;
  }

  return result;
}
