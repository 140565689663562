import React from "react";

const AttentionIcon = () => (
  <svg width="22.17" height="22.17" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00001 14.8571C6.64379 14.8571 5.31804 14.455 4.19039 13.7015C3.06274 12.9481 2.18384 11.8771 1.66484 10.6241C1.14584 9.37115 1.01005 7.99241 1.27464 6.66226C1.53922 5.3321 2.19229 4.11026 3.15127 3.15127C4.11026 2.19229 5.33208 1.53922 6.66224 1.27464C7.99239 1.01005 9.37114 1.14584 10.6241 1.66484C11.8771 2.18384 12.948 3.06272 13.7015 4.19037C14.455 5.31802 14.8571 6.64379 14.8571 8.00001C14.8571 9.81863 14.1347 11.5628 12.8487 12.8487C11.5628 14.1347 9.81863 14.8571 8.00001 14.8571V14.8571ZM8.00001 16C9.58225 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7855 12.5233 15.391 11.0615C15.9965 9.59965 16.155 7.99113 15.8463 6.43929C15.5376 4.88744 14.7757 3.46199 13.6569 2.34317C12.538 1.22435 11.1126 0.462401 9.56073 0.153719C8.00888 -0.154962 6.40035 0.00347057 4.93854 0.608971C3.47673 1.21447 2.22731 2.23987 1.34826 3.55547C0.469211 4.87106 1.3542e-05 6.41776 1.34037e-05 8.00001C1.32182e-05 10.1217 0.842861 12.1566 2.34315 13.6568C3.84344 15.1571 5.87828 16 8.00001 16V16Z"
      fill="#FF3C78"
    />
    <path d="M8 11.4284L8 12.5713M8 10.2856L8 3.42844L8 10.2856Z" stroke="#FF3C78" />
  </svg>
);

export default AttentionIcon;
