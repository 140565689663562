import React, { memo, useContext, useMemo, useState } from "react";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import { VantiScrollBarProps } from "components/ui/VantiScrollBarProps";
import { vantiColors } from "assets/jss/palette";
import { DarkToolTip } from "components/ui/tooltips/tooltip";
import { CloseIcon16Styled } from "../../home/HomePageTabs";
import { useSelector } from "react-redux";
import { userPermissionsSelector } from "modules/user/state/selectors";
import { PERSONA, ROLE } from "@vanti-analytics-org/vanti-common";
import { usePermission } from "react-use";
import usePermissions from "common/hooks/use-permissions";

const removeExtension = text => (text.includes(".") ? text.split(".").shift() : text);
const NO_DATASET_DESCRIPTION_TXT = "No description";
const TOOLTIP_NO_DATASET_DESCRIPTION_TXT =
  "This dataset doesn't contain any file description. You can add it in the Data page";

const DataEntityAssociations = ({ entity, setDisplayDataDeletionDialogId }) => {
  const { datasetsContext } = useContext(AppContext);
  const { datasets } = datasetsContext;
  const [hoveredAssociatedDataId, setHoveredAssociatedDataId] = useState(null);

  //TODO:remove later, we didn't have time or passion to create new role just for small debugging thing
  const canShowDatasetsSchemaIds = usePermissions([ROLE.VIEW_CHAT_MOCK_TOGGLE]);

  const associatedDatasets = Object.values(datasets).filter(d => {
    const datasetId = d.id || d._id;
    return entity.datasets?.find(d => d.entryId === datasetId);
  });

  const sortedDatasetsList = useMemo(() => {
    return associatedDatasets.sort((a, b) => {
      const aLen = a.fileDescription?.length || 0;
      const bLen = b.fileDescription?.length || 0;
      return bLen > aLen ? 1 : bLen === aLen ? 0 : -1;
    });
  }, [associatedDatasets]);

  return (
    <DataEntityAssociationsContainer>
      {sortedDatasetsList.map(d => (
        <DataNameAndDescCard
          key={`${d.name}-${d.id || d._id}}`}
          isHovered={hoveredAssociatedDataId === d._id}
          onMouseEnter={() => setHoveredAssociatedDataId(d.id || d._id)}
          onMouseLeave={() => setHoveredAssociatedDataId(null)}
        >
          <DarkToolTip title={d.name} placement={"top-start"}>
            <Text weight={600} lineClamp={1} lineSize={"normal"} cursor={"help"}>
              {removeExtension(d.name)}
            </Text>
          </DarkToolTip>
          <DarkToolTip
            title={d.fileDescription?.length ? d.fileDescription : TOOLTIP_NO_DATASET_DESCRIPTION_TXT}
            placement={"bottom-start"}
          >
            <Text lineClamp={3} lineSize={"normal"} cursor={"help"}>
              {d.fileDescription?.length ? d.fileDescription : NO_DATASET_DESCRIPTION_TXT}
              {canShowDatasetsSchemaIds && (
                <b>
                  <br />
                  Schema ID: {d.ingestedSchemaId} | Index: {entity.datasets.indexOf(d.id || d._id)}
                </b>
              )}
            </Text>
          </DarkToolTip>
          {hoveredAssociatedDataId === d._id && (
            <CloseIcon16Styled
              onClick={() => setDisplayDataDeletionDialogId(hoveredAssociatedDataId)}
              style={{ right: "4px" }}
            />
          )}
        </DataNameAndDescCard>
      ))}
    </DataEntityAssociationsContainer>
  );
};

const DataNameAndDescCard = styled("div")(({ isHovered }) => ({
  width: "calc(50% - 4px)", //there are 2 cards in each row, and gap isn't included in width, so do 50% - (8px / 2)
  height: 86,
  boxSizing: "border-box",
  display: "flex",
  border: `1px solid ${vantiColors.gray36}`,
  borderRadius: 4,
  padding: 8,
  flexDirection: "column",
  justifyContent: "flex-start",
  position: "relative",
  backgroundColor: isHovered ? vantiColors.gray18 : "transparent",
  transition: "0.3s all ease-in-out"
}));

const DataEntityAssociationsContainer = styled("div")({
  width: "100%",
  overflowY: "auto",
  boxSizing: "border-box",
  display: "flex",
  flexWrap: "wrap",
  gap: 8,
  ...VantiScrollBarProps
});

export default memo(DataEntityAssociations);
