import { createContext, useMemo, useState } from "react";

const defaultState = {
  flows: {}
};

export const FlowContext = createContext({
  flows: {}
});

export const useFlowContext = () => {
  const [flows, setFlows] = useState(defaultState.flows);

  return useMemo(
    () => ({
      flows,
      setFlows
    }),
    [flows]
  );
};
