import { makeStyles } from "@material-ui/core/styles";
import { vantiColors } from "assets/jss/vanti-dashboard";

export default makeStyles(theme => ({
  labelRoot: {
    ...theme.fonts.defaultFont,
    color: vantiColors.darkBlue1 + " !important",
    fontSize: "18px",
    lineHeight: "25px",
    letterSpacing: "unset",
    top: "-5px"
  },
  formControl: {
    margin: "15px 0 0 0",
    position: "relative",
    verticalAlign: "unset"
  },
  input: {
    ...theme.fonts.defaultFont
  }
}));
