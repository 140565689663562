import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import GeneralModalPreviewComponent from "components/ui/Modal/GeneralModalPreviewComponent";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { useParams } from "react-router-dom";
import { Text } from "components/ui";
import SadOctopus from "assets/img/sad_vanti.png";
import { Snackbar, styled } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ChatMessageMetadataSection from "./ChatMessageMetadataSection";
import { logger } from "utils/logger";

const FAILED_TO_FIND_MSG_IN_CHAT_HISTORY_TEXT = "Couldn't find this message in chat's history";
const SNACKBAR_COPIED_TO_CLIPBOARD_TEXT = "Copied to clipboard!";
const FAILED_TO_DECODE_RETURNED_CODE_ERR_MESSAGE = "Failed to decode returned code ¯\\_(ツ)_/¯";

const ChatMessageMetadataModal = () => {
  const { chatContext } = useContext(AppContext);
  const { chatMessageMetadataId, setChatMessageMetadataId, chatSessions } = chatContext;
  const { chatId } = useParams();
  const [showSnackbar, setShowSnackbar] = useState(false);

  const message = useMemo(() => {
    try {
      return chatSessions[chatId].find(msg => msg._id === chatMessageMetadataId);
    } catch (err) {
      return null;
    }
  }, [chatSessions, chatMessageMetadataId]);

  const decodedReturnedCode = useMemo(() => {
    try {
      return message?.returnedCode && atob(message?.returnedCode);
    } catch (err) {
      return FAILED_TO_DECODE_RETURNED_CODE_ERR_MESSAGE;
    }
  }, [message?.returnedCode]);

  const copyTextToClipboard = useCallback(async (text, entity) => {
    setShowSnackbar(true);
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
    } else {
      logger.warn(`Failed to copy ${entity} text to clipboard. Make sure you are using secured (HTTPS) connection.`);
    }
  }, []);

  const convertToChatFormat = useCallback(reportData => {
    const formattedData = {
      question: reportData.question,
      textual_response: reportData.resultSummary,
      chart_data: reportData.chartData,
      chart_labels: reportData.chartLabels,
      chart_type: reportData.chartType,
      report_headline: reportData.description
    };
    return JSON.stringify(formattedData, null, 2);
  }, []);

  const messageInfoObject = useMemo(() => {
    if (message) {
      const obj = { ...message };
      delete obj.reportData;
      delete obj.returnedCode;
      return obj;
    }
  }, [message]);

  return (
    <GeneralModalPreviewComponent
      height={"84%"}
      width={"84%"}
      open={chatMessageMetadataId}
      onClose={() => setChatMessageMetadataId(null)}
    >
      {!message ? (
        <Text size={18} weight={600}>
          {FAILED_TO_FIND_MSG_IN_CHAT_HISTORY_TEXT}: {chatMessageMetadataId}
          <img src={SadOctopus} style={{ transform: "scale(0.8, 0.8)" }} />
        </Text>
      ) : (
        <ChatMessageMetadataContainer>
          {message.reportData && (
            <ChatMessageMetadataSection
              title={"Report Data"}
              content={convertToChatFormat(message.reportData)}
              copyToClipboardCallback={copyTextToClipboard}
            />
          )}
          {message.returnedCode && (
            <ChatMessageMetadataSection
              title={"Returned Code"}
              content={decodedReturnedCode}
              copyToClipboardCallback={copyTextToClipboard}
            />
          )}
          {message && (
            <ChatMessageMetadataSection
              title={"Message Info"}
              content={messageInfoObject}
              copyToClipboardCallback={copyTextToClipboard}
            />
          )}
        </ChatMessageMetadataContainer>
      )}
      <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={() => setShowSnackbar(false)}>
        <Alert onClose={() => setShowSnackbar(false)} severity="success" sx={{ width: "100%" }}>
          {SNACKBAR_COPIED_TO_CLIPBOARD_TEXT}
        </Alert>
      </Snackbar>
    </GeneralModalPreviewComponent>
  );
};

const ChatMessageMetadataContainer = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  gap: 8
});

export default memo(ChatMessageMetadataModal);
