import { styled } from "@material-ui/core";
import React, { memo } from "react";
import Tile from "./Tile";

const ReportsTilesWrapper = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gridGap: 8
});

const ReportsTiles = ({ tiles = [] }) => {
  return (
    <ReportsTilesWrapper>
      {tiles.map(tile => (
        <Tile key="" title={tile.title} createdBy={tile.createdBy} creationDate={tile.creationDate} />
      ))}
    </ReportsTilesWrapper>
  );
};

export default memo(ReportsTiles);
