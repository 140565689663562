import React, { memo } from "react";

const AssemblyTestingNewIcon48 = ({ className }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M14.202 37C11.6089 34.459 10 30.9174 10 27H4C4 32.4509 6.18064 37.3925 9.71713 41L14.202 37Z"
      fill="#86E8B6"
    />
    <path
      d="M24 7C35.0457 7 44 15.9543 44 27C44 32.4509 41.8194 37.3925 38.2829 41L33.798 37C36.3911 34.459 38 30.9174 38 27C38 24.7638 37.4757 22.6501 36.5434 20.775M24 7C18.4052 7 13.347 9.29728 9.71713 13M24 7V13M4 27C4 32.4509 6.18064 37.3925 9.71713 41L14.202 37C11.6089 34.459 10 30.9174 10 27M4 27H10M4 27C4 21.5491 6.18064 16.6075 9.71713 13M24 13C27.5143 13 30.7262 14.2949 33.1845 16.4335M24 13C20.0408 13 16.4654 14.6435 13.9189 17.2855M10 27C10 23.2273 11.4923 19.8031 13.9189 17.2855M13.9189 17.2855L9.71713 13M19.5 37.5H28.5M13.9189 41.5H33.798M36.4182 17.2855L34.8993 18.2126L22.5606 25.7446C21.2161 26.5653 20.9229 28.3931 21.9433 29.5932C22.9637 30.7932 24.8148 30.7977 25.8411 29.6027L35.2421 18.655L36.4182 17.2855Z"
      stroke="black"
    />
  </svg>
);

export default memo(AssemblyTestingNewIcon48);
