import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import makeStyles from "@material-ui/styles/makeStyles";
import Topbar from "./dashboard/Topbar";
import { navigationSetBreadcrumbsType } from "modules/navigation/actions";

const styles = theme => ({
  mainPanel: {
    width: "100%",
    height: "100%"
  },
  hideStreamlitLogo: {
    position: "fixed",
    bottom: "0",
    right: "0",
    width: "66px",
    height: "46px",
    backgroundColor: "white",
    zIndex: "60"
  }
});

const useStyles = makeStyles(styles);

export default function IFramePage(props) {
  const { title, url } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const shouldShowStreamlitLogo = url.toLowerCase().includes("streamlit");

  useEffect(() => {
    dispatch(navigationSetBreadcrumbsType(title));
  }, [title]);

  return (
    <>
      {shouldShowStreamlitLogo && <div className={classes.hideStreamlitLogo} />}
      <iframe id="iframe-page" className={classes.mainPanel} src={url} frameBorder="0"></iframe>
    </>
  );
}
