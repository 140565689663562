import React from "react";

const EyeOpen = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.9999 3.15002L3.2999 3.85002L6.5099 7.06002C4.55077 8.12406 2.97374 9.77453 1.9999 11.78L1.8999 12L1.9999 12.22C2.92058 14.0826 4.34642 15.6488 6.11465 16.7398C7.88287 17.8308 9.92223 18.4026 11.9999 18.39C13.6481 18.3882 15.276 18.0264 16.7699 17.33L19.4099 20L20.1099 19.3L3.9999 3.15002ZM9.6299 10.15L13.8199 14.34C13.3072 14.7627 12.6644 14.9958 11.9999 15C11.2043 15 10.4412 14.684 9.87858 14.1213C9.31597 13.5587 8.9999 12.7957 8.9999 12C8.99724 11.3394 9.21941 10.6976 9.6299 10.18V10.15ZM11.9999 17.39C10.15 17.402 8.33232 16.9057 6.74522 15.9552C5.15812 15.0047 3.86256 13.6365 2.9999 12C3.93201 10.1823 5.42129 8.71055 7.2499 7.80002L8.9199 9.47002C8.32343 10.1779 7.99743 11.0744 7.9999 12C7.9999 13.0609 8.42133 14.0783 9.17147 14.8285C9.92162 15.5786 10.939 16 11.9999 16C12.9255 16.0025 13.8221 15.6765 14.5299 15.08L15.9999 16.57C14.7355 17.1104 13.3749 17.3894 11.9999 17.39Z"
      fill="#13115B"
    />
    <path
      d="M16 12C15.9989 12.3381 15.9518 12.6746 15.86 13C15.7792 13.352 15.6443 13.6894 15.46 14L14.71 13.25C14.8866 12.9056 14.9855 12.5267 15 12.14C15.0054 12.0935 15.0054 12.0465 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9H11.86C11.4706 9.00989 11.0882 9.10549 10.74 9.28L10 8.54C10.3106 8.35573 10.648 8.22079 11 8.14C11.3254 8.04822 11.6619 8.00112 12 8C13.0609 8 14.0783 8.42143 14.8284 9.17157C15.5786 9.92172 16 10.9391 16 12Z"
      fill="#13115B"
    />
    <path
      d="M22.0901 12.0001L21.9901 12.2201C21.2715 13.7083 20.2088 15.004 18.8901 16.0001C18.6292 16.2104 18.3553 16.4041 18.0701 16.5801L17.3401 15.8501C17.6305 15.6809 17.908 15.4904 18.1701 15.2801C19.3508 14.4164 20.3187 13.2946 21.0001 12.0001C20.1401 10.3614 18.8451 8.9914 17.2574 8.04054C15.6697 7.08969 13.8507 6.59475 12.0001 6.61008C11.2294 6.61029 10.4612 6.69751 9.7101 6.87008C9.34106 6.95219 8.97711 7.0557 8.6201 7.18008L7.8501 6.41008C8.1767 6.27215 8.51052 6.15197 8.8501 6.05008C11.3659 5.30949 14.0621 5.47776 16.4664 6.52538C18.8706 7.57301 20.8296 9.4332 22.0001 11.7801L22.0901 12.0001Z"
      fill="#13115B"
    />
  </svg>
);

export default EyeOpen;
