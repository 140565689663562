export const ACTION_TYPES = {
  PREDICT: "predict",
  FILE_PREDICT: "file_predict",
  PROVIDE_FEEDBACK: "provide_feedback",
  UPDATE_PREDICT: "update_feedback",
  UPDATE_FEEDBACK: "update_predict",
  OPEN_MODAL: "open_modal",
  CLOSE_MODAL: "close_modal",
  RESET: "reset"
};

export const predict = payload => {
  return { type: ACTION_TYPES.PREDICT, payload };
};

export const filePredict = payload => {
  return { type: ACTION_TYPES.FILE_PREDICT, payload };
};

export const provideFeedback = payload => {
  return { type: ACTION_TYPES.PROVIDE_FEEDBACK, payload };
};

export const updatePredict = payload => {
  return { type: ACTION_TYPES.UPDATE_PREDICT, payload };
};

export const updateFeedback = payload => {
  return { type: ACTION_TYPES.UPDATE_FEEDBACK, payload };
};

export const openSandboxModal = () => {
  return { type: ACTION_TYPES.OPEN_MODAL };
};

export const closeSandboxModal = () => {
  return { type: ACTION_TYPES.CLOSE_MODAL };
};

export const resetSandbox = () => {
  return { type: ACTION_TYPES.RESET };
};
