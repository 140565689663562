import React, { memo, useMemo } from "react";
import { DarkToolTip } from "components/ui/tooltips/tooltip";
import { Checkbox, styled } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export const PinUnpinMenu = ({
  items,
  pinnedItemsIds,
  menuTitleText,
  onToggleItem,
  onMenuClosed,
  anchorEl,
  setAnchorEl,
  defaultNameTitleText = "no title"
}) => {
  const handleMenuClose = async () => {
    setAnchorEl(null);
    onMenuClosed();
  };

  const pinUnpinList = useMemo(() => {
    if (!items || !pinnedItemsIds) {
      return [];
    }

    const menuTitle = {
      component: (
        <Text size={12} color={vantiColors.lightGray16} weight={600}>
          {menuTitleText}
        </Text>
      )
    };

    return [
      menuTitle,
      ...items.map(item => {
        const name = item.name && item.name.length ? item.name : defaultNameTitleText;
        return {
          isClickable: true,
          component: (
            <DarkToolTip title={name} arrow placement={"left-start"}>
              <FormControlLabelStyled
                control={
                  <CheckboxStyled
                    onChange={event => {
                      onToggleItem(event, item._id);
                    }}
                    checked={pinnedItemsIds.includes(item._id)}
                    name={name}
                  />
                }
                label={<Text>{name}</Text>}
              />
            </DarkToolTip>
          )
        };
      })
    ];
  }, [items, pinnedItemsIds]);

  return (
    <MenuStyled
      anchorEl={anchorEl?.ref}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      {pinUnpinList.map(({ component, isClickable }, i) => (
        <StyledMenuItem isClickable={!!isClickable} disableRipple key={`pinunpin-menu-item-${i}`}>
          {component}
        </StyledMenuItem>
      ))}
    </MenuStyled>
  );
};

const FormControlLabelStyled = styled(FormControlLabel)({
  "&.MuiFormControlLabel-root": {
    width: "100%",
    padding: "16px 12px",
    margin: 0
  },
  display: "flex",
  gap: 8
});

const CheckboxStyled = styled(Checkbox)({
  "&.MuiCheckbox-root": {
    padding: 0,
    color: vantiColors.darkBlue7
  }
});

const StyledMenuItem = styled(MenuItem)(({ theme, color, isClickable }) => ({
  color: color || theme.palette.vantiColors.darkBlue4,
  fontFamily: "Inter",
  cursor: isClickable ? "pointer" : "none",
  pointerEvents: isClickable ? "auto" : "none",
  padding: 0,
  "& .MuiTypography-root": {
    textOverflow: "ellipsis",
    overflow: "auto",
    whiteSpace: "nowrap",
    paddingRight: 16
  }
}));

const MenuStyled = styled(Menu)({
  "& .MuiPaper-root": {
    width: 180
  },
  "& .MuiList-padding": {
    padding: 8,
    margin: 0
  }
});

export default memo(PinUnpinMenu);
