import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import zxcvbnEnPackage from "@zxcvbn-ts/language-en";

zxcvbnOptions.setOptions({
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary
  }
});

export const checkPasswordRequirements = password => {
  const isLongEnough = password.length >= 8;
  const isShortEnough = password.length < 128;
  const isValidLength = isLongEnough && isShortEnough;
  const isUppercase = password.toString().match(/^.*[A-Z].*$/);
  const isLowercase = password.toString().match(/^.*[a-z].*$/);
  const isSymbol = password?.toString().match(/^.*[@$#!%*?&].*$/);
  const isNumber = password.toString().match(/^.*[0-9].*$/);
  const isHardToGuess = isValidLength ? zxcvbn(password).score >= 3 : false;

  return {
    isValidLength,
    isUppercase,
    isLowercase,
    isSymbol,
    isNumber,
    isHardToGuess,
    isShortEnough,
    isLongEnough,

    hasPassedAllChecks: isValidLength && isUppercase && isLowercase && isSymbol && isNumber && isHardToGuess
  };
};
