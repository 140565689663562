import React, { memo } from "react";
import RedCircleExclamationMark from "assets/icons/notification-center/RedCircleExclamationMark";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import GeneralVantiButton, { VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import CustomDialog from "components/ui/Dialog/CustomDialog";
import { styled } from "@material-ui/core";

const DELETE_DIALOG = {
  title: "Confirm deletion",
  body: `Are you sure you want to delete this`
};

const CustomDeleteConfirmationDialog = ({
  isOpenedCondition,
  entity,
  deletionWarningText,
  onCloseCallback,
  onClickCancelCallback,
  onClickDeleteCallback,
  isDisabled
}) => {
  return (
    <CustomDialog
      isOpened={isOpenedCondition}
      title={
        <DeleteDialogHeaderWrapperStyled>
          <RedCircleExclamationMark /> {DELETE_DIALOG.title}
        </DeleteDialogHeaderWrapperStyled>
      }
      body={
        <DeleteDialogContentWrapperStyled>
          {entity.type && (
            <Text color={vantiColors.gray39}>
              {`${DELETE_DIALOG.body} ${entity.type} `}
              <Text color="inherit" weight={700}>
                {entity.name}
              </Text>
              ?
            </Text>
          )}

          <Text color={vantiColors.rose6}>{deletionWarningText}</Text>
        </DeleteDialogContentWrapperStyled>
      }
      onClose={onCloseCallback}
    >
      <GeneralVantiButton
        variant={VARIANTS.SECONDARY}
        buttonInfo={{
          label: "Cancel",
          disabled: false,
          onClick: onClickCancelCallback
        }}
      />

      <GeneralVantiButton
        buttonInfo={{
          label: "Delete",
          disabled: isDisabled,
          onClick: onClickDeleteCallback
        }}
      />
    </CustomDialog>
  );
};

const DeleteDialogHeaderWrapperStyled = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: 8
});

const DeleteDialogContentWrapperStyled = styled("div")({
  display: "flex",
  flexDirection: "column"
});

export default memo(CustomDeleteConfirmationDialog);
