import React, { memo } from "react";
import { FlexItem } from "components/ui";
import Header from "./components/Header";
import DataConnectorsTable from "./components/DataConnectorsTable";
import { styled } from "@material-ui/core";

const DataConnectorsListWrapperStyled = styled(FlexItem)({
  marginBottom: 128,
  minHeight: "100%"
});

const DataConnectorsListStyled = styled(FlexItem)({
  rowGap: 16,
  alignItems: "flex-start",
  flexFlow: "column"
});

const DataConnectorsTableStyled = styled(DataConnectorsTable)({
  flex: 1,
  justifyContent: "flex-start",
  flexFlow: "column"
});

const DataConnectorsList = () => {
  return (
    <DataConnectorsListWrapperStyled container dense="full">
      <DataConnectorsListStyled dense="full" container>
        <Header />
        <DataConnectorsTableStyled />
      </DataConnectorsListStyled>
    </DataConnectorsListWrapperStyled>
  );
};

export default memo(DataConnectorsList);
