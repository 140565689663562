import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const PlaceholderIcon24 = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_3594_18929"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="56"
        height="56"
      >
        <rect x="0.5" y="0.5" width="55" height="55" fill={vantiColors.gray25} />
      </mask>
      <g mask="url(#mask0_3594_18929)">
        <path
          d="M39.7861 19.2739L37.803 14.8227L33.3518 12.8396L37.803 10.8564L39.7861 6.40527L41.7693 10.8564L46.2204 12.8396L41.7693 14.8227L39.7861 19.2739ZM39.7861 49.5944L37.803 45.1433L33.3518 43.1601L37.803 41.1769L39.7861 36.7257L41.7693 41.1769L46.2204 43.1601L41.7693 45.1433L39.7861 49.5944ZM18.6323 40.7803L14.6659 31.9662L5.85181 27.9998L14.6659 24.0335L18.6323 15.2193L22.5986 24.0335L31.4128 27.9998L22.5986 31.9662L18.6323 40.7803ZM18.6323 35.2186L20.9239 30.2915L25.851 27.9998L20.9239 25.7082L18.6323 20.7811L16.3406 25.7082L11.4135 27.9998L16.3406 30.2915L18.6323 35.2186Z"
          fill={vantiColors.gray39}
        />
      </g>
    </svg>
  );
};

export default memo(PlaceholderIcon24);
