import React, { memo, useMemo } from "react";
import { FlexItem, Text } from "components/ui";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import styles from "../../../../styles";

const CurationCSVInputPreview = ({ preview }) => {
  const classes = styles();
  const StyledPaperComponent = props => {
    return <Paper {...props} className={classes.tableContainer} />;
  };
  const { headers, body } = preview;
  const bodyContent = useMemo(() => {
    return body.map((row, index) => {
      return (
        <TableRow key={index}>
          {row.map((value, index) => (
            <TableCell title={value} key={index}>
              {value}
            </TableCell>
          ))}
        </TableRow>
      );
    });
  }, [body]);

  return (
    <FlexItem container dense="full" data-testid={`curation-csv-input-preview`}>
      <div className={classes.dataTableContainer}>
        <div className={classes.wrapper}>
          <div className={classes.scrollContainer}>
            <TableContainer component={StyledPaperComponent}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {headers.map((header, index) => (
                      <TableCell key={index}>
                        <div className={classes.heading}>
                          <span className={classes.headCell}>
                            <Text weight={700}>{header}</Text>
                          </span>
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{bodyContent}</TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </FlexItem>
  );
};

export default memo(CurationCSVInputPreview);
