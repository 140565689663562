import React, { memo } from "react";

const AddDashboardIcon16 = ({ ...rest }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 7.5V5.12988H8.5V7.5H10.8701V8.5H8.5L8.5 11H7.5L7.5 8.5H5V7.5H7.5ZM14.0052 10.4874C14.3319 9.69883 14.5 8.85359 14.5 8C14.5 7.14641 14.3319 6.30118 14.0052 5.51256C13.6786 4.72394 13.1998 4.00739 12.5962 3.40381C11.9926 2.80023 11.2761 2.32144 10.4874 1.99478C9.69883 1.66813 8.85359 1.5 8 1.5C7.14641 1.5 6.30118 1.66813 5.51256 1.99478C4.72394 2.32144 4.00739 2.80022 3.40381 3.40381C2.80023 4.00739 2.32144 4.72394 1.99478 5.51256C1.66813 6.30117 1.5 7.14641 1.5 8C1.5 8.85359 1.66813 9.69882 1.99478 10.4874C2.32144 11.2761 2.80023 11.9926 3.40381 12.5962C4.00739 13.1998 4.72394 13.6786 5.51256 14.0052C6.30117 14.3319 7.14641 14.5 8 14.5C8.85359 14.5 9.69883 14.3319 10.4874 14.0052C11.2761 13.6786 11.9926 13.1998 12.5962 12.5962C13.1998 11.9926 13.6786 11.2761 14.0052 10.4874ZM15.5 8C15.5 8.98491 15.306 9.96018 14.9291 10.8701C14.5522 11.7801 13.9997 12.6069 13.3033 13.3033C12.6069 13.9997 11.7801 14.5522 10.8701 14.9291C9.96018 15.306 8.98491 15.5 8 15.5C7.01509 15.5 6.03982 15.306 5.12987 14.9291C4.21993 14.5522 3.39314 13.9997 2.6967 13.3033C2.00026 12.6069 1.44781 11.7801 1.0709 10.8701C0.693993 9.96018 0.5 8.98491 0.5 8C0.5 7.01509 0.693993 6.03982 1.0709 5.12987C1.44781 4.21993 2.00026 3.39314 2.6967 2.6967C3.39314 2.00026 4.21993 1.44781 5.12988 1.0709C6.03982 0.693993 7.01509 0.5 8 0.5C8.98491 0.5 9.96019 0.693993 10.8701 1.0709C11.7801 1.44781 12.6069 2.00026 13.3033 2.6967C13.9997 3.39314 14.5522 4.21993 14.9291 5.12988C15.306 6.03982 15.5 7.01509 15.5 8Z"
        fill="#0313A4"
      />
    </svg>
  );
};

export default memo(AddDashboardIcon16);
