import { combineReducers } from "redux";
import { all, fork } from "redux-saga/effects";
import queryReducer, * as queryModule from "./query";
import deleteStationReducer from "./operations/deleteStation/reducer";
import deleteStationWatcher from "./operations/deleteStation/saga";
import createAndAssignProductReducer from "./operations/createAndAssignProduct/reducer";
import createAndAssignProductWatcher from "./operations/createAndAssignProduct/saga";
import assignProductReducer from "./operations/assignProduct/reducer";
import assignProductWatcher from "./operations/assignProduct/saga";
import deleteProductReducer from "./operations/deleteProduct/reducer";
import deleteProductWatcher from "./operations/deleteProduct/saga";
import editProductReducer from "./operations/editProduct/reducer";
import editProductWatcher from "./operations/editProduct/saga";

export default combineReducers({
  operations: combineReducers({
    deleteStation: deleteStationReducer,
    assignProduct: assignProductReducer,
    createAndAssignProduct: createAndAssignProductReducer,
    deleteProduct: deleteProductReducer,
    editProduct: editProductReducer
  }),
  query: queryReducer
});

export function* sagas() {
  yield all([
    fork(queryModule.watchStationQuerySaga),
    deleteStationWatcher(),
    createAndAssignProductWatcher(),
    assignProductWatcher(),
    deleteProductWatcher(),
    editProductWatcher()
  ]);
}
