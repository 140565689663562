import React, { useMemo, useCallback, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import chunk from "lodash/chunk";
import { useExpanded, useFilters, useFlexLayout, useGroupBy, useSortBy, useTable } from "react-table";
import { Table, TableBody, TableHead } from "@material-ui/core";
import TableHeadRows from "./table-head-rows/TableHeadRows";
import TableBodyRows from "./table-body-rows/TableBodyRows";
import StationStatusCell from "./table-rows/status/Status";
import StationNameCell from "./table-rows/station-name/StationName";
import StationCreatedByCell from "./table-rows/created-by/CreatedBy";
import StationProductsCell from "./table-rows/products/Products";
import StationLinesCell from "./table-rows/lines/Lines";
import StationLastPredictionCell from "./table-rows/last-prediction/LastPrediction";
import { stationUpdateStatusAction } from "modules/station/query";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import usePermissions from "common/hooks/use-permissions";

export const SORT_ORDER = Object.freeze({ DESC: "desc", ASC: "asc" });

const SORT_FIELDS = Object.freeze({
  NAME: "name",
  AVG_PREDICTION: "avgStationPredictionsPerDay",
  STATUS: "status",
  NUM_OF_MODELS: "numOfModels",
  CREATED_BY: "creatorDisplayName",
  TIMESTAMP_LAST_PREDICT: "timestampLastPredict",
  PRODUCTS: "products",
  LINES: "lines",
  CREATED_AT: "createdAt"
});

const numberSortFieldsMap = {
  [SORT_FIELDS.AVG_PREDICTION]: true,
  [SORT_FIELDS.NUM_OF_MODELS]: true,
  [SORT_FIELDS.TIMESTAMP_LAST_PREDICT]: true
};

export const DEFAULT_SORT_DIRECTION = SORT_ORDER.DESC;
export const DEFAULT_SORT_FIELD = SORT_FIELDS.CREATED_AT;

const StationTable = ({ stations, onDelete, filters }) => {
  const dispatch = useDispatch();

  const isAllowedToChangeStationStatus = usePermissions([ROLES_ENUM.CHANGE_STATION_STATUS]);

  const onStationStatusUpdate = useCallback(
    (stationId, blockState) => {
      dispatch(stationUpdateStatusAction(stationId, blockState));
    },
    [dispatch]
  );

  const data = useMemo(() => {
    return stations.map(station => ({
      status: station.status,
      station: <StationNameCell station={station} />,
      products: <StationProductsCell stationName={station.name} stationId={station.id} />,
      lines: <StationLinesCell stationName={station.name} stationId={station.id} />,
      lastPrediction: (
        <StationLastPredictionCell stationName={station.name} lastPredictTimestamp={station.lastPredictTimestamp} />
      ),
      createdBy: <StationCreatedByCell station={station} />,
      stationObject: station
    }));
  }, [stations]);

  const onSort = useCallback(({ rowA, rowB, sortField }) => {
    if (rowA?.original?.station && rowB?.original?.station) {
      const rowAValue = rowA.original.station.props.station[sortField];
      const rowBValue = rowB.original.station.props.station[sortField];
      if (numberSortFieldsMap[sortField]) {
        return rowBValue - rowAValue > 0 ? -1 : 1;
      }
      return (rowAValue || "").localeCompare(rowBValue || "");
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        minWidth: 84,
        maxWidth: 84,
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          if (row.original) {
            const station = row.original.stationObject;

            return (
              <StationStatusCell
                station={station}
                onStationStatusUpdate={isAllowedToChangeStationStatus && onStationStatusUpdate}
              />
            );
          }

          return null;
        },
        sortType: (rowA, rowB) => onSort({ rowA, rowB, sortField: SORT_FIELDS.STATUS }),
        filter: (rows, _, filterValue) => {
          if (!isEmpty(filterValue)) {
            return rows.filter(row => {
              const status = row.original.status;

              return filterValue.includes(status);
            });
          }

          return rows;
        }
      },
      {
        minWidth: 150,
        maxWidth: 150,
        Header: "Application",
        accessor: "station",
        sortType: (rowA, rowB) => onSort({ rowA, rowB, sortField: SORT_FIELDS.NAME }),
        filter: (rows, _, filterValue) => {
          if (!isEmpty(filterValue)) {
            return rows.filter(row => {
              const stationName = row.original.station.props.station.name;

              return stationName.includes(filterValue);
            });
          }

          return rows;
        }
      },
      {
        minWidth: 106,
        maxWidth: 106,
        Header: "Products",
        accessor: "products",
        disableSortBy: true,
        disableFilters: true
      },
      {
        minWidth: 80,
        maxWidth: 80,
        Header: "Line",
        accessor: "lines",
        disableSortBy: true,
        disableFilters: true
      },
      {
        minWidth: 129,
        maxWidth: 129,
        Header: "Last Prediction",
        accessor: "lastPrediction",
        sortType: (rowA, rowB) => onSort({ rowA, rowB, sortField: SORT_FIELDS.TIMESTAMP_LAST_PREDICT }),
        disableFilters: true
      },
      {
        minWidth: 95,
        maxWidth: 95,
        Header: "Created By",
        accessor: "createdBy",
        sortType: (rowA, rowB) => onSort({ rowA, rowB, sortField: SORT_FIELDS.CREATED_BY }),
        disableFilters: true
      }
    ],
    [onSort, isAllowedToChangeStationStatus, onStationStatusUpdate]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setFilter } = useTable(
    { columns, data },
    useFlexLayout,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded
  );

  useEffect(() => {
    setFilter("station", filters.station);
    setFilter("status", filters.statuses);
  }, [filters, setFilter]);

  const rowChunks = useMemo(() => {
    return chunk(rows, 10);
  }, [rows]);

  return (
    <Table {...getTableProps()} data-testid="applications-table">
      <TableHead data-testid="table-head-section">
        <TableHeadRows headerGroups={headerGroups} />
      </TableHead>
      <TableBody {...getTableBodyProps()} data-testid="table-body-section">
        <TableBodyRows rowChunks={rowChunks} prepareRow={prepareRow} onDelete={onDelete} />
      </TableBody>
    </Table>
  );
};

export default memo(StationTable);
