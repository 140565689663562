import React, { memo } from "react";

const InviteIconCircled = ({ fill = "white", ...rest }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      {...rest}
    >
      <rect x="0.4" y="0.4" width="31.2" height="31.2" rx="15.6" fill={fill} />
      <path
        d="M14.8667 14.8C14.1445 14.8 13.5306 14.5472 13.025 14.0416C12.5195 13.5361 12.2667 12.9222 12.2667 12.2C12.2667 11.4922 12.5195 10.8818 13.025 10.3687C13.5306 9.85622 14.1445 9.59998 14.8667 9.59998C15.5889 9.59998 16.2028 9.85622 16.7084 10.3687C17.2139 10.8818 17.4667 11.4922 17.4667 12.2C17.4667 12.9222 17.2139 13.5361 16.7084 14.0416C16.2028 14.5472 15.5889 14.8 14.8667 14.8ZM8.80005 21.0833C8.80005 18.4917 9.54489 16.9599 12.1263 16.2083C12.8699 15.9916 13.7834 15.8833 14.8667 15.8833H15.2567C15.1989 16.0278 15.1521 16.1722 15.1163 16.3166C15.0799 16.4611 15.0473 16.6055 15.0184 16.75H14.8667C13.8989 16.75 13.0539 16.8473 12.3317 17.042C10.8675 17.438 9.66672 18.6345 9.66672 20.2166H15.1267C15.17 20.3466 15.2206 20.4911 15.2784 20.65C15.3362 20.8089 15.4012 20.9533 15.4734 21.0833H8.80005ZM14.8667 13.9333C15.3434 13.9333 15.7516 13.7634 16.0913 13.4237C16.4305 13.0846 16.6 12.6766 16.6 12.2C16.6 11.7233 16.4305 11.3154 16.0913 10.9762C15.7516 10.6365 15.3434 10.4666 14.8667 10.4666C14.39 10.4666 13.9821 10.6365 13.643 10.9762C13.3032 11.3154 13.1334 11.7233 13.1334 12.2C13.1334 12.6766 13.3032 13.0846 13.643 13.4237C13.9821 13.7634 14.39 13.9333 14.8667 13.9333Z"
        fill="#091733"
      />
      <mask
        id="mask0_966_60549"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="14"
        y="13"
        width="11"
        height="11"
      >
        <rect x="14.8667" y="13.6078" width="9.53333" height="9.53333" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_966_60549)">
        <path
          d="M19.2557 20.3008H20.0107V18.7517H21.5599V17.9966H20.0107V16.4475H19.2557V17.9966H17.7065V18.7517H19.2557V20.3008ZM19.6332 22.2671C19.0968 22.2671 18.592 22.166 18.1188 21.9639C17.6453 21.7621 17.2316 21.4841 16.8776 21.1297C16.5232 20.7757 16.2452 20.362 16.0434 19.8885C15.8413 19.4153 15.7402 18.9105 15.7402 18.3741C15.7402 17.8314 15.8413 17.325 16.0434 16.855C16.2452 16.3847 16.5232 15.9725 16.8776 15.6185C17.2316 15.2642 17.6453 14.9862 18.1188 14.7844C18.592 14.5823 19.0968 14.4812 19.6332 14.4812C20.1759 14.4812 20.6825 14.5823 21.1528 14.7844C21.6228 14.9862 22.0348 15.2642 22.3888 15.6185C22.7431 15.9725 23.0212 16.3863 23.2229 16.8598C23.4251 17.3329 23.5261 17.8377 23.5261 18.3741C23.5261 18.9105 23.4251 19.4153 23.2229 19.8885C23.0212 20.362 22.7431 20.7757 22.3888 21.1297C22.0348 21.4841 21.621 21.7621 21.1475 21.9639C20.6744 22.166 20.1696 22.2671 19.6332 22.2671ZM19.6332 21.4429C20.4874 21.4429 21.2122 21.1448 21.8077 20.5487C22.4039 19.9532 22.702 19.2283 22.702 18.3741C22.702 17.52 22.4039 16.7951 21.8077 16.1996C21.2122 15.6034 20.4874 15.3054 19.6332 15.3054C18.779 15.3054 18.0541 15.6034 17.4586 16.1996C16.8625 16.7951 16.5644 17.52 16.5644 18.3741C16.5644 19.2283 16.8625 19.9532 17.4586 20.5487C18.0541 21.1448 18.779 21.4429 19.6332 21.4429Z"
          fill="#091733"
        />
      </g>
      <rect x="0.4" y="0.4" width="31.2" height="31.2" rx="15.6" stroke="#B5B9C2" strokeWidth="0.8" />
    </svg>
  );
};

export default memo(InviteIconCircled);
