import { getS3ReportPresignedUrl } from "services/api/block";
import dayjs from "dayjs";

export function checkFloatValueRange(value, min, max) {
  value = parseFloat(value);
  if (isNaN(value)) {
    return null;
  }
  if (value < min || value > max) {
    return null;
  }
  return value;
}

export function extractFileType(fileName) {
  if (fileName) {
    return fileName.split(".").pop();
  }
}

export function getConfFromBlock(block) {
  if (block.supervisedConf?.autoMode) {
    return block.supervisedConf;
  }
  if (block.unsupervisedConf?.autoMode) {
    return block.unsupervisedConf;
  }
  if (block.regressionConf?.autoMode) {
    return block.regressionConf;
  }
  return block.modelConf;
}

export async function downloadAndPlugInDynamicPlot(block) {
  if (block.clusteringReport && !block.clusteringReport.dynamicPlot) {
    const url = await getS3ReportPresignedUrl(block.id);
    const response = await fetch(url);
    block.clusteringReport.dynamicPlot = (await response.json()).dynamic_plot;
  }
  if (block.regressionReport && !block.regressionReport.dynamicPlot) {
    const url = await getS3ReportPresignedUrl(block.id);
    const response = await fetch(url);
    block.regressionReport.dynamicPlot = (await response.json()).dynamic_plot;
  }
  return block;
}

export const dummyStationState = station => {
  return station.isDummy == undefined || false ? false : true;
};

export const getEmailDomain = value => {
  return value
    .trim()
    .toLowerCase()
    .split("@")
    .pop();
};

const getHours = date => {
  return new Date(date).getHours() > 9 ? new Date(date).getHours() : "0" + new Date(date).getHours();
};

const getMinutes = date => {
  return new Date(date).getMinutes() > 9 ? new Date(date).getMinutes() : "0" + new Date(date).getMinutes();
};

export const getFormattedDateForDisplay = (date, showFullYear = false, showTime = true) => {
  const formattedYear = showFullYear ? "numeric" : "2-digit";

  const formattedTime = `${getHours(date)}:${getMinutes(date)}`;

  const formattedDate = `${new Date(date).getDate()} ${new Date(date).toLocaleDateString("default", {
    month: "short",
    year: formattedYear
  })}`;

  return showTime ? `${formattedTime} | ${formattedDate}` : formattedDate;
};

export const getTodayDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0
  const yyyy = today.getFullYear();

  return dd + "." + mm + "." + yyyy;
};

export const elapsedTimePretty = createdAt => {
  const todayMoment = dayjs();
  const createdDateMoment = dayjs(createdAt);
  let diff = todayMoment.diff(createdDateMoment, "days");
  if (diff >= 1) return diff + " days ago";
  diff = todayMoment.diff(createdDateMoment, "hours");
  if (diff >= 1) return diff + " hours ago";
  return Math.ceil(todayMoment.diff(createdDateMoment, "minutes")) + " min ago";
};
