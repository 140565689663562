import React, { memo } from "react";

const EntitiesAndFlowsIcon = ({ width = 24, height = 24 }) => {
  return (
    <svg
      fill="#000000"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 502.12 502.12"
      width={width}
      height={height}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g>
          {" "}
          <path d="M490.629,333.483H295.55c-6.348,0-11.502,5.166-11.502,11.502v43.456h-55.106v-68.967h126.485 c6.336,0,11.502-5.16,11.502-11.503V189.647c0-6.342-5.166-11.5-11.502-11.5H160.354c-6.352,0-11.502,5.163-11.502,11.5v47.954 H96.08v-68.97h110.496c6.351,0,11.5-5.163,11.5-11.5V38.816c0-6.345-5.154-11.499-11.5-11.499H11.5C5.163,27.317,0,32.479,0,38.816 v118.316c0,6.345,5.169,11.5,11.5,11.5h72.469v81.081h64.876v58.259c0,6.349,5.157,11.503,11.502,11.503h56.483v81.077h67.217 v62.755c0,6.343,5.154,11.497,11.496,11.497h195.073c6.337,0,11.503-5.16,11.503-11.497V344.985 C502.126,338.649,496.966,333.483,490.629,333.483z M11.5,157.448c-0.174,0-0.319-0.142-0.319-0.322V38.816 c0-0.174,0.139-0.322,0.319-0.322h195.076c0.177,0,0.334,0.139,0.334,0.322v118.316c0,0.174-0.151,0.322-0.334,0.322H11.5V157.448z M160.348,308.291c-0.178,0-0.322-0.142-0.322-0.319v-58.265v-12.105v-47.954c0-0.169,0.139-0.322,0.322-0.322h195.079 c0.171,0,0.318,0.147,0.318,0.322v118.324c0,0.166-0.142,0.319-0.318,0.319H160.348z M490.948,463.294 c0,0.178-0.142,0.325-0.319,0.325H295.55c-0.177,0-0.33-0.136-0.33-0.325v-118.31c0-0.172,0.147-0.319,0.33-0.319h195.079 c0.172,0,0.319,0.142,0.319,0.319V463.294z"></path>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export default memo(EntitiesAndFlowsIcon);
