import { createSelector } from "reselect";

export const onboardingSelector = state => state.onboarding.state;

export const stationSelector = createSelector(
  onboardingSelector,
  state => state.station
);

export const isSubmitPageLoadedSelector = createSelector(
  onboardingSelector,
  state => state.isSubmitPageLoaded
);

export const onboardingTextTrainingStatusModelSelector = createSelector(
  onboardingSelector,
  state => state.onboardingTextTrainingStatus
);

export const onboardingWarningsSelector = createSelector(
  onboardingSelector,
  state => state.warnings
);

export const stationIdSelector = createSelector(
  onboardingSelector,
  state => state.station.stationId
);

export const stationNameSelector = createSelector(
  onboardingSelector,
  state => state.station.stationName
);

export const stationDescriptionSelector = createSelector(
  onboardingSelector,
  state => state.station.stationDescription
);

export const onboardingStationProductsSelector = createSelector(
  onboardingSelector,
  state => state.station.products
);

export const onboardingStationLinesSelector = createSelector(
  onboardingSelector,
  state => state.station.lines
);

export const modelSelector = createSelector(
  onboardingSelector,
  state => state.model
);

export const isOnboardingExistingModelSelector = createSelector(
  onboardingSelector,
  state => !!state.model.rootBlockId
);

export const modelIdSelector = createSelector(
  onboardingSelector,
  modelSelector,
  state => state.model.modelId
);

export const modelNameSelector = createSelector(
  onboardingSelector,
  modelSelector,
  state => state.model.modelName
);

export const modelTypeSelector = createSelector(
  onboardingSelector,
  modelSelector,
  state => state.model.type
);

export const modelConfigurationSelector = createSelector(
  onboardingSelector,
  modelSelector,
  state => state.model.configuration
);

export const modelFirstFileSelector = createSelector(
  onboardingSelector,
  modelSelector,
  state => state.model.file
);

export const navigationSelector = createSelector(
  onboardingSelector,
  state => state.navigation
);

export const navigationCurrentStepSelector = createSelector(
  navigationSelector,
  state => state?.stepper?.currentStep
);

export const navigationNextCallbackSelector = createSelector(
  navigationSelector,
  state => state.next.callback
);

export const navigationBackCallbackSelector = createSelector(
  navigationSelector,
  state => state.back.callback
);
