import { call, put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./actions";
import * as accountService from "services/api/accounts";

export function* createAccountWorker(action) {
  const { email, accountName, phoneNumber, selectedIndustries, selectedRole, marketplace, token } = action.payload;

  try {
    yield call(
      accountService.createAccount,
      email,
      accountName,
      phoneNumber,
      selectedIndustries,
      selectedRole,
      marketplace,
      token
    );

    yield put({ type: actionTypes.CREATE_ACCOUNT_SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.CREATE_ACCOUNT_ERROR, payload: error });
  } finally {
    yield put({ type: actionTypes.CREATE_ACCOUNT_FULFILL });
  }
}

export default function* createAccountWatcher() {
  yield takeLatest(actionTypes.CREATE_ACCOUNT_REQUEST, createAccountWorker);
}