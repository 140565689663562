import React from "react";

const EyeClosed = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9999 18.39C9.92225 18.4026 7.88289 17.8307 6.11467 16.7397C4.34644 15.6487 2.9206 14.0825 1.99992 12.22L1.91992 12L2.01992 11.78C2.93911 9.92043 4.36183 8.35628 6.12618 7.26549C7.89053 6.17471 9.92564 5.6011 11.9999 5.60995C14.0783 5.59381 16.1191 6.16419 17.888 7.25558C19.6569 8.34697 21.0821 9.91512 21.9999 11.78L22.0999 12L21.9999 12.22C21.0821 14.0848 19.6569 15.6529 17.888 16.7443C16.1191 17.8357 14.0783 18.4061 11.9999 18.39ZM2.99992 12C3.86275 13.6396 5.15713 15.0125 6.74328 15.9702C8.32943 16.9279 10.1471 17.434 11.9999 17.434C13.8528 17.434 15.6704 16.9279 17.2566 15.9702C18.8427 15.0125 20.1371 13.6396 20.9999 12C20.1371 10.3603 18.8427 8.98744 17.2566 8.02975C15.6704 7.07205 13.8528 6.56587 11.9999 6.56587C10.1471 6.56587 8.32943 7.07205 6.74328 8.02975C5.15713 8.98744 3.86275 10.3603 2.99992 12Z"
      fill="#13115B"
    />
    <path
      d="M12 16C11.2089 16 10.4355 15.7654 9.77772 15.3259C9.11993 14.8864 8.60723 14.2616 8.30448 13.5307C8.00173 12.7998 7.92252 11.9956 8.07686 11.2196C8.2312 10.4437 8.61216 9.73098 9.17157 9.17157C9.73098 8.61216 10.4437 8.2312 11.2196 8.07686C11.9956 7.92252 12.7998 8.00173 13.5307 8.30448C14.2616 8.60723 14.8864 9.11993 15.3259 9.77772C15.7654 10.4355 16 11.2089 16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16ZM12 9C11.4067 9 10.8266 9.17595 10.3333 9.50559C9.83994 9.83524 9.45543 10.3038 9.22836 10.852C9.0013 11.4001 8.94189 12.0033 9.05765 12.5853C9.1734 13.1672 9.45912 13.7018 9.87868 14.1213C10.2982 14.5409 10.8328 14.8266 11.4147 14.9424C11.9967 15.0581 12.5999 14.9987 13.1481 14.7716C13.6962 14.5446 14.1648 14.1601 14.4944 13.6667C14.8241 13.1734 15 12.5933 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7957 9 12 9Z"
      fill="#13115B"
    />
  </svg>
);

export default EyeClosed;
