import React, { memo } from "react";

const ErrorIcon = ({ width = 40, height = 41, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 3C10.335 3 2.5 10.835 2.5 20.5C2.5 30.165 10.335 38 20 38C29.665 38 37.5 30.165 37.5 20.5C37.5 10.835 29.665 3 20 3ZM0 20.5C0 9.45431 8.95431 0.5 20 0.5C31.0457 0.5 40 9.45431 40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.95431 40.5 0 31.5457 0 20.5Z"
      fill="#FF3C78"
    />
    <path
      d="M30 11.1233L29.3776 10.5L20 19.8766L10.623 10.5L10 11.1233L19.377 20.4998L10 29.8764L10.623 30.5L20 21.1221L29.3776 30.5L30 29.8764L20.623 20.4998L30 11.1233Z"
      stroke="#FF3C78"
      strokeWidth="2"
    />
  </svg>
);

export default memo(ErrorIcon);
