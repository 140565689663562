import React, { memo, useContext, useEffect } from "react";
import { styled } from "@material-ui/core";
import DataPageHeader from "./components/DataPageHeader";
import DataRowsWrapper from "./components/DataRowsWrapper";
import { buildConnectorForDisplay, buildDatasetForDisplay, SOURCE_TYPES } from "./constants";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { allDataConnectorsSelector, isLoadingConnectorsSelector } from "modules/data-connectors/selectors";
import { dataConnectorsStartFetch } from "modules/data-connectors/actions";
import { getAccountDatasets } from "services/api/dataset";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { useNotifications } from "common/hooks/use-notification";
import { accountIdSelector } from "modules/account/state/selectors";
import StateWrapper from "components/ui/StateWrapper/StateWrapper";
import { Skeleton } from "@material-ui/lab";
import { navigationSetBreadcrumbsType } from "modules/navigation/actions";
import { BREADCRUMBS_TYPES } from "common/constants/NavigationConstants";
import useAccountUserNamesRequest from "common/hooks/use-account-user-names-request";

export const FETCH_ACCOUNT_DATASETS_KEY = "fetch.account.datasets";

const DataPageWrapperStyled = styled("div")({
  padding: 16,
  rowGap: 16,
  display: "flex",
  flexDirection: "column",
  overflow: "auto"
});

const SkeletonStyled = styled(Skeleton)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  opacity: 0.3
}));

const LoadingDataSkeleton = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 24 }}>
      <SkeletonStyled variant="rect" height={64} />
      <SkeletonStyled variant="rect" height={64} />
      <SkeletonStyled variant="rect" height={64} />
    </div>
  );
};

const DataPage = () => {
  const dispatch = useDispatch();

  const { datasetsContext } = useContext(AppContext);
  const { setContext } = datasetsContext;

  const accountId = useSelector(accountIdSelector);
  const accountDataConnectors = useSelector(allDataConnectorsSelector);
  const isLoadingAccountConnectors = useSelector(isLoadingConnectorsSelector);

  useNotifications(accountId);
  useAccountUserNamesRequest();

  const fetchAccountDatasetsReq = useQuery([FETCH_ACCOUNT_DATASETS_KEY], async () => getAccountDatasets(), {
    manual: false,
    enabled: true,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false
  });

  useEffect(() => {
    dispatch(navigationSetBreadcrumbsType(BREADCRUMBS_TYPES.NONE));
    dispatch(dataConnectorsStartFetch());
    setContext(prevState => ({ ...prevState, selectedDatasetsForCuration: [] }));
  }, []);

  useEffect(() => {
    if (fetchAccountDatasetsReq.data?.ok) {
      const { data } = fetchAccountDatasetsReq.data;

      if (!data) return;

      const connectors = [];
      const uploadedFiles = [];
      const curatedDatasets = [];
      const datasets = {};

      data.forEach(dataset => {
        datasets[dataset._id] = dataset;

        if (dataset.sourceType === SOURCE_TYPES.LOCAL_UPLOAD) {
          uploadedFiles.push(buildDatasetForDisplay(dataset));
        } else if (dataset.sourceType === SOURCE_TYPES.CURATION) {
          curatedDatasets.push(buildDatasetForDisplay(dataset));
        }
      });

      accountDataConnectors.forEach(connector => {
        const datasets = data.filter(dataset => dataset.sourceDataConnectorId === connector._id);
        connectors.push(buildConnectorForDisplay(datasets, connector));
      });

      setContext(prevState => ({ ...prevState, connectors, uploadedFiles, curatedDatasets, datasets }));
    }
  }, [fetchAccountDatasetsReq.data, accountDataConnectors]);

  return (
    <DataPageWrapperStyled>
      <DataPageHeader />

      <StateWrapper
        success={!(isLoadingAccountConnectors || fetchAccountDatasetsReq.isFetching)}
        LoadingComponent={<LoadingDataSkeleton />}
      >
        <DataRowsWrapper />
      </StateWrapper>
    </DataPageWrapperStyled>
  );
};

export default memo(DataPage);
