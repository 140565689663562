import { put, takeLatest, call } from "redux-saga/effects";
import * as predictionService from "services/api/prediction";
import predictionActionTypes from "./actionTypes";

// eslint-disable-next-line no-unused-vars
function* predictionSaga(action) {
  try {
    const { stationId } = action.payload;
    const predictionResult = yield call(predictionService.getPredictionResults, stationId);
    yield put({ type: predictionActionTypes.PREDICTION_QUERY_SUCCESS, payload: { predictionResult } });
  } catch (error) {
    yield put({ type: predictionActionTypes.PREDICTION_QUERY_ERROR, payload: { error } });
  }
}

export function* predictionWatcher() {
  yield takeLatest(predictionActionTypes.PREDICTION_QUERY_REQUEST, predictionSaga);
}
