import React, { memo } from "react";
import { vantiColors } from "assets/jss/palette";

const CloseIcon24 = ({ color = "none", stroke = vantiColors.black1, ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18 6.5L6 18.5M6 6.5L18 18.5" stroke={stroke} strokeWidth="1.25" />
  </svg>
);

export default memo(CloseIcon24);
