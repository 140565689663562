import React, { memo, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import CircleSolidIcon from "assets/icons/CircleSolidIcon";
import DeleteConnectorDialog from "./DeleteConnectorDialog";
import TableBody from "@material-ui/core/TableBody";
import { FlexContainer, FlexItem, Text } from "components/ui";
import { IconButton, styled, TableCell, TableRow } from "@material-ui/core";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import { getFormattedDateForDisplay, getFormattedTimeForDisplay } from "../../../utils";
import usePermissions from "common/hooks/use-permissions";
import EditIcon from "assets/icons/EditIcon";
import { ReactComponent as Trash } from "assets/img/trash-icon.svg";
import { DATA_CONNECTORS_URL_PREFIX } from "pages/side-bar/data-connectors/constants";
import { APP_MAIN_BACKGROUND_COLOR, vantiColors } from "assets/jss/palette";

const TableRowStyled = styled(TableRow)(({ theme, cursor }) => ({
  borderBottom: `1px solid ${APP_MAIN_BACKGROUND_COLOR}`,
  "&:last-child td, &:last-child th": {
    border: 0
  },
  "&:hover": {
    backgroundColor: theme.palette.vantiColors.lightBlue10
  },
  cursor
}));

const NameCellTextStyled = styled(Text)({
  display: "flex",
  columnGap: 12
});

const IconButtonsWrapperStyled = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  columnGap: 12
});

const IconButtonStyled = styled(IconButton)({
  backgroundColor: vantiColors.gray36,
  padding: 8
});

const TableCellStyled = styled(TableCell)({
  borderBottom: "none",
  padding: "0px 16px",
  height: 84,
  boxSizing: "border-box"
});

const DataConnectorsTableBody = ({ rows, ...props }) => {
  const history = useHistory();
  const isGrantedToEditDc = usePermissions([ROLES_ENUM.MANAGE_DATA_CONNECTORS]);

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);

  const actions = useMemo(
    () => [
      {
        id: "edit-icon",
        Icon: EditIcon,
        onClick: () => {
          history.push(`${DATA_CONNECTORS_URL_PREFIX}/${rows[currentRowIndex]._id}`);
        }
      },
      {
        id: "trash-icon",
        Icon: Trash,
        onClick: () => {
          setIsShowDeleteDialog(true);
        }
      }
    ],
    [rows, currentRowIndex]
  );

  return (
    <TableBody {...props}>
      {rows.map((row, rowIndex) => (
        <TableRowStyled
          key={row.title}
          onMouseEnter={() => {
            setHoveredRowIndex(rowIndex);
            setCurrentRowIndex(rowIndex);
          }}
          onMouseLeave={() => {
            setHoveredRowIndex(null);
          }}
          cursor={isGrantedToEditDc ? "pointer" : "auto"}
          data-testid={`data-connectors-table-row-${rows[rowIndex]._id}`}
          onClick={() => {
            if (row.sourceType !== "data_bricks" && isGrantedToEditDc) {
              history.push(`${DATA_CONNECTORS_URL_PREFIX}/${rows[rowIndex]._id}`);
            }
          }}
        >
          <TableCellStyled data-testid={`data-connector-name-${rows[rowIndex].title}`} style={{ width: "240px" }}>
            <NameCellTextStyled size={"16px"} weight={16} color={vantiColors.gray39}>
              <CircleSolidIcon width={8} weight="medium" />
              {row.title}
            </NameCellTextStyled>
          </TableCellStyled>

          <TableCellStyled
            data-testid={`data-connector-data-source-${rows[rowIndex].title}`}
            style={{ width: "240px" }}
          >
            <Text size={"16px"} weight={16} color={vantiColors.gray39}>
              {row.sourceType}
            </Text>
          </TableCellStyled>

          <TableCellStyled style={{ width: "240px" }}>
            <FlexContainer direction="column">
              <FlexItem dense="full" data-testid={`data-connector-user-name-${rows[rowIndex].title}`}>
                <Text size={"16px"} weight={16} color={vantiColors.gray39}>
                  {row.lastConnectionBy}
                </Text>
              </FlexItem>

              <FlexItem dense="full" data-testid={`data-connector-last-connection-${rows[rowIndex].title}`}>
                <Text size={12} weight={400} color={vantiColors.gray33}>
                  {row.lastConnectionAt
                    ? `${getFormattedTimeForDisplay(new Date(row.lastConnectionAt))} |
                      ${getFormattedDateForDisplay(new Date(row.lastConnectionAt))}`
                    : "-"}
                </Text>
              </FlexItem>
            </FlexContainer>
          </TableCellStyled>

          <TableCellStyled style={{ width: "10%" }}></TableCellStyled>

          <TableCellStyled align="right">
            {rowIndex === hoveredRowIndex && isGrantedToEditDc && (
              <FlexItem dense="full" style={{ position: "relative" }}>
                <IconButtonsWrapperStyled>
                  {actions.map(({ Icon, onClick, id }) => (
                    <IconButtonStyled
                      data-testid={id}
                      onClick={e => {
                        e.stopPropagation();
                        onClick();
                      }}
                      key={id}
                    >
                      <Icon />
                    </IconButtonStyled>
                  ))}
                </IconButtonsWrapperStyled>
              </FlexItem>
            )}
          </TableCellStyled>
        </TableRowStyled>
      ))}

      <DeleteConnectorDialog
        clickedRowIndex={currentRowIndex}
        isShowDeleteDialog={isShowDeleteDialog}
        setIsShowDeleteDialog={setIsShowDeleteDialog}
        currentConnector={rows[currentRowIndex] || {}}
      />
    </TableBody>
  );
};

export default memo(DataConnectorsTableBody);
