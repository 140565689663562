import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Snackbar, styled } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import GeneralModalPreviewComponent from "components/ui/Modal/GeneralModalPreviewComponent";
import ReactFlow, { addEdge, useEdgesState, useNodesState } from "reactflow";
import "reactflow/dist/style.css";
import NewAlertCreationScreen from "./NewAlertCreationScreen";
import CustomEdge from "./CustomEdge";
import CustomNode from "./CustomNode";
import useFlowRequest from "common/hooks/use-flow-request";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import useDashboardRequest from "common/hooks/use-dashboard-request";
import { useParams } from "react-router-dom";
import Drawer from "./drawer/Drawer";
import Header from "./Header";
import Loader from "components/ui/Loader";
import { Text } from "components/ui";
import { DarkToolTip } from "components/ui/tooltips/tooltip";
import { vantiColors } from "assets/jss/palette";

const HorizontalFlowWrapper = styled(ReactFlow)({
  width: "auto"
});

const SaveButtonWrapper = styled("div")({
  position: "absolute",
  bottom: 50,
  right: 50,
  zIndex: 99999
});

const AlertsDrawerContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 8
});

const TooltipContentWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: 8
});

const WrapperStyled = styled("div")({
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
});

const getId = () => `${Math.random().toFixed(3)}`;

const EDGE_TYPES = {
  CustomEdge: "CustomEdge"
};

const edgeTypes = {
  [EDGE_TYPES.CustomEdge]: CustomEdge
};

const NODE_TYPES = {
  CustomNode: "CustomNode"
};

const nodeTypes = {
  [NODE_TYPES.CustomNode]: CustomNode
};

const HorizontalFlow = ({ mainStationData, nodeToModify, onAddNodeFinishCallback = () => {} }) => {
  const { flowContext, dataEntitiesContext } = useContext(AppContext);
  const { flows } = flowContext;
  const { dataEntities } = dataEntitiesContext;

  const { flowId } = useParams();

  const { createFlowReq, updateFlowReq, getAccountFlowsReq, createAlertReq } = useFlowRequest();

  const [hoveringAlertIndex, setHoveringAlertIndex] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);

  const nodeChangeEventValue = useRef(null);

  const initialNodes = useMemo(() => {
    const id = getId();

    return [
      {
        id,
        type: NODE_TYPES.CustomNode,
        data: {
          prevLabel: "Station 1",
          label: "Station 1",
          entities: [],
          name: "Station 1",
          description: "Description",
          childEntities: [],
          onClick: () => setSelectedNodeId(id)
        },
        position: { x: 50, y: 100 },
        mainEntity: {}
      }
    ];
  }, []);

  const [nodesLocal, setNodesLocal, onNodesChange] = useNodesState(initialNodes);
  const [edgesLocal, setEdgesLocal, onEdgesChange] = useEdgesState([]);

  const [newAlert, setNewAlert] = useState(null);
  const [showMetricCreatedSnackbar, setShowMetricCreatedSnackbar] = useState(false); //temp
  const [showFlowSavedSnackbar, setShowFlowSavedSnackbar] = useState(false); //temp
  const [hoveredEdge, setHoveredEdge] = useState(null);

  const [failedMetricCreatedErrorMsg, setFailedMetricCreatedErrorMsg] = useState(null);

  const [flowTitle, setFlowTitle] = useState("");
  const [flowDescription, setFlowDescription] = useState("");

  const [isDrawerOpenWithStationData, setIsDrawerOpenWithStationData] = useState(false);
  const [alertsToShow, setAlertsToShow] = useState([]);

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);

  const [alertName, setAlertName] = useState(null);
  const [alertDescription, setAlertDescription] = useState(null);

  const { dashboardContext } = useContext(AppContext);
  const { dashboards } = dashboardContext;
  const { getDashboardItemsRequest, getDashboardsRequest } = useDashboardRequest();

  const { nodes, edges } = useMemo(() => flows[flowId] || { nodes: [], edges: [] }, [flows, flowId]);

  const connectingNodeId = useRef(null);

  const saveFlowProgress = useCallback(
    (_flowId, _nodes, _edges, _flowTitle, _flowDescription) => {
      updateFlowReq.mutate({
        flowId: _flowId || (flowId === "new" ? createFlowReq.data?._id : flowId),
        nodes: _nodes,
        edges: _edges,
        flowTitle: _flowTitle,
        flowDescription: _flowDescription
      });
    },
    [flowId, createFlowReq.data?._id]
  );

  const onCreateAlert = useCallback((name, description) => {
    const { id } = newAlert;

    createAlertReq.mutate({
      edgeId: id,
      flowId: createFlowReq.data?._id || flowId,
      userPrompt: description
    });

    setAlertName(name);
    setAlertDescription(description);

    setFailedMetricCreatedErrorMsg(null);
  });

  const onClickDelete = useCallback(
    id => {
      setEdgesLocal(es => {
        const edgesNew = es.filter(e => e.id !== id);
        saveFlowProgress(undefined, undefined, edgesNew);
        return edgesNew;
      });
    },
    [saveFlowProgress]
  );

  const onClickAddNewAlert = useCallback(
    (id, source, target) => {
      setNewAlert({ id, source, target });
    },
    [edgesLocal]
  );

  const onClickShowAlerts = useCallback(alerts => {
    if (alerts) {
      setAlertsToShow(alerts);
    }
  }, []);

  const onConnect = useCallback(
    connection => {
      connectingNodeId.current = null;
      const edge = {
        ...connection,
        id: `${connection.source}->${connection.target}`,
        type: EDGE_TYPES.CustomEdge,
        data: { onClickDelete, onClickAdd: onClickAddNewAlert, onClickShowAlerts, alerts: [] }
      };
      setEdgesLocal(eds => {
        const edgesNew = addEdge(edge, eds);
        saveFlowProgress(undefined, undefined, edgesNew);
        return edgesNew;
      });
    },
    [connectingNodeId?.current, onClickDelete, onClickAddNewAlert, onClickShowAlerts, saveFlowProgress]
  );

  const onConnectStart = useCallback(
    (_, { nodeId }) => {
      connectingNodeId.current = nodeId;
    },
    [connectingNodeId?.current]
  );

  const onPaneClick = useCallback(() => {
    setIsEditingTitle(false);
    setIsEditingDescription(false);
    setIsDrawerOpenWithStationData(false);
    setSelectedNode(null);
    setSelectedNodeId(null);
    setAlertsToShow([]);
  }, []);

  const onEdgeMouseEnter = useCallback(
    (event, edge) => {
      if (hoveredEdge) {
        return;
      }

      setHoveredEdge(edge.id);
    },
    [hoveredEdge]
  );

  useEffect(() => {
    if (flowId === "new") {
      createFlowReq.mutate({
        nodes: [],
        edges: [],
        title: "Default flow title",
        description: "Default flow description"
      });
    }
  }, [flowId]);

  const addNewNode = nodeData => {
    setNodesLocal(nodes => {
      const nodesNew = nodes.concat(nodeData);
      saveFlowProgress(undefined, nodesNew);
      return nodesNew;
    });
  };

  const addNewEdge = edgeData => {
    setEdgesLocal(edges => {
      const edgesNew = edges.concat(edgeData);
      saveFlowProgress(undefined, undefined, edgesNew);
      return edgesNew;
    });
  };

  const onClickAddStation = useCallback(() => {
    const id = getId();
    const stationEntity = Object.values(dataEntities).find(entity => entity.name === "STATION");

    if (!stationEntity) return;

    const newNode = {
      id,
      position: {
        x: 300,
        y: 300
      },
      data: { prevLabel: "Station 1", label: "Station 1", entities: [] },
      origin: [0.5, 0.0],
      mainEntity: {
        [stationEntity._id]: "Station 1"
      },
      childEntities: [],
      type: NODE_TYPES.CustomNode
    };

    addNewNode(newNode);
    setIsDrawerOpenWithStationData(true);
  }, [dataEntities]);

  const onUpdateNode = useCallback(
    (stationData, nodeId) => {
      setNodesLocal(nodes => {
        const nodesCopy = [...nodes];

        if (nodeId) {
          const foundNodeIndex = nodesCopy.findIndex(node => node.id === nodeId);

          if (foundNodeIndex >= 0) {
            const foundNode = nodesCopy[foundNodeIndex];

            const updatedNode = { ...foundNode, data: { ...foundNode.data, ...stationData } };

            nodesCopy[foundNodeIndex] = updatedNode;
          }
        } else {
          const lastNodeAdded = { ...nodesCopy.at(-1) };

          const updatedNode = { ...lastNodeAdded, data: { ...lastNodeAdded.data, ...stationData } };

          nodesCopy[nodesCopy.length - 1] = updatedNode;
        }

        saveFlowProgress(undefined, nodesCopy);

        return nodesCopy;
      });
    },
    [saveFlowProgress]
  );

  useEffect(() => {
    if (mainStationData) {
      const id = getId();
      const stationEntity = Object.values(dataEntities).find(entity => entity.name === "STATION");

      const newNode = {
        id,
        position: {
          x: mainStationData?.position?.x,
          y: mainStationData?.position?.y
        },
        data: {
          prevLabel: mainStationData.label,
          label: mainStationData.label,
          entities: [],
          onClick: () => setSelectedNodeId(id)
        },
        origin: [0.5, 0.0],
        mainEntity: {
          [stationEntity._id]: mainStationData.label
        },
        childEntities: [],
        type: NODE_TYPES.CustomNode,
        sourcePosition: "right",
        targetPosition: "left"
      };

      addNewNode(newNode);

      addNewEdge({
        id: `${mainStationData?.id}->${id}`, //needs to be in form of a->b
        source: mainStationData?.source?.id,
        target: id,
        type: EDGE_TYPES.CustomEdge,
        data: { onClickDelete, onClickAdd: onClickAddNewAlert, onClickShowAlerts }
      });
    }
  }, [mainStationData, dataEntities, onClickAddNewAlert, onClickDelete, onClickShowAlerts]);

  useEffect(() => {
    if (nodeToModify) {
      setNodesLocal(nodes => {
        const nodesCopy = [...nodes];

        const foundNodeIndex = nodesCopy.findIndex(node => node.id === nodeToModify?.node?.id);

        if (foundNodeIndex >= 0) {
          nodesCopy[foundNodeIndex] = {
            ...nodesCopy[foundNodeIndex],
            childEntities: [...nodesCopy[foundNodeIndex].childEntities, nodeToModify.newEntity]
          };
        }

        return nodesCopy;
      });

      onAddNodeFinishCallback();
    }
  }, [nodeToModify, onAddNodeFinishCallback]);

  useEffect(() => {
    getAccountFlowsReq.mutate();
  }, []);

  useEffect(() => {
    if (nodes?.length) {
      const nodesModified = [...nodes].map(node => ({
        ...node,
        data: {
          ...node?.data,
          onClick: () => setSelectedNodeId(node.id)
        }
      }));
      setNodesLocal(nodesModified);
    }

    if (edges?.length) {
      setEdgesLocal(() => {
        const _edges = [...edges].map(edge => {
          edge.data = { ...edge.data, onClickDelete, onClickAdd: onClickAddNewAlert, onClickShowAlerts };
          return edge;
        });

        return _edges;
      });
    }
  }, [nodes, edges]);

  useEffect(() => {
    if (Object.keys(dashboards).length) {
      getDashboardItemsRequest.mutate({ dashboardId: Object.keys(dashboards)[0] });
    } else {
      getDashboardsRequest.mutate();
    }
  }, [dashboards]);

  useEffect(() => {
    setEdgesLocal(eds => {
      const edgesCopy = [...eds];

      if (!hoveredEdge) {
        //reset all hovered states
        const _edges = edgesCopy.map(edg => ({
          ...edg,
          data: {
            ...edg.data,
            isHovered: false
          }
        }));

        return _edges;
      }

      const idx = edgesCopy.findIndex(edg => edg.id === hoveredEdge);
      if (idx !== -1) {
        edgesCopy[idx].data = { ...edgesCopy[idx].data, isHovered: true };
      }
      return edgesCopy;
    });
  }, [hoveredEdge]);

  useEffect(() => {
    const result = createAlertReq.data;

    if (createAlertReq.error) {
      setFailedMetricCreatedErrorMsg(createAlertReq.error?.message);
    }

    if (result?.ok && newAlert) {
      setEdgesLocal(() => {
        if (result?.data?.failure) {
          setFailedMetricCreatedErrorMsg("The creation of the alert has failed. Please try again");
        } else {
          const edgesCopy = [...edgesLocal];
          const idx = edgesCopy.findIndex(e => e.id.toString() === newAlert?.id.toString());

          if (idx !== -1) {
            const edgeData = edgesCopy[idx].data || {};

            edgesCopy[idx] = {
              ...edgesCopy[idx],
              data: {
                ...edgeData,
                alerts: [
                  ...edgesCopy[idx].data.alerts,
                  { name: alertName, description: alertDescription, metricId: result?.data?._id }
                ]
              }
            };
          }

          setNewAlert(null);
          setShowMetricCreatedSnackbar(true);
          setFailedMetricCreatedErrorMsg(null);
          setAlertName(null);
          setAlertDescription(null);

          saveFlowProgress(undefined, undefined, edgesCopy);
          return edgesCopy;
        }
      });
    }
  }, [
    { ...createAlertReq.data },
    { ...createAlertReq.data?.data },
    newAlert,
    alertName,
    alertDescription,
    saveFlowProgress
  ]);

  useEffect(() => {
    const foundNode = nodesLocal?.find(node => node.id === selectedNodeId);

    if (foundNode) {
      setSelectedNode(foundNode);
      setIsDrawerOpenWithStationData(true);
    }
  }, [selectedNodeId, nodesLocal]);

  useEffect(() => {
    if (createFlowReq.isSuccess || updateFlowReq.isSuccess) {
      setShowFlowSavedSnackbar(true);
    }
  }, [createFlowReq.isSuccess, updateFlowReq.isSuccess]);

  const SnackbarContent = useMemo(() => {
    return showMetricCreatedSnackbar ? (
      <Alert onClose={() => setShowMetricCreatedSnackbar(false)} severity="success" sx={{ width: "100%" }}>
        {"New metric has been created!"}
      </Alert>
    ) : showFlowSavedSnackbar ? (
      <Alert onClose={() => setShowFlowSavedSnackbar(false)} severity="success" sx={{ width: "100%" }}>
        {"Saved current flow!"}
      </Alert>
    ) : null;
  }, [showMetricCreatedSnackbar, showFlowSavedSnackbar]);

  const onCloseAllSnackbars = useCallback(() => {
    setShowMetricCreatedSnackbar(false);
    setShowFlowSavedSnackbar(false);
  }, []);

  useEffect(() => {
    if (flows[flowId]?.title) {
      setFlowTitle(flows[flowId]?.title);
    } else {
      setFlowTitle("Flow 1");
    }

    if (flows[flowId]?.description) {
      setFlowDescription(flows[flowId]?.description);
    } else {
      setFlowDescription("Description");
    }
  }, [flows[flowId]]);

  const onNodeChangeEvent = payload => {
    let foundNodeIndex;

    switch (payload.type) {
      case "position":
        if (!payload.dragging && nodeChangeEventValue.current) {
          foundNodeIndex = nodesLocal.findIndex(node => node.id === payload.id);

          if (foundNodeIndex >= 0) {
            const nodesCopy = [...nodesLocal];

            const foundNode = nodesCopy[foundNodeIndex];

            nodesCopy[foundNodeIndex] = {
              ...foundNode,
              position: nodeChangeEventValue.current
            };

            nodeChangeEventValue.current = null;

            saveFlowProgress(undefined, nodesCopy);
          }
        } else {
          nodeChangeEventValue.current = payload.position;
        }
        break;

      case "remove": {
        foundNodeIndex = nodesLocal.findIndex(node => node.id === payload.id);

        if (foundNodeIndex >= 0) {
          const nodesCopy = [...nodesLocal];

          const nodesLocalNew = nodesCopy.filter(node => node.id !== payload.id);

          saveFlowProgress(undefined, nodesLocalNew);
        }
      }

      default:
        break;
    }
  };

  return (
    <HorizontalFlowWrapper
      onPaneClick={onPaneClick}
      nodes={nodesLocal}
      edges={edgesLocal}
      onNodesChange={nodes => {
        onNodeChangeEvent(nodes[0]);
        onNodesChange(nodes);
      }}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      attributionPosition="bottom-left"
      onConnectStart={onConnectStart}
      edgeTypes={edgeTypes}
      nodeTypes={nodeTypes}
      onEdgeMouseEnter={onEdgeMouseEnter}
      onEdgeMouseLeave={() => setHoveredEdge(null)}
    >
      <Header
        onClickAddStation={onClickAddStation}
        title={flowTitle}
        description={flowDescription}
        setTitle={title => {
          setFlowTitle(title);
          saveFlowProgress(undefined, undefined, undefined, title);
        }}
        setDescription={description => {
          setFlowDescription(description);
          saveFlowProgress(undefined, undefined, undefined, undefined, description);
        }}
        isEditingTitle={isEditingTitle}
        isEditingDescription={isEditingDescription}
        setIsEditingTitle={setIsEditingTitle}
        setIsEditingDescription={setIsEditingDescription}
      />

      {newAlert && (
        <GeneralModalPreviewComponent height={"50%"} width={"50%"} onClose={() => setNewAlert(null)}>
          <NewAlertCreationScreen onCreateAlert={onCreateAlert} errorMsg={failedMetricCreatedErrorMsg} />
        </GeneralModalPreviewComponent>
      )}

      <Snackbar open={SnackbarContent} autoHideDuration={3000} onClose={onCloseAllSnackbars}>
        {SnackbarContent}
      </Snackbar>

      {isDrawerOpenWithStationData && (
        <Drawer onUpdateNode={onUpdateNode} existingNodeData={{ data: selectedNode?.data, id: selectedNode?.id }} />
      )}

      {alertsToShow.length > 0 && (
        <Drawer>
          <AlertsDrawerContent>
            <Text weight={600} size={18}>
              Metrics
            </Text>

            {alertsToShow.map((alert, index) => (
              <Text
                key={alert}
                onMouseEnter={() => setHoveringAlertIndex(index)}
                onMouseLeave={() => setHoveringAlertIndex(null)}
              >
                <DarkToolTip
                  title={
                    <TooltipContentWrapper>
                      <Text color={vantiColors.white}>Name: {alert.name}</Text>
                      <Text color={vantiColors.white}>Description: {alert.description}</Text>
                    </TooltipContentWrapper>
                  }
                  open={index === hoveringAlertIndex}
                >
                  <WrapperStyled>
                    <Text weight={600}>{index + 1}. </Text>
                    <Text>{alert.name}</Text>
                  </WrapperStyled>
                </DarkToolTip>
              </Text>
            ))}
          </AlertsDrawerContent>
        </Drawer>
      )}
    </HorizontalFlowWrapper>
  );
};

export default memo(HorizontalFlow);
