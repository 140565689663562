import React, { memo, useMemo } from "react";
import { ChatButtonActionType } from "@vanti-analytics-org/vanti-common";
import InlineButtonsDatasetChoiceContainer from "./containers/InlineButtonsDatasetChoiceContainer";
import InlineButtonsFollowupQuestionsContainer from "./containers/InlineButtonsFollowupQuestionsContainer";

const ChatMessageInlineButtonsFactory = ({ message }) => {
  const actionType = useMemo(() => {
    return message.inlineButtons[0].action?.split("/")[0];
  }, [message.inlineButtons]);

  switch (actionType) {
    case ChatButtonActionType.USE_DATASET:
      return <InlineButtonsDatasetChoiceContainer message={message} />;
    case ChatButtonActionType.CHOOSE_REPORT_FOLLOWUP_QUESTION:
      return <InlineButtonsFollowupQuestionsContainer message={message} />;
    default:
      return null;
  }
};

export default memo(ChatMessageInlineButtonsFactory);
