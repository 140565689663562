import { makeStyles } from "@material-ui/core/styles";

// TODO: Replace rgba with HEX
export default makeStyles(theme => ({
  button: {
    display: "flex",
    width: "200px",
    background: "transparent",
    padding: "10px",
    alignItems: "center",
    color: "rgba(57, 66, 83, 0.8)",
    fontWeight: 400,
    fontSize: "14px",
    border: "none",
    justifyContent: "center",
    borderBottom: "1px solid transparent",
    cursor: "pointer"
  },
  active: {
    color: theme.palette.vantiColors.blue4,
    borderColor: theme.palette.vantiColors.blue4,
    backgroundColor: "rgba(19, 17, 91, 0.05)"
  }
}));
