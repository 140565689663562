import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Popover, styled } from "@material-ui/core";
import { useQuery } from "react-query";
import dayjs from "dayjs";

import { webNotificationCenterSelector } from "modules/web-notifications/state/selectors";
import { userSelector } from "modules/user/state/selectors";
import { setUserIsReadNotificationCenterAction } from "modules/user/state/actions";
import { updateWebNotification } from "modules/web-notifications/state/actions";

import { FlexItem } from "components/ui";
import DynamicTabs from "components/ui/Tabs";
import BellNotificationIconWrapper from "components/ui/WebNotificationCenter/BellNotificationIcon/BellNotificationIconWrapper";
import AccountNotificationsActive from "components/ui/WebNotificationCenter/AccountNotificationsActive/AccountNotificationsActive";

import { AppNotificationsContext } from "common/hooks/context-hooks/use-notifications-context";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import usePermissions from "common/hooks/use-permissions";
import { useStations } from "common/hooks/use-station";

import { updateUserWebNotificationCenterReadStatus } from "services/api/user";
import { vantiColors } from "assets/jss/palette";

import useStyles from "./AccountNotificationCenter.style";

const TAB_TITLES = {
  TAB_TITLE_NOTIFICATION: "Notifications"
};

const StyledNotificationCenterPopver = styled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    width: "346px",
    minHeight: "255px",
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(0.5, 0, 0, 0),
    border: `1px solid ${vantiColors.lightGray4}`,
    "&::-webkit-scrollbar": {
      display: "none"
    },
    boxShadow: "0px 4px 15px 0px #39425326"
  }
}));

function AccountNotificationsArchived() {
  return null;
}

const AccountNotificationCenter = ({ anchorElement }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const classes = useStyles();
  const user = useSelector(userSelector);
  const stations = useStations();
  const notifications = useSelector(webNotificationCenterSelector).sort((a, b) => {
    return dayjs(b.createdAt) - dayjs(a.createdAt);
  });

  const { isNotificationCenterOpen, setIsNotificationCenterOpen } = useContext(AppNotificationsContext);
  const isGrantedToViewNotificationCenterArchived = usePermissions([ROLES_ENUM.NOTIFICATION_CENTER_ARCHIVED]);
  const [showBadge, setShowBadge] = useState(false);
  const [activeModelsInStation, setActiveModelsInStation] = useState({});
  const dispatch = useDispatch();

  const updateIsReadNotificationCenter = useQuery({
    queryFn: () => updateUserWebNotificationCenterReadStatus(),
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false
  });

  useEffect(() => {
    for (const station of Object.values(stations)) {
      if (station.activeModelId && station.activeModelId !== activeModelsInStation[station.id]) {
        setActiveModelsInStation({ ...activeModelsInStation, [station.id]: station.activeModelId });
      }
    }
  }, [activeModelsInStation, stations]);

  const updateActiveStatusForBlockNotifications = useCallback(
    (stationId, blockId) => {
      for (const n of notifications) {
        if (n.data?.stationId === stationId) {
          dispatch(updateWebNotification({ ...n, active: n.data?.blockId === blockId }));
        }
      }
    },
    [notifications]
  );

  useEffect(() => {
    for (const [stationId, blockId] of Object.entries(activeModelsInStation)) {
      updateActiveStatusForBlockNotifications(stationId, blockId);
    }
  }, [activeModelsInStation]);

  useEffect(() => {
    if (user.isReadNotificationCenter !== undefined) {
      setShowBadge(!user.isReadNotificationCenter);
    }
  }, [user.isReadNotificationCenter]);

  const handleClick = useCallback(async () => {
    setIsNotificationCenterOpen(preState => !preState);
    if (!user.isReadNotificationCenter && showBadge) {
      await updateIsReadNotificationCenter.refetch();
      dispatch(setUserIsReadNotificationCenterAction({ isReadNotificationCenter: true }));
    }
  }, [user.isReadNotificationCenter, showBadge]);

  const handleClose = useCallback(() => {
    setIsNotificationCenterOpen(false);
  }, []);

  const handleChange = useCallback((event, newValue) => {
    setSelectedTabIndex(newValue);
  }, []);

  const tabContent = useMemo(() => {
    const tabTitleValue = TAB_TITLES[Object.keys(TAB_TITLES)[selectedTabIndex]];
    switch (tabTitleValue) {
      case TAB_TITLES.TAB_TITLE_NOTIFICATION:
        return <AccountNotificationsActive activeNotificationList={notifications.filter(notif => notif.active)} />;
      case TAB_TITLES.TAB_TITLE_ARCHIVE:
        return isGrantedToViewNotificationCenterArchived ? <AccountNotificationsArchived /> : null;
      default:
        return null;
    }
  }, [selectedTabIndex, notifications]);

  return (
    <FlexItem dense="full" data-testid={"account-notification-center"}>
      <BellNotificationIconWrapper
        onClick={handleClick}
        datatest-id={"bell-notification-icon-wrapper"}
        isPopoverOpen={isNotificationCenterOpen}
        showBadge={showBadge}
      />
      <StyledNotificationCenterPopver
        datatest-id={"notification-center-popver"}
        open={isNotificationCenterOpen}
        anchorEl={anchorElement.current}
        onClose={handleClose}
        TransitionProps={{ onExited: () => setSelectedTabIndex(0) }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <DynamicTabs
          datatest-id={"notification-center-tabs"}
          tabProps={{ classes: { root: classes.tabRoot } }}
          tabsProps={{ classes: { indicator: classes.tabsIndicator, flexContainer: classes.tabsFlexContainer } }}
          tabs={Object.values(TAB_TITLES)}
          selectedTabIndex={selectedTabIndex}
          handleChange={handleChange}
        />
        <Divider />
        <FlexItem container dense={"full"}>
          {tabContent}
        </FlexItem>
      </StyledNotificationCenterPopver>
    </FlexItem>
  );
};

export default memo(AccountNotificationCenter);
