import React, { memo, useEffect, useState } from "react";
import Spacer from "components/ui/Spacer";
import ActivityCard from "./ActivityCard";
import { avatarsColorPalette, vantiColors } from "assets/jss/palette";
import { FlexRow } from "components/ui/Flex/FlexContainer";
import { styled, TextField } from "@material-ui/core";
import { VantiScrollBarProps } from "components/ui/VantiScrollBarProps";
import ChatSendArrowIcon24 from "assets/icons/chat-icon/ChatSendArrowIcon24";
import useDataEntityRequest from "common/hooks/use-data-entity-request";

const ACTIVITY_SECTION_INPUT_PLACEHOLDER_TEXT = "Add a comment, use the table name to link it";

const ActivitySection = ({ entity }) => {
  const [newActivityInput, setNewActivityInput] = useState(null);
  const [avatarColorToUserMap, setAvatarColorToUserMap] = useState({});
  const { addActivityRequest } = useDataEntityRequest();

  useEffect(() => {
    setAvatarColorToUserMap(() => {
      const tempMap = {};
      if (!entity?.activity) return tempMap;
      const activityUsersSet = [...new Set(entity?.activity.map(a => a.user.name))];
      for (let i = 0; i < activityUsersSet.length; i++) {
        tempMap[activityUsersSet[i]] = avatarsColorPalette.reverse()[i % avatarsColorPalette.length];
      }
      return tempMap;
    });
  }, [entity?.activity]);

  const onClickAddActivity = () => {
    if (!newActivityInput && !newActivityInput.length) {
      return;
    }

    addActivityRequest.mutate({ dataEntityId: entity._id, text: newActivityInput });
    setNewActivityInput("");
  };

  return (
    <>
      <ActivityList>
        {entity?.activity?.map((activity, i) => (
          <ActivityCard
            activity={activity}
            avatarBgColor={avatarColorToUserMap[activity.user.name]}
            key={`data-entity-activity-${activity._id}`}
          />
        ))}
      </ActivityList>
      <Spacer space={8} />
      <FlexRow style={{ height: "unset", flex: "0", position: "relative" }}>
        <ActivitySectionInput
          placeholder={ACTIVITY_SECTION_INPUT_PLACEHOLDER_TEXT}
          type={"text"}
          value={newActivityInput}
          multiline
          maxRows={4}
          variant={"outlined"}
          onChange={event => setNewActivityInput(event.target.value)}
          onKeyDown={e => {
            if (!e.shiftKey && e.key === "Enter") {
              onClickAddActivity();
              e.preventDefault();
            }
          }}
        />
        <ChatSendArrowIcon24Styled
          onClick={onClickAddActivity}
          stroke={newActivityInput?.length ? vantiColors.darkBlue7 : vantiColors.gray33}
          isEnabled={true}
        />
      </FlexRow>
    </>
  );
};

export const ActivitySectionInput = styled(TextField)({
  width: "100%",
  minHeight: 32,
  boxSizing: "border-box",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "normal",
  color: vantiColors.gray29,
  "&::placeholder": {
    color: vantiColors.lightGray16
  },
  "& .MuiInputBase-root": {
    paddingRight: 50
  },
  "& .MuiInputBase-input": { ...VantiScrollBarProps },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${vantiColors.gray36}`
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${vantiColors.gray36}`
  }
});

const ActivityList = styled("div")({
  width: "100%",
  flex: 1,
  overflowY: "auto",
  boxSizing: "border-box",
  border: `1px solid ${vantiColors.gray36}`,
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
  gap: 4,
  padding: 8,
  ...VantiScrollBarProps
});

const ChatSendArrowIcon24Styled = styled(ChatSendArrowIcon24)(({ isEnabled }) => ({
  position: "absolute",
  bottom: 16,
  right: 16,
  padding: 2,
  cursor: isEnabled ? "pointer" : "not-allowed"
}));

export default memo(ActivitySection);
