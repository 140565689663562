import config from "common/config";
import http from "../http";
import { parseRawBlock } from "modules/station/query";
import { emitStationIdToDelete } from "services/sockets/notification-socket";
import { downloadAndPlugInDynamicPlot } from "utils/utils";

// Server
const stationsApi = `${config.serverUrl}/api/v2/stations`;
const accountsApi = `${config.serverUrl}/api/v2/accounts`;

// API
/**
 *
 */
export async function createStation(name, description, goal, type, products, lines) {
  const res = await http.post(`${stationsApi}`, { name, description, goal, type, products, lines });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function getAllAccountStations() {
  const res = await http.get(stationsApi);
  const result = await res.json();

  if (res.status > 200 || !result?.ok) {
    // HTTP error
    throw new Error(result.message);
  }

  return result.data;
}

export async function getStation(stationId) {
  const res = await http.get(`${stationsApi}/${stationId}`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function getStationTypes() {
  const res = await http.get(`${stationsApi}/stationTypes`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.stationTypes) {
    throw new Error("Didn't get types from server");
  }
  return result.stationTypes;
}

export async function getStationByBlockId(blockId) {
  const res = await http.get(`${stationsApi}/${blockId}/station-by-block`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function updateStation(stationId, name) {
  const res = await http.patch(`${stationsApi}/${stationId}`, { name });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function updateStationType(stationId, modelType) {
  const res = await http.patch(`${stationsApi}/${stationId}/type`, { modelType });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function updateStationBlockStatus(stationId, blockState) {
  const res = await http.patch(`${stationsApi}/${stationId}/update-state`, { blockState });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function deleteStation(stationId) {
  const res = await http.delete(`${stationsApi}/${stationId}`, {});
  const result = await res.json();
  emitStationIdToDelete(stationId);
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result;
}

export async function getAllStationBlocks(stationId, loadDynamicPlots) {
  const res = await http.get(`${stationsApi}/${stationId}/blocks`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  const blocks = result.data.map(block => parseRawBlock(stationId, block));
  if (loadDynamicPlots) {
    for (const block of blocks) {
      await downloadAndPlugInDynamicPlot(block);
    }
  }
  return blocks;
}

export async function updateStationApiStatus(stationId, newStatus) {
  const res = await http.post(`${stationsApi}/${stationId}/api-integration`, {
    apiEnabled: newStatus
  });

  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function addProductsToStation(accountId, stationId, productName) {
  const res = await http.post(`${accountsApi}/${accountId}/products`, { productName, stationId });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function editStationProducts(accountId, product) {
  const res = await http.patch(`${accountsApi}/${accountId}/products/${product._id}`, { productName: product.name });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function assignStationProducts(isAssigned, accountId, stationId, product) {
  const res = await http.patch(`${accountsApi}/${accountId}/products/${product._id}/assign`, {
    product,
    stationId,
    isAssigned
  });

  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function assignStationLines(isAssigned, accountId, stationId, line) {
  const res = await http.patch(`${accountsApi}/${accountId}/lines/${line._id}/assign`, {
    line,
    stationId,
    isAssigned
  });

  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function deleteStationProduct(accountId, productId) {
  const res = await http.delete(`${accountsApi}/${accountId}/products/${productId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function deleteStationLine(accountId, lineId) {
  const res = await http.delete(`${accountsApi}/${accountId}/lines/${lineId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function addLineToStations(accountId, stationIdList, lineName) {
  const res = await http.post(`${accountsApi}/${accountId}/lines`, { lineName, stationIdList });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function editStationLines(accountId, line, stationIdList) {
  const res = await http.patch(`${accountsApi}/${accountId}/lines/${line._id}`, { lineName: line.name, stationIdList });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function dismissStation(stationId) {
  await http.patch(`${stationsApi}/${stationId}/dismiss-blocks`);
}
