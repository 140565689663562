import React, { memo } from "react";

const BellNotificationIcon = ({
  width = "24",
  height = "24",
  viewBox = "0 0 24 24",
  fill = "none",
  bellStroke = "#091733",
  ...rest
}) => {
  return (
    <svg {...rest} width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.0402 20.236C15.6498 17.7429 17.5267 12.5071 17.343 9.44675C17.2126 7.27393 15.5701 6.16754 14.5589 5.68896C14.1011 5.47227 13.7728 5.38429 13.7728 5.38429M17.0402 20.236L12.2106 18.9419M17.0402 20.236L18.0061 20.4948M13.7728 5.38429L14.3295 3.3067M13.7728 5.38429C13.7728 5.38429 13.4444 5.2963 12.9396 5.25505C11.8246 5.16396 9.849 5.30087 8.64964 7.11738C6.96039 9.67587 5.96787 15.1487 3.51723 16.6126M12.2106 18.9419L10.2787 18.4242L8.34688 17.9066M12.2106 18.9419C11.8863 20.152 11.1196 21.0816 9.67071 20.6934C8.22182 20.3052 8.02262 19.1167 8.34688 17.9066M3.51723 16.6126L8.34688 17.9066M3.51723 16.6126L2.55131 16.3537"
        stroke={bellStroke}
      />
    </svg>
  );
};

export default memo(BellNotificationIcon);
