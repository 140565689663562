import React from "react";
import App from "App";
import MobileRestrict from "components/ui/MobileRestrict/MobileRestrict";
import { isUserAgentMobile, shouldSkipMobileRestriction } from "utils/app-utils";

function AppLoader() {
  if (isUserAgentMobile() && !shouldSkipMobileRestriction()) {
    return <MobileRestrict />;
  }

  return <App />;
}

export default AppLoader;
