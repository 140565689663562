import http from "../http";
import config from "common/config";
import { downloadAndPlugInDynamicPlot } from "../../utils/utils";
import { parseRawBlock } from "modules/station/query";
import { readUrl } from "utils/user-data-reader";

// Server
const blocksApi = `${config.serverUrl}/api/v2/blocks`;
const stationsApi = `${config.serverUrl}/api/v2/stations`;

// API
/**
 * @param {string} title
 * @returns {Promise<Object>}
 */
export async function createBlock(stationId, title, description) {
  const res = await http.post(`${stationsApi}/${stationId}/blocks`, { title, description });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @returns {Promise<any>}
 */
export async function getBlock(blockId) {
  const res = await http.get(`${blocksApi}/${blockId}`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  const block = parseRawBlock(blockId, result.data);
  return await downloadAndPlugInDynamicPlot(block);
}

/**
 * @param {string} blockId
 * @returns {Promise<Block>}
 */
export async function deleteBlock(blockId) {
  const res = await http.delete(`${blocksApi}/${blockId}`, {});
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @param {string} blockId
 * @returns {Promise<Block>}
 */
export async function deactivateBlock(blockId) {
  const res = await http.patch(`${blocksApi}/${blockId}/deactivate`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @param {string} blockId
 * @param {string?} title
 * @param {string?} description
 * @returns {Promise<Object>}
 */
export async function updateBlock(blockId, { title, description }) {
  const res = await http.patch(`${blocksApi}/${blockId}`, {
    title,
    description
  });

  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @param {string} blockId
 * @param {string?} modelIntent
 * @returns {Promise<Object>}
 */
export async function updateBlockMetadata(blockId, modelIntent) {
  const res = await http.patch(`${blocksApi}/${blockId}/metadata`, {
    modelIntent
  });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @param {string} blockId
 * @param {string?} modelConf
 * @returns {Promise<Object>}
 */
export async function updateBlockModelConf(blockId, reqBody) {
  const modelConf = {
    ...reqBody,
    codeContent: null,
    code: reqBody.codeContent ? btoa(reqBody.codeContent) : null
  };

  const res = await http.patch(`${blocksApi}/${blockId}/model-conf`, { modelConf });

  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @param {string} blockId
 * @param {File} file
 * @returns {{location: string, name: string}}
 */
export function getBlockFileLocation(userId, blockId) {
  if (!userId || !blockId) {
    return null;
  }
  return `users/${userId}/blocks/${blockId}/files/`;
}

/**
 * @param {string} blockId
 * @param {string} url
 * @param {string} location
 * @param {string} updatedName
 * @returns {Promise<Object>}
 */
export async function processBatch(batchType, blockId, userId, updatedName, originalFileName, feedbackRowsCount) {
  const location = getBlockFileLocation(userId, blockId);
  const res = await http.post(`${blocksApi}/${blockId}/batch-${batchType.toLowerCase()}`, {
    location,
    url: config.env,
    name: updatedName,
    originalFileName,
    feedbackRowsCount
  });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
}

/**
 * @param {string} blockId
 * @param {string} url
 * @param {string} location
 * @param {string} name
 * @param {number} kpiIndex
 * @param {number} sheetIndex
 * @returns {Promise<Object>}
 */
export async function setFile(blockId, location, file) {
  const numOfClasses = Object.keys(file.kpiLabels || []).length;
  let numOfTargets = 0;
  if (file.kpiIndexes.length > 0) {
    numOfTargets = file.kpiIndexes.length;
  } else if (numOfClasses) {
    numOfTargets = 1;
  }

  const kpiLabels = (file.kpiLabels || []).reduce((acc, item) => {
    if (item.value) {
      acc.push({
        [item.label]: item.value
      });
    }

    return acc;
  }, []);

  const body = {
    url: config.env,
    location,
    name: file.originalFile ? file.originalFile.name || file.originalFile.path : file.path,
    kpiIndexes: file.kpiIndexes,
    columnIdentifierIndexes: file.columnIdentifierIndexes.length > 0 ? file.columnIdentifierIndexes : [-1],
    timeSeries: {
      indexes: [-1]
    },
    timeSeriesIndexes: [-1],
    seriesIdIndexes: file.seriesIdIndexes.length > 0 ? file.seriesIdIndexes : [-1],
    kpiLabels: kpiLabels,
    sheetIndex: file.sheetIndex || -1,
    clientValidationError: file.clientValidationError || null,
    sheets: file.data
      ? {
          headers: file.data.headerColumns,
          data: []
        }
      : null,
    numOfClasses,
    numOfTargets,
    fileType: file.type,
    dataSource: file.dataSource
  };

  if (file.timeSeriesIndexes.length > 0) {
    body.timeSeries = {
      indexes: file.timeSeriesIndexes
    };

    if (file.data) {
      const timeSeriesColumnKeys = file.timeSeriesIndexes.map(item => file.data.headerColumns[item]);
      body.timeSeries.metadata = timeSeriesColumnKeys.map(item => file.data.uniqueDataset[item]);
    }
  }

  const res = await http.patch(`${blocksApi}/${blockId}/set-file`, body);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @param {string} blockId
 * @param {number} kpiIndex
 * @param {number} sheetIndex
 * @returns {Promise<Object>}
 */
export async function updateFile(blockId, kpiIndex, sheetIndex) {
  const res = await http.patch(`${blocksApi}/${blockId}/update-file`, {
    kpiIndex,
    sheetIndex
  });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @param {string} blockId
 * @returns {Promise<Object>}
 */
export async function submitBlock(blockId, vantiMlVersion = null) {
  const res = await http.patch(`${blocksApi}/${blockId}/submit`, {}, { "X-VANTI-ML-VERSION": vantiMlVersion });
  const result = await res.json();
  if (res.status > 200 || !result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @param {string} blockId
 * @returns {Promise<Object>}
 */
export async function getExistingModelConfig(blockId) {
  const res = await http.get(`${blocksApi}/${blockId}/existing-config`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

/**
 * @param {string} blockId
 * @param {string} refBlockId
 * @param {object} updatedValues
 * @returns {Promise<Object>}
 */
export async function updateFileValues(blockId, updatedValues) {
  const res = await http.patch(`${blocksApi}/${blockId}/update-file-values`, {
    updatedValues
  });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function getSignedUrl(blockId, imageName) {
  const res = await http.get(`${blocksApi}/${blockId}/dataset-image/${encodeURIComponent(imageName)}`);
  const resultToJson = await res.json();

  if (res.status > 200) {
    throw new Error(resultToJson.message);
  }
  if (!resultToJson.ok) {
    throw new Error(resultToJson.message);
  }

  return resultToJson.url;
}

export async function getImagesSignedUrl(blockId, predictions) {
  const res = await http.post(`${blocksApi}/${blockId}/dataset-image`, {
    predictions
  });
  const resultToJson = await res.json();

  if (res.status > 200) {
    throw new Error(resultToJson.message);
  }
  if (!resultToJson.ok) {
    throw new Error(resultToJson.message);
  }

  return resultToJson.predictions;
}

export async function getDriftImage(predictionId, filename) {
  const res = await http.get(`${blocksApi}/presign-drift-image?predictionId=${predictionId}&filename=${filename}`);
  const resultToJson = await res.json();

  if (res.status > 200) {
    throw new Error(resultToJson.message);
  }
  if (!resultToJson.ok) {
    throw new Error(resultToJson.message);
  }

  return resultToJson.data;
}

export async function getPredictionImage(blockId, imageName, predictionId) {
  const res = await http.get(
    `${blocksApi}/${blockId}/${predictionId}/prediction-image/${encodeURIComponent(imageName)}`
  );
  const resultToJson = await res.json();

  if (res.status > 200) {
    throw new Error(resultToJson.message);
  }
  if (!resultToJson.ok) {
    throw new Error(resultToJson.message);
  }

  return resultToJson.url;
}

export async function getImagesSignedUrls(blockId, ...imageNames) {
  const res = await http.get(`${blocksApi}/${blockId}/report/images/${imageNames}`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data.urls;
}

export async function activateBlock(blockId) {
  const res = await http.patch(`${blocksApi}/${blockId}/activate`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function activateBlockFailure(blockId) {
  const res = await http.patch(`${blocksApi}/${blockId}/activateFailure`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 *
 * @param {{url: string, name: string}} file
 * @param {number} lineCount
 * @returns {{sheets: Array<Object>}}
 */
export function getFileSnippet(file, lineCount = 5) {
  return new Promise((resolve, reject) => {
    readUrl(file, lineCount, (error, results) => {
      if (error) {
        reject(error);
      } else {
        resolve(results);
      }
    });
  });
}

export async function getS3ReportPresignedUrl(blockId) {
  const fileUrl = `${blocksApi}/${blockId}/s3-report-presigned-url`;
  const preSignedUrlRes = await http.get(fileUrl);
  const preSignedUrlResult = await preSignedUrlRes.json();
  return preSignedUrlResult.data.url;
}

export async function getS3ValidationDatasetPresignedUrl(blockId) {
  const fileUrl = `${blocksApi}/${blockId}/s3-validation-dataset-presigned-url`;
  const preSignedUrlRes = await http.get(fileUrl);
  const preSignedUrlResult = await preSignedUrlRes.json();
  return preSignedUrlResult.data.url;
}

export async function getS3DatasetPresignedUrl(blockId) {
  const fileUrl = `${blocksApi}/${blockId}/s3-dataset-presigned-url`;
  const preSignedUrlRes = await http.get(fileUrl);
  const preSignedUrlResult = await preSignedUrlRes.json();
  return preSignedUrlResult.data.url;
}

/**
 *
 * @param {object} data
 * @returns {Promise<string>}
 */
export async function getS3PredictionPresignedUrl(data) {
  const fileUrl = `${blocksApi}/s3-prediction-presigned-url`;

  const preSignedUrlRes = await http.post(fileUrl, data);
  const preSignedUrlResult = await preSignedUrlRes.json();

  return preSignedUrlResult.data.url;
}

export async function getLiveReportData(blockId, timeFrame) {
  const res = await http.get(`${blocksApi}/${blockId}/report/live-data`, { timeFrame });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  return result;
}

export async function createDraftForExistingBlock(blockId) {
  const res = await http.post(`${blocksApi}/${blockId}/create-draft-for-existing`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  return result.data;
}

export async function getBlockConfig(blockId) {
  const res = await http.get(`${blocksApi}/${blockId}/block-config`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  return result;
}

export async function generateBlockBatchConfig(
  batchType,
  blockId,
  userId,
  updatedName,
  originalFileName,
  feedbackRowsCount,
  batchConfig
) {
  const location = getBlockFileLocation(userId, blockId);
  const res = await http.post(`${blocksApi}/${blockId}/batch-config-${batchType.toLowerCase()}`, {
    location,
    url: config.env,
    name: updatedName,
    originalFileName,
    feedbackRowsCount,
    batchConfig
  });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result;
}

export async function getS3PreviewPresignedUrl(blockId) {
  const fileUrl = `${blocksApi}/${blockId}/s3-preview-presigned-url`;

  const preSignedUrlRes = await http.get(fileUrl);

  const preSignedUrlResult = await preSignedUrlRes.json();

  return preSignedUrlResult.data.url;
}

export async function dismissBlockError(blockId) {
  const res = await http.post(`${blocksApi}/${blockId}/dismiss-error`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  return result;
}

export async function setBlockClusterLabel(blockId, clusterIndex, clusterLabel) {
  const res = await http.post(`${blocksApi}/${blockId}/set-cluster-label`, {
    clusterIndex,
    clusterLabel
  });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }

  return result;
}

export async function getInsightsPerLines() {
  const res = await http.get(`${blocksApi}/insights-per-lines`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function getCompareImages(blockId) {
  const res = await http.get(`${blocksApi}/${blockId}/compare-images`);

  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}
