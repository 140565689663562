import React from "react";
import Text from "./Text";
import makeStyles from "@material-ui/styles/makeStyles";

const styles = {
  header: {
    display: "flex"
  }
};

const useStyles = makeStyles(styles);

export default function BrandedTitle({ text }) {
  const classes = useStyles();

  return (
    <div data-testid="title-section" className={classes.header}>
      <Text data-testid={`${text}-headline-text`} size="28px" h={2}>
        {text}
      </Text>
    </div>
  );
}
