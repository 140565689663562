import React from "react";

const ArrowUpIcon = ({ ...rest }) => (
  <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00063 5.17989L0.590476 0.769729L0.00122048 1.35898L5.00063 6.3584L10 1.35898L9.41079 0.769728L5.00063 5.17989Z"
      fill="#394253"
    />
  </svg>
);

export default ArrowUpIcon;
