export const dataCurationsFlagTypes = {
  START_FETCH: "data.curations.start.fetch",
  ADD_CURATION: "data.curations.add.curation",
  EDIT_CURATION: "data.curation.edit.curation",
  REMOVE_CURATION: "data.curation.delete.curation",

  SET_LOADING: "data.curation.set.loading",
  SET_SUCCESS: "data.curation.set.success",
  SET_ERROR: "data.curation.set.error",

  CLEAR_LOADING: "data.curation.clear.loading",
  CLEAR_SUCCESS: "data.curation.clear.success",
  CLEAR_ERROR: "data.curation.clear.error"
};
