import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@material-ui/core";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import SemanticInfoUserActionSection from "./SemanticInfoUserActionSection";
import { FETCH_CHAT_CONTEXT_API_KEY } from "services/api/chat-context";
import { useDispatch } from "react-redux";
import { navigationSetBreadcrumbsType } from "modules/navigation/actions";
import { BREADCRUMBS_TYPES } from "common/constants/NavigationConstants";
import { useMutation } from "react-query";
import { logger } from "utils/logger";
import { DarkToolTip } from "components/ui/tooltips/tooltip";

const SemanticInfoSectionContainer = styled("div")(({ stretch, containerGap = 4 }) => ({
  width: "100%",
  height: stretch ? "100%" : "auto",
  flexGrow: 1,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: containerGap
}));

export const SemanticInfoTextArea = styled("textarea")(({ resize = "none" }) => ({
  width: "100%",
  height: "100%",
  minHeight: "inherit",
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: 16,
  border: "none",
  padding: 12,
  paddingRight: "6%",
  color: vantiColors.gray40,
  lineHeight: "19.36px",
  boxSizing: "border-box",
  borderRadius: 10,
  resize,
  "&::-webkit-scrollbar": {
    width: "6px",
    backgroundColor: "transparent"
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: vantiColors.lightGray1,
    borderRadius: "40px"
  },
  "&:disabled": {
    backgroundColor: "transparent",
    cursor: "not-allowed"
  }
}));

const SemanticInfoTextAreaWrapper = styled("div")(({ stretch }) => ({
  width: "100%",
  height: stretch ? "100%" : "auto",
  minHeight: 68,
  display: "flex",
  flexDirection: "column",
  border: `1px solid ${vantiColors.gray36}`,
  borderRadius: "5px",
  backgroundColor: "white",
  boxSizing: "border-box",
  position: "relative"
}));

const SemanticInfoSectionHeader = styled("div")({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center"
});

const CharacterCountText = styled(Text)({
  alignSelf: "flex-end",
  position: "absolute",
  right: 12,
  top: 12
});

const SemanticInfoSection = ({
  contextType,
  relatedEntityId,
  onSave,
  onSaveRequiresConfirmation = true,
  title,
  maxInputChars,
  Icon,
  getDataCallback,
  isAllowedToEdit,
  tooltipText = "",
  setUnsavedData,
  resize,
  containerGap,
  stretch = true
}) => {
  const dispatch = useDispatch();

  const [text, setText] = useState("");
  const [editedText, setEditedText] = useState(text);
  const [hasTextChanged, setHasTextChanged] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const chatContextReuqest = useMutation(
    FETCH_CHAT_CONTEXT_API_KEY,
    variables => getDataCallback(variables.relatedEntityId, variables.contextType),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess: data => {
        setText(data);
        setEditedText(data);
        setDataFetched(true);
      },
      onError: (error, variables) => logger.error("Failed to get chat context by id", { error, variables })
    }
  );

  useEffect(async () => {
    dispatch(navigationSetBreadcrumbsType(BREADCRUMBS_TYPES.NONE));
  }, []);

  useEffect(async () => {
    chatContextReuqest.mutate({ relatedEntityId, contextType });
  }, [relatedEntityId, contextType]);

  const charCountText = useMemo(() => {
    return `${editedText?.length ?? 0}/${maxInputChars}`;
  }, [editedText, maxInputChars]);

  useEffect(() => {
    setHasTextChanged(text !== editedText);
  }, [text, editedText]);

  const onSaveHandler = useCallback(() => {
    //don't update if context wasn't changed
    if (hasTextChanged && isAllowedToEdit) {
      onSave(editedText);
      setText(editedText);
    }
  }, [hasTextChanged, onSave, editedText, isAllowedToEdit]);

  useEffect(() => {
    if (setUnsavedData) {
      setUnsavedData(dataFetched && hasTextChanged ? { relatedEntityId, contextType, editedText } : null);
    }
  }, [dataFetched, hasTextChanged, relatedEntityId, contextType, editedText]);

  const showTooltip = useMemo(() => isHovered && !isAllowedToEdit, [isHovered, isAllowedToEdit]);

  return (
    <SemanticInfoSectionContainer stretch={stretch} containerGap={containerGap}>
      <SemanticInfoSectionHeader>
        {Icon && <Icon />}
        <Text size={16}>{title}</Text>
      </SemanticInfoSectionHeader>
      <SemanticInfoTextAreaWrapper
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        stretch={stretch}
      >
        {isAllowedToEdit && (
          <CharacterCountText color={vantiColors.gray41} size={12} lineSize={"14.52px"}>
            {charCountText}
          </CharacterCountText>
        )}
        <DarkToolTip arrow open={!!showTooltip} placement={"top"} title={tooltipText}>
          <SemanticInfoTextArea
            wrap={"hard"}
            autoComplete={"on"}
            value={editedText}
            onChange={e => setEditedText(e.target.value)}
            maxLength={maxInputChars}
            disabled={!isAllowedToEdit}
            resize={resize}
          />
        </DarkToolTip>
      </SemanticInfoTextAreaWrapper>
      <SemanticInfoUserActionSection
        onSave={onSaveHandler}
        onSaveRequiresConfirmation={onSaveRequiresConfirmation}
        hasTextChanged={hasTextChanged}
      />
    </SemanticInfoSectionContainer>
  );
};

export default memo(SemanticInfoSection);
