import React, { memo, useMemo, useState } from "react";
import { IconButton, styled, TableCell, TableRow } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import { ReactComponent as CopyIcon } from "assets/icons/CopyIconCurl.svg";
import { ReactComponent as Trash } from "assets/img/trash-icon.svg";
import { APP_MAIN_BACKGROUND_COLOR, vantiColors } from "assets/jss/palette";
import { FlexContainer, FlexItem, Text } from "components/ui";
import EllipsisText from "components/ui/EllipsisText/EllipsisText";
import { addCurationToState } from "modules/data-curations/actions";
import {
  API_QUERIES,
  getFormattedDateForDisplay,
  getFormattedTimeForDisplay
} from "pages/side-bar/data-curation/utils";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { duplicateCuration } from "services/api/dataCuration";
import styles from "../../../styles";
import DeleteCurationDialog from "./DeleteCurationDialog";

const TableRowStyled = styled(TableRow)(({ theme, cursor }) => ({
  borderBottom: `1px solid ${APP_MAIN_BACKGROUND_COLOR}`,
  "&:last-child td, &:last-child th": {
    border: 0
  },
  "&:hover": {
    backgroundColor: theme.palette.vantiColors.lightBlue10
  },
  cursor
}));

const IconButtonsWrapperStyled = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  columnGap: 12
});

const IconButtonStyled = styled(IconButton)({
  backgroundColor: vantiColors.gray36,
  padding: 8
});

const TableCellStyled = styled(TableCell)({
  borderBottom: "none",
  padding: "0px 16px",
  height: 84,
  boxSizing: "border-box"
});

const DataCurationTableBody = ({ rows }) => {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);

  const duplicateCurationReq = useQuery(
    API_QUERIES.keys.duplicateCuration,
    () => duplicateCuration(rows[currentRowIndex]._id),
    {
      manual: true,
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false
    }
  );

  const actions = useMemo(
    () => [
      {
        id: "duplicate-icon",
        Icon: CopyIcon,
        onClick: async () => {
          const response = await duplicateCurationReq.refetch();
          if (response.isSuccess) {
            dispatch(addCurationToState(response.data.data));
            history.push(`/dashboard/data-curation/${response.data.data._id}`);
          }
        }
      },
      {
        id: "trash-icon",
        Icon: Trash,
        onClick: () => {
          setIsShowDeleteDialog(true);
        }
      }
    ],
    [currentRowIndex]
  );

  return (
    <TableBody>
      {rows.map((row, rowIndex) => (
        <TableRowStyled
          onMouseEnter={() => {
            if (rows[rowIndex]._id !== "mock") {
              setHoveredRowIndex(rowIndex);
              setCurrentRowIndex(rowIndex);
            }
          }}
          onMouseLeave={() => {
            setHoveredRowIndex(null);
          }}
          key={row.title}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          style={{ cursor: "pointer" }}
          className={classes.tableRow}
          data-testid={`data-curation-table-row-index-${rowIndex}`}
          onClick={() => {
            if (rows[rowIndex]._id !== "mock") {
              history.push(`/dashboard/data-curation/${rows[rowIndex]._id}`);
            }
          }}
        >
          <TableCellStyled
            data-testid={`row-index-${rowIndex}-cell-value-${row.name}`}
            className={`${classes.customTableCell} ${classes.thirdWidth}`}
          >
            <EllipsisText tooltipText={row.name} size={16}>
              {row.name}
            </EllipsisText>
          </TableCellStyled>

          <TableCellStyled
            data-testid={`row-index-${rowIndex}-cell-value-${row.sourceFormats?.map(sourceFormat => sourceFormat)}`}
            className={`${classes.customTableCell} ${classes.thirdWidth}`}
          >
            <Text size={16}>{row.sourceFormats?.join(", ")}</Text>
          </TableCellStyled>

          <TableCellStyled
            data-testid={`row-index-${rowIndex}-cell-value-${row.lastExecutionBy}-${row.lastExecutionAt}`}
            className={`${classes.customTableCell} ${classes.thirdWidth}`}
          >
            <FlexContainer direction="column">
              <FlexItem dense="full">
                <Text size={16}>{row.lastExecutionBy}</Text>
              </FlexItem>

              <FlexItem dense="full">
                <Text size={12}>
                  {row.lastExecutionAt
                    ? `${getFormattedTimeForDisplay(new Date(row.lastExecutionAt))} |
                      ${getFormattedDateForDisplay(new Date(row.lastExecutionAt))}`
                    : "-"}
                </Text>
              </FlexItem>

              {row.lastExecutionType && (
                <FlexItem dense="full">
                  <Text size={12}>{row.lastExecutionType}</Text>
                </FlexItem>
              )}
            </FlexContainer>
          </TableCellStyled>

          <TableCellStyled style={{ width: "10%" }}></TableCellStyled>

          <TableCellStyled align="right">
            {rowIndex === hoveredRowIndex && (
              <FlexItem dense="full" style={{ position: "relative" }}>
                <IconButtonsWrapperStyled>
                  {actions.map(({ Icon, onClick, id }) => (
                    <IconButtonStyled
                      data-testid={id}
                      onClick={e => {
                        e.stopPropagation();
                        onClick();
                      }}
                      key={id}
                    >
                      <Icon />
                    </IconButtonStyled>
                  ))}
                </IconButtonsWrapperStyled>
              </FlexItem>
            )}
          </TableCellStyled>
        </TableRowStyled>
      ))}

      {isShowDeleteDialog && (
        <DeleteCurationDialog
          clickedRowIndex={currentRowIndex}
          isShowDeleteDialog={isShowDeleteDialog}
          setIsShowDeleteDialog={setIsShowDeleteDialog}
          currentCuration={rows[currentRowIndex] || {}}
        />
      )}
    </TableBody>
  );
};

export default memo(DataCurationTableBody);
