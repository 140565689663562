import React, { memo } from "react";
import Header from "./components/Header";
import DataCurationTable from "./components/DataCurationTable";
import { FlexItem } from "components/ui";
import { styled } from "@material-ui/core";

const DataCurationsListWrapperStyled = styled(FlexItem)({
  marginBottom: 128,
  minHeight: "100%"
});

const DataCurationsListStyled = styled(FlexItem)({
  rowGap: 16,
  alignItems: "flex-start",
  flexFlow: "column"
});

const DataCurationsTableStyled = styled(DataCurationTable)({
  flex: 1,
  justifyContent: "flex-start",
  flexFlow: "column"
});

const DataCurationsList = () => {
  return (
    <DataCurationsListWrapperStyled container dense="full" data-testid={`data-curation-home-page`}>
      <DataCurationsListStyled dense="full" container>
        <Header />
        <DataCurationsTableStyled />
      </DataCurationsListStyled>
    </DataCurationsListWrapperStyled>
  );
};

export default memo(DataCurationsList);
