import React, { createContext, memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import DataConnectorsList from "./pages/data-connectors-list/DataConnectorsList";
import CreateSingleDataConnector from "./pages/single-data-connector/create-connector/CreateSingleDataConnector";
import EditConnectorWrapper from "./pages/single-data-connector/edit-connector/EditConnectorWrapper";
import { allDataConnectorsSelector, isLoadingConnectorsSelector } from "modules/data-connectors/selectors";
import { dataConnectorsStartFetch } from "modules/data-connectors/actions";
import { navigationSetBreadcrumbsType } from "modules/navigation/actions";
import { getAllSupportedDataSources } from "services/api/dataConnectors";
import { BREADCRUMBS_TYPES } from "common/constants/NavigationConstants";
import { styled } from "@material-ui/core";

export const DataConnectorsContext = createContext();

export const DataConnectorsRouterWrapper = styled("div")(({ theme }) => ({
  justifyContent: "center",
  overflowY: "auto",
  position: "relative",
  margin: "16px",
  height: "100%"
}));

const DataConnectorsPage = () => {
  const dispatch = useDispatch();

  const dataConnectors = useSelector(allDataConnectorsSelector);
  const dataConnectorLoadingSelector = useSelector(isLoadingConnectorsSelector);
  const [dataSources, setDataSources] = useState({});

  useEffect(async () => {
    dispatch(navigationSetBreadcrumbsType(BREADCRUMBS_TYPES.DATA_CONNECTORS));
    dispatch(dataConnectorsStartFetch());
    const dataSourcesResponse = await getAllSupportedDataSources();
    setDataSources(dataSourcesResponse);
  }, []);

  const contextObject = useMemo(() => {
    return {
      dataConnectors,
      dataSources,
      loading: dataConnectorLoadingSelector
    };
  }, [dataConnectors, dataSources, dataConnectorLoadingSelector]);

  return (
    <DataConnectorsRouterWrapper container dense="full" data-testid={"data-connectors-router-wrapper"}>
      <DataConnectorsContext.Provider value={contextObject}>
        <Switch>
          <Route path="/dashboard/data-connectors/create" component={CreateSingleDataConnector} />
          <Route path="/dashboard/data-connectors/:connectorId" component={EditConnectorWrapper} />
          <Route path="/" component={DataConnectorsList} />
        </Switch>
      </DataConnectorsContext.Provider>
    </DataConnectorsRouterWrapper>
  );
};

export default memo(DataConnectorsPage);
