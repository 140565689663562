import { vantiColors } from "assets/jss/palette";
import React, { memo } from "react";

const SuccessIcon16 = ({ ...rest }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M6.13215 7.66563L5.79766 7.29398L5.05437 7.96294L5.38885 8.33459L6.13215 7.66563ZM7.4885 9.92011L7.11685 10.2546L7.4609 10.6369L7.83333 10.2822L7.4885 9.92011ZM5.38885 8.33459L7.11685 10.2546L7.86015 9.58563L6.13215 7.66563L5.38885 8.33459ZM7.83333 10.2822L11.8653 6.44218L11.1757 5.71804L7.14367 9.55804L7.83333 10.2822Z"
      fill={vantiColors.green4}
    />
    <path
      d="M15.5 8C15.5 8.98491 15.306 9.96018 14.9291 10.8701C14.5522 11.7801 13.9997 12.6069 13.3033 13.3033C12.6069 13.9997 11.7801 14.5522 10.8701 14.9291C9.96018 15.306 8.98491 15.5 8 15.5C7.01509 15.5 6.03982 15.306 5.12987 14.9291C4.21993 14.5522 3.39314 13.9997 2.6967 13.3033C2.00026 12.6069 1.44781 11.7801 1.0709 10.8701C0.693993 9.96018 0.5 8.98491 0.5 8C0.5 7.01509 0.693993 6.03982 1.0709 5.12987C1.44781 4.21993 2.00026 3.39314 2.6967 2.6967C3.39314 2.00026 4.21993 1.44781 5.12988 1.0709C6.03982 0.693993 7.01509 0.5 8 0.5C8.98492 0.5 9.96018 0.693993 10.8701 1.0709C11.7801 1.44781 12.6069 2.00026 13.3033 2.6967C13.9997 3.39314 14.5522 4.21993 14.9291 5.12988C15.306 6.03982 15.5 7.01509 15.5 8L15.5 8Z"
      stroke={vantiColors.green4}
    />
  </svg>
);

export default memo(SuccessIcon16);
