import { dataCurationsFlagTypes } from "./actionTypes";

const defaultState = {
  dataCurations: [],
  loading: false,
  error: null,
  success: false
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case dataCurationsFlagTypes.START_FETCH: {
      return { ...state, loading: true, success: false, error: null };
    }
    case dataCurationsFlagTypes.SET_SUCCESS: {
      const dataCurations = action.payload;
      return { ...state, loading: false, success: true, error: null, dataCurations };
    }
    case dataCurationsFlagTypes.SET_ERROR: {
      const error = action.payload;
      return { ...state, loading: false, success: false, error };
    }
    case dataCurationsFlagTypes.SET_LOADING: {
      return { ...state, loading: true, error: null, success: false };
    }
    case dataCurationsFlagTypes.CLEAR_LOADING: {
      return { ...state, loading: false };
    }
    case dataCurationsFlagTypes.CLEAR_SUCCESS: {
      return { ...state, success: false };
    }
    case dataCurationsFlagTypes.CLEAR_ERROR: {
      return { ...state, error: null };
    }
    case dataCurationsFlagTypes.ADD_CURATION: {
      const dataCuration = action.payload;

      const dataCurations = [...state.dataCurations];
      dataCurations.push(dataCuration);

      return { ...state, dataCurations };
    }
    case dataCurationsFlagTypes.EDIT_CURATION: {
      const dataCuration = action.payload;

      const dataCurations = [...state.dataCurations];
      const dataCurationToEditIndex = dataCurations.findIndex(curation => curation._id === dataCuration._id);

      if (dataCurationToEditIndex > -1) {
        dataCurations[dataCurationToEditIndex] = dataCuration;
      }

      return { ...state, dataCurations };
    }
    case dataCurationsFlagTypes.REMOVE_CURATION: {
      const dataCurationId = action.payload;
      const dataCurations = [...state.dataCurations];
      const dataCurationToDeleteIndex = dataCurations.findIndex(curation => curation._id === dataCurationId);

      if (dataCurationToDeleteIndex > -1) {
        dataCurations.splice(dataCurationToDeleteIndex, 1);
      }

      return { ...state, dataCurations };
    }

    default: {
      return state;
    }
  }
}
