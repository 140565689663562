import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import InviteIconCircled from "assets/icons/invite-icon/InviteIconCircled";
import { AppContext } from "common/hooks/context-hooks/use-app-context";
import { styled } from "@material-ui/core";
import { avatarsColorPalette, vantiColors } from "assets/jss/palette";
import ChatMessageUserAvatar from "pages/side-bar/chat/components/chat-message/ChatMessageUserAvatar";
import { DashboardUserInvitationStatus } from "@vanti-analytics-org/vanti-common";
import GeneralModalPreviewComponent from "components/ui/Modal/GeneralModalPreviewComponent";
import DashboardInviteUsersModal from "./DashboardInviteUsersModal";

const INVITE_AVATAR_GROUP_Z_INDEX_CAP = 100;
const INVITE_AVATAR_OVERLAP_IN_PX = 12;
const PENDING_INVITATION_CONFIRMATION_TOOLTIP_TEXT = "(Pending)";

const HomePageDashboardInvite = () => {
  const { dashboardContext } = useContext(AppContext);
  const { dashboards, currentDashboard } = dashboardContext;
  const [isHovered, setIsHovered] = useState(false);
  const [openDashboardInviteModal, setOpenDashboardInviteModal] = useState(false);

  const avatars = useMemo(() => {
    const usersInfo = dashboards[(currentDashboard?._id)]?.usersInfo;
    if (!usersInfo) {
      return;
    }

    const statuses = Object.values(DashboardUserInvitationStatus);
    const sortedUsersInfo = usersInfo?.sort((a, b) => statuses.indexOf(b.status) - statuses.indexOf(a.status));
    return sortedUsersInfo.map((user, i) => {
      const isPending = user.status === DashboardUserInvitationStatus.PENDING;
      return (
        <ChatMessageUserAvatar
          displayName={isPending ? `${user.name} ${PENDING_INVITATION_CONFIRMATION_TOOLTIP_TEXT}` : user.name}
          key={user._id}
          backgroundColor={isPending ? vantiColors.gray36 : avatarsColorPalette[i % avatarsColorPalette.length]}
          customAvatarStyle={{
            zIndex: INVITE_AVATAR_GROUP_Z_INDEX_CAP - (i + 1),
            transform: `translate(-${INVITE_AVATAR_OVERLAP_IN_PX * (i + 1)}px, 0px)`,
            border: isPending ? `1px dashed ${vantiColors.lightGray16}` : "unset"
          }}
        />
      );
    });
  }, [dashboards, currentDashboard]);

  const onCloseModal = useCallback(() => {
    setOpenDashboardInviteModal(false);
  }, []);

  return (
    <>
      <InviteSectionContainer>
        <InviteIconCircledStyled
          onClick={() => setOpenDashboardInviteModal(true)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          isHovered={isHovered}
        />
        {avatars}
      </InviteSectionContainer>
      {openDashboardInviteModal && (
        <GeneralModalPreviewComponent
          height={"50%"}
          width={"30%"}
          open={openDashboardInviteModal}
          onClose={onCloseModal}
        >
          <DashboardInviteUsersModal closeModal={onCloseModal} />
        </GeneralModalPreviewComponent>
      )}
    </>
  );
};

const InviteSectionContainer = styled("div")({
  boxSizing: "border-box",
  display: "flex"
});

const InviteIconCircledStyled = styled(InviteIconCircled)(({ isHovered }) => ({
  zIndex: INVITE_AVATAR_GROUP_Z_INDEX_CAP,
  "& > rect:first-of-type": {
    fill: isHovered ? vantiColors.gray35 : "white",
    transition: "0.2s all ease-in-out"
  }
}));

export default memo(HomePageDashboardInvite);
