import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  inputsWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2, auto)",
    columnGap: theme.spacing(5),
    rowGap: theme.spacing(3)
  },
  checkBoxContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    "& > div:last-of-type": {
      display: "flex",
      alignItems: "center",
      "& > label": {
        padding: 0,
        marginTop: 0,
        marginLeft: 0,
        marginRight: theme.spacing(1.5),
        "& > span": {
          padding: 0
        }
      },
      "& a": {
        color: "inherit !important",
        textDecoration: "underline"
      }
    }
  },
  info: {
    marginBottom: theme.spacing(2.75),
    marginTop: theme.spacing(1),
    "& > div": {
      display: "flex",
      alignItems: "center",
      "& > svg": {
        marginRight: theme.spacing(1),
        width: "16px",
        height: "16px",
        marginBottom: theme.spacing(0.25)
      }
    }
  },
  checkboxContainer: {
    paddingBottom: "0px !important"
  }
}));
