import { vantiColors } from "assets/jss/palette";
import React, { memo } from "react";

const CheckboxUnfilled24 = ({ color = vantiColors.darkBlue7, ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M19.5 4.5H4.5V19.5H19.5V4.5Z" stroke={color} />
    </svg>
  );
};

export default memo(CheckboxUnfilled24);
