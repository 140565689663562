import { useContext } from "react";
import { useMutation } from "react-query";
import * as tagsApi from "../../services/api/tags";
import { AppContext } from "./context-hooks/use-app-context";

const GET_ALL_ACCOUNT_TAGS_REQUEST_KEY = "get.all.account.tags.request.key";
const CREATE_TAG_REQUEST_KEY = "create.tag.request.key";
const DELETE_TAG_REQUEST_KEY = "delete.tag.request.key";

const useTagsRequest = () => {
  const { tagsContext, reportsContext } = useContext(AppContext);
  const { setTags } = tagsContext;
  const { setReports } = reportsContext;

  const getAllAccountTags = useMutation(GET_ALL_ACCOUNT_TAGS_REQUEST_KEY, () => tagsApi.getAllAccountTags(), {
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    onSuccess: tags => {
      if (tags) setTags(tags);
    }
  });

  const createTag = useMutation(CREATE_TAG_REQUEST_KEY, ({ reportId, value }) => tagsApi.createTag(reportId, value), {
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    onSuccess: (singleTag, { reportId }) => {
      if (singleTag) {
        setTags(prevValue => [...prevValue, singleTag]);
        setReports(prevValue => {
          const prevValueCopy = { ...prevValue };
          if (prevValueCopy[reportId]?.reportData?.tags) {
            prevValueCopy[reportId].reportData.tags.push(singleTag);
          }
          return prevValueCopy;
        });
      }
    }
  });

  const deleteTag = useMutation(DELETE_TAG_REQUEST_KEY, ({ reportId, tagId }) => tagsApi.deleteTag(reportId, tagId), {
    manual: true,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    onSuccess: (data, { reportId, tagId }) => {
      setTags(prevValue => {
        const tagIndex = prevValue.findIndex(tag => tag._id === tagId);

        if (tagIndex >= 0) {
          const prevValueCopy = [...prevValue];
          prevValueCopy.splice(tagIndex, 1);

          return prevValueCopy;
        }

        return prevValue;
      });

      setReports(prevValue => {
        if (prevValue[reportId]?.reportData?.tags) {
          const tagIndex = prevValue[reportId].reportData.tags.findIndex(tag => tag._id === tagId);

          if (tagIndex >= 0) {
            const prevValueCopy = { ...prevValue };
            prevValueCopy[reportId].reportData.tags.splice(tagIndex, 1);

            return prevValueCopy;
          }
        }

        return prevValue;
      });
    }
  });

  return {
    getAllAccountTags,
    createTag,
    deleteTag
  };
};

export default useTagsRequest;
