import React, { memo, useCallback, useEffect, useReducer } from "react";
import { styled } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import Header from "./Header";
import AddEntitySection from "./AddEntitySection";
import Body from "./Body";

const DrawerStyled = styled("div")({
  boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.10)",
  padding: 16,
  height: "100%",
  backgroundColor: vantiColors.white,
  display: "flex",
  flexDirection: "column",
  rowGap: 24,
  position: "absolute",
  right: 0,
  top: 0,
  zIndex: 99999,
  width: 303
});

const entityDataReducer = (state, payload) => {
  if (payload.childEntity) {
    return {
      ...state,
      childEntities: [...state.childEntities, payload.childEntity]
    };
  }

  if (payload.subEntity) {
    const { childEntityId, subEntity } = payload;

    const foundEntityIndex = state.childEntities.findIndex(entity => entity._id === childEntityId);

    if (foundEntityIndex >= 0) {
      const newEntity = state.childEntities[foundEntityIndex];
      newEntity.subEntity = subEntity;

      const newChildEntities = [...state.childEntities];
      newChildEntities.splice(foundEntityIndex, 1, newEntity);

      return {
        ...state,
        childEntities: newChildEntities
      };
    }
  }

  if (payload.existingNodeData) {
    return { ...payload.existingNodeData };
  }

  return { ...state, ...payload };
};

const initializedEntityData = {
  name: "Station 1",
  description: "Description",
  childEntities: []
};

const Drawer = ({ onUpdateNode, existingNodeData, children }) => {
  const [stationData, dispatch] = useReducer(entityDataReducer, initializedEntityData);

  useEffect(() => {
    if (existingNodeData?.data) {
      dispatch({ existingNodeData: existingNodeData?.data });
    }
  }, [dispatch, existingNodeData?.id]);

  const onNameChange = useCallback(
    newName => {
      dispatch({
        name: newName
      });
    },
    [dispatch]
  );

  const onDescriptionChange = useCallback(
    newDesc => {
      dispatch({
        description: newDesc
      });
    },
    [dispatch]
  );

  const onAddChildEntity = useCallback(
    entity => {
      dispatch({
        childEntity: entity
      });
    },
    [dispatch]
  );

  const onAddSubEntity = useCallback(
    (childEntityId, subEntity) => {
      dispatch({ childEntityId, subEntity });
    },
    [dispatch]
  );

  useEffect(() => {
    onUpdateNode && onUpdateNode(stationData, existingNodeData?.id);
  }, [stationData, onUpdateNode, existingNodeData?.id]);

  return (
    <DrawerStyled>
      {children || (
        <>
          <Header stationData={stationData} onNameChange={onNameChange} onDescriptionChange={onDescriptionChange} />
          <Body childEntities={stationData.childEntities} onAddSubEntity={onAddSubEntity} />
          <AddEntitySection onAddChildEntity={onAddChildEntity} childEntities={stationData.childEntities} />
        </>
      )}
    </DrawerStyled>
  );
};

export default memo(Drawer);
