import React from "react";
import { vantiColors } from "assets/jss/palette";
import * as yup from "yup";
import GreenV from "assets/icons/GreenV.svg";
import { Text } from "components/ui";
import { checkPasswordRequirements } from "../password-check";

export const PASSWORD_VALIDATION_KEYWORDS = {
  ERROR: "Weak",
  INFO: "Almost",
  SUCCESS: "Awesome"
};

const validatePass = (password, testContext) => {
  const requirements = checkPasswordRequirements(password);

  if (requirements.hasPassedAllChecks) return true;

  const getRequirementTextComponent = (requirement, text) => {
    return (
      <div>
        <Text
          color={requirement ? vantiColors.green1 : password.length > 0 ? vantiColors.rose6 : vantiColors.darkBlue4}
          size={12}
        >
          {requirement && <img src={GreenV} alt="Check" />} {text}
        </Text>
      </div>
    );
  };

  const results = (
    <div>
      <div>
        <Text color={vantiColors.gray1} size={12}>
          Password must contain:
        </Text>
      </div>

      <div style={{ marginLeft: 10 }}>
        {getRequirementTextComponent(requirements.isValidLength, "8 characters minimum")}
        {getRequirementTextComponent(requirements.isUppercase, "One uppercase character")}
        {getRequirementTextComponent(requirements.isLowercase, "One lowercase character")}
        {getRequirementTextComponent(requirements.isNumber, "One number")}
        {getRequirementTextComponent(requirements.isSymbol, "One special character")}
        {getRequirementTextComponent(
          requirements.isHardToGuess,
          'Password is not an easy to guess sequence (e.g. "Qwerty1234?" or "Passw0rd!@#")'
        )}
      </div>
    </div>
  );

  return testContext.createError({
    path: "password",
    message: results,
    params: {}
  });
};

const schema = yup
  .object()
  .shape({
    firstName: yup
      .string()
      .required("Mandatory field")
      .min(2, "First Name must be at least 2 characters")
      .matches(/^([a-zA-Z0-9 _-]+)$/, { message: "First Name must not contain special characters" }),
    lastName: yup
      .string()
      .required("Mandatory field")
      .matches(/^([a-zA-Z0-9 _-]+)$/, { message: "Last Name must not contain special characters" }),
    password: yup
      .string()
      .test({
        name: "passwordTest",
        test: validatePass
      })
      .required("Mandatory field"),
    passwordVerification: yup
      .string()
      .oneOf([yup.ref("password"), null], {
        message: "Passwords must match",
        color: vantiColors.rose6
      })
      .required({
        message: "Passwords must match",
        color: vantiColors.rose6
      }),
    policies: yup
      .boolean()
      .isTrue()
      .required()
  })
  .required();

export default schema;
