import React, { memo } from "react";

import BodyCell from "../../table-body-rows/body-cell/BodyCell";
import EllipsisText from "components/ui/EllipsisText/EllipsisText";
import Text from "components/ui/Typography/Text";
import { vantiColors } from "assets/jss/palette";

const StationLastPredictionCell = ({ stationName, lastPredictTimestamp }) => {
  return (
    <BodyCell data-testid={`${stationName}-last-prediction`} align="left">
      <div>
        <EllipsisText tooltipText={lastPredictTimestamp} color={vantiColors.gray33} size={12} weight={400}>
          <Text>{lastPredictTimestamp}</Text>
        </EllipsisText>
      </div>
    </BodyCell>
  );
};

export default memo(StationLastPredictionCell);
