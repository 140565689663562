import { createSelector } from "reselect";

const accountSelector = state => state.account?.state;

export const accountNameSelector = createSelector(
  accountSelector,
  state => state.name
);

export const accountIdSelector = createSelector(
  accountSelector,
  state => state.id
);

export const accountUsersSelector = createSelector(
  accountSelector,
  state => state.users
);

export const accountLicenseSelector = createSelector(
  accountSelector,
  state => state.license
);

export const maxAllowedDeployedModelsCountSelector = createSelector(
  accountSelector,
  state => state.maxAllowedDeployedModelsCount
);

export const deployedModelsCountSelector = createSelector(
  accountSelector,
  state => state.deployedModelsCount
);

export const accountIdentifierSelector = createSelector(
  accountSelector,
  state => `${state?.name}-${state?.id}`
);

export const licenseTimeLeftSelector = createSelector(
  accountSelector,
  state => state?.licenseTimeLeft
);

export const accountProductsSelector = createSelector(
  accountSelector,
  state => state?.products
);

export const accountLinesSelector = createSelector(
  accountSelector,
  state => state?.lines
);
