import React, { memo, useEffect, useMemo, useState } from "react";
import { FlexColumn, FlexRow } from "components/ui/Flex/FlexContainer";
import { CommonTitleText, SemanticInfoTextAreaStyled } from "pages/side-bar/data-entities/body/SingleDataEntityBody";
import GeneralVantiButton, { SIZES, VARIANTS } from "components/ui/Buttons/GeneralVantiButton";
import InfoIcon from "assets/icons/InfoIcon";
import { Text } from "components/ui";
import { styled, withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { vantiColors } from "assets/jss/palette";
import Spacer from "components/ui/Spacer";

const NewAlertCreationScreen = ({ onCreateAlert, errorMsg }) => {
  const [nameText, setNameText] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [allowEditing, setAllowEditing] = useState(true);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const alertDescriptionWritingHint = useMemo(() => {
    return (
      <FlexColumn>
        <CommonTitleText>{"How do I write a good description?"}</CommonTitleText>
        <Text>
          {`A good metric description should include the associated entities in which we want to track and the metric.`}
        </Text>
        <Spacer space={8} />
        <Text weight={600}>{"For example"}</Text>
        <Text>
          {`"Calculate the`}
          <HLText>{`yield`}</HLText>
          {` of `}
          <HLText>{`station_1`}</HLText>
          {` in `}
          <HLText>{`batch_345"`}</HLText>
        </Text>
        <Text>
          {`"Calculate the `}
          <HLText>{`failure_rate`}</HLText>
          {` of `}
          <HLText>{`operator_A`}</HLText>
          {` during the `}
          <HLText>{`assembly`}</HLText>
          {` phase `}
        </Text>
        <Text>
          {`"Calculate the change in `}
          <HLText>{`failed_units`}</HLText>
          {` between `}
          <HLText>{`station_3`}</HLText>
          {` and `}
          <HLText>{`station_4`}</HLText>
        </Text>
      </FlexColumn>
    );
  }, []);

  const onChangeNameText = event => {
    setNameText(event.target.value);
    setIsEditing(event.target.value && event.target.value.length ? event.target.value : false);
  };

  const onChangeDescriptionText = event => {
    setDescriptionText(event.target.value);
    setIsEditing(event.target.value && event.target.value.length ? event.target.value : false);
  };

  useEffect(() => {
    if (errorMsg) {
      setIsBtnDisabled(false);
      setAllowEditing(true);

      return;
    }

    if (!isEditing) {
      setIsBtnDisabled(true);
      return;
    }

    setIsBtnDisabled(false);
  }, [errorMsg, isEditing]);

  return (
    <FlexColumn style={{ gap: 16 }}>
      <CommonTitleText>{"Name"}</CommonTitleText>
      <SemanticInfoTextAreaStyled
        placeholder={"Station_1 yield in batch_345"}
        style={{ minHeight: "unset", height: "unset" }}
        onChange={onChangeNameText}
      />
      <FlexRow style={{ justifyContent: "flex-start", gap: 8, height: "fit-content", alignItems: "center" }}>
        <CommonTitleText>{"Description"}</CommonTitleText>

        <CustomToolTip
          data-testid={"alertDescriptionWritingHint"}
          title={alertDescriptionWritingHint}
          placement={"right-end"}
        >
          <div>
            <InfoIcon cursor={"pointer"} />
          </div>
        </CustomToolTip>
      </FlexRow>

      <SemanticInfoTextAreaStyled
        value={descriptionText}
        placeholder={"Calculate the yield of Station_1 for batch_345"}
        onChange={onChangeDescriptionText}
        disabled={!allowEditing}
      />

      <FlexBottomSection justifyContent={errorMsg ? "space-between" : "flex-end"}>
        {errorMsg && <Text color={vantiColors.danger1}>{errorMsg}</Text>}

        <GeneralVantiButton
          variant={VARIANTS.SECONDARY}
          size={SIZES.SMALL}
          style={{ alignSelf: "flex-end" }}
          onClickDisable={{ eventFinished: errorMsg ? true : false }}
          onClickShowLoader={{ eventFinished: errorMsg ? true : false }}
          buttonInfo={{
            onClick: () => {
              setAllowEditing(false);
              onCreateAlert(nameText, descriptionText);
            },
            label: "Create",
            disabled: isBtnDisabled
          }}
        />
      </FlexBottomSection>
    </FlexColumn>
  );
};

const CustomToolTip = withStyles(theme => ({
  tooltip: {
    border: `1px solid ${vantiColors.gray36}`,
    borderRadius: 5,
    backgroundColor: "white",
    boxSizing: "border-box",
    padding: 16,
    fontSize: 14,
    maxWidth: 600,
    fontFamily: "Inter"
  }
}))(Tooltip);

const HLText = styled("span")({
  fontSize: "inherit",
  fontFamily: "inherit",
  fontWeight: 600,
  color: vantiColors.darkBlue7
});

const FlexBottomSection = styled("div")(({ justifyContent }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent
}));

export default memo(NewAlertCreationScreen);
