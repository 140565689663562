import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import isEmpty from "lodash/isEmpty";

import Card from "components/ui/Card/Card";
import FlexItem from "components/ui/Flex/FlexItem";
import FlexContainer from "components/ui/Flex/FlexContainer";
import Text from "components/ui/Typography/Text";
import * as signInModuleSelectors from "modules/auth/operations/signInByEmail/selectors";
import * as signInModuleActions from "modules/auth/operations/signInByEmail/actions";
import { accountNameSelector } from "modules/account/state/selectors";

import * as authService from "services/auth/auth";
import validationSchema from "utils/validation/confirm-account";
import * as fullStory from "common/fullstory/fullstory";
import FULLSTORY_EVENTS from "common/fullstory/fullStoryEvents.js";

import { darkBlueColor, vantiColors } from "assets/jss/vanti-dashboard";

import AttentionIcon from "assets/icons/AttentionIcon";
import { logger } from "utils/logger";
import Header from "./header";
import Body from "./body";
import Footer from "./footer";
import { profileDataSelector, userEmailSelector } from "modules/user/state/selectors";
import { fetchUserRequestAction } from "modules/user/operations/fetchUser/actions";

const ERROR_CODES_MAP = Object.freeze({
  "auth/invalid-action-code": "You have been already registered",
  "auth/expired-action-code": "Invite link expired."
});

const DISABLED_ERROR_MESSAGES = [
  "You have been already registered",
  "Yor account trial has been expired",
  "Invite link expired"
];

const ConfirmAccount = ({ verifyToken }) => {
  const dispatch = useDispatch();

  const isSuccessSignIn = useSelector(signInModuleSelectors.successSelector);

  const [accountName, setAccountName] = useState("");
  const [email, setEmail] = useState("");

  const profileData = useSelector(profileDataSelector);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange"
  });

  const {
    handleSubmit,
    formState: { errors }
  } = methods;

  const [error, setError] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [showInfo, setShowInfo] = useState(true);

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (!isEmpty(errors.policies)) {
      setError(`* Please accept the Terms of Use to continue`);
    } else {
      setError(null);
    }
  }, [errors.policies, setError]);

  const getErrorMessage = (message, explanation = false) => (
    <>
      <div>
        <AttentionIcon /> {message}
      </div>

      {explanation && (
        <Text color={vantiColors.darkBlue1} size="12px" weight="regular">
          To register, ask your administrator to resend the invitation.
        </Text>
      )}
    </>
  );

  const verifyInvite = async () => {
    if (verifyToken) {
      try {
        const result = await authService.validateUserInvite(verifyToken);

        setEmail(result.email);
        setAccountName(result.accountName);
      } catch (error) {
        setShowInfo(false);
        const message = error.message || "Something went wrong";
        setIsDisable(DISABLED_ERROR_MESSAGES.includes(message));
        setError(getErrorMessage(message, "Invite link expired" === message));
      }
    }
  };

  useEffect(() => {
    verifyInvite();
  }, [verifyToken]);

  useEffect(() => {
    if (isSuccessSignIn) {
      try {
        fullStory.setCustomEvent(FULLSTORY_EVENTS.USER_FIRST_SIGN_IN, profileData);
      } catch (error) {
        logger.error(`Error creating a custom Fullstory event: ${error}`);
      }
    }
  }, [isSuccessSignIn]);

  const onSubmit = async data => {
    try {
      await authService.confirmUserInvite(verifyToken, data.firstName, data.lastName, data.password);

      dispatch(signInModuleActions.signInRequestAction(email, data.password));
    } catch (error) {
      const message = ERROR_CODES_MAP[error.code] || "Something went wrong";
      setError(getErrorMessage(message, "Invite link expired" === message));
    }
  };

  if (!verifyToken) {
    return <Redirect to="/dashboard/auth" />;
  }

  return (
    <FlexContainer justifyContent="center">
      <FlexItem xs={7} container direction="row">
        <Card>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Header />
              <Body isDisable={isDisable} email={email} account={accountName} showInfo={showInfo} />
              <Footer error={error} />
            </form>
          </FormProvider>
        </Card>
      </FlexItem>
    </FlexContainer>
  );
};

export default memo(ConfirmAccount);
