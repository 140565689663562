import React, { memo, useEffect, useMemo } from "react";
import { Button } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";
import { Text } from "components/ui";
import CustomDialog from "components/ui/Dialog/CustomDialog";
import LoadingOverlay from "components/ui/loading-overlay";
import { dataConnectorsRemoveConnectorFromState } from "modules/data-connectors/actions";
import { useDispatch } from "react-redux";
import { useAsyncFn } from "react-use";
import { deleteDataConnector } from "services/api/dataConnectors";
import { DELETE_DATA_CONNECTOR_DIALOG } from "../../../utils";

const DeleteConnectorDialog = ({ isShowDeleteDialog, setIsShowDeleteDialog, currentConnector }) => {
  const dispatch = useDispatch();

  const [deleteConnectorReq, setDeleteConnectorReq] = useAsyncFn(async connectorId => {
    const response = await deleteDataConnector(connectorId);
    return response;
  }, []);

  useEffect(() => {
    if (deleteConnectorReq.value?.ok) {
      if (deleteConnectorReq.value.data?.deleted)
        dispatch(dataConnectorsRemoveConnectorFromState(deleteConnectorReq.value.data.id));
      setIsShowDeleteDialog(false);
    }
  }, [deleteConnectorReq]);

  const errorMessage = useMemo(() => deleteConnectorReq.error?.message, [deleteConnectorReq.error]);

  return (
    <CustomDialog
      isOpened={isShowDeleteDialog}
      title={DELETE_DATA_CONNECTOR_DIALOG.title}
      body={deleteConnectorReq.error ? errorMessage : DELETE_DATA_CONNECTOR_DIALOG.body(currentConnector.title)}
    >
      <Button
        onClick={() => {
          setIsShowDeleteDialog(false);
        }}
        data-testid={"confirm-deletion-dc-cancel-btn"}
      >
        <Text color={vantiColors.gray23}>Cancel</Text>
      </Button>
      <Button
        onClick={() => {
          setDeleteConnectorReq(currentConnector._id);
        }}
        data-testid={"confirm-deletion-dc-delete-btn"}
      >
        <Text color={vantiColors.red5}>Delete</Text>
      </Button>

      {deleteConnectorReq.loading && <LoadingOverlay />}
    </CustomDialog>
  );
};

export default memo(DeleteConnectorDialog);
