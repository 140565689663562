import { put, takeLatest, call } from "redux-saga/effects";
import * as blockService from "services/api/block";
import { createSelector } from "reselect";

// -------------------------
// -------- ACTIONS --------
// -------------------------

const actionTypes = {
  BLOCK_DELETE_REQUEST: "block.delete.request",
  BLOCK_DELETE_SUCCESS: "block.delete.success",
  BLOCK_DELETE_ERROR: "block.delete.error",
  BLOCK_DELETE_FULFILL: "block.delete.fulfill"
};

export function blockDeleteAction(blockId, callback) {
  return { type: actionTypes.BLOCK_DELETE_REQUEST, payload: { blockId, callback } };
}

// -------------------------
// -------- REDUCER --------
// -------------------------

const defaultState = {
  loading: null,
  success: null,
  error: null
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.BLOCK_DELETE_REQUEST: {
      return { ...state, loading: true };
    }
    case actionTypes.BLOCK_DELETE_SUCCESS: {
      return { ...state, success: true };
    }
    case actionTypes.BLOCK_DELETE_ERROR: {
      return { ...state, success: false, error: action.payload.error };
    }
    case actionTypes.BLOCK_DELETE_FULFILL: {
      return { ...state, loading: false };
    }
    default: {
      return state;
    }
  }
}

// ---------------------------
// -------- SELECTORS --------
// ---------------------------

export const blockDeleteSelector = state => state.block?.query;
export const blockDeleteLoadingSelector = createSelector(
  blockDeleteSelector,
  state => state.loading
);

// ---------------------------
// ---------- SAGAS ----------
// ---------------------------

function* blockDeleteSaga(action) {
  const { blockId, callback } = action.payload;
  try {
    const blocks = yield call(blockService.deleteBlock, blockId);
    yield put({ type: actionTypes.BLOCK_DELETE_SUCCESS, payload: { blocks } });
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: actionTypes.BLOCK_DELETE_ERROR, payload: { error } });
  } finally {
    yield put({ type: actionTypes.BLOCK_DELETE_FULFILL, payload: {} });
  }
}

export function* blockDeleteWatcher() {
  yield takeLatest(actionTypes.BLOCK_DELETE_REQUEST, blockDeleteSaga);
}
