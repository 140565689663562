import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import SandboxList from "./sandboxAdmin/SandboxList";
import SandboxEdit from "./sandboxAdmin/SandboxEdit";

const basePath = "/admin/sandbox";

function SandboxAdminItem() {
  return (
    <Switch>
      <Route path={`${basePath}/:blockId/edit`} component={SandboxEdit} />
      <Redirect to={`${basePath}`} />
    </Switch>
  );
}

export default function SandboxAdmin() {
  return (
    <Switch>
      <Route exact path={`${basePath}`} component={SandboxList} />
      <Route path={`${basePath}/:blockId`} component={SandboxAdminItem} />
    </Switch>
  );
}
