import React, { memo, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { ROLES_ENUM } from "common/constants/AccountConstants";
import usePermissions from "common/hooks/use-permissions";
import { ReactComponent as StationsIcon } from "assets/icons/stationsIcon.svg";
import { ReactComponent as SiteIcon } from "assets/icons/siteIcon.svg";
import ChatCsvIcon from "assets/icons/chat-csv/ChatCsvIcon";
import { ReactComponent as ConnectorsIcon } from "assets/icons/connectorsIcon.svg";
import { ReactComponent as VantiLogoSmall } from "assets/icons/VantiFullLogoSmall.svg";
import DemosIcon from "assets/icons/demos/DemosIcon";
import DatasetAnalyzerIcon from "assets/icons/dataset-analyzer/DatasetAnalyzerIcon";
import styles, { Root, StyledSideBar, Nav, NavIcon, NavIconText, StyledLink } from "./SideBar.styles";
import CustomTreeView from "components/layouts/tree-view/CustomTreeView";
import ChatIcon24 from "assets/icons/chat-icon/ChatIcon24";
import ReportIcon24 from "assets/icons/chat/ReportIcon24";
import EntitiesAndFlowsIcon from "assets/icons/data-entities-and-flows/EntitiesAndFlowsIcon";
import { Text } from "components/ui";

const SideBar = () => {
  const classes = styles();

  const isStreamlitAvailable = usePermissions([ROLES_ENUM.STREAMLIT_APPS]);
  const isStreamlitChatCsv = usePermissions([ROLES_ENUM.STREAMLIT_CHAT_CSV]);
  const isRathAvailable = usePermissions([ROLES_ENUM.ENABLE_RATH]);
  const isAllowedToViewSiteView = usePermissions([ROLES_ENUM.VIEW_SITE_VIEW_PAGE]);
  const isAllowedToViewAppsDashboard = usePermissions([ROLES_ENUM.VIEW_APPS_DASHBOARD]);
  const isAllowedToViewChatPage = usePermissions([ROLES_ENUM.VIEW_CHAT_PAGE]);
  const isAllowedToViewHomePage = usePermissions([ROLES_ENUM.VIEW_HOME_PAGE]);
  const isAllowedToViewDataEntitiesPage = usePermissions([ROLES_ENUM.VIEW_DATA_ENTITIES_PAGE]);
  const isAllowedToViewReportsPage = usePermissions([ROLES_ENUM.VIEW_REPORTS_PAGE]);

  const dashboards = useMemo(() => {
    const items = [
      // {
      //   icon: <ConnectorsIcon data-testid={"drawer-dashboard-data-connectors-icon"} />,
      //   path: "/dashboard/data-connectors",
      //   openText: "Data connectors",
      //   closeText: "Connectors"
      // },
      // {
      //   icon: <DataCurationIcon80 data-testid={"drawer-dashboard-data-curation-icon"} />,
      //   path: "/dashboard/data-curation",
      //   openText: "Data curation",
      //   closeText: "Curation"
      // }
    ];

    if (isAllowedToViewHomePage) {
      items.push({
        icon: <SiteIcon data-testid={"drawer-dashboard-home-icon"} />,
        path: "/dashboard/home",
        openText: "Home",
        closeText: "Home"
      });
    }

    items.push({
      icon: <ConnectorsIcon data-testid={"drawer-dashboard-data-icon"} />,
      path: "/dashboard/data",
      openText: "Data",
      closeText: "Data"
    });

    if (isAllowedToViewDataEntitiesPage) {
      items.push({
        icon: <EntitiesAndFlowsIcon data-testid={"drawer-dashboard-data-entities-and-flows-icon"} />,
        path: "/dashboard/entities",
        openText: (
          <span style={{ display: "flex", flexDirection: "column", alignItems: "center", lineHeight: "16px" }}>
            Entities & Flows
          </span>
        ),
        closeText: (
          <span style={{ display: "flex", flexDirection: "column", alignItems: "center", lineHeight: "16px" }}>
            Entities & Flows
          </span>
        )
      });
    }

    if (isAllowedToViewChatPage) {
      items.push({
        icon: <ChatIcon24 data-testid={"chat-icon"} />,
        path: "/dashboard/chat",
        openText: "Chat",
        closeText: "Chat"
      });
    }

    if (isAllowedToViewReportsPage) {
      items.push({
        icon: <ReportIcon24 data-testid={"report-icon"} />,
        path: "/dashboard/reports",
        openText: "Reports",
        closeText: "Reports"
      });
    }

    if (isAllowedToViewAppsDashboard) {
      items.push({
        icon: <StationsIcon data-testid={"drawer-dashboard-applications-icon"} />,
        path: "/dashboard/stations",
        openText: "Applications",
        closeText: "Applications"
      });
    }

    if (isAllowedToViewSiteView) {
      items.push({
        icon: <SiteIcon data-testid={"drawer-dashboard-site-view-icon"} />,
        path: "/dashboard/site-view",
        openText: "Site view",
        closeText: "Site view"
      });
    }

    if (isStreamlitChatCsv) {
      items.push({
        icon: <ChatCsvIcon props={{ color: "#394253" }} />,
        path: "/chat-csv",
        openText: "Chat CSV",
        closeText: "Chat CSV"
      });
    }

    if (isStreamlitAvailable) {
      items.push({
        icon: <DemosIcon props={{ color: "#394253" }} />,
        path: "/demos",
        openText: "Demos",
        closeText: "Demos"
      });
    }

    if (isRathAvailable) {
      items.push({
        icon: <DatasetAnalyzerIcon props={{ color: "#394253" }} />,
        path: "/dataset-analyzer",
        openText: "Dataset Analyzer",
        closeText: "Dataset Analyzer"
      });
    }

    return items;
  }, [
    isAllowedToViewHomePage,
    isAllowedToViewDataEntitiesPage,
    isStreamlitAvailable,
    isAllowedToViewSiteView,
    isAllowedToViewReportsPage,
    isRathAvailable,
    isAllowedToViewAppsDashboard,
    isStreamlitChatCsv,
    isAllowedToViewChatPage
  ]);

  return (
    <Root id="global-container">
      <StyledSideBar>
        <StyledLink to={"/"}>
          <VantiLogoSmall data-testid="drawer-vanti-logo-small" />
        </StyledLink>
        <Nav>
          {dashboards.map(item => {
            return (
              <div key={item.openText}>
                <NavLink to={item.path} activeClassName={classes.activeClassName} key={item.openText}>
                  <NavIcon data-testid={`drawer-dashboard-item-${item.closeText}-section`} className={classes.navIcon}>
                    {item.icon}
                    <NavIconText
                      data-testid={`drawer-dashboard-item-${item.closeText}-text`}
                      className={classes.navIconText}
                    >
                      {item.closeText}
                    </NavIconText>
                  </NavIcon>
                </NavLink>
              </div>
            );
          })}
        </Nav>
      </StyledSideBar>
      <CustomTreeView />
    </Root>
  );
};

export default memo(SideBar);
