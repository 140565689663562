import { actionTypes } from "./actions";
import { defaultSingleResourceOperationState } from "utils/modules-utils";

const defaultState = defaultSingleResourceOperationState();

export default function fetchUsersReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.ACCOUNT_FETCH_USERS_REQUEST: {
      return { ...defaultState, loading: true };
    }
    case actionTypes.ACCOUNT_FETCH_USERS_SUCCESS: {
      return { ...state, success: true };
    }
    case actionTypes.ACCOUNT_FETCH_USERS_ERROR: {
      return { ...state, success: false, error: action.payload };
    }
    case actionTypes.ACCOUNT_FETCH_USERS_FULFILL: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
}
