import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  defaultFontStyle: {
    ...theme.fonts.defaultFont,
    fontSize: "14px",
    display: "inline"
  },
  extraLightFontWeight: {
    fontWeight: theme.fonts.lightFontWeight
  },
  lightFontWeight: {
    fontWeight: theme.fonts.lightFontWeight
  },

  regularXFontWeight: {
    fontWeight: theme.fonts.regularXFontWeight
  },
  mediumFontWeight: {
    fontWeight: theme.fonts.mediumFontWeight
  },
  boldFontWeight: {
    fontWeight: theme.fonts.boldFontWeight
  },
  semiFontWeight: {
    fontWeight: 600
  },
  defaultHeaderMargins: {
    marginTop: "20px",
    marginBottom: "10px"
  },
  uppercase: {
    textTransform: "uppercase"
  },
  capitalize: {
    textTransform: "capitalize"
  },
  underline: {
    textDecoration: "underline"
  },
  darkColor: {
    color: theme.palette.vantiColors.gray30
  },
  dangerColor: {
    color: theme.palette.vantiColors.danger1
  },
  grayColor: {
    color: theme.palette.vantiColors.gray2
  },
  lightGrayColor: {
    color: theme.palette.vantiColors.gray6
  },
  lightGrayBlueColor: {
    color: theme.palette.vantiColors.lightGrayBlue1
  },
  darkGrayColor: {
    color: theme.palette.vantiColors.gray15
  },
  blueColor: {
    color: theme.palette.vantiColors.blue1
  },
  darkBlueColor: {
    color: theme.palette.vantiColors.darkBlue1
  },
  greenColor: {
    color: theme.palette.vantiColors.green1
  },
  turquoiseColor: {
    color: theme.palette.vantiColors.turquoise1
  },
  whiteColor: {
    color: theme.palette.vantiColors.white
  },
  hX: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "48px",
    lineHeight: "72px",
    color: theme.palette.vantiColors.darkBlue1
  },
  h1: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "40px",
    lineHeight: "60px",
    color: theme.palette.vantiColors.darkBlue6
  },
  h2: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "48px",
    color: theme.palette.vantiColors.darkBlue6
  },
  "h2.5": {
    fontSize: "21px"
  },
  h3: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "36px",
    color: theme.palette.vantiColors.darkBlue1
  },
  h4: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "36px",
    letter: "10%",
    color: theme.palette.vantiColors.darkBlue1
  },
  h5: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "21px",
    color: theme.palette.vantiColors.darkBlue1
  },
  body1: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.vantiColors.darkBlue1
  },
  body2: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    color: theme.palette.vantiColors.darkBlue1
  },
  body3: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "24px",
    color: theme.palette.vantiColors.darkBlue4
  },
  disabled: {
    color: "#616875 !important"
  },
  regularFontWeight: {
    fontWeight: theme.fonts.regularFontWeight
  }
}));
