import Dexie from "dexie";

export class ExplorerDB extends Dexie {
  constructor() {
    super("ExplorerDB");
    this.version(1).stores({
      blocks: "id,title,state,useCase",
      stations: "id,apiStatus,type,name,state",
      users: "id,fullName,email,license",
      accounts: "id,name"
    });
  }
}

export const db = new ExplorerDB();
