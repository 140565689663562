import http from "../http";
import config from "common/config";

export const DATASET_API_QUERY_KEYS = {
  GET_DATASET_PRESIGNED_URL: "GET_DATASET_PRESIGNED_URL",
  GET_DATASET_PRESIGNED_URL_FOR_PDF: "GET_DATASET_PRESIGNED_URL_FOR_PDF",
  GET_DATASET_QUESTIONS: "GET_DATASET_QUESTIONS",
  REGENERATE_PREDEFINED_QUESTIONS: "REGENERATE_PREDEFINED_QUESTIONS",
  SAVE_OR_UPDATE_DATASET_QUESTIONS: "SAVE_OR_UPDATE_DATASET_QUESTIONS",
  DELETE_DATASET_QUESTION: "DELETE_DATASET_QUESTION",
  UPDATE_DATASET_DATE_RANGE: "UPDATE_DATASET_DATE_RANGE"
};

const DATASETS_API_URL = `${config.serverUrl}/api/v2/datasets`;

export async function getDatasetPresignedUrl(datasetId) {
  const res = await http.get(`${DATASETS_API_URL}/${datasetId}/download-url`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

export async function getDataset(datasetId) {
  const res = await http.get(`${DATASETS_API_URL}/${datasetId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function getDatasetQuestions(datasetId) {
  const res = await http.get(`${DATASETS_API_URL}/${datasetId}/questions`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function saveOrUpdateDatasetQuestions(datasetId, questions) {
  const res = await http.post(`${DATASETS_API_URL}/${datasetId}/questions`, { questions });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export async function deleteDatasetQuestion(datasetId, questionId) {
  const res = await http.delete(`${DATASETS_API_URL}/${datasetId}/questions/${questionId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }

  return result.data;
}

export const getAccountDatasets = async () => {
  const res = await http.get(`${DATASETS_API_URL}/`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result;
};

export const getFolderDatasets = async datasetId => {
  const res = await http.post(`${DATASETS_API_URL}/${datasetId}/pull-remote-folder`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result;
};

export const deleteDataset = async datasetId => {
  const res = await http.delete(`${DATASETS_API_URL}/${datasetId}`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result;
};

export const getPredefinedQuestionsByDatasetId = async datasetId => {
  const res = await http.get(`${DATASETS_API_URL}/${datasetId}/questions`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const getPredefinedQuestionsByAccount = async () => {
  const res = await http.get(`${DATASETS_API_URL}/questions`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const startLocalFileUpload = async (filename, filesize) => {
  const res = await http.post(`${DATASETS_API_URL}/local-upload/start`, {
    filename,
    filesize
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const finishLocalUpload = async (datasetId, uploadId, parts) => {
  const res = await http.post(`${DATASETS_API_URL}/local-upload/finish`, {
    datasetId,
    uploadId,
    parts
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const getDatasetFileDescription = async datasetId => {
  const res = await http.get(`${DATASETS_API_URL}/${datasetId}/file-description`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const updateDatasetFileDescription = async (datasetId, fileDescription) => {
  const res = await http.patch(`${DATASETS_API_URL}/${datasetId}/file-description`, {
    fileDescription
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const updateDatasetSourceType = async (datasetId, sourceType) => {
  const res = await http.patch(`${DATASETS_API_URL}/${datasetId}/source-type`, {
    sourceType
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const regeneratePredefinedQuestionsForDataset = async datasetId => {
  const res = await http.patch(`${DATASETS_API_URL}/${datasetId}/regenerate-questions`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export const updateDatasetDateRange = async (datasetId, start, end) => {
  const res = await http.patch(`${DATASETS_API_URL}/${datasetId}/date-range`, {
    start,
    end
  });
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
};

export async function getFollowupQuestions(datasetId) {
  const res = await http.get(`${DATASETS_API_URL}/${datasetId}/followupQuestions`);
  const result = await res.json();

  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}
