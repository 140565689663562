import config from "../../common/config";
import http from "../http";

// Server
const host = config.serverUrl;
const sandboxUrl = `${host}/admin/v2/sandbox`;

// API

/**
 * @returns {() => void}
 */
export async function querySandboxes() {
  const res = await http.get(`${sandboxUrl}`);
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}

/**
 * @returns {() => void}
 */
export async function updateSandboxMetadata(blockId, { dataSource }) {
  const res = await http.patch(`${sandboxUrl}/${blockId}/metadata`, { dataSource });
  const result = await res.json();
  if (res.status > 200) {
    // HTTP error
    throw new Error(result.message);
  }
  if (!result.ok) {
    throw new Error(result.message);
  }
  return result.data;
}
