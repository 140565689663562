import React, { memo, useRef, useState } from "react";
import { DashboardItemContainerStyled } from "./DashboardItemContainerStyled";
import { Text } from "components/ui";
import { vantiColors } from "assets/jss/palette";
import { getDateFormatDayjs } from "utils/dateTime";
import { styled } from "@material-ui/core";
import Spacer from "components/ui/Spacer";
import { FlexColumn, FlexRow } from "components/ui/Flex/FlexContainer";
import DashboardItemPinUnpinMenu from "./DashboardItemPinUnpinMenu";
import ClickablePinIconWithTooltip from "./ClickablePinIconWithTooltip";
import PencilIcon24 from "assets/icons/pencil-icon/PencilIcon24";
import { ClickableWrapperWithHoveredClipPath } from "../../../data-entities/body/activity-section/ActivityCard";
import ChartUpIcon24 from "assets/icons/chart/ChartUpIcon24";
import ChartDownIcon24 from "assets/icons/chart/ChartDownIcon24";
import ChartIcon24 from "assets/icons/chart/ChartIcon24";

const extractNumber = str => {
  return str.match(/(\d+)/);
};

const DashboardItemMetric = ({ dashboardItem }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredOverButtonGroup, setIsHoveredOnButtonGroup] = useState(false);
  const [pinUnpinPopupRef, setPinUnpinPopupRef] = useState(null);
  const pinUnpinRef = useRef(null);

  const currentMetricValue = dashboardItem?.metadata?.metrics?.at(-1).valueStr || "";
  const previousMetricValue = dashboardItem?.metadata?.metrics?.at(-2)?.valueStr || currentMetricValue;
  const getMetricValueIcon = () => {
    const curr = extractNumber(currentMetricValue);
    const prev = extractNumber(previousMetricValue);
    return curr > prev ? <ChartUpIcon24 /> : curr < prev ? <ChartDownIcon24 /> : <ChartIcon24 />;
  };

  return (
    <DashboardItemMetricContainerStyled
      onClick={() => {
        if (isHovered && !isHoveredOverButtonGroup) {
          //implement expanded view for metric item
        }
      }}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isHovered={isHovered}
    >
      <FlexRow>
        <FlexColumn style={{ height: "unset", width: "unset", justifyContent: "flex-start" }}>
          <Text size={18} weight={500} lineSize={"normal"}>
            {dashboardItem?.metadata?.reportHeadline}
          </Text>
          <Text color={vantiColors.gray33}>{`Last updated at ${getDateFormatDayjs(dashboardItem?.updatedAt)}`}</Text>
        </FlexColumn>
        {isHovered && (
          <ClickablePinIconWithTooltip
            ref={pinUnpinRef}
            onMouseOverCallback={() => {
              setIsHoveredOnButtonGroup(true);
            }}
            onMouseLeaveCallback={() => {
              setIsHoveredOnButtonGroup(false);
            }}
            onClick={() => {
              setPinUnpinPopupRef({ ref: pinUnpinRef.current });
            }}
          />
        )}
        {!!pinUnpinPopupRef && (
          <DashboardItemPinUnpinMenu
            dashboardItem={dashboardItem}
            anchorEl={pinUnpinPopupRef}
            setAnchorEl={setPinUnpinPopupRef}
          />
        )}
      </FlexRow>

      <Spacer space={24} />
      <FlexColumn style={{ justifyContent: "flex-start", alignItems: "center" }}>
        <FlexRow style={{ justifyContent: "center", alignItems: "center" }}>
          <Text size={42} weight={600} style={{ alignSelf: "center" }}>{`${currentMetricValue}`}</Text>
          {getMetricValueIcon()}
        </FlexRow>
        <Text size={16} lineSize={"normal"} color={vantiColors.gray39}>
          {`Previous result - ${previousMetricValue}`}
        </Text>
        <Spacer space={22.5} />
        <ThresholdWrapper>
          <Text size={16} lineSize={"normal"}>{`Threshold 70%`}</Text>
          <ClickableWrapperWithHoveredClipPath>
            <PencilIcon24 stroke={vantiColors.darkBlue7} />
          </ClickableWrapperWithHoveredClipPath>
        </ThresholdWrapper>
      </FlexColumn>
    </DashboardItemMetricContainerStyled>
  );
};

const DashboardItemMetricContainerStyled = styled(DashboardItemContainerStyled)({
  height: "inherit",
  display: "flex",
  flexDirection: "column",
  cursor: "pointer"
});

const ThresholdWrapper = styled(FlexRow)({
  opacity: 0, //TEMP UNTIL WE ENABLE THIS FEATURE
  width: "fit-content",
  height: "fit-content",
  boxSizing: "border-box",
  backgroundColor: vantiColors.lightBlue10,
  borderRadius: 4,
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 16px",
  alignItems: "center"
});

export default memo(DashboardItemMetric);
