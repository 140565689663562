import { makeStyles } from "@material-ui/core";
import { vantiColors } from "assets/jss/palette";

const styles = makeStyles({
  root: props => ({
    width: "100%",
    position: "relative",
    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      left: "-40px",
      width: "6px",
      height: "100%",
      backgroundColor: props.active ? vantiColors.green1 : vantiColors.lightGreen6
    }
  })
});

export default styles;
