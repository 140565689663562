import React, { memo } from "react";
import { styled } from "@material-ui/core";
import { defaultFont } from "assets/jss/fonts";
import { Text } from "components/ui";
import ReportExecutiveSummarySectionDescription from "./ChatMessageReportExecutiveSummarySection/ReportExecutiveSummarySectionDescription";

const ReportResultSummarySectionContainer = styled("div")({
  fontFamily: defaultFont,
  fontsize: "14px",
  fontWeight: "400",
  lineHeight: "17px",
  display: "flex",
  flexDirection: "column"
});

const ChatMessageReportResultSummarySection = ({ resultSummary, lineClamp, staticReportCallbacks }) => {
  return (
    <ReportResultSummarySectionContainer>
      <Text weight={600} size={16} lineSize={"20px"}>
        Summary
      </Text>
      {staticReportCallbacks?.onSummaryUpdated ? (
        <ReportExecutiveSummarySectionDescription
          message={{}}
          onDescriptionUpdated={staticReportCallbacks.onSummaryUpdated}
        />
      ) : (
        <Text lineClamp={lineClamp}>{resultSummary}</Text>
      )}
    </ReportResultSummarySectionContainer>
  );
};

export default memo(ChatMessageReportResultSummarySection);
