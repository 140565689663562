import React, { memo } from "react";

import { FlexItem, Text } from "components/ui";
import Button from "components/ui/Buttons/Button.js";

import { ReactComponent as ArrowHead } from "assets/icons/ArrowHead.svg";

import { vantiColors } from "assets/jss/palette";

import { isUserAgentMobile } from "utils/app-utils";

import useStyles from "./styles";

const CardHeader = ({ totalCardsCount, carouselIndex, onClickAction, description, isFirstCardDisplaying }) => {
  const classes = useStyles();

  return (
    <FlexItem container justifyContent="center" dense="full" style={{ marginTop: isUserAgentMobile() ? 32 : 64 }}>
      <FlexItem container xs={10} md={6} justifyContent="space-between" alignItems="center" dense="full">
        <FlexItem dense="full">
          {!isFirstCardDisplaying && (
            <Button
              data-testid="create-account-back-button"
              onClick={onClickAction}
              transparent={true}
              className={classes.backButton}
            >
              <ArrowHead className={classes.arrowIcon} />
              <Text weight="bold" size={17} className={classes.backText}>
                Back
              </Text>
            </Button>
          )}
        </FlexItem>

        <FlexItem container xs={6} dense="full" justifyContent="flex-end" data-testid="create-account-header-section">
          <FlexItem container justifyContent="flex-end" dense="full">
            <Text
              data-testid={`create-account-step-counter-step-${carouselIndex + 1}/${totalCardsCount}`}
              size={12}
              weight="medium"
              color={vantiColors.gray17}
            >
              Step {carouselIndex + 1} / {totalCardsCount}
            </Text>
          </FlexItem>

          <br />

          <FlexItem container justifyContent="flex-end" dense="full">
            <Text data-testid={`create-account-header-${description}`} size={14}>
              {description}
            </Text>
          </FlexItem>
        </FlexItem>
      </FlexItem>
    </FlexItem>
  );
};

export default memo(CardHeader);
